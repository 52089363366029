/**
 * TermsCondition Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import {FiSettings} from 'react-icons/fi';
import {FaSignature} from 'react-icons/fa';
import{Mpayroll, Mpayroll2, MassReport , MassPayroll, MassAsset, MassManage} from '../../Entryfile/imagepath';
import {IoDocumentTextOutline} from 'react-icons/io5';
import {VscLaw} from 'react-icons/vsc';
import Attendance from '../../pr-system/attendance';
// import Attendance from '../attendance';

// import {useForm} from 'react-from';
export default class MassTaxes extends Component {
  render() {
    return (
           
			<div className="page-wrapper">
				 <Helmet>
              <title>MassPortal - تقارير  </title>
              <meta name="description" content="Reactify Blank Page" />
            </Helmet>
			{/* Page Content */}
			<div className="content container-fluid">
			  {/* Page Header */}
			  <div className="page-header">
				<div className="row">
				  <div className="col-sm-8 col-4">
					<h3 className="page-title"> اعداد تقارير مالية      </h3>
					<ul className="breadcrumb">
					  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
					</ul>
				  </div>
				</div>
			  </div>
			   {/* Account Logo */}
			   <div className="account-logo">
              {/* <a href="/"><img src={MassReport} alt="Mass Report" /></a> */}
			  <img src={MassReport} alt='MassPortal Report'/>
            </div>
            {/* /Account Logo */}
			  {/* /Page Header */}
			
			  
			  <div className="row">
			  
				<div className="col-lg-10 mx-auto">
				  {/* Plan Tab */}
				  <div className="row justify-content-center mb-4">
					<div className="col-auto">
					  <nav className="nav btn-group settings-nav">
						<a href="#monthly" className="btn btn-outline-secondary active show" data-toggle="tab"> إعدادات </a>
						<a href="#" className="btn btn-outline-secondary" data-toggle="tab"> Exports </a>
					  </nav>
					</div>
				  </div>
				  {/* /Plan Tab */}
				  {/* Plan Tab Content */}
				  <div className="tab-content">
					{/* Monthly Tab */}
					<div className="tab-pane fade active show" id="monthly">
					  <div className="row mb-30 equal-height-cards">
					  <div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								<br></br>
								<h2 className="settings-title">اعدادات و قيود    </h2>
								<span className="display-6"><VscLaw className='privacy' /></span>
								{/* <span>/mo</span> */}
							  </div>
							  <ul className="text-left settings-mass">
							  <li><a href='/massmg-report-newexp'><VscLaw size={25} className='privacy' /> انشاء حسابات مصاريف     </a></li>
							  <li><a href='/massmg-report-newsupp'><VscLaw size={25} className='privacy' /> انشاء حسابات موردين     </a></li>
							  {/* <li><a href='#'><VscLaw size={25} className='privacy' /> قوانين التقاعد   </a></li> */}
							  <li><a href='/mass-report-jv'><VscLaw size={25} className='privacy' /> ادخال قيود مصاريف   </a></li>
							  <li><a href='#'><VscLaw size={25} className='privacy' /> طباعة قيود المصاريف     </a></li>
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								  <br></br>
								<h2 className="settings-title">تحضير بيان أرباح و خسائر  </h2>
								<span className="display-6"><FiSettings className='privacy'/></span>
							  </div>
							  <ul className='text-left settings-mass'>
								<li><a href='/mass-revsaile-rep' ><FiSettings size={20} className='privacy'/>  تحضير بيان أرباح و خسائر  </a></li>
								<li><a href='#' ><FiSettings size={20} className='privacy'/>  تحضير بيان أرباح و خسائر مع فحص الضريبة و التأمين الوطني  </a></li>
								<li><a href='#' ><FiSettings size={20} className='privacy'/>  تحضير بيان تلاؤم لغاية الـتأمين الوطني  </a></li>
								<li><a href='#' ><FiSettings size={20} className='privacy'/>  تقرير كامل  </a></li>
							  </ul>
							  
							  {/* <a href="/shaam-tax" className="btn btn-lg btn-primary mt-auto">ضريبة الدخل </a> */}
							</div>
						  </div>
						</div>

						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">

								{/* <h3>تقارير </h3> */}
								<br></br>
								<h2 className="settings-title"> تمويل   </h2>
								<span className="display-6"><IoDocumentTextOutline className="privacy" /></span>
								{/* <span>/mo</span> */}
							  </div>
							  <ul className="text-left settings-mass">
								<li><a href='/mass-reports-gold-silver'><IoDocumentTextOutline size={25} className='privacy' /> احتساب معادن, ذهب, فضة   </a></li>
								<li><a href='/mass-reports-exchange'><IoDocumentTextOutline size={25} className='privacy' /> احتساب عملات    </a></li>
								<li><a href='/mass-reports-altman-calc'><IoDocumentTextOutline size={25} className='privacy' /> مؤشر <span className='privacy'> Altman </span>    </a></li>
								<li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> أخرى ...    </a></li>
								<li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> أخرى ...    </a></li>

							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						
					  </div>
					</div>
					{/* /Monthly Tab */}
					{/* Yearly Tab */}
					<div className="tab-pane fade" id="annual">
					  <div className="row mb-30 equal-height-cards">
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
							  <br></br>
								<h2 className="settings-title">تقارير للموظف   </h2>
								<span className="display-6"><IoDocumentTextOutline className='privacy' /></span>
							  </div>
							  <ul className='text-left settings-mass'>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> قسيمة راتب     </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> قسيمة كشف 106 </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> قسيمة كشف 161 </a></li>
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								  <br></br>
								  <h2 className='settings-title'> تقارير للشركة </h2>
								  <span className="display-6"><IoDocumentTextOutline className='privacy' /></span>

								{/* <h3>Professional</h3>
								<span className="display-4">$199</span>
								<span>/mo</span> */}
							  </div>
							  <ul className='text-left settings-mass'>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تكلفة الرواتب الشهرية     </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تكلفة الرواتب السنوية      </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> مخصص تعويضات نهاية الخدمة      </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> مخصص الاجازات      </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تقارير أخرى ...      </a></li>

								
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
							  <br></br>
								  <h2 className='settings-title'> تقارير لسلطات الضرائب  </h2>
								  <span className="display-6"><IoDocumentTextOutline className='privacy' /></span>
							  </div>
							  <ul className='settings-mass text-left '>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> كشف 102   </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> كشف 612   </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> كشف 126   </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تصدير كشوفات Online   </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تصريح الضرائب </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تصريح التأمين الوطني    </a></li>

								
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
					  </div>
					</div>
					{/* Yearly Tab */}
				  </div>
				  {/* /Plan Tab Content */}
				  {/* Plan Details */}
			
				 
				  {/* /Plan Details */}
				</div>
			  </div>
			</div>
			{/* /Page Content */}
		  </div>
       
    );
  }
}
