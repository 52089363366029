
import React, { Component, useState } from 'react';
import { Helmet } from "react-helmet";
import { useCollection } from "../../hooks/useCollection"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useDocument } from '../../hooks/useDocument'
import { timeStamp } from "../../firebase/config"
import { useFirestore } from "../../hooks/useFirestore"
import { useHistory } from 'react-router'
import { useAuth } from '../../contexts/AuthContext';
import { Placeholder } from 'react-bootstrap';
import { useEffect } from 'react';
// import { Select } from 'react-select';
export default function SettingsMg() {


  const { user } = useAuthContext()
 const history = useHistory();
 const { updateDocument, response}= useFirestore('mp-clients')
 

 const [users, setUsers] = useState([])
//  Form Valid Values : 
const [userName , setUserName] = useState('')
const [companyName, setCompanyName] = useState('')
const [address, setAddress] = useState('')
const [stateName, setStateName] = useState('')
const [cityName, setCityName] = useState('')
const [mailCode, setMailCode] = useState('')
// const [mailBox, setMailBox] = useState('')
const [email, setEmail] = useState('')
const [phoneNumber, setPhoneNumber] = useState('')
const [officeNumber, setOfficeNumber] = useState('')
const [faxNumber , setFaxNumber] = useState('')
const [taxFileNumber, setTFNumber] = useState('')
const [vatFileNumber, setVatFNumber]= useState('')
const [cutFileNumber, setcFileNumber] = useState('')
const { document, error } = useDocument('mp-clients',  user.uid)
// console.log("document: ", document?document.userName: 'nth')
// Submit Handler 

useEffect(()=>{

  if(document){
    // console.log("My Client is : ", document)
    setUserName(document.userName?document.userName:" ")
    setCompanyName(document.companyName?document.companyName:" ")
    setAddress(document.address?document.address:" ")
    setStateName(document.stateName?document.stateName:" ")
    setCityName(document.cityName?document.cityName:" ")
    setMailCode(document.mailCode?document.mailCode:" ")
    setEmail(document.email?document.email:" ")
    setPhoneNumber(document.phoneNumber?document.phoneNumber:" ")
    setOfficeNumber(document.officeNumber?document.officeNumber:" ")
    setFaxNumber(document.faxNumber?document.faxNumber:" ")
    setTFNumber(document.taxFileNumber?document.taxFileNumber:" ")
    setVatFNumber(document.vatFileNumber?document.vatFileNumber:" ")
    setcFileNumber(document.cutFileNumber?document.cutFileNumber:" ")
  }
},[document])
const handleSubmit = async(e) => {


  e.preventDefault()

  const officeData = {
    userName ,
    companyName ,
    address ,
    stateName ,
    cityName ,
    mailCode,
    // mailBox,
    email ,
    phoneNumber ,
    officeNumber ,
    faxNumber ,
    taxFileNumber ,
    vatFileNumber,
    cutFileNumber ,
    // createdBy,
    // userName : userName?userName : document.userName,
    // companyName : companyName? companyName : document.companyName,
    // address : address?address : document.address,
    // stateName : stateName? stateName : document.stateName,
    // cityName : cityName? cityName : document.cityName,
    // mailCode : mailCode? mailCode : document.mailCode,
    // // mailBox,
    // email : email? email : document.email,
    // phoneNumber : phoneNumber? phoneNumber : document.phoneNumber,
    // officeNumber : officeNumber? officeNumber : document.officeNumber,
    // faxNumber : faxNumber? faxNumber: document.faxNumber,
    // taxFileNumber : taxFileNumber? taxFileNumber : document.taxFileNumber,
    // vatFileNumber : vatFileNumber? vatFileNumber: document.vatFileNumber,
    // cutFileNumber : cutFileNumber? cutFileNumber : document.cutFileNumber,
    // // createdBy,

  }

  


  await updateDocument(user.uid, officeData)
  if (response.error) {
    console.log("error here", error)
  }
  
  window.location.href="/"

  // console.log("createdBy", createdBy)
  // console.log(officeData);
}



    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - تعريف المكتب </title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <h3 className="page-title"> تعريف المكتب </h3>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label> اسم الشركة  <span className="text-danger">*</span></label>
                      <input className="form-control" 
                      type="text" 
                      // required
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName}
                      placeholder= { "   "}
                      // placeholder={document.companyName}
                      // value={updateDocument.companyName} 
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>اسم المدير </label>
                      <input className="form-control "
                       onChange={(e) => setUserName(e.target.value)}
                       value={userName}
                      placeholder= { " "}
                      //  required
                       type="text" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>العنوان</label>
                      <input className="form-control " 
                      onChange={(e) => setAddress(e.target.value) }
                      value={address}
                      placeholder= " "
                      // required
                      type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label>المحافظة </label>
                      <input className="form-control " 
                      onChange={(e) => setStateName(e.target.value) }
                      value={stateName}
                      placeholder= { " "}
                      // required
                      type="text" />
                      {/* <select className=" rtx-select" id='state'
                      // onChange={(e) => setStateName(e.target.value)}
                      >
                        <option></option>
                        <option value='القدس '>القدس</option>
                        <option value='الخليل '>الخليل </option>
                      </select> */}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label>المدينة</label>
                      <input className="form-control"  
                      type="text"
                      // required
                      onChange={ (e) => setCityName(e.target.value)}
                      value={cityName}
                      placeholder= {document?document.cityName: " "}

                       />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label>رمز البريد</label>
                      <input className="form-control"  
                      type="text"
                      onChange={ (e) => setMailCode(e.target.value)}
                      value={mailCode}
                      placeholder= { " "}
                      // required
                       />
                      {/* <select className="form-control select">
                       <option>California</option>
                       <option>Alaska</option>
                       <option>Alabama</option>
                     </select> */}
                    </div>
                  </div>
                  {/* <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label>صندوق البريد </label>
                      <input className="form-control"  
                      type="text"
                      onChange={ (e) => setMailBox(e.target.value)}
                      value={mailBox}
                       />
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>البريد الالكتروني </label>
                      <input className="form-control"
                        type="email" 
                        onChange={ (e) => setEmail(e.target.value)}
                        value ={email}
                       placeholder= { "   "}
                        // required
                        />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>رقم الهاتف - جوال</label>
                      <input className="form-control" 
                      type="text"
                      onChange={ (e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      placeholder= {" "}
                      // required
                       />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>هاتف المكتب - أرضي </label>
                      <input className="form-control"
                      type="text" 
                      onChange={ (e) => setOfficeNumber(e.target.value)}
                      value={officeNumber}
                      placeholder= { " "}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>فاكس</label>
                      <input className="form-control"
                      type="number"
                      onChange={ (e) => setFaxNumber(e.target.value)}
                      value={faxNumber}
                      placeholder= { " "}
                       />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>رقم ملف ضريبة الدخل </label>
                      <input className="form-control"
                        type="number" 
                        onChange={ (e) => setTFNumber(e.target.value)}
                        value={taxFileNumber}
                      placeholder= { " "}
                        />
                    </div>
                  </div>
                  <div className="col-sm-4">
                   <div className="form-group">
                     <label>رقم ملف ضريبة القيمة المضافة </label>
                     <input className="form-control"
                       type="number" 
                       onChange={ (e) => setVatFNumber(e.target.value)}
                       value={vatFileNumber}
                      placeholder= { " "}
                       />
                   </div>
                 </div>
                 <div className="col-sm-4">
                   <div className="form-group">
                     <label>رقم ملف الاقتطاعات </label>
                     <input className="form-control" 
                     type="number"
                     onChange={ (e) => (setcFileNumber(e.target.value))}
                     placeholder= {document?document.cutFileNumber: " "}
                     value={cutFileNumber}
                     
                      />
                   </div>
                 </div>

                </div>
                {/* <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Website Url</label>
                      <input className="form-control" defaultValue="https://www.example.com" type="text" />
                    </div>
                  </div>
                </div> */}
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">تعريف </button>
                  <a href='/mass-modules' className="btn btn-primary submit-btn">لاحقا  </a>

                </div>
               
              </form>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    );
  }

