/**
 * Signin Firebase
 */


import React, { Component, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Avatar_01, Avatar_02, Avatar_05, Avatar_09, Avatar_10, Avatar_11, Avatar_12, Avatar_13, Avatar_16 } from '../../../../Entryfile/imagepath'
import { BsSearch } from 'react-icons/bs'
import aen from "./data/aen"
import alef from "./data/alef"
import s from "./data/s"
import bet from "./data/bet"
import dad from "./data/dad"
import dal from "./data/dal"
import e from "./data/e."
import en from "./data/en"
import f from "./data/f"
import gem from "./data/gem"
import h from "./data/h"
import ha from "./data/ha"
import haaa from "./data/haaa"
import k from "./data/k."
import kaf from "./data/kaf"
import l from "./data/l"
import m from "./data/m"
import n from "./data/n"
import ra from "./data/ra"
import sad from "./data/sad"
import shen from "./data/shen"
import ta from "./data/ta"
import tah from "./data/tah"
import zal from "./data/zal"
import wow from "./data/wow."
import tha from "./data/tha"
import full from './data/db';


export default function Dictionary() {

  const [data, setData] = useState([])
  const [clicked, setClicked] = useState('أ')
  const [search, setSearch] = useState(false)
  const [searchBar, setSearchBar] = useState('')
  useEffect(() => {
    if (searchBar !== '') {
      setSearch(true)
      setData(full)
      setClicked(searchBar[0])
    }
    else if (searchBar == '') {
      setSearch(false)
      setClicked(clicked)
    }
    if (clicked == 'أ') {
      setData(alef)

    }
    else if (clicked == 'ب') {
      setData(bet)
    }
    else if (clicked == 'ت') {
      setData(tah)
    }
    else if (clicked == 'ث') {
      setData(tha)
    }
    else if (clicked == 'ج') {
      setData(gem)
    }
    else if (clicked == 'ح') {
      setData(ha)
    }
    else if (clicked == 'خ') {
      setData(haaa)
    }
    else if (clicked == 'د') {
      setData(dal)
    }
    else if (clicked == 'ر') {
      setData(ra)
    }
    else if (clicked == 'ز') {
      setData(zal)
    }
    else if (clicked == 'س') {
      setData(s)
    }
    else if (clicked == 'ش') {
      setData(shen)
    }
    else if (clicked == 'ص') {
      setData(sad)
    }
    else if (clicked == 'ض') {
      setData(dad)
    }
    else if (clicked == 'ط') {
      setData(ta)
    }
    else if (clicked == 'ع') {
      setData(aen)
    }
    else if (clicked == 'غ') {
      setData(en)
    }
    else if (clicked == 'ف') {
      setData(f)
    }
    else if (clicked == 'ق') {
      setData(kaf)
    }
    else if (clicked == 'ك') {
      setData(k)
    }
    else if (clicked == 'ل') {
      setData(l)
    }
    else if (clicked == 'م') {
      setData(m)
    }
    else if (clicked == 'ن') {
      setData(n)
    }
    else if (clicked == 'ه') {
      setData(h)
    }
    else if (clicked == 'و') {
      setData(wow)
    }
    else if (clicked == 'ي') {
      setData(e)
    }


  }, [clicked, searchBar])
  console.log(searchBar)


  return (
    <div className="page-wrapper">
      <Helmet>
        <title> MassPortal - قاموس    </title>
        <meta name="description" content="MassPortal - قاموس " />
      </Helmet>
      {/* Contact Main Row */}
      <div className="content container-fluid">

        {/* Contact Wrapper */}
        <div className="">
          <div className="">
            <div className="">

              <div className="">
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="row">

                        <div className="card tax-card ">
                          {/* Page Header */}
                          <div className="page-header">
                            <div className="row align-items-center">
                              <div className="col">
                                <br></br>
                                <h3 className="page-title">قاموس <span className='privacy'> MassPortal </span>  التجاري   </h3>
                                <ul className="breadcrumb">
                                  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
                                </ul>
                                <br></br><br></br>
                              </div>
                            </div>
                          </div>
                          {/* /Page Header */}
                          <div className="card-body">

                            <div className='col-sm-24 col-md-8' >
                              <div className="navbar " >
                                <div className="search-box " style={{ width:"100%"}}>
                                  <div class="input-group input-group-sm">
                                    {/* <input type="text" class="form-control" placeholder="Search"/> */}
                                    <input id="dates "
                                      className='  form-control form-control-zq'
                                      aria-describedby="inputGroup-sizing-lg"
                                      onChange={(e) => setSearchBar(e.target.value)}
                                      value={searchBar}
                                      placeholder=" ترجم كلمة عربي, عبري, انجليزي"
                                    />
                                    <span className="input-group-append">
                                      <button className="btn">
                                        <i class="fa fa-search"></i>
                                      </button>
                                    </span>

                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-sm-24 col-md-12  dates-div " >
                              <div className='row input-group input-group-lg justify-content ' >
                                <input id="dates "
                                  className='  col-lg-8 form-control-zq '
                                  aria-describedby="inputGroup-sizing-lg"
                                  onChange={(e) => setSearchBar(e.target.value)}
                                  value={searchBar}
                                  placeholder="ترجم كلمة عربي, عبري, انجليزي"
                                />
                                <span class="input-group-append"><button class="btn" type="button"><i class="fa fa-search"></i></button></span>
                                <BsSearch />


                              </div>

                            </div> */}
                            <div className=''>
                              <br></br>
                              {!search && <div className="card-header col-lg-11">
                                <h4 className="card-title mb-0 text-center ">قاموس <span className='privacy'> ماس </span> التجاري ( <span className='privacy'>{clicked}</span> ) </h4>
                                
                              </div>}
                              {search && <div className="card-header col-lg-11">
                                <h4 className="card-title mb-0 text-center ">نتائج البحث عن <span className='privacy'>( {searchBar} )</span> </h4>

                              </div>}

                              <div className="table-responsive col-md-11">
                                <table className="table table-nowrap table-striped mb-0">

                                  <thead>

	
                                    <tr style={{borderBottom:"3px solid #ff851a" }}>
                                      <th className="privacy text-left display-6  bold"> عربي  </th>


                                      <th className="privacy  text-left display-6  bold">עברית</th>


                                      <th className="privacy  text-right display-6 bold">English</th>



                                      {/* <th>Country</th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!search &&

                                      data.map((d) =>
                                        <tr>
                                          <th className="privacy text-left fs-5" style={{ whiteSpace: "normal", overflow: "hidden" }}>        {d.ar}</th>


                                          <th className="text-left fs-5 " style={{ whiteSpace: "normal", overflow: "hidden" }} > {d.he}</th>


                                          <th className="text-right fs-5 " style={{ whiteSpace: "normal", overflow: "hidden" }}> {d.en}</th>



                                        </tr>
                                      )


                                    }
                                    {search &&

                                      full.map((d) => {
                                        if (d.ar.includes(`${searchBar}`)) {
                                          return (<tr>
                                            <th className="privacy text-left  fs-5">        {d.ar}</th>


                                            <th className="text-left  fs-5"> {d.he}</th>


                                            <th className="text-right  fs-5"> {d.en}</th>



                                          </tr>)
                                        }
                                        else if (d.en.includes(`${searchBar}`)) {
                                          return (<tr>
                                            <th className=" text-left  fs-5">        {d.ar}</th>


                                            <th className="text-left  fs-5"> {d.he}</th>


                                            <th className=" privacy text-right fs-5"> {d.en}</th>



                                          </tr>)
                                        }
                                        else if (d.he.includes(`${searchBar}`)) {
                                          return (<tr>
                                            <th className=" text-left fs-5 ">        {d.ar}</th>


                                            <th className=" privacy text-left  fs-5"> {d.he}</th>


                                            <th className=" text-right fs-5"> {d.en}</th>



                                          </tr>)
                                        }
                                      }
                                      )


                                    }



                                    {/* <tr>
                <td>1</td>
                <td>Anna</td>
                <td>Pitt</td>
                <td>35</td>
                <td>New York</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Anna</td>
                <td>Pitt</td>
                <td>35</td>
                <td>New York</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Anna</td>
                <td>Pitt</td>
                <td>35</td>
                <td>New York</td>
              </tr> */}
                                  </tbody>
                                </table>

                              </div>

                              <div className="contact-alphapets  col-md-1 ">
                                <div className="alphapets-inner">
                                  <br></br>
                                  <a href="#" className={clicked == "أ" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('أ')} >أ</a>
                                  <a href="#" className={clicked == "ب" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ب')}>ب</a>
                                  <a href="#" className={clicked == "ت" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ت')}>ت</a>
                                  <a href="#" className={clicked == "ث" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ث')}>ث</a>
                                  <a href="#" className={clicked == "ج" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ج')}>ج</a>
                                  <a href="#" className={clicked == "ح" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ح')}>ح</a>
                                  <a href="#" className={clicked == "خ" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('خ')}>خ</a>
                                  <a href="#" className={clicked == "د" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('د')}>د</a>
                                  <a href="#" className={clicked == "ر" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ر')}>ر</a>
                                  <a href="#" className={clicked == "ز" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ز')}>ز</a>
                                  <a href="#" className={clicked == "س" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('س')}>س</a>
                                  <a href="#" className={clicked == "ش" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ش')}>ش</a>
                                  <a href="#" className={clicked == "ص" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ص')}>ص</a>
                                  <a href="#" className={clicked == "ض" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ض')}>ض</a>
                                  <a href="#" className={clicked == "ط" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ط')}>ط</a>
                                  <a href="#" className={clicked == "ع" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ع')}>ع</a>
                                  <a href="#" className={clicked == "غ" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('غ')}>غ</a>
                                  <a href="#" className={clicked == "ف" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ف')}>ف</a>
                                  <a href="#" className={clicked == "ق" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ق')}>ق</a>
                                  <a href="#" className={clicked == "ك" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ك')}>ك</a>
                                  <a href="#" className={clicked == "ل" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ل')}>ل</a>
                                  <a href="#" className={clicked == "م" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('م')}>م</a>
                                  <a href="#" className={clicked == "ن" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ن')}>ن</a>
                                  <a href="#" className={clicked == "ه" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ه')}>ه</a>
                                  <a href="#" className={clicked == "و" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('و')}>و</a>
                                  <a href="#" className={clicked == "ي" ? "privacy-active" : 'privacy'} onClick={(e) => setClicked('ي')}>ي</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-2'></div>
                          {/* 
                          <div className="contact-alphapets text-center col-lg-1 ">
                            <div className="alphapets-inner">
                              <a href="#" className='privacy' onClick={(e) => setClicked('أ')}>أ</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ب')}>ب</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ت')}>ت</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ث')}>ث</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ج')}>ج</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ح')}>ح</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('خ')}>خ</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('د')}>د</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ر')}>ر</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ز')}>ز</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('س')}>س</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ش')}>ش</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ص')}>ص</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ض')}>ض</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ط')}>ط</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ع')}>ع</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('غ')}>غ</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ف')}>ف</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ق')}>ق</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ك')}>ك</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ل')}>ل</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('م')}>م</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ن')}>ن</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ه')}>ه</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('و')}>و</a>
                              <a href="#" className='privacy' onClick={(e) => setClicked('ي')}>ي</a>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Contact Wrapper */}
      </div>
      {/* /Contact Main Row */}



    </div>
  );
}



