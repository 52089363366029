/**
 * TermsCondition Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import {FiSettings} from 'react-icons/fi';
import {FaSignature, FaPercent} from 'react-icons/fa';
import{Mpayroll, Mpayroll2, MassLow , MassPayroll, MassAsset, MassManage} from '../../../Entryfile/imagepath';
import {IoDocumentTextOutline} from 'react-icons/io5';
import {VscLaw} from 'react-icons/vsc';

import {  BsTable } from "react-icons/bs";
import {  AiOutlineCalculator , AiOutlineLock } from "react-icons/ai";



import Attendance from '../../../pr-system/attendance';
// import Attendance from '../attendance';
export default class MassLows extends Component {
  render() {
    return (
           
			<div className="page-wrapper">
				 <Helmet>
              <title>MassPortal - قوانين و مراجع   </title>
              <meta name="description" content=" MassPortal - قوانين و مراجع   " />
            </Helmet>
			{/* Page Content */}
			<div className="content container-fluid">
			  {/* Page Header */}
			  <div className="page-header">
				<div className="row">
				  <div className="col-sm-8 col-4">
					<h3 className="page-title"> قوانين و مراجع      </h3>
					<ul className="breadcrumb">
					  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
					</ul>
				  </div>
				</div>
			  </div>
			   {/* Account Logo */}
			   <div className="account-logo">
              {/* <a href="/"><img src={MassLow} alt="Mass Law" /></a> */}
			  <img src={MassLow} alt='MassPortal Law' />
            </div>
            {/* /Account Logo */}
			  {/* /Page Header */}
			
			  
			  <div className="row">
			  
				<div className="col-lg-10 mx-auto">
				  {/* Plan Tab */}
				  <div className="row justify-content-center mb-4">
				  <div className="row">
					  
					  <div className="col-md-12">
						<div className="card card-table mb-0">
						  <div className="card-header">
							<h4 className="card-title mb-0 privacy">معطيات   </h4>
						  </div>
						  <div className="card-body">
							<div className="table-responsive">
							  <table className="table table-center mb-0">
								<thead>
								  <tr>
									<th>قيمة يوم النقاهة / خاص</th>
									<th>قيمة يوم النقاهة / عام   </th>
									<th>راتب حد أدنى / شهري </th>
									<th>راتب حد أدنى / ساعة  </th>
									<th>معدل الرواتب </th>
									<th>يوم سفريات </th>
								  
								  </tr>
								</thead>
								<tbody>
								  <tr className='text-center privacy tr-givens'>
									<td>400</td>
									<td>449</td>
									<td>5300</td>
									<td>29.12</td>
									<td>10428</td>
									<td>22.60</td>
									{/* <td><a className="btn btn-primary btn-sm" href="">Change Plan</a></td> */}
								  </tr>
								</tbody>
							  </table>
							</div>
						  </div>
						</div>
					  </div>
					</div>
					<br></br>
					<div className="col-auto">
						<br></br>
						<br></br>
					  <nav className="nav btn-group settings-nav">
						<a href="#monthly" className="btn btn-outline-secondary active show" data-toggle="tab"> قوانين العمل  </a>
						{/* <a href="#annual" className="btn btn-outline-secondary" data-toggle="tab"> حاسبات  </a> */}
						<a href="#annual" className="btn btn-outline-secondary" data-toggle="tab"> قوانين الضرائب   </a>

					  </nav>
					</div>
				  </div>
				  {/* /Plan Tab */}
				  {/* Plan Tab Content */}
				  <div className="tab-content">
					{/* Monthly Tab */}
					<div className="tab-pane fade active show" id="monthly">
					  <div className="row mb-30 equal-height-cards">
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								  <br></br>
								<h2 className="settings-title">جداول </h2><hr></hr>
								<span className="display-6"><BsTable className='privacy'/></span>
							  </div>
							  <ul className='text-left settings-mass'>
								<li><a href='/mass-law-year' ><BsTable size={20} className='privacy'/>  الاجازة السنوية  </a></li>
								<li><a href='/mass-law-sick' ><BsTable size={20} className='privacy'/>  الاجازة المرضية  </a></li>
								<li><a href='/mass-law-recovery' ><BsTable size={20} className='privacy'/>  رسوم النقاهة  </a></li>
								<li><a href='/mass-law-eid' ><BsTable size={20} className='privacy'/>  أيام العيد   </a></li>
								<li><a href='/mass-law-minors' ><BsTable size={20} className='privacy'/>  قانون عمل القاصرين   </a></li>

							
							
						
								{/* <li><i className="fa fa-check" /> 5 Projects </li>
								<li><i className="fa fa-check" /> 5 GB Storage</li>
								<li><i className="fa fa-check" /> Unlimited Message</li> */}
							  </ul>
							  
							  {/* <a href="/shaam-tax" className="btn btn-lg btn-primary mt-auto">ضريبة الدخل </a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">

								{/* <h3>تقارير </h3> */}
								<br></br>
								<h2 className="settings-title">تعويضات نهاية الخدمة  </h2><hr></hr>
								<span className="display-6"><IoDocumentTextOutline className="privacy" /></span>
								{/* <span>/mo</span> */}
							  </div>
							  <ul className="text-left settings-mass">
								<li><a href='/mass-law-eos'><IoDocumentTextOutline size={25} className='privacy' />تعويضات نهاية الخدمة     </a></li>
								<li><a href='/mass-law-pvi'><IoDocumentTextOutline size={25} className='privacy' /> اعلان مسبق    </a></li>
								<li><a href='/mass-law-rights'><IoDocumentTextOutline size={25} className='privacy' /> تقادم حقوق في قوانين العمل     </a></li>
								<li><a href='/mass-law-dm'><IoDocumentTextOutline size={25} className='privacy' /> التمييز بين موظف شهري و يومي     </a></li>

							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								<br></br>
								<h2 className="settings-title">حاسبات  </h2><hr></hr>
								<span className="display-6"><AiOutlineCalculator className='privacy' /></span>
								{/* <span>/mo</span> */}
							  </div>
							  <ul className="text-left settings-mass">
							  <li><a href='/calculators/WorkLawsCalc/EndOfServiceCalc'><AiOutlineCalculator size={25} className='privacy' /> احتساب تعويضات نهاية الخدمة     </a></li>
							  <li><a href='/naqaha-calculator'><AiOutlineCalculator size={25} className='privacy' /> احتساب النقاهة    </a></li>
							  <li><a href='/halanat-skhar'><AiOutlineCalculator size={25} className='privacy' /> فؤائد على تأخير الرواتب   </a></li>
							  <li><a href='#'><AiOutlineCalculator size={25} className='privacy' /> احتساب تأخير تعويضات     </a> <AiOutlineLock size={25} className='privacy lock-dash'/> </li>
							  <li><a href='#'><AiOutlineCalculator size={25} className='privacy' /> أخرى ...    </a> <AiOutlineLock size={25} className='privacy lock-dash'/></li>
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
					  </div>
					</div>
					{/* /Monthly Tab */}
					{/* Yearly Tab */}
					<div className="tab-pane fade" id="annual">
					  <div className="row mb-30 equal-height-cards">
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
							  <br></br>
								<h2 className="settings-title">شرائح ضريبة الدخل    </h2>
								<hr></hr>
								<span className="display-6"><BsTable className='privacy' /></span>
							  </div>
							  <ul className='text-left settings-mass'>
							  <li><a href='tax-2023'><BsTable size={25} className='privacy' /> شرائح ضريبة الدخل لعام 2023     </a></li>
							  <li><a href='tax-2022'><BsTable size={25} className='privacy' /> شرائح ضريبة الدخل لعام 2022     </a></li>
							  <li><a href='tax-2021'><BsTable size={25} className='privacy' /> شرائح ضريبة الدخل لعام 2021 </a></li>
							  <li><a href='#'><BsTable size={25} className='privacy' /> ملعومات أخرى ...  </a> <AiOutlineLock size={25} className='privacy lock-dash'/></li>
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								  <br></br>
								  <h2 className='settings-title'> ضريبة القيمة المضافة  </h2><hr></hr>
								  <span className="display-6"><FaPercent className='privacy' /></span>

								{/* <h3>Professional</h3>
								<span className="display-4">$199</span>
								<span>/mo</span> */}
							  </div>
							  <ul className='text-left settings-mass'>
							  <li><a href='vat-table'><FaPercent size={25} className='privacy' /> نسب ضريبة القيمة المضافة - سنوات      </a></li>
							  <li><a href='calculator-vat'><FaPercent size={25} className='privacy' /> احتساب قيمة مضافة سريع      </a></li>
							  <li><a href='month-vat-rpt'><FaPercent size={25} className='privacy' /> تعبئة كشف القيمة المضافة       </a></li>
							  <li><a href='#'><FaPercent size={25} className='privacy' /> ملعومات أخرى ...  </a><AiOutlineLock size={25} className='privacy lock-dash'/> </li>
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
							  <br></br>
								  <h2 className='settings-title'> حاسبات   </h2> <hr></hr>
								  <span className="display-6"><AiOutlineCalculator className='privacy' /></span>
							  </div>
							  <ul className='settings-mass text-left '>
							  <li><a href='calc-rtx-21'><AiOutlineCalculator size={25} className='privacy' /> فحص ارجاع ضريبي - 2021    </a></li>
							  <li><a href='calc-rtx'><AiOutlineCalculator size={25} className='privacy' /> احتساب ضريبي مستقل    </a></li>
							  <li><a href='#'><AiOutlineCalculator size={25} className='privacy' /> احتساب ضريبة الدخل - سنوي    </a><AiOutlineLock size={25} className='privacy lock-dash'/></li>
							  <li><a href='#'><AiOutlineCalculator size={25} className='privacy' /> احتساب الضريبة لعمل خارج البلاد    </a><AiOutlineLock size={25} className='privacy lock-dash'/></li>
							  <li><a href='#'><AiOutlineCalculator size={25} className='privacy' /> ملعومات أخرى ...  </a><AiOutlineLock size={25} className='privacy lock-dash'/> </li>
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
					  </div>
					</div>
					{/* Yearly Tab */}
				  </div>
				  {/* /Plan Tab Content */}
				  {/* Plan Details */}
				  {/* <div className='row'>
				  <div className="col-lg-12 mx-auto"> 
			  <br></br>
			  <div className="table-responsive">
              <table className="table table-striped custom-table table-nowrap mb-0">
                <thead>
                  <tr>                   
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>                  
                  </tr>
                </thead>
                <tbody>
                  <tr>                   
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>                  
                  </tr>                 
                </tbody>
              </table>
            </div>
			  <br></br>
			  </div>			  
				  </div> */}
				
				  {/* /Plan Details */}
				</div>
			  </div>
			</div>
			{/* /Page Content */}
		  </div>
       
    );
  }
}
