/**
 * Signin Firebase
 */

import React, { Component ,useState } from 'react';
import { Helmet } from "react-helmet";
import {massportallogo} from '../../Entryfile/imagepath';
import {useSignup} from '../../hooks/useSignup'

export default function Registrationpage() {

      const [email, setEmail] = useState('')
      const [password, setPassword] = useState('')
      const [displayName, setDisplayName] = useState('')
      const { signup, isPending, error } = useSignup()

      const handleSubmit = (e) => {
        e.preventDefault()
        signup(email, password, displayName)
      }
    
      return (
      <div className="page-wrapper"> 
            
            <div className="main-wrapper">
              <Helmet>
                  <title>Register - Mass Portal</title>
                  <meta name="description" content="انشاء حساب جديد "/>					
            </Helmet> 
        <div className="account-content">
          {/* <a href="/applyjob/joblist" className="btn btn-primary apply-btn">Apply Job</a> */}
          <div className="container">
            {/* Account Logo */}
            <div className="account-logo">
              <a href="/home"><img src={massportallogo} alt="MassPortal Logo " /></a>
            </div>
            {/* /Account Logo */}
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">انشاء حساب جديد</h3>
                {/* <p className="account-subtitle">دخول الى الصفحة الرئيسية</p> */}
                {/* Account Form */}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>البريد الالكتروني</label>
                    <input className="form-control" 
                    type="text"
                    onChange={(e) => setEmail(e.target.value)} 
                    value={email}
                     />
                  </div>
                  <div className="form-group">
                    <label>كلمة المرور</label>
                    <input className="form-control" 
                    type="password" 
                    onChange={(e) => setPassword(e.target.value)} 
                    value={password} 
                    />
                  </div>
                  <div className="form-group">
                    <label>اسم المستخدم </label>
                    <input className="form-control" 
                      type="text"
                      onChange={(e) => setDisplayName(e.target.value)}
                      value={displayName}
                    />
                    
                  </div>
                  <div className="form-group text-center">
                    {/* <button className="btn btn-primary account-btn" type="submit">Register</button> */}
                    { !isPending && <button className="btn btn-primary account-btn">انشاء</button> }
                    { isPending && <button className="btn btn-primary account-btn" disabled>تحميل</button> }
                    { error && <p>{error}</p> }  
                  </div>
                  <div className="account-footer">
                    <p>هل لديك حساب? <a href="./loginpage">تسجيل الدخول</a></p>
                  </div>
                </form>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      );
   }




