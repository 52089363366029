import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { BiShekel, BiEdit } from 'react-icons/bi'
import { BsCheckLg } from 'react-icons/bs'
import { CgPlayListAdd } from 'react-icons/cg'
import { MdDeleteOutline } from 'react-icons/md'



export default function TableForm({
  catalogNum,
  month,
  setMonth,
  setCatalogNum,
  invoiceDetails,
  setInvoiceDetails,
  catagory,
  setCatagory,
  date1,
  date2,
  setDate1,
  setDate2,
  details,
  setDetails,
  quantity,
  setQuantity,
  price,
  setPrice,
  discountForProduct,
  setDiscountForProduct,
  amount,
  setAmount,
  setList,
  total,
  setTotal,
  perTax,
  setPerTax,
  tax,
  setTax,
  amounts,
  setAmounts,
  discount,
  setDiscount,
  totalAfDiscount,
  setTotalAfDiscount,
  isExemption,
  setIsExemption,
  totalAfTax,
  setTotalAfTax,
  exemptionAmount,
  setExemptionAmount,
  list,
  revenues,
  setRevenues,
  revenuesExempt,
  setRevenuesExempt,
  expenses,
  setExpenses,
  fixedExpenses,
  setFixedExpenses,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const numFocus = useRef(null)


  useEffect(() => {
    // console.log("exemption", isExemption)
    
    // console.log("revenues :",revenues)
    // console.log("revenuesExempt", revenuesExempt)
    // console.log("list is ", list)
    // console.log("expenses", expenses)
    // console.log("fixedExpenses",fixedExpenses)

    if (localStorage.getItem("list") === undefined) {
      setList(JSON.parse(localStorage.getItem("list")))
      localStorage.setItem("list", JSON.stringify(list))
        ;
    }


    if (list.length == 0 && revenues.length == 0 && revenuesExempt.length == 0 && expenses.length == 0 && fixedExpenses.length == 0 && localStorage.getItem("list") != undefined) {
      setList(JSON.parse(localStorage.getItem("list")))
      setRevenues(JSON.parse(localStorage.getItem("revenues")))
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))
      setExpenses(JSON.parse(localStorage.getItem("expenses")))
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      // console.log("EXEMPTTTTTTTT:  useEffect ",revenuesExempt)

    }


    else if (list.length == 0 && (localStorage.getItem("revenuesExempt") != undefined) && (localStorage.getItem("revenues") === (undefined)) && (localStorage.getItem("expenses") === (undefined))(localStorage.getItem("fixedExpenses") === (undefined))) {
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("expenses") != undefined || localStorage.getItem("expenses") != null) && (localStorage.getItem("revenues") === (undefined)) && (localStorage.getItem("revenuesExempt") === (undefined))(localStorage.getItem("fixedExpenses") === (undefined))) {
      setExpenses(JSON.parse(localStorage.getItem("expenses")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("fixedExpenses") != undefined || localStorage.getItem("fixedExpenses") != null) && (localStorage.getItem("revenues") === (undefined)) && (localStorage.getItem("expenses") === (undefined))(localStorage.getItem("revenuesExempt") === (undefined))) {
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("revenues") != (undefined)) && (localStorage.getItem("revenuesExempt") != (undefined)) && (localStorage.getItem("expenses") === (undefined))(localStorage.getItem("fixedExpenses") === (undefined))) {
      setList(JSON.parse(localStorage.getItem("list")))
      setRevenues(JSON.parse(localStorage.getItem("revenues")))
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))

    }
    else if (list.length == 0 && (localStorage.getItem("revenuesExempt") != (undefined)) && (localStorage.getItem("revenues") === (undefined)) && (localStorage.getItem("expenses") != (undefined))(localStorage.getItem("fixedExpenses") === undefined)) {
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))
      setList(JSON.parse(localStorage.getItem("list")))
      setExpenses(JSON.parse(localStorage.getItem("expenses")))

    }
    else if (list.length == 0 && (localStorage.getItem("expenses") != (undefined)) && (localStorage.getItem("revenues") != (undefined)) && (localStorage.getItem("revenuesExempt") === (undefined))(localStorage.getItem("fixedExpenses") === (undefined))) {
      setExpenses(JSON.parse(localStorage.getItem("expenses")))
      setRevenues(JSON.parse(localStorage.getItem("revenues")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("fixedExpenses") != (undefined)) && (localStorage.getItem("revenues") === (undefined)) && (localStorage.getItem("expenses") != (undefined))(localStorage.getItem("revenuesExempt") === (undefined))) {
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      setExpenses(JSON.parse(localStorage.getItem("expenses")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("revenues") != (undefined)) && (localStorage.getItem("fixedExpenses") != (undefined)) && (localStorage.getItem("expenses") === (undefined))(localStorage.getItem("revenuesExempt") === (undefined))) {
      setRevenues(JSON.parse(localStorage.getItem("revenues")))
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("revenuesExempt") != (undefined)) && (localStorage.getItem("fixedExpenses") != (undefined)) && (localStorage.getItem("expenses") === (undefined))(localStorage.getItem("revenuesExempt") === (undefined))) {
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      setList(JSON.parse(localStorage.getItem("list")))

    }

    else if (list.length == 0 && (localStorage.getItem("revenues") != (undefined)) && (localStorage.getItem("fixedExpenses") != (undefined)) && (localStorage.getItem("expenses") != (undefined))(localStorage.getItem("revenuesExempt") === (undefined))) {
      setRevenues(JSON.parse(localStorage.getItem("revenues")))
      setExpenses(JSON.parse(localStorage.getItem("expenses")))
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("revenues") != (undefined)) && (localStorage.getItem("fixedExpenses") === (undefined)) && (localStorage.getItem("expenses") != (undefined))(localStorage.getItem("revenuesExempt") != (undefined))) {
      setRevenues(JSON.parse(localStorage.getItem("revenues")))
      setExpenses(JSON.parse(localStorage.getItem("expenses")))
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("revenues") != (undefined)) && (localStorage.getItem("fixedExpenses") != (undefined)) && (localStorage.getItem("expenses") === (undefined))(localStorage.getItem("revenuesExempt") != (undefined))) {
      setRevenues(JSON.parse(localStorage.getItem("revenues")))
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("revenues") === (undefined)) && (localStorage.getItem("fixedExpenses") != (undefined)) && (localStorage.getItem("expenses") != (undefined))(localStorage.getItem("revenuesExempt") != (undefined))) {
      setExpenses(JSON.parse(localStorage.getItem("expenses")))
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))
      setList(JSON.parse(localStorage.getItem("list")))

    }
    else if (list.length == 0 && (localStorage.getItem("revenues") === (undefined)) && (localStorage.getItem("fixedExpenses") === (undefined)) && (localStorage.getItem("expenses") === (undefined))(localStorage.getItem("revenuesExempt") === (undefined))) {

      setList(JSON.parse(localStorage.getItem("list")))


    }
    else if (list.length == 0 && (localStorage.getItem("revenues") != (undefined)) && (localStorage.getItem("fixedExpenses") != (undefined)) && (localStorage.getItem("expenses") != (undefined))(localStorage.getItem("revenuesExempt") != (undefined))) {
      setRevenues(JSON.parse(localStorage.getItem("revenues")))
      setExpenses(JSON.parse(localStorage.getItem("expenses")))
      setFixedExpenses(JSON.parse(localStorage.getItem("fixedExpenses")))
      setRevenuesExempt(JSON.parse(localStorage.getItem("revenuesExempt")))
      setList(JSON.parse(localStorage.getItem("list")))

    }


  }, [isExemption, list, revenues])

  const handleSubmit = (e) => {
    e.preventDefault()
   
    const newItems = {
      id: uuidv4(),
      catalogNum,
      details,
      quantity,
      price,
      discountForProduct,
      amount,
      discount,
      isExemption,
      exemptionAmount,
      invoiceDetails,
      catagory,
      date1,
      date2

    }
    // console.log("تفاصيل الفاتورة : ", invoiceDetails)
    // console.log("cat : ", catagory)
    setDetails("")
    setQuantity(1)
    setPrice("")
    setAmount("")
    setCatalogNum("")
    setInvoiceDetails(invoiceDetails)
    setCatagory(catagory)
    setDiscountForProduct("")
    setIsExemption(false)
    list = list.filter((row) => row.name != "yara")
    setList([...list, newItems])
    setIsEditing(false)
    localStorage.setItem("list", JSON.stringify([...list, newItems]));
    if (catagory == "إيرادات" && !isExemption) {
      setRevenues([...revenues, newItems])
      localStorage.setItem("revenues", JSON.stringify([...revenues, newItems]));

    }
    else if (catagory == "إيرادات" && isExemption) {
      console.log("Exemption Here", catagory, isExemption)

      setRevenuesExempt([...revenuesExempt, newItems])
      localStorage.setItem("revenuesExempt", JSON.stringify([...revenuesExempt, newItems]));


    }

    else if (catagory == "مصاريف عادية" && !isExemption) {
      setExpenses([...expenses, newItems])
      localStorage.setItem("expenses", JSON.stringify([...expenses, newItems]));


    }


    else if (catagory == "مصاريف أصول ثابتة" && !isExemption) {
      setFixedExpenses([...fixedExpenses, newItems])
      localStorage.setItem("fixedExpenses", JSON.stringify([...fixedExpenses, newItems]));


    }


    numFocus.current.focus();


  }

  // Calculate items amount function
  useEffect(() => {
    const calculateAmount = (amount) => {

      let dis = (discountForProduct / 100)
      let rs = ((price - (price * dis)) * quantity)
      setAmount(rs)
      //console.log(quantity,"quantity",price,"price",discountForProduct,"discount",amount,"amout")
      //  console.log("result",(quantity * (price - (price * discountForProduct / 100 ))))
    }

    calculateAmount(amount)
  }, [amount, price, quantity, setAmount, discountForProduct, setDiscountForProduct, perTax])

  // Calculate total amount of items in table
  useEffect(() => {
    // let rows = document.querySelectorAll(".amount")
    // let sum = 0

    // for (let i = 0; i < rows.length; i++) {
    //   if (rows[i].className === "amount") {
    //     sum += isNaN(rows[i].innerHTML) ? 0 : parseInt(rows[i].innerHTML)
    //     setTotal(sum)
    //     console.log(sum,"total")
    //   }
    // }
    // ams ==>amounts
    let ams = 0
    let exmpams = 0
    list.map((item) => {
      if (item.isExemption) {
        exmpams += item.amount
      }
      else {
        ams += item.amount
      }
      setAmounts(ams)
      setExemptionAmount(exmpams)
      // console.log(ams,"amounts")
      // console.log(exmpams,"Expitionamounts")
      // console.log(perTax,"perTaxs")

    })

    // taf ==> total after discount
    let taf = 0;
    taf = (ams * 1 - (ams * 1 * (discount / 100)))
    setTotalAfDiscount(taf)
    // console.log(totalAfDiscount,"totalADiscoi=unt")

    // tx ==> tax
    let tx = 0
    tx = (taf * (perTax / 100)).toFixed(2)
    setTax(tx)

    let ttaftx = ((tx * 1 + taf * 1))
    setTotalAfTax(ttaftx)

    // tt ==> total
    let tt = 0;
    tt = taf + tx * 1
    setTotal(tt + exemptionAmount)
  }, [amount, discountForProduct, price, quantity, list, amounts, discount, perTax])

  // Edit function
  const editRow = (id) => {
    setIsEditing(true)
    if(list.length == 1)
    {
      let newi = {name:"yara"}
      list.push(newi)
    }
    const editingRow = list.find((row) => row.id === id)
    setList(list.filter((row) => row.id !== id))
    localStorage.setItem("list", JSON.stringify(list.filter((row) => row.id !== id)))
    setDetails(editingRow.details)
    setQuantity(editingRow.quantity)
    setPrice(editingRow.price)
    setCatalogNum(editingRow.catalogNum)
    setIsExemption(editingRow.isExemption)
    setDiscountForProduct(editingRow.discountForProduct)
    setInvoiceDetails(editingRow.invoiceDetails)
    setCatagory(editingRow.catagory)
    setDate1(editingRow.date1)
    setDate2(editingRow.date2)
    if (catagory == "إيرادات" && !isExemption) {
      setRevenues(revenues.filter((row) => row.id !== id))
      localStorage.setItem("revenues", JSON.stringify(revenues.filter((row) => row.id !== id)))


    }
    else if (catagory == "إيرادات" && isExemption) {
      localStorage.setItem("revenuesExempt", JSON.stringify(revenuesExempt.filter((row) => row.id !== id)))
      setRevenuesExempt(revenuesExempt.filter((row) => row.id !== id))

    }

    else if (catagory == "مصاريف عادية" && !isExemption) {
      localStorage.setItem("expenses", JSON.stringify(expenses.filter((row) => row.id !== id)))
      setExpenses(expenses.filter((row) => row.id !== id))
    }

    else if (catagory == "مصاريف أصول ثابتة" && !isExemption) {

      localStorage.setItem("fixedExpenses", JSON.stringify(fixedExpenses.filter((row) => row.id !== id)))
      setFixedExpenses(fixedExpenses.filter((row) => row.id !== id))
    }



  }
  const handleClear = () => {
    window.localStorage.removeItem("list");
    window.localStorage.removeItem("revenues");
    window.localStorage.removeItem("revenuesExempt");
    window.localStorage.removeItem("fixedExpenses");
    window.localStorage.removeItem("expenses");

    window.location.reload(true);
  }


  // Delete function
  const deleteRow = (id) => {
     
    let x =list.filter((row) => row.id == id)
    isExemption = x[0].isExemption
    if(list.length ==1)
    {
      handleClear()
    }
    else
    {
      setList(list.filter((row) => row.id !== id))
      localStorage.setItem("list", JSON.stringify(list.filter((row) => row.id !== id)))
      if (catagory == "إيرادات" && !isExemption) {
        setRevenues(revenues.filter((row) => row.id !== id))
        localStorage.setItem("revenues", JSON.stringify(revenues.filter((row) => row.id !== id)))
       
        //console.log("category , false : ", catagory)
  
      }
      else if (catagory == "إيرادات" && isExemption) {
        setRevenuesExempt(revenuesExempt.filter((row) => row.id !== id))
        localStorage.setItem("revenuesExempt", JSON.stringify(revenuesExempt.filter((row) => row.id !== id)))
        //console.log("category , true : ", catagory)
      }
  
      else if (catagory == "مصاريف عادية" && !isExemption) {
        setExpenses(expenses.filter((row) => row.id !== id))
        localStorage.setItem("expenses", JSON.stringify(expenses.filter((row) => row.id !== id)))
        //console.log("category , flase : ", catagory)
  
      }
  
      else if (catagory == "مصاريف أصول ثابتة" && !isExemption) {
        setFixedExpenses(fixedExpenses.filter((row) => row.id !== id))
        localStorage.setItem("fixedExpenses", JSON.stringify(fixedExpenses.filter((row) => row.id !== id)))
        //console.log("category , false : ", catagory)
  
      }
    }
 


  }




  return (
    <div className='row card'>
      <div className='row card-body'>


        <form onSubmit={handleSubmit}>
          <div className="table-responsive">
            <table className="table table-hover table-white">
              <thead>
                <tr >
                  {/* <th style={{width: '20px'}}>#</th> */}
                  <th>رقم الفاتورة </th>
                  <th className="col-md-2"> تفاصيل الفاتورة </th>
                  <th className="col-md-2 ">نوع القيد </th>
                  <th >تاريخ الفاتورة </th>
                  <th >تاريخ الاستحقاق </th>
                  {/* <th  >الكمية </th> */}
                  <th  >قيمة الفاتورة  </th>
                  {/* <th title='ضريبة القيمة المضافة ' style={{width: '80px'}} >ض.ق.م</th> */}
                  {/* <th >خصم %</th> */}
                  <th>مجموع</th>
                  <th title='معفي من ضريبة القيمة المضافة '  >معفي من ض.ق.م  </th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                        <td>1</td>
                        <td>
                          <input
                           className="form-control"
                            type="text"
                             style={{minWidth: '150px'}}
                             onChange={e=>setCatalogNum(e.target.value)}
                             />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            type="text" 
                            style={{minWidth: '150px'}} 
                            onChange={e=>setDetails(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            onChange={e=>setPrice(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            style={{width: '80px'}}
                             type="text"
                             onChange={e=>setQuantity(e.target.value)} 
                             />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            readOnly 
                            style={{width: '120px'}} 
                            type="text" 
                            value={(amount)}
                            />
                        </td>
                        <td onClick={()=> addItem()}><a href="javascript:void(0)" className="text-success font-18 btn" title="Add"><i className="fa fa-plus" /></a></td>
                      </tr> */}
                {/* <tr>
                        <td>2</td>
                        <td>
                          <input className="form-control" type="text" style={{minWidth: '150px'}} />
                        </td>
                        <td>
                          <input className="form-control" type="text" style={{minWidth: '150px'}} />
                        </td>
                        <td>
                          <input className="form-control" style={{width: '100px'}} type="text" />
                        </td>
                        <td>
                          <input className="form-control" style={{width: '80px'}} type="text" />
                        </td>
                        <td>
                          <input className="form-control" readOnly style={{width: '120px'}} type="text" />
                        </td>
                        <td><a href="javascript:void(0)" className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a></td>
                      </tr> */}
                <tr>
                  {/* <td>1</td> */}
                  <td>
                    <input
                      required
                      className="form-control"
                      type="text"
                      ref={numFocus}
                      value={catalogNum}
                      onChange={e => setCatalogNum(e.target.value)}
                    />
                  </td>
                  {/* <td>
                  <div className="form-group form-focus select-focus  w-100" style={{ padding: "0px" }}>
                    <select id="currency-two"
                      className='select floating ex-select w-100'
                      onChange={(e)=> (setInvoiceDetails(e.target.value))} 
                      value={invoiceDetails} 
                    >
                      <option value="فاتورة ضريبية">فاتورة ضريبية </option>
                      <option value="وصل بدون ضريبة"> وصل بدون ضريبة</option>
                      <option value="فاتورة وصل"> فاتورة وصل</option>
                      <option value="قيد">قيد</option>
                      <option value="قيد عكسي">قيد عكسي </option>
                      <option value="عمولة">عمولة </option>

                    </select>
                    <br></br>
                    <br></br>

                  </div>
                </td> */}
                  <td>
                    <select
                      id="tax"
                      className='select floating  tax-select invoice-select'
                      name='tax'
                      // value={contact.tax}
                      onChange={(e) => (setInvoiceDetails(e.target.value))}

                    //  onChange={handleChange}
                    >
                      <option value="فاتورة ضريبية">فاتورة ضريبية </option>
                      <option value="وصل بدون ضريبة"> وصل بدون ضريبة</option>
                      <option value="فاتورة وصل"> فاتورة وصل</option>
                      <option value="فاتورة مقاصة">فاتورة مقاصة </option>

                      <option value="قيد">قيد</option>
                      <option value="قيد عكسي">قيد عكسي </option>
                      <option value="عمولة">عمولة </option>

                    </select>
                  </td>
                  <td>
                    <select
                      id="tax2"
                      className='select floating  tax-select invoice-select'
                      name='tax2'
                      // value={contact.tax}
                      onChange={(e) => (setCatagory(e.target.value))}
                    //  onChange={handleChange}
                    >
                      <option value="إيرادات">إيرادات</option>
                      <option value="مصاريف عادية"> مصاريف عادية</option>
                      <option value="مصاريف أصول ثابتة"> مصاريف أصول ثابتة </option>

                    </select>
                  </td>
                  <td>
                    <div className="form-group">
                      <div className="">
                        <input
                          required

                          className="form-control " type="date"
                          style={{ fontSize: "20px" }}
                          value={date1}
                          onChange={(e) => setDate1(e.target.value)}
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="form-group">
                      <div className="">
                        <input
                          required

                          className="form-control " type="date" style={{ fontSize: "20px" }}
                          value={date2}
                          onChange={(e) => setDate2(e.target.value)}
                        />
                      </div>
                    </div>
                  </td>
                  {/* <td>
                  <input
                    required
                    className="form-control"
                    type="text"
                    value={quantity}
                    onChange={e => setQuantity(e.target.value)}
                  />
                </td> */}
                  <td>
                    <input

                      className="form-control"
                      // style={{ width: '100px' }}
                      type="text"
                      value={price}
                      required
                      onChange={e => setPrice(e.target.value)}

                    />
                  </td>

                  {/* <td>
                            <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            value={singleTax}
                            // onChange={e=> setDiscountForProduct(e.target.value)}
                            />
                        </td> */}
                  {/* <td>
                  <input
                    className="form-control"
                    type="text"
                    value={discountForProduct}
                    onChange={e => setDiscountForProduct(e.target.value)}
                  />
                </td> */}
                  <td>
                    <input

                      className="form-control"
                      readOnly
                      // style={{ width: '120px' }}
                      type="text"
                      value={(amount)}
                    />
                  </td>
                  <td>
                    <input
                      onChange={(e) => {
                        setIsExemption(e.target.checked)
                      }
                      }
                      style={{ width: '80px' }}
                      type="checkbox"
                      checked={isExemption}

                    />
                    <span className='pb-5' style={{ color: 'white' }}>{isExemption ? 'نعم' : 'لا'}</span>
                  </td>
                  {/* <td>
                            <a 
                            // href="javascript:void(0)"
                             className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a></td> */}
                </tr>
                <tr>
                  <td
                    colSpan={10}
                    className='text-center'
                  >
                    <button
                      type='submit'
                      //  onClick={handleSubmit}
                      // href="javascript:void(0)"
                      className="text-success font-18 btn"

                    >
                      {isEditing ? <span className='fs-4' title="تعديل ">
                        تعديل  <BiEdit size={40} /> </span> : <span className='fs-4' title="إضافة ">
                        إضافة  <CgPlayListAdd size={40} /> </span>}</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
        <div className="table-responsive">
          <table className="table table-hover table-white">
            <thead>
              <tr>
                <th style={{ width: '20px' }}>#</th>
                <th >رقم الفاتورة </th>
                <th className="col-md-2 ">تفاصيل الفاتورة </th>
                <th className="col-md-2 ">نوع القيد </th>

                <th>تاريخ الفاتورة </th>
                <th>تاريخ الاستحقاق </th>

                {/* <th  >الكمية </th> */}
                <th >قيمة الفاتورة  </th>
                {/* <th  >خصم %  </th> */}
                <th>مجموع</th>
                <th title='معفي من ضريبة القيمة المضافة '  >معفي من ض.ق.م  </th>
                <th> تعديل</th>
                <th>حذف </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (

                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      type="text"
                      // style={{ minWidth: '150px' }}
                      value={item.catalogNum}
                    />
                  </td>
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      type="text"
                      // style={{ minWidth: '150px' }}
                      value={item.invoiceDetails}
                    />
                  </td>
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      // style={{ width: '100px' }}
                      type="text"
                      value={item.catagory}
                    />
                  </td>
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      // style={{ width: '100px' }}
                      type="text"
                      value={item.date1}
                    />
                  </td>
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      // style={{ width: '100px' }}
                      type="text"
                      value={item.date2}
                    />
                  </td>
                  {/* <td>
                <input
                readOnly
                  className="form-control"
                  style={{ width: '80px' }}
                  type="text"
                  value={item.quantity}
                />
              </td> */}
                  <td>
                    <input
                      readOnly
                      className="form-control"
                      // style={{ width: '100px' }}
                      type="text"
                      value={item.price}
                    />
                  </td>

                  {/* <td>
                <input
                readOnly
                  className="form-control"
                  type="text"
                  value={item.discountForProduct}
                />
              </td> */}
                  <td>
                    <input
                      readOnly
                      className="form-control amount"

                      // style={{ width: '120px' }}
                      type="text"
                      value={item.amount}
                    />
                  </td>
                  <td>
                    <a className="text-success font-18 " >
                      {item.isExemption ? <BsCheckLg color='yellow' size={30} /> : ""}
                    </a>
                  </td>
                  <td >
                    <a
                    onClick={(e) => { editRow(item.id)}}
                    // onClick={() => (editRow(item.id, item.isExemption))}
                      // href="javascript:void(0)"
                      className="text-success font-18 pointer-mk" title="تعديل"><BiEdit size={30} />
                    </a>
                  </td>
                  <td >
                    <a 
                    onClick={(e) => (deleteRow(item.id))}
              
                      // href="javascript:void(0)"
                      className="text-danger font-18 pointer-mk" title="حذف"><MdDeleteOutline size={30} />
                    </a>
                  </td>
                  {/* <td>
                          <a 
                          // href="javascript:void(0)"
                           className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a>
                           </td> */}
                </tr>
              ))}

            </tbody>
          </table>
        </div>
        {/* <div className="table-responsive">
          <table className="table  table-white">
            <tbody>
              <tr>
                <td style={{ "width": "100%" }} />
                <td />
                <td />
                <td />
                <td></td>
                <td className=" page-title "> المجموع <span className='privacy fs-6'>(الملزم)</span> </td>
                <td className="page-title" style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{amounts.toLocaleString()}</td>
              </tr>


              <tr>
                        <td colSpan={5} className="text-right page-title">
                          خصم %
                        </td>
                        <td style={{textAlign: 'right', paddingRight: '30px', width: '230px'}}>
                          <input className="form-control text-right" type="text" onChange={e=> setDiscount(e.target.value)}/>
                        </td>
                      </tr>

              <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td className="text-right page-title"> المجموع بعد الخصم </td>
                        <td className="page-title" style={{textAlign: 'right', paddingRight: '30px', width: '230px'}}>{totalAfDiscount.toLocaleString()}</td>
                      </tr>
              <tr>
                <td style={{ "width": "100%" }}></td>
                <td />
                <td />
                <td />
                <td></td>

              </tr>

              <tr >
                <td style={{ "width": "100%" }} />
                <td />
                <td />
                <td />
                <td></td>
                <td className=" page-title">المجموع <span className='privacy fs-6 '>(شامل الضريبة)</span> </td>
                <td className="page-title" style={{ textAlign: 'right', paddingRight: '30px', width: '230px', borderBottom: "gray 2px solid" }}>{totalAfTax.toLocaleString()}</td>
              </tr>
              <tr>



              </tr>
              <tr>
                <td style={{ "width": "100%" }} />
                <td />
                <td />
                <td />
                <td />
                <td className=" page-title"> المجموع   <span className='privacy fs-6'>(المعفي)</span>  </td>
                <td className="page-title" style={{ textAlign: 'right', paddingRight: '30px', width: '230px', borderBottom: "gray 2px solid" }}>{exemptionAmount.toLocaleString()}</td>
              </tr>
              <tr>
                <td style={{ "width": "100%" }} />
                <td />
                <td />
                <td />
                <td />
                <td className="fs-1 text-primary " style={{ textAlign: 'right', fontWeight: 'bold' }}>
                  المجموع الكلي
                </td>
                <td className="page-title text-primary fs-1 result-mk" style={{
                  textAlign: 'right', paddingRight: '30px', fontWeight: 'bold', fontSize: '16px', width: '230px', borderBottom: "#FF9B44 8px double"
                }}>
                  <span className='page-title text-primary fs-1 bold result-mk'
                  >
                    {total.toLocaleString()}</span>  <BiShekel />
                </td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  )
}
