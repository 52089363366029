
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

// import MyCompanyDetails from '../../../data/myCompanyDetails'
import { massportallogo } from '../../../Entryfile/imagepath';

import Invoice from './CreateInvoice'
class CreateJv extends Component {
   render() {
      return ( 
        
      <div className="page-wrapper">
        <Helmet>
            <title>MassPortal - قيود مصاريف و مشتريات     </title>
            <meta name="description" content="Login page"/>					
        </Helmet>
        <Invoice 
        logo={massportallogo.Logo} 
     
        />
    </div>
      );
   }
}

export default CreateJv;
