/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Itt21 from '../../../components/Tables/basic21';
import Itt22 from '../../../components/Tables/basic22/itt22';
import { TaxCalculatorComponent21 } from '../../../calculators/components/TaxCalculatorComponent21';
import { TaxCalculatorComponent22 } from '../../../calculators/components/TaxCalculatorComponent22';
export default class LawYear extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | قانون الاجازة السنوية  </title>
					<meta name="description" content="MassPortal - قانون الاجازة السنوية " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>قانون الاجازة السنوية</h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>


						<div className="row">

							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<h2 >قانون الإجازة السنوي يعتبر قانون اجتماعي الذي يقرر: </h2>
								<br></br>
								<ol className='privacy-ol'>
									<li>
										<p>
											مدة الإجازة السنوية للموظف حسب مدة خدمة وسنوات العمل.

											<br></br>
										</p>
									</li>
									<li>
										<p>
											القيمة النقدية التي تدفع للموظف بدل أيام إجازته.


										</p>
									</li>

								</ol>
								<ul className='privacy-ol'>
									<li>
										<h4>
											يفسر القانون أيضا الأيام الغير محسوبة كأيام إجازة, وما هي الطرق التي تعطى بها الإجازة.


											<br></br>
										</h4>

									</li>
									<li>
										<h4>
											هدف القانون الأول هو جعل الموظف استغلال أيام إجازته وليس إعطاء علاوة في قيمة الراتب.

											<br></br>
										</h4>

									</li>

								</ul>
								<h2 >حقوق مدة الإجازة السنوية  </h2>
								<br></br>


							</div>

							<div className="col-lg-10">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy">حسب قانون الإجازة السنوية</h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead>
													<tr>
														<th> مدة الخدمة </th>
														<th>استحقاق</th>
														<th></th>
														<th>استحقاق بأيام عمل فعلي  ( صافي )</th>
														{/* <th>الضريبة </th>
												<th>الضريبة التراكمية</th> */}

													</tr>
												</thead>
												<tbody>
													<tr>
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														<td className='privacy '>6 أيام للأسبوع </td>
														<td className='privacy '>5 أيام للأسبوع </td>

													</tr>
													<tr>
														<td>4 <span className='privacy'>-</span> 1</td>
														<td>14</td>
														<td>12</td>
														<td>10</td>
														{/* <td>1.013</td> */}
													</tr>
													<tr>
														<td>5 </td>
														<td>16</td>
														<td>14</td>
														<td>12</td>
													</tr>

													<tr>
														<td>6</td>
														<td>18</td>
														<td>16</td>
														<td>14</td>
													</tr>
													<tr>
														<td>7</td>
														<td>21</td>
														<td>18</td>
														<td>15</td>
													</tr>
													<tr>
														<td>8</td>
														<td>22</td>
														<td>19</td>
														<td>16</td>
													</tr>
													<tr>
														<td>9</td>
														<td>23</td>
														<td>20</td>
														<td>17</td>
													</tr>
													<tr>
														<td>10</td>
														<td>24</td>
														<td>21</td>
														<td>18</td>
													</tr>
													<tr>
														<td>11</td>
														<td>25</td>
														<td>22</td>
														<td>19</td>
													</tr>
													<tr>
														<td>12</td>
														<td>26</td>
														<td>23</td>
														<td>20</td>
													</tr>
													<tr>
														<td>13</td>
														<td>27</td>
														<td>24</td>
														<td>20</td>
													</tr>
													<tr>
														<td> <span className='privacy'>+</span> 14</td>
														<td>28</td>
														<td>24</td>
														<td>20</td>
														{/* <td>1.013</td> */}
													</tr>

												</tbody>
											</table>

										</div>

									</div>
									<hr></hr>
									<ul className='privacy-ol'>

										<h4 className='privacy text-center'>
											حسب قانون الإجازة السنوية, يضاف يوم استراحة واحد لكل سبعة (7) أيام إجازة.
										</h4>

									</ul>
								</div>
								<h2 >أيام غيابات عن العمل الغير محسوبة بأيام الإجازة السنوية: </h2>
								<br></br>
								<ol className='privacy-ol'>
									<li>
										<p>
											أيام العيد.
											<br></br>
										</p>
									</li>
									<li>
										<p>
											إجازة ولادة.
										</p>
									</li>
									<li>
										<p>
											أيام إصابة, أو أيام مرضية.</p>
									</li>
									<li>
										<p>
											أيام حداد.</p>
									</li>
									<li>
										<p>
											أيام إضراب.
										</p>
									</li>

									<li>
										<p>
											أيام إعلام مسبق للفصل (إلا إذا تعدى أل 14 يوم).

										</p>
									</li>


								</ol>
						
									<ul className='privacy-ol'>
									<li>
										<h4 className='privacy '>										
											اذا دخلت الأيام المذكورة أعلاه على أيام الإجازة السنوية, فتلقائيا تتوقف الإجازة السنوية, ويجب إتمام الإجازة السنوية في وقت لاحق يفضل قبل نهاية السنة.										
										</h4>
									</li>

									</ul>
							</div>

							{/* =============================================================== */}
							<div className="col-lg-10">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy">احتساب القيمة النقدية بدل الاجازة </h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead>
													<tr>
														<th> نوع الأجير  </th>
														<th>قيمة الاجازة </th>
													
														{/* <th>الضريبة </th>
												<th>الضريبة التراكمية</th> */}

													</tr>
												</thead>
												<tbody>
													<tr>
														<td >أجير شهري </td>
														<td >الراتب العادي ( 1 ) 30 يوم </td>
													

													</tr>
													<tr>
														<td>أجير غير شهري </td>
														<td>أيام الاجازة  x  مجموع الرواتب في الثلاثة أشهر الأخيرة ( 2 ) 90 يوم </td>
														
														{/* <td>1.013</td> */}
													</tr>
													
												</tbody>
											</table>

										</div>

									</div>
									<hr></hr>
									<ul className='privacy-ol'>

										<li>
										<h4 className='privacy '>
										الراتب العادي المقرر للموظف للفترة الزمنية المحددة, لو لم يخرج لإجازة وأكمل عمله بشكل عادي.										
										</h4>
										</li>
										<li>
										<h4 className='privacy'>
										الراتب المقرر لاحتساب قيمة الإجازة فهو الراتب المحسوب فقط بدل ساعات العمل العادية إلا إذا تم

الاتفاق على غير ذلك, حيث لا تحسب في قيمة الراتب مصاريف لا تدفع في فترة الإجازة.

								 </h4>
										</li>

									</ul>
								</div>
								
								
							</div>

						</div>



						{/* /Content End */}
					</div>

					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
