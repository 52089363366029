import { TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import taxData from "../mass-modules/mass-taxes/national-insurance/data/output";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
import ReactTooltip from "react-tooltip";
import { useAuthContext } from "../hooks/useAuthContext";
import { projectFirestore } from "../firebase/config";
import { massportallogo } from "../Entryfile/imagepath";
export function CalculatorRtX(props) {
  const [taxYears, setTaxYears] = useState(0);
  const [donationsyears, setDonationsYears] = useState(0);
  const [t_value, setT_value] = useState(0);
  const [insurance_benefits, setInsurance_benefits] = useState(0);
  const [annualyears, setannualyears] = useState(0);
  const [points, setpoints] = useState(0);
  const [tax, setTax] = useState(0);
  const [show, setShow] = useState(false);
  const [paid, setPaid] = useState("");
  const [year, setyear] = useState(0);
  const [personalincome, setpersonalincome] = useState(0);
  const [betuahlemui, setbetuahlemui] = useState(0);
  const [coronapays, setcoronapays] = useState(0);
  const [anotherincome, setanotherincome] = useState(0);
  const [tzkeat25, settzkeat25] = useState(0);
  const [discountdource, setdiscountdource] = useState(0);
  const [taxpays, settaxpays] = useState(0);
  const [const15, setconst15] = useState(0);
  const [const25, setconst25] = useState(0);
  const [const35, setconst35] = useState(0);
  const [betuahlemui52, setbetuahlemui52] = useState(0);
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [totalDiscounts, setTotalDiscounts] = useState(0);
  const [IncomeWithTax, setIncomewithTax] = useState(0);
  const [totalTzkeat, setTotalTzkeat] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalReturn, setTotalReturn] = useState(0);
  const [TaxMustPay, setTaxMustPay] = useState(0);
  const[currentYear,setCurrentYear] = useState(0)
  const printRef = React.useRef();
  const [isActive, setIsActive] = useState(false);
  // HOOKS
  const { user } = useAuthContext()
  // const {testing } =usePrintPdf();
  const [companyName, setCompanyName] = useState(' ')
  //  getCompanyName();
  useEffect(  () => {
    const getCompanyName = () => {
      if (user != null) {

        const mydata =  projectFirestore.collection('mp-clients').doc(user.uid).get().then((docRef) => { setCompanyName(docRef.data().companyName) })
          .catch((error) => { })
      }
      else
        setCompanyName(" ")
    }

    getCompanyName();
  })

  useEffect(()=>{
       //  Current Date 
       const d = new Date().toLocaleString() + "";
       let current = d.split(',');
       const today = current[0];
       const todaySplitArr = today.split('/');
       const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
      //  console.log("today is : ",  (parseInt(todaySplitArr[2])))
       setCurrentYear( (parseInt(todaySplitArr[2])))
  })
  let pricePoint = taxData.taxData[year].creditPoint;
  let percPension =
    taxData.taxData[year].pension.taxRelief.employee.taxCreditRate / 100;

  let num = [
    taxData.taxData[year].incomeTax.band1.max / 12,
    taxData.taxData[year].incomeTax.band2.max / 12,
    taxData.taxData[year].incomeTax.band3.max / 12,
    taxData.taxData[year].incomeTax.band4.max / 12,
    taxData.taxData[year].incomeTax.band5.max / 12,
    taxData.taxData[year].incomeTax.band6.max / 12,
    taxData.taxData[year].incomeTax.band7.max / 12,
  ];

  let percentage = [
    taxData.taxData[year].incomeTax.band1.rate / 100,
    taxData.taxData[year].incomeTax.band2.rate / 100,
    taxData.taxData[year].incomeTax.band3.rate / 100,
    taxData.taxData[year].incomeTax.band4.rate / 100,
    taxData.taxData[year].incomeTax.band5.rate / 100,
    taxData.taxData[year].incomeTax.band6.rate / 100,
    taxData.taxData[year].incomeTax.band7.rate / 100,
  ];

  let np = [
    num[0] * percentage[0],
    (num[1] - num[0]) * percentage[1],
    (num[2] - num[1]) * percentage[2],
    (num[3] - num[2]) * percentage[3],
    (num[4] - num[3]) * percentage[4],
    (num[5] - num[4]) * percentage[5],
  ];

  const handleSubmit = (evt) => {

    evt.preventDefault();

    let id = Math.random();
    //  مجموع الدخل
    let income =
      annualyears * 1 +
      coronapays * 1 +
      betuahlemui * 1 +
      personalincome * 1 +
      anotherincome * 1;
    //  مجموع الدخل الذي عليه ضريبة ثابتة
    let constIncome = const15 * 1 + const25 * 1 + const35 * 1

    //  مجموع الدخل + مجموع الدخل الذي عليه ضريبة ثابتة
    let totalIncome = income + constIncome
    setTotalIncomes(totalIncome);

    //  مجموع الخصميات
    let totalDiscounts = betuahlemui52 * 0.52 * 1;
    setTotalDiscounts(totalDiscounts);


    //  الدخل الملزم بالضريبة
    let Incomewithtax = totalIncome - totalDiscounts
    setIncomewithTax(Incomewithtax);

    // نقاط التزكية السنوية
    let total_Points_PricePoints = Math.round(pricePoint * points * 12);

    // مجموع التزكيات
    let totaltzkeat = total_Points_PricePoints + insurance_benefits * percPension + donationsyears * 0.35 + tzkeat25 * 0.25
    setTotalTzkeat(totaltzkeat)


    setTax(calculateTax(parseFloat(((income - totalDiscounts) / 12))));
    setShow(true)
    const timer = setTimeout(() => window.scroll(0, 2000), 100);
    function calculateTax(income) {
      let tax;

      if (income > 0 && income <= num[0]) {
        tax = income * percentage[0];
      } else if (income > num[0] && income <= num[1]) {
        tax = (income - num[0]) * percentage[1] + np[0];
      } else if (income > num[1] && income <= num[2]) {
        tax = (income - num[1]) * percentage[2] + (np[0] + np[1]);
      } else if (income > num[2] && income <= num[3]) {
        tax = (income - num[2]) * percentage[3] + (np[0] + np[1] + np[2]);
      } else if (income > num[3] && income <= num[4]) {
        tax =
          (income - num[3]) * percentage[4] + (np[0] + np[1] + np[2] + np[3]);
      } else if (income > num[4] && income <= num[5]) {
        tax =
          (income - num[4]) * percentage[5] +
          (np[0] + np[1] + np[2] + np[3] + np[4]);
      } else if (income > num[5]) {
        tax =
          (income - num[5]) * percentage[6] +
          (np[0] + np[1] + np[2] + np[3] + np[4] + np[5]);
      }
      // الضريبة السنوية
      tax = Math.round(tax * 12 +
        const15 * 0.15 +
        const25 * 0.25 +
        const35 * 0.35);
      setTotalTax(tax)

      //الضريبة المستحقة 
      let mustTax = tax - totaltzkeat
      if (tax - total_Points_PricePoints < 0) {
        mustTax = 0;
      }
      else if (tax - insurance_benefits * percPension < 0) {
        mustTax = 0
      } else {
        mustTax = tax - totaltzkeat
      }
      setTaxMustPay(mustTax)
      // الضريبة المدفوعة
      let paid = taxYears * 1 + taxpays * 1 + discountdource * 1
      setPaid(paid)
      // الارجاع \ رصيد
      let returN = mustTax - paid
      setTotalReturn(returN)

    }
  };

  // Thousands Commas Function 
  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  }

  // Page refresh Function
  const newCalc = () => {
    window.location.reload();
  }


  //  PDF Prin Function 
  const handleDownloadPdf = async () => {

    const iaactive = true;
    await setIsActive(iaactive);
    //  Current Date 
    const d = new Date().toLocaleString() + "";
    let current = d.split(',');
    const today = current[0];
    const todaySplitArr = today.split('/');
    const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
    // Current Date 
    // PDF Header And Footer Values 
    const header = todayFrm;
    const element = printRef.current;
    element.style.background = '#FFFFFF';
    element.style.color = '#212529';
  
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();

    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    //  Header  - Logo
    pdf.addImage(massportallogo, 'png', 90, 5, 20, 20);
    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 25, pdfWidth, pdfHeight);
    // Header - Date 
    pdf.text(header, 2, 29, { baseline: 'top' });
    // Footer

    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const footer = d + '             www.mass-portal.com';
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight-2, { baseline: 'bottom' });
    pdf.setFontSize(16);
    pdf.save('ضريبة الدخل .pdf');
  };


  return (
    <div className="row">
      {/* <div className="col-sm-12 col-md-2"></div> */}
      <div className="col-xl-6 d-flex div-center ">
        <div className="card flex-fill">
          <div className="card-header">
            <h3 className="card-title mb-0 privacy ">احتساب ضريبة الدخل </h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">اختر السنة </label>
                <div className="col-lg-8">
                  <select
                    onChange={(e) => setyear(e.target.value)}
                    className="btn year-select year-big-select"
                  >
                    {taxData.taxData.map((year, index) => (
                      <option className="text-dark year-text-dark" value={index} key={index}>
                        {year.taxYear}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <h3 className="mb-1 privacy">الدخل السنوي</h3>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  دخل شخصي - أرباح سنوية ( 150 )
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setpersonalincome(e.target.value);
                    }}
                    value={personalincome}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  رواتب سنوية ( 172 , 158 )
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setannualyears(e.target.value);
                    }}
                    value={annualyears}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  مخصصات تأمين وطني ( 258 )
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setbetuahlemui(e.target.value);
                    }}
                    value={betuahlemui}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  هبات كورونا ( 001 )
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setcoronapays(e.target.value);
                    }}
                    value={coronapays}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label" dir="ltr">
                  دخل ملزم ثابت %15{" "}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setconst15(e.target.value);
                    }}
                    value={const15}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label" dir="ltr">
                  دخل ملزم ثابت %25{" "}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setconst25(e.target.value);
                    }}
                    value={const25}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label" dir="ltr">
                  دخل ملزم ثابت %35{" "}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setconst35(e.target.value);
                    }}
                    value={const35}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  دخل اخر ( 9999 )
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setanotherincome(e.target.value);
                    }}
                    value={anotherincome}
                  />
                </div>
              </div>
              <br />
              <hr />
              <br />
              <h3 className=" mb-1 privacy">خصميات</h3>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  {" "}
                  دفعات تأمين وطني مستقل - 52% ( 30 )
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setbetuahlemui52(e.target.value);
                    }}
                    value={betuahlemui52}
                  />
                </div>
              </div>
              <hr />
              <h3 className="mb-1 privacy">التزكيات</h3>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label"> مجموع التزكيات  </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setpoints(e.target.value);
                    }}
                    value={points}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  {" "}
                  قيمة نقاط تزكية أخرى{" "}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setT_value(e.target.value);
                    }}
                    value={t_value}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label" dir="ltr">
                  {" "}
                  مخصصات تأمين تقاعدي ( 45,86 ){" "}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setInsurance_benefits(e.target.value);
                    }}
                    value={insurance_benefits}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  تزكيات 25% ( 47 ){" "}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      settzkeat25(e.target.value);
                    }}
                    value={tzkeat25}
                  />
                </div>
              </div>


              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  تبرعات سنوية ( 37 )
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setDonationsYears(e.target.value);
                    }}
                    value={donationsyears}
                  />
                </div>
              </div>



              <hr />
              <h3 className="mb-2 privacy">الضريبة المدفوعة في مدار السنة</h3>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  ضريبة سنوية ( 42 )
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setTaxYears(e.target.value);
                    }}
                    value={taxYears}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  خصم بالمصدر ( 40 ){" "}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      setdiscountdource(e.target.value);
                    }}
                    value={discountdource}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                  سلفيات ضريبية الدخل مدفوعة{" "}
                </label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      settaxpays(e.target.value);
                    }}
                    value={taxpays}
                  />
                </div>
              </div>

              <div className="text-center">
                <button className="btn btn-primary btn-calc-rtx"> احتسب </button>
              </div>
            </form>
          </div>
        </div>
      </div>




      {/* ================ SHOW HERE ================= */}

      {show && <div className=" container">
        <div className="content container-fluid">
          <div className="page-header ">

            <div className="">
              <div className='row'>
                <div className="col-sm-12 col-md-10">


                </div>
                {/* <div className="col-sm-12 col-md-1"></div> */}
                <div className="col-sm-12 col-md-2">
                  <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                    <AiOutlineFilePdf size={40} className='privacy' />
                  </button>
                  <button className="btn " data-tip data-for="excel-tip">
                    <SiMicrosoftexcel size={40} className='privacy' />
                  </button>

                </div>

              </div>

              <div className={isActive?" card-white":"card"} ref={printRef} >
                {/* <div className="card-header">
                  <h4 className="card-title mb-0 text-center privacy"> </h4>
                </div> */}
                <br></br>
                <div className="row">
                  <br></br>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? " privacy-black-bold text-left company-marg " : " privacy hide"} id='date-block'>
                      الشركة : <span className="privacy-black active-result"> {user ? companyName : " "}  </span>
                    </h4>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive?"card-title mb-0  text-center privacy-black ":"card-title mb-0  text-center privacy"}>
                      نتائج احتساب ضريبة الدخل 
                <br></br>
                للسنة الضريبية <span className={ isActive?"privacy-black-bold":"privacy-bold"}>         {currentYear - year} </span>
                    </h4>
                  </div>
                  <div className='col-sm-12 col-md-4'>
                    <h4 className={isActive ? "privacy-black-bold text-center " : " privacy hide"} id='date-block'>
                       التاريخ : 

                    </h4>


                  </div>
                </div>
                <div className="card-body">
                  {/* ================================================ */}

                  <div className="row result-div">
                    <br></br>


                    <div className="col-sm-12 col-md-4 ">
                      <label className="label-title">مجموع الدخل </label>

                    </div>
                    <div className="col-sm-12 col-md-3">

                      <label className={isActive?" reuslt-insurance":"privacy reuslt-insurance"}> {commify(totalIncomes)}</label>
                    </div>
                    <div className="col-sm-12 col-md-2 ">
                      <label className="label-title">مجموع الخصميات </label>

                    </div>
                    <div className="col-sm-12 col-md-3">

                      <label className={isActive?" reuslt-insurance":"privacy reuslt-insurance"}> {commify(totalDiscounts)}</label>
                    </div>
                    <div>

                    </div>

                    <div className=" row col-sm-12 col-md-6 border-div">
                      <br></br>
                      <h4 className={ isActive?" mb-0  privacy-black text-center":" mb-0  privacy text-center"}>الدخل الملزم بالضريبة   </h4>
                      <br></br>
                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label>الدخل الملزم بالضريبة   </label>
                      </div>
                      <div className="col-sm-12 col-md-6 ">
                        <br></br>
                        <label className="reuslt-insurance">{commify(IncomeWithTax)}</label>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label>اجمالي الضريبة    </label>
                      </div>
                      <div className="col-sm-12 col-md-6 ">
                        <br></br>
                        <label className={isActive?" reuslt-insurance":"privacy reuslt-insurance"}>{commify(totalTax)}</label>
                      </div>

                      {/* <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>التأمين الوطني   </label>
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                          <br></br>
                          <label className="reuslt-insurance privacy ">hhhhhhhhhh</label>
                        </div> */}
                    </div>
                    <div className="row col-sm-12 col-md-6 border-div">
                      <br></br>
                      <h4 className={isActive?" mb-0  privacy-black text-center":" mb-0  privacy text-center"}>التزكيات    </h4>
                      <br></br>
                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label>مجموع التزكيات   </label>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label className="reuslt-insurance">{commify(totalTzkeat)}</label>
                      </div>



                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label>الضريبة المتسحقة    </label>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label className={isActive?" reuslt-insurance":"privacy reuslt-insurance"}>{commify(TaxMustPay)}</label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2"></div>
                    <div className="col-sm-12 col-md-4  ">
                      <br></br>
                      <label>الضريبة المدفوعة      </label>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <br></br>
                      <label className={isActive?" reuslt-insurance":"privacy reuslt-insurance"}>{commify(paid)}</label>
                    </div>
                    <div className="col-sm-12 col-md-2"></div>
                    <div className="col-sm-12 col-md-4  ">
                      <br></br>
                      <label > الضريبة المستحقة للدفع   <span className={totalReturn > 0 || totalReturn == 0 ? " span-return " : " privacy-green "}> ( ارجاع )</span>     </label>


                    </div>
                    <div className={ isActive?"col-sm-12 col-md-6 result-black":"col-sm-12 col-md-6"}>
                      <br></br>
                      <label className={totalReturn > 0 || totalReturn == 0 ? "reuslt-insurance privacy" : "reuslt-insurance privacy-green "}>{commify(totalReturn)}</label>

                    </div>




                  </div> <br></br>



                </div>
              </div>
              <ReactTooltip id="pdf-tip" place="top" effect="solid">
                طباعة PDF
              </ReactTooltip>
              <ReactTooltip id="excel-tip" place="top" effect="solid">
                طباعة Excel
              </ReactTooltip>
              <div className="form-group row">

                <div className="col-sm-12 col-md-3"></div><br></br><br></br>
                <div className="col-sm-12 col-md-6  ">
                  <br></br>
                  <button className="btn btn-primary naqaha-btn insurance-btn" onClick={newCalc} > احتساب جديد  </button>
                  <br></br> <br></br>
                </div>

                <hr></hr>
              </div>
            </div>

          </div>
        </div>
      </div>}
    </div>
  );
}