import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { BiShekel, BiEdit } from 'react-icons/bi'
import { BsCheckLg } from 'react-icons/bs'
import { CgPlayListAdd } from 'react-icons/cg'
import { MdDeleteOutline } from 'react-icons/md'



export default function TableForm({
  catalogNum,
  setCatalogNum,
  details,
  setDetails,
  quantity,
  setQuantity,
  price,
  setPrice,
  discountForProduct,
  setDiscountForProduct,
  amount,
  setAmount,
  setList,
  total,
  setTotal,
  perTax,
  setPerTax,
  tax,
  setTax,
  amounts,
  setAmounts,
  discount,
  setDiscount,
  totalAfDiscount,
  setTotalAfDiscount,
  isExemption,
  setIsExemption,
  totalAfTax,
  setTotalAfTax,
  exemptionAmount,
  setExemptionAmount,
  list,

}) {
  const [isEditing, setIsEditing] = useState(false)

  useEffect(() => {
    console.log("exemption", isExemption)
  }, [isExemption])

  const handleSubmit = (e) => {
    e.preventDefault()
    const newItems = {
      id: uuidv4(),
      catalogNum,
      details,
      quantity,
      price,
      discountForProduct,
      amount,
      discount,
      isExemption,
      exemptionAmount,
    }
    setDetails("")
    setQuantity(1)
    setPrice("")
    setAmount("")
    setCatalogNum("")
    setDiscountForProduct("")
    setIsExemption(false)
    setList([...list, newItems])
    setIsEditing(false)
    //console.log(list)

  }


  // Calculate items amount function
  useEffect(() => {
    const calculateAmount = (amount) => {

      let dis = (discountForProduct / 100)
      let rs = ((price - (price * dis)) * quantity)
      setAmount(rs)
      //console.log(quantity,"quantity",price,"price",discountForProduct,"discount",amount,"amout")
      //  console.log("result",(quantity * (price - (price * discountForProduct / 100 ))))
    }

    calculateAmount(amount)
  }, [amount, price, quantity, setAmount, discountForProduct, setDiscountForProduct, perTax])

  // Calculate total amount of items in table
  useEffect(() => {
    // let rows = document.querySelectorAll(".amount")
    // let sum = 0

    // for (let i = 0; i < rows.length; i++) {
    //   if (rows[i].className === "amount") {
    //     sum += isNaN(rows[i].innerHTML) ? 0 : parseInt(rows[i].innerHTML)
    //     setTotal(sum)
    //     console.log(sum,"total")
    //   }
    // }
    // ams ==>amounts
    let ams = 0
    let exmpams = 0
    list.map((item) => {
      if (item.isExemption) {
        exmpams += item.amount
      }
      else {
        ams += item.amount
      }
      setAmounts(ams)
      setExemptionAmount(exmpams)
      // console.log(ams,"amounts")
      // console.log(exmpams,"Expitionamounts")
      // console.log(perTax,"perTaxs")

    })

    // taf ==> total after discount
    let taf = 0;
    taf = (ams * 1 - (ams * 1 * (discount / 100)))
    setTotalAfDiscount(taf)
    // console.log(totalAfDiscount,"totalADiscoi=unt")

    // tx ==> tax
    let tx = 0
    tx = (taf * (perTax / 100)).toFixed(2)
    setTax(tx)

    let ttaftx = ((tx * 1 + taf * 1))
    setTotalAfTax(ttaftx)

    // tt ==> total
    let tt = 0;
    tt = taf + tx * 1
    setTotal(tt + exemptionAmount)
  }, [amount, discountForProduct, price, quantity, list, amounts, discount, perTax])

  // Edit function
  const editRow = (id) => {
    const editingRow = list.find((row) => row.id === id)
    setList(list.filter((row) => row.id !== id))
    setIsEditing(true)
    setDetails(editingRow.details)
    setQuantity(editingRow.quantity)
    setPrice(editingRow.price)
    setCatalogNum(editingRow.catalogNum)
    setIsExemption(editingRow.isExemption)
    setDiscountForProduct(editingRow.discountForProduct)
  }

  // Delete function
  const deleteRow = (id) => setList(list.filter((row) => row.id !== id))

  return (
    <div>

      <form onSubmit={handleSubmit}>
        <div className="table-responsive">
          <table className="table table-hover table-white">
            <thead>
              <tr >
                {/* <th style={{width: '20px'}}>#</th> */}
                <th>رقم الفاتورة </th>
                <th className="col-md-2"> تفاصيل الفاتورة </th>
                <th className="col-md-1 ">المورد </th>
                <th >تاريخ الفاتورة </th>
                <th >تاريخ الاستحقاق </th>
                <th  >الكمية </th>
                <th  >قيمة الفاتورة  </th>
                {/* <th title='ضريبة القيمة المضافة ' style={{width: '80px'}} >ض.ق.م</th> */}
                <th >خصم %</th>
                <th>مجموع</th>
                <th title='معفي من ضريبة القيمة المضافة '  >معفي من ض.ق.م  </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                        <td>1</td>
                        <td>
                          <input
                           className="form-control"
                            type="text"
                             style={{minWidth: '150px'}}
                             onChange={e=>setCatalogNum(e.target.value)}
                             />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            type="text" 
                            style={{minWidth: '150px'}} 
                            onChange={e=>setDetails(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            onChange={e=>setPrice(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            style={{width: '80px'}}
                             type="text"
                             onChange={e=>setQuantity(e.target.value)} 
                             />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            readOnly 
                            style={{width: '120px'}} 
                            type="text" 
                            value={(amount)}
                            />
                        </td>
                        <td onClick={()=> addItem()}><a href="javascript:void(0)" className="text-success font-18 btn" title="Add"><i className="fa fa-plus" /></a></td>
                      </tr> */}
              {/* <tr>
                        <td>2</td>
                        <td>
                          <input className="form-control" type="text" style={{minWidth: '150px'}} />
                        </td>
                        <td>
                          <input className="form-control" type="text" style={{minWidth: '150px'}} />
                        </td>
                        <td>
                          <input className="form-control" style={{width: '100px'}} type="text" />
                        </td>
                        <td>
                          <input className="form-control" style={{width: '80px'}} type="text" />
                        </td>
                        <td>
                          <input className="form-control" readOnly style={{width: '120px'}} type="text" />
                        </td>
                        <td><a href="javascript:void(0)" className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a></td>
                      </tr> */}
              <tr>
                {/* <td>1</td> */}
                <td>
                  <input
                    required
                    className="form-control"
                    type="text"

                    value={catalogNum}
                    onChange={e => setCatalogNum(e.target.value)}
                  />
                </td>
                <td>
                  <div className="form-group form-focus select-focus  w-100" style={{ padding: "0px" }}>
                    <select id="currency-two"
                      className='select floating ex-select w-100'
                    >
                      <option>فاتورة ضريبية </option>
                      <option> وصل بدون ضريبة</option>
                      <option> فاتورة وصل</option>
                      <option>قيد</option>
                      <option>قيد عكسي </option>
                      <option>عمولة </option>

                    </select>
                    <br></br>
                    <br></br>

                  </div>
                </td>
                <td>
                  <div className="form-group form-focus select-focus  w-100" style={{ padding: "0px" }}>
                    <select id="currency-two"
                      className='select floating ex-select w-100'
                    >
                      <option>مورد  1  </option>
                      <option> مورد 2 </option>
                    </select>
                   

                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <div className="">
                      <input className="form-control datetimepicker" type="date" 
                      style={{fontSize:"20px" }}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <div className="form-group">
                    <div className="">
                      <input className="form-control datetimepicker" type="date"   style={{fontSize:"20px" }} />
                    </div>
                  </div>
                </td>
                <td>
                  <input
                    required
                    className="form-control"
                    // style={{ width: '80px' }}
                    type="text"
                    value={quantity}
                    onChange={e => setQuantity(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    required
                    className="form-control"
                    // style={{ width: '100px' }}
                    type="text"
                    value={price}
                    onChange={e => setPrice(e.target.value)}
                  />
                </td>

                {/* <td>
                            <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            value={singleTax}
                            // onChange={e=> setDiscountForProduct(e.target.value)}
                            />
                        </td> */}
                <td>
                  <input

                    className="form-control"
                    // style={{ width: '100px' }}
                    type="text"
                    value={discountForProduct}
                    onChange={e => setDiscountForProduct(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    className="form-control"
                    readOnly
                    // style={{ width: '120px' }}
                    type="text"
                    value={(amount)}
                  />
                </td>
                <td>
                  <input
                    onChange={(e) => {
                      setIsExemption(e.target.checked)
                    }
                    }
                    style={{ width: '80px' }}
                    type="checkbox"
                    checked={isExemption}

                  />
                  <span className='pb-5' style={{ color: 'white' }}>{isExemption ? 'نعم' : 'لا'}</span>
                </td>
                {/* <td>
                            <a 
                            // href="javascript:void(0)"
                             className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a></td> */}
              </tr>
              <tr>
                <td
                  colSpan={10}
                  className='text-center'
                >
                  <button
                    type='submit'
                    //  onClick={handleSubmit}
                    // href="javascript:void(0)"
                    className="text-success font-18 btn"

                  >
                    {isEditing ? <span className='fs-4' title="تعديل ">
                      تعديل  <BiEdit size={40} /> </span> : <span className='fs-4' title="إضافة ">
                      إضافة  <CgPlayListAdd size={40} /> </span>}</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
      <table className="table table-hover table-white">
        <thead>
          <tr>
            <th style={{ width: '20px' }}>#</th>
            <th >رقم الفاتورة </th>
            <th className="col-md-2 ">تفاصيل الفاتورة </th>
            <th >المورد </th>

            <th>تاريخ الفاتورة </th>
            <th>تاريخ الاستحقاق </th>

            <th  >الكمية </th>
            <th >قيمة الفاتورة  </th>
            <th  >خصم %  </th>
            <th>مجموع</th>
            <th title='معفي من ضريبة القيمة المضافة '  >معفي من ض.ق.م  </th>
            <th> تعديل</th>
            <th>حذف </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, index) => (

            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  // style={{ minWidth: '150px' }}
                  value={item.catalogNum}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  // style={{ minWidth: '150px' }}
                  value={item.details}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  // style={{ width: '100px' }}
                  type="text"
                  value="00"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  // style={{ width: '100px' }}
                  type="text"
                  value="00-00-00"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  // style={{ width: '100px' }}
                  type="text"
                  value="00-00-00"
                />
              </td>
              <td>
                <input
                  className="form-control"
                  style={{ width: '80px' }}
                  type="text"
                  value={item.quantity}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  // style={{ width: '100px' }}
                  type="text"
                  value={item.price}
                />
              </td>
              
              <td>
                <input
                  className="form-control"
                  // style={{ width: '80px' }}
                  type="text"
                  value={item.discountForProduct}
                />
              </td>
              <td>
                <input
                  className="form-control amount"
                  readOnly
                  // style={{ width: '120px' }}
                  type="text"
                  value={item.amount}
                />
              </td>
              <td>
                <a className="text-success font-18 " >
                  {item.isExemption ? <BsCheckLg color='yellow' size={30} /> : ""}
                </a>
              </td>
              <td >
                <a onClick={() => editRow(item.id)}
                  // href="javascript:void(0)"
                  className="text-success font-18 pointer-mk" title="تعديل"><BiEdit size={30} />
                </a>
              </td>
              <td >
                <a onClick={() => deleteRow(item.id)}
                  // href="javascript:void(0)"
                  className="text-danger font-18 pointer-mk" title="حذف"><MdDeleteOutline size={30} />
                </a>
              </td>
              {/* <td>
                          <a 
                          // href="javascript:void(0)"
                           className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a>
                           </td> */}
            </tr>
          ))}

        </tbody>
      </table>
      <div className="table-responsive">
        <table className="table  table-white">
          <tbody>
            <tr>
              <td style={{ "width": "100%" }} />
              <td />
              <td />
              <td />
              <td></td>
              <td className=" page-title "> المجموع <span className='privacy fs-6'>(الملزم)</span> </td>
              <td className="page-title" style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{amounts.toLocaleString()}</td>
            </tr>


            {/* <tr>
                        <td colSpan={5} className="text-right page-title">
                          خصم %
                        </td>
                        <td style={{textAlign: 'right', paddingRight: '30px', width: '230px'}}>
                          <input className="form-control text-right" type="text" onChange={e=> setDiscount(e.target.value)}/>
                        </td>
                      </tr> */}

            {/* <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td className="text-right page-title"> المجموع بعد الخصم </td>
                        <td className="page-title" style={{textAlign: 'right', paddingRight: '30px', width: '230px'}}>{totalAfDiscount.toLocaleString()}</td>
                      </tr> */}
            <tr>
              <td style={{ "width": "100%" }}></td>
              <td />
              <td />
              <td />
              <td></td>
              <td className=" page-title" >ضريبة  <span className='privacy fs-6 '>(القيمة المضافة)</span></td>
              <td className="page-title" style={{ textAlign: 'right', paddingRight: '30px', width: '230px' }}>{tax.toLocaleString()}</td>
            </tr>

            <tr >
              <td style={{ "width": "100%" }} />
              <td />
              <td />
              <td />
              <td></td>
              <td className=" page-title">المجموع <span className='privacy fs-6 '>(شامل الضريبة)</span> </td>
              <td className="page-title" style={{ textAlign: 'right', paddingRight: '30px', width: '230px', borderBottom: "gray 2px solid" }}>{totalAfTax.toLocaleString()}</td>
            </tr>
            {/* <hr className='hr-mk'/> */}
            <tr>



            </tr>
            <tr>
              <td style={{ "width": "100%" }} />
              <td />
              <td />
              <td />
              <td />
              <td className=" page-title"> المجموع   <span className='privacy fs-6'>(المعفي)</span>  </td>
              <td className="page-title" style={{ textAlign: 'right', paddingRight: '30px', width: '230px', borderBottom: "gray 2px solid" }}>{exemptionAmount.toLocaleString()}</td>
            </tr>
            <tr>
              <td style={{ "width": "100%" }} />
              <td />
              <td />
              <td />
              <td />
              <td className="fs-1 text-primary " style={{ textAlign: 'right', fontWeight: 'bold' }}>
                المجموع الكلي
              </td>
              <td className="page-title text-primary fs-1 result-mk" style={{
                textAlign: 'right', paddingRight: '30px', fontWeight: 'bold', fontSize: '16px', width: '230px', borderBottom: "#FF9B44 8px double"
                // textDecoration:'underline',textDecorationStyle:'double'
              }}>
                <span className='page-title text-primary fs-1 bold result-mk'
                //  style={{ borderBottom:"orange 8px double"}}
                >
                  {total.toLocaleString()}</span>  <BiShekel />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  )
}
