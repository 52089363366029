/**
 * Blank Page
 */
import React, { useEffect ,useState } from 'react';
import { Helmet } from "react-helmet";
// import TxTbl from './TxTblAbt';
import ExchngRate from '../../../calculators/exchange-rate/ExchngRate'
import { RiExchangeDollarLine } from "react-icons/ri";
import ReactTooltip from "react-tooltip";


export default function  ExChange()  {
    
	
        function getRate(currencyTwo) {
            const currencyOne = 'ILS';
            const urll = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/${currencyTwo}`;
            const [rateEx, setRateEx] = useState();
            useEffect(() => {
        
              fetch(urll)
                .then(res => res.json())
                .then(data => {
                  // console.log(data);
                  const ratee = data.conversion_rates[currencyOne];
                  const rateD_four = ratee.toFixed(2);
                  setRateEx(rateD_four)
        
                });
            });
        
            return (rateEx);
          }
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | احتساب العملات      </title>
					<meta name="description" content="MassPortal - احتساب ضريبة الأملاك  " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<h1> احتساب العملات  </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
						</div>
                        <div className="row">
          <h3 className="card-title">  أسعار الصرف </h3>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <i className="fa fa-usd" />
                </span>
                <div className="dash-widget-info">
                  {/* <h3>0.00</h3> */}
                  <h3>{getRate('USD')}</h3>
                  <a href="exchange-rate" data-tip data-for="ex-tip">
                    <RiExchangeDollarLine className="privacy exhange-icon" size={25} />
                  </a>
                  <ReactTooltip id="ex-tip" place="right" effect="solid">
                    احسب سعر الصرف
                  </ReactTooltip>
                  <span>شيكل   </span>
                  <span className='span2'>دولار</span>
                </div>
              </div>

            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon-jordan">JD</span>
                <div className="dash-widget-info">
                  {/* <h3>0.00</h3> */}
                  <h3>{getRate('JOD')}</h3>
                  <a href="exchange-rate" data-tip data-for="ex-tip">
                    <RiExchangeDollarLine className="privacy exhange-icon" size={25} />
                  </a>
                  <ReactTooltip id="ex-tip" place="right" effect="solid">
                    احسب سعر الصرف
                  </ReactTooltip>
                  <span>شيكل</span>
                  <span className='span2'>دينار</span>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon"><i className="fa fa-eur" /></span>
                <div className="dash-widget-info">
                  <h3>{getRate('EUR')}</h3>
                  {/* <h3>0.00</h3> */}
                  <a href="exchange-rate" data-tip data-for="ex-tip">
                    <RiExchangeDollarLine className="privacy exhange-icon" size={25} />
                  </a>
                  <ReactTooltip id="ex-tip" place="right" effect="solid">
                    احسب سعر الصرف
                  </ReactTooltip>
                  <span>شيكل</span>
                  <span className='span2'>يورو</span>
                </div>
              </div>
            </div>
          </div>


          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon"><i className="fa fa-jpy" aria-hidden="true"></i></span>
                <div className="dash-widget-info">
                  {/* <h3>0.00</h3> */}
                  {/* <h3>{getRate('CNY')}</h3> */}
                  <h3>{getRate('CNY')}</h3>
                  <a href="exchange-rate" data-tip data-for="ex-tip">
                    <RiExchangeDollarLine className="privacy exhange-icon" size={25} />
                  </a>
                  <ReactTooltip id="ex-tip" place="right" effect="solid">
                    احسب سعر الصرف
                  </ReactTooltip>
                  <span>شيكل</span>
                  <span className='span2'>يوان</span>
                </div>
              </div>
            </div>
          </div>
        </div>
						<div className="row">
							
						<div className=''>
                        <ExchngRate />
									</div>
							{/* <div className='col-lg-1'>
							</div> */}
						</div>
						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>
		);
	}

