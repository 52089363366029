let bet =[
    {
     ar: "بائع",
     he: "מוכר",
     en: "Salesman\/ seller\/ vendor"
    },
    {
     ar: "بائع مرخص لة بالسعر الاصلي",
     he: "סוכן מורשה לפי הערך הנקוב\/ פארי",
     en: "Licensed dealer\/ licensed broker at par"
    },
    {
     ar: "بترول",
     he: "נפט",
     en: "Oil"
    },
    {
     ar: "بحث حالة السوق",
     he: "חקר שווקים",
     en: "Market research"
    },
    {
     ar: "بخشيش",
     he: "טיפ\/ תשר",
     en: "Tip"
    },
    {
     ar: "بخل",
     he: "קמצנות",
     en: "Stinginess"
    },
    {
     ar: "بدون حصة ارباح",
     he: "ללא זכות לדיווידנד",
     en: "Ex- dividend"
    },
    {
     ar: "بديل",
     he: "תחליף",
     en: "Substitute"
    },
    {
     ar: "برميل",
     he: "חבית",
     en: "Barrel"
    },
    {
     ar: "برنامج",
     he: "תכנית",
     en: "plan"
    },
    {
     ar: "برنامج مارشال",
     he: "תכנית מארשל",
     en: "Marshall plan"
    },
    {
     ar: "بريد",
     he: "דואר",
     en: "Post"
    },
    {
     ar: "بريد الدرجة الاولى",
     he: "דואר מהיר",
     en: "First class mail"
    },
    {
     ar: "بريد الدرجة الثانية",
     he: "דואר רגיל",
     en: "Second class mail"
    },
    {
     ar: "بضائع",
     he: "טובין",
     en: "Goods"
    },
    {
     ar: "بضائع مجمركة",
     he: "סחורות מוצברות",
     en: "Cleared goods"
    },
    {
     ar: "بضائع موجودة",
     he: "מלאי",
     en: "Stock\/ inventory"
    },
    {
     ar: "بضاعة",
     he: "סחורה",
     en: "Commodity\/ good"
    },
    {
     ar: "بضاعة تامة الصنع",
     he: "מוצר מוגמר",
     en: "Finished good\/ end product\/ final product"
    },
    {
     ar: "بضاعة محفزة للشراء",
     he: "מוצר דחף",
     en: "Impulse good"
    },
    {
     ar: "بضاعة مشحونة",
     he: "מטען",
     en: "Freight\/ lading\/ cargo"
    },
    {
     ar: "بضاعة مودعة في مستودع الجمرك",
     he: "סחורה המאוכסנת במחסן ערובה",
     en: "Bonded good"
    },
    {
     ar: "بطاقة اعتماد",
     he: "כרטיצ אשראי",
     en: "Credit card"
    },
    {
     ar: "بطالة",
     he: "אבטלה",
     en: "Unemployment"
    },
    {
     ar: "بطالة احتكاكية",
     he: "אבטלה חיכוכית",
     en: "Frictional unemployment"
    },
    {
     ar: "بطالة خفية",
     he: "אבטלה סמויה",
     en: "Hidden unemployment\/ disguised unemployment"
    },
    {
     ar: "بطالة مزمنة",
     he: "אבטלה כרונית",
     en: "Chronic unemployment"
    },
    {
     ar: "بطالة مقنعة",
     he: "אבטלה סמויה",
     en: "Hidden unemployment\/ disguised unemployment"
    },
    {
     ar: "بطالة موسمية",
     he: "אבטלה עונתית",
     en: "Seasonal unemployment"
    },
    {
     ar: "بطالة هيكلية",
     he: "אבטלה מבנית",
     en: "Structural unemployment"
    },
    {
     ar: "بطلان الاستعمال",
     he: "יציאה מכלל שימוש",
     en: "obsolescence"
    },
    {
     ar: "بقالة",
     he: "חנות מכולת",
     en: "grocery"
    },
    {
     ar: "البلى العادي",
     he: "בלאי",
     en: "Wear and tear"
    },
    {
     ar: "بناء",
     he: "בנייה",
     en: "Building"
    },
    {
     ar: "بنط",
     he: "נקודה",
     en: "Point"
    },
    {
     ar: "بنك",
     he: "בנק",
     en: "Bank"
    },
    {
     ar: "بنك اجنبي",
     he: "בנק זר",
     en: "Foreign bank"
    },
    {
     ar: "بنك استثمار",
     he: "בנק להשקעות",
     en: "Investment bank"
    },
    {
     ar: "بنك اسلامي",
     he: "בנק אסלאמי",
     en: "Islamic bank"
    },
    {
     ar: "بنك اعتماد",
     he: "בנק לאשראי",
     en: "Credit bank"
    },
    {
     ar: "بنك اعتماد زراعي",
     he: "בנק לאשראי חקלאי",
     en: "Rural credit bank "
    },
    {
     ar: "البنك الاسلامي للتنمية",
     he: "הבנק האסלאמי לפיתוח",
     en: "Islamic development bank"
    },
    {
     ar: "البنك الدولي",
     he: "הבנק העולמי",
     en: "World bank"
    },
    {
     ar: "البنك الدولي للانشاء والتعمير",
     he: "הבנק הבינלאומי לשיקום ופיתוח",
     en: "International bank for reconstruction and development"
    },
    {
     ar: "بنك تجاري",
     he: "בנק מסחרי",
     en: "Merchant bank"
    },
    {
     ar: "بنك تعاوني",
     he: "בנק קואופרטיבי",
     en: "Cooperative bank"
    },
    {
     ar: "بنك توفير",
     he: "בנק לחיסכון",
     en: "Savings bank"
    },
    {
     ar: "بنك خاص",
     he: "בנק פרטי",
     en: "Private bank"
    },
    {
     ar: "بنك خصم السندات",
     he: "בנק ניכיונות",
     en: "Discount bank"
    },
    {
     ar: "بنك ربوي",
     he: "בנק העוסק בריבית (בנק שאינו אסלאמי)",
     en: "Bank which deals with interest (a bank which is not an Islamic bank)"
    },
    {
     ar: "بنك رهونات",
     he: "בנק למשכנתאות",
     en: "Mortgage bank"
    },
    {
     ar: "بنك زراعي",
     he: "בנק לחקלאות",
     en: "Agricultural bank"
    },
    {
     ar: "بنك صناعي",
     he: "בנק לתעשייה",
     en: "industrial bank"
    },
    {
     ar: "بنك عضو",
     he: "בנק חבר",
     en: "Member bank"
    },
    {
     ar: "بنك مراسل",
     he: "בנק עמית",
     en: "Correspondent bank"
    },
    {
     ar: "بنك مركزي",
     he: "בנק מרכזי",
     en: "Central bank"
    },
    {
     ar: "بواسطة",
     he: "אצל",
     en: "Care of"
    },
    {
     ar: "بورجوازية",
     he: "בורגנות",
     en: "Bourgeoisie"
    },
    {
     ar: "بورصة",
     he: "בורסה",
     en: "Exchange"
    },
    {
     ar: "بورصة الاوراق المالية",
     he: "בורסה לניירות ערך",
     en: "Stock exchange"
    },
    {
     ar: "فاتورة",
     he: "שטר",
     en: "Note\/ deed\/ bill"
    },
    {
     ar: "بوليصة",
     he: "פוליסה",
     en: "policy"
    },
    {
     ar: "بوليصة شحن",
     he: "שטר מטען\/ שטר משגר",
     en: "Bill of lading\/ consignment note"
    },
    {
     ar: "بوليصة شحن اصلية",
     he: "שטר מטען מקורי",
     en: "Original bill of lading"
    },
    {
     ar: "بوليصة شحن بالطائرة",
     he: "שטר מטען אווירי",
     en: "Airway bill of lading"
    },
    {
     ar: "بوليصة شحن بسكة حديد",
     he: "שטר מטען על רכבת ",
     en: "Rail (way) bill of lading"
    },
    {
     ar: "بوليصة شحن بضائع تم شحنها",
     he: "שטר מטען שהטענתו בוצעה",
     en: "Shipped bill of lading"
    },
    {
     ar: "بوليصة شحن ذات تحفظات",
     he: "שטר מטען פגום",
     en: "Foul bill of lading"
    },
    {
     ar: "بوليصة شحن شخصية",
     he: "שטר מטעין ישיר",
     en: "Straight bill of lading"
    },
    {
     ar: "بوليصة شحن عبر وسائل نقل متتابعة",
     he: "שטר מטען במעבר",
     en: "Through bill of lading"
    },
    {
     ar: "بوليصة شحن غير نظيفة",
     he: "שטר מטען מלוכלך",
     en: "Unclean bill of lading\/ dirty bill of lading"
    },
    {
     ar: "بوليصة شحن قابلة للتداول",
     he: "שטר מטען סחיר",
     en: "Negotiable bill of lading"
    },
    {
     ar: "بوليصة شحن لامرها",
     he: "שטר מטען לפקודה",
     en: "Order bill of lading"
    },
    {
     ar: "بوليصة شحن لحاملها",
     he: "שטר מטען למוכ\"ז",
     en: "Bill of lading to bearer"
    },
    {
     ar: "بوليصة شحن متقادمة",
     he: "שטר מטען מיושן",
     en: "Stale bill of lading"
    },
    {
     ar: "بوليصة شحن ناقصة",
     he: "שטר מטען פגום",
     en: "Foul bill of lading"
    },
    {
     ar: "بوليصة شحن نظيفة",
     he: "שטר מטען נקי",
     en: "Clean bill of lading"
    },
    {
     ar: "بيان الارباح والخسائر",
     he: "דו\"ח רווח והפסד",
     en: "Profit and loss statement"
    },
    {
     ar: "بيان الاوضاع",
     he: "רשימת נכסים והתחייבויות",
     en: "Statement of affairs"
    },
    {
     ar: "بيان حساب",
     he: "מצב חשבון",
     en: "Statement of account"
    },
    {
     ar: null,
     he: "חשבונית מס",
     en: "Invoice"
    },
    {
     ar: "بيان شكلي",
     he: "דו\"ח פרופורמה",
     en: "Pro-forma statement"
    },
    {
     ar: "بيان مالي",
     he: "דו\"ח פינאנסי\/ דו\"ח כספי",
     en: "Financial statement"
    },
    {
     ar: null,
     he: null,
     en: null
    },
    {
     ar: "بيان مالي مشترك",
     he: "דו\"ח פינאנסי מאוחד\/ דו\"ח כספי    מאוחד",
     en: "Combined financial statement\/ consolidated financial statement"
    },
    {
     ar: "بيان مالي موحد",
     he: "דו\"ח פינאנסי מאוחד\/ דו\"ח כספי מאוחד",
     en: null
    },
    {
     ar: null,
     he: null,
     en: "Combined financial statement\/ consolidated financial statement"
    },
    {
     ar: "بيان مصادر الاموال واستعمالاتها",
     he: "דו\"ח מקורות ושימושים",
     en: "Statement of sources and uses of funds\/ statement of sources and applications"
    },
    {
     ar: "بيت الاصدار",
     he: "בית הוצאה",
     en: "Issuing house"
    },
    {
     ar: " بيع",
     he: "מכירה",
     en: "sale"
    },
    {
     ar: "بيع الديون",
     he: "מכירת חובות",
     en: "factoring"
    },
    {
     ar: "بيع باسعار مخفضة",
     he: "מכירה במחירי הנחה",
     en: "Bargain sale"
    },
    {
     ar: "بيع بالتجزئة",
     he: "קמעונאות",
     en: "retail"
    },
    {
     ar: "بيع بالجملة",
     he: "סיטונאות",
     en: "wholesale"
    },
    {
     ar: "بيع بالدين",
     he: "מכירה באשראי",
     en: "Sale on credit"
    },
    {
     ar: "بيع بالمفرق",
     he: "קמעונאות",
     en: "retail"
    },
    {
     ar: "بيع بخس",
     he: "הצפה",
     en: "Dumping"
    },
    {
     ar: "بيع بشرط الاستحسان",
     he: "מכירה לאחר שהקונה קיבל את הסחורה והסכים לקנותה",
     en: "Sale on approval"
    },
    {
     ar: "بيع تصفية",
     he: "מכירת חיסול",
     en: "Clearing sale"
    },
    {
     ar: "بيع على المكشوف",
     he: "מכירה בחסר",
     en: "Selling short"
    },
    {
     ar: "بيع مباشر",
     he: "מכירה ישירה",
     en: "Direct selling"
    },
    {
     ar: "بيع مشروط",
     he: "מכירה מותנית",
     en: "Conditional sale"
    }
   ]
   export default bet ;