
import React, { Component, useState } from 'react';



export default function GeloomCalc() {
 const [income,setIncome] = useState(0)
 const [percTax,setPercTax] = useState(30)
 const [tax,setTax] = useState(0)
 const [total,setTotal] = useState(0)


 function Calc(income){
    let ttl,tx=0 // total & tax
    ttl = income / (1 - (percTax /100))
    tx = ttl - income
    setTotal(ttl.toFixed(2))
    setTax(tx.toFixed(2))

  }

    const handleSubmit = (e)=>{
        e.preventDefault()
        Calc(income)
    }

    
   

  
 
  return (
 

    <div className="card text-left">
      {/* Page Header */}

      <div className=" card-body eOfService">
        <br></br>
        <form onSubmit={handleSubmit} >
          <h2 className="card-title text-center"> احتساب ضرائب تمثيلي   <span className="privacy">( גילום )</span> </h2>
          <br></br>
          <div className="">

            <br></br>
            <div className="form-gronum row">
              <div className="col-12">
                <label className=" col-form-label">المبلغ الصافي  (المراد سحبه) </label>
                <br></br>
                <input
                  type="text"
                  className="form-control"
                  name="amount"
                  id='amount'
                  // value={contact.amount}
                  onChange={(e)=>setIncome(e.target.value)}
                />
             
              </div>
              <div className='col-12'>
              
              </div>

             
              <div className='col-12'>
                <br></br>
                <label className=" col-form-label label-vit">نسبة الضريبة  </label>
               
                <select
                  id="tax"
                  className='select floating vit-select '
                  name='tax'
                  // value={contact.tax}

                  onChange={(e)=>setPercTax(e.target.value)}
                >
                  <option value="45"> 45 %  </option>
                  <option value="40"> 40 %  </option>
                  <option value="35"> 35 %  </option>
                  <option selected="selected" value="30"> 30 %  </option>
                  <option value="25"> 25 %  </option>
                  <option value="20"> 20 %  </option>
                  <option value="15"> 15 %  </option>
                  <option value="10"> 10 %  </option>
                  <option value="5"> 5 %  </option>
<br></br>
                </select>

              </div><br></br>

             
              <div className="col-12">
                <br></br>
                <label className=" col-form-label">الضريبة </label>
                <br></br>
                <input
                  type="number"
                  className="form-control"
                  name="vit"

                  value={tax}
                />
                <br></br>
              </div>

              <div className="col-12">
                <label className=" col-form-label"> المبلغ  الكلي   </label>
                <br></br>
                <input type="number"
                  className="form-control"
                  name="vit"
                  value={total}
                />
                <br></br>
              </div>

            </div>

          </div>
          {/* <div className="text-center button-height">
            <button type="submit" className="btn btn-primary calc-button ">حساب</button>
          </div> */}

          {/* /Page Content */}
          <div className='text-center'>
            <button className='btn btn-primary'>حساب</button>
          </div>
        </form>
      </div>

    </div>
  );


        }

