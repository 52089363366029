/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { useState, useEffect } from 'react';
import { FaSignature } from "react-icons/fa";
import { GrLinkPrevious } from "react-icons/gr";
import { BsCalendar2Date } from "react-icons/bs";
import { AiOutlineFileWord, AiOutlineFilePdf ,AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si"
import { ImLocation } from "react-icons/im"
import { otherStorage, otherFirestore } from '../../../../firebase/config';
export default function MathCourses() {

  const [info, setInfo] = useState([]);

  // Start the fetch operation
  useEffect(() => {
    // do stuff here...
    const Fetchdata = () => {
      // console.log("FetchData Function is called ");
      otherFirestore.collection("courses-accounting").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          data.id = element.id;
          // console.log("data here", data.sortId)
          
          setInfo(arr => [...arr, data ]);
        });
      })
    }
    Fetchdata();
  
    // info.sort((a, b) => (a.sortId < b.sortId) ? 1 : -1)
   
  }, [])
  const myData = info
  .sort((a, b) => a.sortId > b.sortId ? 1 : -1)
  .map((item) => (
     <div key={item.id}> {item.name}</div>
  ));

// info.reverse();
// const itemm = info.shift();
// info.reverse();
// info.push(itemm);


  return (
    <div className="page-wrapper">
      <Helmet>
        <title>MassPortal - دورات ماث أكاديمي</title>
        <meta name="description" content="دورات ماث أكاديمي" />
      </Helmet>
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">

            <div className="col-sm-12 text-center" >
              <br></br>
              <br></br>
              <br></br>
              <h3 className="page-title"> دورات <span className='privacy'> Math Fintech</span></h3>
             <br></br>
              <p className="privacy-bred"><a href="/">الرئيسية</a></p>
             
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          {info.map((data) => (
            <div className="col-sm-12 col-md-6 col-xl-4 " key={data.id}>
              <div className="topics">
                <a href={'https://math-ac2.web.app/single-course-details-fintech?id='+data.id} target='_blank'>
                <h3 className="topic-title text-center"> {data.course_title}</h3>
                        </a> 
                        <hr></hr>
                <ul className="topics-list">
                  <li>
                    <tr>
                      <td>
                        <BsCalendar2Date size={30} className="privacy contract-icon" />
                      </td>
                      <td>
                       
                        <p>تاريح الدورة</p>
                      </td>
                      <td>
                        <p>{data.dateOfCourseStart}</p>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                      <td>
                        <ImLocation size={30} className="privacy contract-icon" />
                      </td>
                      <td>
                        <p>مكان الدورة </p>                                 
                        </td>
                      <td>
                        <p> مركز Math Academy </p>
                      </td>
                    </tr>
                  </li>
                  <li className='topics-li-left ' >
                    <tr>
                    <td>
                      <a href="https://math-ac2.web.app/courses-accounting" target='_blank' className='contract-a'><AiOutlineArrowLeft size={30} className="privacy contract-icon"  /></a>

                      </td>
                    <td>
                      <a 
                      href={'https://math-ac2.web.app/single-course-details-fintech?id='+data.id}
                      target='_blank'>
                          للاستزادة
                                                  </a>
                      </td>
                      <td>
                      <a href="https://math-ac2.web.app/courses-accounting" target='_blank' className='contract-a'><AiOutlineArrowRight size={30} className="privacy contract-icon"  /></a>

                      </td>
                     
                    
                    </tr>
                  </li>
                  <br></br>
                 <li></li>
                 
                </ul>
              </div>
            </div>
          ))}


          {/* <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Getting Started <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6 ">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div> */}
        </div>
      </div>
      {/* /Page Content */}
    </div>

  );

}



