
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
// import ExchngRate from './ExchngRate';
import GeloomCalc from './GeloomCalc';
class Geloompage extends Component {
  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - احتساب ( ضرائب تمثيلي)</title>
          <meta name="description" content="احتساب ضرائب تمثيلي " />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">حاسبة ضرائب تمثيلي  </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
                </ul>
                <br></br><br></br>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* /Page Content */}
          <div className='row'>
            <div className='col-sm-12 col-md-4'>

            </div>
            <div className='col-sm-12 col-md-4'>
            <GeloomCalc />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Geloompage;
