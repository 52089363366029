/**
 * Blank Page
 */
 import React, { Component } from 'react';
 import { Helmet } from "react-helmet";
import HalanatSkhar from '../../../../calculators/WorkLawsCalc/vat-calc/Halanat_Sh_Calc';
 
 export default class Halant_sh extends Component {
   render() {
     return (
       <div className="page-wrapper">
         <Helmet>
           <title>MassPortal | فوائد على تأخير الرواتب      </title>
           <meta name="description" content="MassPortal - فوائد على تأخير الرواتب " />
         </Helmet>
         {/* Page Content */}
         <div className="content container-fluid">
           <div className="small-container">
             <div className="inner-header text-center">
               <br></br>
               <br></br>
               <h1> احتساب الفوائد على تأخير الرواتب    </h1>
               <br></br>
               <br></br>
             </div>
             <div>  

                < HalanatSkhar />
             </div>
             {/* /Content End */}
           </div>
           {/* /Page Content */}
         </div>
       </div>
 
     );
   }
 }
 