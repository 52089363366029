let priceGroup = [
  
   
{
    year:"01/01/2019",
    months:{
        groups:{
    group1:{
            group: "1",
            min: "0",
            max: "106,000"},
    
    
    group2:{ 
            group: "2",
            min: "106,001",
            max: "128,000"},
   
    group3:{
            group: "3",
            min: "128,001",
            max: "151,000"},
    
    group4:{
            group: "4",
            min: "151,001",
            max: "170,000"},
    
    group5:{
            group: "5",
            min: "170,001",
            max: "221,000"},
    
    group6:{
            group: "6",
            min: "221,001",
            max: "314,000"},
    
    group7:{
            group: "7",
            min: "314,001",
            max: "أكثر من ذلك "}
        }
    }},
  
{
            year:"01/01/2018",
            months:{
                groups:{
            group1:{
                group: "1",
                min: "0",
                max: "100,000"},
            
            
            group2:{ 
                group: "2",
                min: "100,001",
                max: "120,000"
              },
            group3:{
                group: "3",
                min: "120,001",
                max: "141,000"
              },
            
            group4:{
                group: "4",
                min: "141,001",
                max: "159,000"
              },
            
            group5:{
                group: "5",
                min: "159,001",
                max: "207,000"
              },
            
            group6:{
                group: "6",
                min: "207,001",
                max: "296,000"
              },
            
            group7:{
                group: "7",
                min: "296,001",
                max: "أكثر من ذلك "}
                }
            }},
{
                year:"01/01/2011",
                
                months:{
                    groups:{
                group1:{
                    group: "1",
                    min: "0",
                    max: "97,000"},
                
                
                group2:{ 
                    group: "2",
                    min: "97,001",
                    max: "117,000"
                  },
                group3:{
                    group: "3",
                    min: "117,001",
                    max: "137,000"
                  },
                
                group4:{
                    group: "4",
                    min: "137,001",
                    max: "155,000"
                  },
                
                group5:{
                    group: "5",
                    min: "155,001",
                    max: "202,000"
                  },
                
                group6:{
                    group: "6",
                    min: "202,001",
                    max: "288,000"
                  },
                
                group7:{
                    group: "7",
                    min: "288,001",
                    max: "أكثر من ذلك "}
                    }
                }},
{
                year:"01/01/2010",
                
                months:{
                    groups:{
                group1:{
                    group: "1",
                    min: "0",
                    max: "95,000"},
                
                
                group2:{ 
                    group: "2",
                    min: "95,001",
                    max: "114,000"
                  },
                group3:{
                    group: "3",
                    min: "114,001",
                    max: "134,000"
                  },
                
                group4:{
                    group: "4",
                    min: "134,001",
                    max: "151,000"
                  },
                
                group5:{
                    group: "5",
                    min: "151,001",
                    max: "197,000"
                  },
                
                group6:{
                    group: "6",
                    min: "197,001",
                    max: "281,000"
                  },
                
                group7:{
                    group: "7",
                    min: "281,001",
                    max: "أكثر من ذلك "}
                    }
                }},
{
                year:"01/01/2008",
                
                months:{
                    groups:{
                group1:{
                    group: "1",
                    min: "0",
                    max: "92,000"},
                
                
                group2:{ 
                    group: "2",
                    min: "92,001",
                    max: "111,000"
                  },
                group3:{
                    group: "3",
                    min: "111,001",
                    max: "130,000"
                  },
                
                group4:{
                    group: "4",
                    min: "130,001",
                    max: "147,000"
                  },
                
                group5:{
                    group: "5",
                    min: "147,001",
                    max: "191,000"
                  },
                
                group6:{
                    group: "6",
                    min: "191,001",
                    max: "273,000"
                  },
                
                group7:{
                    group: "7",
                    min: "273,001",
                    max: "أكثر من ذلك "}
                    }
                }},
{
                year:"01/01/2007",
                
                months:{
                    groups:{
                group1:{
                    group: "1",
                    min: "0",
                    max: "87,000"},
                
                
                group2:{ 
                    group: "2",
                    min: "87,001",
                    max: "114,000"
                  },
                group3:{
                    group: "3",
                    min: "114,001",
                    max: "133,000"
                  },
                
                group4:{
                    group: "4",
                    min: "133,001",
                    max: "151,000"
                  },
                
                group5:{
                    group: "5",
                    min: "151,001",
                    max: "196,000"
                  },
                
                group6:{
                    group: "6",
                    min: "196,001",
                    max: "280,000"
                  },
                
                group7:{
                    group: "7",
                    min: "280,001",
                    max: "أكثر من ذلك "}
                    }
                }},

{
                year:"01/05/2006",
                
                months:{
                    groups:{
                group1:{
                    group: "1",
                    min: "0",
                    max: "91,000"},
                
                
                group2:{ 
                    group: "2",
                    min: "91,001",
                    max: "120,500"
                  },
                group3:{
                    group: "3",
                    min: "120,501",
                    max: "141,000"
                  },
                
                group4:{
                    group: "4",
                    min: "141,001",
                    max: "160,000"
                  },
                
                group5:{
                    group: "5",
                    min: "160,001",
                    max: "209,000"
                  },
                
                group6:{
                    group: "6",
                    min: "209,001",
                    max: "299,000"
                  },
                
                group7:{
                    group: "7",
                    min: "299,001",
                    max: "أكثر من ذلك "}
                    }
                }},
                {
                    year:"01/05/2005",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "92,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "92,001",
                        max: "122,000"
                      },
                    group3:{
                        group: "3",
                        min: "122,000",
                        max: "144,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "144,001",
                        max: "165,000"
                      },
                    
                    group5:{
                        group: "5",
                        min: "165,001",
                        max: "215,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "215,001",
                        max: "307,000"
                      },
                    
                    group7:{
                        group: "7",
                        min: "307,001",
                        max: "أكثر من ذلك "}
                        }
                    }},
                {
                    year:"01/05/2004",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "91,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "91,001",
                        max: "120,000"
                      },
                    group3:{
                        group: "3",
                        min: "120,000",
                        max: "142,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "142,001",
                        max: "163,000"
                      },
                    
                    group5:{
                        group: "5",
                        min: "165,001",
                        max: "212,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "212,001",
                        max: "303,000"
                      },
                    
                    group7:{
                        group: "7",
                        min: "303,001",
                        max: "أكثر من ذلك "}
                        }
                    }},
                {
                    year:"01/01/2004",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "86,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "86,001",
                        max: "114,000"
                      },
                    group3:{
                        group: "3",
                        min: "114,000",
                        max: "135,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "135,001",
                        max: "155,000"
                      },
                    
                    group5:{
                        group: "5",
                        min: "155,001",
                        max: "201,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "201,001",
                        max: "287,000"
                      },
                    
                    group7:{
                        group: "7",
                        min: "287,001",
                        max: "أكثر من ذلك "}
                        }
                    }},
                {
                    year:"01/05/2003",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "86,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "86,001",
                        max: "114,000"
                      },
                    group3:{
                        group: "3",
                        min: "114,000",
                        max: "144,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "144,001",
                        max: "201,000"
                      },
                    
                   
                    
                    group5:{
                        group: "5",
                        min: "201,001",
                        max: "287,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "287,001",
                        max: "أكثر من ذلك "},
                        group7:{
                            group:"",
                            min:"",
                            max:""
                        }
                        }
                    }},
                {
                    year:"01/05/2002",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "80,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "80,001",
                        max: "114,000"
                      },
                    group3:{
                        group: "3",
                        min: "106,000",
                        max: "134,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "134,001",
                        max: "187,000"
                      },
                    
                   
                    
                    group5:{
                        group: "5",
                        min: "187,001",
                        max: "267,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "267,001",
                        max: "أكثر من ذلك "},
                        group7:{
                            group:"",
                            min:"",
                            max:""
                        }
                        }
                    }},
                {
                    year:"01/03/2000",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "75,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "75,001",
                        max: "99,000"
                      },
                    group3:{
                        group: "3",
                        min: "99,000",
                        max: "125,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "125,001",
                        max: "175,000"
                      },
                    
                   
                    
                    group5:{
                        group: "5",
                        min: "175,001",
                        max: "250,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "250,001",
                        max: "أكثر من ذلك "},
                        group7:{
                            group:"",
                            min:'',
                            max:''
                        }
                        }
                    }},
                {
                    year:"01/01/1999",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "71,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "71,001",
                        max: "94,000"
                      },
                    group3:{
                        group: "3",
                        min: "94,000",
                        max: "118,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "118,001",
                        max: "166,000"
                      },
                    
                   
                    
                    group5:{
                        group: "5",
                        min: "166,001",
                        max: "237,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "237,001",
                        max: "أكثر من ذلك "},
                        group7:{
                            group:"",
                            min:'',
                            max:''
                        }
                        }
                    }},
                {
                    year:"01/03/1998",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "61,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "61,001",
                        max: "81,000"
                      },
                    group3:{
                        group: "3",
                        min: "81,000",
                        max: "102,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "102,001",
                        max: "143,000"
                      },
                    
                   
                    
                    group5:{
                        group: "5",
                        min: "143,001",
                        max: "204,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "204,001",
                        max: "أكثر من ذلك "},
                        group7:{
                            group:"",
                            min:'',
                            max:''
                        }
                        }
                    }},
                {
                    year:"01/04/1997",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "61,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "61,001",
                        max: "81,000"
                      },
                    group3:{
                        group: "3",
                        min: "81,000",
                        max: "101,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "101,001",
                        max: "142,000"
                      },
                    
                   
                    
                    group5:{
                        group: "5",
                        min: "142,001",
                        max: "203,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "203,001",
                        max: "أكثر من ذلك "},
                        group7:{
                            group:"",
                            min:'',
                            max:''
                        }
                        }
                    }},
                {
                    year:"01/01/1997",
                    
                    months:{
                        groups:{
                    group1:{
                        group: "1",
                        min: "0",
                        max: "60,000"},
                    
                    
                    group2:{ 
                        group: "2",
                        min: "60,001",
                        max: "80,000"
                      },
                    group3:{
                        group: "3",
                        min: "80,000",
                        max: "100,000"
                      },
                    
                    group4:{
                        group: "4",
                        min: "100,001",
                        max: "140,000"
                      },
                    
                   
                    
                    group5:{
                        group: "5",
                        min: "140,001",
                        max: "200,000"
                      },
                    
                    group6:{
                        group: "6",
                        min: "200,001",
                        max: "أكثر من ذلك "},
                        group7:{
                            group:"",
                            min:'',
                            max:''
                        }
                        }
                    }},
  ]
export default {priceGroup} ;