let taxProperty = [
  {
    yearMil:1375315200000,
    date: "01/08/2013",
    band1:{
    rate: "6",
    min: 0,
    max:"أكثر من ذلك"
    }
  },
  {
    yearMil:1072915200000,
    date: "01/01/2004",
    band1:{
    rate: "5",
    min: 0,
    max:"أكثر من ذلك"
    }
  },
  {
    yearMil:1041379200000,
    date: "01/01/2003",
    band1:{
    rate: "4.75",
    min: 0,
    max:"أكثر من ذلك"
    }
  },
  {
    yearMil:1005091200000,
    date: "07/11/2001",
    band1:{
    rate: "4.5",
    min: 0,
    max:"أكثر من ذلك"
    }
  },
  {
    yearMil:946598400000,
    date: "31/12/1999",
    band1:{
    rate: "5",
    min: 0,
    max:"أكثر من ذلك"
    }
  },
  {
    yearMil:888710400000,
    date: "01/03/1998",
    band1:{
    rate: "4.5",
    min: 0,
    max:"أكثر من ذلك"
    }
  },
  {
    yearMil:788918400000,
    date: "01/01/1995",
    band1:{
    rate: "5",
    min: 0,
    max:"أكثر من ذلك"
    }
  }
]
export default {taxProperty}