/**
 * TermsCondition Page
 */
import React, { Component, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";







export default function RashamCtt() {


  const [searchTerm, setSearchTerm] = useState("");
  const [details, setDetails] = useState("");
  const [arr , setArr] = useState([]);
  const apiURL = 'https://data.gov.il/api/3/action/datastore_search?resource_id=cb12ac14-7429-4268-bc03-460f48157858&q=';

  // Search by song or artist
  async function searchSongs(term) {
    const res = await fetch(`${apiURL}+${term}`);
    const data = await res.json();
    // console.log("🚀 ~ ~ data", data)

    const record = data.result.records;

    console.log("🚀 ~ record", record);
    const index = record.length -1 ;
    console.log("🚀 ~ record", index);
    const obj = record[index];
    // console.log(" ~ obj", obj)
    
    const entries = Object.entries(obj);
    setDetails(entries);
    // console.log("🚀 ~ details", details);


    // Object.entries(obj).forEach(entry => {
    //   const [key, value] = entry;
    //   console.log(key, value);
    // });  


    //  showData(entries);
  }


  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (!searchTerm) {
      alert('Please type in a search term');
    } else {
      searchSongs(searchTerm);
    }

    // console.log("Function Call Here"); 
    // console.log("text Value : ", searchTerm);

  }
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Mass Portal - Rasham</title>
        <meta name="description" content="Reactify Blank Page" />
      </Helmet>
      {/* Page Content */}
      <div className="content container-fluid">
        <div className="small-container">
          <div className="inner-header text-center">
            <br></br>
            <br></br>

            <h1>אישור ניהול תקין</h1>

            <p className="privacy-bred"><a href="/">الرئيسية</a></p>

            <br></br>
             

          </div>


          <div className="row">

            <div className="col-sm-12">
              <p className='comp-content'>
              للبحث عن  تفاصيل  شركة يمكنك  البحث عن طريق اسم الشركة او الرقم .
 أداة بسيطة وسهلة الاستخدام ،  نتيجة البحث باللغة العبرية<span className='privacy'> ( עברית ) </span>.
يتم تقديم هذه الخدمة <span className='privacy'> مجانًا </span> ، ويتم تحديث المعلومات الواردة فيها بانتظام من مسجل الشركات.
تتضمن المعلومات المقدمة معلومات تتعلق بالشركات ومؤسسات النفع العام<span className='privacy'> (חל"צ ) </span>.
              </p>
                    <br></br>
          
              <div>
                <p>
                  
                </p>
              </div>
        
              <form onSubmit={handleSubmit} className="form card ">

                <div className="card-body">

                  <div className="form-gronum row ">
                    <br></br>
                    <label className=" col-form-label comp-control">رقم الشركة أو اسمها</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control comp-control"
                        name="compName"
                        placeholder="أدخل رقم الشركة أو اسمها ..."
                        required
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        الرجاء ادخال رقم الشركة أو اسمها.
                      </div>
                      <br />




                    </div>
                  </div>
                </div>
                <br />
                <div className="text-center">
                  <button type="submit" className="btn btn-primary calc-button">ابحث </button>
                </div>
                <br></br>
              </form>
              <br></br>
              {details && (
                <div className='container'>
                <div className="card">


                <div className="card-body">
                <div className="table-responsive">
                <table className="table table-striped mb-0 comp-table-cont">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                      {details.map((data) =>
                     (
                       <tr key={data[0]}>
                         <td className='comp-table m-l-15'>{data[0]}</td>
                         <td className='just-table ' >{data[1]}</td>
                       </tr>
                     ) )}
                    </tbody>

                 
                </table>
                </div>  </div>  </div> </div>
              )}
              {/* {details &&(
              <>
               {details.map((data) =>
            (
               
                      <div key={data[0]} className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                        <h2 className='privacy'> {data[0]}   </h2>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                        <h2>   {data[1]} </h2>
                        </div>
                        <br></br>
                     
                      </div>
                      
                    ))}
              </>
            )} */}

             

            </div>

          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>

  );

}
