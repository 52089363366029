let e=[
 {
  ar: "يدوي",
  he: "ידני",
  en: "Manual"
 },
 {
  ar: "يمكن تحويلة",
  he: "ניתן להעברה\/ ניתן להמרה",
  en: "Assignable"
 },
 {
  ar: "يوم التسوية",
  he: "יום התשלום",
  en: "Settlement day"
 },
 {
  ar: "يوم التصفية",
  he: "יום התשלום",
  en: "Settlement day"
 },
 {
  ar: "يوم القيمة",
  he: "יום ערך",
  en: "Value day"
 },
 {
  ar: "يوم عمل",
  he: "יום עבודה",
  en: "Working day\/ workday"
 }
]
export default e;