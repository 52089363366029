
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { MassManage, MassAsset, MassPayroll, MassBill, MassFinance, MassLow,MassFleet, MassReport, MassTax ,MassContractLogo} from "../Entryfile/imagepath"
import {AiOutlineLock} from 'react-icons/ai';
class MassModules extends Component {
   render() {
      return ( 
         <div className="page-wrapper">
            <Helmet>
                <title>MassPortal - Modules</title>
                <meta name="description" content="MassPortal - Modules"/>					
            </Helmet>
         {/* Page Content */}
         <div className="content container-fluid">
           {/* Page Header */}
           <div className="page-header">
             <div className="row align-items-center">
               <div className="col">
                 <h3 className="page-title">MassPortal - <span className='privacy'> Lobby </span></h3>
                 <ul className="breadcrumb">
                   <li className="breadcrumb-item"><a href="/rtl/app/main/dashboard"></a>الرئيسية</li>
                  <br></br>
                 </ul>
               </div>
               {/* <div className="col-auto float-right ml-auto">
                 <a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_client"><i className="fa fa-plus" /> Add Client</a>
                 <div className="view-icons">
                    <a href="/rtl/app/employees/clients" className="grid-view btn btn-link active"><i className="fa fa-th" /></a>
                    <a href="/rtl/app/employees/clients-list" className="list-view btn btn-link"><i className="fa fa-bars" /></a>
                 </div>
               </div> */}
             </div>
           </div>
           {/* /Page Header */}
           {/* Search Filter */}
           {/* <div className="row filter-row">
             <div className="col-sm-6 col-md-3">  
               <div className="form-group form-focus">
                 <input type="text" className="form-control floating" />
                 <label className="focus-label">Client ID</label>
               </div>
             </div>
             <div className="col-sm-6 col-md-3">  
               <div className="form-group form-focus">
                 <input type="text" className="form-control floating" />
                 <label className="focus-label">Client Name</label>
               </div>
             </div>
             <div className="col-sm-6 col-md-3"> 
               <div className="form-group form-focus select-focus">
                 <select className="select floating"> 
                   <option>Select Company</option>
                   <option>Global Technologies</option>
                   <option>Delta Infotech</option>
                 </select>
                 <label className="focus-label">Company</label>
               </div>
             </div>
             <div className="col-sm-6 col-md-3">  
               <a href="#" className="btn btn-success btn-block"> Search </a>  
             </div>     
           </div> */}
           {/* Search Filter */}
           <div className="row staff-grid-row">
             
           <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   <a href="/mass-law" className="avatar"><img alt="Mass Law" src={MassLow} /></a>
                 </div>
              
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-law">قوانين و مراجع</a></h4>
                 <div className="small text-muted">قوانين الضريبة , قوانين العمل </div>
                 <a href="/pages/sidebar-pages/contracts/contracts-heb" className="btn btn-white btn-sm m-t-10 buttonspace">عقود </a>
                 <a href="/reports-he" className="btn btn-white btn-sm m-t-10">مراسلات </a>
               </div>
             </div>
             <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   <a href="/mass-report" className="avatar"><img alt="Mass Report " src={MassReport} /></a>
                   {/* <a href="#" className="avatar"><img alt="Mass Report " src={MassReport} /></a> */}
                 </div>
               
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-report">اعداد تقارير مالية </a></h4>
                 {/* <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="#">اعداد تقارير مالية </a></h4> */}

                 <div className="small text-muted">ميزانية , بيان أرباح و خسائر</div>
                 {/* <a href="#" className="btn btn-white btn-sm m-t-10 buttonspace"> <AiOutlineLock size={20} className='privacy'/> اعدادات </a>
                 <a href="#" className="btn btn-white btn-sm m-t-10"> <AiOutlineLock size={20} className='privacy'/> تقارير</a> */}
                 <a href="/mass-report" className="btn btn-white btn-sm m-t-10 buttonspace">  اعدادات </a>
                 <a href="/mass-report" className="btn btn-white btn-sm m-t-10"> Exports</a>
               </div>
             </div>
             <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   {/* <a href="/mass-tax" className="avatar"><img alt="Mass Tax" src={MassTax} /></a> */}
                   <a href="/mass-tax" className="avatar"><img alt="Mass Tax" src={MassTax} /></a>
                 </div>
               
                 {/* <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/calc-rtx">حاسبات الضريبة </a></h4> */}
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-tax">حاسبات الضريبة </a></h4>

                 <div className="small text-muted">فحص ارجاع ضريبي </div>
                 <a href="/mass-tax" className="btn btn-white btn-sm m-t-10 buttonspace">  جداول </a>
                 <a href="/mass-tax" className="btn btn-white btn-sm m-t-10">   حاسبات </a>
                 {/* <a href="/mass-tax" className="btn btn-white btn-sm m-t-10 buttonspace"> <AiOutlineLock size={20} className='privacy'/> اعدادات </a>
                 <a href="/mass-tax" className="btn btn-white btn-sm m-t-10"> <AiOutlineLock size={20} className='privacy'/>  تقارير </a> */}
               </div>
             </div>

             <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   <a href="/mass-asstes" className="avatar"><img alt="Mass Assets" src={MassAsset} /></a>
                   {/* <a href="#" className="avatar"><img alt="Mass Assets" src={MassAsset} /></a> */}
                 </div>
               
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-asstes">ادارة الأصول الثابتة </a></h4>
                 {/* <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="#">ادارة الأصول الثابتة </a></h4> */}

                 {/* <h5 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/rtl/app/profile/client-profile">الاستهلاك</a></h5> */}
                 <div className="small text-muted">الاستهلاك</div>
                 {/* <a href="#" className="btn btn-white btn-sm m-t-10 buttonspace"> <AiOutlineLock size={20} className='privacy'/> اعدادات</a> */}
                 {/* <a href="#" className="btn btn-white btn-sm m-t-10"> <AiOutlineLock size={20} className='privacy'/> تقارير</a> */}
                 <a href="/mass-asstes" className="btn btn-white btn-sm m-t-10 buttonspace">  اعدادات</a>
                 <a href="/mass-asstes" className="btn btn-white btn-sm m-t-10">  تقارير</a>
               </div>
             </div>

             
 <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   <a href="/mass-management" className="avatar"><img alt="Mass Management" src={MassManage} /></a>
                 </div>
               
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-management">ادارة مكتب محاسبة</a></h4>
                 <br></br>
                 <a href="/mass-management" className="btn btn-white btn-sm m-t-10 buttonspace">اعدادات </a> 
                 <a href="/mass-management" className="btn btn-white btn-sm m-t-10"> تقارير </a>
               </div>
             </div>
             <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   <a href="/dash" className="avatar"><img src={MassPayroll} alt="" /></a>
                 </div>
                 {/* <div className="dropdown profile-action">
                   <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                   <div className="dropdown-menu dropdown-menu-right">
                     <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_client"><i className="fa fa-pencil m-r-5" /> Edit</a>
                     <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_client"><i className="fa fa-trash-o m-r-5" /> Delete</a>
                   </div>
                 </div> */}
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/dash">نظام الرواتب </a></h4> 
                 <br></br>
                 {/* <h5 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/rtl/app/profile/client-profile">Ruby Bartlett</a></h5>
                 <div className="small text-muted">CEO</div> */}
                 <a href="/dash" className="btn btn-white btn-sm m-t-10 buttonspace">  اعدادات </a>
                 <a href="/dash" className="btn btn-white btn-sm m-t-10">  تقارير </a>
               </div>
             </div>

             <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   {/* <a href="/mass-billing" className="avatar"><img src={MassBill} alt="Mass Billing" /></a> */}
                   <a href="/mass-contracts" className="avatar"><img src={MassContractLogo} alt="Mass Contracts" /></a>
                 </div>
                
                 {/* <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-billing">نظام الفوترة </a></h4> */}
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-contracts">عقود  </a></h4>
                 <br></br>
                 <a href="/mass-contracts" className="btn btn-white btn-sm m-t-10 buttonspace">  عقود  </a>
                 <a href="/mass-contracts" className="btn btn-white btn-sm m-t-10">   عقود ديناميكية  </a>
                 {/* <a href="/mass-billing" className="btn btn-white btn-sm m-t-10 buttonspace"> <AiOutlineLock size={20} className='privacy'/> اعدادات  </a>
                 <a href="/mass-billing" className="btn btn-white btn-sm m-t-10"> <AiOutlineLock size={20} className='privacy'/> تقارير  </a> */}
               </div>
             </div>


             <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   {/* <a href="/mass-finance" className="avatar"><img alt="Mass Finance" src={MassFinance} /></a> */}
                   <a href="/mass-fleets" className="avatar"><img alt="Mass Fleets" src={MassFleet} /></a>
                 </div>
                
                 {/* <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-finance">Finance </a></h4> */}
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-fleets">مركبات - Fleet  </a></h4>
                 <br></br>
                 <a href="/mass-fleets" className="btn btn-white btn-sm m-t-10 buttonspace">جداول </a>
                 <a href="/mass-fleets" className="btn btn-white btn-sm m-t-10">   حاسبات </a>
                 {/* <a href="/mass-finance" className="btn btn-white btn-sm m-t-10 buttonspace"> <AiOutlineLock size={20} className='privacy'/> حاسبات </a>
                 <a href="/mass-finance" className="btn btn-white btn-sm m-t-10">  <AiOutlineLock size={20} className='privacy'/> تقارير</a> */}
               </div>
             </div>
             {/* <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   <a href="/mass-billing" className="avatar"><img src={MassBill} alt="Mass Billing" /></a>
                   <a href="#" className="avatar"><img src={MassBill} alt="Mass Billing" /></a>
                 </div>
                
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-billing">نظام الفوترة </a></h4>
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="#">نظام الفوترة </a></h4>
                 <br></br>
                 <a href="#" className="btn btn-white btn-sm m-t-10 buttonspace"> <AiOutlineLock size={20} className='privacy'/> اعدادات  </a>
                 <a href="#" className="btn btn-white btn-sm m-t-10"> <AiOutlineLock size={20} className='privacy'/> تقارير  </a>
                 <a href="/mass-billing" className="btn btn-white btn-sm m-t-10 buttonspace"> <AiOutlineLock size={20} className='privacy'/> اعدادات  </a>
                 <a href="/mass-billing" className="btn btn-white btn-sm m-t-10"> <AiOutlineLock size={20} className='privacy'/> تقارير  </a>
               </div>
             </div> */}

             {/* <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   <a href="/mass-finance" className="avatar"><img alt="Mass Finance" src={MassFinance} /></a>
                 </div>
                
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/mass-finance">مركبات - Fleet  </a></h4>
                 <br></br>
                 <a href="#" className="btn btn-white btn-sm m-t-10 buttonspace"> <AiOutlineLock size={20} className='privacy'/> حاسبات </a>
                 <a href="#" className="btn btn-white btn-sm m-t-10">  <AiOutlineLock size={20} className='privacy'/> تقارير</a>
               </div>
             </div> */}

            
            


             

             {/* <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
               <div className="profile-widget">
                 <div className="profile-img">
                   <a href="/rtl/app/profile/client-profile" className="avatar"><img alt="" src={MassTax} /></a>
                 </div>
                 <div className="dropdown profile-action">
                   <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                   <div className="dropdown-menu dropdown-menu-right">
                     <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_client"><i className="fa fa-pencil m-r-5" /> Edit</a>
                     <a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_client"><i className="fa fa-trash-o m-r-5" /> Delete</a>
                   </div>
                 </div>
                 <h4 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/rtl/app/profile/client-profile">Carlson Tech</a></h4>
                 <h5 className="user-name m-t-10 mb-0 text-ellipsis"><a href="/rtl/app/profile/client-profile">Betty Carlson</a></h5>
                 <div className="small text-muted">CEO</div>
                 <a href="/rtl/conversation/chat" className="btn btn-white btn-sm m-t-10 buttonspace">Message</a>
                 <a href="/rtl/app/profile/client-profile" className="btn btn-white btn-sm m-t-10">View Profile</a>
               </div>
             </div> */}
           </div>
         </div>
         {/* /Page Content */}
         {/* Add Client Modal */}
         {/* <div id="add_client" className="modal custom-modal fade" role="dialog">
           <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
             <div className="modal-content">
               <div className="modal-header">
                 <h5 className="modal-title">Add Client</h5>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true">×</span>
                 </button>
               </div>
               <div className="modal-body">
                 <form>
                   <div className="row">
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">First Name <span className="text-danger">*</span></label>
                         <input className="form-control" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Last Name</label>
                         <input className="form-control" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Username <span className="text-danger">*</span></label>
                         <input className="form-control" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Email <span className="text-danger">*</span></label>
                         <input className="form-control floating" type="email" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Password</label>
                         <input className="form-control" type="password" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Confirm Password</label>
                         <input className="form-control" type="password" />
                       </div>
                     </div>
                     <div className="col-md-6">  
                       <div className="form-group">
                         <label className="col-form-label">Client ID <span className="text-danger">*</span></label>
                         <input className="form-control floating" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Phone </label>
                         <input className="form-control" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Company Name</label>
                         <input className="form-control" type="text" />
                       </div>
                     </div>
                   </div>
                   <div className="table-responsive m-t-15">
                     <table className="table table-striped custom-table">
                       <thead>
                         <tr>
                           <th>Module Permission</th>
                           <th className="text-center">Read</th>
                           <th className="text-center">Write</th>
                           <th className="text-center">Create</th>
                           <th className="text-center">Delete</th>
                           <th className="text-center">Import</th>
                           <th className="text-center">Export</th>
                         </tr>
                       </thead>
                       <tbody>
                         <tr>
                           <td>Projects</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Tasks</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Chat</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Estimates</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Invoices</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Timing Sheets</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                       </tbody>
                     </table>
                   </div>
                   <div className="submit-section">
                     <button className="btn btn-primary submit-btn">Submit</button>
                   </div>
                 </form>
               </div>
             </div>
           </div>
         </div> */}
         {/* /Add Client Modal */}
         {/* Edit Client Modal */}
         {/* <div id="edit_client" className="modal custom-modal fade" role="dialog">
           <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
             <div className="modal-content">
               <div className="modal-header">
                 <h5 className="modal-title">Edit Client</h5>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true">×</span>
                 </button>
               </div>
               <div className="modal-body">
                 <form>
                   <div className="row">
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">First Name <span className="text-danger">*</span></label>
                         <input className="form-control" defaultValue="Barry" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Last Name</label>
                         <input className="form-control" defaultValue="Cuda" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Username <span className="text-danger">*</span></label>
                         <input className="form-control" defaultValue="barrycuda" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Email <span className="text-danger">*</span></label>
                         <input className="form-control floating" defaultValue="barrycuda@example.com" type="email" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Password</label>
                         <input className="form-control" defaultValue="barrycuda" type="password" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Confirm Password</label>
                         <input className="form-control" defaultValue="barrycuda" type="password" />
                       </div>
                     </div>
                     <div className="col-md-6">  
                       <div className="form-group">
                         <label className="col-form-label">Client ID <span className="text-danger">*</span></label>
                         <input className="form-control floating" defaultValue="CLT-0001" type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Phone </label>
                         <input className="form-control" defaultValue={9876543210} type="text" />
                       </div>
                     </div>
                     <div className="col-md-6">
                       <div className="form-group">
                         <label className="col-form-label">Company Name</label>
                         <input className="form-control" type="text" defaultValue="Global Technologies" />
                       </div>
                     </div>
                   </div>
                   <div className="table-responsive m-t-15">
                     <table className="table table-striped custom-table">
                       <thead>
                         <tr>
                           <th>Module Permission</th>
                           <th className="text-center">Read</th>
                           <th className="text-center">Write</th>
                           <th className="text-center">Create</th>
                           <th className="text-center">Delete</th>
                           <th className="text-center">Import</th>
                           <th className="text-center">Export</th>
                         </tr>
                       </thead>
                       <tbody>
                         <tr>
                           <td>Projects</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Tasks</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Chat</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Estimates</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Invoices</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                         <tr>
                           <td>Timing Sheets</td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                           <td className="text-center">
                             <input defaultChecked type="checkbox" />
                           </td>
                         </tr>
                       </tbody>
                     </table>
                   </div>
                   <div className="submit-section">
                     <button className="btn btn-primary submit-btn">Save</button>
                   </div>
                 </form>
               </div>
             </div>
           </div>
         </div> */}
         {/* /Edit Client Modal */}
         {/* Delete Client Modal */}
         {/* <div className="modal custom-modal fade" id="delete_client" role="dialog">
           <div className="modal-dialog modal-dialog-centered">
             <div className="modal-content">
               <div className="modal-body">
                 <div className="form-header">
                   <h3>Delete Client</h3>
                   <p>Are you sure want to delete?</p>
                 </div>
                 <div className="modal-btn delete-action">
                   <div className="row">
                     <div className="col-6">
                       <a href="" className="btn btn-primary continue-btn">Delete</a>
                     </div>
                     <div className="col-6">
                       <a href="" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div> */}
         {/* /Delete Client Modal */}
       </div>
      );
   }
}

export default MassModules;
