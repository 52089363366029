/**
 * TermsCondition Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import {FiSettings} from 'react-icons/fi';
import {FaSignature, FaPercent} from 'react-icons/fa';
import{Mpayroll, Mpayroll2, MassLow , MassPayroll, MassAsset, MassManage} from '../../../Entryfile/imagepath';
import {IoDocumentTextOutline} from 'react-icons/io5';
import {VscLaw} from 'react-icons/vsc';

import {  BsTable } from "react-icons/bs";
import {  AiOutlineCalculator , AiOutlineLock } from "react-icons/ai";



import Attendance from '../../../pr-system/attendance';
// import Attendance from '../attendance';
export default class MassLowsComp extends Component {
  render() {
    return (
           
		<div className="tab-content">
		{/* Monthly Tab */}
		<div className="tab-pane fade active show" id="monthly">
		  <div className=" equal-height-cards">
			<div className="">
			  <div className="card pricing-box">
				<div className="card-body d-flex flex-column">
				  <div className="mb-4">
					<br />
					<h2 className="settings-title">حاسبات  </h2><hr></hr><br />
					<span className="display-6"><AiOutlineCalculator className='privacy' /></span><br />
					{/* <span>/mo</span> */}
				  </div>
				  <ul className="text-left settings-mass">
				  <li><a href='/calculators/WorkLawsCalc/EndOfServiceCalc'><AiOutlineCalculator size={30} className='privacy' /> احتساب تعويضات نهاية الخدمة     </a></li><br />
				  <li><a href='/naqaha-calculator'><AiOutlineCalculator size={30} className='privacy' /> احتساب النقاهة    </a></li><br />
				  <li><a href='/halanat-skhar'><AiOutlineCalculator size={30} className='privacy' /> فؤائد على تأخير الرواتب   </a></li><br />
				  <li><a href='#'><AiOutlineCalculator size={30} className='privacy' /> احتساب تأخير تعويضات     </a> <AiOutlineLock size={30} className='privacy lock-dash'/> </li><br />
				  <li><a href='#'><AiOutlineCalculator size={30} className='privacy' /> أخرى ...    </a> <AiOutlineLock size={30} className='privacy lock-dash'/></li><br />
				  </ul><br />
				  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
				</div>
			  </div>
			</div>
		  </div>
		</div>
		{/* /Monthly Tab */}
	  </div>
       
    );
  }
}
