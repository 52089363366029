import { useState, useEffect } from 'react'

export const useGoldApi = () => {

  const[priceOfGold, setPriceOfGold] = useState(' ');
  const [goldOz, setOuncePrice] = useState(' ');
  const [ozPriceNumber, setOuncePriceNumber] = useState(0);
  const [silverPriceNumber, setSilverPriceNumber] = useState(0);
  const [goldGram, setGramPrice] = useState(' ');
  const [goldCoin, setGoldCoin] = useState(' ');
  const [goldCoinWeight, setGoldCoinWeight] = useState(7.988);
  const [goldKilo, setKiloPrice] = useState(' ');
  const [silverPricee, setSilverPrice] = useState(' ');
  const ozGrams = 31.1034768
  // Gold Prices 
// ================================================================
useEffect(() => {
   
  const fetchGoldPriceFromAPI = async () => {
    const date = new Date();
    try {
      const proxyUrl = 'https://api.allorigins.win/raw?url=';
      const goldPriceAPIURL = 'https://www.quandl.com/api/v3/datasets/LBMA/GOLD.json?api_key=7K35kndVXAGmSWTvBMdH&start_date=2017-10-09&start_date=2017-10-09';
      const response = await fetch(proxyUrl + encodeURIComponent(goldPriceAPIURL));
      const data = await response.json();
      const index = data.dataset.column_names.indexOf('USD (AM)');
      const priceG = data.dataset.data[0][index];
      // console.log("priceG", priceG)
      setPriceOfGold(priceG);
      calculate(priceG);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
    fetchGoldPriceFromAPI();

},[]);

// ================================================================
  

  // Calculate gold prices by weights 
  const calculate = async (gPrice) => {
    try{
         // console.log("price : ", gPrice)
    const currency_one = "USD"
    // setCap(currency_one);
    const amountEl_one = 1;
   
    const currency_two = "ILS"
      const urll6 = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/${currency_one}`;
     await fetch(urll6)
        .then(res => res.json())
        .then(data => {
          // console.log(data);
          const rate = data.conversion_rates[currency_two];
          // console.log("Rate From URL6 : ", rate);
  
          const rateeEll = `1 ${currency_one}   = ${rate.toFixed(4)} ${currency_two} `;
          
          // console.log("Amout rateeEll : ", rateeEll);
          var amountEl2 = (gPrice * rate);
  
          // console.log("Amout Two Value : ", amountEl2);
          // Ounce Price 
          
          setOuncePrice(amountEl2.toLocaleString(undefined, { maximumFractionDigits: 2 }));
          setOuncePriceNumber(amountEl2);
          
          // Gram Price : 
          var perGramPrice = amountEl2 / 31.10;
          // console.log("gram price :  : ", perGramPrice.toFixed(2));
          setGramPrice(perGramPrice.toLocaleString(undefined, { maximumFractionDigits: 2 }));
          setGoldCoin(perGramPrice.toLocaleString(undefined, { maximumFractionDigits: 2 }));
          setGoldCoin(perGramPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })* goldCoinWeight);
          // KiloGram Price 
          var perKaratPrice = amountEl2 / 0.031;
          const kp = perKaratPrice;
          // console.log("kilo price Gold : ", kp.toLocaleString(undefined, { maximumFractionDigits: 2 }))
          setKiloPrice(kp.toLocaleString(undefined, { maximumFractionDigits: 0 }));
  
        });
    }
    catch(err){
      console.log("cannot fitch ")
    }
  }
  // Silver Price
  useEffect(() => {
    const fetchSilverPriceFromAPI= async()=> {

      var silverPriceAPIURL = "https://data.nasdaq.com/api/v3/datasets/LBMA/SILVER.json?api_key=4d31wzBygf92RQWYyX6B&start_date=2017-10-09&start_date=2017-10-09";
        var date = new Date();
        if (date.getUTCHours() < 17) {
                date.setTime(date.getTime() - (1000 * 60 * 60 * 24));
            }
        const proxyUrl = 'https://api.allorigins.win/raw?url=';
        const response = await fetch(proxyUrl + encodeURIComponent(silverPriceAPIURL));
        const data = await response.json();
        //  + "&start_date=" + date + "&end_date=" + date;
      
          var index = data.dataset.column_names.indexOf("USD");
          // console.log("Silver price index:", typeof(data.dataset.data[0][index]/ 31.10));
          const priceS = (data.dataset.data[0][index]/ 31.10).toFixed(2);
          setSilverPriceNumber((data.dataset.data[0][index]/ 31.10))
          // console.log("Silver Price is : ", priceS);
          // silverPriceNumber(priceS.toFixed(2))
          setSilverPrice(priceS.toLocaleString(undefined, { maximumFractionDigits: 2 }));
      }
      fetchSilverPriceFromAPI();
  },[]);

  return { priceOfGold, goldOz, goldGram,goldKilo,silverPricee, ozPriceNumber, silverPriceNumber, ozGrams , goldCoin , goldCoinWeight}
}