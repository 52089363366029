/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Helmet } from "react-helmet";
import { FaSignature } from "react-icons/fa";
import { AiOutlineFileWord, AiOutlineFilePdf } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si"
import {MdOutlineLocalLibrary} from 'react-icons/md'
class ReportsHeComp extends Component {

  render() {

    return (
      <div >

        {/* Page Content */}
        <div className="content container-fluid card">
          <br></br><br></br>
          <div className="row">

            <h2 className="card-title text-center"> مراسلات ضريبية و تقارير
              <span className="privacy"> ( עברית ) </span> </h2>

            {/*=================================== Report Div ============================================= */}
            <div className="col-sm-12 col-md-12 col-xl-6 card-body ">
              <div className="topics card-he">
                <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> אישורים < FaSignature size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                  <li>
                    <tr>

                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F314.docx?alt=media&token=6c37f09a-c0ba-4df0-a50b-684a9e1aa99b" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                        <ReactTooltip id="downloadTip" place="top" effect="solid" className='tooltipp'>
                          Download .dox
                        </ReactTooltip>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F314.docx?alt=media&token=6c37f09a-c0ba-4df0-a50b-684a9e1aa99b" data-tip data-for="downloadTip" >
                          אישור על ניהול פנקסי חשבונות ורשומות לפי חוק עסקאות
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>

                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F315.docx?alt=media&token=51b459c5-7ab7-43e7-b7f6-42fc375670ea" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F315.docx?alt=media&token=51b459c5-7ab7-43e7-b7f6-42fc375670ea" data-tip data-for="downloadTip">
                          דו"ח הכנסות השנתי
                        </a>
                      </td>
                    </tr>
                  </li>
                    <li>
                      <td><a></a></td>
                      <td></td>
                    </li>
                </ul>
              </div>
            </div>

            {/* ========================= Report Div ================================== */}
            <div className="col-sm-12 col-md-12 col-xl-6 card-body ">
              <div className="topics card-he">
                <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> מכתבים - לקוחות < FaSignature size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                  <li>
                    <tr>

                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F323.docx?alt=media&token=46282c81-189f-4bab-b49d-de2f82be3aa8" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F323.docx?alt=media&token=46282c81-189f-4bab-b49d-de2f82be3aa8" data-tip data-for="downloadTip">
                          נתונים להצהרת הון
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>

                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F325.docx?alt=media&token=dddbf9cd-a262-4fbe-8932-206854195b20" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F325.docx?alt=media&token=dddbf9cd-a262-4fbe-8932-206854195b20" data-tip data-for="downloadTip">
                          הצהרת עוסק מייצג
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <td>
                    <a href="/dictionary" ><MdOutlineLocalLibrary size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>

                    </td>
                    
                    <td>
                      <a href='/dictionary' className=''> قاموس <span className='privacy'> MaasPortal </span> التجاري </a>

                    </td>
                  </li>


                </ul>
              </div>
            </div>
            {/* More Button  */}
            {/* <div className="col-sm-12 col-md-12 col-xl-2 card body">
            More
          </div> */}

            {/* More Button  */}
          </div>
          <div className=' text-center button-height'>
            <a href='/reports-he' className='btn btn-primary calc-button'> المزيد... </a>

          </div>
          <br></br>
        </div>
        {/* /Page Content */}
      </div>

    );
  }
}

export default ReportsHeComp;
