let h= [
 {
  ar: "هبة",
  he: "תרומה",
  en: "Contribution\/ donation"
 },
 {
  ar: "هبوط",
  he: "ירידה",
  en: "Decline"
 },
 {
  ar: "هبوط الاسعار",
  he: "נפילת מחירים",
  en: "Fall in prices"
 },
 {
  ar: "هبوط القيمة المعجل",
  he: "פחת מואץ",
  en: "Accelerated depreciation"
 },
 {
  ar: "هبوط القيمة بالاستعمال",
  he: "פחת",
  en: "depreciation"
 },
 {
  ar: "هبوط قيمة العملة",
  he: "פיחות",
  en: "Depreciation\/ devaluation"
 },
 {
  ar: "هجرة الرساميل",
  he: "נדידת הון",
  en: "Exodus of capital"
 },
 {
  ar: "هدية",
  he: "מתנה",
  en: "Gift"
 },
 {
  ar: "هروب الراسمال",
  he: "בריחת הון",
  en: "Flight of capital"
 },
 {
  ar: "هندسة صناعية",
  he: "הנדסת תעשייה",
  en: "Industrial engineering"
 },
 {
  ar: "هيئة لا تستهدف الربح",
  he: "מוסד ללא כוונת רווח",
  en: "Non- profit association\/"
 },
 {
  ar: null,
  he: null,
  en: " non- profit organization"
 },
 {
  ar: "هيكل اساسي",
  he: "תשתית",
  en: "infrastructure"
 }
]
export default h;