/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { TaxCalculatorComponent21 } from '../../../../../calculators/components/TaxCalculatorComponent21';
import { TaxCalculatorComponent22 } from '../../../../../calculators/components/TaxCalculatorComponent22';
import Itt21 from '../../../../Tables/basic21';
import Itt22 from '../../../../Tables/basic22/itt22';
export default class EmpBillCalc extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | Taxes</title>
					<meta name="description" content="Reactify Blank Page" />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					{/* Page Header */}
					<div className="page-header">
						<div className="row">
							<div className="col-sm-12">
								<h3 className="page-title">فحص ضريبة الدخل</h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><a href="/home">الرئيسية</a></li>
									{/* <li className="breadcrumb-item active"></li> */}
								</ul>
							</div>
						</div>
					</div>
					{/* /Page Header */}
					{/* Content Starts */}
					{/* Tax Calculators  */}
					<br></br>
						<br></br>
					<div className='row w-100'>
					
						<div className='col-s-12 col-md-6 '>
							<TaxCalculatorComponent22 />
						</div>
						
						<div className='col-xs-12 col-md-6'>
							<TaxCalculatorComponent21 />
						</div>
						
						<Itt21 />
						<Itt22 />
						{/* <Itt22 /> */}
					</div>
				
					{/* /Content End */}
				</div>
				{/* /Page Content */}
			</div>

		);
	}
}
