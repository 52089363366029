let taxProperty = [
  {
    yearMil:1642291200000,
    date: "16/01/2022",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1902945
    },
    band2: {
      rate: "5",
      min: 1902945,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1610755200000,
    date: "16/01/2021",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1842155
    },
    band2: {
      rate: "5",
      min: 1842155,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1579132800000,
    date: "16/01/2020",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1838615
    },
    band2: {
      rate: "5",
      min: 1838615,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1547596800000,
    date: "16/01/2019",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1788285
    },
    band2: {
      rate: "5",
      min: 1788285,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1516060800000,
    date: "16/01/2018",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1672385
    },
    band2: {
      rate: "5",
      min: 1672385,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1484524800000,
    date: "16/01/2017",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1759310
    },
    band2: {
      rate: "5",
      min: 1759310,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1452902400000,
    date: "16/01/2016",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1734225
    },
    band2: {
      rate: "5",
      min: 1734225,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1421366400000,
    date: "16/01/2015",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1700220
    },
    band2: {
      rate: "5",
      min: 1700220,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1389830400000,
    date: "16/01/2014",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1644310
    },
    band2: {
      rate: "5",
      min: 1644310,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil:1358294400000,
    date: "16/01/2013",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1593755
    },
    band2: {
      rate: "5",
      min: 1593755,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1326672000000,
    date: "16/01/2012",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1540855
    },
    band2: {
      rate: "5",
      min: 1540855,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1295136000000,
    date: "16/01/2011",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1463035
    },
    band2: {
      rate: "5",
      min: 1463035,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1263600000000,
    date: "16/01/2010",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1393220
    },
    band2: {
      rate: "5",
      min: 1393220,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1232064000000,
    date: "16/01/2009",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1318400
    },
    band2: {
      rate: "5",
      min: 1318400,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1200441600000,
    date: "16/01/2008",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1165670
    },
    band2: {
      rate: "5",
      min: 1165670,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1192492800000,
    date: "16/10/2007",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1187840
    },
    band2: {
      rate: "5",
      min: 1187840,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1184544000000,
    date: "16/07/2007",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1127190
    },
    band2: {
      rate: "5",
      min: 1127190,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1176681600000,
    date: "16/04/2007",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1123730
    },
    band2: {
      rate: "5",
      min: 1123730,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1168905600000,
    date: "16/01/2007",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1043310
    },
    band2: {
      rate: "5",
      min: 1043310,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1160956800000,
    date: "16/10/2006",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1077095
    },
    band2: {
      rate: "5",
      min: 1077095,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1153008000000,
    date: "16/07/2006",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1075925
    },
    band2: {
      rate: "5",
      min: 1075925,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1145145600000,
    date: "16/04/2006",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1115570
    },
    band2: {
      rate: "5",
      min: 1115570,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1137369600000,
    date: "16/01/2006",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1113230
    },
    band2: {
      rate: "5",
      min: 1113230,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1129420800000,
    date: "16/10/2005",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1088750
    },
    band2: {
      rate: "5",
      min: 1088750,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1121472000000,
    date: "16/07/2005",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1046875
    },
    band2: {
      rate: "5",
      min: 1046875,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1113609600000,
    date: "16/04/2005",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1029395
    },
    band2: {
      rate: "5",
      min: 1029395,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1105833600000,
    date: "16/01/2005",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1042240
    },
    band2: {
      rate: "5",
      min: 1042240,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1097884800000,
    date: "16/10/2004",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1087695
    },
    band2: {
      rate: "5",
      min: 1087695,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1089936000000,
    date: "16/07/2004",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1085350
    },
    band2: {
      rate: "5",
      min: 1085350,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1082073600000,
    date: "16/04/2004",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1070215
    },
    band2: {
      rate: "5",
      min: 1070215,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1074211200000,
    date: "16/01/2004",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1070215
    },
    band2: {
      rate: "5",
      min: 1070215,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1072915200000,
    date: "01/01/2004",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1088870
    },
    band2: {
      rate: "5",
      min: 1088870,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1066262400000,
    date: "16/10/2003",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1088870
    },
    band2: {
      rate: "4.75",
      min: 1088870,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1058313600000,
    date: "16/07/2003",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1072560
    },
    band2: {
      rate: "4.75",
      min: 1072560,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1050451200000,
    date: "16/04/2003",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1158840
    },
    band2: {
      rate: "4.75",
      min: 1158840,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1042675200000,
    date: "16/01/2003",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1150785
    },
    band2: {
      rate: "4.75",
      min: 1150785,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1041379200000,
    date: "01/01/2003",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1169845
    },
    band2: {
      rate: "4.75",
      min: 1169845,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1034726400000,
    date: "16/10/2002",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1169845
    },
    band2: {
      rate: "4.5",
      min: 1169845,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1026777600000,
    date: "16/07/2002",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1210975
    },
    band2: {
      rate: "4.5",
      min: 1210975,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1018915200000,
    date: "16/04/2002",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1151810
    },
    band2: {
      rate: "4.5",
      min: 1151810,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1011139200000,
    date: "16/01/2002",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1064610
    },
    band2: {
      rate: "4.5",
      min: 1064610,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1005091200000,
    date: "07/11/2001",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1065630
    },
    band2: {
      rate: "4.5",
      min: 1065630,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 1003190400000,
    date: "16/10/2001",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1065630
    },
    band2: {
      rate: "5",
      min: 1065630,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 995241600000,
    date: "16/07/2001",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1031535
    },
    band2: {
      rate: "5",
      min: 1031535,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 987379200000,
    date: "16/04/2001",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1027510
    },
    band2: {
      rate: "5",
      min: 1027510,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 979603200000,
    date: "16/01/2001",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1008550
    },
    band2: {
      rate: "5",
      min: 1008550,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 971654400000,
    date: "16/10/2000",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1000400
    },
    band2: {
      rate: "5",
      min: 1000400,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 963705600000,
    date: "16/07/2000",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1015685
    },
    band2: {
      rate: "5",
      min: 1015685,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 955843200000,
    date: "16/04/2000",
    band1: {
      rate: "0.5",
      min: 0,
      max: 985055
    },
    band2: {
      rate: "5",
      min: 985055,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 947980800000,
    date: "16/01/2000",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1039180
    },
    band2: {
      rate: "5",
      min: 1039180,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 946684800000,
    date: "01/01/2000",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1076180
    },
    band2: {
      rate: "5",
      min: 1076180,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 940032000000,
    date: "16/10/1999",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1076180
    },
    band2: {
      rate: "4.5",
      min: 1076180,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 932083200000,
    date: "16/07/1999",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1016705
    },
    band2: {
      rate: "4.5",
      min: 1016705,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 924220800000,
    date: "16/04/1999",
    band1: {
      rate: "0.5",
      min: 0,
      max: 995260
    },
    band2: {
      rate: "4.5",
      min: 995260,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 916444800000,
    date: "16/01/1999",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1059515
    },
    band2: {
      rate: "4.5",
      min: 1059515,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 908496000000,
    date: "16/10/1998",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1033010
    },
    band2: {
      rate: "4.5",
      min: 1033010,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 900547200000,
    date: "16/07/1998",
    band1: {
      rate: "0.5",
      min: 0,
      max: 1003965
    },
    band2: {
      rate: "4.5",
      min: 1003965,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 892684800000,
    date: "16/04/1998",
    band1: {
      rate: "0.5",
      min: 0,
      max: 995300
    },
    band2: {
      rate: "4.5",
      min: 995300,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 884908800000,
    date: "16/01/1998",
    band1: {
      rate: "0.5",
      min: 0,
      max: 982560
    },
    band2: {
      rate: "4.5",
      min: 982560,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 876960000000,
    date: "16/10/1997",
    band1: {
      rate: "0.5",
      min: 0,
      max: 991225
    },
    band2: {
      rate: "4.5",
      min: 991225,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 869011200000,
    date: "16/07/1997",
    band1: {
      rate: "0.5",
      min: 0,
      max: 972875
    },
    band2: {
      rate: "4.5",
      min: 972875,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 861148800000,
    date: "16/04/1997",
    band1: {
      rate: "0.5",
      min: 0,
      max: 949945
    },
    band2: {
      rate: "4.5",
      min: 949945,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  },
  {yearMil: 853372800000,
    date: "16/01/1997",
    band1: {
      rate: "0.5",
      min: 0,
      max: 916820
    },
    band2: {
      rate: "4.5",
      min: 916820,
      max:"أكثر من ذلك"
    },
    band3:{
      min:""
    }
  }
] 
export default {taxProperty}