let shen =[
 {
  ar: "شاحن",
  he: "משגר (שולח סחורה)",
  en: "Consigner"
 },
 {
  ar: null,
  he: "שולח",
  en: "dispatcher"
 },
 {
  ar: "شبة احتكار",
  he: "מעין מונופול",
  en: "Quasi- monopoly"
 },
 {
  ar: "شبة الربح",
  he: "מעין רנטה",
  en: "Quasi- rent"
 },
 {
  ar: "شبة نقود",
  he: "נכס נזיל",
  en: "Near money\/ liquid asset"
 },
 {
  ar: "شح",
  he: "קמצנות",
  en: "stinginess"
 },
 {
  ar: "شحن",
  he: "משלוח",
  en: "Shipment\/ delivery"
 },
 {
  ar: null,
  he: "מטען",
  en: "lading\/ cargo\/ freight"
 },
 {
  ar: "شحن جوي",
  he: "שליחה בדרך האוויר",
  en: "Air freight"
 },
 {
  ar: "شحن فوري",
  he: "משלוח מידי",
  en: "Prompt shipment"
 },
 {
  ar: "شراء",
  he: "רכישה\/ קנייה",
  en: "Purchase\/ buying"
 },
 {
  ar: "شراء بالتقسيط",
  he: "קנייה בתשלומים",
  en: "Installment purchase\/ "
 },
 {
  ar: null,
  he: null,
  en: "installment buying"
 },
 {
  ar: null,
  he: "ליסינג\/ שכר- מכר",
  en: "leasing"
 },
 {
  ar: "شراء ثانية",
  he: "קנייה בחזרה",
  en: "repurchase"
 },
 {
  ar: "شراء متبادل",
  he: "קנייה גומלין",
  en: "Reciprocal buying"
 },
 {
  ar: "شراء متكافئ",
  he: "רכישה נגדית",
  en: "Counter purchase"
 },
 {
  ar: "شرط",
  he: "תנאי",
  en: "condition"
 },
 {
  ar: "شركة",
  he: "חברה",
  en: "Company\/ corporation"
 },
 {
  ar: null,
  he: "פירמה",
  en: "firm"
 },
 {
  ar: "شركة ائتمانية",
  he: "חברת נאמנות\/ חברה לנאמנות",
  en: "Trust company"
 },
 {
  ar: "شركة اجنبية",
  he: "חברה זרה",
  en: "Foreign corporation"
 },
 {
  ar: "شركة استثمار",
  he: "חברת השקעות\/ חברה להשקעות",
  en: "Investment trust\/ investment company"
 },
 {
  ar: "شركة استثمار مغلقة الراسمال",
  he: "חברת השקעות סגורה",
  en: "Closed end investment company"
 },
 {
  ar: "شركة اشخاص",
  he: "שותפות",
  en: "partnership"
 },
 {
  ar: "شركة ام",
  he: "חברת אם",
  en: "Parent company"
 },
 {
  ar: "شركة بسيطة",
  he: "שותפות",
  en: "partnership"
 },
 {
  ar: "شركة بسيطة احد اعضاؤها على الاقل خفي",
  he: "שותפות שלפחות אחד מחבריה סמוי",
  en: "Secret partnership"
 },
 {
  ar: "شركة بسيطة عامة",
  he: "שותפות כללית",
  en: "General partnership"
 },
 {
  ar: "شركة تابعة",
  he: "חברת בת",
  en: "subsidiary"
 },
 {
  ar: "شركة تامين",
  he: "חברת ביטוח",
  en: "Insurance company"
 },
 {
  ar: "شركة تحصيل ديون تجارية",
  he: "חרה לגבייה",
  en: "Factoring company"
 },
 {
  ar: "شركة تضامن",
  he: "שותפות",
  en: "partnership"
 },
 {
  ar: "شركة توصية بسيطة",
  he: "שותפות מוגבלת",
  en: "Limited partnership"
 },
 {
  ar: "شركة حكومية",
  he: "חברה ממשלתית",
  en: "Government corporation"
 },
 {
  ar: "شركة خاصة",
  he: "חברה פרטית",
  en: "Private company"
 },
 {
  ar: "شركة ذات مسؤولية محدودة",
  he: "חברה בעירבון מוגבל",
  en: "Limited company"
 },
 {
  ar: "شركة زميلة",
  he: "חברה קשורה",
  en: "Affiliated company"
 },
 {
  ar: "شركة شحن",
  he: "חברת הובלות",
  en: "Carloading company"
 },
 {
  ar: "شركة عامة",
  he: "חברה ציבורית",
  en: "Public company"
 },
 {
  ar: "شركة عبر الشاطئ",
  he: "חברה מעבר לחוף (חברה המהווה מקלט מס)",
  en: "Off shore company"
 },
 {
  ar: "شركة غير مساهمة",
  he: "חברה שאינה חברת מניות",
  en: "Non shore company"
 },
 {
  ar: "شركة قابضة",
  he: "חברת החזקות",
  en: "Holding company"
 },
 {
  ar: "شركة متعددة القوميات",
  he: "חברה רב לאומית",
  en: "International company\/ multinational company"
 },
 {
  ar: "شركة محاصة",
  he: "חברת מניות בעירבון מוגבל",
  en: "Joint stock company"
 },
 {
  ar: "شركة محلية",
  he: "חברה מקומית",
  en: "Local company"
 },
 {
  ar: "شركة مساهمة",
  he: "חברת מניות בעירבון מוגבל",
  en: "Joint stock company"
 },
 {
  ar: "شركة مسجلة",
  he: "חברה רשומה",
  en: "Registered company"
 },
 {
  ar: "شركة منتسبة",
  he: "חברה קשורה",
  en: "Affiliated company"
 },
 {
  ar: "شركة منحلة",
  he: "חברה מחוקה",
  en: "Defunct company"
 },
 {
  ar: "شركة نفط",
  he: "חברת נפט",
  en: "Oil company"
 },
 {
  ar: "شروط التسليف",
  he: "תנאי אשראי",
  en: "Credit terms"
 },
 {
  ar: "شروط التسليم",
  he: "תנאי המסירה",
  en: "Terms of delivery"
 },
 {
  ar: "شروط الدفع",
  he: "תנאי התשלום",
  en: "Terms of payment"
 },
 {
  ar: "شروط العقد",
  he: "תנאי החוזה",
  en: "Terms of contract"
 },
 {
  ar: "شروط مؤاتية",
  he: "תנאים נאותים",
  en: "Favourable terms"
 },
 {
  ar: "شريك",
  he: "שותף",
  en: "partner"
 },
 {
  ar: "شريك اصغر",
  he: "שותף זוטר",
  en: "Junior partner"
 },
 {
  ar: "شريك خفي",
  he: "שותף סמוי",
  en: "Latent partner\/ secret partner "
 },
 {
  ar: "شريك عامل",
  he: "שותף פעיל",
  en: "Acting partner\/ active partner"
 },
 {
  ar: "شريك متضامن",
  he: "שותף פעיל",
  en: "Acting partner\/ active partner"
 },
 {
  ar: "شريك موحى",
  he: "שותף סמוי",
  en: "Latent partner\/ secret partner"
 },
 {
  ar: "شريك نشط",
  he: "שותף פעיל",
  en: "Acting partner\/ active partner"
 },
 {
  ar: "شطب",
  he: "מחיקה",
  en: "Write off"
 },
 {
  ar: "شغل",
  he: "עבודה",
  en: "Work\/ labour\/ job"
 },
 {
  ar: "شهادة",
  he: "תעודה",
  en: "Certificate\/ scrip\/ note"
 },
 {
  ar: "شهادة اسهم",
  he: "תעודת בעלות על מניה",
  en: "Share certificate"
 },
 {
  ar: "شهادة ايداع",
  he: "תעודת פיקדון",
  en: "Certificate of deposit"
 },
 {
  ar: "شهادة تاسيس الشركة",
  he: "תעודת רישום",
  en: "Certificate of incorporation"
 },
 {
  ar: "شهادة تامين",
  he: "תעודת ביטוח",
  en: "Certificate of insurance"
 },
 {
  ar: "شهادة تقدير الاضرار",
  he: "תעודת הערכת נזק",
  en: "Certificate of damage"
 },
 {
  ar: "شهادة منشأ",
  he: "תעודת מקור",
  en: "Certificate of origin"
 },
 {
  ar: "شهرة",
  he: "מוניטין",
  en: "reputation"
 },
 {
  ar: "شيك",
  he: "שיק\/ המחאה",
  en: "Check\/ cheque\/ draft"
 },
 {
  ar: "شيك زائف",
  he: "שיק מזויף",
  en: "False cheque"
 },
 {
  ar: "شيك سياحي",
  he: "המחאת נוסעים",
  en: "Traveller's cheque"
 },
 {
  ar: "شيك على بياض",
  he: "שיק בלנקו",
  en: "Blank cheque"
 },
 {
  ar: "شيك للمستفيد",
  he: "שיק למוטב",
  en: "Payee cheque"
 },
 {
  ar: "شيك متقادم",
  he: "שיק שפג תוקפו",
  en: "Stale cheque"
 },
 {
  ar: "شيك مردود",
  he: "שיק ללא כיסוי",
  en: "Dishonoured cheque\/ rubber cheque"
 },
 {
  ar: "شيك مرفوض",
  he: "שיק לא כיסוי",
  en: "Dishonoured cheque\/ rubber cheque"
 },
 {
  ar: "شيك مسطر",
  he: "שיק מסורטט",
  en: "Crossed cheque"
 },
 {
  ar: "شيك مصرفي",
  he: "שיק בנקאי",
  en: "Banker's draft\/ bank cheque "
 },
 {
  ar: "شيك معلق",
  he: "שיק שטרם נפרע",
  en: "Outstanding cheque"
 },
 {
  ar: "شيك مفتوح",
  he: "שיק פתוח",
  en: "Open cheque"
 },
 {
  ar: "شيك مقابل",
  he: "שיק נגדי",
  en: "Counter cheque"
 },
 {
  ar: "شيك ملغى",
  he: "שיק מבוטל",
  en: "Cancelled cheque"
 },
 {
  ar: "شيك يحمل تاريخا لاحقا",
  he: "שיק דחוי",
  en: "Post dated cheque"
 }
]
export default shen ;