/**
 * Signin Firebase
 */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../hooks/useLogin';
import { Helmet } from "react-helmet";
// import {Applogo} from '../Entryfile/imagepath.jsx'
import {massportallogo, massPortall} from '../../Entryfile/imagepath';

export default function Loginpage(){

  //  state = {
  //     email: 'demo@example.com',
  //     password: 'test#123'
  //  }
      
  //  loginclick(){
  //   // this.props.history.push("/rtl/app/main/dashboard")
  //   localStorage.setItem("firstload","true")
  //  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, error, isPending } = useLogin()
  // let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }


     
      return (       
        <div className="page-wrapper">   
         <div className="main-wrapper">
           <Helmet>
               <title>MassPortal | Login</title>
               <meta name="description" content="Login page"/>					
         </Helmet>
        <div className="account-content">
          {/* <a href="/rtl/applyjob/joblist" className="btn btn-primary apply-btn">Apply Job</a> */}
          <div className="container">
            {/* Account Logo */}
            <div className="account-logo">
              <a href="/"><img src={massPortall} alt="Mass Portal" /></a>
            </div>
            {/* /Account Logo */}
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">تسجيل الدخول</h3>
                {/* <p className="account-subtitle">Access to our dashboard</p> */}
                {/* Account Form */}
                <form onSubmit={handleSubmit} >

                  <div className="form-group">

                    <label>البريد الالكتروني</label>
                    <input className="form-control" 
                      type="text"
                      onChange={(e) => setEmail(e.target.value)} 
                      value={email}
                    />
                  </div>
                  <div className="form-group">
                    
                    <div className="row">
                      <div className="col">
                        <label>كلمة المرور</label>
                      </div>
                      <div className="col-auto">
                        <a className="text-muted" href="/forgotpassword">
                          نسيت كلمة السر ؟ 
                        </a>
                      </div>
                    </div>
                    <input className="form-control" 
                      type="password"
                      onChange={(e) => setPassword(e.target.value)} 
                      value={password}
                     />
                  </div>
                  <div className="form-group text-center">
                    {/* <a className="btn btn-primary account-btn" href="/home">
                    Login</a> */}

                    { !isPending && <button className="btn btn-primary account-btn">تسجيل الدخول</button> }
                    { isPending && <button className="btn btn-primary account-btn" disabled>تحميل</button> }
                    { error && <p>{error}</p> }
                  </div>
                  <div className="account-footer">
                    <p>ليس لديك حساب? <a href="/register">انشاء حساب جديد</a></p>
                  </div>
                </form>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      );
   }



