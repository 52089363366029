/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Helmet } from "react-helmet";
import { IoDocumentTextOutline } from "react-icons/io5";
import { AiOutlineFileWord, AiOutlineCalculator } from "react-icons/ai";
import { VscStarEmpty } from "react-icons/vsc"
import { MdOutlineLocalLibrary } from 'react-icons/md'
class MpNew extends Component {

  render() {
    return (
      <div >
        {/* Page Content */}
        <div className="content container-fluid card">
          <br></br><br></br>
          <div className="row">

            <h2 className="card-title text-center"> جديد  <span className='privacy'> MassPortal</span></h2>

            {/*=================================== Report Div ============================================= */}
            <div className="col-sm-12 col-md-12 col-xl-6 card-body ">
              <div className="topics card-he">
                <h3 className="topic-title text-center">< VscStarEmpty size={30} className="privacy" /> حاسبات  < VscStarEmpty size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                  <li>
                    <tr>
                      <td>
                          <a href='/mass-reports-gold-silver'><AiOutlineCalculator size={25} className="privacy contract-icon" /> احتساب معادن, ذهب, فضة   </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                      <td>
													<a href='/nationalIns-calculator'><AiOutlineCalculator size={25} className='privacy' /> حساب التأمين الوطني   </a>
                      </td>
                    </tr>
                  </li>
                </ul>
              </div>
            </div>

            {/* ========================= Report Div ================================== */}
            <div className="col-sm-12 col-md-12 col-xl-6 card-body ">
              <div className="topics card-he">
                <h3 className="topic-title text-center">< VscStarEmpty size={30} className="privacy" /> تقارير < VscStarEmpty size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                  <li>
                    <tr>
                      <td>
												<a href="calculators/open-vat-calc" > <AiOutlineCalculator size={25} className='privacy' />   احسب ضريبتك في خمس دقائق  </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                    <td>
                    <a href='/mass-revsaile-rep' ><IoDocumentTextOutline size={25} className='privacy'/>  تحضير بيان أرباح و خسائر  </a>
                    </td>
                    </tr>
                  </li>
                </ul>
              </div>
            </div>
            {/* More Button  */}
            {/* <div className="col-sm-12 col-md-12 col-xl-2 card body">
            More
          </div> */}

            {/* More Button  */}
          </div>
          <div className=' text-center button-height'>
            <a href='/reports-he' className='btn btn-primary calc-button'> المزيد... </a>

          </div>
          <br></br>
        </div>
        {/* /Page Content */}
      </div>

    );
  }
}

export default MpNew;
