import React from 'react'
import { TxTblOneTimeShebah } from './tx-tbl-oneTime-shebah'

export default function TxTblOneTime() {
  return (
    <div className="col-sm-24 col-md-12  dates-div">

        <TxTblOneTimeShebah />
    </div>
  )
}
