 let fuelPrice = [
  {
    
    year:2021,
    
  band1:{
    date: "01/12/2021",
    price: 6.38,
    
  },
  band2:{
    date: "01/11/2021",
    price: 6.62,
    
  },
  band3:{
    date: "01/10/2021",
    price: 6.39,
    
  },
  band4:{
    date: "01/09/2021",
    price: 6.31,
    
  },
  band5:{
    date: "01/08/2021",
    price: 6.36,
    
  },
  band6:{
    date: "01/07/2021",
    price: 6.31,
    
  },
  band7:{
    date: "01/06/2021",
    price: 6.13,
    
  },
  band8:{
    date: "01/05/2021",
    price: 6.06,
    
  },
  band9:{
    date: "01/04/2021",
    price: 6.04,
    
  },
  band10:{
    date: "01/03/2021",
    price: 5.99,
    
  },
  band11:{
    date: "01/02/2021",
    price: 5.72,
    
  },
  band12:{
    date: "01/01/2021",
    price: 5.51,
    
  }},
  {
    year:2020,
band1:    {
    date: "01/12/2020",
    price: 5.43,
    
  },
  band2:{
    date: "01/11/2020",
    price: 5.34,
    
  },
  band3:{
    date: "01/10/2020",
    price: 5.46,
    
  },
  band4:{
    date: "01/09/2020",
    price: 5.5,
    
  },
  band5:{
    date: "02/08/2020",
    price: 5.45,
    
  },
  band6:{
    date: "01/07/2020",
    price: 5.44,
    
  },
  band7:{
    date: "01/06/2020",
    price: 5.21,
    
  },
  band8:{
    date: "01/05/2020",
    price: 4.79,
    
  },
  band9:{
    date: "01/04/2020",
    price: 4.89,
    
  },
  band10:{
    date: "01/03/2020",
    price: 5.92,
    
  },
  band11:{
    date: "02/02/2020",
    price: 5.99,
    
  },
  band12:{
    date: "01/01/2020",
    price: 6.14,
    
  }},
 { 
  
  year:2019,
  band1:{
    date: "01/12/2019",
    price: 6.16,
    
  },
  band2:{
    date: "01/11/2019",
    price: 6.12,
    
  },
  band3:{
    date: "01/10/2019",
    price: 6.18,
    
  },
  band4:{
    date: "01/09/2019",
    price: 6.07,
    
  },
  band5:{
    date: "01/08/2019",
    price: 6.26,
    
  },
  band6:{
    date: "01/07/2019",
    price: 6.25,
    
  },
  band7:{
    date: "01/06/2019",
    price: 6.37,
    
  },
  band8:{
    date: "01/05/2019",
    price: 6.51,
    
  },
  band9:{
    date: "01/04/2019",
    price: 6.32,
    
  },
  band10:{
    date: "01/03/2019",
    price: 6.12,
    
  },
  band11:{
    date: "01/02/2019",
    price: 5.91,
    
  },
  band12:{
    date: "01/01/2019",
    price: 5.83,
    
  }},
  {
    
    year:2018,
band1:    {
    date: "01/12/2018",
    price: 6.01,
    
  },
  band2:{
    date: "01/11/2018",
    price: 6.48,
    
  },
  band3:{
    date: "02/10/2018",
    price: 6.59,
    
  },
  band4:{
    date: "02/09/2018",
    price: 6.59,
    
  },
  band5:{
    date: "01/08/2018",
    price: 6.57,
    
  },
  band6:{
    date: "01/07/2018",
    price: 6.49,
    
  },
  band7:{
    date: "01/06/2018",
    price: 6.6,
    
  },
  band8:{
    date: "01/05/2018",
    price: 6.46,
    
  },
  band9:{
    date: "01/04/2018",
    price: 6.35,
    
  },
  band10:{
    date: "01/03/2018",
    price: 6.18,
    
  },
  band11:{
    date: "01/02/2018",
    price: 6.3,
    
  },
  band12:{
    date: "01/01/2018",
    price: 6.19,
    
  }},
  {
    
    year:2017,
band1:    {
    date: "01/12/2017",
    price: 6.14,
    
  },
  band2:{
    date: "01/11/2017",
    price: 6.06,
    
  },
  band3:{
    date: "01/10/2017",
    price: 6.06,
    
  },
  band4:{
    date: "01/09/2017",
    price: 6,
    
  },
  band5:{
    date: "01/08/2017",
    price: 5.9399999999999995,
    
  },
  band6:{
    date: "02/07/2017",
    price: 5.76,
    
  },
  band7:{
    date: "01/06/2017",
    price: 5.99,
    
  },
  band8:{
    date: "01/05/2017",
    price: 6.02,
    
  },
  band9:{
    date: "02/04/2017",
    price: 5.96,
    
  },
  band10:{
    date: "01/03/2017",
    price: 6.1,
    
  },
  band11:{
    date: "01/02/2017",
    price: 6.16,
    
  },
  band12:{
    date: "01/01/2017",
    price: 6.16,
    
  }},
 { 
  
  year:2016,
  band1:{
    date: "01/12/2016",
    price: 5.96,
    
  },
  band2:{
    date: "01/11/2016",
    price: 6.03,
    
  },
  band3:{
    date: "02/10/2016",
    price: 5.89,
    
  },
  band4:{
    date: "01/09/2016",
    price: 5.96,
    
  },
  band5:{
    date: "01/08/2016",
    price: 5.6899999999999995,
    
  },
  band6:{
    date: "01/07/2016",
    price: 5.96,
    
  },
  band7:{
    date: "01/06/2016",
    price: 6.01,
    
  },
  band8:{
    date: "01/05/2016",
    price: 5.85,
    
  },
  band9:{
    date: "01/04/2016",
    price: 5.84,
    
  },
  band10:{
    date: "01/03/2016",
    price: 5.54,
    
  },
  band11:{
    date: "01/02/2016",
    price: 5.73,
    
  },
  band12:{
    date: "01/01/2016",
    price: 5.78,
    
  }},
  {
    
    year:2015,
band1:    {
    date: "01/12/2015",
    price: 5.99,
    
  },
  band2:{
    date: "01/11/2015",
    price: 5.96,
    
  },
  band3:{
    date: "01/10/2015",
    price: 6.07,
    
  },
  band4:{
    date: "01/09/2015",
    price: 6.09,
    
  },
  band5:{
    date: "02/08/2015",
    price: 6.38,
    
  },
  band6:{
    date: "01/07/2015",
    price: 6.65,
    
  },
  band7:{
    date: "01/06/2015",
    price: 6.64,
    
  },
  band8:{
    date: "01/05/2015",
    price: 6.63,
    
  },
  band9:{
    date: "01/04/2015",
    price: 6.52,
    
  },
  band10:{
    date: "01/03/2015",
    price: 6.46,
    
  },
  band11:{
    date: "01/02/2015",
    price: 6.08,
    
  },
  band12:{
    date: "01/01/2015",
    price: 6.27,
    
  }},
  {
    year:2014,
band1:    {
    date: "01/12/2014",
    price: 6.9,
    
  },
  band2:{
    date: "01/11/2014",
    price: 7.05,
    
  },
  band3:{
    date: "01/10/2014",
    price: 7.32,
    
  },
  band4:{
    date: "01/09/2014",
    price: 7.39,
    
  },
  band5:{
    date: "01/08/2014",
    price: 7.4,
    
  },
  band6:{
    date: "01/07/2014",
    price: 7.62,
    
  },
  band7:{
    date: "01/06/2014",
    price: 7.52,
    
  },
  band8:{
    date: "01/05/2014",
    price: 7.57,
    
  },
  band9:{
    date: "01/04/2014",
    price: 7.5,
    
  },
  band10:{
    date: "02/03/2014",
    price: 7.53,
    
  },
  band11:{
    date: "02/02/2014",
    price: 7.36,
    
  },
  band12:{
    date: "01/01/2014",
    price: 7.45,
    
  }},
  {
    
    year:2013,
band1:    {
    date: "01/12/2013",
    price: 7.4,
    
  },
  band2:{
    date: "01/11/2013",
    price: 7.32,
    
  },
  band3:{
    date: "01/10/2013",
    price: 7.42,
    
  },
  band4:{
    date: "01/09/2013",
    price: 7.85,
    
  },
  band5:{
    date: "01/08/2013",
    price: 7.6,
    
  },
  band6:{
    date: "01/07/2013",
    price: 7.43,
    
  },
  band7:{
    date: "02/06/2013",
    price: 7.52,
    
  },
  band8:{
    date: "01/05/2013",
    price: 7.26,
    
  },
  band9:{
    date: "02/04/2013",
    price: 7.57,
    
  },
  band10:{
    date: "01/03/2013",
    price: 7.95,
    
  },
  band11:{
    date: "01/02/2013",
    price: 7.83,
    
  },
  band12:{
    date: "01/01/2013",
    price: 7.52,
    
  }},
  {
    year:2012,
band1:    {
    date: "02/12/2012",
    price: 7.52,
    
  },
  band2:{
    date: "01/11/2012",
    price: 7.58,
    
  },
  band3:{
    date: "02/10/2012",
    price: 7.9399999999999995,
    
  },
  band4:{
    date: "02/09/2012",
    price: 8.25,
    
  },
  band4:{
    date: "01/09/2012",
    price: 7.77,
    
  },
  band5:{
    date: "01/08/2012",
    price: 7.7,
    
  },
  band6:{
    date: "01/07/2012",
    price: 7.27,
    
  },
  band7:{
    date: "01/06/2012",
    price: 7.43,
    
  },
  band8:{
    date: "01/05/2012",
    price: 7.65,
    
  },
  band9:{
    date: "01/04/2012",
    price: 7.79,
    
  },
  band10:{
    date: "01/03/2012",
    price: 7.74,
    
  },
  band11:{
    date: "01/02/2012",
    price: 7.46,
    
  },
  band12:{
    date: "01/01/2012",
    price: 7.23,
    
  }},
 {
    year:2011,
  band1:{
    date: "01/12/2011",
    price: 7.11,
    
  },
  band2:{
    date: "01/11/2011",
    price: 7.22,
    
  },
  band3:{
    date: "01/10/2011",
    price: 7.29,
    
  },
  band4:{
    date: "01/09/2011",
    price: 7.27,
    
  },
  band5:{
    date: "01/08/2011",
    price: 7.22,
    
  },
  band6:{
    date: "01/07/2011",
    price: 7.22,
    
  },
  band7:{
    date: "01/06/2011",
    price: 7.46,
    
  },
  band8:{
    date: "01/05/2011",
    price: 7.62,
    
  },
  band9:{
    date: "01/04/2011",
    price: 7.39,
    
  },
  band10:{
    date: "01/03/2011",
    price: 7.3,
    
  },
  band11:{
    date: "14/02/2011",
    price: 7.02,
    
  },
  band11:{
    date: "01/02/2011",
    price: 7.26,
    
  },
  band12:{
    date: "02/01/2011",
    price: 7.14,
    
  }}
]
export default {fuelPrice}