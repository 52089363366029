
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert';
import { useGoldApi } from '../../hooks/useGoldApi';
import { useRate } from '../../hooks/useRate';

import { BiShekel } from 'react-icons/bi'
export default function Alzakat() {
  const { rateEx } = useRate("USD");
  const { priceOfGold, goldOz, goldGram, goldKilo, silverPricee } = useGoldApi();
  
 const silverPriceGram = (silverPricee*rateEx).toFixed(2);
//  console.log("silver ? ", silverPriceGram)
  
//  const [nisab, setNisab] = useState(' ');
// tot 2830115.07
const silverPrice = silverPriceGram;
const goldPrice = goldGram;
const nisab = (goldPrice * 85);


  const [cash, setCash] = useState('0');
  const [silvergram, setSilvergram] = useState('0');
  const [shares, setShares] = useState('0');
  const [silver, setSilver] = useState('0');
  const [deposits, setDeposits] = useState('0');
  const [durg, setDurg] = useState('0');
  const [goldgram, setGoldgram] = useState('0');
  const [bank, setBank] = useState('0');
  const [gold, setGold] = useState('0');
  const [debts, setDebts] = useState('0');
  const [expenses, setExpenses] = useState('0');

  //   const [alzaka, setAlzaka]= useState(0) ;
  const [show, setShow] = useState(false);
  const [showDev, setShowDev] = useState(false);

  const [data, setData] = useState([]);
  const alrt = useAlert();

  const nisabb = (goldGram * 85).toLocaleString(undefined, { maximumFractionDigits: 2 });
  // console.log("النصاب  :", nisabb )



  // useEffect(() =>{
  //     fetch('https://goldprice.org/')
  //     .then(res =>{
  //      return res.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //     })
  //   }, []);
  // const handleClick = () =>{
  //   let value = 0;
  //   value=
  //   parseFloat(goldgram)*goldPrice;
  //   console.log('value=', value);
  // }

  const newCalc = () => {
    window.location.reload();
  }
    // Thousands Commas Function 
    function commify(n) {
      var parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  }
  function myFunction() {
    alert(" المجموع الكلي اقل من  حد النصاب")
  }
  const handleSubmit = (e) => {
    const id = Math.random();
    e.preventDefault();
    let tot = 0;
    let tot1 = 0;
    let sub1=0;
    //  tot=
    //   parseFloat( cash)  
    //   +parseFloat(bank) 
    //   +parseFloat(gold)  
    //   -parseFloat(expenses)
    //   -parseFloat(debts);


    // console.log("silver ? ", silverP)
    // console.log("gold ? ", goldGram)
    tot=
    parseFloat( cash)  
    +parseFloat(bank)  
    +parseFloat(durg)  
    +parseFloat(deposits)  
    +parseFloat(shares)  
    // +parseFloat(shares)  
    // +parseFloat(gold)  
    // +parseFloat(silver)  
    -parseFloat(expenses)
    -parseFloat(debts)
    +(parseFloat(silvergram)*silverPrice)  
    +(parseFloat(goldgram)*goldPrice);

    tot1=
    parseFloat( cash)  
    +parseFloat(bank)  
    +parseFloat(durg)  
    +parseFloat(deposits)  
    +parseFloat(shares)  
    +(parseFloat(silvergram)*silverPrice)  
    +(parseFloat(goldgram)*goldPrice);

    sub1 = parseFloat(expenses) + parseFloat(debts)

    // console.log("tot", tot);
    if (tot >= nisab) {
      zakat(tot);
      setShowDev(true)
    const timer = setTimeout(() => window.scroll(0, 2000), 100);
      
    }
    else myFunction()


    function zakat(money) {
      let alzaka = 0;
      alzaka = money * 0.025;

      console.log(alzaka);

      setData(prev => [...prev,
      {
        id: id,
        zaka: alzaka,
        nisab: nisab,
        total: tot,
        tot1,
        sub1

      }])
      alzaka = alzaka.toFixed(2);
    }


  }
  return (

    <div className="card" >
      <div className="card-body eOfService">
        <br></br>
        {/* <button onClick={() => alrt.show('المجموع الكلي اقل من  حد النصاب!',{
          
           timeout: 3000,
        })}>
        Show Alert
      </button> */}
        <br></br>
        <form onSubmit={handleSubmit} >
          <h1 className='card-title text-center'>حساب قيمة  الزكاة </h1>
          <br></br>
          <div className="form-gronup row zakat-form">
            {/* Gold Prices */}
           <div className='row'>
           <div className='col-sm-6 col-md-3'>
              <h4 className=""> النصاب اليوم  <span> 85 (غرام) :</span> </h4>
            </div>
            <div className='col-sm-6 col-md-4'>
              <h3 className="privacy text-left"> {nisabb} <BiShekel /></h3>
            </div>
            <br></br>
            </div> 
            

            {/* <div className='col-sm-12 col-md-6'>
              <h4 className=""> سعر الذهب   <span>  (غرام) :</span> </h4>
            </div>
            <div className='col-sm-12 col-md-6'>
              <h4 className="privacy"> {goldGram} <BiShekel /></h4>
            </div> */}

            {/* Gold Prices */}
            <div className='row form-border-green '>
              <h2 className='privacy'>الممتلكات </h2>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">النقد في اليد </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="amountHand"
                value={cash}
                onChange={(e) => setCash(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">النقد في جميع البنوك </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="bank"
                value={bank}
                onChange={(e) => setBank(e.target.value)} />
            </div>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">الودائع في جميع البنوك </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="deposits"
                value={deposits}
                onChange={(e) => setDeposits(e.target.value)} />
            </div>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">إجمالي القيمة السوقية لأسهم المضاربة </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="shares"
                value={shares}
                onChange={(e) => setShares(e.target.value)} />
            </div>
            <br></br>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">إجمالي القيمة السوقية للعقارات المتاحة للبيع خلال سنة واحدة </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="durg"
                value={durg}
                onChange={(e) => setDurg(e.target.value)} />
            </div>
            <div className='col-sm-12 col-md-6'>

            </div>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">إجمالي وزن الذهب ( المحفوظ لغرض الإستثمار )   <span className='privacy'> ( غرام ) </span>  </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="goldgram"
                value={goldgram}
                onChange={(e) => setGoldgram(e.target.value)} />
            </div>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">سعر الذهب  </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="gold"
                value={goldGram}
                onChange={(e) => setGold(e.target.value)} />
            </div>

            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">إجمالي وزن الفضة  <span className='privacy'> ( غرام ) </span>  </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="silvergram"
                value={silvergram}
                onChange={(e) => setSilvergram(e.target.value)} />
            </div>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">سعر الفضة </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="silver"
                value={silverPriceGram}
                // onChange={(e) => setSilver(e.target.value)} 
                />
            </div>
            </div>
{/* ============================================================================== */}
         <div className='row form-border-red'>
          <h2 className='privacy'> تنزل المطلوبات</h2>
          <div className="col-sm-12 col-md-6 ">
              <label className=" col-form-label">مجموع الالتزامات الشخصية المستحقة للغير </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="debts"
                value={expenses}
                onChange={(e) => setExpenses(e.target.value)}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label className=" col-form-label">مجموع الأقساط التجارية مستحقة الدفع للغير </label>
              <br></br>
              <input
                type="text"
                className="form-control"
                name="debts"
                value={debts}
                onChange={(e) => setDebts(e.target.value)} />
            </div>

         </div>
            



            {/*            
            <div className='button'>
              <button type='submit'>  احسب الزكاة</button>
            </div> */}
            <div className="text-center button-height zakat-btn-div">
              <br></br>
              <button type="submit" className="btn btn-primary zakat-button ">احسب الزكاة</button>

            </div>


          </div>
        </form>

   

         {/* ================ SHOW HERE ================= */}

      {showDev && <div className=" container">
        <div className="content container-fluid">
          <div className="page-header ">

            <div className="">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0 text-center privacy">نتائج الاحتساب </h4>
                </div>
                <div className="card-body">
                  {/* ================================================ */}
                  {data.map((d, index) => (
                    <div className="row result-div" key={index}>
                      <br></br>
                   

                      <div className="col-sm-12 col-md-4 ">
                        <label className="label-title">النصاب اليوم 85 غرام </label>

                      </div>
                      <div className="col-sm-12 col-md-3">

                        <label className="privacy reuslt-insurance">{commify(nisab)}</label>
                      </div>
                      <div className="col-sm-12 col-md-2 ">
                        {/* <label className="label-title">المبلغ المعفي</label> */}

                      </div>
                      <div className="col-sm-12 col-md-3">

                        <label className="privacy reuslt-insurance"></label>
                      </div>
                      <div>

                        </div>

                      <div className=" row col-sm-12 col-md-6 border-div">
                        <br></br>
                        <h4 className=" mb-0  privacy text-center">الممتلكات   </h4>
                        <br></br>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>مجموع الممتلكات   </label>
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                          <br></br>
                          <label className="reuslt-insurance">{commify(d.tot1)}</label>
                        </div>
                       
                            {/* <div className="col-sm-12 col-md-6">
                              <br></br>
                              <label>الشريحة الثانية   </label>
                            </div>
                            <div className="col-sm-12 col-md-6 ">
                              <br></br>
                              <label className="reuslt-insurance"></label>
                            </div> */}
                       
                        {/* <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>التأمين الوطني   </label>
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                          <br></br>
                          <label className="reuslt-insurance privacy "></label>
                        </div> */}
                      </div>
                      <div className="row col-sm-12 col-md-6 border-div">
                        <br></br>
                        <h4 className=" mb-0  privacy text-center">المجموع الكلي     </h4>
                        <br></br>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>مجموع المطلوبات   </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance">{commify(d.sub1)}</label>
                        </div>
                       
                            {/* <div className="col-sm-12 col-md-6">
                              <br></br>
                              <label>الشريحة الثانية   </label>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <br></br>
                              <label className="reuslt-insurance"></label>
                            </div>
                         
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>التأمين الصحي   </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance privacy"></label>
                        </div> */}
                      </div>
                      <div className="col-sm-12 col-md-2"></div>
                      <div className="col-sm-12 col-md-4  ">
                        <br></br>
                        <label>المجموع الكلي      </label>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label className="reuslt-insurance privacy">{commify(d.total)}</label>
                      </div>
                      <div className="col-sm-12 col-md-2"></div>
                      <div className="col-sm-12 col-md-4  ">
                        <br></br>
                        <label>زكاة المال     </label>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label className="reuslt-insurance privacy">{commify(d.zaka)}</label>
                      </div>


                      <div className="form-group row">

                        <div className="col-sm-12 col-md-3"></div><br></br><br></br>
                        <div className="col-sm-12 col-md-6  ">
                          <br></br>
                          <button className="btn btn-primary naqaha-btn insurance-btn" onClick={newCalc} > احتساب جديد  </button>
                          <br></br> <br></br>
                        </div>
                       
                        <hr></hr>
                      </div>

                    </div>))} <br></br>
               
            
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>}
      {/* ============================================================================== */}
     


      </div>
    </div>
  );
}

// export default Alzaka