import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import './assets/styles/style-dark.css';
import { analytics } from './firebase/config';
import App from './App';
//js files 
// ============================================
import './assets/js/bootstrap.min.js';
import './assets/css/select2.min.css';
import './assets/js/popper.min.js';
import './assets/js/select2.min.js';
import './assets/js/jquery-3.2.1.min.js';
import './assets/js/jquery.slimscroll.min.js';

import "./assets/js/bootstrap-datetimepicker.min.js"
import "./assets/js/jquery-ui.min.js"
import "./assets/js/task.js"
import "./assets/js/multiselect.min.js"
import "./assets/plugins/bootstrap-tagsinput/bootstrap-tagsinput.css"
import "./assets/plugins/bootstrap-rtl/css/bootstrap.min.css"
import "./assets/plugins/bootstrap-rtl/js/bootstrap.min.js"
// =============================================
// import './assets/js/app.js'

// Custom File
// import 'font-awesome/css/font-awesome.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/line-awesome.min.css';

// import './assets/css/style-dark.css';
// import './assets/css/style.css';
import './Entryfile/imagepath'
import './Entryfile/routerpath'
import reportWebVitals from './reportWebVitals';

import { AuthContextProvider } from './context/AuthContext';

// import {$,jQuery} from 'jquery';
// export for others scripts to use
// window.$ = $;
// window.jQuery = jQuery;


import './assets/js/app.js';
import { Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
// optional cofiguration
const options = {
  position: 'bottom center',
  // timeout: 5000,
  offset: '50px',
  transition: 'fade',
  containerStyle: {
    zIndex: 100,
   
  }
}

ReactDOM.render(
  <React.StrictMode>
    <React.Fragment>
      <AuthContextProvider>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
      </AuthContextProvider> 
    </React.Fragment>
     
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
