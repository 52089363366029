/**
 * Signin Firebase
 */

import React, { Component, useRef, useState} from 'react';
import { useAuth } from '../../contexts/AuthContext.js';
import{Link} from"react-router-dom";
import { Helmet } from "react-helmet";
import {massportallogo} from '../../Entryfile/imagepath.jsx'
import { Form, Button, Card, Alert } from "react-bootstrap"
import firebase from 'firebase/app';

export default function ForgotPassword ()  {
  const emailRef = useRef()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [message2, setMessage2] = useState("")

  const [loading, setLoading] = useState(false)
  // mass.dev100@gmail.com
  async function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)
    await firebase.auth().sendPasswordResetEmail(emailRef.current.value)
    .then(() => {
      // Password reset email sent!
      // console.log("email sent to you ")
      setMessage(" تحقق من صندوق الوارد الخاص بك لمزيد من التعليمات")
      setMessage2("اذا لم يصلك ايميل اعادة تعيين كلمة المرور, الرجاء فحص  Sapm Emails ")
           setTimeout(() => {
       window.location.href="./loginpage";
    }, 8000);
    
      // ..
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorMessage)
      // ..
    });
    // try {
    //   setMessage("")
    //   setError("")
    //   setLoading(true)
    //   await resetPassword(emailRef.current.value)
    //   setMessage("تحقق من صندوق الوارد الخاص بك لمزيد من التعليمات")
    //   setTimeout(() => {
    //    window.location.href="./loginpage";
    // }, 6000);
    // } catch {
    //   setError("فشل إعادة تعيين كلمة المرور")
    // }

    // setLoading(false)
  }
 
      return (
        <div className="page-wrapper"> 
          <div className="main-wrapper">
            <Helmet>
                    <title>MassPortal | Forgot Password</title>
                    <meta name="description" content="Login page"/>					
            </Helmet>
          <div className="account-content">
            {/* <a href="/applyjob/joblist" className="btn btn-primary apply-btn">Apply Job</a> */}
            <div className="container">
              {/* Account Logo */}
              <br></br>
              <br></br>

              <div className="account-logo">
                <a href="/home"><img src={massportallogo} alt="Dreamguy's Technologies" /></a>
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">نسيت كلمة السر ؟ </h3>
                  <br></br>
                  {/* <p className="account-subtitle">Enter your email to get a password reset link</p> */}
                  <p className="account-subtitle">أدخل بريدك الإلكتروني للحصول على رابط تغيير كلمة المرور</p>
                  {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          {message && <Alert variant="success">{message2}</Alert>}
                  {/* Account Form */}
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>البريد الالكتروني</label>
                      <input className="form-control" type="email" ref={emailRef} required  />
                    </div>
                    <div className="form-group text-center">
                      <button className="btn btn-primary account-btn" type="submit">تغيير كلمة السر</button>
                    </div>
                    <div className="account-footer">
                      <p>تذكرت كلمة السر ? <a href="./loginpage">تسجيل الدخول</a></p>
                    </div>
                  </form>
                  {/* /Account Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      );
   
}


