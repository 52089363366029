let full = [
 {
  ar: "ائتمان",
  he: "אשראי",
  en: "Credit"
 },
 {
  ar: "ابتزاز",
  he: "סחיטה",
  en: "Blackmail"
 },
 {
  ar: "اتاوة",
  he: "תמלוגים",
  en: "Royalties"
 },
 {
  ar: "اتجار",
  he: "מסחר\/ סחר",
  en: "Commerce\/ trade"
 },
 {
  ar: "اتحاد",
  he: "התאחדות",
  en: "association"
 },
 {
  ar: "اتحاد المنتجين",
  he: "קרטל",
  en: "Cartel"
 },
 {
  ar: "اتحاد جمركي",
  he: "הסכם מכס בין מדינות",
  en: "Customs union"
 },
 {
  ar: "اتحاد شركات",
  he: "קונסורציום",
  en: "consortium"
 },
 {
  ar: "اتحاد عمالي",
  he: "איגוד מקצועי",
  en: "Labour union"
 },
 {
  ar: "اتحاد نقدي",
  he: "איחוד מוניטארי",
  en: "Monetary union"
 },
 {
  ar: "اتعاب",
  he: "שכר טרחה",
  en: "Fee"
 },
 {
  ar: "اتفاق اجمالي",
  he: "עסקת חבילה",
  en: "Package deal"
 },
 {
  ar: "اتفاق اضماني",
  he: "עסקת חבילה",
  en: "Package deal"
 },
 {
  ar: "اتفاق شفوي",
  he: "הסכם בעל-פה",
  en: "Parole contract"
 },
 {
  ar: "اتفاق عمالي",
  he: "הסכם עבודה",
  en: "Labour agreement"
 },
 {
  ar: "اتفاقية تجارية تبادلية",
  he: "הסכם גומלין",
  en: "Reciprocal trade agreement"
 },
 {
  ar: "اتفاقية خيار لاجل",
  he: "אופציה",
  en: "Option"
 },
 {
  ar: "اتفاقية مقاصة",
  he: "הסכם סילוקין",
  en: "Clearing agreement"
 },
 {
  ar: "اجازة",
  he: "חופשה",
  en: "Leave\/ holiday"
 },
 {
  ar: "اجازة بدون راتب",
  he: "חופשה ללא תשלום",
  en: "Unpaid leave"
 },
 {
  ar: "اجازة سنوية",
  he: "חופשה שנתית",
  en: "Annual leave"
 },
 {
  ar: "اجر",
  he: "שכר\/ משכורת",
  en: "Salary\/ wages"
 },
 {
  ar: "الاجر بالقطعة",
  he: "שכר לפי יחידה",
  en: "Piece rate"
 },
 {
  ar: "اجر تشجيعي ",
  he: "שכר עידוד",
  en: "Incentive pay"
 },
 {
  ar: "اجر حقيقي",
  he: "שכר ריאלי",
  en: "Real wage"
 },
 {
  ar: "اجر متساو",
  he: "שכר שווה",
  en: "Equal pay"
 },
 {
  ar: "اجر متكافىء ",
  he: "שכר שווה",
  en: "Equal pay"
 },
 {
  ar: "اجراءات الافلاس",
  he: "הליכי פשיטת רגל",
  en: "Bankruptcy procedures"
 },
 {
  ar: "اجرة",
  he: "דמי שכירות",
  en: "rent"
 },
 {
  ar: "اجرة الشحن",
  he: "דמי הובלה",
  en: "Freight"
 },
 {
  ar: "اجرة الهاتف برسم التحصيل",
  he: "שיחת גוביינא",
  en: "Collect phonecall"
 },
 {
  ar: "اجمالي",
  he: "סך הכל",
  en: "total"
 },
 {
  ar: "اجور الشحن",
  he: "דמי משלוח",
  en: "Shipping charge"
 },
 {
  ar: "احتكار",
  he: "מונופול",
  en: "monopoly"
 },
 {
  ar: "احتكار الشراء",
  he: "מונופסון",
  en: "Monopsony"
 },
 {
  ar: "احتكار القلة",
  he: "מספר מעט של פירמות בענף",
  en: "oligopoly"
 },
 {
  ar: "احتكار بيع ثنائي",
  he: "דואופול (מונופול של שני יצרנים)",
  en: "Duopoly"
 },
 {
  ar: "احتكار بيع شركتين",
  he: "דואופול (מונופול של שני יצרנים)",
  en: "Duopoly"
 },
 {
  ar: "احتكار ثنائي",
  he: "מונופול דו- צדדי",
  en: "Bilateral monopoly"
 },
 {
  ar: "احتكار جزئي",
  he: "מונופול חלקי",
  en: "Partial monopoly"
 },
 {
  ar: "احتكار شراء ثنائي ",
  he: "דואופסון (מונופול של שני קונים)",
  en: "Duopsony"
 },
 {
  ar: "احتكار طبيعي",
  he: "מונופול טבעי",
  en: "Natural monopoly"
 },
 {
  ar: "احتكار قانوني",
  he: "מונופול חוקי",
  en: "Legal monopoly"
 },
 {
  ar: "اختراع",
  he: "פטנט",
  en: "Patent"
 },
 {
  ar: "اختزان",
  he: "אגירה",
  en: "Hoarding"
 },
 {
  ar: "ادارات متشابكة",
  he: "הנהלות משותפות לכמה חברות",
  en: "Interlocking directorates"
 },
 {
  ar: "ادارة",
  he: "הנהלה",
  en: "management"
 },
 {
  ar: "ادارة الاعمال",
  he: "מינהל עסקים",
  en: "Business administration\/ business management"
 },
 {
  ar: "ادارة الانتاج",
  he: "ניהול ייצור",
  en: "Production management"
 },
 {
  ar: "ادارة الجمارك",
  he: "ניהול המכס",
  en: "Customs administration"
 },
 {
  ar: "ادارة الدين",
  he: "ניהול חובות",
  en: "Debt management"
 },
 {
  ar: "ادارة المستودعات",
  he: "ניהול מלאי",
  en: "Inventory management"
 },
 {
  ar: "ادارة مالية",
  he: "ניהול פינאנסי",
  en: "Financial management"
 },
 {
  ar: "ادنى حد للاجر",
  he: "שכר מינימום",
  en: "Minimum wage"
 },
 {
  ar: "اذخار",
  he: "חיסכון",
  en: "Saving"
 },
 {
  ar: "اذخار اجباري",
  he: "חיסכון חובה",
  en: "Compulsory saving"
 },
 {
  ar: "اذخار سلبي",
  he: "חיסכון שלילי",
  en: "dissaving"
 },
 {
  ar: "ارباح غير موزعة",
  he: "רווחים שלא חולקו",
  en: "Undistributed profits\/ retained earnings"
 },
 {
  ar: "ارباح محتجزة",
  he: "רווחים שלא חולקו",
  en: "Undistributed profits\/ retained earnings"
 },
 {
  ar: "ارتباط",
  he: "הצמדה",
  en: "Undexation\/ linkage"
 },
 {
  ar: "ارث",
  he: "יורשה",
  en: "Inheritance"
 },
 {
  ar: "ارسالية",
  he: "משגור\/ קונסיגנציה ",
  en: "consignment"
 },
 {
  ar: "ارض حدية",
  he: "קרקע דלה",
  en: "Marginal land"
 },
 {
  ar: "ازدهار",
  he: "שגשוש\/ גאות (כלכלית)",
  en: "prosperity"
 },
 {
  ar: "ازدواج ضريبي",
  he: "כפל מס\/ מיסוי כפול",
  en: "Double taxation"
 },
 {
  ar: "ازمة اقتصادية",
  he: "משבר כלכלי",
  en: "Economic crisis"
 },
 {
  ar: "اساس الضريبة",
  he: "בסיס מס",
  en: "Tax base"
 },
 {
  ar: "استثمار",
  he: "השקעה",
  en: "Investment"
 },
 {
  ar: "استثمار اجنبي",
  he: "השקעה זרה",
  en: "Foreign investment"
 },
 {
  ar: "استثمار تجاري",
  he: "השקעה בתחום המסחר",
  en: "Trade investment"
 },
 {
  ar: "استثمار دائم",
  he: "השקעה קבועה",
  en: "Permanent investment"
 },
 {
  ar: "استثمار سلبي",
  he: "השקעה שלילית\/ תצרוכת הון (בחברה)",
  en: "Disinvestment"
 },
 {
  ar: "استثمار مباشر",
  he: "השקעה ישירה",
  en: "Direct investment"
 },
 {
  ar: "استثمارات اجنبية",
  he: "השקעות חוץ",
  en: "Foreign investment"
 },
 {
  ar: "استحقاق",
  he: "פירעון",
  en: "maturity"
 },
 {
  ar: "استرداد ",
  he: "החזר",
  en: "Pay pack"
 },
 {
  ar: "استرداد القيمة",
  he: "פדיון של הלוואה",
  en: "redemption"
 },
 {
  ar: "استرداد المال",
  he: "החזר כספי",
  en: "refund"
 },
 {
  ar: "استفاجة اجتماعية",
  he: "זכויות סוציאליות",
  en: "Social benefit"
 },
 {
  ar: "استقاء",
  he: "החכרת מטעלמעבד תמורת חלק מהיבול",
  en: "Leasing of a plantation to a cultivator in exchange for part of the crops"
 },
 {
  ar: "استقالة",
  he: "התפטרות",
  en: "Resignation"
 },
 {
  ar: "استنفاد",
  he: "אזילה",
  en: "Depletion"
 },
 {
  ar: "استهلاك",
  he: "צריכה",
  en: "Consumption"
 },
 {
  ar: "استهلاك بالاستعمال",
  he: "פחת",
  en: "depreciation"
 },
 {
  ar: "استهلاك راس مال",
  he: "צריכת הון",
  en: "Capital consumption"
 },
 {
  ar: "استهلاك معجل",
  he: "פחת מואץ",
  en: "Accelerated depreciation"
 },
 {
  ar: "استهلاك ناجم عن الاستعمال العادي",
  he: "בלאי",
  en: "Wear to tear"
 },
 {
  ar: "استيراد",
  he: "יבוא",
  en: "import"
 },
 {
  ar: "استيراد راس المال",
  he: "יבוא הון",
  en: "Capital import"
 },
 {
  ar: "استيراد غير منظور",
  he: "יבוא סמוי",
  en: "Invisible import"
 },
 {
  ar: "استيراد منظور",
  he: "יבוא גלוי",
  en: "Visible import"
 },
 {
  ar: "اسراف",
  he: "בזבוז",
  en: "waste"
 },
 {
  ar: "اسطول تجاري",
  he: "צי הסוחר",
  en: "Merchant navy"
 },
 {
  ar: "اسعار ثابتة",
  he: "מחירים קבועים",
  en: "Fixed prices"
 },
 {
  ar: "اسكان",
  he: "שיכון",
  en: "housing"
 },
 {
  ar: "اسكو: اللجنة الاقتصادية والاجتماعية لغرب اسيا",
  he: 'null',
  en: 'null'
 },
 {
  ar: "اسهم التاسيس",
  he: "מניות יזמים\/ מניות מייסדים",
  en: "Promoter's shares\/ founder shares"
 },
 {
  ar: "اسهم المؤسسين",
  he: "מניות יזמים\/ מניות מייסדים",
  en: "Promoter's shares\/ founder shares"
 },
 {
  ar: "اسهم تاسيسية",
  he: "מניות יזמים\/ מניות מייסדים",
  en: "Promoter's shares\/ founder shares"
 },
 {
  ar: "اسهم راس المال",
  he: "הון מניות",
  en: "Capital shares\/ share capital"
 },
 {
  ar: "اسهم متداولة",
  he: "סך המניות הנסחרות",
  en: "Outstanding stocks"
 },
 {
  ar: "اشتراك",
  he: "מינוי (למשל על עיתון)",
  en: "subscription"
 },
 {
  ar: "اشتراكية",
  he: "סוציאליזם",
  en: "Socialism"
 },
 {
  ar: "اشعار",
  he: "הודעה",
  en: "Note\/ advice"
 },
 {
  ar: "اشعار بالارسال",
  he: "הודעת משלוח",
  en: "Advice of dispatch"
 },
 {
  ar: "اشعار بالاعتماد",
  he: "הודעת אשראי",
  en: "Credit advice"
 },
 {
  ar: "اشعار بالشحن",
  he: "הודעת משלוח",
  en: "Advice of dispatch"
 },
 {
  ar: "اشعار دائن",
  he: "הודעת זיכוי",
  en: "Credit memorandum\/ credit note"
 },
 {
  ar: "اشغال عامة",
  he: "עבודות ציבוריות",
  en: "Public works"
 },
 {
  ar: "اشياء ثمينة",
  he: "דברי ערך",
  en: "Valuables"
 },
 {
  ar: "اصدار",
  he: "הנפקה",
  en: "Issuing\/ issue"
 },
 {
  ar: "اصدار اسهم علاوة",
  he: "הנפקת מניות הטבה",
  en: "Scrip issue"
 },
 {
  ar: "اصدار بالمناقصة",
  he: "הנפקה באמצעת מכרז",
  en: "Issue by tender"
 },
 {
  ar: "اصدار نقدي",
  he: "הדפסת כסף",
  en: "Printing money"
 },
 {
  ar: "اصل",
  he: "נכס",
  en: "Asset"
 },
 {
  ar: "اصل حقيقي",
  he: "נכס מוחשי",
  en: "Actual asset\/ tangible asset"
 },
 {
  ar: "اصل دائم",
  he: "נכס שאינו מתכלה",
  en: "Non- wasting asset"
 },
 {
  ar: "اصل سائل",
  he: "נכס נזיל",
  en: "Liquid asset\/ near money"
 },
 {
  ar: "اصل صاف",
  he: "שווי נכס לאחר הפחתת מסים והיטלים",
  en: "Net asset"
 },
 {
  ar: "اصل عامل",
  he: "נכס פעיל",
  en: "Active asset"
 },
 {
  ar: "اصل مالي",
  he: "נכס הון",
  en: "Financial asset"
 },
 {
  ar: "اصل متناقص",
  he: "נכס מתכלה",
  en: "Wasting asset"
 },
 {
  ar: "اصل مجمد",
  he: "נכס מוקפא",
  en: "Frozen asset"
 },
 {
  ar: "اصل مرهون",
  he: "נכס משועבד",
  en: "Pledged asset"
 },
 {
  ar: "اصل نقدي",
  he: "נכס במזומן",
  en: "Cash asset"
 },
 {
  ar: "اصول",
  he: "נכסים",
  en: "assets"
 },
 {
  ar: "اصول مؤجلة",
  he: "נכסים נדחים",
  en: " Deferred asset"
 },
 {
  ar: "اضراب",
  he: "שביתה",
  en: "strike"
 },
 {
  ar: "اضراب التباطؤ",
  he: "שביתת האטה",
  en: "Go- slow strike"
 },
 {
  ar: "اضراب تاييدي",
  he: "שביתת הזדהות",
  en: "Sympathetic strike"
 },
 {
  ar: "اضراب عام",
  he: "שביתה כללית",
  en: "General strike"
 },
 {
  ar: "اضراب غير شرعي",
  he: "שביתה בלתי חוקית",
  en: "Outlawed strike\/ illegal strike"
 },
 {
  ar: "اضراب غير قانوني",
  he: "שביתה בלתי חוקית",
  en: "Outlawed strike\/ illegal strike"
 },
 {
  ar: "اعادة الاستيراد",
  he: "יבוא חוזר",
  en: "Re-import"
 },
 {
  ar: "اعادة الاصدار",
  he: "הנפקה מחדש",
  en: "Re-issue"
 },
 {
  ar: "اعادة الانتاج",
  he: "ייצור חוזר",
  en: "Re-production"
 },
 {
  ar: "اعادة البيع",
  he: "מכירה מחדש",
  en: "resale"
 },
 {
  ar: "اعادة التصدير",
  he: "יצוא חוזר",
  en: "Re-export"
 },
 {
  ar: "اعادة التقييم",
  he: "שיעורך",
  en: "Revaluation"
 },
 {
  ar: "اعادة الخصم",
  he: "ניכיון משנה",
  en: "rediscount"
 },
 {
  ar: "اعادة توزيع الدخل",
  he: "חלוקה מחדש של הכנסה",
  en: "Redistribution of income"
 },
 {
  ar: "اعارة",
  he: "הלוואה",
  en: "loan"
 },
 {
  ar: "اعانة",
  he: "סיוע\/ עזרה",
  en: "Aid "
 },
 {
  ar: 'null',
  he: "סובסידיה",
  en: "Subsidy"
 },
 {
  ar: "اعانة بطالة",
  he: "דמי אבטלה",
  en: "Unemployment benefit"
 },
 {
  ar: "اعتماد",
  he: "אשראי",
  en: "credit"
 },
 {
  ar: 'null',
  he: "הקצבה",
  en: "appropriation"
 },
 {
  ar: "اعتماد تجاري",
  he: "אשראי מסחרי",
  en: "Trade credit"
 },
 {
  ar: "اعتماد دفتري",
  he: "אשראי בספרים",
  en: "Book credit"
 },
 {
  ar: "اعتماد دوار",
  he: "אשראי מתחדש מעצמו",
  en: "Revolving credit"
 },
 {
  ar: "اعتماد طويل الاجل",
  he: "אשראי לטווח ארוך",
  en: "Long tern credit"
 },
 {
  ar: "اعتماد غير مضمون",
  he: "אשראי בלתי מובטח",
  en: "Unsecured credit"
 },
 {
  ar: "اعتماد قابل للتحويل",
  he: "אשראי ניתן להעברה",
  en: "Transferable credit"
 },
 {
  ar: "اعتماد قصير الاجل",
  he: "אשראי לטווח ארוך",
  en: "Short tern credit"
 },
 {
  ar: "اعتماد للاستيراد",
  he: "אשראי ליבוא",
  en: "Import credit"
 },
 {
  ar: "اعتماد للتصدير",
  he: "אשראי ליצוא",
  en: "Export credit"
 },
 {
  ar: "اعتماد متوسط الاجل",
  he: "אשראי לטווח בינוני",
  en: "Medium tern credit"
 },
 {
  ar: "اعتماد محلي",
  he: "אשראי מקומי",
  en: "Local credit\/ domestic credit"
 },
 {
  ar: "اعتماد مستندي",
  he: "אשראי דוקומנטרי",
  en: "Documentary credit"
 },
 {
  ar: "اعتماد مصرفي",
  he: "אשראי בנקאי",
  en: "Bank credit"
 },
 {
  ar: "اعتماد مضمون",
  he: "אשראי מובטח",
  en: "Secured credit"
 },
 {
  ar: "اعتماد مفتوح",
  he: "אשראי פתוח",
  en: "Open credit"
 },
 {
  ar: "اعتماد ممتد",
  he: "אשראי מוגדל",
  en: "Extended credit"
 },
 {
  ar: "اعفاء ضريبي",
  he: "מתן פטור מס",
  en: "Tax exemption\/ zero rating "
 },
 {
  ar: "اعلان توزيع ارباح السهم",
  he: "הכרזה על חלוקת דיווידנד",
  en: "Declaration of dividends"
 },
 {
  ar: "اعمال الاعلان",
  he: "פרסום",
  en: "Advertising"
 },
 {
  ar: "اغاثة",
  he: "סעד",
  en: "Relief\/ welfare"
 },
 {
  ar: "اغراق",
  he: "הצפה",
  en: "Dumping"
 },
 {
  ar: "افراط في الاتجار",
  he: "התרחבות יתר",
  en: "Overtrading"
 },
 {
  ar: "افلاس",
  he: "פשיטת רגל",
  en: "Insolvency\/ bankruptcy"
 },
 {
  ar: "اقتصاد",
  he: "כלכלה",
  en: "Economics\/ economy"
 },
 {
  ar: "اقتصاد اجتماعي",
  he: "כלכלה חברתית",
  en: "Socio-economy"
 },
 {
  ar: "اقتصاد السوق",
  he: "כלכלת שוק",
  en: "Market economy"
 },
 {
  ar: "اقتصاد تطبيقي",
  he: "כלכלה שימושית",
  en: "Applied economics"
 },
 {
  ar: "اقتصاد ثنائي",
  he: "כלכלה שנייה",
  en: "Dual economy"
 },
 {
  ar: "اقتصاد موجة",
  he: "כלכלה מתוכננת\/ משק מתוכנן",
  en: "Planned economy"
 },
 {
  ar: "اقتصادي",
  he: "כלכלן",
  en: "Economist"
 },
 {
  ar: "الاقتصاديات الصغرى",
  he: "מיקרו כלכלה",
  en: "Micro-economics"
 },
 {
  ar: "اقتطاع",
  he: "ניכוי",
  en: "Deduction"
 },
 {
  ar: "اقتطاعية",
  he: "פיאודליזם",
  en: "Feudalism"
 },
 {
  ar: "اقفال الحساب",
  he: "סגירת חשבון",
  en: "Closing of an account "
 },
 {
  ar: "اكتتاب",
  he: "פניה לקניית נייר ערך",
  en: "Application"
 },
 {
  ar: "الالتزام بشراء ورقة مالية في الاكتتاب العام",
  he: "התחייבות לקניית נייר ערך בהנפקה",
  en: "subscription"
 },
 {
  ar: "اكتتاب غير مغطى",
  he: "חתימת חסר",
  en: "Under- subscription"
 },
 {
  ar: "اكتتاب مفرط",
  he: "חתימת יתר",
  en: "Over- subscription"
 },
 {
  ar: "اكتفاء ذاتي",
  he: "משק אוטרקי",
  en: "Autarchy economy"
 },
 {
  ar: "اكتناز",
  he: "אגירה",
  en: "Hoarding"
 },
 {
  ar: "التزام",
  he: "התחייבות",
  en: "Obligation"
 },
 {
  ar: "الة",
  he: "מכונה",
  en: "Machine"
 },
 {
  ar: "الة بيع",
  he: "אוטומט למכירת מוצרים",
  en: "Vending machine"
 },
 {
  ar: "امانة الصندوق",
  he: "גזברות",
  en: "Treasurership"
 },
 {
  ar: "امتياز",
  he: "זיכיון",
  en: "Franchise"
 },
 {
  ar: "امدادات",
  he: "אספקה",
  en: "Supply"
 },
 {
  ar: "امر بدفع ربح السهم",
  he: "המחאת דיווידנדים",
  en: "Dividend warrant"
 },
 {
  ar: "امر بيع",
  he: "הוראת מכירה",
  en: "Sale order"
 },
 {
  ar: "امر دائم",
  he: "הוראת קבע",
  en: "Standing order\/ permanent instruction"
 },
 {
  ar: "امر شراء",
  he: "הוראת קנייה",
  en: "Buy order"
 },
 {
  ar: "اموال",
  he: "כספים",
  en: "Funds"
 },
 {
  ar: 'null',
  he: "נכסים",
  en: "Assets"
 },
 {
  ar: "اموال عامة",
  he: "כספי ציבור",
  en: "Public funds"
 },
 {
  ar: "اموال متنقلة",
  he: "כסף המועבר מהשקעה להשקעה כדי לצבור רווח קצר מועד",
  en: "Hot money"
 },
 {
  ar: "اموال وهمية",
  he: "נכסים מדומים",
  en: "Fictitious assets"
 },
 {
  ar: "امين",
  he: "אפוטרופוס\/ נאמן",
  en: "Fiduciary\/ trustee"
 },
 {
  ar: "امين الصندوق ",
  he: "גזבר",
  en: "Burser\/ cashier\/ treasurer"
 },
 {
  ar: "امين سر",
  he: "מזכיר",
  en: "Secretary"
 },
 {
  ar: "امين عام",
  he: "מזכיר כללי",
  en: "Secretary general"
 },
 {
  ar: "انتاج",
  he: "ייצור",
  en: "Production\/ manufacturing"
 },
 {
  ar: "انتاج بالجملة",
  he: "ייצור המוני",
  en: "Mass production\/ large scale production"
 },
 {
  ar: "انتاج حسب الطلب",
  he: "ייצור של פריט ייחודי",
  en: "One off"
 },
 {
  ar: "انتاج على نطاق واسع",
  he: "ייצור המוני",
  en: "Mass production\/ large scale production"
 },
 {
  ar: 'null',
  he: 'null',
  en: 'null'
 },
 {
  ar: "انتاج قومي اجمالي",
  he: "תוצר לאומי גולמי",
  en: "Gross national product"
 },
 {
  ar: "انتاجية",
  he: "פריון",
  en: "Productivity"
 },
 {
  ar: "انتعاش ",
  he: "התאוששות",
  en: "Recovery"
 },
 {
  ar: "انتفاع",
  he: "הפקת תועלת",
  en: "Utilization"
 },
 {
  ar: "انتهاء الاجل",
  he: "פקיעה",
  en: "Expiration"
 },
 {
  ar: "اندماج",
  he: "מיזוג",
  en: "Merger\/ integration\/ consolidation"
 },
 {
  ar: "انعدام العيوب",
  he: "אפס ליקויים",
  en: "Zero defects"
 },
 {
  ar: "انفاق",
  he: "הוצאה",
  en: "Expenditure\/ outlay\/ expense"
 },
 {
  ar: "انقضاء",
  he: "פקיעה",
  en: "Expiration"
 },
 {
  ar: "انكماش",
  he: "דפלציה",
  en: "Deflation"
 },
 {
  ar: "اوراق النقد المتداولة",
  he: "שטרות במחזור",
  en: "Notes in circulation"
 },
 {
  ar: "اوراق النقد سحبت من التداول ",
  he: "שטרות שהוצאו מהמחזור",
  en: "Bills retired"
 },
 {
  ar: "اوقية",
  he: "אונקייה",
  en: "ounce"
 },
 {
  ar: "ايجار",
  he: "השכרה\/ שכר דירה",
  en: "Lease\/ rent"
 },
 {
  ar: "ايداع للاستيراد",
  he: "פיקדון יבוא",
  en: "Import deposit"
 },
 {
  ar: "ايد عاملة",
  he: "כוח אדם",
  en: "Manpower"
 },
 {
  ar: "ايراد",
  he: "הכנסה\/ פדיון",
  en: "Income\/ revenue"
 },
 {
  ar: "ايراد حدي",
  he: "פדיון שולי",
  en: "Marginal revenue"
 },
 {
  ar: "ايراد داخلي",
  he: "פדיון ממכירות מקומיות",
  en: "Inland revenue"
 },
 {
  ar: "ايصال",
  he: "קבלה",
  en: "Receipt"
 },
 {
  ar: "بائع",
  he: "מוכר",
  en: "Salesman\/ seller\/ vendor"
 },
 {
  ar: "بائع مرخص لة بالسعر الاصلي",
  he: "סוכן מורשה לפי הערך הנקוב\/ פארי",
  en: "Licensed dealer\/ licensed broker at par"
 },
 {
  ar: "بترول",
  he: "נפט",
  en: "Oil"
 },
 {
  ar: "بحث حالة السوق",
  he: "חקר שווקים",
  en: "Market research"
 },
 {
  ar: "بخشيش",
  he: "טיפ\/ תשר",
  en: "Tip"
 },
 {
  ar: "بخل",
  he: "קמצנות",
  en: "Stinginess"
 },
 {
  ar: "بدون حصة ارباح",
  he: "ללא זכות לדיווידנד",
  en: "Ex- dividend"
 },
 {
  ar: "بديل",
  he: "תחליף",
  en: "Substitute"
 },
 {
  ar: "برميل",
  he: "חבית",
  en: "Barrel"
 },
 {
  ar: "برنامج",
  he: "תכנית",
  en: "plan"
 },
 {
  ar: "برنامج مارشال",
  he: "תכנית מארשל",
  en: "Marshall plan"
 },
 {
  ar: "بريد",
  he: "דואר",
  en: "Post"
 },
 {
  ar: "بريد الدرجة الاولى",
  he: "דואר מהיר",
  en: "First class mail"
 },
 {
  ar: "بريد الدرجة الثانية",
  he: "דואר רגיל",
  en: "Second class mail"
 },
 {
  ar: "بضائع",
  he: "טובין",
  en: "Goods"
 },
 {
  ar: "بضائع مجمركة",
  he: "סחורות מוצברות",
  en: "Cleared goods"
 },
 {
  ar: "بضائع موجودة",
  he: "מלאי",
  en: "Stock\/ inventory"
 },
 {
  ar: "بضاعة",
  he: "סחורה",
  en: "Commodity\/ good"
 },
 {
  ar: "بضاعة تامة الصنع",
  he: "מוצר מוגמר",
  en: "Finished good\/ end product\/ final product"
 },
 {
  ar: "بضاعة محفزة للشراء",
  he: "מוצר דחף",
  en: "Impulse good"
 },
 {
  ar: "بضاعة مشحونة",
  he: "מטען",
  en: "Freight\/ lading\/ cargo"
 },
 {
  ar: "بضاعة مودعة في مستودع الجمرك",
  he: "סחורה המאוכסנת במחסן ערובה",
  en: "Bonded good"
 },
 {
  ar: "بطاقة اعتماد",
  he: "כרטיצ אשראי",
  en: "Credit card"
 },
 {
  ar: "بطالة",
  he: "אבטלה",
  en: "Unemployment"
 },
 {
  ar: "بطالة احتكاكية",
  he: "אבטלה חיכוכית",
  en: "Frictional unemployment"
 },
 {
  ar: "بطالة خفية",
  he: "אבטלה סמויה",
  en: "Hidden unemployment\/ disguised unemployment"
 },
 {
  ar: "بطالة مزمنة",
  he: "אבטלה כרונית",
  en: "Chronic unemployment"
 },
 {
  ar: "بطالة مقنعة",
  he: "אבטלה סמויה",
  en: "Hidden unemployment\/ disguised unemployment"
 },
 {
  ar: "بطالة موسمية",
  he: "אבטלה עונתית",
  en: "Seasonal unemployment"
 },
 {
  ar: "بطالة هيكلية",
  he: "אבטלה מבנית",
  en: "Structural unemployment"
 },
 {
  ar: "بطلان الاستعمال",
  he: "יציאה מכלל שימוש",
  en: "obsolescence"
 },
 {
  ar: "بقالة",
  he: "חנות מכולת",
  en: "grocery"
 },
 {
  ar: "البلى العادي",
  he: "בלאי",
  en: "Wear and tear"
 },
 {
  ar: "بناء",
  he: "בנייה",
  en: "Building"
 },
 {
  ar: "بنط",
  he: "נקודה",
  en: "Point"
 },
 {
  ar: "بنك",
  he: "בנק",
  en: "Bank"
 },
 {
  ar: "بنك اجنبي",
  he: "בנק זר",
  en: "Foreign bank"
 },
 {
  ar: "بنك استثمار",
  he: "בנק להשקעות",
  en: "Investment bank"
 },
 {
  ar: "بنك اسلامي",
  he: "בנק אסלאמי",
  en: "Islamic bank"
 },
 {
  ar: "بنك اعتماد",
  he: "בנק לאשראי",
  en: "Credit bank"
 },
 {
  ar: "بنك اعتماد زراعي",
  he: "בנק לאשראי חקלאי",
  en: "Rural credit bank "
 },
 {
  ar: "البنك الاسلامي للتنمية",
  he: "הבנק האסלאמי לפיתוח",
  en: "Islamic development bank"
 },
 {
  ar: "البنك الدولي",
  he: "הבנק העולמי",
  en: "World bank"
 },
 {
  ar: "البنك الدولي للانشاء والتعمير",
  he: "הבנק הבינלאומי לשיקום ופיתוח",
  en: "International bank for reconstruction and development"
 },
 {
  ar: "بنك تجاري",
  he: "בנק מסחרי",
  en: "Merchant bank"
 },
 {
  ar: "بنك تعاوني",
  he: "בנק קואופרטיבי",
  en: "Cooperative bank"
 },
 {
  ar: "بنك توفير",
  he: "בנק לחיסכון",
  en: "Savings bank"
 },
 {
  ar: "بنك خاص",
  he: "בנק פרטי",
  en: "Private bank"
 },
 {
  ar: "بنك خصم السندات",
  he: "בנק ניכיונות",
  en: "Discount bank"
 },
 {
  ar: "بنك ربوي",
  he: "בנק העוסק בריבית (בנק שאינו אסלאמי)",
  en: "Bank which deals with interest (a bank which is not an Islamic bank)"
 },
 {
  ar: "بنك رهونات",
  he: "בנק למשכנתאות",
  en: "Mortgage bank"
 },
 {
  ar: "بنك زراعي",
  he: "בנק לחקלאות",
  en: "Agricultural bank"
 },
 {
  ar: "بنك صناعي",
  he: "בנק לתעשייה",
  en: "industrial bank"
 },
 {
  ar: "بنك عضو",
  he: "בנק חבר",
  en: "Member bank"
 },
 {
  ar: "بنك مراسل",
  he: "בנק עמית",
  en: "Correspondent bank"
 },
 {
  ar: "بنك مركزي",
  he: "בנק מרכזי",
  en: "Central bank"
 },
 {
  ar: "بواسطة",
  he: "אצל",
  en: "Care of"
 },
 {
  ar: "بورجوازية",
  he: "בורגנות",
  en: "Bourgeoisie"
 },
 {
  ar: "بورصة",
  he: "בורסה",
  en: "Exchange"
 },
 {
  ar: "بورصة الاوراق المالية",
  he: "בורסה לניירות ערך",
  en: "Stock exchange"
 },
 {
  ar: "فاتورة",
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "بوليصة",
  he: "פוליסה",
  en: "policy"
 },
 {
  ar: "بوليصة شحن",
  he: "שטר מטען\/ שטר משגר",
  en: "Bill of lading\/ consignment note"
 },
 {
  ar: "بوليصة شحن اصلية",
  he: "שטר מטען מקורי",
  en: "Original bill of lading"
 },
 {
  ar: "بوليصة شحن بالطائرة",
  he: "שטר מטען אווירי",
  en: "Airway bill of lading"
 },
 {
  ar: "بوليصة شحن بسكة حديد",
  he: "שטר מטען על רכבת ",
  en: "Rail (way) bill of lading"
 },
 {
  ar: "بوليصة شحن بضائع تم شحنها",
  he: "שטר מטען שהטענתו בוצעה",
  en: "Shipped bill of lading"
 },
 {
  ar: "بوليصة شحن ذات تحفظات",
  he: "שטר מטען פגום",
  en: "Foul bill of lading"
 },
 {
  ar: "بوليصة شحن شخصية",
  he: "שטר מטעין ישיר",
  en: "Straight bill of lading"
 },
 {
  ar: "بوليصة شحن عبر وسائل نقل متتابعة",
  he: "שטר מטען במעבר",
  en: "Through bill of lading"
 },
 {
  ar: "بوليصة شحن غير نظيفة",
  he: "שטר מטען מלוכלך",
  en: "Unclean bill of lading\/ dirty bill of lading"
 },
 {
  ar: "بوليصة شحن قابلة للتداول",
  he: "שטר מטען סחיר",
  en: "Negotiable bill of lading"
 },
 {
  ar: "بوليصة شحن لامرها",
  he: "שטר מטען לפקודה",
  en: "Order bill of lading"
 },
 {
  ar: "بوليصة شحن لحاملها",
  he: "שטר מטען למוכ\"ז",
  en: "Bill of lading to bearer"
 },
 {
  ar: "بوليصة شحن متقادمة",
  he: "שטר מטען מיושן",
  en: "Stale bill of lading"
 },
 {
  ar: "بوليصة شحن ناقصة",
  he: "שטר מטען פגום",
  en: "Foul bill of lading"
 },
 {
  ar: "بوليصة شحن نظيفة",
  he: "שטר מטען נקי",
  en: "Clean bill of lading"
 },
 {
  ar: "بيان الارباح والخسائر",
  he: "דו\"ח רווח והפסד",
  en: "Profit and loss statement"
 },
 {
  ar: "بيان الاوضاع",
  he: "רשימת נכסים והתחייבויות",
  en: "Statement of affairs"
 },
 {
  ar: "بيان حساب",
  he: "מצב חשבון",
  en: "Statement of account"
 },
 {
  ar: "فاتورة",
  he: "חשבונית מס",
  en: "Invoice"
 },
 {
  ar: "بيان شكلي",
  he: "דו\"ח פרופורמה",
  en: "Pro-forma statement"
 },
 {
  ar: "بيان مالي",
  he: "דו\"ח פינאנסי\/ דו\"ח כספי",
  en: "Financial statement"
 },
 
 {
  ar: "بيان مالي مشترك",
  he: "דו\"ח פינאנסי מאוחד\/ דו\"ח כספי    מאוחד",
  en: "Combined financial statement\/ consolidated financial statement"
 },
 {
  ar: "بيان مالي موحد",
  he: "דו\"ח פינאנסי מאוחד\/ דו\"ח כספי מאוחד",
  en: "consolidated financial statement"
 },
 {
  ar: "بيان مالي مشترك",
  he: "ח פינאנסי מאוחד",
  en: "Combined financial statement\/ consolidated financial statement"
 },
 {
  ar: "بيان مصادر الاموال واستعمالاتها",
  he: "דו\"ח מקורות ושימושים",
  en: "Statement of sources and uses of funds\/ statement of sources and applications"
 },
 {
  ar: "بيت الاصدار",
  he: "בית הוצאה",
  en: "Issuing house"
 },
 {
  ar: " بيع",
  he: "מכירה",
  en: "sale"
 },
 {
  ar: "بيع الديون",
  he: "מכירת חובות",
  en: "factoring"
 },
 {
  ar: "بيع باسعار مخفضة",
  he: "מכירה במחירי הנחה",
  en: "Bargain sale"
 },
 {
  ar: "بيع بالتجزئة",
  he: "קמעונאות",
  en: "retail"
 },
 {
  ar: "بيع بالجملة",
  he: "סיטונאות",
  en: "wholesale"
 },
 {
  ar: "بيع بالدين",
  he: "מכירה באשראי",
  en: "Sale on credit"
 },
 {
  ar: "بيع بالمفرق",
  he: "קמעונאות",
  en: "retail"
 },
 {
  ar: "بيع بخس",
  he: "הצפה",
  en: "Dumping"
 },
 {
  ar: "بيع بشرط الاستحسان",
  he: "מכירה לאחר שהקונה קיבל את הסחורה והסכים לקנותה",
  en: "Sale on approval"
 },
 {
  ar: "بيع تصفية",
  he: "מכירת חיסול",
  en: "Clearing sale"
 },
 {
  ar: "بيع على المكشوف",
  he: "מכירה בחסר",
  en: "Selling short"
 },
 {
  ar: "بيع مباشر",
  he: "מכירה ישירה",
  en: "Direct selling"
 },
 {
  ar: "بيع مشروط",
  he: "מכירה מותנית",
  en: "Conditional sale"
 },
 
     
 {
  ar: "تاجر",
  he: "סוחר",
  en: "Tradesman\/ trader\/ merchant\/ dealer"
 },
 {
  ar: "تاجير",
  he: "החכרה\/ השכרה",
  en: "Lease\/ rent"
 },
 {
  ar: "تاجيل دفع الديون",
  he: "מורטוריום\/ דחיית פרעון חובות",
  en: "Moratorium"
 },
 {
  ar: "تاخير الدفع",
  he: "דחיית תשלום",
  en: "Delay of payment"
 },
 {
  ar: "تاريخ استحقاق ربح السهم",
  he: "מועד מוסדר לתשלום הדיווידנד",
  en: "Record date of dividend"
 },
 {
  ar: "تاريخ الاستحقاق",
  he: "מועד פירעון",
  en: "Maturity date"
 },
 {
  ar: "تاريخ الاستحقاق",
  he: "תאריך פירעון",
  en: "Due date"
 },
 {
  ar: "تاريخ الاقفال",
  he: "תאריך אחרון",
  en: "Closing date"
 },
 {
  ar: "تاريخ الانقضاء",
  he: "תאריך פקיעה",
  en: "Expiry date"
 },
 {
  ar: "تاريخ السريان",
  he: "תאריך כניסה לתוקף",
  en: "Date of effect"
 },
 {
  ar: "تاريخ محدد",
  he: "תאריך קבוע",
  en: "Fixed date"
 },
 {
  ar: "تاسيس شركة",
  he: "ייסוד חברה",
  en: "Formation of a company"
 },
 {
  ar: "تاكل",
  he: "שחיקה",
  en: "Erosion"
 },
 {
  ar: "تاميم",
  he: "הלאמה",
  en: "Nationalization"
 },
 {
  ar: "تامين ",
  he: "ביטוח",
  en: "Assurance\/ insurance"
 },
 {
  ar: "تامين جماعي",
  he: "ביטוח קיבוצי",
  en: "Group insurance"
 },
 {
  ar: "تامين ضد البطالة",
  he: "ביטוח אבטלה",
  en: "Unemployment insurance"
 },
 {
  ar: "تامين ضد الغير",
  he: "ביטוח צד שלישי",
  en: "Third party insurance"
 },
 {
  ar: "تامين على الحياة",
  he: "ביטוח חיים",
  en: "Life assurance"
 },
 {
  ar: "تامين قومي",
  he: "ביטוח לאומי",
  en: "National insurance"
 },
 {
  ar: "تامين مزدوج",
  he: "ביטןח יתר",
  en: "Double insurance"
 },
 {
  ar: "تبادل",
  he: "חליפין",
  en: "Exchange"
 },
 {
  ar: "تبادل الاسهم",
  he: "מסחר במניות",
  en: "Exchange of shares"
 },
 {
  ar: "تباطؤ في النشاط الاقتصادي",
  he: "האטה בפעילות הכלכלית",
  en: "Slowdown of economic activity"
 },
 {
  ar: "تبديل",
  he: "המרה",
  en: "Conversion"
 },
 {
  ar: "تبرع",
  he: "תרומה",
  en: "Contribution\/ donation"
 },
 {
  ar: "تثبيت الاسعار",
  he: "ייצוב המחירים",
  en: "Stabilization of prices"
 },
 {
  ar: "تثبيت قيمة العملة",
  he: "ייצוב ערך המטבע",
  en: "Currency stabilization"
 },
 {
  ar: "تجارة",
  he: "מסחר\/ סחר",
  en: "Commerce\/ trade"
 },
 {
  ar: "تجارة اجنبية",
  he: "סחר חוץ",
  en: "Foreign trade\/ external trade"
 },
 {
  ar: "تجارة المخدرات",
  he: "סחר בסמים",
  en: "Drug traffic"
 },
 {
  ar: "تجارة بالتجزئة",
  he: "קמעונאות",
  en: "retail"
 },
 {
  ar: "تجارة بالجملة",
  he: "סיטונאות",
  en: "Wholesale"
 },
 {
  ar: "تجارة ثلاثية",
  he: "סחר משולש",
  en: "Triangular trade"
 },
 {
  ar: "تجارة حرة",
  he: "סחר חופשי",
  en: "Free trade"
 },
 {
  ar: "تجارة خارجية",
  he: "סחר חוץ",
  en: "Foreign trade\/ external trade"
 },
 {
  ar: "تجارة داخلية",
  he: "סחר פנימי",
  en: "Domestic trade"
 },
 {
  ar: "تجارة دولية",
  he: "סחר בינלאומי",
  en: "International "
 },
 {
  ar: "تجارة ساحلية",
  he: "סחר חופים",
  en: "Coasting trade"
 },
 {
  ar: "تجارة عادلة",
  he: "מסחר הוגן",
  en: "Fair trade"
 },
 {
  ar: "تجارة غير قانونية",
  he: "סחר לא חוקי",
  en: "traffic"
 },
 {
  ar: "تجارة غير منظورة",
  he: "סחר סמוי",
  en: "Invisible trade"
 },
 {
  ar: "تجارية",
  he: "מרקנטליזם",
  en: "Mercantilism"
 },
 {
  ar: "تجديد",
  he: "חידוש",
  en: "Innovation"
 },
 {
  ar: "  تجزئة السهم",
  he: "פיצול מניה",
  en: "Stock split"
 },
 {
  ar: "تجميد الاجور",
  he: "הקפאת שכר",
  en: "Wages freeze"
 },
 {
  ar: "تجميد الاسعار",
  he: "הקפאת מחירים",
  en: "Price freeze"
 },
 {
  ar: "تجنب الضرائب",
  he: "השתמטות מתשלום מס",
  en: "Tax avoidance"
 },
 {
  ar: "تجيير",
  he: "הסבה",
  en: "Endorsement\/ indorsement"
 },
 {
  ar: "تحديد السعر",
  he: "קביעת מחיר",
  en: "Price fixing"
 },
 {
  ar: "تحركات راس المال",
  he: "העברת הון",
  en: "Capital transfers"
 },
 {
  ar: "تحسين",
  he: "שיפור\/ השבחה",
  en: "improvement"
 },
 {
  ar: "تحصيل",
  he: "גבייה",
  en: "Collection"
 },
 {
  ar: "تحقيق ربح",
  he: "עשיית רווח",
  en: "Making profit"
 },
 {
  ar: "تحليل التكاليف والعائدات",
  he: "ניתוח עלות- תועלת",
  en: "Cost- benefit analysis"
 },
 {
  ar: "تحمل",
  he: "ספיגה",
  en: "Absorption"
 },
 {
  ar: "تحمل البائع مصاريف الشحن وتحمل المشتري مصاريف التفريغ",
  he: "טעינה על חשבון המוכר ופריקה על חשבון הקונה",
  en: "Free out"
 },
 {
  ar: "تحمل المشتري مصاريف الشحن والتفريغ",
  he: "טעינה ופריקה על חשבון הקונה",
  en: "Free in and out"
 },
 {
  ar: "تحمل المشتري مصاريف الشحن وتحمل البائع مصاريف التفريغ",
  he: "טעינה על חשבון הקונה ופריקה על חשבון המוכר",
  en: "Free in"
 },
 {
  ar: "تحوط",
  he: "עסקת מגן\/ הדג'ינג",
  en: "hedging"
 },
 {
  ar: "تحويل",
  he: "העברה\/ העברת כסף",
  en: "Conveyance\/ transfer"
 },
 {
  ar:  "تحويل",
  he: "המרה",
  en: "Conversion"
 },
 {
  ar: "تحويل اجنبي",
  he: "מסחר במטבע חוץ",
  en: "Foreign exchange"
 },
 {
  ar: "تحويل اعتمادي",
  he: "העברת אשראי",
  en: "Credit transfer\/ giro"
 },
 {
  ar: "تحويل الديون",
  he: "העברת חובות",
  en: "Transfer of debts"
 },
 {
  ar: "تحويل خارجي",
  he: "מסחר במטבע חוץ",
  en: "Foreign exchange"
 },
 {
  ar: "تحويل مصرفي",
  he: "המרה בנקאית",
  en: "Bank exchange"
 },
 {
  ar: "تحويلات اعتمادية",
  he: "העברות בנקאיות",
  en: "Credit transfer"
 },
 {
  ar: "تخريب مفتعل",
  he: "חבלה",
  en: "Sabotage"
 },
 {
  ar: "تخصيص",
  he: "הפרטה",
  en: "Privatization"
 },
 {
  ar:"تخصيص",
  he: "הקצבה",
  en: "Appropriation"
 },
 {
  ar: "تخصيص",
  he: "הקצאה",
  en: "Allotment\/ allocation"
 },
 {
  ar: "تخطيط اقتصادي",
  he: "תכנון כלכלי",
  en: "Economic planning"
 },
 {
  ar: "تخطيط الدولة",
  he: "תכנון מרכזי",
  en: "Central planning\/ state planning"
 },
 {
  ar: "تخطيط حكومي",
  he: "תכנון מרכזי",
  en: "Central planning\/ state planning"
 },
 {
  ar: "تخفيض",
  he: "הנחה",
  en: "Rebate\/ discount\/ allowance\/ cutback"
 },
 {
  ar: "تخفيض",
  he: "קיצןץ",
  en: "discount"
 },
 {
  ar: "تخفيض الاجور",
  he: "קיצןץ בשכר",
  en: "Wages cut"
 },
 {
  ar: "تخفيض التكاليف",
  he: "צמצום עלויות",
  en: "Reduction of costs"
 },
 {
  ar: "تخفيض السعر",
  he: "הורדת מחיר\/ הוזלה",
  en: "Price cutting"
 },
 {
  ar: "تخفيض الضرائب",
  he: "הורדת מיסים\/ הקלה במס",
  en: "Tax cut\/ abatement of taxes"
 },
 {
  ar: "تخفيض المواصفات",
  he: "דילול",
  en: "Dilution"
 },
 {
  ar: "تخفيض زاحف",
  he: "פיחות זוחל",
  en: "Gradual devaluation\/creeping devaluation\/ crawling devaluation"
 },
 {
  ar: "تخفيض قيمة العملة",
  he: "פיחות",
  en: "Depreciation\/ devaluation"
 },
 {
  ar: "تدفق نقدي",
  he: "תזרים מזומנים",
  en: "Cash flow"
 },
 {
  ar: "تدقيق الحسابات",
  he: "ביקורת חשבונות\/ ראיית חשבון",
  en: "audit"
 },
 {
  ar: "تدقيق الحسابات الداخلي",
  he: "ביקורת פנים",
  en: "Internal auditing"
 },
 {
  ar: "تدقيق الحسابات المستقل",
  he: "ביקורת חשבונות בלתי תלויה",
  en: "Independent audit"
 },
 {
  ar: "تدقيق الميزانية",
  he: "ביקורת מאזן",
  en: "Balance sheet audit"
 },
 {
  ar: "تدقيق نقدي",
  he: "ביקורת כספים",
  en: "Cash audit"
 },
 {
  ar: "تذكرة",
  he: "כרטיס",
  en: "Ticket"
 },
 {
  ar: "تذكرة الدوام",
  he: "כרטיס נוכחות בעבודה",
  en: "Time ticket"
 },
 {
  ar: "تراكم",
  he: "צבירה",
  en: "Accumulation"
 },
 {
  ar: "ترشيد",
  he: "ייעול",
  en: "Making efficient"
 },
 {
  ar: "ترك",
  he: "נטישה",
  en: "abandonment"
 },
 {
  ar: "تركز",
  he: "מיזוג",
  en: "Integration\/ merger\/ consolidation"
 },
 {
  ar: "تركيز اساسي",
  he: "תשתית",
  en: "Infrastructure"
 },
 {
  ar: "ترميم",
  he: "שיקום",
  en: "Restoration"
 },
 {
  ar: "ترويج المبيعات",
  he: "קידום מכירות",
  en: "Sales promotion"
 },
 {
  ar: "تزوير",
  he: "זיוף",
  en: "Forgery\/ fraud"
 },
 {
  ar: "تسجيل مدين مباشر",
  he: "העברת כסף ישירות מחשבון לחשבון",
  en: "Direct debit"
 },
 {
  ar: "تسعير",
  he: "קביעת מחיר",
  en: "pricing"
 },
 {
  ar: "تسعير حسب المناطق",
  he: "קביעת מחיר על- פי אזור",
  en: "Zone pricing"
 },
 {
  ar: "تسليف",
  he: "אשראי",
  en: "Credit"
 },
 {
  ar: "تسليم",
  he: "מסירה",
  en: "Delivery"
 },
 {
  ar: "تسليم البضاعة داخل الشاحنة",
  he: "חופשי על המשאית",
  en: "Free on truck"
 },
 {
  ar: "تسليم خارج المركب",
  he: "מחיר על הרציף",
  en: "Ex- quay"
 },
 {
  ar: "تسليم خارج المصنع",
  he: "מחיר בשער המפעל",
  en: "Ex- factory"
 },
 {
  ar: "تسليم رصيف الميناء",
  he: "מחיר על הרציף ",
  en: "Ex- quay"
 },
 {
  ar: "تسليم رصيف محطة القطار",
  he: "חופשי על הרכבת",
  en: "Free on rail"
 },
 {
  ar: "تسليم سكة الحديد ",
  he: "חופשי על הרכבת",
  en: "Free on rail"
 },
 {
  ar: "تسليم ظهر المركب",
  he: "מחיר בנמל",
  en: "Ex- ship"
 },
 {
  ar: "تسليم على ظهر السفينة",
  he: "חופשי על סיפון האונייה",
  en: "Free on board"
 },
 {
  ar: "تسليم فعلي",
  he: "מסירה בפועל",
  en: "Actual delivery"
 },
 {
  ar: "تسوية الحسابات",
  he: "סילוק חשבונות",
  en: "Credit clearing"
 },
 {
  ar: "تسويق",
  he: "שיווק",
  en: "marketing"
 },
 {
  ar: "تشريك",
  he: "קולקטיוויזציה",
  en: "collectivization"
 },
 {
  ar: "تصدير",
  he: "יצוא",
  en: "export"
 },
 {
  ar: "تصدير راس مال",
  he: "יצוא הון",
  en: "Capital export"
 },
 {
  ar: "تصدير منظور",
  he: "יצוא גלוי",
  en: "Visible export"
 },
 {
  ar: "تصديق مصرفي",
  he: "אישור בנקאי",
  en: "Bank confirmation"
 },
 {
  ar: "تصريح",
  he: "הצהרה",
  en: "Declaration"
 },
 {
  ar:  "تصريح",
  he: "רשיון",
  en: "license"
 },
 {
  ar: "تصريح استيراد",
  he: "רשיון יבוא",
  en: "Import license"
 },
 {
  ar: "تصريح تصدير",
  he: "רשיון יצוא",
  en: "Export license"
 },
 {
  ar: "تصريف خاص",
  he: "הנפקה פרטית",
  en: "Private placement\/ private placing"
 },
 {
  ar: "تصفية",
  he: "סילוק (חשבון)",
  en: "Clearance\/ defrayment\/ liquidation"
 },
 {
  ar: "تصفية",
  he: "פירוק",
  en: "defrayment"
 },
 {
  ar: "تصنيع",
  he: "תיעוש",
  en: "Industrialization\/ manufacturing\/ production"
 },
 {
  ar: "تصنيع",
  he: "ייצור",
  en: " manufacturing"
 },
 {
  ar: "تضخم جامح",
  he: "אינפלציה דוהרת",
  en: "Runaway inflation\/ galloping inflation"
 },
 {
  ar: "تضخم جامع",
  he: "היפר- אינפלציה",
  en: "Hyperinflation"
 },
 {
  ar: "تضخم حلزوني",
  he: "סחרור אינפלציוני",
  en: "Inflation spiral"
 },
 {
  ar: "تضخم لولبي",
  he: "סחרור אינפלציוני",
  en: "Inflation spiral"
 },
 {
  ar: "تضخم مالي",
  he: "אינפלציה",
  en: "Inflation"
 },
 {
  ar: "تضخم مفرط",
  he: "היפר- אינפלציה",
  en: "Hyperinflation"
 },
 {
  ar: "تضخم مكبوت",
  he: "אינפלציה כבושה",
  en: "Repressed inflation"
 },
 {
  ar: "تضخم نقدي",
  he: "אינפלציה",
  en: "Inflation"
 },
 {
  ar: "تضييق الاعتمادات",
  he: "מצוקת אשראי",
  en: "Credit squeeze"
 },
 {
  ar: "تطوير",
  he: "פיתוח",
  en: "Development"
 },
 {
  ar: "تظهير",
  he: "הסבה",
  en: "Endorsement\/ Indorsement"
 },
 {
  ar: "تعاونية",
  he: "קואופרטיבי",
  en: "Cooperative"
 },
 {
  ar: "تعايش الكساد والتضخم",
  he: "סטגפלציה",
  en: "Stagflation"
 },
 {
  ar: "تعجيل في النشاط الاقتصادي",
  he: "האצה בפעילות הכלכלית",
  en: "Acceleration of economic activity"
 },
 {
  ar: "تعرفة",
  he: "תעריף",
  en: "Tariff\/ rate"
 },
 {
  ar: "تعرفة اتفاقية",
  he: "מכס חוזי",
  en: "Conventional tariff"
 },
 {
  ar: "تعرفة الترانزبت",
  he: "מכס מעבר",
  en: "Transit duty"
 },
 {
  ar: "تعرفة ايرادية",
  he: "מכס פיסקאלי",
  en: "Revenue tariff"
 },
 {
  ar: "تعرفة جمركية",
  he: "מכס",
  en: "Import tariff\/ customs\/ import duty"
 },
 {
  ar: "تعرفة عامة",
  he: "מכס כללי",
  en: "General tariff"
 },
 {
  ar: "تعرفة مانعة",
  he: "מכס מונע",
  en: "Prohibitive tariff"
 },
 {
  ar: "تعرفة مرور",
  he: "מכס מעבר",
  en: "Transit tariff"
 },
 {
  ar: "تعرفة مضادة",
  he: "מכס מאזן",
  en: "Countervailing tariff"
 },
 {
  ar: "تعويض",
  he: "פיצוי",
  en: "Compensation\/ reparation"
 },
 {
  ar: "تعويض الصرف",
  he: "פיצוי פיטורים",
  en: "Severance pay"
 },
 {
  ar: "تعويض انهاء الخدمة",
  he: "פיצוי פיטורים",
  en: "Severance pay"
 },
 {
  ar: "تغطية",
  he: "עסקת מגן\/ הדג'ינג",
  en: "hedging"
 },
 {
  ar: "تفاوت الدخول",
  he: "אי- שוויוניות בהכנסות ",
  en: "Inequality of incomes"
 },
 {
  ar: "تقاعد",
  he: "פרישה",
  en: "Retirement"
 },
 {
  ar: "تقاعد اجباري",
  he: "פרישה המחויבת על פי חוק או הסדר",
  en: "Compulsory retirement"
 },
 {
  ar: "تقدم",
  he: "התקדמות",
  en: "Progression"
 },
 {
  ar: "تقدير",
  he: "אומדן\/ הערכה ",
  en: "Estimate\/ valuation"
 },
 {
  ar: "تقدير بخس",
  he: "הערכת חסר",
  en: "Underestimate"
 },
 {
  ar: "تقدير حقيقي",
  he: "מונחים ריאלים",
  en: "Real terms"
 },
 {
  ar: "تقدير مغالى فية",
  he: "הערכת יתר",
  en: "Overestimate"
 },
 {
  ar: "تقرير",
  he: "דו\"ח",
  en: "Report"
 },
 {
  ar: "تقرير دوري",
  he: "דו\"ח תקופתי",
  en: "Periodic report"
 },
 {
  ar: "تقرير سنوي",
  he: "דו\"ח שנתי",
  en: "Annual repot"
 },
 {
  ar: "تقسيط",
  he: "תשלומים",
  en: "installments"
 },
 {
  ar: "تقسيم العمل",
  he: "חלוקת עבודה",
  en: "Division of labour"
 },
 {
  ar: "تقشف",
  he: "צנע",
  en: "Austerity"
 },
 {
  ar: "تقنين ",
  he: "קיצוב",
  en: "Rationing"
 },
 {
  ar: "تقنين الاعتماد",
  he: "קיצוב אשראי",
  en: "Credit rationing"
 },
 {
  ar: "تقييد",
  he: "הגבלה",
  en: "Restriction"
 },
 {
  ar: "تقييم",
  he: "שומה\/ הערכה",
  en: "Valuation\/ estimate"
 },
 {
  ar: "تكافؤ الفرص",
  he: "שוויון הזדמנויות",
  en: "Equalization of opportunities"
 },
 {
  ar: "تكاليف الانتاج",
  he: "עלויות ייצור",
  en: "Manufacturing costs\/ production costs"
 },
 {
  ar: "تكاليف التصنيع",
  he: "עלויות ייצור",
  en: "Manufacturing costs\/ production costs"
 },
 {
  ar: "تكاليف المعيشة",
  he: "יוקר מחיה",
  en: "Cost of living"
 },
 {
  ar: "تكاليف مختلطة",
  he: "עלויות מעורבות",
  en: "Mixed costs"
 },
 {
  ar: "تكاليف مشتركة",
  he: "עלויות משולבות",
  en: "Joint costs"
 },
 {
  ar: "تكامل افقي",
  he: "שילוב מאוזן",
  en: "Horizontal integration"
 },
 {
  ar: "تكامل عمودي",
  he: "שילוב מאונך",
  en: "Vertical integration"
 },
 {
  ar: "تكلفة",
  he: "עלות",
  en: "Cost"
 },
 {
  ar: "تكلفة اساسية",
  he: "עלות בסיסית",
  en: "Prime cost"
 },
 {
  ar: "تكلفة اصلية",
  he: "עלות מקורית",
  en: "Original cost "
 },
 {
  ar: "تكلفة الابدال",
  he: "עלות חילוף",
  en: "Replacement cost"
 },
 {
  ar: "تكلفة الاختيار",
  he: "עלות אלטרנטיבית",
  en: "Opportunity cost"
 },
 {
  ar: "تكلفة الحصول",
  he: "עלות קנייה",
  en: "Acquisition cost"
 },
 {
  ar: "تكلفة السلع المبيعة",
  he: "עלות מכירות",
  en: "Cost of goods sold"
 },
 {
  ar: "تكلفة تارخية",
  he: "עלות היסטורית",
  en: "Historical cost"
 },
 {
  ar: "تكلفة ثابتة",
  he: "עלות קבועה",
  en: "Fixed cost"
 },
 {
  ar: "تكلفة حدية",
  he: "עלות שולית",
  en: "Marginal cost"
 },
 {
  ar: "تكلفة حقيقية",
  he: "עלות בפועל",
  en: "Actual cost"
 },
 {
  ar: "تكلفة راسمالية",
  he: "עלות הון",
  en: "Cost of capital"
 },
 {
  ar: "تكلفة غير مباشرة ",
  he: "עלות עקיפה",
  en: "Indirect cost\/ on cost"
 },
 {
  ar: "تكلفة غير متكررة",
  he: "עלות שקועה",
  en: "Sunk cost"
 },
 {
  ar: "تكلفة لا يمكن تجنبها",
  he: "עלות בלתי נמנעת",
  en: "Unavoidable cost"
 },
 {
  ar: "تكلفة مباشرة",
  he: "עלות ישירה",
  en: "Direct cost"
 },
 {
  ar: "تكلفة متزايدة",
  he: "עלות תוספתית",
  en: "Incremental cost"
 },
 {
  ar: "تكلفة متغيرة",
  he: "עלות משתנה",
  en: "Variable cost"
 },
 {
  ar: "تكلفة منسوبة",
  he: "עלות ניתנת לייחוס",
  en: "Attributable cost"
 },
 {
  ar: "تكلفة يمكن تجنبها",
  he: "עלות נמנעת",
  en: "Avoidable cost"
 },
 {
  ar: "تكلفة يمكن مراقبتها",
  he: "עלות נשלטת",
  en: "Controllable cost"
 },
 {
  ar: "تكليف",
  he: "היטל",
  en: "Charge\/ duty"
 },
 {
  ar: "تكوين راس المال",
  he: "יצירת הון",
  en: "Capital formation"
 },
 {
  ar: "تكوين شركة",
  he: "יצירת חברה",
  en: "Formation of a company"
 },
 {
  ar: "تمويل",
  he: "מימון",
  en: "Financing"
 },
 {
  ar: "تمويل ذاتي",
  he: "מימון עצמי",
  en: "Self- financing"
 },
 {
  ar: "تمييز في استيفاء الاسعار",
  he: "הפליית מחירים",
  en: "Price discrimination"
 },
 {
  ar: "تنازل",
  he: "ויתור",
  en: "disclaiming"
 },
 {
  ar: "تناقص",
  he: "הפחתה",
  en: "decrease"
 },
 {
  ar: "تنبؤ اقتصادي",
  he: "תחזית כללית",
  en: "Economic forecast"
 },
 {
  ar: "تنزيل",
  he: "הנחה",
  en: "Rebate\/ discount\/ allowance"
 },
 {
  ar: "تنمية",
  he: "פיתוח",
  en: "Development"
 },
 {
  ar: "تنمية اقتصادية",
  he: "פיתוח כלכלי",
  en: "Economic development"
 },
 {
  ar: "تنويع الاستثمار",
  he: "גיוו ההשקעה",
  en: "Diversification of investment"
 },
 {
  ar: "تهريب",
  he: "הברחה",
  en: "Smuggling"
 },
 {
  ar: "توحيد",
  he: "איחוד\/ מיזוג",
  en: "Consolidation\/ integration\/ merger"
 },
 {
  ar: "توزيع",
  he: "הקצאה",
  en: "Allotment\/ allocation"
 },
 {
  ar: "توزيع الاسهم",
  he: "הקצאת מניות",
  en: "Allotment of shares"
 },
 {
  ar: "توزيع الدخل",
  he: "התפלגות הכנסות",
  en: "Income distribution"
 },
 {
  ar: "توسع المؤسسة",
  he: "הרחבת פעילות של חברה",
  en: "Expansion of a firm"
 },
 {
  ar: "توظيف",
  he: "השקעה",
  en: "Investment"
 },
 {
  ar: "توفير",
  he: "חיסכון",
  en: "Savings"
 },
 {
  ar: "توقيع",
  he: "חתימה",
  en: "signature"
 },
 
 {
  ar: "ثروة",
  he: "עושר",
  en: "Wealth\/ richness"
 },
 {
  ar: "ثمن",
  he: "עלות\/ מחיר",
  en: "Cost\/ price"
 },
 {
  ar: "ثمن الوحدة",
  he: "עלות ליחידה",
  en: "Unit cost"
 },
 {
  ar: "ثمن مفترض",
  he: "עלות זקופה",
  en: "Imputed cost "
 },
 {
  ar: "ثمن مقدر",
  he: "עלות זקופה",
  en: "Imputed cost "
 },
 {
  ar: "الثروة الصناعية",
  he: "המהפכה התעשייתית",
  en: "industrial revolution"
 },
 
 {
  ar: "جباية",
  he: "גבייה",
  en: "Collection"
 },
 {
  ar: "جرد",
  he: "מלאי",
  en: "Inventory\/ stock"
 },
 {
  ar: "جرد مستمر",
  he: "מלאי מתמשך",
  en: "Continuous inventory"
 },
 {
  ar: "الجماعة الاقتصادية الاوروبية",
  he: "הקהילה הכלכלית האירופית",
  en: "European economic"
 },
 {
  ar: "جمرك",
  he: "מכס",
  en: "Customs\/ import duty\/ import tariff"
 },
 {
  ar: "جمرك",
  he: "מכס",
  en: "Customs house"
 },
 {
  ar: "جمرك",
  he: "בית המכס",
  en: "Customs"
 },
 {
  ar: "جمع الاموال",
  he: "גיוס כספים",
  en: "Raising of funds"
 },
 {
  ar: "جمعية",
  he: "התאחדות",
  en: "Association"
 },
 {
  ar: "الجمعية الاوروبية للتجارة الحرة",
  he: "ההתאחדות האירופית לסחר חופשי",
  en: "European free trade association"
 },
 {
  ar: "جمعية تعاونية",
  he: "אגודה שיתופית",
  en: "Cooperative society"
 },
 {
  ar: "جمعية عمومية للمساهمين",
  he: "אסיפה כללית של בעלי המניות",
  en: "Shareholders' general meeting"
 },
 {
  ar: "جيرو",
  he: "העברת אשראי",
  en: "Credit transfer\/ giro"
 },
 
 {
  ar: "حالة",
  he: "מצב",
  en: " Condition"
 },
 {
  ar: "حامل اسهم",
  he: "בעל מניות",
  en: "Shareholder\/ stockholder"
 },
 {
  ar: "حجم الاعمال التجارية",
  he: "פדיון של עסק\/ מחזור",
  en: "Turnover"
 },
 {
  ar: "حد ادنى",
  he: "מינימום",
  en: "Minimum"
 },
 {
  ar: "حد اقصى",
  he: "מקסימום",
  en: "Maximum"
 },
 {
  ar: "حد الربح",
  he: "שולי רווח",
  en: "Margin of profit"
 },
 {
  ar: "حدود التبادل",
  he: "תנאי סחר",
  en: "Terms of trade"
 },
 {
  ar: "حرب الاسعار",
  he: "מלחמת מחירים",
  en: "Price war"
 },
 {
  ar: "حرفة ",
  he: "מקצוע",
  en: "Profession"
 },
 {
  ar: "حركة",
  he: "תנועה",
  en: "Traffic"
 },
 {
  ar: "حركة النقد",
  he: "תזרים מזומנים",
  en: "Cash flow"
 },
 {
  ar: "حساب",
  he: "חשבון",
  en: "Account\/ invoice\/ bill"
 },
 {
  ar: "حساب اسمي",
  he: "חשבון נומינאלי",
  en: "Nominal account"
 },
 {
  ar: "حساب اعتماد",
  he: "חשבון אשראי",
  en: "Credit account"
 },
 {
  ar: "حساب السلف والقروض",
  he: "חשבון הלוואות",
  en: "Loan account"
 },
 {
  ar: "حساب المبيعات",
  he: "חשבון מכירות",
  en: "Sales account"
 },
 {
  ar: "حساب المراقبة",
  he: "חשבון ראשי",
  en: "Control account"
 },
 {
  ar: "حساب النقص",
  he: "חשבון גירעוני",
  en: "Deficiency account"
 },
 {
  ar: "حساب الودائع",
  he: "חשבון פיקדון",
  en: "Deposit account"
 },
 {
  ar: "حساب ايداع",
  he: "חשבון פיקדון",
  en: "Deposit account"
 },
 {
  ar: "حساب تقسيط الدفع",
  he: "חשבון תשלומים",
  en: "Installment account"
 },
 {
  ar: "حساب توفير",
  he: "חשבון חיסכון",
  en: "Savings account"
 },
 {
  ar: "حساب جاري",
  he: "חשבון שוטף",
  en: "Running account"
 },
 {
  ar:"حساب جاري",
  he: "חשבון עובר ושב",
  en: "Checking account\/ current account"
 },
 {
  ar: "حساب جاري خاص",
  he: "חשבון עובר ושב מיוחד",
  en: "Special checking account"
 },
 {
  ar: "حساب خارجي",
  he: "חשבון חיצוני",
  en: "External account"
 },
 {
  ar: "حساب ختامي",
  he: "חשבון סגור",
  en: "Closing account"
 },
 {
  ar: "حساب ختامي",
  he: "חשבון סופי",
  en: "Final account"
 },
 {
  ar: "حساب شيكات",
  he: "חשבון שיקים",
  en: "Drawing account"
 },
 {
  ar: "حساب غير المقيم",
  he: "חשבון תושב חוץ",
  en: "Non- resident account"
 },
 {
  ar: "حساب غير شخصي",
  he: "חשבון לא אישי",
  en: "Impersonal account"
 },
 {
  ar: "حساب غير نشط",
  he: "חשבון לא פעיל",
  en: "Inactive account"
 },
 {
  ar: "حساب مدين",
  he: "חשבון במשיכת יתר",
  en: "Bank debit"
 },
 {
  ar: "حساب مشترك",
  he: "חשבון משותף",
  en: "Joint account"
 },
 {
  ar: "حساب مصرفي",
  he: "חשבון בנק",
  en: "Bank account"
 },
 {
  ar: "حساب مفتوح",
  he: "חשבון פתוח",
  en: "Open account"
 },
 {
  ar: "حساب مقيم",
  he: "פיקדון תושב מקומי\/ חשבון תושב מקומי",
  en: "Resident account"
 },
 {
  ar: "حساب مكشوف",
  he: "חשבון שבוצעה בו משיכת יתר",
  en: "Overdrawn account"
 },
 {
  ar: "حساب نشط",
  he: "חשבון פעיל",
  en: "Active account"
 },
 {
  ar: "حسابات مرحلية",
  he: "דו\"ח ביניים",
  en: "Interim account"
 },
 {
  ar: "حسابات موحدة",
  he: "חשבונות מאוחדים",
  en: "Consolidated accounts"
 },
 {
  ar: "حسب القيمة",
  he: "לפי הערך",
  en: "Ad valorem"
 },
 {
  ar: "حسم",
  he: "ניכוי",
  en: "Deduction"
 },
 {
  ar: "حسم",
  he: "הנחה",
  en: "Allowance\/ discount\/ rebate"
 },
 {
  ar: "حسم تجاري",
  he: "הנחה קמעונית",
  en: "Trade discount"
 },

 {
  ar: "حصار",
  he: "בלוקדה",
  en: "Blockade"
 },
 {
  ar: "حصر",
  he: "הגבלה",
  en: "restriction"
 },
 {
  ar: "حصة",
  he: "מכסה",
  en: "Quota"
 },
 {
  ar: "شارك",
  he: "מניה",
  en: "Share\/ stock"
 },
 {
  ar: "حصة ارباح",
  he: "דיווידנד",
  en: "Dividend"
 },
 {
  ar: "حصة ارباح اضافية",
  he: "דיווידנד נוסף",
  en: "Extra dividend"
 },
 {
  ar: "حصة ارباح عادية",
  he: "דיווידנד רגיל",
  en: "Regular dividend"
 },
 {
  ar: "حصة ارباح متجمعة",
  he: "דיווידנד נצבר",
  en: "Accumulated account "
 },
 {
  ar: "حصة ارباح مستحقة",
  he: "דיווידנד נצבר שעדיין לא שולם",
  en: "Accrued dividend"
 },
 {
  ar: "حصة ارباح نهاية السنة",
  he: "דיווידנד לסוף שנה",
  en: "Year end dividend"
 },
 {
  ar: "حضيض",
  he: "שפל",
  en: "through"
 },
 {
  ar: "حظر",
  he: "אמברגו",
  en: "Embargo"
 },
 {
  ar: "حظر الاستيراد",
  he: "איסור יבוא",
  en: "Import ban"
 },
 {
  ar: "حق التاليف والنشر",
  he: "זכויות יוצרים",
  en: "copyright"
 },
 {
  ar: "حق التوقيع",
  he: "זכות חתימה",
  en: "Right to sign"
 },
 {
  ar: "حق الحجز",
  he: "שיעבוד",
  en: "lien"
 },
 {
  ar: "حق بيع الاسهم بسعر محدود",
  he: "אופציית מכר",
  en: "Put option"
 },
 {
  ar: "حق شراء الاسهم بسعر محدود",
  he: "אופצצית רכש",
  en: "Call option"
 },
 {
  ar: "حقن",
  he: "הזרמה",
  en: "injection"
 },
 {
  ar: "حقوق الاختراع",
  he: "זכויות פטנט",
  en: "Patent rights"
 },
 {
  ar: "حقوق السحب الخاصة",
  he: "זכויות משיכה מיוחדים",
  en: "Special drawing rights"
 },
 {
  ar: "حقوق المساهم",
  he: "הון עצמי של פירמה",
  en: "equity"
 },
 {
  ar: "حكم",
  he: "בורר",
  en: "Arbiter\/ arbitrator"
 },
 {
  ar: "حمولة",
  he: "מטען",
  en: "Cargo\/ lading\/ freight"
 },
 {
  ar: "حمولة بالطن",
  he: "טונאז'\/ מספר הטונות",
  en: "tonnage"
 },
 {
  ar: "حواجز جمركية",
  he: "חומות מכס",
  en: "Tariff walls "
 },
 {
  ar: " حوافز التصدير",
  he: "תמריצי יצוא",
  en: "Export incentives"
 },
 {
  ar: "حوالة",
  he: "שיק\/ המחאה\/ שטר חליפין",
  en: "Check\/ cheque\/ draft\/ bill of exchange"
 },
 
 {
  ar: "خادم",
  he: "משרת",
  en: "Servant"
 },
 {
  ar: "خاص",
  he: "פרטי",
  en: "private"
 },
 {
  ar: "خاضع لضريبة",
  he: "חייב במס",
  en: "Liable to tax\/ taxable"
 },
 {
  ar: "خالص اجرة البريد",
  he: "דמי הדואר שולמו",
  en: "Post- free\/ post paid"
 },
 {
  ar: "خام",
  he: "חומר גלם",
  en: "Raw material"
 },
 {
  ar: "خبير حسابات تامين",
  he: "אקטואר",
  en: "actuary"
 },
 {
  ar: "خدمات اجتماعية",
  he: "שירותי רווחה",
  en: "Social services"
 },
 {
  ar: "خدمات ادارية",
  he: "שירותי מנהלה",
  en: "Administrative services"
 },
 {
  ar: "خدمات بلدية",
  he: "שירותים מוניציפאליים",
  en: "Municipal services"
 },
 {
  ar: "خدمة",
  he: "שירות",
  en: "service"
 },
 {
  ar: "خدمة البريد",
  he: "שירות הדואר",
  en: "Postal service"
 },
 {
  ar: "خدمة عامة",
  he: "שירות ציבורי",
  en: "Public service"
 },
 {
  ar: "خروج الرساميل ",
  he: "נדידת הון",
  en: "Exodus of capital"
 },
 {
  ar: "خزانة",
  he: "אוצר המדינה",
  en: "Exchequer"
 },
 {
  ar: "خزانة فولاذية",
  he: "כספת",
  en: "safe"
 },
 {
  ar: "خسائر الراسمال",
  he: "הפסד הון",
  en: "Capital loss"
 },
 {
  ar: "خسارة",
  he: "הפסד",
  en: "loss"
 },
 {
  ar: "خسارة جسيمة",
  he: "הפסד כבד",
  en: "Heavy loss"
 },
 {
  ar: "خسارة فعلية",
  he: "הפסד בפועל",
  en: "Actual loss"
 },
 {
  ar: "خسارة كلية",
  he: "נזק שאינו ניתן לתיקון",
  en: "Total loss"
 },
 {
  ar: "خسارة ناتجة",
  he: "הפסד עקיף",
  en: "Consequential loss"
 },
 {
  ar: "خصخصة",
  he: "הפרטה",
  en: "Privatization"
 },
 {
  ar: "خصم",
  he: "ניכיון",
  en: "discount"
 },
 {
  ar: "خصم مؤجل",
  he: "הטבה נדחית",
  en: "Deferred rebate"
 },
 {
  ar: "خط اعتماد",
  he: "קו אשראי",
  en: "Credit line"
 },
 {
  ar: "خط انتاج",
  he: "קו ייצור",
  en: "Production line"
 },
 {
  ar: "خطة",
  he: "תכנית",
  en: "plan"
 },
 {
  ar: "خطة خمسية",
  he: "תכנית חומש",
  en: "Five- year plan"
 },
 {
  ar: "خطر",
  he: "סיכון",
  en: "risk"
 },
 {
  ar: "خلاف عمالي",
  he: "סכסוך עבודה",
  en: "Labour dispute"
 },
 {
  ar: "خيار",
  he: "אופציה",
  en: "Option"
 },
 {
  ar: "خيار البيع",
  he: "אופציית מכר",
  en: "Put option"
 },
 {
  ar: "خيار الشراء",
  he: "אופציית רכש",
  en: "Call option"
 },
 
 {
  ar: "دائرة المبيعات",
  he: "מחלקת מכירות",
  en: "Sales department"
 },
 {
  ar: "دائن",
  he: "מלווה",
  en: "Lender\/ creditor"
 },
 {
  ar: "دائن ممتاز",
  he: "נושה מועדף",
  en: "Preferred creditor"
 },
 {
  ar: "دار سك العملة",
  he: "מטבעה",
  en: "mint"
 },
 {
  ar: "دافع",
  he: "משלם",
  en: "Payer"
 },
 {
  ar: "دافع الضريبة",
  he: "משלמם מסים",
  en: "taxpayer"
 },
 {
  ar: "دخل",
  he: "הכנסה",
  en: "Income\/ revenue"
 },
 {
  ar: "دخل اجمالي",
  he: "הכנסה פרוטו",
  en: "Gross income"
 },
 {
  ar: "دخل الانفاق",
  he: "הכנסה פנויה",
  en: "Disposable income\/ franked income"
 },
 {
  ar: "دخل حدي",
  he: "הכנסה שולית",
  en: "Marginal income"
 },
 {
  ar: "دخل حقيقي",
  he: "הכנסה ריאלית",
  en: "Real income"
 },
 {
  ar: "دخل صافي",
  he: "הכנסה נקייה",
  en: "Net income"
 },
 {
  ar: "دخل غير مكتسب",
  he: "הכנסה שלא מגיעה אישית",
  en: "Unearned income"
 },
 {
  ar: "دخل قومي",
  he: "הכנסה לאומית",
  en: "National income"
 },
 {
  ar: "دخل لكل شخص",
  he: "הכנסה לנפש",
  en: "Per capita income"
 },
 {
  ar: "دخل متاح",
  he: "הכנסה פניוה",
  en: "Disposable income\/ franked income"
 },
 {
  ar: "دخل متجمع",
  he: "הכנסה נצברת",
  en: "Accrued income"
 },
 {
  ar: "دخل مدفوع الضريبة",
  he: "הכנסה פנויה",
  en: "Disposable income\/ franked income"
 },
 {
  ar: "دخل مفترض",
  he: "הכנסה זקופה",
  en: "Imputed income"
 },
 {
  ar: "دخل مقدر",
  he: "הכנסה זקופה",
  en: "Imputed income"
 },
 {
  ar: "دخل مكتسب",
  he: "הכנסה מיגיעה אישית",
  en: "Earned money"
 },
 {
  ar: "دخل نقدي",
  he: "הכנסה כספית",
  en: "Money income"
 },
 {
  ar: "الدرجة الاولى",
  he: "מחלקה ראשונה\/ מובחר",
  en: "First class"
 },
 {
  ar: "دفتر الجرد",
  he: "ספר מלאי",
  en: "Book of inventory"
 },
 {
  ar: "دفتر الفواتير",
  he: "ספר חשבוניות",
  en: "Invoice book"
 },
 {
  ar: "دفتر المحاسبة",
  he: "ספר חשבונות",
  en: "Accounting book"
 },
 {
  ar: "دفتر المودع",
  he: "פנקס הפקדות",
  en: "Depositor's book"
 },
 {
  ar: "دفتر شيكات",
  he: "פנקס שיקים",
  en: "chequebook"
 },
 {
  ar: "دفع",
  he: "תשלום ",
  en: "payment"
 },
 {
  ar: "دفع بشيك",
  he: "תשלום בשיק",
  en: "Payment by cheque"
 },
 {
  ar: "دفع سلفا",
  he: "תשלום מראש",
  en: "Payment in advice\/ Prepayment"
 },
 {
  ar: "دفع عند الاستلام",
  he: "תשלום בעת הקבלה",
  en: "Pay on receipt"
 },
 {
  ar: "دفع عند التسليم",
  he: "תשלום בעת המסירה",
  en: "Pay on delivery"
 },
 {
  ar: "دفع عيني",
  he: "תשלום בעין (בסחורה עצמה)",
  en: "Payment in kind"
 },
 {
  ar: "دفع لقاء المستندات",
  he: "מזומן כנגד מסמכים",
  en: "Cash against documents"
 },
 {
  ar: "دفع مقدما",
  he: "תשלום מראש",
  en: "Payment in advice\/ Prepayment"
 },
 {
  ar: "دفع نقدا",
  he: "תשלום במזומן",
  en: "Cash payment"
 },
 {
  ar: "دفع نقدا عند التسليم",
  he: "תשלום במזומן בעת המסירה",
  en: "Cash on delivery"
 },
 {
  ar: "دفع نوعي",
  he: "תשלום בעין (בסחורה עצמה)",
  en: "Payment in kind"
 },
 {
  ar: "دفعة",
  he: "תשלום ",
  en: "payment"
 },
 {
  ar: "دفعة اضافية",
  he: "תשלום נוסף",
  en: "surcharge"
 },
 {
  ar: "دفعة جزئية",
  he: "תשלום חלקי",
  en: "Partial payment"
 },
 {
  ar: "دفعة رمزية",
  he: "תשלום סמלי",
  en: "Token payment"
 },
 {
  ar: "دفعة كاملة",
  he: "תשלום מלא",
  en: "Full pay"
 },
 {
  ar: "دفعة مؤجلة",
  he: "תשלום דחוי",
  en: "Deferred payment"
 },
 {
  ar: "دكان",
  he: "חנות",
  en: "Shop\/ store"
 },
 {
  ar: "دلال",
  he: "כרוז במכירה פומבית",
  en: "auctioneer"
 },
 {
  ar: "دليل",
  he: "מדד",
  en: "index"
 },
 {
  ar: "دمج",
  he: "מיזוג",
  en: "Integration\/ merger\/ consolidation"
 },
 {
  ar: "دورة",
  he: "מחזור",
  en: "turnover"
 },
 {
  ar: "دورة تجارية",
  he: "מחזור עסקים",
  en: "Trade cycle"
 },
 {
  ar: "دولار النفط",
  he: "פטרו- דולר",
  en: "petrodollar"
 },
 {
  ar: "دولار اوروبي",
  he: "יורו דולר",
  en: "Eurodollar"
 },
 {
  ar: "دولة اكثر رعاية",
  he: "אומה מועדפת ביותר",
  en: "Most favoured nation"
 },
 {
  ar: "دولة الرفاهية",
  he: "מדינת סעד\/ מדינת רווחה",
  en: "Welfare state"
 },
 {
  ar: "دولة دائنة",
  he: "מדינה נושה\/ מדינה בעלת מאזן מסחרי חיובי",
  en: "Creditor nation"
 },
 {
  ar: "دولة صناعية",
  he: "מדינה מתועשת",
  en: "Industrialized country"
 },
 {
  ar: "دولة متطورة",
  he: "מדינה מפותחת",
  en: "Developed country"
 },
 {
  ar: "دولة مدينة",
  he: "מדינה בעלת חוב\/ מדינה חייבת\/ מדינה בעלת מאזן מסחרי שלילי",
  en: "Debtor nation"
 },
 {
  ar: "دولة مصنعة",
  he: "מדינה מתועשת",
  en: "Industrialized country"
 },
 {
  ar: "دولة نامية",
  he: "מדינה מתפתחת",
  en: "Developing country"
 },
 {
  ar: "ديموقراطية صناعية",
  he: "דמוקרטיה תעשייתית",
  en: "Industrial country"
 },
 {
  ar: "دين ثابت يحكم",
  he: "חוב שבית הדין חייב לשלמו\/ חוב פסוק",
  en: "Judgment country"
 },
 {
  ar: "دين خارجي",
  he: "חוב חיצוני",
  en: "External country"
 },
 {
  ar: "دين داخلي",
  he: "חוב פנימי",
  en: "Internal country"
 },
 {
  ar: "دين دفتري",
  he: "חוב בספרים",
  en: "Book debt"
 },
 {
  ar: "دين غير مضمون",
  he: "חוב מסופק",
  en: "Doubtful debt"
 },
 {
  ar: "دين قومي",
  he: "חוב לאומי",
  en: "National debt"
 },
 {
  ar: "دين قومي داخلي",
  he: "חוב לאומי פנימי",
  en: "Internal national debt"
 },
 {
  ar: "دين مؤجل الدفع",
  he: "אשראי נדחה",
  en: "Deferred credit"
 },
 {
  ar: "دين مستحق",
  he: "חוב שטרם נפרע",
  en: "Outstanding debt"
 },
 {
  ar: "دين مضمون",
  he: "חוב מובטח",
  en: "Secured debt"
 },
 {
  ar: "دين مقسط",
  he: "חוב המוחזר בתשלומים",
  en: "Installment debt"
 },
 {
  ar: "دين مكفول",
  he: "חוב מיוצב",
  en: "Funded debt"
 },
 {
  ar: "دين موحد",
  he: "חוב מאוחד",
  en: "Consolidated debt"
 },
 {
  ar: "ذهب",
  he: "זהב",
  en: "gold"
 },
 
 {
  ar: "راتب",
  he: "משכורת",
  en: "Salary\/ wages"
 },
 {
  ar: "راس مال",
  he: "הון\/ קרן",
  en: "Capital\/ fund"
 },
 {
  ar: "راسمال ",
  he: "הון\/ קרן",
  en: "Capital\/ fund"
 },
 {
  ar: "راسمال اسمي",
  he: "הון מניות רשום",
  en: "Authorized share capital\/ registered share capital"
 },
 {
  ar: "راسمال اولي",
  he: "הון התחלתי",
  en: "Initial capital"
 },
 {
  ar: "راسمال بشري",
  he: "הון אנושי",
  en: "Human capital"
 },
 {
  ar: "راسمال ثابت",
  he: "הון קבוע",
  en: "Fixed capital"
 },
 {
  ar: "راسمال جاهز",
  he: "הון נזיל",
  en: "Fluid capital"
 },
 {
  ar: "راسمال حر",
  he: "הון חופשי",
  en: "Free capital"
 },
 {
  ar: "راسمال ذو اخطار",
  he: "הון סיכון",
  en: "Risk capital\/ venture capital"
 },
 {
  ar: "راسمال سائل",
  he: "הון נזיל",
  en: "Fluid capital"
 },
 {
  ar: "راسمال عاطل",
  he: "הון לא מנוצל",
  en: "Idle capital"
 },
 {
  ar: "راسمال عامل",
  he: "הון חוזר",
  en: "Working capital\/ circulating capital"
 },
 {
  ar: "راسمال لم يطالب بة",
  he: "הון שלא נקרא לתשלום",
  en: "Uncalled capital"
 },
 {
  ar: "راسمال متداول",
  he: "הון חוזר",
  en: "Working capital\/ circulating capital"
 },
 {
  ar: "راسمال مدفوع ",
  he: "הון נפרע",
  en: "Paid- up capital"
 },
 {
  ar: "راسمال مرخص",
  he: "הון מניות רשום",
  en: "Authorized share capital\/ registered share capital"
 },
 {
  ar: "راسمال مستثمر",
  he: "הון מושקע",
  en: "Invested capital"
 },
 {
  ar: "راسمال مصدر",
  he: "הון מונפק",
  en: "Issued capital"
 },
 {
  ar: "راسمال مصرح بة",
  he: "הון רשום",
  en: "Authorized  capital"
 },
 {
  ar: "راسمال موظف",
  he: "הון מועסק",
  en: "Capital employed"
 },
 {
  ar: "راسمال نشط",
  he: "הון פעיל",
  en: "Active capital"
 },
 {
  ar: "راسمال نقدي",
  he: "הון כספי",
  en: "Money capital"
 },
 {
  ar: "راسمال وطني",
  he: "הון לאומי",
  en: "National capital"
 },
 {
  ar: "راسمالية ",
  he: "קפיטליזם",
  en: "capitalism"
 },
 {
  ar: "راهن",
  he: "לוקח משכנתא",
  en: "Mortgagor\/ mortgager"
 },
 {
  ar: "رئيس",
  he: "נשיא",
  en: "president"
 },
 {
  ar: "رئيس اداري",
  he: "מנהל אדמיניסטרטיבי",
  en: "Administrative head"
 },
 {
  ar: "رب العمل",
  he: "מעסיק",
  en: "employer"
 },
 {
  ar: "ربا",
  he: "ריבית",
  en: "Interest"
 },
 {
  ar: 'null',
  he: "ריביצ קצוצה",
  en: "usurious interest"
 },
 {
  ar: "ربح",
  he: "רווח",
  en: "Profit\/ gain revenue\/ income"
 },
 {
  ar: "ربح",
  he: "הכנסה",
  en:"gain revenue"
 },
 {
  ar: "ربح اجمالي",
  he: "רווח גולמי",
  en: "Gross profit"
 },
 {
  ar: "ربح التشغيل",
  he: "רווח תפעולי",
  en: "Operating profit"
 },
 {
  ar: "ربح التصفية",
  he: "דיווידנד פירוק",
  en: "Liquidation dividend"
 },
 {
  ar: "ربح الراسمال",
  he: "רווח הון",
  en: "Capital gain"
 },
 {
  ar: "ربح الصرف",
  he: "פרמיית חילפין",
  en: "Exchange premium"
 },
 {
  ar: "ربح راسمالي",
  he: "רווח הון",
  en: "Capital gain"
 },
 {
  ar: "ربح سهم مؤقت",
  he: "דיווידנד ביניים",
  en: "Interim dividend"
 },
 {
  ar: "ربح صافي",
  he: "רווח נקי",
  en: "Net profit"
 },
 {
  ar: "لربح صوري",
  he: "רווח מדומה",
  en: "Fictitious profit"
 },
 {
  ar: "ربح قانوني",
  he: "דיווידנד שיש לחלקו על פי חוק",
  en: "Statutory dividend"
 },
 {
  ar: "ربح لم يطالب بة",
  he: "דיווידנד שלא נדרש",
  en: "Unclaimed dividend"
 },
 {
  ar: "ربح مامول",
  he: "רווח שלא מומש",
  en: "Paper profit"
 },
 {
  ar: "ربح متوقع",
  he: "רווח חזוי",
  en: "Anticipated profit"
 },
 {
  ar: "ربح محض",
  he: "רווח טהור",
  en: "Pure profit"
 },
 {
  ar: "ربح محقق",
  he: "רווח כתוצאה ממימוש נייר ערך",
  en: "Realized profit"
 },
 {
  ar: "رجل اعمال",
  he: "איש עסקים",
  en: "businessman"
 },
 {
  ar: "رخاء",
  he: "גאות( כלכלית)\/ שגשוג",
  en: "prosperity"
 },
 {
  ar: "رخصة",
  he: "רשיון",
  en: "license"
 },
 {
  ar: "رخصة استيراد",
  he: "רשיון יבוא",
  en: "Import license"
 },
 {
  ar: "رخصة تصدير",
  he: "רשיון יצוא",
  en: "Export license"
 },
 {
  ar: "رد المال",
  he: "החזר הון",
  en: "Capital recovery"
 },
 {
  ar: 'null',
  he: "החזר כספי",
  en: "refund"
 },
 {
  ar: "ردهة المبادلات التجارية",
  he: "זירת המסחר",
  en: "Trading floor"
 },
 {
  ar: "رسم",
  he: "אגרה",
  en: "Fee"
 },
 {
  ar: 'null',
  he: "היטל\/ מס",
  en: "charge\/ duty\/ tax"
 },
 {
  ar: "رسم ارساء",
  he: "דמי עגינה",
  en: "keelage"
 },
 {
  ar: "رسم استيراد",
  he: "מכס",
  en: "Import duty\/ customs\/ import tariff"
 },
 {
  ar: "رسم الجمرك",
  he: "מכס",
  en: "Import duty\/ customs\/ import tariff"
 },
 {
  ar: "رسم الرصيف",
  he: "דמי רציף",
  en: "Quayage\/ wharfage\/ quay handling charges"
 },
 {
  ar: "رسم الطوابع",
  he: "מס בולים",
  en: "Stamp duty"
 },
 {
  ar: "رسم بياني للفعالية",
  he: "תרשים פעילות",
  en: "Activity chart"
 },
 {
  ar: "رسم تاخير",
  he: "דמי השהיה",
  en: "Demurrage free"
 },
 {
  ar: "رسم تسجيل",
  he: "דמי הרשמה",
  en: "Registration fee"
 },
 {
  ar: "رسم جمركي",
  he: "דמי מכס",
  en: "Customs duty"
 },
 {
  ar: "رسم خدمة",
  he: "דמי שירות",
  en: "Service charge"
 },
 {
  ar: "رسم دخول",
  he: "דמי כניסה",
  en: "Admission charge\/ admission fee"
 },
 {
  ar: "رسم مقطوع",
  he: "תשלום קבוע",
  en: "Fixed fee"
 },
 {
  ar: "رسوم جمركية مستعادة",
  he: "הישבון (החזרי כספים)",
  en: "Customs drawback"
 },
 {
  ar: "رشوة",
  he: "שוחד",
  en: "bribe"
 },
 {
  ar: "رصيد",
  he: "יתרה",
  en: "Balance"
 },
 {
  ar: "الوديعه",
  he: "פיקדון",
  en: "deposit"
 },
 {
  ar: "رصيد دائن",
  he: "יתרת זכות",
  en: "Credit balance"
 },
 {
  ar: "رصيد في المصلرف",
  he: "יתרה בבנק",
  en: "Bank balance"
 },
 {
  ar: "رصيد مدين",
  he: "יתרת חובה",
  en: "Debit balance"
 },
 {
  ar: "رفاهية",
  he: "רווח\/ סעד",
  en: "Welfare\/ relief"
 },
 {
  ar: "رفاهية اجتماعية",
  he: "רווחה חברתית",
  en: "Social welfare"
 },
 {
  ar: "رفض الاعتراف بالدين",
  he: "השתמטות מתשלום חוב",
  en: "repudiation"
 },
 {
  ar: "رفع السعر",
  he: "מרווח (שיעור התוספת במחיר)",
  en: "Mark up"
 },
 {
  ar: "رفع قيمة عملة",
  he: "ייסוף מטבע",
  en: "Currency appreciation"
 },
 {
  ar: "رق",
  he: "עבדות",
  en: "slavery"
 },
 {
  ar: "رقابة على الصرف",
  he: "פיקוח על מטבע",
  en: "Exchange control"
 },
 {
  ar: "رقم",
  he: "מספר",
  en: "number"
 },
 {
  ar: "رقم الحساب",
  he: "מספר החשבון",
  en: "Account number"
 },
 {
  ar: "رقم قياسي",
  he: "מדד",
  en: "index"
 },
 {
  ar: "ركود",
  he: "מיתון",
  en: "recession"
 },
 {
  ar: "ركود اقتصادي",
  he: "שפל כלכלי",
  en: "Depression\/ slump"
 },
 {
  ar: "رهن",
  he: "משכנתא",
  en: "mortgage"
 },
 {
  ar: "رهن متحرك",
  he: "שיעבוד צף",
  en: "Floating charge"
 },
 {
  ar: "رواج ",
  he: "סחירות",
  en: "marketability"
 },
 {
  ar: "ريع",
  he: "הכנסה",
  en: "Income\/ revenue"
 },
 
 {
  ar: "زبون",
  he: "לקוח ",
  en: "Customer\/ client"
 },
 {
  ar: "زراعة",
  he: "חקלאות",
  en: "agriculture"
 },
 {
  ar: "زراعي",
  he: "חקלאי",
  en: "agrarian"
 },
 {
  ar: "زكاة",
  he: "צדקה",
  en: "charity"
 },
 {
  ar: "زيادة",
  he: "עלייה",
  en: "increase"
 },
 {
  ar: "زيادة القيمة",
  he: "עליית ערך",
  en: "appreciation"
 },
 {
  ar: "زيت",
  he: "שמן",
  en: "oil"
 },
 {
  ar: "زيف",
  he: "זיוף",
  en: "Forgery\/ fraud"
 },
 
 {
  ar: "ساري المفعول",
  he: "בר תוקף",
  en: "valid"
 },
 {
  ar: "ساعات الدوام",
  he: "שעות העבודה",
  en: "Office hours\/ hours of work"
 },
 {
  ar: "ساعات العمل",
  he: "שעות העבודה",
  en: "Office hours\/ hours of work"
 },
 {
  ar: "ساعة عمل",
  he: "שעת עבודה",
  en: "Working hour"
 },
 {
  ar: "سبيكة",
  he: "מטיל (של זהב, כסף)",
  en: "bullion"
 },
 {
  ar: "سحب",
  he: "משיכה",
  en: "Drawing\/ draft"
 },
 {
  ar: "سحب الاستثمار",
  he: "תצרוכת הון (בחברה)\/ השקעה שלילית",
  en: "disinvestment"
 },
 {
  ar: "سحب على المكشوف",
  he: "משיכת יתר",
  en: "overdraft"
 },
 {
  ar: "سداد",
  he: "סילוק (חשבון)",
  en: "Defrayment\/ clearance"
 },
 {
  ar: "سرقة",
  he: "גניבה",
  en: "theft"
 },
 {
  ar: "سعر",
  he: "שער",
  en: "Rate"
 },
 {
  ar: "سعر",
  he: "מחיר",
  en: "price\/ cost"
 },
 {
  ar: "سعر اداري",
  he: "מחיר שבפיקוח",
  en: "Administered price"
 },
 {
  ar: "سعر ادنى محدد لسلعة مطروحة بالمزاد",
  he: "מחיר מינימאלי במכירה פומבית\/ מחיר פתיחה",
  en: "Upset price\/ first price\/ opening price"
 },
 {
  ar: "سعر اعادة الخصم",
  he: "שער ניכיון המשנה",
  en: "Rediscount rate"
 },
 {
  ar: "سعر الاصدار",
  he: "מחיר הנפקה",
  en: "Issue price"
 },
 {
  ar: "سعر الافتتاح",
  he: "מחיר התחלתי\/ מחיר פתיחה",
  en: "Upset price\/ first price\/ opening price"
 },
 {
  ar: "سعر الاقفال",
  he: "שער סגירה",
  en: "Closing price"
 },
 {
  ar: "سعر البضاعة مع التامين والشحن",
  he: "עלות ביטוח תובלה",
  en: "Cost insurance freight"
 },
 {
  ar: "سعر البيع",
  he: "מחיר מכירה",
  en: "Selling price"
 },
 {
  ar: "سعر التبادل",
  he: "שער חליפין",
  en: "Exchange rate"
 },
 {
  ar: "سعر التحويل",
  he: "שער חליפין",
  en: "Exchange rate"
 },
 {
  ar: "سعر التحويل الرسمي",
  he: "שער חליפין רשמי",
  en: "Official exchange rate"
 },
 {
  ar: "سعر التسليم",
  he: "מחיר מסירה",
  en: "Delivered price"
 },
 {
  ar: "سعر التكلفة مضاف الية ربح معين",
  he: "עלות פלוס (רווח)",
  en: "Cost plus"
 },
 {
  ar: "سعر الخصم",
  he: "שער ניכיון",
  en: "Discount rate"
 },
 {
  ar: "سعر الخصم الادنى لدى المصارف",
  he: "ריבית הפריים\/ שער הריבית הבסיסי ",
  en: "Prime interest rate"
 },
 {
  ar: "سعر الخصم لدى المصارف",
  he: "שער בנקאי\/ שער הריבית הבנקאי",
  en: "Bank rate"
 },
 {
  ar: "سعر السلعة العائد للمنتج",
  he: "עלות גורמי הייצור",
  en: "Factor cost"
 },
 {
  ar: "سعر السوق",
  he: "מחיר השוק",
  en: "Market price"
 },
 {
  ar: "سعر السوق الحرة",
  he: "מחיר הוגן",
  en: "Arm's length price\/ fair price"
 },
 {
  ar: "سعر الشراء",
  he: "מחיר קניה",
  en: "Buying price\/ purchase price"
 },
 {
  ar: "سعر الصرف",
  he: "שער חליפין",
  en: "Exchange rate"
 },
 {
  ar: "سعر الصرف الاجل",
  he: "שער עתידי",
  en: "Forward rate"
 },
 {
  ar: "سعر الطلب",
  he: "מחיר ביקוש",
  en: "Demand rate"
 },
 {
  ar: "سعر العقد",
  he: "מחיר הננקב בחוזה",
  en: "Contract rate"
 },
 {
  ar: "سعر الفائدة",
  he: "שער הריבית",
  en: "Interest rate\/ rate of interest"
 },
 {
  ar: "سعر الفائدة الرئيسي",
  he: "ריבית הפריים\/ שער הריבית הבסיסי",
  en: "Prime interest rate "
 },
 {
  ar: "سعر الفتح",
  he: "מחיר התחלתי\/ מחיר פתיחה",
  en: "Upset price\/ first price\/ opening price"
 },
 {
  ar: "سعر القطع لدى المصارف",
  he: "שער בנקאי\/ שער הריבית הבנקאי",
  en: "Bank rate"
 },
 {
  ar: "سعر المفرق",
  he: "מחיר קמעוני",
  en: "Retail price"
 },
 {
  ar: "سعر الوحدة",
  he: "מחיר ליחידה",
  en: "Unit price"
 },
 {
  ar: "سعر باهظ",
  he: "מחיר מופרז",
  en: "Prohibitive price\/ excessive price "
 },
 {
  ar: "سعر تنافسي",
  he: "מחיר תחרות",
  en: "Competitive price"
 },
 {
  ar: "سعر ثابت",
  he: "שער בסיסי",
  en: "Flat rate\/ basic rate"
 },
 {
  ar: "سعر خارج البورصة",
  he: "מחיר מחוץ לבורסה",
  en: "Street price"
 },
 {
  ar: "سعر صافي",
  he: "מחיר נטו",
  en: "Net price"
 },
 {
  ar: "سعر صرف ثابت",
  he: "שער חליפין קבוע",
  en: "Fixed exchange rate"
 },
 {
  ar: "سعر عائم",
  he: "שער נייד",
  en: "Floating rate"
 },
 {
  ar: "سعر عادل",
  he: "מחיר הוגן",
  en: "Arm's length price\/ fair price "
 },
 {
  ar: "سعر عادي",
  he: "מחיר נורמאלי",
  en: "Normal price"
 },
 {
  ar: "سعر فاحش",
  he: "מחיר מופרז",
  en: "Prohibitive price\/ excessive price"
 },
 {
  ar: "سعر مخفض",
  he: "מחיר מופחת",
  en: "Reduced price"
 },
 {
  ar: "سعر معتدل",
  he: "מחיר סביר",
  en: "Moderate price"
 },
 {
  ar: "سعر معدل",
  he: "שער יציג",
  en: "Representative rate"
 },
 {
  ar: "سعر معروض",
  he: "מחיר מוצע",
  en: "quotation"
 },
 {
  ar: "سعر مفروض",
  he: "מחיר שבפיקוח",
  en: "Administered price"
 },
 {
  ar: "سفتجة",
  he: "שטר חליפין",
  en: "Bill of exchange\/ draft"
 },
 {
  ar: "سقف",
  he: "תקרה",
  en: "ceiling"
 },
 {
  ar: "س ك النقود",
  he: "הטבעת מטבעת",
  en: "minting"
 },
 {
  ar: "سكرتير",
  he: "מזכיר",
  en: "secretary"
 },
 {
  ar: "سلة العملات",
  he: "סל מטבעות",
  en: "Currency basket"
 },
 {
  ar: "سلطة ادارية",
  he: "רשות מנהלית",
  en: "Administrative authority"
 },
 {
  ar: "سلع",
  he: "טובין",
  en: "goods"
 },
 {
  ar: "سلع متكاملة",
  he: "מוצרים משלימים",
  en: "Complementary goods"
 },
 {
  ar: "سلع متنافسة",
  he: "מוצרים תחליפיים",
  en: "Competitive goods\/ substitutes"
 },
 {
  ar: "سلع مستوردة",
  he: "סחורות מיובאות",
  en: "Import goods"
 },
 {
  ar: "سلع مصدرة",
  he: "סחורות מיוצאות",
  en: "Export goods"
 },
 {
  ar: "سلعة",
  he: "מצרך\/ סחורה\/ מוצר",
  en: "Commodity\/ good\/ product"
 },
 {
  ar: "سلعة استهلاكية",
  he: "מוצר צריכה",
  en: "Consumers' good"
 },
 {
  ar: "سلعة اقتصادية",
  he: "מצרך כלכלי",
  en: "Economic good"
 },
 {
  ar: "سلعة اكثر رواجا",
  he: "רב מכר",
  en: "Best seller"
 },
 {
  ar: "سلعة دنيا",
  he: "מוצר נחות",
  en: "Inferior good"
 },
 {
  ar: "سلعة شبة متينة",
  he: "מוצר בר קיימא למחצה",
  en: "Semi durable good"
 },
 {
  ar: "سلعة كمالية",
  he: "מוצר מותרות",
  en: "Luxury article"
 },
 {
  ar: "سلعة متينة",
  he: "מוצר בר קיימא",
  en: "Durable good"
 },
 {
  ar: "سلعة مغرية",
  he: "מוצר פיתוי",
  en: "bait"
 },
 {
  ar: "سلعة منتجة",
  he: "מוצר ייצור",
  en: "Producer's good"
 },
 {
  ar: "سلف",
  he: "הלוואה ללא ריבית",
  en: "Interest free loan"
 },
 {
  ar: "سلفة",
  he: "מקדמה",
  en: "advance"
 },
 {
  ar: "سمسار",
  he: "ברוקר",
  en: "Broker"
 },
 {
  ar: " سمسار ة ",
  he: "ברוקר העוסק במניות\/ סוכן בורסה",
  en: "stock broker"
 },
 {
  ar: "سمسار العقارات",
  he: "סוחר בנדל\"ן",
  en: "Real estate broker"
 },
 {
  ar: "سمسار اوراق مالية",
  he: "ג'ובר",
  en: "Jobber"
 },
 {
  ar: "سمسار اوراق مالية",
  he: "סוחר מניות\/ סוכן מניות",
  en: "stock jobber"
 },

 {
  ar: "سمسار تامين",
  he: "נציג חברת ביטוח",
  en: "Insurance broker"
 },
 {
  ar: "سمسار مرخص لة",
  he: "סוכן מורשה",
  en: "Licensed broker\/ licensed dealer"
 },
 {
  ar: "سمسرة",
  he: "דמי תיווך",
  en: "brokerage"
 },
 {
  ar: "سمعة",
  he: "מוניטין",
  en: "reputation"
 },
 {
  ar: "سن التقاعد",
  he: "גיל פרישה",
  en: "Retirement age"
 },
 {
  ar: "سناهية",
  he: "קצבה שנתית",
  en: "annuity"
 },
 {
  ar: "سنة الاساس",
  he: "שנת בסיס",
  en: "Base year"
 },
 {
  ar: "سنة مالية",
  he: "שנת כספים",
  en: "Financial year\/ fiscal year"
 },
 {
  ar: "سند",
  he: "איגרת חוב",
  en: "Debenture\/ bond"
 },
 {
  ar: "سند",
  he: "נייר ערך",
  en: " security"
 },
 {
  ar: "سند" ,
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "سند اجنبي",
  he: "איגרת חוב זרה",
  en: "Foreign bond"
 },
 {
  ar: "سند اختياري",
  he: "איגרת חוב אופציה",
  en: "Optional bond"
 },
 {
  ar: "سند ادنى",
  he: "שטר חוב",
  en: "Promissory note"
 },
 {
  ar: "سند اسمي",
  he: "נייר ערך על שם",
  en: "Registered security"
 },
 {
  ar: "سند اولي",
  he: "איגרת חוב ראשונית",
  en: "Primary bond"
 },
 {
  ar: "سند بدون فائدة",
  he: "איגרת חוב שאינה נושאת ריבית",
  en: "Non- interest bearing bond"
 },
 {
  ar: "سند بفائدة",
  he: "איגרת חוב נושאת ריבית ",
  en: "Interest bearing bond"
 },
 {
  ar: "سند بلدي",
  he: "איגרת חוב מוניציפאלית",
  en: "Municipal bond"
 },
 {
  ar: "سند بيع",
  he: "שטר מכר",
  en: "Bill of sale"
 },
 {
  ar: "سند حكومي",
  he: "איגרת חוב ממשלתית",
  en: "Government bond"
 },
 {
  ar: "سند حكومي",
  he: "נייר ערך ממשלתי",
  en: "Government security"
 },
 {
  ar: "سند دين",
  he: "איגרת חוב",
  en: "Debenture\/ bond"
 },
 {
  ar: "سند ذو اجل",
  he: "איגרת חוב בלתי צמיתה",
  en: "Tern bond"
 },
 {
  ar: "سند ذو انصبة",
  he: "איגרת חוב נושאת פרסים",
  en: "Premium bond"
 },
 {
  ar: "سند ذوعوائد ثابتة  ",
  he: "נייר ערך בעל תשואה קבועה",
  en: "Fixed yield security"
 },
 {
  ar: "سند شركة",
  he: "איגרת חוב המונפקת על ידי חברה",
  en: "Corporate bond"
 },
 {
  ar: "سند على الخزينة",
  he: "שטר אוצר",
  en: "Treasury bill"
 },
 {
  ar: "سند غير قابل للتداول",
  he: "איגרת חוב בלתי סחירה",
  en: "Non negotiable bond"
 },
 {
  ar: "سند قابل للتحويل",
  he: "איגרת חוב ניתנת להמרה במניות\/ איגרת חוב בת המרה",
  en: "Convertible bond"
 },
 {
  ar: "سند لا ترد قيمتة",
  he: "נייר ערך שאינו בר פדיון",
  en: "Irredeemable security"
 },
 {
  ar: "سند لحاملة",
  he: "נייר ערך למוכ\"ז",
  en: "Dearer security"
 },
 {
  ar: "سند لحاملة بقسائم",
  he: "איגרת חוב נושאת תלושים",
  en: "Coupon bond"
 },
 {
  ar: "سند مالي",
  he: "בטוחה\/ נייר ערך",
  en: "security"
 },
 {
  ar: "سند مستحق",
  he: "שטר שחל זמן פירעונו",
  en: "Due bill"
 },
 {
  ar: "سند مستحق الدفع",
  he: "שטר לפירעון",
  en: "Bill payable"
 },
 {
  ar: "سند مستحق القبض",
  he: "שטר לגבייה",
  en: "Bill receivable"
 },
 {
  ar: "سند مسجل في قائمة البورصة",
  he: "נייר ערך רשום בבורסה",
  en: "Listed security"
 },
 {
  ar: "سند مسوق",
  he: "נייר ערך סחיר",
  en: "Marketable security"
 },
 {
  ar: "سند مشارك",
  he: "איגרת חוב משתתפת",
  en: "Participating bond"
 },
 {
  ar: "سند مصرفي",
  he: "שטר כסף",
  en: "Bank bill\/ paper money\/ currency note"
 },
 {
  ar: "سند مضمون",
  he: "איגרת חוב מובטחת",
  en: "Guaranteed bond\/ secured bond"
 },
 {
  ar: "سند من فئة أ",
  he: "איגרת חוב מסוג א",
  en: "A bond"
 },
 {
  ar: "سند منهج",
  he: "איגרת חוב מווסתת",
  en: "Managed bond"
 },
 {
  ar: "سنديك",
  he: "כונס נכסים",
  en: "Official receiver"
 },
 {
  ar: "سهم",
  he: "מניה",
  en: "Stock\/ share"
 },
 {
  ar: "سهم الدرجة الاولى",
  he: "מניה בטוחה",
  en: "Bluechip share"
 },
 {
  ar: "سهم بلا قيمة اسمية",
  he: "מניה ללא ערך נקוב",
  en: "No-par share"
 },
 {
  ar: "سهم تصويت",
  he: "מניה בעלת זכות הצבעה",
  en: "Voting share"
 },
 {
  ar: "سهم ربح",
  he: "מניית הטבה",
  en: "Bonus share"
 },
 {
  ar: "سهم عادي",
  he: "מניה רגילה",
  en: "Ordinary share\/ common share"
 },
 {
  ar: "سهم علاوة",
  he: "מניית הטבה",
  en: "Bonus share"
 },
 {
  ar: "سهم غير تصويتي",
  he: "מניה ללא זכות הצבעה",
  en: "Non-voting share"
 },
 {
  ar: "سهم غير مسجل في البورصة",
  he: "מניה שאינה רשומה למסחר בבורסה",
  en: "Unlisted share"
 },
 {
  ar: "سهم غير مصدر",
  he: "מניה שלא הונפקה",
  en: "Unissued share"
 },
 {
  ar: "سهم قائد",
  he: "מניה מובילה",
  en: "Leading share"
 },
 {
  ar: "سهم قابل للاسترداد",
  he: "מניה בת פדיון",
  en: "Redeemable share"
 },
 {
  ar: "سهم قابل للتحويل",
  he: "מניה בת המרה",
  en: "Convertible share"
 },
 {
  ar: "سهم مؤجل",
  he: "מניה נדחית ",
  en: "Deferred share"
 },
 {
  ar: "سهم متعدد الاصوات",
  he: "מניה בעלת זכות הצבעה כפולה",
  en: "Multiple voting share"
 },
 {
  ar: "سهم مدرج",
  he: "מניה רשומה",
  en: "Quoted share\/ authorized share"
 },
 {
  ar: "سهم مدفوع القيمة جزئيا",
  he: "מניה שנפרעה בחלקה",
  en: "Part paid share"
 },
 {
  ar: "سهم مسترد",
  he: "מניה המוחזקת על ידי החברה המנפיקה",
  en: "Treasury share"
 },
 {
  ar: "سهم مسجل في البورصة",
  he: "מניה רשומה למסחר בבורסה",
  en: "Listed share"
 },
 {
  ar: "سهم مسدد القيمة",
  he: "מניה נפרעת במלואה",
  en: "Paid up share"
 },
 {
  ar: "سهم مسقط",
  he: "מניה חלוטה",
  en: "Forfeited share "
 },
 {
  ar: "سهم مصرح بة",
  he: "מניה רשומה",
  en: "Quoted share\/ authorized share"
 },
 {
  ar: "سهم مطروح",
  he: "מניה המוחזקת למטרת ספקולציה",
  en: "Floating share"
 },
 {
  ar: "سهم مكافاة",
  he: "מניית הטבה",
  en: "Bonus share"
 },
 {
  ar: "سهم ممتاز",
  he: "מניית בכורה",
  en: "Preference share"
 },
 {
  ar: "سهم ممتاز غير متراكم الارباح",
  he: "מניית בכורה שאינה צוברת רווחים",
  en: "Non cumulative preferred share"
 },
 {
  ar: "سهم ممتاز محدود الارباح",
  he: "מנית בכורה בלי משתתפת",
  en: "Non participating preferred share"
 },
 {
  ar: "سهم منحة",
  he: "מניית הטבה",
  en: "Bonus share"
 },
 {
  ar: "سهم نصفي",
  he: "מניה בערך נקוב של חמישים דולר",
  en: "Half stock"
 },
 {
  ar: "سوق",
  he: "שוק",
  en: "market"
 },
 {
  ar: "سوق الاسهم",
  he: "שוק המניות",
  en: "Stock market"
 },
 {
  ar: "سوق الاوراق المالية",
  he: "שוק ניירות הערך",
  en: "Stock market"
 },
 {
  ar: "سوق الاوراق المالية الجديدة",
  he: "שוק ההנפקות המקורי\/ שוק ההנפקות הראשון\/ שוק ראשוני לניירות ערך",
  en: "New issue market"
 },
 {
  ar: "السوق الاوروبية المشتركة",
  he: "השוק האירופי המשותף",
  en: "European common market"
 },
 {
  ar: "السوق الحرة",
  he: "דיוטי פרי\/ חנות פטורה ממכס",
  en: "Duty free (shop) "
 },
 {
  ar: "سوق الخفاء",
  he: "מסחר בניירות ערך שאינם רשומים בבורסה",
  en: "Crub market\/ overs accounter"
 },
 {
  ar: "سوق الراسمال",
  he: "שוק הון",
  en: "Capital market"
 },
 {
  ar: "سوق الرساميل",
  he: "שוק הון",
  en: "Capital market"
 },
 {
  ar: "سوق السلع",
  he: "שוק הסחורות",
  en: "Commodity market"
 },
 {
  ar: "سوق الصفقات الانية",
  he: "שור שבו המסירה מיידית",
  en: "Spot market"
 },
 {
  ar: "سوق الصفقات الفورية",
  he: "שוק שבו המסירה מיידית",
  en: "Spot market"
 },
 {
  ar: "سوق العمل",
  he: "שוק העבודה",
  en: "Labour market\/ work market"
 },
 {
  ar: "سوق القروض",
  he: "שוק ההלוואות",
  en: "Loan market"
 },
 {
  ar: "سوق القطع الاجلة",
  he: "שוק לסחורות עתידיות",
  en: "Forward exchange market"
 },
 {
  ar: "سوق النفط",
  he: "שוק הנפט",
  en: "Oil market"
 },
 {
  ar: "سوق ثانوية",
  he: "שוק משני לניירות ערך",
  en: "Secondary market"
 },
 {
  ar: "سوق حرة",
  he: "שוק חופשי",
  en: "Free market\/ open market"
 },
 {
  ar: "سوق سوداء",
  he: "שוק שחור",
  en: "Black market"
 },
 {
  ar: "سوق غير كاملة",
  he: "שוק בלתי משוכלל",
  en: "Imperfect market"
 },
 {
  ar: "سوق كاملة",
  he: "שוק משוכלל",
  en: "Perfect market"
 },
 {
  ar: "سوق مالية",
  he: "שוק כספים",
  en: "Financial market\/ money market"
 },
 {
  ar: "سوق مشتركة",
  he: "שוק משותף",
  en: "Common market"
 },
 {
  ar: "سوق نشطة",
  he: "שוק פעיל",
  en: "Active market"
 },
 {
  ar: "سوق نقدية",
  he: "שוק מוניטארי",
  en: "Monetary market"
 },
 {
  ar: "سوق يورو",
  he: "שור יורו",
  en: "Euromarket"
 },
 {
  ar: "سياحة",
  he: "תיירות",
  en: "tourism"
 },
 {
  ar: "سياسة اقتصادية",
  he: "מדיניות כלכלית",
  en: "Economic policy"
 },
 {
  ar: "سياسة الاسعار",
  he: "מדיניות מחירים",
  en: "Price policy"
 },
 {
  ar: "سياسة تحريك الاقتصاد",
  he: "מדיניות של מתן עבודות יוזמה לשם האצת הפעילות הכלכלית",
  en: "Pump policy"
 },
 {
  ar: "سياسة دفع ارباح الاسهم",
  he: "מדיניות דיווידנד",
  en: "Dividend policy"
 },
 {
  ar: "سياسة سعرية",
  he: "מדיניות מחירים",
  en: "Price policy"
 },
 {
  ar: "سياسة ضريبية",
  he: "מדיניות פיסקאלית",
  en: "Fiscal policy"
 },
 {
  ar: "سياسة مالية",
  he: "מדיניות פיסקאלית",
  en: "Fiscal policy"
 },
 {
  ar: "سياسة نقدية",
  he: "מדיניות מוניטארית",
  en: "Monetary policy"
 },
 {
  ar: "سيولة",
  he: "נזילות",
  en: "liquidity"
 },
 {
  ar: "سيولة زائدة",
  he: "נזילות עודפת",
  en: "Over liquidity"
 },
 
 {
  ar: "شاحن",
  he: "משגר (שולח סחורה)",
  en: "Consigner"
 },
 {
  ar: "شاحن" ,
  he: "שולח",
  en: "dispatcher"
 },
 {
  ar: "شبة احتكار",
  he: "מעין מונופול",
  en: "Quasi- monopoly"
 },
 {
  ar: "شبة الربح",
  he: "מעין רנטה",
  en: "Quasi- rent"
 },
 {
  ar: "شبة نقود",
  he: "נכס נזיל",
  en: "Near money\/ liquid asset"
 },
 {
  ar: "شح",
  he: "קמצנות",
  en: "stinginess"
 },
 {
  ar: "شحن",
  he: "משלוח",
  en: "Shipment\/ delivery"
 },
 {
  ar: "شحن",
  he: "מטען",
  en: "lading\/ cargo\/ freight"
 },
 {
  ar: "شحن جوي",
  he: "שליחה בדרך האוויר",
  en: "Air freight"
 },
 {
  ar: "شحن فوري",
  he: "משלוח מידי",
  en: "Prompt shipment"
 },
 {
  ar: "شراء",
  he: "רכישה\/ קנייה",
  en: "Purchase\/ buying"
 },
 {
  ar: "شراء بالتقسيط",
  he: "קנייה בתשלומים",
  en: "Installment purchase\/ "
 },
 {
  ar: "شراء بالتقسيط",
  he: "קנייה בתשלומים",
  en: "installment buying"
 },
 {
  ar: "شراء بالتقسيط",
  he: "ליסינג\/ שכר- מכר",
  en: "leasing"
 },
 {
  ar: "شراء ثانية",
  he: "קנייה בחזרה",
  en: "repurchase"
 },
 {
  ar: "شراء متبادل",
  he: "קנייה גומלין",
  en: "Reciprocal buying"
 },
 {
  ar: "شراء متكافئ",
  he: "רכישה נגדית",
  en: "Counter purchase"
 },
 {
  ar: "شرط",
  he: "תנאי",
  en: "condition"
 },
 {
  ar: "شركة",
  he: "חברה",
  en: "Company\/ corporation"
 },
 {
  ar: "شركة",
  he: "פירמה",
  en: "firm"
 },
 {
  ar: "شركة ائتمانية",
  he: "חברת נאמנות\/ חברה לנאמנות",
  en: "Trust company"
 },
 {
  ar: "شركة اجنبية",
  he: "חברה זרה",
  en: "Foreign corporation"
 },
 {
  ar: "شركة استثمار",
  he: "חברת השקעות\/ חברה להשקעות",
  en: "Investment trust\/ investment company"
 },
 {
  ar: "شركة استثمار مغلقة الراسمال",
  he: "חברת השקעות סגורה",
  en: "Closed end investment company"
 },
 {
  ar: "شركة اشخاص",
  he: "שותפות",
  en: "partnership"
 },
 {
  ar: "شركة ام",
  he: "חברת אם",
  en: "Parent company"
 },
 {
  ar: "شركة بسيطة",
  he: "שותפות",
  en: "partnership"
 },
 {
  ar: "شركة بسيطة احد اعضاؤها على الاقل خفي",
  he: "שותפות שלפחות אחד מחבריה סמוי",
  en: "Secret partnership"
 },
 {
  ar: "شركة بسيطة عامة",
  he: "שותפות כללית",
  en: "General partnership"
 },
 {
  ar: "شركة تابعة",
  he: "חברת בת",
  en: "subsidiary"
 },
 {
  ar: "شركة تامين",
  he: "חברת ביטוח",
  en: "Insurance company"
 },
 {
  ar: "شركة تحصيل ديون تجارية",
  he: "חרה לגבייה",
  en: "Factoring company"
 },
 {
  ar: "شركة تضامن",
  he: "שותפות",
  en: "partnership"
 },
 {
  ar: "شركة توصية بسيطة",
  he: "שותפות מוגבלת",
  en: "Limited partnership"
 },
 {
  ar: "شركة حكومية",
  he: "חברה ממשלתית",
  en: "Government corporation"
 },
 {
  ar: "شركة خاصة",
  he: "חברה פרטית",
  en: "Private company"
 },
 {
  ar: "شركة ذات مسؤولية محدودة",
  he: "חברה בעירבון מוגבל",
  en: "Limited company"
 },
 {
  ar: "شركة زميلة",
  he: "חברה קשורה",
  en: "Affiliated company"
 },
 {
  ar: "شركة شحن",
  he: "חברת הובלות",
  en: "Carloading company"
 },
 {
  ar: "شركة عامة",
  he: "חברה ציבורית",
  en: "Public company"
 },
 {
  ar: "شركة عبر الشاطئ",
  he: "חברה מעבר לחוף (חברה המהווה מקלט מס)",
  en: "Off shore company"
 },
 {
  ar: "شركة غير مساهمة",
  he: "חברה שאינה חברת מניות",
  en: "Non shore company"
 },
 {
  ar: "شركة قابضة",
  he: "חברת החזקות",
  en: "Holding company"
 },
 {
  ar: "شركة متعددة القوميات",
  he: "חברה רב לאומית",
  en: "International company\/ multinational company"
 },
 {
  ar: "شركة محاصة",
  he: "חברת מניות בעירבון מוגבל",
  en: "Joint stock company"
 },
 {
  ar: "شركة محلية",
  he: "חברה מקומית",
  en: "Local company"
 },
 {
  ar: "شركة مساهمة",
  he: "חברת מניות בעירבון מוגבל",
  en: "Joint stock company"
 },
 {
  ar: "شركة مسجلة",
  he: "חברה רשומה",
  en: "Registered company"
 },
 {
  ar: "شركة منتسبة",
  he: "חברה קשורה",
  en: "Affiliated company"
 },
 {
  ar: "شركة منحلة",
  he: "חברה מחוקה",
  en: "Defunct company"
 },
 {
  ar: "شركة نفط",
  he: "חברת נפט",
  en: "Oil company"
 },
 {
  ar: "شروط التسليف",
  he: "תנאי אשראי",
  en: "Credit terms"
 },
 {
  ar: "شروط التسليم",
  he: "תנאי המסירה",
  en: "Terms of delivery"
 },
 {
  ar: "شروط الدفع",
  he: "תנאי התשלום",
  en: "Terms of payment"
 },
 {
  ar: "شروط العقد",
  he: "תנאי החוזה",
  en: "Terms of contract"
 },
 {
  ar: "شروط مؤاتية",
  he: "תנאים נאותים",
  en: "Favourable terms"
 },
 {
  ar: "شريك",
  he: "שותף",
  en: "partner"
 },
 {
  ar: "شريك اصغر",
  he: "שותף זוטר",
  en: "Junior partner"
 },
 {
  ar: "شريك خفي",
  he: "שותף סמוי",
  en: "Latent partner\/ secret partner "
 },
 {
  ar: "شريك عامل",
  he: "שותף פעיל",
  en: "Acting partner\/ active partner"
 },
 {
  ar: "شريك متضامن",
  he: "שותף פעיל",
  en: "Acting partner\/ active partner"
 },
 {
  ar: "شريك موحى",
  he: "שותף סמוי",
  en: "Latent partner\/ secret partner"
 },
 {
  ar: "شريك نشط",
  he: "שותף פעיל",
  en: "Acting partner\/ active partner"
 },
 {
  ar: "شطب",
  he: "מחיקה",
  en: "Write off"
 },
 {
  ar: "شغل",
  he: "עבודה",
  en: "Work\/ labour\/ job"
 },
 {
  ar: "شهادة",
  he: "תעודה",
  en: "Certificate\/ scrip\/ note"
 },
 {
  ar: "شهادة اسهم",
  he: "תעודת בעלות על מניה",
  en: "Share certificate"
 },
 {
  ar: "شهادة ايداع",
  he: "תעודת פיקדון",
  en: "Certificate of deposit"
 },
 {
  ar: "شهادة تاسيس الشركة",
  he: "תעודת רישום",
  en: "Certificate of incorporation"
 },
 {
  ar: "شهادة تامين",
  he: "תעודת ביטוח",
  en: "Certificate of insurance"
 },
 {
  ar: "شهادة تقدير الاضرار",
  he: "תעודת הערכת נזק",
  en: "Certificate of damage"
 },
 {
  ar: "شهادة منشأ",
  he: "תעודת מקור",
  en: "Certificate of origin"
 },
 {
  ar: "شهرة",
  he: "מוניטין",
  en: "reputation"
 },
 {
  ar: "شيك",
  he: "שיק\/ המחאה",
  en: "Check\/ cheque\/ draft"
 },
 {
  ar: "شيك زائف",
  he: "שיק מזויף",
  en: "False cheque"
 },
 {
  ar: "شيك سياحي",
  he: "המחאת נוסעים",
  en: "Traveller's cheque"
 },
 {
  ar: "شيك على بياض",
  he: "שיק בלנקו",
  en: "Blank cheque"
 },
 {
  ar: "شيك للمستفيد",
  he: "שיק למוטב",
  en: "Payee cheque"
 },
 {
  ar: "شيك متقادم",
  he: "שיק שפג תוקפו",
  en: "Stale cheque"
 },
 {
  ar: "شيك مردود",
  he: "שיק ללא כיסוי",
  en: "Dishonoured cheque\/ rubber cheque"
 },
 {
  ar: "شيك مرفوض",
  he: "שיק לא כיסוי",
  en: "Dishonoured cheque\/ rubber cheque"
 },
 {
  ar: "شيك مسطر",
  he: "שיק מסורטט",
  en: "Crossed cheque"
 },
 {
  ar: "شيك مصرفي",
  he: "שיק בנקאי",
  en: "Banker's draft\/ bank cheque "
 },
 {
  ar: "شيك معلق",
  he: "שיק שטרם נפרע",
  en: "Outstanding cheque"
 },
 {
  ar: "شيك مفتوح",
  he: "שיק פתוח",
  en: "Open cheque"
 },
 {
  ar: "شيك مقابل",
  he: "שיק נגדי",
  en: "Counter cheque"
 },
 {
  ar: "شيك ملغى",
  he: "שיק מבוטל",
  en: "Cancelled cheque"
 },
 {
  ar: "شيك يحمل تاريخا لاحقا",
  he: "שיק דחוי",
  en: "Post dated cheque"
 },
 
 {
  ar: "صاحب الدخل",
  he: "בעל הכנסה (שאינה מעבודה) ",
  en: "rentier"
 },
 {
  ar: "صاحب عمل",
  he: "מעסיק",
  en: "employer"
 },
 {
  ar: "صاحب مصرف",
  he: "בנקאי",
  en: "banker"
 },
 {
  ar: "صادرات",
  he: "סחורות יצוא",
  en: "export"
 },
 {
  ar: "صافي",
  he: "נטו\/ נקי",
  en: "net"
 },
 {
  ar: "صافي الاستثمار",
  he: "השקעה נקייה",
  en: "Net investment"
 },
 {
  ar: "صافي القيمة الحالية",
  he: "ערך נוכח נקי",
  en: "Net present investment"
 },
 {
  ar: "صافي الناتج القومي",
  he: "תוצר לאומי נקי",
  en: "Net national product"
 },
 {
  ar: "صراف",
  he: "גזבר\/ קופאי",
  en: "Burser\/ treasurer\/ cashier"
 },
 {
  ar:  "صراف",
  he: "שלם",
  en: "paymaster\/ payclerk\/ disburser"
 },
 {
  ar: "صراف",
  he: "שלם",
  en: "changer\/ money changer"
 },
 {
  ar:  "صراف",
  he: "חלפן כספים",
  en: "teller"
 },
 {
  ar: "صراف",
  he: "טלר",
  en: "teller"
 },
 {
  ar: "صرف",
  he: "פיטורים",
  en: "Dismissal"
 },
 {
  ar: "صرف",
  he: "תשלום",
  en: "payment"
 },
 {
  ar: "صفقات متكافئة",
  he: "סחר נגדי",
  en: "Counter trade"
 },
 {
  ar: "صفقة",
  he: "עסקה\/ מציאה",
  en: "Transaction\/ bargain"
 },
 {
  ar: "صك",
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "صك تحويل الملكية",
  he: "שטר העברת מניות",
  en: "Transfer deed"
 },
 {
  ar: "صناعة",
  he: "תעשייה",
  en: "industry"
 },
 {
  ar: "صناعة ثقيلة",
  he: "תעשייה כבדה",
  en: "Heavy industry"
 },
 {
  ar: "صناعي",
  he: "תעשיין",
  en: "Industrialist"
 },
 {
  ar: "صناعي",
  he: "תעשייתי",
  en: "industrial"
 },
 {
  ar: "صندوق",
  he: "קרן",
  en: "fund"
 },
 {
  ar: "صندوق ائتمان",
  he: "קרן נאמנות",
  en: "Mutual fund\/ unit trust"
 },
 {
  ar: "صندوق الاحتياطي",
  he: "קופת גמל",
  en: "Provident fund"
 },
 {
  ar: "صندوق الطوارئ",
  he: "קופת גמל",
  en: "Provident fund"
 },
 {
  ar: "صندوق النقد الدولي",
  he: "קרן המטבע הבינלאומית",
  en: "International monetary fund"
 },
 {
  ar: "صندوق النقد العربي",
  he: "קרן המטבע הערבית",
  en: "Arab monetary fund"
 },
 {
  ar: "صندوق توفير",
  he: "קרן חיסכון",
  en: "Saving fund"
 },
 {
  ar: "صندوق عام",
  he: "קרן ציבורית",
  en: "Public fund"
 },
 {
  ar: "صندوق معاشات التقاعد",
  he: "קרן פנסיה",
  en: "Pension fund"
 },
 {
  ar: "صهريجية",
  he: "מכלית נפט",
  en: "tanker"
 },
 {
  ar: "صيانة",
  he: "תחזוקה",
  en: "maintenance"
 },
 {
  ar: "صيرفة",
  he: "בנקאות",
  en: "Banking"
 },
 {
  ar: "صيرفة",
  he: "חלפנות",
  en: "money changing"
 },
 {
  ar: "صيرفة متعددة",
  he: "בנקאות כפולה",
  en: "Multiple banking"
 },
 
 {
  ar: "ضامن السندات",
  he: "חתם",
  en: "underwriter"
 },
 {
  ar: "ضبط",
  he: "ויסות",
  en: "Regulation\/ control"
 },
 {
  ar: "ضد جميع الاخطار",
  he: "כל הסיכונים",
  en: "Against all risks"
 },
 {
  ar: "ضرر",
  he: "נזק",
  en: "damage"
 },
 {
  ar: "ضريبة",
  he: "מס",
  en: "Tax\/ duty"
 },
 {
  ar: "ضريبة ارباح الشركات",
  he: "מס חברות",
  en: "Corporate tax\/ company tax\/ corporation tax"
 },
 {
  ar: "ضريبة اضافية",
  he: "מס נוסף",
  en: "surtax"
 },
 {
  ar: "ضريبة اضافية على الواردات",
  he: "היטל על יבוא",
  en: "Import surcharge"
 },
 {
  ar: "ضريبة الارث",
  he: "מס יורשה\/ מס עיזבון",
  en: "Inheritance tax\/ succession tax"
 },
 {
  ar: "ضريبة الاعناق",
  he: "מס גולגולת",
  en: "Head tax\/ poll tax"
 },
 {
  ar: "ضريبة الدخل",
  he: "מס הכנסה",
  en: "Income tax"
 },
 {
  ar: "ضريبة الراس",
  he: "מס גולגולת",
  en: "Head tax\/ poll tax"
 },
 {
  ar: "ضريبة الربح",
  he: "מס על דיווידנד",
  en: "Dividend tax"
 },
 {
  ar: "ضريبة الربح الراسمالي",
  he: "מס רווחי הון",
  en: "Capital gains tax"
 },
 {
  ar: "ضريبة الشركات",
  he: "מס חברות",
  en: "Corporate tax\/ company tax\/ corporation tax"
 },
 {
  ar: "ضريبة العقار",
  he: "מס רכוש",
  en: "Property tax"
 },
 {
  ar: "ضريبة القيمة المضافة",
  he: "מס ערך מוסף",
  en: "Value added tax"
 },
 {
  ar: "ضريبة الملاهي",
  he: "היטל עינוגים",
  en: "Entertainment duty"
 },
 {
  ar: "ضريبة الممتلكات",
  he: "מס רכוש",
  en: "Property tax"
 },
 {
  ar: "ضريبة انتاج",
  he: "בלו",
  en: "Excise tax"
 },
 {
  ar: "ضريبة ايلولة الميراث",
  he: "מס יורשה\/ מס עיזבון",
  en: "Inheritance tax\/ succession tax"
 },
 {
  ar: "ضريبة بلدية",
  he: "ארנונה",
  en: "Municipal tax"
 },
 {
  ar: "ضريبة تصاعدية",
  he: "מס פרוגרסיבי",
  en: "Progressive tax"
 },
 {
  ar: "ضريبة تفاضلية",
  he: "מס מפלה",
  en: "Discriminating tax"
 },
 {
  ar: "ضريبة تمييزية",
  he: "מס מפלה",
  en: "Discriminating tax"
 },
 {
  ar: "ضريبة تنازلية",
  he: "מס רגרסיבי",
  en: "Regressive tax"
 },
 {
  ar: "ضريبة جملة المبيعات",
  he: "מס מחזור",
  en: "Turnover tax"
 },
 {
  ar: "ضريبة حركة راس المال",
  he: "מס מחזור",
  en: "Turnover tax"
 },
 {
  ar: "ضريبة حماية جمركية",
  he: "מס מגן",
  en: "Protective duty"
 },
 {
  ar: "ضريبة خفية",
  he: "מס סמוי",
  en: "Hidden tax"
 },
 {
  ar: "ضريبة رقم الاعمال",
  he: "מס מחזור",
  en: "Turnover tax"
 },
 {
  ar: "ضريبة شراء",
  he: "מס קנייה",
  en: "Purchase tax "
 },
 {
  ar: "ضريبة عقارية",
  he: "מס רווחי הון על נדל\"ן",
  en: "Real estate tax"
 },
 {
  ar: "ضريبة على اساس القيمة",
  he: "מס על פי ערך",
  en: "Ad valorem tax"
 },
 {
  ar: "ضريبة عند المنبع",
  he: "מס במקור",
  en: "Tax at the source"
 },
 {
  ar: "ضريبة غير مباشرة",
  he: "מס עקיף",
  en: "Indirect tax"
 },
 {
  ar: "ضريبة مباشرة",
  he: "מס ישיר",
  en: "Direct tax"
 },
 {
  ar: "ضريبة متدرجة",
  he: "מס מדורג",
  en: "Graduated tax"
 },
 {
  ar: "ضريبة موزعة",
  he: "מס קצוב",
  en: "Apportioned tax"
 },
 {
  ar: "ضريبة نسبية",
  he: "מס יחסי",
  en: "Proportional tax"
 },
 {
  ar: "ضريبة نوعية",
  he: "מס ייחודי",
  en: "Specific tax"
 },
 {
  ar: "ضمان",
  he: "אחריות\/ ערבות",
  en: "guarantee"
 },
 {
  ar: "ضمان اجتماعي",
  he: "ביטוח סוציאלי",
  en: "Social insurance"
 },
 {
  ar: "ضمان شخصي",
  he: "ערבות אישית",
  en: "Personal guarantee"
 },
 {
  ar: "ضمان عطاء",
  he: "ערבות מכרז",
  en: "Bid bond\/ tender bond"
 },
 {
  ar: "ضمان مصرفي",
  he: "ערבות בנקאית",
  en: "Bank guarantee"
 },
 {
  ar: "ضمانات",
  he: "ביטחונות",
  en: "securities"
 },
 
 {
  ar: "طابع ايراد",
  he: "בול הכנסה",
  en: "Revenue stamp"
 },
 {
  ar: "طابع تجاري",
  he: "בול מסחרי",
  en: "Trading stamp"
 },
 {
  ar: "طابع تشجيع المبيعات",
  he: "בול מסחרי",
  en: "Trading stamp"
 },
 {
  ar: "طاقة",
  he: "אנרגיה",
  en: "energy"
 },
 {
  ar: "الطبقة العاملة",
  he: "מעמד הפועלים",
  en: "Working class"
 },
 {
  ar: "طبقة عليا",
  he: "מעמד עליון",
  en: "Lower class"
 },
 {
  ar: "طبقة دنيا",
  he: "מעמד תחתון",
  en: "Upper class"
 },
 {
  ar: "طبقة متوسطة",
  he: "מעמד בינוני",
  en: "Middle class"
 },
 {
  ar: "طبقة وسطى",
  he: "מעמד בינוני",
  en: "Middle class"
 },
 {
  ar: "طرد المستاجر",
  he: "נישול",
  en: "eviction"
 },
 {
  ar: "طريقة الصنع",
  he: "תהליך ייצור",
  en: "Manufacturing process"
 },
 {
  ar: "طلب",
  he: "ביקוש",
  en: "Demand"
 },
 {
  ar:  "طلب",
  he: "המנה",
  en: "order"
 },
 {
  ar: "طلب اجمالي",
  he: "ביקוש מצרפי",
  en: "Aggregate demand\/ composite demand"
 },
 {
  ar: "طلب اليد العاملة",
  he: "ביקוש לעבודה",
  en: "Demand for labour"
 },
 {
  ar: "طلب بريد",
  he: "קנייה באמצעות הדואר",
  en: "Mail order"
 },
 {
  ar: "طلب تكاملي",
  he: "ביקוש משלים",
  en: "Complementary demand"
 },
 {
  ar: "طلب خفيف",
  he: "הוראה למסחר בשברים",
  en: "Odd lot order"
 },
 {
  ar: "طلب فائض",
  he: "עודף ביקוש",
  en: "Excess demand"
 },
 {
  ar: "طلب مترابط",
  he: "ביקוש משולב",
  en: "Joint demand"
 },
 {
  ar: "طلب مركب",
  he: "ביקוש מצרפי",
  en: "Aggregate demand\/ composite demand"
 },
 {
  ar: "طلب مستقر",
  he: "הזמנה עומדת\/ הזמנה קבועה",
  en: "Standing order"
 },
 {
  ar: "طلب يحدد سعر بيع او شراء السهم",
  he: "הוראה בהגבלת שער",
  en: "Limit order"
 },
 
 {
  ar: "ظروف العمل",
  he: "תנאי עבודה",
  en: "Limit order"
 },
 
 {
  ar: "عائد",
  he: "תשואה",
  en: "Yield\/ return"
 },
 {
  ar: "عائد اجمالي للاسهم المستهلكة",
  he: "תשואה שנתית לפדיון",
  en: "Yield to maturity\/ redemption yield"
 },
 {
  ar: "عائد الاستثمار",
  he: "תשואה להשקעה ",
  en: "Return on investment"
 },
 {
  ar: "عائد راس المال",
  he: "תשואה להון",
  en: "Return to capital"
 },
 {
  ar: "عائد مقياس",
  he: "תשואה לגודל",
  en: "Return to scale"
 },
 {
  ar: "عارض",
  he: "מציע מחיר במכרז\/ מציע מחיר במכירה פומבית",
  en: "bidder"
 },
 {
  ar: "عاطل عن العمل",
  he: "מובטל",
  en: "Jobless\/ unemployed"
 },
 {
  ar: "العالم الثالث",
  he: "העולם השלישי",
  en: "Third world"
 },
 {
  ar: "عام الميزانية",
  he: "שנת תקציב",
  en: "Budgetary year"
 },
 {
  ar: "عامل",
  he: "עובד\/ פועל",
  en: "Employee\/ worker"
 },
 {
  ar: "عامل ابيض الياقة",
  he: "עובד הצווארון הלבן",
  en: "White collar worker"
 },
 {
  ar: "عامل ازرق الياقة",
  he: "עובד הצווארון הכחול",
  en: "Blue collar worker"
 },
 {
  ar: "عبء الضريبة",
  he: "תחולת מס",
  en: "Tax incidence"
 },
 {
  ar: "عبودية",
  he: "עבדות",
  en: "slavery"
 },
 {
  ar: "عجز",
  he: "גירעון",
  en: "deficit"
 },
 {
  ar: "عدد",
  he: "מספר",
  en: "number"
 },
 {
  ar: "عديم القيمة",
  he: "חסר ערך",
  en: "worthless"
 },
 {
  ar: "عربون",
  he: "עירבון",
  en: "deposit"
 },
 {
  ar: "عرض",
  he: "היצע",
  en: "Supply"
 },
 {
  ar: "عرض",
  he: "הצעה",
  en: "offer"
 },
 {
  ar: "عرض",
  he: "הצעת מחיר במכרז",
  en: "bid"
 },
 {
  ar: "عرض بخس",
  he: "הצעה נמוכה מההערכה",
  en: "underbid"
 },
 {
  ar: "عرض سعر",
  he: "הצעת מחיר",
  en: "quotation"
 },
 {
  ar: "عرض سعر اقل",
  he: "הצעה נמוכה מההערכה",
  en: "underbid"
 },
 {
  ar: "عرض فائض",
  he: "עודף היצע",
  en: "Excess supply"
 },
 {
  ar: "عرض متلازم",
  he: "היצע משולב",
  en: "Joint supply"
 },
 {
  ar: "عرض مقابل",
  he: "הצעה נגדית",
  en: "Counter offer"
 },
 {
  ar: "العرض والطلب",
  he: "היצע וביקוש",
  en: "Supply and demand"
 },
 {
  ar: "عطاء",
  he: "הצעת מחיר במכרז",
  en: "bid"
 },
 {
  ar: "عطاء بالظرف المختوم",
  he: "מכרז חתום",
  en: "Sealed bid"
 },
 {
  ar: "عطلة",
  he: "חופשה",
  en: "Holiday\/ leave"
 },
 {
  ar: "عطلة البنوك",
  he: "בנק הולידיי (חופשה שבה הבנקים סגורים)",
  en: "Bank holiday"
 },
 {
  ar: "عقد",
  he: "חוזה",
  en: "contract"
 },
 {
  ar: "عقد الشراكة",
  he: "הסכם שותפות",
  en: "Deed of partnership"
 },
 {
  ar: "عقد بيع",
  he: "חוזה מכירה",
  en: "Sale contract"
 },
 {
  ar: "عقد خيار",
  he: "חוזה אופציה",
  en: "Option contract"
 },
 {
  ar: "عقد عمل ",
  he: "חוזה עבודה",
  en: "Labour contract"
 },
 {
  ar: "عقد غير مشروع",
  he: "חוזה לא חוקי",
  en: "Illegal contract"
 },
 {
  ar: "عقد قابل للتنفيذ",
  he: "חוזה בר ביצוע",
  en: "Executory contract"
 },
 {
  ar: "عقد مشروط",
  he: "חוזה מותנה",
  en: "Conditional contract"
 },
 {
  ar: "عقد مفتوح",
  he: "חוזה פתוח",
  en: "Open contract"
 },
 {
  ar: "عقد مكتوب",
  he: "חוזה כתוב",
  en: "Written contract"
 },
 {
  ar: "عقوبات اقتصادية",
  he: "סנקציות כלכליות",
  en: "Economic sanctions"
 },
 {
  ar: "عقوبة",
  he: "סנקציה",
  en: "sanction"
 },
 {
  ar: "على الحساب",
  he: "בהקפה\/ באשראי\/ על חשבון",
  en: "On account"
 },
 {
  ar: "علاقات العمل",
  he: "יחסי עבודה",
  en: "Labour relations"
 },
 {
  ar: "علامة تجارية",
  he: "סמל מסחרי\/ סימן מסחרי",
  en: "Trade mark"
 },
 {
  ar: "علامة تجارية نسجلة",
  he: "סימן מסחרי רשום",
  en: "Registered trade mark"
 },
 {
  ar: "علاوة",
  he: "תוספת",
  en: "allowance"
 },
 {
  ar: "علاوة على السند",
  he: "פרמיה ביחס למחיר איגרת חוב",
  en: "Premium on bond"
 },
 {
  ar: "علاوة غلاء المعيشة",
  he: "תוספת יוקר",
  en: "Cost of living allowance"
 },
 {
  ar: "علم الاقتصاد الشامل",
  he: "מקרו כלכלה",
  en: "Macro economics"
 },
 {
  ar: "عمالة",
  he: "תעסוקה",
  en: "employment"
 },
 {
  ar: "عمالة كاملة",
  he: "תעסוקה מלאה",
  en: "Full employment"
 },
 {
  ar: "عمل",
  he: "עבודה",
  en: "Work\/ labour\/ job"
 },
 {
  ar: "عمل جماعي",
  he: "עבודת צוות",
  en: "teamwork"
 },
 {
  ar: "عمل يدوي",
  he: "עבודה פיזית\/ עבודת כפיים ",
  en: "Manual work\/ labour work"
 },
 {
  ar: "عملة",
  he: "מטבע",
  en: "currency"
 },
 {
  ar: "عملة اجنبية",
  he: "מטבע זר\/ מטבע חוץ",
  en: "Foreign currency"
 },
 {
  ar: "عملة احتياطية",
  he: "עתודות מטבע",
  en: "Currency reserves"
 },
 {
  ar: "عملة سهلة",
  he: "מטבע רך",
  en: "Soft currency "
 },
 {
  ar: "عملة شرعية",
  he: "הילך חוקי",
  en: "Legal tender\/ lawful money"
 },
 {
  ar: "عملة صعبة",
  he: "מטבע קשה",
  en: "Hard currency"
 },
 {
  ar: "عملة ضعيفة",
  he: "מטבע רך",
  en: "Soft currency"
 },
 {
  ar: "عملة قابلة للتحويل",
  he: "מטבע ניתן להמרה\/ כסף בר המרה",
  en: "Convertible money"
 },
 {
  ar: "عملة قانونية",
  he: "הילך חוקי",
  en: "Legal tender\/ lawful money"
 },
 {
  ar: "عملة متداولة",
  he: "כסף במחזור",
  en: "Money in circulation"
 },
 {
  ar: "عملة مجمدة",
  he: "מטבע חסום",
  en: "Blocked currency"
 },
 {
  ar: "عملة مدارة",
  he: "מטבע חוץ שמחירו אינו חופשי לחלוטין",
  en: "Managed currency"
 },
 {
  ar: "عملة معدنية",
  he: "מטבע",
  en: "coin"
 },
 {
  ar: "عملة مقومة باقل من قيمتها",
  he: "מטבע שמוערך בפחות משוויו",
  en: "Undervalued currency"
 },
 {
  ar: "عملة موجهة",
  he: "מטבע חוץ שמחירו אינו חופשי לחלוטין",
  en: "Managed currency"
 },
 {
  ar: "عملة ورقية",
  he: "שטר כסף",
  en: "Paper money\/ bank bill\/ currency note"
 },
 {
  ar: "عملة يسرة",
  he: "מטבע רך",
  en: "Soft currency"
 },
 {
  ar: "عمليات اجلة التسليم",
  he: "חוזים עתידיים",
  en: "futures"
 },
 {
  ar: "عملية الاوفست",
  he: "עסקת קיזוז",
  en: "Offset transaction"
 },
 {
  ar: "عمولة",
  he: "דמי תיווך",
  en: "Brockerage"
 },
 {
  ar: "عمولة",
  he: "עמלה",
  en: "commission"
 },
 {
  ar: "عمولة الباعة",
  he: "עמלת סוכן",
  en: "Salesmen commission"
 },
 {
  ar: "عمولة التحويل",
  he: "עמלת חליפין",
  en: "Exchange charge"
 },
 {
  ar: "عمولة الصرافة",
  he: "אגיו",
  en: "agio"
 },
 {
  ar: "عمولة المصرف",
  he: "עמלה בנקאית",
  en: "Bank charge"
 },
 {
  ar: "عميل",
  he: "סוכן",
  en: "Agent"
 },
 {
  ar: "عميل",
  he: "לקוח",
  en: "customer\/ client"
 },
 {
  ar: "عناصر الانتاج",
  he: "גורמי ייצור",
  en: "Earnings per share"
 },
 {
  ar: "عوائد الرصيف",
  he: "דמי רציף",
  en: "Quayage\/ wharfage\/ quay handling charges "
 },
 {
  ar: "عوائد السهم",
  he: "רווחים למניה",
  en: "Earning per share"
 },
 {
  ar: "عوامل الانتاج",
  he: "גורמי ייצור",
  en: "Factors of production"
 },
 {
  ar: "عوز",
  he: "עוני",
  en: "poverty"
 },
 {
  ar: "عيب",
  he: "פגם",
  en: "defect"
 },
 {
  ar: "عينة",
  he: "דוגמה",
  en: "ample"
 },
 
 {
  ar: "غاز",
  he: "גז",
  en: "gas"
 },
 {
  ar: "غالي",
  he: "יקר ",
  en: "expensive"
 },
 {
  ar: "غبن",
  he: "הונאה",
  en: "fraud"
 },
 {
  ar: "غرامة",
  he: "קנס",
  en: "fine"
 },
 {
  ar: "غرفة تجارة",
  he: "לשכת מסחר",
  en: "Chamber of commerce\/ board of trade"
 },
 {
  ar: "غرفة مقاصة",
  he: "מסלקה",
  en: "Clearing house"
 },
 {
  ar: "غسل السندات",
  he: "כיבוס איגרות חוב",
  en: "Bond washing"
 },
 {
  ar: "غش",
  he: "זיוף",
  en: "Forgery\/ fraund"
 },
 {
  ar: "غطاء",
  he: "כיסוי",
  en: "cover"
 },
 {
  ar: "غلة",
  he: "תשואה",
  en: "Return\/ yield"
 },
 {
  ar: "غناء",
  he: "עושר",
  en: "Wealth\/ richness"
 },
 {
  ar: "غني",
  he: "עושר",
  en: "Wealth\/ richness"
 },
 {
  ar: "غير مربح",
  he: "לא רווחי",
  en: "Non- profit"
 },
 
 {
  ar: "فائدة",
  he: "תועלת",
  en: "Utility\/ benefit"
 },
 {
  ar: "فائدة",
  he: "ריבית",
  en: "interest"
 },
 {
  ar: "فائدة اسمية",
  he: "ריבית נומינאלית",
  en: "Nominal interest"
 },
 {
  ar: "فائدة اضافية",
  he: "הטבה נלווית",
  en: "Fringe benefit"
 },
 {
  ar: "فائدة بسيطة",
  he: "ריבית פשוטה\/ ריבית שאינה ריבית דריבית",
  en: "Simple interest"
 },
 {
  ar: "فائدة دائنة",
  he: "ריבית קרדיטורית",
  en: "Credit interest"
 },
 {
  ar: "فائدة سلبية",
  he: "ריבית שלילית",
  en: "Negative interest"
 },
 {
  ar: "فائدة متجمعة",
  he: "ריבית נצברת",
  en: "Accrued interest"
 },
 {
  ar: "فائدة مدينة",
  he: "ריבית דביטורית",
  en: "Debit interest"
 },
 {
  ar: "فائدة مركبة",
  he: "ריבית דריבית",
  en: "Compound interest"
 },
 {
  ar: "فائدة مفترضة",
  he: "ריבית זקופה",
  en: "Imputed interest"
 },
 {
  ar: "فائدة مقدرة",
  he: "ריבית זקופה",
  en: "Bill\/ invoice"
 },
 {
  ar: "فاتورة",
  he: "חשבון\/ חשבונית\/ חשבונית מס",
  en: "Bill\/ invoice"
 },
 {
  ar: "فاتورة تجارية",
  he: "חשבון מסחרי",
  en: "Commercial invoice"
 },
 {
  ar: "فاتورة شحن",
  he: "שטר הובלה",
  en: "Drayage bill"
 },
 {
  ar: "فاتورة شكلية",
  he: "חשבון פרופורמה",
  en: "Pro- forma invoice"
 },
 {
  ar: "فاتورة قنصلية",
  he: "חשבונית קונסולרית",
  en: "Consular invoice"
 },
 {
  ar: "فاتورة مبدئية",
  he: "חשבון פרופורמה",
  en: "Pro-forma invoice"
 },
 {
  ar: "فاتورة مصححة",
  he: "חשבון מתוקן",
  en: "Corrected invoice"
 },
 {
  ar: "فاتورة نقل",
  he: "שטר הובלה",
  en: "Drayage bill"
 },
 {
  ar: "فاحص المصارف",
  he: "המפקח על הבנקים",
  en: "Bank examiner\/ bank commissioner"
 },
 {
  ar: "فاقة",
  he: "עוני",
  en: "poverty"
 },
 {
  ar: "فتح اعتماد",
  he: "פתיחת אשראי",
  en: "Opening of credit"
 },
 {
  ar: "فتح حساب",
  he: "פתיחת חשבון",
  en: "Opening of account"
 },
 {
  ar: "فترة استرداد راس المال",
  he: "תקופת החזר",
  en: "Payback period"
 },
 {
  ar: "فترة تجربة",
  he: "תקופת ניסיון",
  en: "Probationary period"
 },
 {
  ar: "فجوة تضخمية",
  he: "פער אינפלציוני",
  en: "Inflationary gap"
 },
 {
  ar: "فجوة دولارية",
  he: "פער דולרי",
  en: "Dollar gap\/ dollar shortage"
 },
 {
  ar: "فدرالي",
  he: "פדראלי",
  en: "federal"
 },
 {
  ar: "فدية",
  he: "כופר",
  en: "ransom"
 },
 {
  ar: "فرض الضرائب",
  he: "מיסוי",
  en: "taxation"
 },
 {
  ar: "فرع ",
  he: "סניף",
  en: "branch"
 },
 {
  ar: "فساد",
  he: "שחיתות",
  en: "corruption"
 },
 {
  ar: "فضة",
  he: "כסף (המתכת)",
  en: "silver"
 },
 {
  ar: "فعالية",
  he: "יעילות",
  en: "efficiency"
 },
 {
  ar: "فقر",
  he: "עוני",
  en: "poverty"
 },
 {
  ar: "فلاحة",
  he: "חקלאות",
  en: "agriculture"
 },
 {
  ar: "فلوس",
  he: "כסך",
  en: "money"
 },
 {
  ar: "في الحالة الراهنة",
  he: "כפי שהוא",
  en: "As is"
 },
 {
  ar: "في المئة",
  he: "אחוז",
  en: "percent"
 },
 {
  ar: "فيض نقدي",
  he: "תזרים מזומנים",
  en: "Cash flow"
 },
 
 {
  ar: "قائمة الجرد",
  he: "רשימת מלאי",
  en: "Inventory list"
 },
 {
  ar: "قائمة بالبضائع المشحونة",
  he: "רשימה של סחורות להובלה",
  en: "waybill"
 },
 {
  ar: "قاعدة الذهب",
  he: "בסיס זהב",
  en: "Gold standard"
 },
 {
  ar: "قدرة على الدفع",
  he: "יכולת לשלם חובות",
  en: "solvency"
 },
 {
  ar: "قرض",
  he: "הלוואה",
  en: "loan"
 },
 {
  ar: "قرض ائتماني بدون ضمان",
  he: "הלוואה ללא ביטחונות",
  en: "Fiduciary loan"
 },
 {
  ar: "قرض اجباري",
  he: "הלוואה הניתנת בכפייה",
  en: "Compulsory loan"
 },
 {
  ar: "قرض الزامي",
  he: "הלוואה כפויה",
  en: "Forced loan"
 },
 {
  ar: "قرض تحت الطلب",
  he: "הלוואה שהגיע מועד פירעונה",
  en: "Call loan"
 },
 {
  ar: "قرض حسن",
  he: "הלוואה ללא ריבית",
  en: "Interest free loan"
 },
 {
  ar: "قرض حكومي",
  he: "הלוואה ממשלתית",
  en: "Government loan"
 },
 {
  ar: "قرض شخصي",
  he: "הלוואה אישית",
  en: "Personal loan"
 },
 {
  ar: "قرض طويل الاجل",
  he: "הלוואה לטווח ארוך",
  en: "Long term loan"
 },
 {
  ar: "قرض قصير الاجل",
  he: "הלוואה לטווח קצר",
  en: "Short term loan"
 },
 {
  ar: "قرض محدود الاجل",
  he: "הלוואה שתקופת ההחזר שלה מוגדרת",
  en: "Term loan"
 },
 {
  ar: "قرض محلي",
  he: "הלוואה מקומית",
  en: "Local loan"
 },
 {
  ar: "قرض مصرفي",
  he: "הלוואה בנקאית",
  en: "Banl loan"
 },
 {
  ar: "قرض مضمون",
  he: "הלוואה מובטחת",
  en: "Secured loan"
 },
 {
  ar: "قسط",
  he: "תשלום (מכמה תשלומים)",
  en: "installment"
 },
 {
  ar: "قسط التامين",
  he: "פרמיית ביטוח",
  en: "Insurance premium"
 },
 {
  ar:"قسط التامين",
  he: "פרמיה\/ דמי ביטוח",
  en: "premium"
 },
 {
  ar: "قسط الخطر",
  he: "פרמיית סיכון",
  en: "Risk premium"
 },
 {
  ar: "قسط تصاعدي",
  he: "פרמיה הולכת וגדלה",
  en: "Increasing premium"
 },
 {
  ar: "قسط ثابت",
  he: "פרמיה קבועה",
  en: "Fixed premium"
 },
 {
  ar: "قسط متزايد",
  he: "פרמיה הולכת וגדלה",
  en: "Increasing premium"
 },
 {
  ar: "قسم المبيعات",
  he: "מחלקת מכירות",
  en: "Sales department"
 },
 {
  ar: "قسيمة",
  he: "שובר\/ תלוש",
  en: "Voucher\/ coupon"
 },
 {
  ar: "قسيمة طلب",
  he: "טופס הזמנה",
  en: "Order form"
 },
 {
  ar: "قطاع",
  he: "מגזר\/ סקטור",
  en: "sector"
 },
 {
  ar: "قطاع خاص",
  he: "מגזר פרטי\/ סקטור פרטי",
  en: "Private sector"
 },
 {
  ar: "قطاع عام",
  he: "מגזר ציבורי\/ סקטור ציבורי",
  en: "Public sector"
 },
 {
  ar: "قطع",
  he: "מטבע",
  en: "currency"
 },
 {
  ar: "قوى السوق",
  he: "כוחות שוק",
  en: "Market forces"
 },
 {
  ar: "قوة شرائية",
  he: "כוח קנייה",
  en: "Purchasing power"
 },
 {
  ar: "قوة عاملة",
  he: "כוח עבודה",
  en: "Work force\/ labour force"
 },
 {
  ar: "قيد",
  he: "הגבלה",
  en: "restriction"
 },
 {
  ar: "قيمة",
  he: "ערך",
  en: "Rate\/ value"
 },
 {
  ar: "قيمة اساسية",
  he: "ערך נקוב\/ ערך נומינאלי",
  en: "Nominal value\/ face value\/ par value\/ par"
 },
 {
  ar: "قيمة اسمية",
  he: "ערך נקוב\/ ערך נומינאלי",
  en: "Nominal value\/ face value\/ par value\/ par"
 },
 {
  ar: "قيمة اصلية",
  he: "ערך נקוב\/ ערך נומינאלי",
  en: "Nominal value\/ face value\/ par value\/ par"
 },
 {
  ar: "قيمة المخلفات",
  he: "ערך גרט",
  en: "Salvage value"
 },
 {
  ar: "قيمة النقود",
  he: "ערך הכסף",
  en: "Value of money"
 },
 {
  ar: "قيمة حالية",
  he: "ערך נוכחי",
  en: "Present value"
 },
 {
  ar: "قيمة حقيقية",
  he: "ערך ריאלי",
  en: "Real value"
 },
 {
  ar: "قيمة دفترية",
  he: "ערך בספרים",
  en: "Book value"
 },
 {
  ar: "قيمة صافية",
  he: "שווי נקי",
  en: "Net worth"
 },
 {
  ar: "قيمة عند التصفية",
  he: "ערך חיסול",
  en: "Liquidation value"
 },
 {
  ar: "قيمة فعلية",
  he: "ערך ריאלי",
  en: "Real value"
 },
 {
  ar: "قيمة قانونية",
  he: "ערך נקוב\/ ערך נומינאלי",
  en: "Nominal value\/ face value\/ par value\/ par"
 },
 {
  ar: "قيمة محاسبية",
  he: "ערך בספרים",
  en: "Book value"
 },
 {
  ar: "قيمة مستخلصة",
  he: "ערך גרט",
  en: "Salvage value"
 },
 {
  ar: "قيمة مشتقة",
  he: "ערך נגזר",
  en: "Derived value"
 },
 {
  ar: "قيمة مضافة",
  he: "ערך מוסף",
  en: "Value added"
 },
 {
  ar: "قيمة معلنة",
  he: "ערך מוצהר",
  en: "Declared value"
 },
 {
  ar: "قيود الاستيراد",
  he: "הגבלות יבוא",
  en: "Import restrictions"
 },
 
 {
  ar: "كاتب",
  he: "פקיד",
  en: "clerk"
 },
 {
  ar: "كادح",
  he: "עובד הצווארון הכחול",
  en: "Blue collar worker"
 },
 {
  ar: "كارتل",
  he: "קרטל",
  en: "cartel"
 },
 {
  ar: "كتاب اعتماد",
  he: "מכתב אשראי",
  en: "Letter of credit"
 },
 {
  ar: "كتاب اعتماد تجاري",
  he: "מכתב אשראי מסחרי",
  en: "Commercial letter of credit "
 },
 {
  ar: "كتاب اعتماد دائري",
  he: "מכתב אשראי מתחדש מעצמו",
  en: "Revolving letter of credit"
 },
 {
  ar: "كتاب اعتماد دوار",
  he: "מכתב אשראי מתחדש מעצמו",
  en: "Revolving letter of credit"
 },
 {
  ar: "كتاب اعتماد غير قابل للالغاء",
  he: "מכתב אשראי בלתי חוזר\/ מכתב אשראי בלתי ניתן לביטול",
  en: "Irrevocable letter of credit"
 },
 {
  ar: "كتاب اعتماد غير قابل للتجزئة",
  he: "מכתב אשראי בלתי ניתן לחלוקה",
  en: "Undivisible letter of credit"
 },
 {
  ar: "كتاب اعتماد غير قابل للنقض",
  he: "מכתב אשראי בלתי חוזר\/ מכתב אשראי בלתי ניתן לביטול ",
  en: "Irrevocable letter of credit"
 },
 {
  ar: "كتاب اعتماد قابل للالغاء",
  he: "מכתב אשראי חוזר\/ מכתב אשראי בר ביטול",
  en: "Revocable letter of credit"
 },
 {
  ar: "كتاب اعتماد قابل للتجزئة",
  he: "מכתב אשראי ניתן לחלוקה",
  en: "Divisible letter of credit"
 },
 {
  ar: "كتاب اعتماد قابل للتحويل",
  he: "מכתב אשראי ניתן להעברה",
  en: "Transferable letter of credit"
 },
 {
  ar: "كتاب اعتماد قابل للنقض",
  he: "מכתב אשראי חוזר\/ מכתב אשראי בר ביטול",
  en: "Revocable letter of credit"
 },
 {
  ar: "كتاب اعتماد للاستيراد",
  he: "מכתב אשראי ליבוא",
  en: "Import letter of credit"
 },
 {
  ar: "كتاب اعتماد للتصدير",
  he: "מכתב אשראי ליצוא",
  en: "Export letter of credit"
 },
 {
  ar: "كتاب اعتماد مثبت",
  he: "מכתב אשראי מאושר",
  en: "Confirmed letter of credit"
 },
 {
  ar: "كتاب اعتماد محدود",
  he: "מכתב אשראי מוגבל",
  en: "Limited letter of credit"
 },
 {
  ar: "كتاب اعتماد مفتوح",
  he: "מכתב אשראי פתוח",
  en: "Opened letter of credit"
 },
 {
  ar: "كتاب اعتماد نقدي",
  he: "מכתב אשראי לתשלום במזומן",
  en: "Cash letter of credit"
 },
 {
  ar: "كتاب النوايا",
  he: "מכתב נכונות",
  en: "Letter of intent"
 },
 {
  ar: "كتاب تخصيص",
  he: "מכתב הקצאה",
  en: "Letter of allotment"
 },
 {
  ar: "كتاب تعويض",
  he: "מכתב שיפוי",
  en: "Letter of indemnity"
 },
 {
  ar: "كتاب ضمان",
  he: "מכתב ערבות",
  en: "Letter of guarantee"
 },
 {
  ar: "كتاب معاوضة",
  he: "מכתב שיפוי",
  en: "Letter of indemnity"
 },
 {
  ar: "كثيف الدراية",
  he: "עתיר ידע",
  en: "Know how intensive"
 },
 {
  ar: "كثيف العمل",
  he: "עתיר עבודה",
  en: "Labour intensive"
 },
 {
  ar: "كثيف راس المال",
  he: "עתיר הון",
  en: "Capital intensive"
 },
 {
  ar: "كري",
  he: "השכרה",
  en: "Lease\/ rent"
 },
 {
  ar: "كساد اقتصادي",
  he: "שפל כלכלי",
  en: "Depression\/ slump"
 },
 {
  ar: "كسب",
  he: "רווח",
  en: "Gain\/ profit"
 },
 {
  ar: "كشف الرواتب",
  he: "רשימת מקבלי המשכורות",
  en: "payroll"
 },
 {
  ar: "كشف حساب",
  he: "מצב חשבון",
  en: "Statement of account"
 },
 {
  ar: "كفالة",
  he: "אחריות\/ ערבות",
  en: "Responsibility\/ liability\/ guarantee"
 },
 {
  ar: "كفاية",
  he: "יעילות",
  en: "efficiency"
 },
 {
  ar: "كمبيالة",
  he: "המחאה\/ שטר חליפין",
  en: "Draft\/ bill of exchange"
 },
 {
  ar: 'null',
  he: "תעודה\/ שטר",
  en: "Certificate\/ scrip\/ note\/ deed\/ bill"
 },
 {
  ar: "كمبيالة تدفع عند الطلب",
  he: "שטר לתשלום על פי דרישה",
  en: "Demand draft"
 },
 {
  ar: "كمبيالة داخلية",
  he: "שטר חליפין לשימוש בסחר פנים",
  en: "Domestic bill of exchange\/ inland bill of exchange"
 },
 {
  ar: "كمبيالة صورية",
  he: "שטר טובה",
  en: "Accommodation bill "
 },
 {
  ar: "كمبيالة مجاملة",
  he: "שטר טובה",
  en: "Accommodation bill "
 },
 {
  ar: "كمبيالة مصرفية",
  he: "שטר כסף",
  en: "Bank bill\/ currency note\/ paper money"
 },
 {
  ar: "كمية",
  he: "כמות",
  en: "quantity"
 },
 {
  ar: "كمية الطلب الاقتصادية",
  he: "כמות אופטימלית להזמנה",
  en: "Economic order quantity"
 },
 {
  ar: "كمية ناتجة",
  he: "תפוקה",
  en: "output"
 },
 {
  ar: "كوتا",
  he: "מכסה",
  en: "quota"
 },
 {
  ar: "كوميكون",
  he: "קומקון",
  en: "comecon"
 },
 {
  ar: "كونسورتيوم",
  he: "קונסורציום",
  en: "Consortium "
 },
 
 {
  ar: "لائحة الاسعار",
  he: "מחירון",
  en: "Price list\/ quotation board"
 },
 {
  ar: "اللجنة الاقتصادية والاجتماعية لغرب اسيا",
  he: "הוועדה הכלכלית חברתית למערב אסיה",
  en: "Economic and social commission for Western Asia "
 },
 {
  ar: "لحاملة",
  he: "למוסר כתב זה",
  en: "To bearer"
 },
 {
  ar: "لحساب المستفيد فقط",
  he: "למוטב בלבד",
  en: "Account payee only"
 },
 {
  ar: "لوازم",
  he: "אספקה",
  en: "supply"
 },
 {
  ar: "لوحة الاسعار",
  he: "מחירון",
  en: "Price list\/ quotation board"
 },
 {
  ar: "لويدز",
  he: "לוידס",
  en: "Lloyd's"
 },
 
 {
  ar: "مادة",
  he: "חומר",
  en: "material"
 },
 {
  ar: "مادة اولية",
  he: "חומר גלם",
  en: "Raw material"
 },
 {
  ar: "مادية",
  he: "חמרנות\/ מטריאליזם",
  en: "materialism"
 },
 {
  ar: "مؤسسة ",
  he: "פירמה",
  en: "Firm"
 },
 {
  ar:"مؤسسة ",
  he: "קבוצה\/ קונצרן",
  en: "group\/ concern"
 },
 {
  ar: "مؤسسة ",
  he: "עסק",
  en: "business"
 },
 {
  ar: "مؤسسة ",
  he: "חברה",
  en: "corporation\/ company"
 },
 {
  ar: "مؤسسة عامة",
  he: "עסק חי",
  en: "Going concern"
 },
 {
  ar: "مؤشر",
  he: "מדד",
  en: "index"
 },
 {
  ar: "مؤشر اسعار المستهلكين",
  he: "מדד המחירים לצרכן",
  en: "Consumer prices index"
 },
 {
  ar: "مؤشر داو جونز",
  he: "מדד דאו ג'ונס",
  en: "Daw jones index"
 },
 {
  ar: "مؤشر نيقي",
  he: "מדד ניקיי",
  en: "Nikkei index"
 },
 {
  ar: "ماكنة",
  he: "מכונה",
  en: "machine"
 },
 {
  ar: "مال",
  he: "כסף",
  en: "money"
 },
 {
  ar: "مالك",
  he: "בעלים",
  en: "owner"
 },
 {
  ar: "مالك فرد",
  he: "בעלות יחיד",
  en: "Sole proprietorship"
 },
 {
  ar: "مالك وحيد",
  he: "בעלות יחיד",
  en: "Sole proprietorship"
 },
 {
  ar: "مالي",
  he: "פינאנסי\/ כספי",
  en: "financial"
 },
 {
  ar: "المالية",
  he: "משרד האוצר",
  en: "Exchequer\/ treasury\/ ministry of finance"
 },
 {
  ar: "مؤمن",
  he: "חתם",
  en: "Under writer"
 },
 {
  ar: "مأمور",
  he: "פקיד",
  en: "clerk"
 },
 {
  ar: "مأمور تصفية",
  he: "מפרק",
  en: "liquidator"
 },
 {
  ar: "مأمور تفليسة",
  he: "כונס נכסים",
  en: "Official receiver"
 },
 {
  ar: "مأمور شراء",
  he: "קניין",
  en: "buyer"
 },
 {
  ar: "مؤهل",
  he: "מוסמך\/ בעל הכשרה",
  en: "qualified"
 },
 {
  ar: "مبادرة",
  he: "יוזמה",
  en: "intiative"
 },
 {
  ar: "مبادلة الاسهم",
  he: "מסחר במניות",
  en: "Exchange of shares"
 },
 {
  ar: "مبدل العملات الاجنبية",
  he: "חלפן כספים",
  en: "Money changer\/ changer"
 },
 {
  ar: "مبلغ",
  he: "סכום",
  en: "Sum\/ amount"
 },
 {
  ar: "مبلغ مدين",
  he: "חיוב\/ חובה",
  en: "debit"
 },
 {
  ar: "مبيعات",
  he: "מכירות",
  en: "sales"
 },
 {
  ar: "متاجر متعددة الفروع",
  he: "רשת חנויות",
  en: "Chain stores\/ multiple store"
 },
 {
  ar: "متاجرة",
  he: "מסחר\/ סחר",
  en: "Commerce\/ trade"
 },
 {
  ar: "متجر",
  he: "חנות",
  en: "Shop\/ store"
 },
 {
  ar: "متجر الخدمة الذاتية",
  he: "סופרמרקט",
  en: "supermarket"
 },
 {
  ar: "متجر متعدد الاقسام",
  he: "חנות כלבו",
  en: "Department store"
 },
 {
  ar: "متجر مصنف",
  he: "חנות כלבו",
  en: "Department store"
 },
 {
  ar: "متطلبات",
  he: "דרישות",
  en: "requirements"
 },
 {
  ar: "متعطل",
  he: "מובטל",
  en: "Unemployed\/ jobless"
 },
 {
  ar: "متعهد",
  he: "יזם",
  en: "entrepreneur"
 },
 {
  ar: "متقاعد",
  he: "גימלאי\/ פנסיונר",
  en: "pensioner"
 },
 {
  ar: "متوسط الايراد",
  he: "פדיון ממוצע",
  en: "Average revenue"
 },
 {
  ar: "مثمن",
  he: "שמאי",
  en: "assessor"
 },
 {
  ar: "مجانا",
  he: "חינם",
  en: "Free of charge"
 },
 {
  ar: "مجلة اقتصادية",
  he: "כתב עת לענייני כלכלה",
  en: "Economic journal"
 },
 {
  ar: "مجلس ادارة",
  he: "מועצת מנהלים",
  en: "Board of management\/ board of directors"
 },
 {
  ar: "مجلس حكام",
  he: "מועצת נגידים",
  en: "Board of governors"
 },
 {
  ar: "مجمع",
  he: "קונגלומרט (חברת ענק המורכבת ממספר פירמות)",
  en: "conglomerate"
 },
 {
  ar: "مجموعة الدول العشر",
  he: "קבוצת העשר",
  en: "Group of ten"
 },
 {
  ar: "مجموعة شركات",
  he: "קבוצת חברות",
  en: "Group of companies"
 },
 {
  ar: "محاسب",
  he: "מנהל חשבונות",
  en: "bookkeeper"
 },
 {
  ar: "محاسب استشاري",
  he: "יועץ חשבונאי",
  en: "Consulting accountant"
 },
 {
  ar: "محاسبة",
  he: "חשבונאות",
  en: "Accountancy\/ accounting "
 },
 {
  ar: "محاسبة",
  he: "הנהלת חשבונות",
  en: "bookkeeping"
 },
 {
  ar: "محاسبة ادارية",
  he: "חשבונאות ניהולית",
  en: "Management accounting\/ managerial accounting"
 },
 {
  ar: "محاسبة التكاليف",
  he: "תמחיר",
  en: "Costing\/ cost accounting"
 },
 {
  ar: "محاسبة التكاليف التحميلية",
  he: "תמחיר ספיגה",
  en: "Absorption costing\/ absorption cost accounting"
 },
 {
  ar: "محاسبة التكاليف القياسية",
  he: "תמחיר תקן",
  en: "Standard costing\/ standard cost accounting"
 },
 {
  ar: "محاسبة تكاليف مراحل التصنيع",
  he: "תמחיר תהליך",
  en: "Process costing\/ process cost accounting"
 },
 {
  ar: "محاسبة تكاليف مراحل المعالجة",
  he: "תמחיר תהליך",
  en: "Process costing\/ process cost accounting"
 },
 {
  ar: "محاسبة دورية",
  he: "חשבונאות מחזורים",
  en: "Cycle accounting"
 },
 {
  ar: "محاسبة طبيعة",
  he: "חשבונאות מקובלת",
  en: "Applied accounting"
 },
 {
  ar: "محاسبة مالية",
  he: "חשבונאות פינאנסית",
  en: "Financial accounting"
 },
 {
  ar: "محافظ بنك",
  he: "נגיד בנק",
  en: "Governor of a bank"
 },
 {
  ar: "محترف",
  he: "איש מקצוע",
  en: "professional"
 },
 {
  ar: "محدود",
  he: "בעירבון מוגבל",
  en: "limited"
 },
 {
  ar: "محصول",
  he: "תפוקה",
  en: "Output"
 },
 {
  ar: "محصول",
  he: "יבול",
  en: "crop"
 },
 {
  ar: "محطة توليد الطاقة",
  he: "תחנת כוח",
  en: "Power station"
 },
 {
  ar: "محفظة مستندات الاستثمار",
  he: "תיק השקעות",
  en: "Investment portfolio"
 },
 {
  ar: "محل تجاري",
  he: "חנות",
  en: "Shop\/ store"
 },
 {
  ar: "محلات السلسلة",
  he: "רשת חנויות",
  en: "Chain stores\/ multiple stores"
 },
 {
  ar: "محوزات",
  he: "החזקות",
  en: "holdings"
 },
 {
  ar: "مخزن",
  he: "מחסן",
  en: "storeroom"
 },
 {
  ar: "مخزون البضاعة",
  he: "מלאי",
  en: "Stock\/ inventory"
 },
 {
  ar: "مخصص",
  he: "קצבה",
  en: "allowance"
 },
 {
  ar: "مخطط",
  he: "תכנית",
  en: "plan"
 },
 {
  ar: "مدى الضرر",
  he: "מידת הנזק",
  en: "Extent of damage"
 },
 {
  ar: "مدبرة المنزل",
  he: "עוזרת בית",
  en: "housekeeper"
 },
 {
  ar: "مدة الاساس",
  he: "תקופת בסיס",
  en: "Bas period"
 },
 {
  ar: "مدة المحاسبة",
  he: "תקופת חשבון",
  en: "Accounting period"
 },
 {
  ar: "مدة الوظيفة",
  he: "תקופת כהונה",
  en: "Tenure\/ term of office"
 },
 {
  ar: "مدخل الانتاج",
  he: "תשומה",
  en: "input"
 },
 {
  ar: "مدفوع",
  he: "תשלום",
  en: "payment"
 },
 {
  ar: "مدفوعات تحويلية",
  he: "תשלומי העברה",
  en: "Transfer payments"
 },
 {
  ar: "مدقق حسابات",
  he: "רואה חשבון",
  en: "accountant"
 },
 {
  ar: "مدير",
  he: "מנהל\/ איש מנהלה",
  en: "Manager\/ administrator\/ director"
 },
 {
  ar: "مدير المبيعات",
  he: "מנהל מכירות",
  en: "Sales manager"
 },
 {
  ar: "مدير تنفيذي",
  he: "מנהל כללי",
  en: "Executive director\/ managing director"
 },
 {
  ar: "مدير عام",
  he: "מנהל כללי",
  en: "Executive director\/ managing director"
 },
 {
  ar: "مدير مشترك",
  he: "חברת הנהלה בכמה חברות",
  en: "Interlocking director"
 },
 {
  ar: "مدير مكتب البريد",
  he: "מנהל הדואר",
  en: "postmaster"
 },
 {
  ar: "مدير منتدب",
  he: "מנהל כללי",
  en: "Executive director\/ managing director"
 },
 {
  ar: "مديرة المنزل",
  he: "מנהל משק בית",
  en: "housekeeper"
 },
 {
  ar: "مدين",
  he: "בעל חוב\/ חייב",
  en: "debtor"
 },
 {
  ar: "مدينة السوق",
  he: "עיר שוק",
  en: "Market town"
 },
 {
  ar: "مدينة سكنية",
  he: "עיר שינה",
  en: "Dormitory town"
 },
 {
  ar: "مديونية",
  he: "חבויות (כלל חובות של גוף מסוים)",
  en: "indebtedness"
 },
 {
  ar: "مذهب التجارية",
  he: "מרקנטליזם",
  en: "mercantilism"
 },
 {
  ar: "مراب",
  he: "מלווה בריבית",
  en: "usurer"
 },
 {
  ar: "مرابحة",
  he: "מכירת סחורה או שירות על ידי מתווך כשפער התיווך ידוע לקונה",
  en: "Selling of commodity or service by a middleman when the brokerage is known to the purchaser"
 },
 {
  ar: "مراجحة",
  he: "ארביטראג'\/ בוררות",
  en: "arbitrage"
 },
 {
  ar: "مراقب الحسابات",
  he: "מבקר",
  en: "comptroller"
 },
 {
  ar: "مراقب العملة",
  he: "מפקח על מטבע חוץ",
  en: "Controller of the currency\/ supervisor of foreign currency"
 },
 {
  ar: "مراقب الموازنة",
  he: "קצין תקציבים",
  en: "Budget controller"
 },
 {
  ar: "مراقب مالي",
  he: "מבקר",
  en: "comptroller"
 },
 {
  ar: "مراقبة الاسعار",
  he: "פיקוח על המחירים",
  en: "Price control\/ valorization"
 },
 {
  ar: "مراقبة الانتاج",
  he: "בקרת ייצור",
  en: "Production control"
 },
 {
  ar: "مراقبة التحويل",
  he: "פיקוח על מטבע",
  en: "Exchange control"
 },
 {
  ar: "مراقبة التسليف",
  he: "פיקוח על אשראי",
  en: "Credit control"
 },
 {
  ar: "مراقبة الجودة",
  he: "בקרת איכות",
  en: "Quality control"
 },
 {
  ar: "مراقبة الدولة",
  he: "פיקוח ממשלתי",
  en: "Government supervision"
 },
 {
  ar: "مراقبة الميزانية",
  he: "בקרה תקציבית",
  en: "Budgetary control"
 },
 {
  ar: "مراقبة النوعية",
  he: "בקרת איכות",
  en: "Quality control"
 },
 {
  ar: "مراهنة",
  he: "הימור",
  en: "gambling"
 },
 {
  ar: "مربحية",
  he: "רווחיות",
  en: "profitability"
 },
 {
  ar: "مرتهن",
  he: "נותן משכנתא",
  en: "mortgagee"
 },
 {
  ar: "مردود ",
  he: "תשואה",
  en: "Return\/ yield"
 },
 {
  ar: "مرسل",
  he: "שולח",
  en: "Dispatcher"
 },
 {
  ar:"مرسل",
  he: "משגר (שולח הסחורה)",
  en: "Consigner"
 },
 {
  ar: "مركز التكاليف",
  he: "מרכז עלות",
  en: "Cost center"
 },
 {
  ar: "مركز تسوق",
  he: "מרכז קניות",
  en: "Shopping center"
 },
 {
  ar: "مركز جمركي",
  he: "בית המכס",
  en: "Customs house"
 },
 {
  ar: "مركز رئيسي",
  he: "משרד ראשי",
  en: "Head office\/ main office\/ headquarters"
 },
 {
  ar: "مركز صناعي",
  he: "מרכז תעשייתי",
  en: "Industrial center"
 },
 {
  ar: "مرونة الاسعار",
  he: "גמישות מחירים",
  en: "Flexibility of prices"
 },
 {
  ar: "مزاد",
  he: "מכירה פומבית",
  en: "auction"
 },
 {
  ar: "مزاد تراجعي",
  he: "מכירה פומבית הולנדית",
  en: "Dutch auction"
 },
 {
  ar: "مزاد صيني ",
  he: "מכירה פומבית סינית",
  en: "Chinese auction"
 },
 {
  ar: "مزاد هولندي",
  he: "מכירה פומבית הולנדית",
  en: "Dutch auction"
 },
 {
  ar: "مزارع",
  he: "חקלאי",
  en: "farmer"
 },
 {
  ar: "مزارعة",
  he: "החכרת קרקע למעבד תמורת חלק מהיבול",
  en: "Leasing of land to a cultivator in exchange for part of the crops"
 },
 {
  ar: "مزايد",
  he: "מציע מחיר במכירה פומבית",
  en: "bidder"
 },
 {
  ar: "مزرعة",
  he: "חווה",
  en: "farm"
 },
 {
  ar: "مزرعة جماعية",
  he: "חווה שיתופית",
  en: "Collective farm"
 },
 {
  ar: "مساح",
  he: "מודד",
  en: "surveyor"
 },
 {
  ar: "مساحة",
  he: "מדידה",
  en: "surveying"
 },
 {
  ar: "مساعد اداري",
  he: "עוזר מנהלי",
  en: "Administrative assiatant"
 },
 {
  ar: "مساعدة",
  he: "סיוע\/ עזרה",
  en: "aid"
 },
 {
  ar: "مساقاة",
  he: "החכרת קרקע למעבד תמורת חלק מהיבול",
  en: "Leasing of land to a cultivator in exchange for part of the crops"
 },
 {
  ar: "مساندة",
  he: "סיוע\/ עזרה",
  en: "aid"
 },
 {
  ar: "مساهم",
  he: "בעל מניות",
  en: "Stockholder\/ shareholder"
 },
 {
  ar: "مساهمة",
  he: "השתתפות",
  en: "contribution"
 },
 {
  ar: "المساوة في تحمل عبء الضرائب",
  he: "שוויון בעול המסים",
  en: "Equality of taxation"
 },
 {
  ar: "مسؤول اداري",
  he: "מנהל\/ איש מנהלה",
  en: "Manager\/ administrator\/ director administrative officer"
 },
 {
  ar: "مسؤول اداري",
  he: "אחראי מנהלי",
  en: " administrator"
 },
 {
  ar: "مسؤولية",
  he: "אחריות",
  en: "Liability\/ responsibility"
 },
 {
  ar: "مسؤولية غير محدودة",
  he: "אחריות בלתי מוגבלת",
  en: "Unlimited liability"
 },
 {
  ar: "مسؤلية محدودة",
  he: "אחריות מוגבלת",
  en: "Limited liability"
 },
 {
  ar: "مساومة جماعية",
  he: "הסכם קיבוצי",
  en: "Collective bargaining"
 },
 {
  ar: "مستاجر",
  he: "שוכר\/ דייר",
  en: "Tenant"
 },
 {
  ar: "مستاجر",
  he: "חוכר",
  en: "leaseholder"
 },
 {
  ar: "مستثمر",
  he: "משקיע",
  en: "investor"
 },
 {
  ar: "مستثمرون حماعيون",
  he: "משקיעים מוסדיים",
  en: "Institutional investors"
 },
 {
  ar: "مستحق الدفع",
  he: "לתשלום",
  en: "payable"
 },
 {
  ar: "مستخدم",
  he: "מועסק\/ שכיר\/ עובד",
  en: "Employee\/ worker"
 },
 {
  ar: "مستشار اداري",
  he: "יועץ מנהלי",
  en: "Administrative adviser"
 },
 {
  ar: "مستشار اقتصادي",
  he: "יועץ כלכלי",
  en: "Economic adviser"
 },
 {
  ar: "مستشار الاستثمار",
  he: "יועץ השקעות",
  en: "Investment adviser"
 },
 {
  ar: "مستشار مالي",
  he: "יועץ פינאנסי",
  en: "Financial adviser"
 },
 {
  ar: "مستفيد",
  he: "מוטב",
  en: "payee"
 },
 {
  ar: "مستند القيد",
  he: "שובר",
  en: "Coupon\/ voucher"
 },
 {
  ar: "مستهلك",
  he: "צרכן",
  en: "consumer"
 },
 {
  ar: "مستوى الاسعار",
  he: "רמת מחירים",
  en: "Price level"
 },
 {
  ar: "مستوى المعيشة",
  he: "רמת חיים",
  en: "Standard of living"
 },
 {
  ar: "مستودع",
  he: "מחסן",
  en: "storeroom"
 },
 {
  ar: "مستودع الجمرك",
  he: "מחסן ערובה",
  en: "Bond\/ warehouse"
 },
 {
  ar: "مستورد",
  he: "יבואן",
  en: "importer"
 },
 {
  ar: "مستوردات",
  he: "סחורות יבוא",
  en: "imports"
 },
 {
  ar: "مسجل",
  he: "רשם",
  en: "Registrar"
 },
 {
  ar: "مسجل الشركات",
  he: "רשם החברות",
  en: "Registrar of companies"
 },
 {
  ar: "مسك الدفاتر بطريقة القيد المزدوج",
  he: "ניהול ספרים בשיטת הפנקסנות הכפולה",
  en: "Double entry bookkeeping"
 },
 {
  ar: "مسك الدفاتر بطريقة القيد المفرد ",
  he: "ניהול ספרים בשיטת הרישום החד- צדדית",
  en: "Single entry bookkeeping"
 },
 {
  ar: "مسكوكة",
  he: "מטבע",
  en: "coin"
 },
 {
  ar: "مسكوكة ذهبية",
  he: "מטבע זהב",
  en: "Gold coin"
 },
 {
  ar: "مشتري",
  he: "קונה",
  en: "Purchaser\/ buyer"
 },
 {
  ar: "مشترك بالتضامن والتكافل",
  he: "ביחד וליחוד",
  en: "Joint and several"
 },
 {
  ar: "مشتريات",
  he: "רכש",
  en: "Purchase"
 },
 {
  ar: "مشتريات",
  he: "קניות",
  en: "Purchases"
 },
 {
  ar: "مشروع",
  he: "מפעל",
  en: "Enterprise"
 },
 {
  ar: "مشروع",
  he: "תכנית",
  en: "Plan"
 },
 {
  ar: "مشروع",
  he: "פרוייקט",
  en: "Project\/ venture"
 },
 {
  ar: "مشروع انجازي",
  he: "פרוייקט המבוצע בשלמותו עד למסירה הסופית ללקוח",
  en: "Turn key project"
 },
 {
  ar: "مشروع خاص",
  he: "יוזמה פרטית",
  en: "Private enterprise"
 },
 {
  ar: "مشروع عام",
  he: "מפעל ציבורי",
  en: "Public enterprise"
 },
 {
  ar: "مشروع مارشال",
  he: "תכנית מארשל",
  en: "Marshall plan"
 },
 {
  ar: "مشروع ممكن تطبيقة عمليا",
  he: "תכנית ישימה",
  en: "Feasible program"
 },
 {
  ar: "مشغل",
  he: "בית מלאכה",
  en: "Workshop"
 },
 {
  ar: "مصادرة",
  he: "עיקול",
  en: "Confiscation"
 },
 {
  ar: "مصاريف مستحقة متراكمة",
  he: "חיובים נצברים",
  en: "Accrued charges"
 },
 {
  ar: "مصدر",
  he: "מקור",
  en: "Source"
 },
 {
  ar: "مصدر",
  he: "יצואן",
  en: "Exporter"
 },
 {
  ar: "مصدر ثروة",
  he: "משאב",
  en: "Resource"
 },
 {
  ar: "مصدر غير مباشر",
  he: "יצואן עקיף",
  en: "indirect exporter"
 },
 {
  ar: "مصدر مباشر",
  he: "יצואן ישיר",
  en: "Direct exporter"
 },
 {
  ar: "مصرف",
  he: "בנק",
  en: "bank"
 },
 {
  ar: "مصروف",
  he: "הוצאה",
  en: "Outlay\/ expenditure\/ expense"
 },
 {
  ar: "مصروف منفق",
  he: "הוצאה שוטפת",
  en: "Out of pocket expense"
 },
 {
  ar: "مصف",
  he: "מפרק",
  en: "Liquidator"
 },
 {
  ar: "مصفاة",
  he: "בית זיקוק",
  en: "Refinery"
 },
 {
  ar: "مصلحة الاوراق المالية",
  he: "רשות לנייר ערך",
  en: "Securities authority"
 },
 {
  ar: "مصنع",
  he: "בית חרושת",
  en: "Factory"
 },
 {
  ar: "مصنع مسبقا",
  he: "טרומי",
  en: "Prefabricated"
 },
 {
  ar: "مصنوع بناءا على طلب",
  he: "לפי הזמנה",
  en: "Made to order"
 },
 {
  ar: "مضارب",
  he: "ספקולנט",
  en: "Speculator"
 },
 {
  ar: "مضارب",
  he: "מבקש מניות בהנפקה חדשה כדי לממשן מהר",
  en: "Bear stage"
 },
 {
  ar: "مضارب بالنزول",
  he: "ספקולנט",
  en: "Speculator\/ bear"
 },
 {
  ar: "مضارب بانخفاض الاسعار",
  he: "ספקולנט",
  en: "Speculator\/ bear"
 },
 {
  ar: "مضاربة",
  he: "ספספרות\/ ספקולציה",
  en: "Speculation"
 },
 {
  ar:  "مضاربة",
  he: "שותפות שבה גורם אחד תורם את המימון והאחר את העבודה והידע",
  en: "Partnership in which one side contributes the financing and the other contributes the work and knowledge"
 },
 {
  ar: "مضاربة مفتعلة",
  he: "מניפולציה",
  en: "Manipulation"
 },
 {
  ar: "مطالب",
  he: "דרישות",
  en: "requirements"
 },
 {
  ar: "مع الربح",
  he: "עם דיווידנד",
  en: "Cum dividend"
 },
 {
  ar: "معاش التقاعد",
  he: "פנסיה",
  en: "Pension"
 },
 {
  ar: "معاملة",
  he: "עסקה",
  en: "Bargain\/ transaction"
 },
 {
  ar: "معدل",
  he: "שער\/ שיעור",
  en: "Rate\/ ratio"
 },
 {
  ar: "معدل",
  he: "ממוצע",
  en: "average"
 },
 {
  ar: "معدل اساسي",
  he: "שער בסיסי",
  en: "Flat rate\/ basic rate"
 },
 {
  ar: "معدل التكلفة",
  he: "עלות ממוצעת",
  en: "Average cost"
 },
 {
  ar: "معدل السعر",
  he: "שער יציג",
  en: "Representative rate"
 },
 {
  ar: "معدل العائد",
  he: "שער התשואה",
  en: "Rate of return"
 },
 {
  ar: "معدل المردود",
  he: "שער התשואה",
  en: "Rate of return"
 },
 {
  ar: "معدل النمو",
  he: "שיעור הצמיחה",
  en: "Rate of growth"
 },
 {
  ar: "معدل داخلي مردود",
  he: "שיעור תשואה פנימי",
  en: "Internal rate of return"
 },
 {
  ar: "معدل دورات المخزون",
  he: "מחזור מלאי",
  en: "Inventory turnover"
 },
 {
  ar: "معدلات التبادل",
  he: "תנאי סחר",
  en: "Terms of trade"
 },
 {
  ar: "معفى من اجرة البريد",
  he: "דמי הדואר שולמו",
  en: "Post- free\/ post paid"
 },
 {
  ar: "معفى من الرسوم",
  he: "פטור ממכס",
  en: "Duty- free"
 },
 {
  ar: "معفى من الضريبة",
  he: "פטור ממס",
  en: "Tax- free"
 },
 {
  ar: "معمل تكرير",
  he: "בית זיקוק",
  en: "refinery"
 },
 {
  ar: "معونة",
  he: "סיוע",
  en: "Aid"
 },
 {
  ar: "معونة اجنبية",
  he: "סיוע חוץ",
  en: "Foreign aid"
 },
 {
  ar: "معونة مالية",
  he: "סיוע פינאנסי",
  en: "Financial aid"
 },
 {
  ar: "مفسد الاضراب",
  he: "מפר שביתה",
  en: "Strike breaker"
 },
 {
  ar: "مفوض مراقبة المصارف",
  he: "המפקח של הבנקים",
  en: "Bank commissioner\/ bank examiner"
 },
 {
  ar: "مقابل الدفع",
  he: "כנגד תשלום",
  en: "Against payment"
 },
 {
  ar: "مقابل ايصال",
  he: "כנגד קבלה",
  en: "Against receipt"
 },
 {
  ar: "مقاصة",
  he: "קיזוז",
  en: "Set off"
 },
 {
  ar: "مقاطعة",
  he: "חרם",
  en: "Boycott"
 },
 {
  ar: "مقامرة",
  he: "הימור",
  en: "gambling"
 },
 {
  ar: "مقاول",
  he: "קבלן",
  en: "Contractor"
 },
 {
  ar: "مقاول من الباطن",
  he: "קבלן משנה",
  en: "Sub- contractor"
 },
 {
  ar: "مقاولات من الباطن",
  he: "קבלנות משנה",
  en: "Sub- contracting"
 },
 {
  ar: "مقاومة الانكماش",
  he: "נקיטת צעדים אנטי- דפלציוניים",
  en: "reflation"
 },
 {
  ar: "مقايضة",
  he: "סחר חליפין",
  en: "Barter"
 },
 {
  ar: "مقدر",
  he: "שמאי",
  en: "Assessor"
 },
 {
  ar: "مقدم العطاء",
  he: "מציע מחיר במכירה פומבית\/ מציע מחיר במכרז",
  en: "bidder"
 },
 {
  ar: "مقر رئيسي",
  he: "משרד ראשי",
  en: "Head office\/ main office\/ headquarters"
 },
 {
  ar: "مقرض",
  he: "מלווה",
  en: "Measure\/ creditor"
 },
 {
  ar: "مقياس الاضرار",
  he: "מדידת נזק",
  en: "Measure of damages"
 },
 {
  ar: "مكافأة",
  he: "פרס",
  en: "Award"
 },
 {
  ar: "مكافأة",
  he: "תגמול",
  en: "Reward"
 },
 {
  ar: "مكافأة",
  he: "הטבה",
  en: "Bonus\/ premium"
 },
 {
  ar: "مكتب",
  he: "משרד",
  en: "Office"
 },
 {
  ar: "مكتب بريد",
  he: "בית דואר",
  en: "Post office"
 },
 {
  ar: "مكتب رئيسي",
  he: "משרד ראשי",
  en: "Head office\/ main office\/ headquarters"
 },
 {
  ar: "المكتب الرئيسي لمقاطعة اسرائيل",
  he: "המשרד הראשי לחרם על ישראל",
  en: "Central boycott office"
 },
 {
  ar: "مكنة",
  he: "מכונה",
  en: "Machine"
 },
 {
  ar: "مكينة",
  he: "מכונה",
  en: "machine"
 },
 {
  ar: "ملاءة",
  he: "יכולת לשלם חובות",
  en: "solvency"
 },
 {
  ar: "ملاءة ائتمانية",
  he: "כושר אשראי",
  en: "Credit wortiness"
 },
 {
  ar: "ملاحظ اشغال",
  he: "מנהל עבודה",
  en: "foreman"
 },
 {
  ar: "ملاحظ اعمال",
  he: "מנהל עבודה",
  en: "foreman"
 },
 {
  ar: "ملك",
  he: "נכסים",
  en: "Assets"
 },
 {
  ar: "ملك",
  he: "רכוש",
  en: "property"
 },
 {
  ar: "ملك ثابت",
  he: "נכס דלא ניידי",
  en: "Real estate"
 },
 {
  ar: "ملك خاص",
  he: "רכוש פרטי",
  en: "Private property"
 },
 {
  ar: "ملك شائع",
  he: "רכוש משותף",
  en: "Joint property\/ coproperty"
 },
 {
  ar: "ملك عام",
  he: "נחלת הכלל",
  en: "Public domain"
 },
 {
  ar: "ملك عام",
  he: "רכוש ציבורי",
  en: "Public property"
 },
 
 {
  ar: "ملك عقاري",
  he: "נכס דלא ניידי",
  en: "Real estate"
 },
 {
  ar: "ملك مشترك",
  he: "רכוש משותף",
  en: "Joint property\/ coproperty"
 },
 {
  ar: "ملكية",
  he: "קניין\/ רכוש",
  en: "Property"
 },
 {
  ar: "ملكية",
  he: "בעלות",
  en: "Ownership\/ proprietorship"
 },
 {
  ar: "ملكية الدولة",
  he: "בעלות ממשלתית",
  en: "State ownership"
 },
 {
  ar: "ملكية جماعية",
  he: "בעלות קיבוצית",
  en: "Collective ownership"
 },
 {
  ar: "ملكية عامة",
  he: "בעלות ציבורית",
  en: "Public ownership"
 },
 {
  ar: "ملكية مشتركة",
  he: "בעלות משותפת",
  en: "Co- ownership\/ joint ownership"
 },
 {
  ar: "ممارسة تجارية تقييدية",
  he: "הגבל עסק",
  en: "Restrictive trade practice"
 },
 {
  ar: "ممتهن",
  he: "איש מקצוע",
  en: "Professional"
 },
 {
  ar: "ممول",
  he: "מממן",
  en: "Financier"
 },
 {
  ar: "منافسة",
  he: "תחרות",
  en: "Competition"
 },
 {
  ar: "منافسة احتكارية",
  he: "תחרות מונופוליסטית",
  en: "Monopolistic competition"
 },
 {
  ar: "منافسة حادة",
  he: "תחרות עזה",
  en: "Brisk competition"
 },
 {
  ar: "منافسة حرة",
  he: "תחרות חופשית",
  en: "Free competition"
 },
 {
  ar: "منافسة غير كاملة",
  he: "תחרות בלתי משוכללת",
  en: "Imperfect competition"
 },
 {
  ar: "منافسة كاملة",
  he: "תחרות משוכללת",
  en: "Perfect competition"
 },
 {
  ar: "منافسة نشطة",
  he: "תחרות עזה",
  en: "Brisk competition"
 },
 {
  ar: "مناقصة",
  he: "מכרז",
  en: "Tender"
 },
 {
  ar: "منتج",
  he: "יצרן",
  en: "Producer"
 },
 {
  ar: "منتج",
  he: "מוצר\/ תוצר",
  en: "Good\/ product"
 },
 {
  ar: "منتج ثانوي ",
  he: "מוצר לוואי",
  en: "By- product"
 },
 {
  ar: "منتج جانبي",
  he: "מוצר לוואי",
  en: "By- product"
 },
 {
  ar: "منتج فرعي",
  he: "מוצר לוואי",
  en: "By- product"
 },
 {
  ar: "منتج مشترك",
  he: "מוצר משולב",
  en: "Joint product"
 },
 {
  ar: "منتج من الدرجة الثانية",
  he: "מוצר סוג ב",
  en: "second"
 },
 {
  ar: "منتج نهائي",
  he: "מוצר מוגמר",
  en: "End product\/ final product\/ finished good"
 },
 {
  ar: "منتج وسيط",
  he: "מוצר ביניים",
  en: "Intermediate product"
 },
 {
  ar: "منتوج",
  he: "מוצר",
  en: "Product\/ good"
 },
 {
  ar: "منحة",
  he: "מענק",
  en: "Grant"
 },
 {
  ar: "منشأة متعددة الانتاج",
  he: "חברה רב- מוצרית",
  en: "Multi- product firm"
 },
 {
  ar: "منصب",
  he: "משרה",
  en: "Post\/ job"
 },
 {
  ar: "منصب شاغر",
  he: "משרה פנויה",
  en: "vacancy"
 },
 {
  ar: "منطقة التجارة الحرة",
  he: "אזור סחר חופשי",
  en: "Free trade area\/ free trade zone"
 },
 {
  ar: "منطقة تنمية",
  he: "אזור פיתוח",
  en: "Development area"
 },
 {
  ar: "منظم العمل",
  he: "יזם",
  en: "entrepreneur"
 },
 {
  ar: "منظمة البلدان المصدرة للبترول",
  he: "ארגון המדינות המיצאות נפט",
  en: "Organization of petroleum exporting countries"
 },
 {
  ar: "منظمة التعاون الاقتصادي والتنمية ",
  he: "ארגון לשיתוף פעולה ולפיתוח כלכלי",
  en: "Organization for economic cooperation and development"
 },
 {
  ar: "منظمة امامية",
  he: "חברצ קש",
  en: "Front organization"
 },
 {
  ar: "منظمة عمالية",
  he: "ארגון עובדים",
  en: "Labour organization"
 },
 {
  ar: "منفعة",
  he: "תועלת",
  en: "Utility\/ benefit"
 },
 {
  ar: "منفعة حدية",
  he: "תועלת שולית",
  en: "Marginal utility"
 },
 {
  ar: "مهلة الدفع",
  he: "ארכה לתשלום",
  en: "Grace period"
 },
 {
  ar: "مهنة",
  he: "מקצוע",
  en: "profession"
 },
 {
  ar: "مهندس الانتاج",
  he: "מהנדס ייצור",
  en: "Production engineer"
 },
 {
  ar: "مهندس زراعي",
  he: "אגרונום",
  en: "Agronomist"
 },
 {
  ar: "موارد المال",
  he: "מקורות הכסף\/ היצע כסף",
  en: "Money supply"
 },
 {
  ar: "موارد طبيعية",
  he: "משאבים טבעיים",
  en: "Natural resources"
 },
 {
  ar: "موازنة",
  he: "תקציב",
  en: "Budget"
 },
 {
  ar: "موازنة المشتريات",
  he: "תקציב רכישה",
  en: "Purchasing budget"
 },
 {
  ar: "موازنة وظيفية",
  he: "תקציב ייעודי",
  en: "Functional budget"
 },
 {
  ar: "مواصفة",
  he: "מיפרט",
  en: "Specification"
 },
 {
  ar: "موثق الشركات",
  he: "רשם חברות",
  en: "Registrar of companies"
 },
 {
  ar: "موجودات الشركة",
  he: "נכסים",
  en: "Assets"
 },
 {
  ar: "مودع",
  he: "מפקיד",
  en: "Depositor"
 },
 {
  ar: "مودع في مستودع الجمرك",
  he: "מאוחסן במחסן ערובה",
  en: "In bond"
 },
 {
  ar: "موراتوريوم",
  he: "מורטוריום\/ דחיית פרעון חובות",
  en: "Moratorium"
 },
 {
  ar: "مورد",
  he: "משאב",
  en: "Resource"
 },
 {
  ar:  "مورد",
  he: "ספק",
  en: "supplier"
 },
 {
  ar: "موظف",
  he: "עובד\/ מועסק\/ שכיר",
  en: "Worker\/ employee"
 },
 {
  ar: "موظف",
  he: " פקיד",
  en: "Clerk "
 },
 {
  ar: "موظف جمركي",
  he: "פקיד מכס",
  en: "Customs officer"
 },
 {
  ar: "موظف حكومي",
  he: "עובד מדינה",
  en: "Government employee\/ civil servant"
 },
 {
  ar: "موظف كامل الدوام",
  he: "עובד במשרה מלאה",
  en: "Full time employee"
 },
 {
  ar: "موظف مأجور",
  he: "מועסק בשכר",
  en: "Salaried employee"
 },
 {
  ar: "موظف مدني",
  he: "עובד מדינה",
  en: "Government employee\/ civil servant"
 },
 {
  ar: "موظفون",
  he: "פקידות\/ צוות עובדים",
  en: "staff"
 },
 {
  ar: "ميزان",
  he: "מאזן",
  en: "Balance\/ balance sheet"
 },
 {
  ar: "ميزان الاختبار",
  he: "מאזן בוחן",
  en: "Trial balance"
 },
 {
  ar: "ميزان تجاري",
  he: "מאזן מסחרי\/ מאזן סחר חוץ",
  en: "Trade balance"
 },
 {
  ar: "ميزان عاجز",
  he: "מאזן שלילי",
  en: "Adverse balance"
 },
 {
  ar: "ميزان مدفوعات",
  he: "מאזן תשלומים",
  en: "Balance of payments"
 },
 {
  ar: "ميزان مدفوعات رابح",
  he: "מאזן תשלומים חיובי",
  en: "Active balance of payments"
 },
 {
  ar: "ميزان مدفوعات نشط",
  he: "מאזן תשלומים חיובי",
  en: "Active balance of payments"
 },
 {
  ar: "ميزان مدين",
  he: "מאזן שלילי",
  en: "Adverse balance"
 },
 {
  ar: "ميزانية",
  he: "תקציב",
  en: "budget"
 },
 {
  ar: "ميزانية الدولة",
  he: "תקציב המדינה",
  en: "State budget\/ national budget"
 },
 {
  ar: "ميزانية المبيعات",
  he: "תקציב מכירות",
  en: "Sales budget"
 },
 {
  ar: "ميزانية النقد",
  he: "תקציב מזומנים",
  en: " Cash budget "
 },
 {
  ar: "ميزانية رأس المال",
  he: "תקציב פיתוח",
  en: "Capital budget"
 },
 {
  ar: "ميزانية سنوية",
  he: "תקציב שנתי",
  en: "Annual budget"
 },
 {
  ar: "ميزانية عامة",
  he: "מאזן",
  en: "Balance\/ balance sheet"
 },
 {
  ar: "ميزانية عمومية",
  he: "מאזן",
  en: "Balance\/ balance sheet"
 },
 {
  ar: "ميزانيةغير متوازنة",
  he: "תקציב בלתי מאוזן",
  en: "Unbalanced budget"
 },
 {
  ar: "ميزانية متوازنة",
  he: "תקציב מאוזן",
  en: "Balanced budget"
 },
 {
  ar: "ميزانية مرنة",
  he: "תקציב גמיש",
  en: "Flexible budget"
 },
 {
  ar: "ميزانية مؤقتة",
  he: "תקציב ביניים",
  en: "Interim budget"
 },
 {
  ar: "ميناء حر",
  he: "נמל חופשי",
  en: "Free port"
 },
 
 {
  ar: "نائب رئيس",
  he: "סגן נשיא",
  en: "Vice president"
 },
 {
  ar: "ناتج",
  he: "מוצר",
  en: "Good\/ product"
 },
 {
  ar: "نادي بريس",
  he: "מועדון פריס",
  en: "Paris club"
 },
 {
  ar: "نادي لندن",
  he: "מודון לונדון",
  en: "London club"
 },
 {
  ar: "ناقلة يترول",
  he: "מכלית נפט",
  en: "Tanker"
 },
 {
  ar: "نزع الملكية",
  he: "נישול",
  en: "Eviction"
 },
 {
  ar: "نسبة",
  he: "ערך\/ יחס\/ שיעור",
  en: "Rate\/ ratio"
 },
 {
  ar: "نسبة التبادل الدولية",
  he: "תנאי סחר",
  en: "Terms of trade"
 },
 {
  ar: "نسبة الزيادة",
  he: "שיעור העלייה",
  en: "Rate of increase"
 },
 {
  ar: "نسبة السيولة",
  he: "יחס הנזילות",
  en: "Liquidity ratio\/ cash ratio"
 },
 {
  ar: "نسبة السيولة",
  he: "שיעור נזילות",
  en: "Liquidity rate "
 },
 {
  ar: "نشاط اقتصادي",
  he: "פעילות כלכלית",
  en: "Economic activity"
 },
 {
  ar: "نشرة الاكتتاب",
  he: "תשקיף",
  en: "Prospectus"
 },
 {
  ar: "نشرة اولية",
  he: "תשקיף",
  en: "Prospectus"
 },
 {
  ar: "نظام اقتصادي",
  he: "שיטה כלכלית",
  en: "Economic system"
 },
 {
  ar: "نظام الاحتياطي الفدرالي",
  he: "מערכת העתודות הפדראליות",
  en: "Federal reserve system"
 },
 {
  ar: "نظام نقدي",
  he: "מערכת מוניטארית",
  en: "Monetary system"
 },
 {
  ar: "نظم",
  he: "ויסות",
  en: "Control\/ regulation"
 },
 {
  ar: "نفاد",
  he: "אזילה",
  en: "Depletion"
 },
 {
  ar: "نفط",
  he: "נפט",
  en: "Oil"
 },
 {
  ar: "نفقات ادارية",
  he: "הוצאות ניהול",
  en: "Administrative expenses"
 },
 {
  ar: "نفقات البيع",
  he: "הוצאות מכירה",
  en: "Selling costs"
 },
 {
  ar: "نفقات التشغيل",
  he: "הוצאות תפעוליות",
  en: "Operating costs"
 },
 {
  ar: "نفقات التعبئة",
  he: "דמי טיפול",
  en: "Handling charges"
 },
 {
  ar: "نفقات التفريغ",
  he: "הוצאות פריקה",
  en: "Unloading charges"
 },
 {
  ar: "نفقات الخصم",
  he: "הוצאות ניכיון",
  en: "Discount charges"
 },
 {
  ar: "نفقات السفر",
  he: "הוצאות נסיעה",
  en: "Travel expenses"
 },
 {
  ar: "نفقات المحكمة",
  he: "הוצאות משפט",
  en: "Court costs"
 },
 {
  ar: "نفقات النقل",
  he: "הוצאות הובלה",
  en: "Transportation charges"
 },
 {
  ar: "نفقات اولية",
  he: "הוצאות ראשוניות",
  en: "Preliminary expenses"
 },
 {
  ar: "نفقات راسمالية",
  he: "הוצאות הון",
  en: "Capital expenditures"
 },
 {
  ar: "نفقات عامة",
  he: "הוצאות הממשלה",
  en: "Public expenditure"
 },
 {
  ar: "نفقات عامة",
  he: "הוצאות כלליות",
  en: "General expenses "
 },
 {
  ar: "نفقات فعلية",
  he: "הוצאות בפועל",
  en: "Actual expenses"
 },
 {
  ar: "نفقات مباشرة",
  he: "הוצאות ישירות  ",
  en: "Direct charges"
 },
 {
  ar: "نفقات متجمعة",
  he: "הוצאות נצברות",
  en: "Accrued expenses"
 },
 {
  ar: "نفقة",
  he: "הוצאה",
  en: "Outlay\/ expenditure\/ expense"
 },
 {
  ar: "نفقة",
  he: "עלות",
  en: "cost"
 },
 {
  ar: "نفقة الارتفاق",
  he: "עלות השימוש",
  en: "User cost"
 },
 {
  ar: "نفقة الاستخدام",
  he: "עלות השימוש",
  en: "User cost"
 },
 {
  ar: "نفقة دائمة",
  he: "הוצאה קבועה",
  en: "Standing charge"
 },
 {
  ar: "نفقة عامة",
  he: "הוצאה ציבורית",
  en: "Public expenditure"
 },
 {
  ar: "نفقة مؤجلة",
  he: "חיוב נדחה",
  en: "Deferred charge"
 },
 {
  ar: "نقابة",
  he: "איגןד",
  en: "Guild\/ gild"
 },
 {
  ar: "نقابة",
  he: "איגוד מקצועי",
  en: "Labour union"
 },
 {
  ar:"نقابة",
  he: "סינדיקט",
  en: "syndicate"
 },
 {
  ar: "نقد",
  he: "כסף מזומן",
  en: "Cash"
 },
 {
  ar: "نقد",
  he: "מטבע",
  en: "Currency"
 },
 {
  ar: "نقدي",
  he: "כספי",
  en: "Financial"
 },
 {
  ar: "نقص",
  he: "מחסור",
  en: "Shortage"
 },
 {
  ar: "نقص القيمة المعجل",
  he: "פחת מואץ",
  en: "Accelerated depreciation"
 },
 {
  ar: "نقص القيمة بالاستعمال",
  he: "פחת",
  en: "depreciation"
 },
 {
  ar: "نقطة",
  he: "נקודה",
  en: "Point"
 },
 {
  ar: "نقطة التشبع",
  he: "נקודת רוויה",
  en: "Saturation point"
 },
 {
  ar: "نقل",
  he: "משלוח ",
  en: "Delivery\/ shipment"
 },
 {
  ar: "نقل",
  he: "העברה",
  en: "Transfer\/conveyance"
 },
 {
  ar: "نقود قابلة للتحويل",
  he: "כסף בר המרה",
  en: "Convertible money"
 },
 {
  ar: "نمو اقتصادي",
  he: "צמיחה כלכלית",
  en: "Economic growth"
 },
 {
  ar: "نهب",
  he: "שוד",
  en: "Robbery"
 },
 {
  ar: "نوبة ليلة",
  he: "משמרת לילה",
  en: "Night shift"
 },
 {
  ar: "نوعية",
  he: "איכות",
  en: "quality"
 },
 
 {
  ar: "هبة",
  he: "תרומה",
  en: "Contribution\/ donation"
 },
 {
  ar: "هبوط",
  he: "ירידה",
  en: "Decline"
 },
 {
  ar: "هبوط الاسعار",
  he: "נפילת מחירים",
  en: "Fall in prices"
 },
 {
  ar: "هبوط القيمة المعجل",
  he: "פחת מואץ",
  en: "Accelerated depreciation"
 },
 {
  ar: "هبوط القيمة بالاستعمال",
  he: "פחת",
  en: "depreciation"
 },
 {
  ar: "هبوط قيمة العملة",
  he: "פיחות",
  en: "Depreciation\/ devaluation"
 },
 {
  ar: "هجرة الرساميل",
  he: "נדידת הון",
  en: "Exodus of capital"
 },
 {
  ar: "هدية",
  he: "מתנה",
  en: "Gift"
 },
 {
  ar: "هروب الراسمال",
  he: "בריחת הון",
  en: "Flight of capital"
 },
 {
  ar: "هندسة صناعية",
  he: "הנדסת תעשייה",
  en: "Industrial engineering"
 },
 {
  ar: "هيئة لا تستهدف الربح",
  he: "מוסד ללא כוונת רווח",
  en: "Non- profit association\/"
 },
 {
  ar: "هيئة لا تستهدف الربح",
  he: "מוסד ללא כוונת רווח",
  en: " non- profit organization"
 },
 {
  ar: "هيكل اساسي",
  he: "תשתית",
  en: "infrastructure"
 },
 
 {
  ar: "واجهة عرض",
  he: "חלון ראווה",
  en: "Display window"
 },
 {
  ar: "وارث",
  he: "יורש",
  en: "Heir"
 },
 {
  ar: "واردات",
  he: "סחורות יבוא",
  en: "Imports"
 },
 {
  ar: "واردات معفاة من الرسوم",
  he: "סחורות המותרות ביבוא חופשי",
  en: "Free imports"
 },
 {
  ar: "وثيقة",
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "وحدة العملة الاوروبية",
  he: "יחידת המטבע האירופית",
  en: "European currency unit"
 },
 {
  ar: "وديعة",
  he: "הפקדה",
  en: "Deposit"
 },
 {
  ar: "وديعة حاضرة",
  he: "הפקדה במזומן",
  en: "Cash Deposit "
 },
 {
  ar: "وديعة لأجل",
  he: "פקדון לזמו קצוב",
  en: "Time deposit"
 },
 {
  ar: "وديعة للاستيراد",
  he: "הפקדת יבוא",
  en: "Import deposit"
 },
 {
  ar: "وديعة مصرفية",
  he: "פיקדון בבנק",
  en: "Bank deposit"
 },
 {
  ar: "وراثة",
  he: "ירושה",
  en: "Inheritance"
 },
 {
  ar: "ورشة",
  he: "בית מלאכה",
  en: "workshop"
 },
 {
  ar: "ورقة تجارية مقبولة",
  he: "מסמך מסחרי מתאים",
  en: "Eligible commercial"
 },
 {
  ar:"ورقة تجارية مقبولة",
  he:  "מסמך מסחרי מתאים",
  en: "paper"
 },
 {
  ar: "ورقة مالية",
  he: "בטוחה\/ נייר ערך",
  en: "security"
 },
 {
  ar: "ورقة مصرفية",
  he: "בנקנוט\/ שטר בנקנוט\/ ",
  en: "Bank paper\/ banknote"
 },
 {
  ar:"ورقة مصرفية",
  he: "שטר כסף",
  en: "Bank bill\/ currency note\/"
 },
 {
  ar:"ورقة مصرفية",
  he: "שטר כסף",
  en: "Paper money"
 },
 {
  ar: "ورقة نقدية",
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "وزارة الاسكان",
  he: "משרד השיכון",
  en: "Ministry of housing"
 },
 {
  ar: "وزارة الاشغال العامة",
  he: "משרד לעבודות ציבוריות",
  en: "Ministry of public works"
 },
 {
  ar: "وزارة الاقتصاد",
  he: "משרד הכלכלה",
  en: "Ministry of economy"
 },
 {
  ar: "وزارة الاقتصاد الوطني",
  he: "משרד הכלכלה הלאומית",
  en: "Ministry of national economy "
 },
 {
  ar: "وزارة التجارة",
  he: "משרד המסחר",
  en: "Ministry of commerce"
 },
 {
  ar: "وزارة التخطيط",
  he: "משרד התכנון",
  en: "Ministry of planning"
 },
 {
  ar: "وزارة الخزينة",
  he: "משרד האוצר",
  en: "Exchequer\/ treasury\/"
 },
 {
  ar: "وزارة الخزينة",
  he: "משרד האוצר",
  en: "Ministry of finance"
 },
 {
  ar: "وزارة الزراعة",
  he: "משרד החקלאות",
  en: "Ministry of agriculture"
 },
 {
  ar: "وزارة السياحة",
  he: "משרד התיירות",
  en: "Ministry of tourism"
 },
 {
  ar: "وزارة الشؤون الاجتماعية",
  he: "משרד הרווחה",
  en: "Ministry of social affairs"
 },
 {
  ar: "وزارة الصناعة",
  he: "משרד התעשייה",
  en: "Ministry of industry"
 },
 {
  ar: "وزارة العمل",
  he: "משרד העבודה",
  en: "Ministry of labour"
 },
 {
  ar: "وزارة المال",
  he: "משרד האוצר",
  en: "Exchequer\/ treasury\/"
 },
 {
  ar: "وزارة المال",
  he: "משרד האוצר",
  en: "Ministry of finance"
 },
 {
  ar: "وزارة المالية",
  he: "משרד האוצר",
  en: "Exchequer\/ treasury\/"
 },
 {
  ar:"وزارة المالية",
  he: "משרד האוצר",
  en: "Ministry of finance"
 },
 {
  ar: "وزن اجمالي",
  he: "משקל ברוטו",
  en: "Gross weight"
 },
 {
  ar: "وزن صافي",
  he: "משקל נקי",
  en: "Net weight"
 },
 {
  ar: "وزير الخزانة",
  he: "שר האוצר",
  en: "Minister of finance\/"
 },
 {
  ar: "وزير الخزانة",
  he:"שר האוצר",
  en: "Chancellor of the exchequer"
 },
 {
  ar: "وزير المالية",
  he: "שר האוצר",
  en: "Minister of finance\/"
 },
 {
  ar:  "وزير المالية",
  he: "שר האוצר",
  en: "Chancellor of the exchequer"
 },
 {
  ar: "وسائل الدفع",
  he: "אמצעי תשלום",
  en: "Means of payment"
 },
 {
  ar: "وسطاء ماليون",
  he: "מתווכים פינאנסיים",
  en: "Financial intermediaries"
 },
 {
  ar: "وسيط",
  he: "מתווך",
  en: "Broker"
 },
 {
  ar: "وسيط جمركي",
  he: "עובד במכס",
  en: "Customs broker"
 },
 {
  ar: "وسيط خارج البورصة",
  he: "ברוקר העוסק במסחר בנירות",
  en: "Curb broker"
 },
 {
  ar:  "وسيط خارج البورصة",
  he: "ערך שאינם רשומים",
  en:  "Curb broker"
 },
 {
  ar: "وصية",
  he: "צוואה",
  en: "will"
 },
 {
  ar: "وصية شفوية ",
  he: "צוואה בעל- פה",
  en: "Nuncupation will"
 },
 {
  ar: "وضع اليد الفعلي",
  he: "החזקה בפועל",
  en: "Actual possession"
 },
 {
  ar: "وضيعة",
  he: "הפקדה",
  en: "Deposit"
 },
 {
  ar: "وظيفة",
  he: "משרה",
  en: "Job"
 },
 {
  ar: "وظيفة لبعض الوقت",
  he: "משרה חלקית",
  en: "Discharge"
 },
 {
  ar: "وفرة دولارية",
  he: "גלישת דולרים",
  en: "Dollar glut"
 },
 {
  ar: "وقت اضافي",
  he: "שעות נוספות",
  en: "overtime"
 },
 {
  ar: "وقود",
  he: "דלק",
  en: "Fuel"
 },
 {
  ar: "وكالة",
  he: "סוכנות",
  en: "Agency"
 },
 {
  ar: "وكيل",
  he: "סוכן",
  en: "Agent"
 },
 {
  ar: "وكيل المشتريات",
  he: "קניין",
  en: "Buyer"
 },
 {
  ar: "وكيل عام",
  he: "סוכן כללי",
  en: "General agent"
 },
 {
  ar: "وكيل تأمين",
  he: "סוכן ביטוח",
  en: "Insurance agent"
 },
 {
  ar: "وكيل وحيد",
  he: "סוכן בלעדי",
  en: "Exclusive agent"
 },
 
 {
  ar: "يدوي",
  he: "ידני",
  en: "Manual"
 },
 {
  ar: "يمكن تحويلة",
  he: "ניתן להעברה\/ ניתן להמרה",
  en: "Assignable"
 },
 {
  ar: "يوم التسوية",
  he: "יום התשלום",
  en: "Settlement day"
 },
 {
  ar: "يوم التصفية",
  he: "יום התשלום",
  en: "Settlement day"
 },
 {
  ar: "يوم القيمة",
  he: "יום ערך",
  en: "Value day"
 },
 {
  ar: "يوم عمل",
  he: "יום עבודה",
  en: "Working day\/ workday"
 },
 
]
export default full ;