/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { AiOutlineFilePdf, AiOutlineLink, AiFillCalculator, AiOutlineCalculator } from "react-icons/ai";
import { BiLock } from 'react-icons/bi';
import { massportallogo } from '../../../../Entryfile/imagepath';
class ShaamTaxHe extends Component {

  render() {
    return (
      <div >

        {/* Page Content */}
        <div >


          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <h2 className='links-title text-center '> דיווחים שוטפים</h2>
                  <div className="roles-menu">
                    <ul>
                      <li >
                        <a href="https://secapp.taxes.gov.il/GMMIKDAMA/firstPage.aspx" target='_blank'>מקדמות מס הכנסה
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />                             
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/NIKPAY/#/Home" target='_blank'>דיווח ניכויים
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/EMHANDOCH/STARTPAGEINTERNET.ASPX" target='_blank'>דיווח תקופתי למע"מ
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                              <BiLock size={20} className='matrial-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/EMDVHMFRT/WLOGONMENU.ASPX" target='_balnk'>דוח מפורט למע"מ
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                              <BiLock size={20} className='matrial-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/EMSLRDIVUH/knisa_doch.aspx#no-back-button" target='_blank'>החזר בלו על סולר
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                              <BiLock size={20} className='matrial-icons' />
                            </span>
                          </span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <h2 className='links-title text-center '> דיווחים שנתיים </h2>
                  <div className="roles-menu">
                    <ul>
                      <li >
                        <a href="https://secapp.taxes.gov.il/SHDOCHSHANA1301/frmMenu.aspx" target='_blank'>דוח שנתי למס הכנסה ( 1301 )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/SHDOCHSHANASHORT/FRMLOGIN.ASPX" target='_blank'>דוח שנתי מקוצר ( מס הכנסה )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/SH6111/WebForm1.aspx" target='_blank'>דוחות כספיים ( 6111 )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/SHDOCHNIK/frmMenu.aspx" target='_blank'>דוחות ניכויים ( 856 \ 126 )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/EMPATUR/STARTPAGEINTERNET.ASPX" target='_blank'>הצהרת עוסק פטור למע"מ
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                              <BiLock size={20} className='matrial-icons' />
                            </span>
                          </span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <h2 className='links-title text-center '> תשלומי חובות</h2>
                  <div className="roles-menu">
                    <ul>
                      <li >
                        <a href="https://secapp.taxes.gov.il/GMHOVOTMH/FIRSTPAGE.ASPX" target='_blank'>מס הכנסה
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/NIKPAYHOVOT/#/home" target='_blank'>ניכויים
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/EMHANPAYMENT/HAZANATPIRTEYTASHLUM.ASPX" target='_blank'>מע"מ
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/GMHOVOTMH/FIRSTPAGESCHARDIRA.ASPX" target='_blank'>מס מופחת בגין שכר דירה ( % 10  )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/KMPAYMENTNEW/FIRSTPAGE.ASPX" target='_blank'>תשלום קנס מנהלי
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>


          </div>


        </div>
        {/* /Page Content */}



      </div>
    );
  }
}

export default ShaamTaxHe;
