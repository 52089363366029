/**
 * TermsCondition Page
 */
import React, { Component, useState , useEffect} from 'react';
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { massportallogo } from '../../../../Entryfile/imagepath';
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { projectFirestore } from "../../../../firebase/config";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
export default function RashamC() {

  const printRef = React.useRef();
  const [isActive, setIsActive] = useState(false);
  // HOOKS
  const { user } = useAuthContext()
  const [companyName, setCompanyName] = useState(' ')
  const [compName, setCompName] = useState(" ")
  const [searchTerm, setSearchTerm] = useState("");
  const [details, setDetails] = useState("");
  const apiURL = 'https://data.gov.il/api/3/action/datastore_search?resource_id=f004176c-b85f-4542-8901-7b3176f9a054&q=';

  // Search by song or artist
  async function searchSongs(term) {
    const res = await fetch(`${apiURL}+${term}`);
    const data = await res.json();
    // console.log("🚀 ~ ~ data", data)

    const record = data.result.records;

    // console.log("🚀 ~ record", record[0])

    const obj = record[0];
    // console.log(" ~ obj", obj)

    const entries = Object.entries(obj);
    setDetails(entries);
    // console.log("🚀 ~ details", details);


    Object.entries(obj).forEach(entry => {
      const [key, value] = entry;
      console.log(key, value);
      if (key =="שם חברה")
      {
        setCompName(value)
        console.log("Company Name is : ", value)
      }
    });  


    //  showData(entries);
  }
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (!searchTerm) {
      alert('Please type in a search term');
    } else {
      searchSongs(searchTerm);
    }

    // console.log("Function Call Here"); 
    // console.log("text Value : ", searchTerm);

  }

  
  //  PDF Prin Function 
  const handleDownloadPdf = async () => {

    const element = printRef.current;
    element.style.background = '#FFFFFF';
    element.style.color = '#212529';

    const iaactive = true;
    await setIsActive(iaactive);
    //  Current Date 
    const d = new Date().toLocaleString() + "";
    let current = d.split(',');
    const today = current[0];
    const todaySplitArr = today.split('/');
    const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
    // Current Date 

    // PDF Header And Footer Values 
    const header = todayFrm;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    //  Header  - Logo
    pdf.addImage(massportallogo, 'png', 90, 1, 20, 20);

    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 22, pdfWidth, pdfHeight);

    // Header - Date 
    pdf.text(header, 2, 26, { baseline: 'top' });

    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const footer = d + '             www.mass-portal.com';
    // Footer
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight - 2, { baseline: 'bottom' });
    pdf.setFontSize(20);
    pdf.save('مراقب الشركات  .pdf');
  };
//  getCompanyName();
  // const {docc , errr} = useDocument('mp-clients', docId)
  useEffect(() => {
    const getCompanyName = () => {
      if (user != null) {

        const mydata = projectFirestore.collection('mp-clients').doc(user.uid).get().then((docRef) => { setCompanyName(docRef.data().companyName) })
          .catch((error) => { })
      }
      else
        setCompanyName(" ")
    }

    getCompanyName();
  })
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Mass Portal - Rasham</title>
        <meta name="description" content="Reactify Blank Page" />
      </Helmet>
      {/* Page Content */}
      <div className="content container-fluid">
        <div className="small-container">
          <div className="inner-header text-center">
            <br></br>
            <br></br>

            <h1>مراقب الشركات</h1>


            <p className="privacy-bred"><a href="/">الرئيسية</a></p>

            <br></br>
             

          </div>


          <div className="row">

            <div className="col-sm-12">
              <p className='comp-content'>
              للبحث عن  تفاصيل  شركة يمكنك  البحث عن طريق اسم الشركة او الرقم .
 أداة بسيطة وسهلة الاستخدام ،  نتيجة البحث باللغة العبرية<span className='privacy'> ( עברית ) </span>.
يتم تقديم هذه الخدمة <span className='privacy'> مجانًا </span> ، ويتم تحديث المعلومات الواردة فيها بانتظام من مسجل الشركات.
تتضمن المعلومات المقدمة معلومات تتعلق بالشركات ومؤسسات النفع العام<span className='privacy'> (חל"צ ) </span>.
              </p>
                    <br></br>
          
              <div>
                <p>
                  
                </p>
              </div>
        
              <form onSubmit={handleSubmit} className="form card " >

                <div className="card-body">

                  <div className="form-gronum row ">
                    <br></br>
                    <label className=" col-form-label comp-control">رقم الشركة أو اسمها</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control comp-control"
                        name="compName"
                        placeholder="أدخل رقم الشركة أو اسمها ..."
                        required
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        الرجاء ادخال رقم الشركة أو اسمها.
                      </div>
                      <br />




                    </div>
                  </div>
                </div>
                <br />
                <div className="text-center">
                  <button type="submit" className="btn btn-primary calc-button">ابحث </button>
                </div>
                <br></br>
              </form>
              <br></br>
              {details && (
                <div className='container'>
                  <div className='row'>
                <div className="col-sm-12 col-md-10">


                </div>
                {/* <div className="col-sm-12 col-md-1"></div> */}
                <div className="col-sm-12 col-md-2">
                  <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                    <AiOutlineFilePdf size={40} className='privacy' />
                  </button>
                  <button className="btn " data-tip data-for="excel-tip">
                    <SiMicrosoftexcel size={40} className='privacy' />
                  </button>
                  <ReactTooltip id="pdf-tip" place="top" effect="solid">
          طباعة PDF
        </ReactTooltip>
        <ReactTooltip id="excel-tip" place="top" effect="solid">
          طباعة Excel
        </ReactTooltip>
                </div>

              </div>
                <div className={isActive ? " card-white" : "card"} ref={printRef}>
                <br></br>
                <div className="row">
                
                  <div className="col-sm-12 col-md-4">
                    {/* <h4 className={isActive ? " privacy-black-bold text-left company-marg " : " privacy hide"} id='date-block'>
                      الشركة : <span className="privacy-black"> {user ? companyName : " "}  </span>
                    </h4> */}
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? "card-title mb-0 text-center privacy-black-bold " : "card-title mb-0 text-center  privacy"}>
                      مراقب الشركات 
                      <br></br>
                      <span className={isActive ? "privacy-black-bold" : "privacy-bold"}> 
                      {/* {compName} */}
                       </span>
                    </h4>
                  </div>
                  <div className='col-sm-12 col-md-4'>
                    <h4 className={isActive ? "privacy-black-bold text-center " : " privacy hide"} id='date-block'>
                      التاريخ
                    </h4>


                  </div>

                </div>
                    
                <div className={isActive?"card-body card-body-white" : "card-body "}>
                <div className="table-responsive">
                <table className="table table-striped mb-0 comp-table-cont">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                      {details.map((data) =>
                     (
                       <tr key={data[0]}>
                         <td className='comp-table m-l-15'>{data[0]}</td>
                         <td className='just-table ' >{data[1]}</td>
                       </tr>
                     ) )}
                    </tbody>

                 {/* 514763366 */}
                </table>
                </div>  </div>  </div> </div>
              )}
              {/* {details &&(
              <>
               {details.map((data) =>
            (
               
                      <div key={data[0]} className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                        <h2 className='privacy'> {data[0]}   </h2>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-6'>
                        <h2>   {data[1]} </h2>
                        </div>
                        <br></br>
                     
                      </div>
                      
                    ))}
              </>
            )} */}

             

            </div>

          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>

  );

}
