
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Avatar_11, Avatar_09, Avatar_02, Avatar_10, Avatar_05, Avatar_08 } from "../Entryfile/imagepath"

class Holidays extends Component {
   render() {
      return (
        
      <div className=""> 
     
      {/* Page Content */}
      <div className="">
        {/* Page Header */}
       
        {/* /Page Header */}
      
         

            <div className="table-responsive">
              <table className="table table-striped custom-table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>نوع الاجازة </th>
                    <th>رصيد افتتاحي</th>
                    <th>استحقاق شهري</th>
                    <th>استغلال</th>
                    <th className="text-right">رصيد</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="holiday-completed">
                    <td>1</td>
                    <td>مرضية</td>
                    <td>10 </td>
                    <td>1.5</td>
                    <td>1</td>
                   <td>10.5</td>
                  </tr>
                  <tr className="holiday-completed">
                    <td>2</td>
                    <td>سنوية</td>
                    <td>10</td>
                    <td>1</td>
                    <td>2</td>
                    <td>9</td>
                  </tr>
                  <tr className="holiday-completed">
                    <td>3</td>
                    <td>نقاهة</td>
                    <td>5.75 </td>
                    <td>2</td>
                    <td>0</td>
                   <td>7.75</td>
                  </tr>
                  {/* <tr className="holiday-completed">
                    <td>3</td>
                    <td>مرضية</td>
                    <td>10 </td>
                    <td>1.5</td>
                    <td>0</td>
                   <td>11.5</td>
                  </tr>
                  <tr className="holiday-completed">
                    <td>2</td>
                    <td>سنوية</td>
                    <td>12</td>
                    <td>2</td>
                    <td>3</td>
                    <td>11</td>
                  </tr>
                  <tr className="holiday-completed">
                    <td>3</td>
                    <td>مرضية</td>
                    <td>10 </td>
                    <td>1.5</td>
                    <td>0</td>
                   <td>10.5</td>
                  </tr>
                  <tr className="holiday-completed">
                    <td>2</td>
                    <td>سنوية</td>
                    <td>10</td>
                    <td>1.5</td>
                    <td>2</td>
                    <td>9.5</td>
                  </tr> */}
                
                </tbody>
              </table>
            </div>
          </div>
        
    
      {/* /Page Content */}
      
    </div>
        );
   }
}

export default Holidays;
