import React, { useState,} from "react";
import { formatMoney } from "accounting-js";
export function Tax23Calc(props) {
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [points, setPoints] = useState(0);
  const [tax, setTax] = useState(0);
  const [show,setShow] = useState(false);
  const [data,setData] = useState ([]);
  let pricePoint = 235;
  let num = [
    6790,
    9730,
    15620,
    21710,
    45180,
    58190];

  let percentage =[
    0.1,
    0.14,
    0.2,
    0.31,
    0.35,
    0.47,
    0.5];

    let np =[
    (num[0])*percentage[0],
    (num[1]-num[0])*percentage[1],
    (num[2]-num[1])*percentage[2],
    (num[3]-num[2])*percentage[3],
    (num[4]-num[3])*percentage[4],
    (num[5]-num[4])*percentage[5]];
  
 

  const handleSubmit = (evt) => {
      evt.preventDefault();
      let id = Math.random();
      if (monthlyIncome !=="")
      {
        setTax(calculateTax(parseFloat(monthlyIncome)));
        setShow(true)
      }

      function calculateTax (income) {
        /*                                                
        YEAR 2021                           YEAR 2022                          
        Price Point    218                  Price Point    223                        
        up to 6290     10%                  up to 6450     10%                     
        6290 => 9030   14%                  6290 => 9240   14%                       
        9030 => 14490  20%                  9240 => 14840  20%                        
        14490 => 20140 31%                  14840 => 20620 31%                         
        20140 => 41910 35%                  20620 => 42910 35%                    
        41910 => 53970 47%                  42910 => 55270 47%                         
        53970 => up    50%                  55270 => up    50%                           
        */                                                  
       
        let tax = 0;

        if(income > 0 && income <= num[0]){
          tax = (income) * percentage[0] - (points*pricePoint);
        }
        else if(income > num[0] && income <=num[1]){
          tax = ((income - num[0]) * percentage[1]) + (np[0]) - (points*pricePoint);
        }
        else if(income > num[1] && income <= num[2]){
          tax = ((income - num[1]) * percentage[2]) + (np[0]+np[1]) - (points*pricePoint);
        }
        else if(income > num[2] && income <= num[3]){
          tax = ((income - num[2]) * percentage[3]) + (np[0]+np[1]+np[2]) - (points*pricePoint);
        }
        else if(income > num[3] && income <= num[4]){
          tax = ((income - num[3]) * percentage[4]) + (np[0]+np[1]+np[2]+np[3]) - (points*pricePoint);
        }
        else if(income >num[4] && income <= num[5]){
          tax = ((income - num[4]) * percentage[5]) + (np[0]+np[1]+np[2]+np[3]+np[4]) - (points*pricePoint);
        }
        else if(income > num[5]){
          tax = ((income -num[5]) * percentage[6]) +  (np[0]+np[1]+np[2]+np[3]+np[4]+np[5]) - (points*pricePoint);
        }

        setData(prev =>[...prev,
          { id: id,
          tax: tax.toFixed(1),
          points:points,
          total : (monthlyIncome - tax).toFixed(1),
          monthlyIncome:monthlyIncome
          }])

          if(tax<0){
          tax = 0;
          }
        
      } 
     
  }
  const handleDelete=(id)=>{
    let newData = data.filter((data)=>
        data.id !== id )
        setData(newData)
        newData = [];
  }
  return (
    <div className="card tax-card">
     
      <div className="card-body eOfService">
    <form onSubmit={handleSubmit} >
    <div className="">
      <h3 className="card-title text-center"> احسب ضريبتك لعام <span className="privacy">2023</span> <span> - شهري </span></h3>
      <br></br>
      <br></br>
      <div className="form-gronum row">
              <div className="col-12">
                <label className=" col-form-label">الراتب الشهري</label>
               <br></br>
                <input type="text" className="form-control" name="monthlyincome" value={monthlyIncome}
                  onChange={(e) => setMonthlyIncome(e.target.value)} />
              <br></br>
              </div>

              <div className="col-12">



               
                <label className=" col-form-label">نقاط التزكية</label>
               <br></br>
                <div className="">
                  <input 
                    type="text" 
                    className="form-control m-b-0" 
                    name="points" 
                    value={points}
                    onChange={(e) => setPoints(e.target.value)} 
                  />


                </div>
              </div>
            </div>

  </div>
  <br/>
  <div className="text-center button-height">
    <button type="submit" className="btn btn-primary calc-button ">حساب</button>
  </div>
</form>
 
<br/><br/>

    { show && 
				  
            	<div className="card-body">
				
					  <h3 className="card-title">احتساب الضريبة الشهرية</h3>
					
				
					  <div className="table-responsive">
						<table className="table table-striped mb-0 ">
						  <thead>
							<tr>
                <th></th>
                <th>الضريبة المستحقة</th>
							  <th>نقاط التزكية</th>
							  <th>الراتب الشهري</th>
							  <th></th>
							</tr>
						  </thead>
						  <tbody>
							{
                // {formatMoney(salesSum, { symbol: "₪",  format: " %v %s" })}
                data.map((d,index)=>(
                  <tr key={index}>
                 <td>{index+1}</td>
                  <td>{formatMoney((d.tax),{ symbol: "₪",  format: " %v %s" })}</td>
                  <td>{d.points}</td>
                  <td>{formatMoney((d.monthlyIncome),{ symbol: "₪",  format: " %v %s" })}</td>
                  
                  <td onClick={()=>handleDelete(d.id)}>حذف</td>
                  </tr>
                ))}
						
						  </tbody>
						</table>
					  </div>
					</div>
				 
			}
    </div>
    </div>
  );
}