
import React, { Component, useState } from 'react';
import { formatMoney } from "accounting-js";
import { Helmet } from "react-helmet";
import { Avatar_01, Avatar_04, Avatar_05, Avatar_09, Avatar_10, Avatar_11, Avatar_12, Avatar_13, Avatar_16 } from "../../Entryfile/imagepath"
import { IoSwapHorizontalOutline } from "react-icons/io5";

import axios from "axios";
import { set } from 'react-hook-form';
export default function ExchngRate() {
  const [rateEl, setRateEl] = useState('');
  const [rate1, setRate1] = useState('');
  const [rate2, setRate2] = useState('');
  const [amountTwo, setAmountTwo] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [fromCurrency, setFromCurrency] = useState();
  const [toCurrency, setToCurrency] = useState();
  const [rateTo, setRateTo] = useState();
  const [currencyAp, setCap] = useState();
  const [currencyAp2, setCap2] = useState();
  function calculate() {
    const currency_one = document.querySelector('#currency-one').value;
    setCap(currency_one);
    const amountEl_one = document.querySelector('#amount-one').value;
    const currency_two = document.querySelector('#currency-two').value;
    
    
    const amountEl_two = document.querySelector('#amount-two').value;
    const myDiv = document.querySelector('#calcDiv');
    setCap2(currency_two);

    //  To render the values in a div  //

    var selectFrom = document.querySelector('#currency-one');
    var valueFrom = selectFrom.options[selectFrom.selectedIndex].text;
    setFromCurrency(valueFrom);

    var selectTo = document.querySelector('#currency-two');
    var valueTo = selectTo.options[selectTo.selectedIndex].text;
    setToCurrency(valueTo);

    //  To render the values in a div  //
    const urll6 = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/${currency_one}`;
    fetch(urll6)
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        const rate = data.conversion_rates[currency_two];
        // console.log("Rate From URL6 : ", rate);

        const rateeEll = `1 ${currency_one}   = ${rate.toFixed(4)} ${currency_two} `;
        setRateEl(rateeEll);
        setRateTo(rate.toFixed(4));

        var amountEl2 = (amountEl_one * rate).toFixed(2);

        setAmountTwo(amountEl2);
        setFrom(amountEl_one);
        setTo(amountEl2);

        // console.log("Amout Two Value : ", amountEl2);

      });

  }



  function printV() {
    const url = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/USD`;


    const currency_one = "USD";

    // const amountEl_one = document.querySelector('#amount-one').value;
    const currency_two = "ILS";

    // const amountEl_two = document.querySelector('#amount-two').value;
    try{

      const urll6 = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/${currency_one}`;
      fetch(urll6)
        .then(res => res.json())
        .then(data => {
          // console.log(data);
          const rate = data.conversion_rates[currency_two];
  
          const one = 1;
          // const rateeEll = ` 1 ${currency_one}   = ${rate.toFixed(4)} ${currency_two} `;
  
          const rateeEll = `   1${currency_one}    = ${rate.toFixed(4)} ${currency_two} `;
  
          setRate1(currency_one);
          setRate2(rate.toFixed(4) +" " + currency_two);
  
  
  
  
        });
    }catch(error){
      consoloe.log("ant fech :-(", error)
    }
  


  }


  printV();

  return (

    <div className="card ">
      {/* Page Header */}

      <div className=" card-body eOfService">
        <br></br>

        <h2 className="card-title text-center"> محول العملات  <span className="privacy">Online</span> </h2>
<br></br>
        <div className="row">


          <div className="col-sm-6 col-md-4">
            <h4 className="title "> تحويل من </h4>
            <div className=" form-group form-focus select-focus se-ex">
              <select
                id="currency-one"
                className='select floating ex-select '
                defaultValue={"USD"}
                onChange={() => calculate()}
              >
                <option value="AUD">  دولار استرالي ( AUD ) </option>
                <option value="BHD">دينار بحريني  ( BHD )</option>
                <option value="BEF">فرنك بلجيكي ( BEF ) </option>
                <option value="BRL">ريال برازيلي ( BRL ) </option>
                <option value="GBP">الجنيه الاسترليني ( GBP ) </option>
                <option value="CAD">دولار كندي ( CAD ) </option>
                <option value="CNY">اليوان الصيني ( CNY )</option>
                <option value="DKK">كرون دنماركي ( DKK )</option>
                <option value="EGP">الجنيه المصري ( EGP )</option>
                <option value="EUR">اليورو ( EUR )</option>
                <option value="DEM">المارك الألماني ( DEM )</option>
                <option value="INR">الروبية الهندية ( INR )</option>
                <option value="IQD">الدينار العراقي ( IQD )</option>
                <option value="ILS">شيكل إسرائيلي ( ILS )</option>
                <option value="ITL">ليرة ايطالية ( ITL )</option>
                <option value="JPY">الين الياباني ( JPY )</option>
                <option value="JOD">دينار أردني ( JOD )</option>
                <option value="KWD">دينار كويتي ( KWD )</option>
                <option value="LBP">ليرة لبنانية ( LBP )</option>
                <option value="LYD">دينار ليبي ( LYD )</option>
                <option value="MXN">بيزو مكسيكي ( MXN )</option>
                <option value="MAD">درهم مغربي ( MAD )</option>
                <option value="NZD">الدولار النيوزيلندي ( NZD )</option>
                <option value="OMR">ريال عماني ( OMR )</option>
                <option value="QAR">ريال قطري ( QAR )</option>
                <option value="RUB">روبل روسي ( RUB )</option>
                <option value="SAR">الريال السعودي ( SAR )</option>
                <option value="SYP">الليرة السورية ( SYP )</option>
                <option value="TND">دينار تونسي ( TND )</option>
                <option value="TRY">الليرة التركية ( TRY )</option>
                <option value="UAH">الهريفنيا الأوكراني ( UAH )</option>
                <option value="AED">درهم اماراتى ( AED )</option>
                <option value="USD">الدولار الأمريكي ( USD )</option>
                <option value="YER">ريال يمني ( YER )</option>
              </select>


              <br></br>
              <br></br>

            </div>
            <div className='form-group form-focus  d-flex ex-div'>
              <input type="number"
                id="amount-one"
                placeholder="أدخل المبلغ لتحويله"
                className='rate-input-style text-center privacy'
                onChange={() => calculate()}
              />

            </div>

          </div>




          <div className="col-sm-6 col-md-4">

            <h4 className="title ">   الى  </h4>
            <div className="form-group form-focus select-focus se-ex">
              <select id="currency-two"
                className='select floating ex-select'
                defaultValue={"ILS"}
                onChange={() => calculate}

              >
                <option value="AUD">  دولار استرالي ( AUD ) </option>
                <option value="BHD">دينار بحريني  ( BHD )</option>
                <option value="BEF">فرنك بلجيكي ( BEF ) </option>
                <option value="BRL">ريال برازيلي ( BRL ) </option>
                <option value="GBP">الجنيه الاسترليني ( GBP ) </option>
                <option value="CAD">دولار كندي ( CAD ) </option>
                <option value="CNY">اليوان الصيني ( CNY )</option>
                <option value="DKK">كرون دنماركي ( DKK )</option>
                <option value="EGP">الجنيه المصري ( EGP )</option>
                <option value="EUR">اليورو ( EUR )</option>
                <option value="DEM">المارك الألماني ( DEM )</option>
                <option value="INR">الروبية الهندية ( INR )</option>
                <option value="IQD">الدينار العراقي ( IQD )</option>
                <option value="ILS">شيكل إسرائيلي ( ILS )</option>
                <option value="ITL">ليرة ايطالية ( ITL )</option>
                <option value="JPY">الين الياباني ( JPY )</option>
                <option value="JOD">دينار أردني ( JOD )</option>
                <option value="KWD">دينار كويتي ( KWD )</option>
                <option value="LBP">ليرة لبنانية ( LBP )</option>
                <option value="LYD">دينار ليبي ( LYD )</option>
                <option value="MXN">بيزو مكسيكي ( MXN )</option>
                <option value="MAD">درهم مغربي ( MAD )</option>
                <option value="NZD">الدولار النيوزيلندي ( NZD )</option>
                <option value="OMR">ريال عماني ( OMR )</option>
                <option value="QAR">ريال قطري ( QAR )</option>
                <option value="RUB">روبل روسي ( RUB )</option>
                <option value="SAR">الريال السعودي ( SAR )</option>
                <option value="SYP">الليرة السورية ( SYP )</option>
                <option value="TND">دينار تونسي ( TND )</option>
                <option value="TRY">الليرة التركية ( TRY )</option>
                <option value="UAH">الهريفنيا الأوكراني ( UAH )</option>
                <option value="AED">درهم اماراتى ( AED )</option>
                <option value="USD">الدولار الأمريكي ( USD )</option>
                <option value="YER">ريال يمني ( YER )</option>


              </select>
              <br></br>
              <br></br>

            </div>
            <div className='form-group form-focus  d-flex ex-div'>

            <input type="text"
                id="amount-two"
                placeholder="0"
                className='rate-input-style text-center privacy'
                // onChange={() => calculate()}
                value={formatMoney(to?to:0,{ symbol: "",  format: " %v %s" })}
              />
            </div>
          </div>



          {/* ENDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD */}
          {/* <div className="col-sm-6 col-md-4 card-body" id='calcDiv'>
            <h4 className="title card-title  "  >   <br></br> سعر العملة  <span className='privacy '  dir='rtl'>{rate} </span> </h4>
            <br></br>
           

            <div className=' rate-style '  >
              <br></br>
              <h3 className='privacy'> {rateEl}  </h3>
            </div>
            <div>
              <h3>{from}</h3>
              <h3> {to} </h3>
            </div>


          </div> */}
          {from == null && (
  <div className="col-sm-12 col-md-4 d-flex">
  <div className=" flex-fill">
    <div className="card-body">
      <h4 className="card-title text-center">سعر العملة</h4>
      {/* <div className="statistics">
        <div className="row">
          <div className="col-md-5 col-5 text-center">
            <div className="stats-box mb-4">
              <p> {fromCurrency} </p>
              <h3>{from}</h3>
            </div>
          </div>
          <div className='col-md-2 col-2 text-center'>
            <h1 className='privacy'> = </h1>
          </div>
          <div className="col-md-5 col-5 text-center">
            <div className="stats-box mb-4">
              <p>{toCurrency}</p>
              <h3>{to}</h3>
            </div>
          </div>
        </div>
      </div> */}


    </div>
    <div className='  row'>
      <div className="col-md-5 col-5 text-center fix-style">
        <h2><i className="fa fa-dot-circle-o text-warning mr-2" />  {rate1}</h2>

      </div>
      <div className='col-md-2 col-2 text-center'>
        <h2 className='privacy'> = </h2>
      </div>
      <div className="col-md-5 col-5 text-center">
        <h2> {rate2} </h2>

      </div>
    </div>
  </div>

</div>
)}
          {from != null && (
            <div className="col-sm-12 col-md-4 d-flex">
              <div className=" flex-fill">
                <div className="card-body">
                  <h4 className="card-title">سعر العملة</h4>
                  <div className="statistics">
                    <div className="row">
                      <div className="col-md-5 col-5 text-center">
                        <div className="stats-box mb-4">
                          <p> {fromCurrency} </p>
                          <h3>{formatMoney(from,{ symbol: "",  format: " %v %s" })}</h3>
                        </div>
                      </div>
                      <div className='col-md-2 col-2 text-center'>
                        <h1 className='privacy'> = </h1>
                      </div>
                      <div className="col-md-5 col-5 text-center">
                        <div className="stats-box mb-4">
                          <p>{toCurrency}</p>
                          <h3>{formatMoney(to,{ symbol: "",  format: " %v %s" })}</h3>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                <div className='  row'>
                  <div className="col-md-5 col-5 text-center">
                    <p><i className="fa fa-dot-circle-o text-warning mr-2" /> {currencyAp}</p>

                  </div>
                  <div className='col-md-2 col-2 text-center'>
                    <p className='privacy'> = </p>
                  </div>
                  <div className="col-md-5 col-5 text-center">
                    <p><i className="fa fa-dot-circle-o text-warning mr-2" /> {rateTo} {currencyAp2}</p>

                  </div>
                </div>
              </div>

            </div>
          )}



        </div>




        <br></br>
        <br></br>
        <br></br>

        {/* /Search Filter */}


        {/* /Page Content */}
      </div>

    </div>
  );
}



