import React, { Component } from 'react';
 import { Helmet } from "react-helmet";
 import { FaInfo } from "react-icons/fa";
 import CompulsoryInsuranceTbl from './TxCompulsoryInsuranceTbl';
 
 export default class TaxCompulsoryInsuranceTable extends Component {
     render() {
         return (
 
             <div className="page-wrapper">
                 <Helmet>
                     <title>MassPortal | جدول  تأمين الزامي للمركبة       </title>
                     <meta name="description" content="MassPortal - جدول  تأمين الزامي للمركبة     " />
                 </Helmet>
                 {/* Page Content */}
                 <div className="content container-fluid">
                     <div className="small-container">
                         <div className="inner-header text-center">
                             <h1 className='privacy'>جدول  تأمين الزامي للمركبة  </h1>
                             <p className="privacy-bred"><a href="/">الرئيسية</a></p>
                         </div>
                         
                         <div className="row">
                                     <div className="card-body">
                                     <CompulsoryInsuranceTbl />
                                     </div>
                             <div className='col-lg-1'>
                             </div>
                         </div>
                         {/* /Content End */}
                     </div>
                     {/* /Page Content */}
                 </div>
             </div>
         );
     }
 }
 