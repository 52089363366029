import React, { useState, useEffect } from "react";
import { useIntervalNumPerc } from "../../../hooks/useIntervalNumPerc";
import { useAptCalc } from "../../../hooks/useAptCalc";
import { BiShekel } from 'react-icons/bi';
import { BsInfoCircle } from 'react-icons/bs';
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
import ReactTooltip from "react-tooltip";

import { useAuthContext } from "../../../hooks/useAuthContext";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { projectFirestore } from "../../../firebase/config";
import { massportallogo } from "../../../Entryfile/imagepath";

export function TxAbtCalc(props) {



  const printRef = React.useRef();

  const [dateStart, setdateStart] = useState(0);
  const [tbl, setTbl] = useState("oneAbt");
  const [sellingCost, setSellingCost] = useState(0);
  const [show, setShow] = useState(false);
  const [myPer, setMyPer] = useState(100);
  const [isFree, setIsFree] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [datee, setDatee] = useState(" ");
  // HOOKS
  const { user } = useAuthContext()
  const [companyName, setCompanyName] = useState(' ')
  //  getCompanyName();
  // const {docc , errr} = useDocument('mp-clients', docId)
  useEffect(() => {
    const getCompanyName = () => {
      if (user != null) {

        const mydata = projectFirestore.collection('mp-clients').doc(user.uid).get().then((docRef) => { setCompanyName(docRef.data().companyName) })
          .catch((error) => { })
      }
      else
        setCompanyName(" ")
    }

    getCompanyName();
  })


  const { num, perc: percentage, np } = useIntervalNumPerc(dateStart, tbl);
  const { tax, taxSlide1, taxSlide2, taxSlide3, taxSlide4, taxSlide5, money, taxSum1, taxSum2, taxSum3, taxSum4, taxSum5 } = useAptCalc(sellingCost, percentage, num, np, myPer);


  const handleSubmit = (evt) => {
    evt.preventDefault();
    let id = Math.random();
    // ===================
    let mydate = new Date(dateStart)
    const day = mydate.getDate()
    const month = mydate.getMonth()
    const  year = mydate.getFullYear()
    const finlaDate = day + ' / ' + month + " / "+ year
    // const today = current[0];
     setDatee(finlaDate)
    // ===================

    setShow(true);
    const timer = setTimeout(() => window.scroll(0, 1000), 100);
  };

  function newCalc() {
    window.location.reload();
  }
  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "")
    );
  }
  //  PDF Prin Function 
  const handleDownloadPdf = async () => {

    const element = printRef.current;
    element.style.background = '#FFFFFF';
    element.style.color = '#212529';

    const iaactive = true;
    await setIsActive(iaactive);
    //  Current Date 
    const d = new Date().toLocaleString() + "";
    let current = d.split(',');
    const today = current[0];
    const todaySplitArr = today.split('/');
    const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
    // Current Date 

    // PDF Header And Footer Values 
    const header = todayFrm;



    const canvas = await html2canvas(element);


    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();


    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;


    // pdf.addImage(data, 'PNG', 0, 10, pdfWidth, pdfHeight);
    // // Header
    // pdf.text(header, 2, 15, { baseline: 'top' });
    // // Footer
    //  Header  - Logo
    pdf.addImage(massportallogo, 'png', 90, 5, 20, 20);
    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 25, pdfWidth, pdfHeight);
    // Header - Date 
    pdf.text(header, 2, 29, { baseline: 'top' });
   
    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const footer = d + '             www.mass-portal.com';
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight-2, { baseline: 'bottom' });
    pdf.setFontSize(20);
    pdf.save('ضريبة الاملاك.pdf');
  };


  return (
    <div className="card container">
      <div className="card-body eOfService">

        <form
          onSubmit={handleSubmit}
          className="form container
      naqaha-form"
        >
          <div className="">
            <br></br>
            <div id="Btn_1"></div>
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <h2 className="card-title text-center privacy"> احتساب ضريبة الأملاك</h2>
              </div>
            </div>

            <div className="form-group row insurance-row">
              <div className="col-sm-12 col-md-2"></div>
              <div className="col-sm-12 col-md-6">
                <select id="dates"
                  className='select floating tax-abt-select '
                  name='dates' value={tbl} onChange={(e) => (setTbl(e.target.value))} >
                  <option value="oneAbt"> حاسبة ضريبة  شقة سكنية (واحدة)</option>
                  <option value="grtOneAbt">حاسبة ضريبة شقة سكن (أكثر من واحدة)
                  </option>
                  <option value="farm">حاسبة ضريبة عقارات اقتصاد زراعي</option>
                  <option value="newOne">حاسبة ضريبة عقارات قادم جديد</option>
                  <option value="noAbt"> حاسبة ضريبة عقارات غير سكنية</option>
                  <option value="court"> حاسبة ضريبة عقارات مخزون تجاري</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-4">

              </div>
              <div className="col-sm-12 col-md-2"></div>
              <div className="col-sm-12 col-md-2">

                <label className="col-form-label"> تاريخ البيع </label>
              </div>
              <div className="col-sm-12 col-md-4  ">

                <input
                  type="date"
                  className="form-control"
                  name="dateStart"
                  onChange={(e) => {
                    setdateStart(Date.parse(new Date(e.target.value)));
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-4">

              </div>
              <div className=" col-sm-12 col-md-2"></div>
              <div className="col-sm-12 col-md-2">
                <label className="col-form-label"> المبلغ </label>
              </div>
              <div className="col-sm-12 col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="sellingCost"
                  value={sellingCost}
                  onChange={(e) => setSellingCost(e.target.value)}
                />
              </div>
              <div className=" col-sm-12 col-md-2"></div>
              <div className=" col-sm-12 col-md-2"></div>
              <div className=" col-sm-12 col-md-2"></div>


              {/* <div className="col-sm-12 co"></div> */}

              <div className="form-check col-sm-12 col-md-6 abt-chacbox ">
                <input type="checkbox"
                  className="form-check-input naqaha-checkbox"
                  value={isFree}
                  name="checkbox"
                  id="flexCheckDefault"
                  onChange={(e) => setIsFree(!isFree)}
                />
                <label className="tax-check-lable" for="flexCheckDefault"> امتلاك بدون مقابل </label>

              </div>
              <div className=" col-sm-12 col-md-2"></div>
              <div className=" col-sm-12 col-md-2"></div>
              <div className="col-sm-12 col-md-2"></div>
              <div className="col-sm-12 col-md-2">
                <label className="col-form-label"> نسبة مشاركتي بالمبلغ </label>
              </div>
              <div className="col-sm-12 col-md-4">
                <input
                  type="text"
                  placeholder="100"
                  className="form-control"
                  name="myPer"
                  value={myPer}
                  onChange={(e) => setMyPer(e.target.value)}
                />
              </div>
            </div>

            <br />
            <div className="form-group row">
              <div className="col-sm-12 col-md-2"></div>
              <div className="col-sm-12 col-md-6  ">
                <button
                  type="submit"
                  className="btn btn-primary naqaha-btn insurance-btn"
                >
                  {" "}
                  احتساب{" "}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <br />
      <br />

      {show && (
        <>
          <div className="container" >
            <div className="content container-fluid">
              <div className="page-header ">
                <div className="">
                  <div className='row'>
                    <div className="col-sm-12 col-md-10">
                    </div>
                    {/* <div className="col-sm-12 col-md-1"></div> */}
                    <div className="col-sm-12 col-md-2">
                      <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                        <AiOutlineFilePdf size={40} className='privacy' />
                      </button>
                      <button className="btn " data-tip data-for="excel-tip">
                        <SiMicrosoftexcel size={40} className='privacy' />
                      </button>
                    </div>
                  </div>
                  <div className={isActive?" card-white":"card"} ref={printRef}>
                    <br></br>
                    <div className="row">
                      <br></br>
                      <div className="col-sm-12 col-md-4">
                        <h4 className={isActive ? " privacy-black-bold text-left company-marg " : " privacy hide"} id='date-block'>
                          الشركة : <span className="privacy-black"> {user ? companyName : " "}  </span>
                        </h4>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <h4 className= {isActive? "card-title mb-0 text-center privacy-black-bold " : "card-title mb-0 text-center  privacy"}>
                          حساب ضريبة الأملاك 
                          <br></br>
                          <span className={isActive?"privacy-black-bold" :"privacy-bold"}> {datee} </span>
                        </h4>
                      </div>
                      <div className='col-sm-12 col-md-4'>
                        <h4 className={isActive ? "privacy-black-bold text-center " : " privacy hide"} id='date-block'>
                          التاريخ
                        </h4>


                      </div>
                    </div>




                    <div className="card-body">

                      <div className="row result-div result-dev-tax" >

                        <div className="col-sm-12 col-md-2 ">
                          <label className="label-title">قيمة العقار </label>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <label className={isActive ? " reuslt-insurance" : "privacy reuslt-insurance"}>
                            {commify(sellingCost)} <BiShekel />
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-2 ">
                          <label className="label-title">نسبة التملك</label>
                        </div>
                        <div className="col-sm-12 col-md-1">
                          <label className={isActive ? " reuslt-insurance" : "privacy reuslt-insurance"}>
                            {commify(myPer)}
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-2 ">
                          <label className="label-title">
                            {" "}
                            الضريبة للمالك
                          </label>
                        </div>
                        {!isFree && <div className="col-sm-12 col-md-2">
                          <label className={isActive ? " reuslt-insurance" : "privacy reuslt-insurance"}>
                            {commify(money.toFixed(0))}
                          </label>
                        </div>}
                        {isFree &&
                          <div className="col-sm-12 col-md-2">
                            <label className={isActive ? " reuslt-insurance" : "privacy reuslt-insurance"}>
                              {commify((money / 3).toFixed(0))}
                            </label>
                          </div>
                        }
                        <div className=" row col-sm-12 col-md-12 border-div">
                          <br></br>
                          <h4 className={isActive ? " mb-0  text-center col-sm-12 col-md-2 " : " mb-0  privacy text-center col-sm-12 col-md-2 "}>
                            {" "}
                          </h4>
                          <h4 className=" mb-0  privacy text-center col-sm-12 col-md-5 ">
                            {" "}
                            {" "}
                          </h4>
                          <h4 className=" mb-0  privacy text-center col-sm-12 col-md-5 ">
                            {" "}
                            {" "}
                          </h4>
                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            {/* <label className="privacy">الشريحة الأولى </label> */}
                          </div>
                          <br></br>

                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            <label className={isActive ? " " : "privacy"}>الشرائح   </label>

                          </div>

                          <div className="col-sm-12 col-md-3">
                            <br></br>
                            <label className={isActive ? " " : "privacy"}>المبلغ الملزم بالضريبة  </label>
                          </div>
                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            <label className={isActive ? " " : "privacy"}>نسبة الضريبة  </label>
                          </div>
                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            <label className={isActive ? " " : "privacy"}>قيمة الضريبة  </label>
                          </div>
                          {/* <div className="col-sm-12 col-md-2">
                            <br></br>
                            <label className="privacy">  </label>
                          </div> */}





                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            <label className={isActive ? " " : "privacy"}>الشريحة الأولى </label>
                          </div>

                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            <label className="reuslt-insurance">
                              {commify(num[0])}{" "}
                            </label>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <br></br>
                            <label className="reuslt-insurance">
                              {commify(taxSum1)}{" "}
                            </label>
                          </div>
                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            <label className="reuslt-insurance">
                              %{((percentage[0]) * 100).toFixed(2)}{" "}
                            </label>
                          </div>
                          <div className="col-sm-12 col-md-2 ">
                            <br></br>
                            <label className="reuslt-insurance">
                              {commify(taxSlide1.toFixed(0))}
                            </label>
                          </div>
                          {/* <div className="col-sm-12 col-md-2">
                            <br></br>
                          </div> */}
                          {taxSlide2 != 0 && num[1] != 0 && num[0] != 0 && (
                            <>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className={isActive ? " " : "privacy"}>
                                  الشريحة الثانية{" "}
                                </label>
                              </div>

                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(num[1])}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-3">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSum2)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  %{((percentage[1]) * 100).toFixed(2)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2 ">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSlide2.toFixed(0))}
                                </label>
                              </div>
                              {/* <div className="col-sm-12 col-md-2">
                            <br></br>
                          </div> */}

                            </>
                          )}
                          {taxSlide2 != 0 && num[1] === "" && num[0] !== "" && (
                            <>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className={isActive ? " " : "privacy"}>
                                  الشريحة الثانية{" "}
                                </label>
                              </div>

                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  أكثر من{commify(num[0])}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSum2)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  %{((percentage[1]) * 100).toFixed(2)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2 ">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSlide2.toFixed(0))}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                              </div>
                            </>
                          )}
                          {taxSlide3 != 0 && num[2] !== "" && num[1] !== "" && num[0] !== "" && (
                            <>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className={isActive ? " " : "privacy"}>
                                  الشريحة الثالثة{" "}
                                </label>
                              </div>

                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(num[2])}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-3">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSum3)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  %{((percentage[2]) * 100).toFixed(2)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2 ">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSlide3.toFixed(0))}
                                </label>
                              </div>
                              {/* <div className="col-sm-12 col-md-2">
                            <br></br>
                          </div> */}
                            </>
                          )}
                          {taxSlide3 != 0 && num[2] === "" && (
                            <>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className={isActive ? " " : "privacy"}>
                                  الشريحة الثالثة{" "}
                                </label>
                              </div>

                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  أكثر من {commify(num[1])}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSum3)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  %{((percentage[2]) * 100).toFixed(2)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2 ">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSlide3.toFixed(0))}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                              </div>
                            </>
                          )}
                          {taxSlide4 != 0 && num[3] !== "" && num[2] !== "" && num[1] !== "" && num[0] !== "" && (
                            <>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className={isActive ? " " : "privacy"}>
                                  الشريحة الرابعة{" "}
                                </label>
                              </div>

                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(num[3])}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-3">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSum4)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  %{((percentage[3]) * 100).toFixed(2)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2 ">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSlide4.toFixed(0))}
                                </label>
                              </div>
                              {/* <div className="col-sm-12 col-md-2">
                            <br></br>
                          </div> */}
                            </>
                          )}
                          {taxSlide5 != 0 && num[3] !== "" && num[2] !== "" && num[1] !== "" && num[0] !== "" && (
                            <>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className={isActive ? " " : "privacy"}>
                                  الشريحة الخامسة{" "}
                                </label>
                              </div>

                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {" "}
                                  اكثر من {commify(num[3])}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-3">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {" "}
                                  {commify(taxSum5)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {" "}
                                  %{((percentage[4]) * 100).toFixed(2)}{" "}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2 ">
                                <br></br>
                                <label className="reuslt-insurance">
                                  {commify(taxSlide5.toFixed(0))}
                                </label>
                              </div>
                              <div className="col-sm-12 col-md-2">
                                <br></br>
                              </div>
                            </>
                          )}
                          <div className="">
                            <br></br><br></br>
                            <hr className="line-style"></hr>
                          </div>
                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            <br></br>
                            <br></br>

                          </div>
                          <div className="col-sm-12 col-md-2">
                            <br></br>
                            <br></br>
                            <br></br>
                            <label className={isActive ? " reuslt-insurance " : "reuslt-insurance privacy"}>
                              {" "}
                              المجموع{" "}
                            </label>
                          </div>
                          <div className="col-sm-12 col-md-2 ">
                            <br></br>
                            <br></br>
                            <br></br>
                            <label className={isActive ? " reuslt-insurance " : "reuslt-insurance privacy"}>
                              {commify(sellingCost)}
                            </label>
                          </div>
                          <div className="col-sm-12 col-md-3 result-percent">
                            <br></br>
                            <br></br>
                            <br></br>
                            <label className={isActive ? " reuslt-insurance   text-right " : "reuslt-insurance privacy "}>
                              %{commify(((tax / sellingCost) * 100).toFixed(2))}
                            </label>
                          </div>

                          <div className="col-sm-12 col-md-3 ">
                            <br></br>
                            <br></br>
                            <br></br>
                            <label className={isActive ? " reuslt-insurance " : "reuslt-insurance privacy"}>
                              {commify(tax.toFixed(0))} <BiShekel />
                            </label>
                          </div>

                          {!isFree &&
                            <div className="col-sm-12 col-md-12 ">
                              <br></br>
                              <br></br>
                              <br></br>
                              <label className="  privacy col-sm-12 col-md-2 ">

                              </label>
                              <label className={isActive ? "reuslt-tax col-sm-12 col-md-4 " : "privacy reuslt-tax col-sm-12 col-md-4 "}>
                                المبلغ الضريبي الملزم للدفع
                              </label>
                              <label className={isActive ? "reuslt-insurance title-double-underline  col-sm-12 col-md-6" : "reuslt-insurance title-double-underline privacy col-sm-12 col-md-6"}  >{commify(money.toFixed(0))} <BiShekel /> </label>
                            </div>
                          }
                          {isFree &&
                            <div className="col-sm-12 col-md-12 ">
                              <br></br>
                              <br></br>
                              <br></br>
                              <label className="  privacy col-sm-12 col-md-2 ">

                              </label>
                              <label className=" reuslt-tax privacy col-sm-12 col-md-4 ">
                                المبلغ الضريبي الملزم للدفع
                              </label>
                              <label className="title-double-underline reuslt-insurance privacy col-sm-12 col-md-6 " >{commify(((money / 3).toFixed(0)))} <BiShekel /> </label>
                            </div>
                          }
                          <div className="col-sm-12 col-md-12 text-center ">

                            <br></br>
                            <br></br>
                            <br></br>
                            <hr className="line-style"></hr>
                            <br></br>
                            <br></br>

                            <label className="  ">
                              <h4 className={isActive ? " privacy-black" : ""}>

                                <BsInfoCircle className={isActive ? "" : "privacy-icon"} />

                                اعتبارًا من 01/08/2013 ،يتم تطبيق معدلات ضريبة الشراء المخفضة لشقة سكنية واحدة  فقط للمواطن

                              </h4>
                            </label>
                          </div>


                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactTooltip id="pdf-tip" place="top" effect="solid">
            طباعة PDF
          </ReactTooltip>
          <ReactTooltip id="excel-tip" place="top" effect="solid">
            طباعة Excel
          </ReactTooltip>

          <div className="print-div">

            <button className="btn btn-primary naqaha-btn insurance-btn" onClick={newCalc}> احتساب جديد  </button>
          </div>
        </>
      )}
    </div>
  );
}
