let ta=[
 {
  ar: "طابع ايراد",
  he: "בול הכנסה",
  en: "Revenue stamp"
 },
 {
  ar: "طابع تجاري",
  he: "בול מסחרי",
  en: "Trading stamp"
 },
 {
  ar: "طابع تشجيع المبيعات",
  he: "בול מסחרי",
  en: "Trading stamp"
 },
 {
  ar: "طاقة",
  he: "אנרגיה",
  en: "energy"
 },
 {
  ar: "الطبقة العاملة",
  he: "מעמד הפועלים",
  en: "Working class"
 },
 {
  ar: "طبقة عليا",
  he: "מעמד עליון",
  en: "Lower class"
 },
 {
  ar: "طبقة دنيا",
  he: "מעמד תחתון",
  en: "Upper class"
 },
 {
  ar: "طبقة متوسطة",
  he: "מעמד בינוני",
  en: "Middle class"
 },
 {
  ar: "طبقة وسطى",
  he: "מעמד בינוני",
  en: "Middle class"
 },
 {
  ar: "طرد المستاجر",
  he: "נישול",
  en: "eviction"
 },
 {
  ar: "طريقة الصنع",
  he: "תהליך ייצור",
  en: "Manufacturing process"
 },
 {
  ar: "طلب",
  he: "ביקוש",
  en: "Demand"
 },
 {
  ar: null,
  he: "המנה",
  en: "order"
 },
 {
  ar: "طلب اجمالي",
  he: "ביקוש מצרפי",
  en: "Aggregate demand\/ composite demand"
 },
 {
  ar: "طلب اليد العاملة",
  he: "ביקוש לעבודה",
  en: "Demand for labour"
 },
 {
  ar: "طلب بريد",
  he: "קנייה באמצעות הדואר",
  en: "Mail order"
 },
 {
  ar: "طلب تكاملي",
  he: "ביקוש משלים",
  en: "Complementary demand"
 },
 {
  ar: "طلب خفيف",
  he: "הוראה למסחר בשברים",
  en: "Odd lot order"
 },
 {
  ar: "طلب فائض",
  he: "עודף ביקוש",
  en: "Excess demand"
 },
 {
  ar: "طلب مترابط",
  he: "ביקוש משולב",
  en: "Joint demand"
 },
 {
  ar: "طلب مركب",
  he: "ביקוש מצרפי",
  en: "Aggregate demand\/ composite demand"
 },
 {
  ar: "طلب مستقر",
  he: "הזמנה עומדת\/ הזמנה קבועה",
  en: "Standing order"
 },
 {
  ar: "طلب يحدد سعر بيع او شراء السهم",
  he: "הוראה בהגבלת שער",
  en: "Limit order"
 },
 {
  ar: null,
  he: null,
  en: null
 },
 {
  ar: "ظروف العمل",
  he: "תנאי עבודה",
  en: "Limit order"
 }
]
export default ta ;