import React, { useState} from "react";

export default function HalanatSkhar() {
  // states for form (inputs)
  const [dateMustPay, setDateMustPay] = useState(0);
  const [datePaid, setDatePaid] = useState(0);
  const [income, setIncome] = useState(0);
  // states for result (outputs)
  const [compensationAmount, setCompensationAmount] = useState(0);
  const [compensationRatio, setCompensationRatio] = useState(0);
  const [TotalToPay, setTotalToPay] = useState(0);
  const [weeksOfDelay, setWeeksOfDelay] = useState(0);
  //
  const [show,setShow] = useState(false)

  let oneWeek = 604800000
  let band1 = 0.05
  let band2 = 0.1

  // Thousands Commas Function 
  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}

  const handleSubmit =(e)=>{
    e.preventDefault()
    setShow(true)
    let delay = Math.ceil((datePaid - dateMustPay) / oneWeek)
    setWeeksOfDelay(delay)
    let coAmount = ((delay - 1) * income * band2) + income * band1 
    setCompensationAmount(coAmount)
    let TTP = coAmount*1 + income*1
    setTotalToPay(TTP)
    let coRatio = (delay - 1) * 10 + band1 * 100
    setCompensationRatio(coRatio)
    const timer = setTimeout(() => window.scroll(0, 2000), 100);
  }

//   const newCalc = ()=>{
//     window.location.reload()
//   }
  

  return (
    <div className=" card ">
        <div className="card-header ">
          <h4 className="card-title mb-0 privacy text-center" >نموذج الاحتساب </h4>
        </div>
                <div className="card-body">
      <form onSubmit={handleSubmit} className=" eOfService form container naqaha-form">
        <div className="input-group mb-3 ">
        <span className="input-group-text  span-MK">موعد الدفع</span>
          <input type="date" className="form-control " onChange={(e)=> setDateMustPay(Date.parse(new Date(e.target.value))) } />
        </div>
        <div className="input-group mb-3">
        <span className="input-group-text span-MK">تم الدفع في</span>
        <input type="date" className="form-control" onChange={(e)=> setDatePaid(Date.parse(new Date(e.target.value))) }/>
        </div>
        <div className="input-group mb-3">
        <span className="input-group-text span-MK">الراتب</span>
        <input type="text" className="form-control" onChange={(e)=> setIncome(e.target.value)}/>
        </div>
        <div className="text-center">
        <button className="btn btn-primary btn-return">أحتساب</button>
        </div>
      </form>
      <hr />
      <br />
        {/* =====Result===== */}
        { show && <div className="container card">
        <div className="card-header ">
          <h4 className="card-title mb-0 privacy text-center" >ناتج الاحتساب </h4>
        </div>
       <div className="card-body">
       <div className="input-group mb-3">
        <span className="input-group-text span-MK">مبلغ التعويض</span>
        <input className="form-control input-MK" value={commify(compensationAmount)}></input>
        <span className="input-group-text span-MK">نسبة التعويض </span>
        <input className="form-control input-MK" value={commify(compensationRatio) + "%"}></input>
        </div>

        <div className="input-group mb-3">
        <span className="input-group-text span-MK">مبلغ الدفع الكلي</span>
          <input value={commify(TotalToPay)} className="form-control input-MK"></input>
        </div>
        <div className="input-group mb-3">
        <span className="input-group-text span-MK">أسابيع التأخير</span>
        <input value={commify(weeksOfDelay)}  className="form-control input-MK"></input>
        </div>
        <div className="input-group mb-3">
        <span className="input-group-text span-MK">الأسبوع الأول </span>
        <input value={band1*100 + "%"} className="form-control input-MK"></input>
        <span className="input-group-text span-MK">باقي الأسابيع</span>
        <input value={band2*100 + "%"} className="form-control input-MK"></input>
        </div>
       </div>
      </div>}
      </div>
    </div>
  );
}