let kaf=[
 {
  ar: "قائمة الجرد",
  he: "רשימת מלאי",
  en: "Inventory list"
 },
 {
  ar: "قائمة بالبضائع المشحونة",
  he: "רשימה של סחורות להובלה",
  en: "waybill"
 },
 {
  ar: "قاعدة الذهب",
  he: "בסיס זהב",
  en: "Gold standard"
 },
 {
  ar: "قدرة على الدفع",
  he: "יכולת לשלם חובות",
  en: "solvency"
 },
 {
  ar: "قرض",
  he: "הלוואה",
  en: "loan"
 },
 {
  ar: "قرض ائتماني بدون ضمان",
  he: "הלוואה ללא ביטחונות",
  en: "Fiduciary loan"
 },
 {
  ar: "قرض اجباري",
  he: "הלוואה הניתנת בכפייה",
  en: "Compulsory loan"
 },
 {
  ar: "قرض الزامي",
  he: "הלוואה כפויה",
  en: "Forced loan"
 },
 {
  ar: "قرض تحت الطلب",
  he: "הלוואה שהגיע מועד פירעונה",
  en: "Call loan"
 },
 {
  ar: "قرض حسن",
  he: "הלוואה ללא ריבית",
  en: "Interest free loan"
 },
 {
  ar: "قرض حكومي",
  he: "הלוואה ממשלתית",
  en: "Government loan"
 },
 {
  ar: "قرض شخصي",
  he: "הלוואה אישית",
  en: "Personal loan"
 },
 {
  ar: "قرض طويل الاجل",
  he: "הלוואה לטווח ארוך",
  en: "Long term loan"
 },
 {
  ar: "قرض قصير الاجل",
  he: "הלוואה לטווח קצר",
  en: "Short term loan"
 },
 {
  ar: "قرض محدود الاجل",
  he: "הלוואה שתקופת ההחזר שלה מוגדרת",
  en: "Term loan"
 },
 {
  ar: "قرض محلي",
  he: "הלוואה מקומית",
  en: "Local loan"
 },
 {
  ar: "قرض مصرفي",
  he: "הלוואה בנקאית",
  en: "Banl loan"
 },
 {
  ar: "قرض مضمون",
  he: "הלוואה מובטחת",
  en: "Secured loan"
 },
 {
  ar: "قسط",
  he: "תשלום (מכמה תשלומים)",
  en: "installment"
 },
 {
  ar: "قسط التامين",
  he: "פרמיית ביטוח",
  en: "Insurance premium"
 },
 {
  ar: null,
  he: "פרמיה\/ דמי ביטוח",
  en: "premium"
 },
 {
  ar: "قسط الخطر",
  he: "פרמיית סיכון",
  en: "Risk premium"
 },
 {
  ar: "قسط تصاعدي",
  he: "פרמיה הולכת וגדלה",
  en: "Increasing premium"
 },
 {
  ar: "قسط ثابت",
  he: "פרמיה קבועה",
  en: "Fixed premium"
 },
 {
  ar: "قسط متزايد",
  he: "פרמיה הולכת וגדלה",
  en: "Increasing premium"
 },
 {
  ar: "قسم المبيعات",
  he: "מחלקת מכירות",
  en: "Sales department"
 },
 {
  ar: "قسيمة",
  he: "שובר\/ תלוש",
  en: "Voucher\/ coupon"
 },
 {
  ar: "قسيمة طلب",
  he: "טופס הזמנה",
  en: "Order form"
 },
 {
  ar: "قطاع",
  he: "מגזר\/ סקטור",
  en: "sector"
 },
 {
  ar: "قطاع خاص",
  he: "מגזר פרטי\/ סקטור פרטי",
  en: "Private sector"
 },
 {
  ar: "قطاع عام",
  he: "מגזר ציבורי\/ סקטור ציבורי",
  en: "Public sector"
 },
 {
  ar: "قطع",
  he: "מטבע",
  en: "currency"
 },
 {
  ar: "قوى السوق",
  he: "כוחות שוק",
  en: "Market forces"
 },
 {
  ar: "قوة شرائية",
  he: "כוח קנייה",
  en: "Purchasing power"
 },
 {
  ar: "قوة عاملة",
  he: "כוח עבודה",
  en: "Work force\/ labour force"
 },
 {
  ar: "قيد",
  he: "הגבלה",
  en: "restriction"
 },
 {
  ar: "قيمة",
  he: "ערך",
  en: "Rate\/ value"
 },
 {
  ar: "قيمة اساسية",
  he: "ערך נקוב\/ ערך נומינאלי",
  en: "Nominal value\/ face value\/ par value\/ par"
 },
 {
  ar: "قيمة اسمية",
  he: "ערך נקוב\/ ערך נומינאלי",
  en: "Nominal value\/ face value\/ par value\/ par"
 },
 {
  ar: "قيمة اصلية",
  he: "ערך נקוב\/ ערך נומינאלי",
  en: "Nominal value\/ face value\/ par value\/ par"
 },
 {
  ar: "قيمة المخلفات",
  he: "ערך גרט",
  en: "Salvage value"
 },
 {
  ar: "قيمة النقود",
  he: "ערך הכסף",
  en: "Value of money"
 },
 {
  ar: "قيمة حالية",
  he: "ערך נוכחי",
  en: "Present value"
 },
 {
  ar: "قيمة حقيقية",
  he: "ערך ריאלי",
  en: "Real value"
 },
 {
  ar: "قيمة دفترية",
  he: "ערך בספרים",
  en: "Book value"
 },
 {
  ar: "قيمة صافية",
  he: "שווי נקי",
  en: "Net worth"
 },
 {
  ar: "قيمة عند التصفية",
  he: "ערך חיסול",
  en: "Liquidation value"
 },
 {
  ar: "قيمة فعلية",
  he: "ערך ריאלי",
  en: "Real value"
 },
 {
  ar: "قيمة قانونية",
  he: "ערך נקוב\/ ערך נומינאלי",
  en: "Nominal value\/ face value\/ par value\/ par"
 },
 {
  ar: "قيمة محاسبية",
  he: "ערך בספרים",
  en: "Book value"
 },
 {
  ar: "قيمة مستخلصة",
  he: "ערך גרט",
  en: "Salvage value"
 },
 {
  ar: "قيمة مشتقة",
  he: "ערך נגזר",
  en: "Derived value"
 },
 {
  ar: "قيمة مضافة",
  he: "ערך מוסף",
  en: "Value added"
 },
 {
  ar: "قيمة معلنة",
  he: "ערך מוצהר",
  en: "Declared value"
 },
 {
  ar: "قيود الاستيراد",
  he: "הגבלות יבוא",
  en: "Import restrictions"
 }
]
export default kaf ;