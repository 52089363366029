import React, { useState, useEffect } from "react";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
import ReactTooltip from "react-tooltip";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { massportallogo } from "../../Entryfile/imagepath";
import { useAuthContext } from "../../hooks/useAuthContext";
import { projectFirestore } from "../../firebase/config";
export function NaqahaCalcG(props) {
  const printRef = React.useRef();

  const [dateStart, setdateStart] = useState("");
  const [dateFinish, setdateFinish] = useState("");
  const [sectionName, setSectionName] = useState(" قطاع عام ")
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false)
  const [isprivate, setIsprivate] = useState(false)
  const [isFirstRec, setIsFirstRec] = useState(false)
  const [data, setData] = useState([]);
  const [datee, setDatee] = useState(" ");
  const [isActive, setIsActive] = useState(false);

  // HOOKS
  const { user } = useAuthContext()
  const [companyName, setCompanyName] = useState(' ')
  //  getCompanyName();
  // const {docc , errr} = useDocument('mp-clients', docId)
  useEffect(() => {
    const getCompanyName = () => {
      if (user != null) {

        const mydata = projectFirestore.collection('mp-clients').doc(user.uid).get().then((docRef) => { setCompanyName(docRef.data().companyName) })
          .catch((error) => { })
      }
      else
        setCompanyName(" ")
    }

    getCompanyName();
  })

  let intervalDay = [
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13

  ];
  let intervalYear = [
    1,
    2,
    4,
    11,
    16,
    20,
    25
  ];

  let recYears = [
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022
  ]
  // const loglog = ()=>{
  //   console.log('clicked here ')
  //   window.scroll(0,600);
  // }
  const refresh = () => {
    window.location.reload();
  }

  const handleSubmit = (evt) => {


    evt.preventDefault();


    let id = Math.random();
    if (dateFinish !== "" && dateStart !== "") {
      setTotal(calculateRec());
      setShow(true)

      const timer = setTimeout(() => window.scroll(0, 5000), 100);




      //   window.scrollTo({
      //     top: 5000,
      //     behavior: "smooth",
      // });

    }



    function calculateRec() {


      const year = 2626560000 * 12;
      let firstDate = new Date(dateStart);
      let secondDate = new Date(dateFinish);
      let recYear = secondDate.getFullYear();

      let sub = secondDate - firstDate;
      let years;
      if (sub > 0) {
        years = (Math.floor((sub / year)));
      }
      else {
        sub = firstDate - secondDate
        years = (Math.floor((sub / year)));

      }
      let recDay = 400;
      // console.log("private ? ", isprivate);
      if (recYear === recYears[0] && !isprivate) { recDay = 421 }
      else if (recYear === recYears[1] && !isprivate) { recDay = 424 }
      else if (recYear === recYears[2] && !isprivate) { recDay = 426 }
      else if (recYear === recYears[3] && !isprivate) { recDay = 432 }
      else if (recYear === recYears[4] && !isprivate) { recDay = 429 }
      else if (recYear === recYears[5] && !isprivate) { recDay = 432 }
      else if (recYear === recYears[6] && !isprivate) { recDay = 449 }
      // console.log(years)
      let clacDay = 0;
      let myDay = 0;

      if (years < intervalYear[0] && (!isprivate || isprivate || !isFirstRec || isFirstRec)) {
        myDay = 0
        clacDay = 0
        recDay = 0
      }
      else if (years >= intervalYear[0] && years < intervalYear[2] && !isprivate && !isFirstRec) {
        myDay = (intervalDay[2])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[0] && years < intervalYear[2] && !isprivate && isFirstRec) {
        myDay = (intervalDay[2]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[2] && years < intervalYear[3] && !isprivate && !isFirstRec) {
        myDay = (intervalDay[4])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[2] && years < intervalYear[3] && !isprivate && isFirstRec) {
        myDay = (intervalDay[4]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[3] && years < intervalYear[4] && !isprivate && !isFirstRec) {
        myDay = (intervalDay[5])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[3] && years < intervalYear[4] && !isprivate && isFirstRec) {
        myDay = (intervalDay[5]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[4] && years < intervalYear[5] && !isprivate && !isFirstRec) {
        myDay = (intervalDay[6])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[4] && years < intervalYear[5] && !isprivate && isFirstRec) {
        myDay = (intervalDay[6]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[5] && years < intervalYear[6] && !isprivate && !isFirstRec) {
        myDay = (intervalDay[7])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[5] && years < intervalYear[6] && !isprivate && isFirstRec) {
        myDay = (intervalDay[7]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[6] && !isprivate && !isFirstRec) {
        myDay = (intervalDay[8])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[6] && !isprivate && isFirstRec) {
        myDay = (intervalDay[8]) * years
        clacDay = myDay * (recDay) * 1;
      }

      else if (years >= intervalYear[0] && years < intervalYear[1] && isprivate && !isFirstRec) {
        myDay = (intervalDay[0])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[0] && years < intervalYear[1] && isprivate && isFirstRec) {
        myDay = (intervalDay[0]) * years
        clacDay = myDay * (recDay) * 1;
      }

      else if (years >= intervalYear[1] && years < intervalYear[2] && isprivate && !isFirstRec) {
        myDay = (intervalDay[1])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[1] && years < intervalYear[2] && isprivate && isFirstRec) {
        myDay = (intervalDay[1]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[2] && years < intervalYear[3] && isprivate && !isFirstRec) {
        myDay = (intervalDay[2])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[2] && years < intervalYear[3] && isprivate && isFirstRec) {
        myDay = (intervalDay[2]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[3] && years < intervalYear[4] && isprivate && !isFirstRec) {
        myDay = (intervalDay[3])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[3] && years < intervalYear[4] && isprivate && isFirstRec) {
        myDay = (intervalDay[3]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[4] && years < intervalYear[5] && isprivate && !isFirstRec) {
        myDay = (intervalDay[4])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[4] && years < intervalYear[5] && isprivate && isFirstRec) {
        myDay = (intervalDay[4]) * years
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[5] && isprivate && !isFirstRec) {
        myDay = (intervalDay[5])
        clacDay = myDay * (recDay) * 1;
      }
      else if (years >= intervalYear[5] && isprivate && isFirstRec) {
        myDay = (intervalDay[5]) * years
        clacDay = myDay * (recDay) * 1;
      }

      setData(prev => [...prev,
      {
        id: id,
        years: years,
        clacDay: clacDay,
        recDay: recDay,
        myDay: myDay
      }])


      return years, clacDay, recDay, myDay

    }


  }

  const handleDelete = (id) => {
    let newData = data.filter((data) =>
      data.id !== id)
    setData(newData)
    newData = [];
  }
// This Function prints the whole page 
// function downloadPDFWithBrowserPrint() {
//   window.print();
// }
  //  PDF Prin Function 
  const handleDownloadPdf = async () => {

    const element = printRef.current;
    element.style.background = '#FFFFFF';
    element.style.color = '#212529';

    const iaactive = true;
    await setIsActive(iaactive);
    //  Current Date 
    const d = new Date().toLocaleString() + "";
    let current = d.split(',');
    const today = current[0];
    const todaySplitArr = today.split('/');
    const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
    // Current Date 

    // PDF Header And Footer Values 
    const header = todayFrm;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    //  Header  - Logo
    pdf.addImage(massportallogo, 'png', 90, 5, 20, 20);

    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 25, pdfWidth, pdfHeight);

    // Header - Date 
    pdf.text(header, 2, 28, { baseline: 'top' });

    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const footer = d + '             www.mass-portal.com';
    // Footer
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight - 2, { baseline: 'bottom' });
    pdf.setFontSize(20);
    pdf.save('رسوم أيام النقاهة-عام .pdf');
  };

  return (
    <div className="card">
      <div className=" card-body eOfService">

        <form onSubmit={handleSubmit} className="form container naqaha-form">

          <div className="">
            <br></br>
            <h2 className="card-title text-center"> احتساب رسوم  النقاهة  - <span className="privacy"> قطاع عام </span>  </h2>

            <div className="form-group row">
              {/* <div className="col-sm-12 col-md-2"></div> */}

              {/* <div className='col-sm-12 col-md-2'></div> */}
              <div className="col-sm-12 col-md-2"></div>
              <div className='col-sm-12 col-md-4'><br></br>

                <label> تاريخ بدء العمل  </label>
                <input type="date"
                  className="form-control naqaha-date"

                  name="dateStart"
                  value={dateStart}
                  onChange={(e) => setdateStart(e.target.value)} />

              </div>
              <div className='col-sm-12 col-md-4'>
                <br></br>
                <label> تاريخ رسوم النقاهة المراد الحصول عليها  </label>
                <input
                  type="date"
                  className="form-control naqaha-date"
                  name="dateFinsh" value={dateFinish}
                  onChange={(e) => setdateFinish(e.target.value)} />

              </div>
              <div className="col-sm-12 col-md-1"></div>
              <div className="col-sm-12 col-md-2"></div>
              <div className='col-sm-12 col-md-8'>

                <div className="form-check">
                  <input type="checkbox"
                    className="form-check-input naqaha-checkbox"
                    value={isFirstRec}
                    name="checkbox"
                    id="flexCheckDefault"
                    onChange={(e) => setIsFirstRec(!isFirstRec)}
                  />
                  <label className="naqaha-check-lable" for="flexCheckDefault"> لم اتقاضى رسوم نقاهة من قبل  </label>

                </div>
              </div>
              <div className="row naqaha-div">
                <div className="col-sm-12 col-md-3"></div>
                <div className="col-sm-12 col-md-6  ">
                  <button type="submit" className="btn btn-primary naqaha-btn" > احتساب </button>
                </div>
              </div>


            </div>
          </div>

          {/* <div className="text-right">
            <button type="submit" className="btn btn-primary">حساب</button>
          </div> */}
        </form>
      </div>


      {show && <div className="" >

        <hr></hr>
        <div className='row'>
          <br></br>
          <div className="col-sm-12 col-md-10">
          </div>
          {/* <div className="col-sm-12 col-md-1"></div> */}
          <div className="col-sm-12 col-md-2">
            <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
              <AiOutlineFilePdf size={40} className='privacy' />
            </button>
            <button className="btn " data-tip data-for="excel-tip">
              <SiMicrosoftexcel size={40} className='privacy' />
            </button>
          </div>
        </div>
        <ReactTooltip id="pdf-tip" place="top" effect="solid">
          طباعة PDF
        </ReactTooltip>
        <ReactTooltip id="excel-tip" place="top" effect="solid">
          طباعة Excel
        </ReactTooltip>
        <div className={isActive ? " card-white" : "card"} ref={printRef}>

          <br></br>
          <div className="row">
            <br></br> <br></br>
            <div className="col-sm-12 col-md-4">
              <h4 className={isActive ? " privacy-black-bold text-left company-marg " : " privacy hide"} id='date-block'>
                الشركة : <span className="privacy-black"> {user ? companyName : " "}  </span>
              </h4>
            </div>
            <div className="col-sm-12 col-md-4">
              <h4 className={isActive ? "card-title mb-0 text-center privacy-black-bold " : "card-title mb-0 text-center  privacy"}>
                رسوم ايام النقاهة  - <span className="privacy"> {sectionName} </span>
              </h4>
            </div>
            <div className='col-sm-12 col-md-4'>
              <h4 className={isActive ? "privacy-black-bold text-center " : " privacy hide"} id='date-block'>
                التاريخ
              </h4>


            </div>
          </div>
          <div className={isActive ? "card-body card-body-white" : "card-body"} >




            <div className="table-responsive">
              <br></br> <br></br>
              <table className="table table-striped mb-0 " dir={isActive ? 'rtl' : " "}>
                <thead>
                  {isFirstRec && (
                    <>
                      <tr className="tr-naqaha">
                        <th>من سنة  </th>
                        <th className="privacy">{dateStart}</th>
                        <th>الى سنة   </th>
                        <th className="privacy"> {dateFinish}</th>
                      </tr>
                    </>
                  )}
                  {!isFirstRec && (
                    <>
                      <tr>
                        <th> لسنة </th>

                        <th className="privacy"> {dateFinish}</th>
                      </tr>
                    </>
                  )}

                  <tr className={isActive?"flip":" "}>

                    <th></th>
                    <th > الأيام المستحقة </th>
                    <th> سنوات العمل</th>
                    <th>رسوم يوم النقاهة الواحد</th>

                    <th>رسوم النقاهة الكلي</th>


                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((d, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{d.myDay}</td>
                        <td>{d.years}</td>
                        <td>{d.recDay}</td>
                        <td>{d.clacDay}</td>
                        <td onClick={() => handleDelete(d.id)}><button className="btn privacy" type="button"> حذف</button></td>

                      </tr>
                    ))}

                </tbody>
              </table>

            </div>
            <hr></hr>

          </div></div>
        <br></br>
        <div className="row naqaha-div">
          <br></br><br></br>
          <div className="col-sm-12 col-md-3"></div>
          <br></br>
          <div className="col-sm-12 col-md-6  ">
            <button type="submit" className="btn btn-primary naqaha-btn" onClick={refresh}> احتساب جديد  </button>
          </div>
        </div>
        <br></br> <br></br>
      </div>


      }

    </div>

  );
}