/**
 * App Header
 */
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import '../../assets/js/app.js';
// import 'font-awesome/css/font-awesome.min.css';
import '../../assets/css/font-awesome.min.css';
import '../../assets/css/line-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../../assets/js/bootstrap.min.js';
import '../../assets/css/select2.min.css';
import '../../assets/js/popper.min.js';


import '../../assets/js/select2.min.js';
import '../../assets/js/jquery-3.2.1.min.js';

import '../../assets/js/jquery.slimscroll.min.js';
import "../../assets/js/bootstrap-datetimepicker.min.js"
import "../../assets/js/jquery-ui.min.js"
import "../../assets/js/task.js"
import "../../assets/js/multiselect.min.js"
import "../../assets/plugins/bootstrap-tagsinput/bootstrap-tagsinput.css"
import "../../assets/plugins/bootstrap-rtl/css/bootstrap.min.css"
import "../../assets/plugins/bootstrap-rtl/js/bootstrap.min.js"
import { MdLanguage } from "react-icons/md";
import { RiFileList3Line, RiTeamFill, RiGovernmentLine } from "react-icons/ri";
import { AiOutlineFilePdf, AiOutlineLink, AiFillCalculator, AiOutlineCalculator, AiOutlineAppstoreAdd } from "react-icons/ai";
import { FaSignature, FaClipboardList, FaUserAlt, FaWpforms, FaPercent } from "react-icons/fa";
import { BsInfoCircle, BsInfoLg, BsCalculator, BsTable, BsMoonStars, BsQuestionCircle } from "react-icons/bs";
import { HiOutlineClipboardList } from "react-icons/hi";
import { ImTable2 } from "react-icons/im";
import { IoDocumentTextOutline, IoCarSportOutline } from "react-icons/io5";
import { SiMicrosoftexcel } from "react-icons/si"
import { VscLaw } from "react-icons/vsc";
import { FiPhoneCall } from "react-icons/fi";


import { GoInfo } from "react-icons/go";
// import '../../assets/css/style-dark.css';
//  import { withRouter } from 'react-router-dom';
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext.js';

export default function Sidebar() {
  const { logout } = useLogout()
  const { user } = useAuthContext()

  const pathname = window.location.pathname;

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>الرئيسية</span>
            </li>
            <li className="submenu">
              <a href="#"><i className="la la-money" /> <span> ضريبة الدخل </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                <li className="submenu">
                  <a href="#"> <span> احتساب الضرائب </span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    {/* 2022 Calculators */}
                    <li className="submenu">

                      <a href="#"> <span> 2022</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('Tax22') ? "active" : ""}
                            href="/calculators/WorkLawsCalc/Tax22"> <AiOutlineCalculator size={18} /> <span> حساب اجمالي  </span> </a>
                        </li>



                        <li>
                          <a className={pathname.includes('Nato22') ? "active" : ""}
                            href="/calculators/nato-calculator/Nato22"> <AiOutlineCalculator size={18} /> <span> احتساب NATO  </span> </a>
                        </li>


                      </ul>
                    </li>
                    {/* 2021 Calculators */}
                    <li className="submenu">

                      <a href="#"> <span> 2021</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('Tax21') ? "active" : ""}
                            href="/calculators/WorkLawsCalc/Tax21"> <AiOutlineCalculator size={18} /> <span> حساب اجمالي  </span> </a>
                        </li>


                        <li>
                          <a className={pathname.includes('Nato21') ? "active" : ""}
                            href="/calculators/nato-calculator/Nato21"> <AiOutlineCalculator size={18} /> <span> احتساب NATO  </span> </a>
                        </li>


                      </ul>
                    </li>

                    {/* <li>
                          <a className={pathname.includes('calculator-vat') ? "active" : ""}
                            href="/calculator-vat"> <AiOutlineCalculator size={18} /> <span> احتساب القيمة المضافة   </span> </a>
                        </li> */}
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/empBillsCalc') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/empBillsCalc">  <AiOutlineCalculator size={20} />  <span> فحص ضريبة الدخل </span>
                      </a>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/tax-calc') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/tax-calc"> <AiOutlineCalculator size={20} /><span> حساب الضريبة التراكمية </span>
                      </a>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/TotalTabel') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/TotalTabel">  <ImTable2 size={15} /> <span> جدول اجمالي - صافي </span>
                      </a>
                    </li>



                    <li className="submenu">

                      <a href="#"> <span> حساب ضريبة عامل اسرائيلي في الخارج</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/OutsideTaxCalc') ? "active" : ""}
                            href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/OutsideTaxCalc"> <AiOutlineCalculator size={18} /> <span> حساب الضريبة  </span> </a>
                        </li>

                      </ul>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/PaymentCalcRank') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/PaymentCalcRank"><span> <AiOutlineCalculator size={20} />  حساب الاجور حسب الرتبة </span>
                      </a>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/CmoparePaymentDev') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/CmoparePaymentDev"> <AiOutlineCalculator size={20} />  <span> مقارنات تطوير الاجور </span>
                      </a>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/EmployeeSalaryCalc') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/EmployeeSalaryCalc">  <AiOutlineCalculator size={20} /> <span> حساب رواتب الموظفين </span>
                      </a>
                    </li>

                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/InsuranceCalc') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/InsuranceCalc">تنسيق رسوم التأمين الوطني
                      </a>
                    </li>

                    <li className="submenu">

                      <a href="#"> <span> حسابات التعويض</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/CompensationCalc') ? "active" : ""}
                            href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/CompensationCalc"> <AiOutlineCalculator size={18} /> حسابات التعويض </a>
                        </li>

                      </ul>
                    </li>

                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/DeffCalc') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/DeffCalc"> <AiOutlineCalculator size={20} /> حاسوب فروقات تعويضات
                      </a>
                    </li>

                    <li className="submenu">

                      <a href="#"> <span> حاسوب امتناع تعويضات</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/NonCompCalc') ? "active" : ""}
                            href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/NonCompCalc"> <AiOutlineCalculator size={18} />  امتناع تعويضات </a>
                        </li>

                      </ul>
                    </li>

                    <li className="submenu">

                      <a href="#"> <span> حسابات الأجر المقتطع</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/DeductWageCalc') ? "active" : ""}
                            href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/DeductWageCalc"> <AiOutlineCalculator size={18} /> حسابات الأجر المقتطع </a>
                        </li>

                      </ul>
                    </li>
                    <li className="submenu">

                      <a href="#"> <span> حساب بدل نقاهة </span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('pages/sidebar-pages/payments-taxes/payments-loans-calc/RecupCalc') ? "active" : ""}
                            href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/RecupCalc"> <AiOutlineCalculator size={18} /> حساب بدل نقاهة </a>
                        </li>

                      </ul>
                    </li>


                  </ul>
                </li>

{/* 
                <li className="submenu">
                  <a href="#"> <span> جداول المعلومات</span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>


                    <li className="submenu">

                      <a href="#"> <span> جداول ضريبة الدخل</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li><a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel20') ? "active" : ""}
                          href="/pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel20"> <ImTable2 size={15} /> <span> 2020 </span> </a></li>


                        <li><a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel21') ? "active" : ""}
                          href="/pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel21">  <ImTable2 size={15} /> <span> 2021 </span> </a></li>




                        <li><a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel22') ? "active" : ""}
                          href="/pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel22"> <ImTable2 size={15} />   <span> 2022 </span> </a></li>




                      </ul>
                    </li>

          

                    <li className="submenu">

                      <a href="#"> <span> جداول التأمين الوطني</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>
                        <li>
                          <a className={pathname.includes('nationalIns-calculator') ? "active" : ""}
                            href="/nationalIns-calculator"> <ImTable2 size={15} /> جدول التامين الوطني </a>
                        </li>

                        <li>
                          <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/insurance-tax-tabels/InsuranceTaxTabel') ? "active" : ""}
                            href="/pages/sidebar-pages/payments-taxes/tabels-data/insurance-tax-tabels/InsuranceTaxTabel"> <ImTable2 size={15} /> جدول التامين الوطني </a>
                        </li>

                      </ul>
                    </li>
                    <li className="submenu">

                      <a href="#"> <span> جداول ضريبة القيمة المضافة</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/added-value-taxtabels/AddedVTax') ? "active" : ""}
                            href="/pages/sidebar-pages/payments-taxes/tabels-data/added-value-taxtabels/AddedVTax"> <ImTable2 size={15} /> ضريبة القيمة المضافة </a>
                        </li>

                      </ul>
                    </li>


                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/MinistryOfHealth') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/tabels-data/MinistryOfHealth"> <ImTable2 size={15} /> جدول ضريبة وزارة الصحة
                      </a>
                    </li>
                    <li className="submenu">

                      <a href="#"> <span> جدول قانون العمل</span> <span className="menu-arrow" /></a>
                      <ul style={{ display: 'none' }}>

                        <li>
                          <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/work-law-tabel/LawTabel ') ? "active" : ""}
                            href="/pages/sidebar-pages/payments-taxes/tabels-data/work-law-tabel/LawTabel "> <ImTable2 size={15} /> جدول قانون العمل </a>
                        </li>

                      </ul>
                    </li>

                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/LivingCoast') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/tabels-data/LivingCoast">تكلفة المعيشة
                      </a>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/MinCoast') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/tabels-data/MinCoast">الحد الأدنى  \ متوسط الأجر
                      </a>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/MinCoastYouth') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/tabels-data/MinCoastYouth">الحد الادنى لأجور الشباب
                      </a>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/AverageSalary') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/tabels-data/AverageSalary">معدل الاجور في القطاع العام
                      </a>
                    </li>
                    <li>
                      <a className={pathname.includes('pages/sidebar-pages/payments-taxes/tabels-data/ProtectedRent') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/tabels-data/ProtectedRent">الايجار المحمي
                      </a>
                    </li>
                    <li>
                          <a className={pathname.includes('main/employee-') ?"active" :""} 
                              href="#">تكلفة المعيشة
                          </a>
                      </li>

                  </ul>
                </li> */}

                <li className="submenu">

                  <a href="#"> <span> كشف رأس المال</span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>

                    <li>
                      <a className={pathname.includes('info') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/moneyreport/info"> <GoInfo size={20} /><span> ماهو كشف رأس المال </span> </a>
                    </li>
                    <li>
                      <a className={pathname.includes('moneyList') ? "active" : ""}
                        href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/moneyreport/moneyList"> <HiOutlineClipboardList size={20} /><span>قوائم مصاريف المعيشة</span> </a>
                    </li>

                  </ul>
                </li>
                <li className="submenu">
                  <a href="#"> <span className='reports-style'>  فحص ارجاع ضريبي </span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    <li><a className={pathname.includes('calc-rtx-21') ? "active" : ""} href="/calc-rtx-21">   <span> فحص سريع لسنة 2021  </span></a></li>
                    <li><a className={pathname.includes('calc-rtx') ? "active" : ""} href="calc-rtx">   <span> احتساب ضريبي- مستقل </span></a></li>
                  </ul>
                </li>

                <li className="submenu">
                  <a href=""><RiGovernmentLine size={20} /> <span>تصريح لسلطات الضريبة</span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    <li>
                      <a className={pathname.includes('shaam-tax') ? "active" : ""}
                        href="/shaam-tax"><ImTable2 size={18} /> <span>  لنكات   </span>
                      </a>
                    </li>


                    <li>
                      <a className={pathname.includes('shaam-codes') ? "active" : ""}
                        href="/shaam-codes"> <span>  <ImTable2 size={18} /> שאילתות לשע"מ </span></a>
                    </li>
                  </ul>
                </li>





              </ul>
            </li>

            <li className="submenu">
              <a href="#">
                <FaPercent size={16} />
                <span> ضريبة القيمة المضافة </span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: 'none' }}>
                <li>
                  <a className={pathname.includes('calculators/main/Calculator') ? "active" : ""}
                    href="/calculator-vat">احتساب قيمة مضافة سريع
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('month-vat-rpt') ? "active" : ""}
                    href="/month-vat-rpt">تعبئة كشف دوري
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('vat-table') ? "active" : ""}
                    href="/vat-table">جدول نسب القيمة المضافة
                  </a>
                </li>

              </ul>
            </li>

            <li className="submenu">
              <a href="#">
                <FaPercent size={16} />
                <span> التأمين الوطني  </span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: 'none' }}>
                <li>
                  <a className={pathname.includes('nationalIns-calculator') ? "active" : ""}
                    href="/nationalIns-calculator">التأمين الوطني 
                  </a>
                </li>
              </ul>
            </li>




            <li className="submenu">
              <a href="#"> <VscLaw size={25} /> <span>قوانين العمل</span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                {/* <li><a href=""><span>Level 2</span></a></li> */}
                {/* <li className="submenu ">
                  <a href="" className='sub-active' > <span> حاسبات قوانين العمل</span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    <li><a className={pathname.includes('EndOfServiceCalc') ? "active" : ""} href="/calculators/WorkLawsCalc/EndOfServiceCalc"> <AiOutlineCalculator size={18} />  <span> تعويضات نهاية الخدمة </span> </a></li>
                    <li><a className={pathname.includes('EndOfServiceCalc') ? "active" : ""} href=""> <AiOutlineCalculator size={18} />  <span> احتساب النقاهة  </span> </a></li>
                    <li><a className={pathname.includes('EndOfServiceCalc') ? "active" : ""} href=""> <AiOutlineCalculator size={18} />  <span> الاجازة النسوية  </span> </a></li>

                  </ul>
                </li> */}
                <li><a className={pathname.includes('mass-law') ? "active" : ""} href="/mass-law"> <AiOutlineCalculator size={18} />  <span> حاسبات و جداول   </span> </a></li>

              </ul>
            </li>




            <li className="submenu">
              <a href=""><i className="la la-money" /> <span>نظام الرواتب </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                <li>
                  <a className={pathname.includes('dash') ? "active" : ""} href="/dash"> <span>داش الرواتب </span></a>
                </li>
                <li>
                  <a href=""> <span>موظفين</span></a>
                </li>
                <li>
                  <a className={pathname.includes('payslip') ? "active" : ""}
                    href="/payslip"> <span>تلوش سريع </span></a>
                </li>
                <li>
                  <a href=""> <span> معطيات الشركة و تعاريف</span></a>
                </li>
                <li className="submenu">
                  <a href=""> <span>تقارير </span> <span className="menu-arrow" /></a>
                  <ul style={{ display: 'none' }}>
                    <li><a href=""><span>كشف 101</span></a></li>
                    <li><a href=""> <span>كشف 102</span></a></li>
                    <li> <a href=""><span> كشف 161</span></a></li>
                    <li> <a href="#"><span> كشف 126</span></a></li>
                  </ul>
                </li>

              </ul>
            </li>
            <li className="submenu">
              <a href=""><i className="la la-user" /> <span>موظفين </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                <li>
                  <a className={pathname.includes('dash') ? "active" : ""} href="/employees/all-employees"> <span>جميع الموظفين </span></a>
                </li>
                <li>
                  {/* <a className={pathname.includes('dash') ? "active" : ""} href="/employees/departments"> <span>أقسام </span></a> */}
                </li>
              </ul>
            </li>


            <li className="submenu">
              <a href="#"><i className="la la-dashboard" /> <span>أنظمة - Mass </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                <li><a
                //  className={pathname.includes('exchange-rate') ? "active" : ""}
                  href="https://mass-billing.web.app/"
                  target="_blank"
                  > نظام الفوترة - Mass Billing </a></li>
                <li className="submenu">
                  <a href="#"><i className="la la-money" />
                    <span> &nbsp; قروض &nbsp;</span> <span className="menu-arrow" />
                  </a>
                  <ul style={{ display: 'none' }}>
                    <li>
                      <a className={pathname.includes('calculators/main/Calculator') ? "active" : ""}
                        href="/calculators/main/Calculator"> قروض
                      </a>
                    </li>

                  </ul>
                </li>

              </ul>

            </li>



            <li className="submenu">
              <a href="#"><IoCarSportOutline size={25} />
                <span> مركبات</span> <span className="menu-arrow" />
              </a>
              <ul style={{ display: 'none' }}>
                <li>
                  <a className={pathname.includes('carExp') ? "active" : ""}
                    href="/pages/sidebar-pages/cars/carExp">  <ImTable2 size={15} /> <span>  مصاريف المركبات  </span>
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('TotalPrices') ? "active" : ""}
                    href="/pages/sidebar-pages/cars/TotalPrices">  <ImTable2 size={15} /> <span>  مجموعات الأسعار  </span>
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('FuelPrice') ? "active" : ""}
                    href="/pages/sidebar-pages/cars/FuelPrice">  <ImTable2 size={15} /> <span>  أسعار الوقود  </span>
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('InsurancePrice') ? "active" : ""}
                    href="/pages/sidebar-pages/cars/InsurancePrice">  <ImTable2 size={15} /> <span>  أسعار التأمين  </span>
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('LicensePrice') ? "active" : ""}
                    href="/pages/sidebar-pages/cars/LicensePrice">  <ImTable2 size={15} /> <span>  أسعار الترخيص  </span>
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('Report') ? "active" : ""}
                    href="/pages/sidebar-pages/cars/Report">  <FaWpforms size={15} /> <span>  تقرير مصاريف المركبات  </span>
                  </a>
                </li>

              </ul>
            </li>
            <li className="submenu">
              <a href=""><FaSignature size={25} /> <span>عقود , تقارير , مراسلات </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>

                <li>
                  <a className={pathname.includes('contracts-heb') ? "active" : ""}
                    href="/pages/sidebar-pages/contracts/contracts-heb"><IoDocumentTextOutline size={18} /> <span> عقود باللغة العبرية (עברית) </span>
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('reports-he') ? "active" : ""}
                    href="/reports-he"><IoDocumentTextOutline size={18} /> <span> مراسلات وتقارير (עברית) </span>
                  </a>
                </li>
                <li>
                  <a className={pathname.includes('contracts-ar') ? "active" : ""}
                    href="/pages/sidebar-pages/contracts/contracts-ar"> <span>  <IoDocumentTextOutline size={18} /> عقود باللغة العربية </span></a>
                </li>
              </ul>
            </li>

            <li className="submenu">
              <a href="#"><RiGovernmentLine size={20} /> <span>شركات و جمعيات </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                <li>
                  <a className={pathname.includes('rasham-comp') ? "active" : ""}
                    href="/rasham-comp">مراقب الشركات  </a>
                </li>

                <li>
                  <a className={pathname.includes('rasham-as') ? "active" : ""}
                    href="/rasham-as">مراقب الجمعيات  </a>
                </li>

                <li>
                  <a className={pathname.includes('payslip') ? "active" : ""}
                    href="/rasham-ct">شهادة ادارة سليمة (جمعيات)  </a>
                </li>

              </ul>
            </li>


            <li className="submenu">
              <a href=""><BsMoonStars size={25} className='mosque-flip moon' /> <span>احتسب زكاتك </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                <li>
                  <a className={pathname.includes('ZakatInfo') ? "active" : ""}
                    href="/calculators/zakat-calc/ZakatInfo"> <GoInfo size={18} /><span> معلومات عن نصاب الزكاة</span></a>
                </li>

                <li>
                  <a className={pathname.includes('ZakatCalc') ? "active" : ""}
                    href="/calculators/zakat-calc/ZakatCalc"> <AiOutlineCalculator size={18} /> <span> احتساب الزكاة</span></a>
                </li>

              </ul>
            </li>

            <li className={pathname.includes('dictionary') ? "active" : ""}>
              <a href="/dictionary"> <ImTable2 size={23} /> <span>قواميس</span></a>
            </li>
            <li className="submenu">
              <a href=""><BsQuestionCircle size={23} className='mosque-flip moon' /> <span>أسئلة و أجوبة </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                <li>
                  <a className={pathname.includes('recovery-faqs') ? "active" : ""}
                    href="/mass-law-recovery-faqs"> <span> النقاهة ( FAQ )</span></a>
                </li>



              </ul>
            </li>

            {/* <li className={pathname.includes('loginpage') ? "active" : ""}>
              <a href="https://massportall-firebaseapp-com.translate.goog/?_x_tr_sl=ar&_x_tr_tl=iw&_x_tr_hl=en&_x_tr_pto=wapp"> <MdLanguage size={23} /> <span>עברית</span></a>

            </li> */}

            {/* <li className={pathname.includes('allemployees') ? "active" : ""}>
              <a className={pathname.includes('allemployees') ? "active" : ""}
                href="/pages/sidebar-pages/Employees/allemployees"><RiTeamFill size={25} /><span>مطورين</span>
              </a>
            </li> */}
            <li className={pathname.includes('about-us') ? "active" : ""}>
              <a href="/about-us"> <BsInfoCircle size={23} /> <span>من نحن </span></a>
            </li>
            <li className={pathname.includes('privacy-policy') ? "active" : ""}>
              <a href="/privacy-policy"> <RiFileList3Line size={23} /> <span>النظام الداخلي</span></a>
            </li> 
            <li className="submenu">
              <a href="#"><i className="la la-dashboard" /> <span>مؤشر - Mass </span> <span className="menu-arrow" /></a>
              <ul style={{ display: 'none' }}>
                {/* <li><a
                  href="https://mass-billing.web.app/"
                  target="_blank"
                  > نظام الفوترة - Mass Billing </a></li>
                <li className="submenu">
                  <a href="#"><i className="la la-money" />
                    <span> &nbsp; قروض &nbsp;</span> <span className="menu-arrow" />
                  </a>
                  <ul style={{ display: 'none' }}>
                    <li>
                      <a className={pathname.includes('calculators/main/Calculator') ? "active" : ""}
                        href="/calculators/main/Calculator"> قروض
                      </a>
                    </li>

                  </ul>
                </li> */}

              </ul>

            </li>
            <li className={pathname.includes('mass-fintec-prices') ? "active" : ""}>
              {/* <a href="/mass-fintec-prices"> <RiFileList3Line size={23} /> <span></span></a> */}
              <a href="/mass-fintec-prices"><i className="la la-money" /> <span> أسعار خدمة المحاسبة </span></a>

            </li>

            <li className={pathname.includes('contact') ? "active" : ""}>
              <a href="/contact"> <FiPhoneCall size={23} /> <span>اتصل بنا</span></a>
            </li>


            <li className={pathname.includes('mass-modules') ? "active" : ""}>
              <a className={pathname.includes('/mass-modules') ? "active" : ""}
                href="/mass-modules" ><AiOutlineAppstoreAdd size={23} className='privacy' /><span className="privacy">Lobby</span>
              </a>
            </li>
            <li className='version-style'><p>V 53</p></li>


            {/* <li className="submenu">
                 <a href=""><i className="la la-share-alt" /> <span>Multi Level</span> <span className="menu-arrow" /></a>
                 <ul style={{display: 'none'}}>
                   <li className="submenu">
                     <a href=""> <span>Level 1</span> <span className="menu-arrow" /></a>
                     <ul style={{display: 'none'}}>
                       <li><a href=""><span>Level 2</span></a></li>
                       <li className="submenu">
                         <a href=""> <span> Level 2</span> <span className="menu-arrow" /></a>
                         <ul style={{display: 'none'}}>
                           <li><a href="">Level 3</a></li>
                           <li><a href="">Level 3</a></li>
                         </ul>
                       </li>
                       <li><a href=""> <span>Level 2</span></a></li>
                     </ul>
                   </li>
                   <li>
                     <a href=""> <span>Level 1</span></a>
                   </li>
                 </ul>
               </li> */}

          </ul>

        </div>
      </div>
    </div>

  );
}






