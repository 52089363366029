/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
// import TxTbl from './TxTblAbt';
import { TxAbtCalc } from './TxAbtCalc';

export default class TaxAbtCalc extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | احتساب ضريبة الأملاك     </title>
					<meta name="description" content="MassPortal - احتساب ضريبة الأملاك  " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<h1>احتساب ضريبة الأملاك </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
						</div>
						<div className="row">
							
						<div className=' zakat-div'>
									<TxAbtCalc />
									</div>
							{/* <div className='col-lg-1'>
							</div> */}
						</div>
						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>
		);
	}
}
