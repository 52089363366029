/**
 * Signin Firebase
 */

import React, { useState, useRef } from 'react';

import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
// import {Applogo} from '../Entryfile/imagepath.jsx'
import { massPortall } from '../../Entryfile/imagepath';
import emailjs from '@emailjs/browser';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { useAlert } from 'react-alert'
import ReactTooltip from "react-tooltip";



export default function Contact() {
  // Form initial state
  const INITIAL_STATE = {
    name: "",
    email: "",

    subject: "",


  };


  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const form = useRef();
  // let navigate = useNavigate();
  const [contact, setContact] = useState(INITIAL_STATE);


  const { register, handleSubmit, watch, formState: { errors } } = useForm();



  const onSubmit = async e => {

    // =============== Emailjs Script ==================================
    emailjs.sendForm('service_9sh2mfb', 'template_2u6zqas', form.current, 'jsge89fz8gdshfhzl')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    // =============== Emailjs Script ==================================

   
    const url = '/thank-you';
    setTimeout(() => window.location.href = url, 1000);
  };

  // const handleChange = e => {
  //   const { name, value } = e.target;
  //   setContact(prevState => ({ ...prevState, [name]: value }));
  //   // console.log(contact)
  // }
  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   console.log("email : ", email);
  //   console.log("name : ", name);
  // }



  return (
    <div className="page-wrapper">
      <div className="main-wrapper">
        <Helmet>
          <title>MassPortal | اتصل بنا </title>
          <meta name="description" content="MassPortal | اتصل بنا " />
        </Helmet>
        <div className="account-content">
          {/* <a href="/rtl/applyjob/joblist" className="btn btn-primary apply-btn">Apply Job</a> */}
          <div className="container">
            {/* Account Logo */}
            <div className="account-logo">
              <a href="/"><img src={massPortall} alt="Mass Portal" /></a>
            </div>
            {/* /Account Logo */}
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">اتصل بنا   </h3>
                {/* <p className="account-subtitle">Access to our dashboard</p> */}
                {/* Account Form */}
                <form ref={form} onSubmit={handleSubmit(onSubmit)} >
                  <div className="form-group">

                    <div className="row">
                      <div className="col">
                        <label>الاسم </label>
                      </div>

                    </div>
                    <input className="form-control"
                      type="text"
                      name='name'
                      onChange={(e) => setName(e.target.value)}
                      {...register("name", { required: true })}
                      
                    // ref={register({ required: true })}


                    />

                    <div className='invalid-feedback' style={{ display: 'block' }}>
                     {errors.name?.type === 'required' && "الرجاء ادخال الاسم"}
                    </div>
                  </div>



                  <div className="form-group">

                    <label>البريد الالكتروني</label>
                    <input className="form-control"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                      {...register("email", { required: true ,  pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i})}
                      name="email"
                    />
                     <div className='invalid-feedback' style={{ display: 'block' }}>
                     {errors.email?.type === 'required' && "الرجاء التأكد من البريد الالكتروني"}
                    </div>
                  </div>
                  <div className="form-group">

                    <div className="row">
                      <div className="col">
                        <label>أريد أن أستفسر عن  </label>
                      </div>

                    </div>
                    <input className="form-control textArea-contact"
                      type="textArea"
                      cols="0"
                      rows="5"
                      name='subject'
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    />
                  </div>

                  <div className="form-group text-center" >
                    <a className="btn btn-primary account-btn"  onClick={handleSubmit(onSubmit)}  >
                      ارسال </a>

                  </div>
                  {/* <ReactTooltip type="success"  id="ex-tip" place="right" effect="solid">
            شكرا لك , تم الارسال بنجاح 
          </ReactTooltip> */}

                </form>

               
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



