
import React, { useState, useEffect } from 'react'
import InvoiceStyle from './InvoiceStyle'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { BiShekel, BiSearchAlt, BiArrowBack, BiExit } from 'react-icons/bi'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { BsFillPersonLinesFill, BsFillFileEarmarkPersonFill, BsCardList } from 'react-icons/bs'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { v4 as uuidv4 } from 'uuid'
import TableForm from './TableForm'

// import TableInvoice from './TableInvoice'
// import Customers from '../../../data/customers'
// import Contacts from '../../../data/contacts'
import { massportallogo } from '../../../Entryfile/imagepath'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { set } from 'react-hook-form'

// import { useAuthContext } from '../../../../hooks/useAuthContext'
// import { usePostNestDocs } from '../../../../hooks/usePostNestDocs'
// import { useFetchNestDocs } from '../../../../hooks/useFetchNestDocs'
// import { useCollection } from '../../../../hooks/useCollection'
// import { useUpdateNestDoc } from '../../../../hooks/useUpdateNestDoc'


import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export default function CreateInvoice({
  logo,

}) {

  // const {user} = useAuthContext()
  // const { documents:CoData, err } = useCollection('mpbclients', ["id", "==", user.uid],)
  // const { documents:customers, error } = useFetchNestDocs('mpbclients', user.uid, "customers")
  // const { documents:invoiceNum, er } = useFetchNestDocs('mpbclients', user.uid, "invoicenumbers",["title", "==", "invoices"])
  // const { addDocument, response } = usePostNestDocs('mpbclients', user.uid ,"invoices")
  // const { addDocument:UpdateInvoiceNum, response:invRes } = useUpdateNestDoc('mpbclients', user.uid ,"invoicenumbers","invoices")

  const printRef = React.useRef()

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [value, setValue] = useState(0)
  const [taxNum, setTaxNum] = useState("")
  const [email, setEmail] = useState('')
  const [perTax, setPerTax] = useState(0)
  const [perTax2, setPerTax2] = useState(0)
  const [address, setAddress] = useState('')
  const [cityAddress, setCityAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [invoiceAddress, setInvoiceAddress] = useState('')
  const [dateInvoice, setDateInvoice] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [invoiceNumber, setInvoiceNumber] = useState(0)
  const [startinvoiceNumber, setStartInvoiceNumber] = useState(0)
  // const [companyName,setCompanyName]=useState('')
  const [details, setDetails] = useState('')
  const [catalogNum, setCatalogNum] = useState('')
  const [price, setPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [discountForProduct, setDiscountForProduct] = useState(0)
  const [exemptionAmount, setExemptionAmount] = useState(0)
  const [amount, setAmount] = useState(0)
  const [amounts, setAmounts] = useState(0)
  const [tax, setTax] = useState(0)
  const [isExemption, setIsExemption] = useState(false)
  const [totalAfTax, setTotalAfTax] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalAfDiscount, setTotalAfDiscount] = useState(0)
  const [list, setList] = useState([])
  const [information, setInformation] = useState('')
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [convertedContent, setConvertedContent] = useState();

  const [lastInvoiceNum, setLastInvoiceNum] = useState(0)
  const [invoiceCounter, setInvoiceCounter] = useState(0)
  const [isSearch, setIsSearch] = useState(false)
  const [contacts, setContacts] = useState([])
  const [isResult, setIsResult] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [filtered, setFiltered] = useState('')
  const [isAddContact, setIsAddContact] = useState(true)
  const [data, setData] = useState([])
  const [coData, setCoData] = useState()


  //MODAL
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  //MODAL
  const [showMssg, setShowMssg] = useState(false)
  const handleCloseMssg = () => setShowMssg(false);
  const handleShowMssg = () => setShowMssg(true);

  // LANGUAGE
  const [lang, setLang] = useState('')
  const [DEAR, setDEAR] = useState('')
  const [TAXNUMBER, setTAXNUMBER] = useState('')
  const [DATE, setDATE] = useState('')
  const [PAYUNTIL, setPAYUNTIL] = useState('')
  const [TOTAL, setTOTAL] = useState('')
  const [TAXINVOICENUMBER, setTAXINVOICENUMBER] = useState('')
  const [NOTE, setNOTE] = useState('')
  const [SIGNATURE, setSIGNATURE] = useState('')
  const [ORIGINAL, setORIGINAL] = useState('')
  // const[EXEMPT,setEXEMPT] = useState('')


  let nam = ''
  let addres = ''
  let mail = ''
  let txnum = 0
  let phon = ''
  let city = ''

  // Text Editor Handler
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();

  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
    // console.log("Editor state:", currentContentAsHTML)
}

const createMarkup = (html) => {
  return {
      __html: DOMPurify.sanitize(html)
  }
}
  //     useEffect(()=>{
  //       setData(customers)
  //       // console.log(data,"data")
  //       if(invoiceNum){
  //         setInvoiceCounter((invoiceNum[0].counter)*1)
  //         setInvoiceNumber((invoiceNum[0].start)*1 + invoiceCounter )
  //         setStartInvoiceNumber(invoiceNum[0].start)
  //         // setLastInvoiceNum((invoiceNum[0].start)*1 + (invoiceNum[0].counter)*1 - 1)
  //         if (invoiceCounter > 0) {
  //            setLastInvoiceNum(invoiceNumber -1)}
  //         else setLastInvoiceNum(0) 
  //       }

  //      if (CoData){
  //       setCoData(CoData[0])
  //       setPerTax(CoData[0].taxPer)
  //       setPerTax2(perTax )
  //       // console.log(perTax,"PER TAX")
  //      }
  //       if( customers && data){
  //         console.log('invoiceNum',invoiceNumber)
  //         nam =  data[value].firstName +" " + data[value].lastName;
  //         // console.log(nam,"nam")
  //         addres = data[value].address
  //         mail = data[value].email
  //         txnum = data[value].vatFileNumber
  //         phon = data[value].phoneNumber
  //         city = data[value].cityName
  //          }
  //          let date = new Date();
  //          let current_date = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+ date.getDate();
  //          setDateInvoice(current_date)
  //          setDueDate(current_date)
  //            setAddress(addres)
  //            setEmail(mail)
  //            setTaxNum(txnum)
  //            setName(nam);
  //            setPhone(phon);
  //            setCityAddress(city)
  //           //  console.log(name,"name")
  //           },[customers,data,value,CoData,coData,invoiceNum,invoiceNumber])

  //           // useEffect(()=>{


  //     // },[customers,data,CoData,coData])



  // const handleSubmit = (e)=>{
  //   // e.preventDefault()
  //   addDocument({
  //     name,
  //     taxNum,
  //     address,
  //     city,
  //     phone,
  //     email,
  //     total,
  //     lastInvoiceNum,
  //     dateInvoice,
  //     dueDate,
  //     amounts,
  //     tax,
  //     discount,
  //     information,
  //     totalAfDiscount,
  //     perTax,
  //     lang,
  //     invoiceNumber,
  //     exemptionAmount,
  //     totalAfTax,
  //     // UserID: user.uid,
  //     list,
  //     CustomersID: data[value].id,
  //     paid:false,
  //     amountPaid:0,
  //   }).then(()=>{
  //     // console.log("list",list)
  //     console.log("تم إنشاء الفاتورة")

  //   })

  //   console.log("handleSubmit Run!")



  //   // alert("تم إنشاء الفاتورة")



  // }

  // // reset the form fields
  // useEffect(() => {
  //   // console.log("response is: ",response)
  //   if (response.success) {
  //     UpdateInvoiceNum({
  //       counter:invoiceCounter*1 +1,
  //     }).then(()=>{
  //       handleDownloadPdf()
  //       setShowModal2(true)
  //     }).then(()=>{
  //     //   setName('');
  //     // setTaxNum('')
  //     // setAddress('')
  //     // setPhone('');
  //     // setEmail('')
  //     setTotal(0)
  //     setTotalAfDiscount(0)
  //     // setDateInvoice('')
  //     // setDueDate('')
  //     setList([])
  //     setAmounts(0)
  //     setTax(0.00)
  //     setDiscount(0)
  //     setInformation('')
  //     // setPerTax(17)
  //     // setCityAddress('')
  //     setTotalAfTax(0)
  //     setExemptionAmount(0)
  //     // setValue(0)

  //     // setLang('')
  //     // setShow(!show)
  //     })


  //   }
  // }, [response.success])

  //    useEffect(()=>{  
  //     console.log(customers)
  //     const Translate=()=>{
  //       if(lang === 'en'){
  //         setDEAR('Dear : ')
  //         setTAXNUMBER('Tax Number :')
  //         setDATE('Invoice Date :')
  //         setPAYUNTIL('Pay Until :')
  //         setTOTAL('Total :')
  //         setTAXINVOICENUMBER('Tax Invoice No. ')
  //         setNOTE('Notes :')
  //         setSIGNATURE('Signature :')
  //         setORIGINAL('Original')
  //         // setEXEMPT('Exempt from tax ')
  //       }
  //       else if (lang === 'he'){
  //         setDEAR('לכבוד :')
  //         setTAXNUMBER("  מספר ע'מ :")
  //         setDATE('תאריך חשבונית : ')
  //         setPAYUNTIL('לתשלום עד :')
  //         setTOTAL('סה"כ לתשלום :')
  //         setTAXINVOICENUMBER("חשבונית מס מס'")
  //         setNOTE('הערות : ')
  //         setSIGNATURE('חתימה')
  //         setORIGINAL('מקור')
  //         // setEXEMPT('פטור ממ"ע')
  //       }else {
  //         setDEAR('حضرة :')
  //         setTAXNUMBER(' رقم الملف الضريبي  :')
  //         setDATE('تاريخ الفاتورة :')
  //         setPAYUNTIL('تاريخ الاستحقاق :')
  //         setTOTAL('المبلغ الكلي')
  //         setTAXINVOICENUMBER('فاتورة رقم')
  //         setNOTE('ملاحظات :')
  //         setSIGNATURE('التوقيع :')
  //         setORIGINAL('أصلية')
  //         // setEXEMPT('معفي ض.ق.م')


  //       }
  //     }
  //     Translate()


  //    },[lang])





  const handleDownloadPdf = async () => {
    // handleSubmit()
    // console.log("new invoice created !!")
    // console.log(" here ")
    const iaactive = true;
    const element = printRef.current;
    // element.style.background = '#FFFFFF';

    const canvas = await html2canvas(element);

    const d = new Date().toLocaleString() + "";
    const footer = d + '             www.mass-portal.com';
    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 290;
    var imgHeight = (canvas.height * imgWidth / canvas.width);
    var doc = new jsPDF();
    var position = 18; // give some top padding to first page
    // Data
    doc.addImage(imgData, 'PNG', 2, position - 10, imgWidth -8, imgHeight);
    doc.addImage(massportallogo, 'png', 2, 0, 20, 20);
    doc.text(footer, 30, pageHeight , { baseline: 'bottom' });

    doc.save('create.pdf');
  };


  //     function addZero(i) {
  //       if (i < 10) {i = "0" + i}
  //       return i;
  //     }
  //     const date = new Date();
  //     let h = addZero(date.getHours());
  //     let m = addZero(date.getMinutes());
  //     let s = addZero(date.getSeconds());
  //     let time = h + ":" + m + ":" + s;
  //     const d = new Date().toLocaleString() + "";
  //       let current = d.split(',');
  //       const today = current[0];
  //       const todaySplitArr = today.split('/');
  //       const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];


  //     //-----------------get the date of today---------------------
  //     //---------------- calculate amounts of products-------------
  //     // - get the data for every contact ==> name,address, email ,etc... -

  //     // useEffect(()=>{

  //     //   },[value,])




  //     // ------------- function search contact---------

  //     useEffect(()=>{
  //       // console.log(contacts,"contacts")
  //       const searchContact = ()=>{
  //         let filter = [];
  //         let fullname;

  //         if(isSearch && customers && data ){
  //         for(let i=0; i < data.length; i++){
  //           fullname = data[i].firstName + data[i].lastName
  //          for(let j=0; j <= inputValue.length; j++ ){
  //             if (
  //               (
  //                (data[i].firstName[j] === inputValue[j] && data[i].firstName[j-1] === inputValue[j-1]) ||
  //                (data[i].lastName[j] === inputValue[j] && data[i].lastName[j-1] === inputValue[j-1]) || 
  //                (fullname[j] === inputValue[j] && fullname[j-1] === inputValue[j-1])
  //                ) 
  //                &&
  //               (j == (inputValue.length)-1) 
  //                ){
  //               filter.push(data[i],)
  //             }

  //          }
  //         }
  //         if (filter.length !=0 ){
  //           setIsResult(true)
  //           setFiltered(filter)
  //         } 
  //       }
  //       else if(!isSearch){
  //         setFiltered([])
  //         setIsResult(false)
  //         filter=[];
  //       }       
  //         }
  //         searchContact()
  //     },[inputValue,data])



  //     // -function get index of contact by id when click on contact in result table

  //     const getIndexById = (id)=>{
  //       data.map((c,index)=>{
  //         if (c.id == id ){
  //           setValue(index)
  //         }
  //       }
  //       )
  //     }

  //     //---------------- function handleChange ---------------------
  //     // function nameChange(e) {
  //     //   if(nam === ""){
  //     //     setName(e.target.value)
  //     //   }
  //     // }
  //     // function nameChange(e) {
  //     //   if(name === "" || name === ""){
  //     //     setName(e.target.value)
  //     //   }
  //     // }
  //     // function addressChange(e) {
  //     //   if(addres === ""){
  //     //     setAddress(e.target.value)
  //     //   }
  //     // }
  //     // function emailChange(e) {
  //     //   if(mail === ""){
  //     //     setEmail(e.target.value)
  //     //   }
  //     // }
  //     // function taxNumChange(e) {
  //     //   if(txnum === ""){
  //     //     setTaxNum(e.target.value)
  //     //   }
  //     // }

  //     const ONCHANGE = (e)=>{
  //       if(e.target.value > -1 ){
  //         setValue(e.target.value)
  //       }
  //     }

  //-------------------start rendering--------------------------


  return (
    <div className="content container-fluid">

      {/* ---------------INVOICE START HERE--------------------- */}

      {show ? <InvoiceStyle
        lang={lang}
        DEAR={DEAR}
        TAXNUMBER={TAXNUMBER}
        TAXINVOICENUMBER={TAXINVOICENUMBER}
        DATE={DATE}
        PAYUNTIL={PAYUNTIL}
        TOTAL={TOTAL}
        NOTE={NOTE}
        SIGNATURE={SIGNATURE}
        ORIGINAL={ORIGINAL}
        name={name}
        printRef={printRef}
        taxNum={taxNum}
        address={address}
        cityAddress={cityAddress}
        phone={phone}
        email={email}
        logo={logo}
        coData={coData}

        list={list}
        amounts={amounts}
        tax={tax}
        discount={discount}
        total={total}
        information={information}
        totalAfDiscount={totalAfDiscount}
        perTax={perTax}
        dueDate={dueDate}
        totalAfTax={totalAfTax}
        exemptionAmount={exemptionAmount}

        handleDownloadPdf={handleDownloadPdf}
        // handleSubmit={handleSubmit}

        show={show}
        setShow={setShow}
        showModal={showModal2}
        setShowModal={setShowModal2}
        invoiceNumber={invoiceNumber}
        dateInvoice={dateInvoice}


      />
        :
        ////////////////////////////////////// ENGLISH INVOICE ////////////////////////




        ////////////////////////// form start here///////////////////////////////////// 



        <div >   {/* Page Content */}
          <div >
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">قيود مصاريف و مشتريات  </h3>
                  <h1 className="page-title text-center bold">JV رقم <span className='text-success'>{invoiceNumber + '#'}</span> </h1>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
                    {/* <li className="breadcrumb-item active">Create Invoice</li> */}
                  </ul>
                  {lastInvoiceNum && <h4 className=" text-right ">رقم اخر قيد أصدر  <span className='bold'>{lastInvoiceNum + '#'}</span></h4>}

                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div >
                  <div className='helping-nav-mk'>
                    <div className='right'>
                      {!isSearch && <div>
                        <a href="/client-new" className='text-success  m-4 add-mk' ><FaFileInvoiceDollar size={25} /> حساب مصاريف جديدة </a>

                        {/* <a 
                  href="create-quick-invoice" 
                  className='text-success  add-mk' 
                  
                  > <span > < BsFillFileEarmarkPersonFill size={25} /> زبون طيار </span></a> */}
                        <a
                          href="#"
                          className='text-success  m-4 add-mk'
                          onClick={() => setIsSearch(!isSearch)}
                        > <span > < BiSearchAlt size={25} /> بحث </span></a>
                      </div>
                      }

                      {isSearch &&
                        <a
                          href="#"
                          className='text-success text-right m-4 add-mk fs-5'
                          onClick={() => {
                            setIsSearch(!isSearch);
                            setInputValue('')
                          }}
                        >
                          رجوع  < BiArrowBack size={25} />
                        </a>}
                    </div>
                    {/* <div className='center'>

          </div> */}
                    <div className='left' onClick={handleShowModal} >
                      <BiExit size={25} /> <span>خروج</span>
                    </div>

                    <Modal show={showModal} onHide={handleCloseModal}>
                      <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                      </Modal.Header>
                      <Modal.Body>هل تود حفظ مسودة؟</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal} href="/" >
                          خروج
                        </Button>
                        <Button variant="primary" onClick={handleCloseModal}>
                          نعم, حفظ مسودة
                        </Button>
                      </Modal.Footer>
                    </Modal>

                  </div>
                  {!isSearch && <div className='mb-4 input-group'>
                    <a
                      href="#"
                      className='text-success  m-2  input-group-text'
                      onClick={() => {
                        setIsAddContact(false)
                        setIsSearch(false)
                        setName(nam)
                        setAddress(addres)
                        setEmail(mail)
                        setTaxNum(txnum)
                      }
                      }
                    > <span > <BsCardList size={25} /> قائمة المصاريف  </span>
                    </a>
                    <div className='m-2 w-50-mk'>
                     <div>
                        <select className="select select-sales-mk ">
                        <option > مشتريات </option>
                        <option > مصاريف الهاتف </option>
                        <option > مصاريف العقود </option>
                     
                        </select>
                      </div>
                      {/* {data && <div>
                        <select
                          className="select select-sales-mk "
                          value={value}
                          onChange={e => {
                            ONCHANGE(e)
                          }}
                        >

                          {data.map((contact, i) =>

                            <option key={contact.id} value={i} >{contact.firstName} {contact.lastName}</option>
                          )}
                        </select>
                      </div>} */}
                    </div>
                  </div>}
                  <div className="row">




                    {/* -----------------------------------searchContact--------------------------------- */}


                    {isSearch && <div>
                      <div className="form-group">

                        {/* <a href="/client-new" className='text-success text-center m-4 add-mk fs-5' ><AiOutlineUserAdd size={25}/> زبون جديد </a>
                 <a 
                  href="#" 
                  className='text-success text-right m-4 add-mk fs-5' 
                  onClick={()=> {
                    setIsSearch(!isSearch);
                    setName('')
                    setAddress('')
                    setEmail('')
                    setTaxNum('')
                  }}
                  > <span > < BsFillFileEarmarkPersonFill size={25} /> زبون طيار </span></a> */}



                        <input className='form-control input-mk' onChange={(e) => {
                          setInputValue(e.target.value);
                          setFiltered([])
                        }} placeholder="ابحث " />


                      </div>
                    </div>
                    }
                    {/* ----------------------------Render The Filtered Contact --------------------------- */}


                    {isSearch && isResult && <div className="card">
                      <div className="card-header">
                        <h4 className="card-title mb-0 text-center privacy">نتائج البحث </h4>
                      </div>
                      <table className='table table-nowrap  p-5 '>
                        <thead className='privacy fs-5'>
                          <th >الاسم</th>
                          <th>رقم الهاتف</th>
                          <th>البريد الالكتروني</th>
                          <th>الحالة</th>
                        </thead>
                        <tbody className='tr-mk'>
                          {filtered.map((c, i) =>
                            <tr className="fs-5 " key={c.id}
                              onClick={() => {
                                getIndexById(c.id);
                                // setValue(i)
                                setIsSearch(!isSearch)
                              }}
                            >
                              <td className=''>{c.firstName} {c.lastName}</td>
                              <td className=''>{c.phoneNumber}</td>
                              <td className=''>{c.email}</td>
                              {/* <td className=''>{c.ContactStatus}</td> */}
                            </tr>
                          )}
                          {/* {names.filter(name => name.includes('J')).map(filteredName => (
      <li>
        {filteredName}
      </li>
    ))} */}
                        </tbody>
                      </table>
                    </div>}


                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>اسم المصروف   <span className="text-danger">*</span></label>

                        <input className="form-control" type="name"
                          onChange={(e) => {
                            // nam === "زبون  طيار" || name == undefined ? "name" :  setName(e.target.value) 
                            setName(e.target.value)
                          }}
                          value={name}
                          required
                        />



                      </div>
                    </div>

                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>رقم حساب المصروف  <span className="text-danger">*</span></label>
                        <input
                          className="form-control"
                          //  onChange={taxNumChange}
                          onChange={e => setTaxNum(e.target.value)}
                          value={taxNum}
                          required
                        />

                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>نوع المصروف</label>
                        <input className="form-control"
                          type="text"
                          value={email}
                          //  onChange={emailChange}
                          onChange={e => setEmail(e.target.value)}

                        />
                        {/* <input className="form-control" type="email" onChange={e=>setEmail(e.target.value)}/> */}

                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>مفند</label>
                        <input className="form-control"
                          type="text"
                          value={email}
                          //  onChange={emailChange}
                          onChange={e => setEmail(e.target.value)}

                        />
                        {/* <input className="form-control" type="email" onChange={e=>setEmail(e.target.value)}/> */}

                      </div>
                    </div>
                    {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>ضريبة </label>
                  <select className="select select-sales" onChange={e=>setPerTax(e.target.value)} value={perTax}>
                    <option value={perTax2 }>ض.ق.م - {perTax2 + "%"}</option>
                    <option  value={0}>معفى - {0 + "%"}</option>
                    <option value={17}>GST</option>
                  </select>
                </div>
              </div> */}
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>رصيد المصروف قبل ادخال القيود </label>
                        {/* <textarea className="form-control " rows={1} defaultValue={""} /> */}
                        <input className="form-control " type="text"
                          onChange={(e) => setAddress(e.target.value)}
                          value={address}
                        // onChange={addressChange}
                        />

                        {/* <input className="form-control" type="address" onChange={e=>setAddress(e.target.value)}/> */}


                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label >عدد القيود  </label>
                        {/* <textarea className="form-control" rows={1} defaultValue={""} /> */}
                        <input className="form-control " type="text"
                          placeholder={address}
                          onChange={e => setInvoiceAddress(e.target.value)} />

                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>تاريخ أخر تعديل للحساب    <span className="text-danger">*</span></label>
                        <div className="cal-icon">
                          <input className="form-control datetimepicker" type="text"
                            // onChange={e=>setDateInvoice(e.target.value)}
                            value={dateInvoice} />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <label>تاريخ تعديل الحساب  <span className="text-danger">*</span></label>
                        <div className="cal-icon">
                          <input className="form-control datetimepicker" type="text"
                            onChange={e => setDueDate(e.target.value)}
                            value={dueDate} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    {/* ---------------- Table Form --------------------- */}
                    <TableForm
                      details={details}
                      setDetails={setDetails}
                      quantity={quantity}
                      setQuantity={setQuantity}
                      price={price}
                      setPrice={setPrice}
                      amount={amount}
                      setAmount={setAmount}
                      total={total}
                      setTotal={setTotal}
                      totalAfDiscount={totalAfDiscount}
                      setTotalAfDiscount={setTotalAfDiscount}
                      totalAfTax={totalAfTax}
                      setTotalAfTax={setTotalAfTax}
                      catalogNum={catalogNum}
                      setCatalogNum={setCatalogNum}
                      perTax={perTax}
                      setPerTax={setPerTax}
                      tax={tax}
                      setTax={setTax}
                      discountForProduct={discountForProduct}
                      setDiscountForProduct={setDiscountForProduct}
                      amounts={amounts}
                      setAmounts={setAmounts}
                      discount={discount}
                      setDiscount={setDiscount}
                      isExemption={isExemption}
                      setIsExemption={setIsExemption}
                      exemptionAmount={exemptionAmount}
                      setExemptionAmount={setExemptionAmount}
                      list={list}
                      setList={setList}
                    />

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>نص اضافي للقيد  </label>
                          <div style={{ border: "1px solid black", padding: '10px', minHeight: '300px', backgroundColor:"#16191c"}}>
                            <Editor
                              editorState={editorState}
                              toolbarClassName="rdw-editor-toolbar"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              
                              onEditorStateChange={handleEditorChange}
                            />
                          </div>
                          {/* <textarea className="form-control" onChange={(e) => setInformation(e.target.value)} value={information} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal show={showMssg} onHide={handleCloseMssg}>
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>يرجى تعبئة الحقول المطلوبة, لايمكنك إنشاء قيد مالي</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseMssg}  >
                      إغلاق
                    </Button>

                  </Modal.Footer>
                </Modal>
                <div className="submit-section">
                  <button
                    type='submit'
                    className="btn btn-primary submit-btn m-r-10"
                    //  onClick={handleDownloadPdf} data-tip data-for="pdf-tip"
                    onClick={() => {
                      total ? setShow(!show) : handleShowMssg();
                      setLang('ar');
                    }}
                  >
                    معاينة AR
                  </button>
                  <button
                    className="btn btn-primary submit-btn"
                    type='submit'
                    onClick={() => {
                      total ? setShow(!show) : handleShowMssg();
                      setLang('en');
                    }}
                  >
                    معاينة EN
                  </button>
                  <button
                    type='submit'
                    className="btn btn-primary submit-btn m-r-10"
                    onClick={() => {
                      total ? setShow(!show) : handleShowMssg();
                      setLang('he');
                    }}
                  >
                    معاينة HE
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Content */}</div>}
    </div>
  )
}

