/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { AiOutlineFilePdf, AiOutlineLink, AiFillCalculator, AiOutlineCalculator } from "react-icons/ai";
import { BiLock } from 'react-icons/bi';
import { massportallogo } from '../../../../Entryfile/imagepath';
class ShaamTaxAr extends Component {

  render() {
    return (
      <div >

        {/* Page Content */}
        <div >


          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <h2 className='links-title text-center '> تصريح دوري </h2>
                  <div className="roles-menu ">
                    <ul>
                      <li >
                        <a href="https://secapp.taxes.gov.il/GMMIKDAMA/firstPage.aspx" target='_blank'>سلفيات ضريبة الدخل 
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />                             
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/NIKPAY/#/Home" target='_blank'>تصريح اقتطاعات 
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/EMHANDOCH/STARTPAGEINTERNET.ASPX" target='_blank'> كشف دوري ( ض . ق . م )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                              <BiLock size={20} className='matrial-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/EMDVHMFRT/WLOGONMENU.ASPX" target='_balnk'> تصريح مفصل ( PCN )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                              <BiLock size={20} className='matrial-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/EMSLRDIVUH/knisa_doch.aspx#no-back-button" target='_blank'>ارجاع مكوس سولار ( بلو )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                              <BiLock size={20} className='matrial-icons' />
                            </span>
                          </span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <h2 className='links-title text-center '> تصريح سنوي  </h2>
                  <div className="roles-menu">
                    <ul>
                      <li >
                        <a href="https://secapp.taxes.gov.il/SHDOCHSHANA1301/frmMenu.aspx" target='_blank'>تقرير سنوي ضريبة الدخل  ( 1301 )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/SHDOCHSHANASHORT/FRMLOGIN.ASPX" target='_blank'>تقرير سنوي مختصر   ( ضريبة الدخل )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/SH6111/WebForm1.aspx" target='_blank'>تقارير محاسبية  ( 6111 )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/SHDOCHNIK/frmMenu.aspx" target='_blank'>تقارير اقتطاعات  ( 856 \ 126 )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://www.misim.gov.il/EMPATUR/STARTPAGEINTERNET.ASPX" target='_blank'> تصريح سنوي ملف معفي ( ض . ق . م )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                              <BiLock size={20} className='matrial-icons' />
                            </span>
                          </span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <h2 className='links-title text-center '> دفعات ضريبية </h2>
                  <div className="roles-menu">
                    <ul>
                      <li >
                        <a href="https://secapp.taxes.gov.il/GMHOVOTMH/FIRSTPAGE.ASPX" target='_blank'>ضريبة الدخل 
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/NIKPAYHOVOT/#/home" target='_blank'>اقتطاعات 
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/EMHANPAYMENT/HAZANATPIRTEYTASHLUM.ASPX" target='_blank'> ض . ق . م 
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/GMHOVOTMH/FIRSTPAGESCHARDIRA.ASPX" target='_blank'>ضريبة دخل بدل ايجار مخفضة ( % 10  )
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>
                      <li >
                        <a href="https://secapp.taxes.gov.il/KMPAYMENTNEW/FIRSTPAGE.ASPX" target='_blank'>دفع غرامات ادارية 
                          <span className="role-action ">
                            <span className=" large " >
                              <AiOutlineLink className='material-icons' />
                            </span>
                          </span>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>


          </div>


        </div>
        {/* /Page Content */}



      </div>
    );
  }
}

export default ShaamTaxAr;
