import React,{useState,useEffect} from 'react'



export const useAptCalc = (sellingCost,percentage,num,np,myPer) => {

    const [tax,setTax]=useState(0)
    const [taxSlide1,setTaxSlide1]=useState(0)
    const [taxSlide2,setTaxSlide2]=useState(0)
    const [taxSlide3,setTaxSlide3]=useState(0)
    const [taxSlide4,setTaxSlide4]=useState(0)
    const [taxSlide5,setTaxSlide5]=useState(0)
    const [taxSum1,setTaxSum1]=useState(0)
    const [taxSum2,setTaxSum2]=useState(0)
    const [taxSum3,setTaxSum3]=useState(0)
    const [taxSum4,setTaxSum4]=useState(0)
    const [taxSum5,setTaxSum5]=useState(0)
    const [money,setMoney]=useState(0)
    
    useEffect(()=>{
        calculateRec(sellingCost,percentage,num,np,myPer)
    },[sellingCost,percentage,num,np,myPer])

    function calculateRec (sellingCost,percentage,num,np,myPer) {
       
              
        let tx = 0;
        let slide1 = 0;
        let slide2 = 0;
        let slide3 = 0;
        let slide4 = 0;
        let slide5 = 0;
        let myMoney = (sellingCost*1);
        let sum1 = 0 ;        
        let sum2 = 0 ;        
        let sum3 = 0 ;        
        let sum4 = 0 ;        
        let sum5 = 0 ;        

        if(num[2] !=="" && num[1] !=="" && num[0]!==0){
            // console.log("hello iam here in first if stetment")
        if((myMoney) > 0 && (myMoney) <= num[0]){
          tx = myMoney*percentage[0];
          slide1 = (num[0])*percentage[0];
          slide2 = 0;
          slide3 = 0;
          slide4 = 0;
          slide5 = 0;
          sum1 = myMoney ;        
          sum2 = 0 ;        
          sum3 = 0 ;        
          sum4 = 0 ;        
          sum5 = 0 ;
          myMoney = tx  *(myPer*1/100)
          
      }
      else if((myMoney) > num[0] && (myMoney) <=num[1]){
          tx = (((myMoney) - num[0]) * percentage[1]) +(num[0])*percentage[0] ;
          slide1 =(num[0])*percentage[0]
          slide2 = (((myMoney) - num[0]) * percentage[1])  ;
          slide3 = 0;
          slide4 = 0;
          slide5 = 0;
          sum1 = num[0] ;        
          sum2 = myMoney - num[0] ;        
          sum3 = 0 ;        
          sum4 = 0 ;        
          sum5 = 0 ;
          myMoney = tx  *(myPer*1/100)
      }
      else if((myMoney) > num[1] && (myMoney) <= num[2]  ){
          tx = (((myMoney) - num[1]) * percentage[2]) + ((num[0])*percentage[0]+(num[1]-num[0])*percentage[1]) ;
          slide1 = (num[0])*percentage[0];
          slide2 = (num[1]-num[0])*percentage[1];
          slide3 = (((myMoney) - num[1]) * percentage[2]);
          slide4 = 0;
          slide5 = 0;
          sum1 = num[0] ;        
          sum2 = num[1] - num[0] ;        
          sum3 = myMoney - num[1] ;        
          sum4 = 0 ;        
          sum5 = 0 ;
          myMoney = tx  *(myPer*1/100)
      }
      else if((myMoney) > num[2] && (myMoney) <= num[3]){
          tx = (((myMoney) - num[2]) * percentage[3]) + ((num[0])*percentage[0]+(num[1]-num[0])*percentage[1]+(num[2]-num[1])*percentage[2]) ;
          slide1 =(num[0])*percentage[0] ;
          slide2 = (num[1]-num[0])*percentage[1];
          slide3 =(num[2]-num[1])*percentage[2];
          slide4 = (((myMoney) - num[2]) * percentage[3]);
          slide5 = 0;
          sum1 = num[0] ;        
          sum2 = num[1] - num[0] ;        
          sum3 = num[2] - num[1] ;        
          sum4 = myMoney - num[2] ;        
          sum5 = 0 ;
          myMoney = tx  *(myPer*1/100)
        }
        else if((myMoney) > num[3]){
          tx = (((myMoney) - num[3]) * percentage[4]) + (+(num[1]-num[0])*percentage[1]+(num[2]-num[1])*percentage[2]+(num[3]-num[2])*percentage[3]);
          slide1 = (num[0])*percentage[0] ;
          slide2 = (num[1]-num[0])*percentage[1]
          slide3 = (num[2]-num[1])*percentage[2]
          slide4 = (num[3]-num[2])*percentage[3]
          slide5 = (((myMoney) - num[3]) * percentage[4]);
          sum1 = num[0] ;        
          sum2 = num[1] - num[0] ;        
          sum3 = num[2] - num[1] ;        
          sum4 = num[3] - num[2] ;        
          sum5 = myMoney - num[3] ;
          myMoney = tx  *(myPer*1/100)
        }

        setTax(tx)
        setTaxSlide1(slide1)
        setTaxSlide2(slide2)
        setTaxSlide3(slide3)
        setTaxSlide4(slide4)
        setTaxSlide5(slide5)
        setTaxSum1(sum1)
        setTaxSum2(sum2)
        setTaxSum3(sum3)
        setTaxSum4(sum4)
        setTaxSum5(sum5)
        setMoney(myMoney)
    }
    else if(num[1]==="" && num[2] ==="" && num[3] ==="" ){
        // console.log("hello iam here in second if stetment")
        if((myMoney) > 0 && (myMoney) <= num[0]){
            tx = myMoney*percentage[0];
            slide1 = (num[0])*percentage[0];
            slide2 = 0;
            slide3 = 0;
            slide4 = 0;
            slide5 = 0;
            sum1 = myMoney ;
            sum2 = 0 ;
            sum3 = 0 ;
            sum4 = 0 ;
            sum5 = 0 ;
            myMoney = tx  *(myPer*1/100)
            
            
        }
        else if((myMoney) >=num[0]   ){
            
            slide1 = (num[0])*percentage[0];
            slide2 = (((myMoney) - num[0]) * percentage[1])  ;
            slide3 = 0
            slide4 = 0;
            slide5 = 0;
            tx = slide1 + slide2 + slide3 + slide4 + slide5
            sum1 =num[0] ;
            sum2 = myMoney- num[0] ;
            sum3 = 0;
            sum4 = 0 ;
            sum5 = 0 ;
            myMoney = tx  *(myPer*1/100)
        }
        setTax(tx)
        setTaxSlide1(slide1)
        setTaxSlide2(slide2)
        setTaxSlide3(slide3)
        setTaxSlide4(slide4)
        setTaxSlide5(slide5)
        setTaxSum1(sum1)
        setTaxSum2(sum2)
        setTaxSum3(sum3)
        setTaxSum4(sum4)
        setTaxSum5(sum5)
        setMoney(myMoney)
    }
    else if( num[1] !=="" &&  num[2] ==="" ){
        // console.log("hello iam here in third if stetment")
        if((myMoney) > 0 && (myMoney) <= num[0]){
            tx = myMoney*percentage[0];
            slide1 = (num[0])*percentage[0];
            slide2 = 0;
            slide3 = 0;
            slide4 = 0;
            slide5 = 0;
            sum1 = myMoney ;
            sum2 = 0 ;
            sum3 = 0 ;
            sum4 = 0 ;
            sum5 = 0 ;
            myMoney = tx  *(myPer*1/100)
            
            
        }
        else if((myMoney) > num[0] && (myMoney) <=num[1]){
            tx = (((myMoney) - num[0]) * percentage[1]) +(num[0])*percentage[0] ;
            slide1 =(num[0])*percentage[0]
            slide2 = (((myMoney) - num[0]) * percentage[1])  ;
            slide3 = 0;
            slide4 = 0;
            slide5 = 0;
            sum1 =num[0] ;
            sum2 = myMoney - num[0] ;
            sum3 = 0 ;
            sum4 = 0 ;
            sum5 = 0 ;
            myMoney = tx  *(myPer*1/100)
        }
        else if((myMoney) > num[1]   ){
            tx = (((myMoney) - num[1]) * percentage[2]) + ((num[0])*percentage[0]+(num[1]-num[0])*percentage[1]) ;
            slide1 = (num[0])*percentage[0];
            slide2 = (num[1]-num[0])*percentage[1];
            slide3 = (((myMoney) - num[1]) * percentage[2]);
            slide4 = 0;
            slide5 = 0;
            sum1 =num[0] ;
            sum2 = num[1] - num[0] ;
            sum3 = myMoney - num[1] ;
            sum4 = 0 ;
            sum5 = 0 ;
            myMoney = tx  *(myPer*1/100)
        }
        setTax(tx)
        setTaxSlide1(slide1)
        setTaxSlide2(slide2)
        setTaxSlide3(slide3)
        setTaxSlide4(slide4)
        setTaxSlide5(slide5)
        setTaxSum1(sum1)
        setTaxSum2(sum2)
        setTaxSum3(sum3)
        setTaxSum4(sum4)
        setTaxSum5(sum5)
        setMoney(myMoney)
    }
    else if( num[1]==="" && num[0] !=0){

        // console.log("iam in else")
        if((myMoney) > 0 && (myMoney) <= num[0]){
            tx = myMoney*percentage[0];
            slide1 = (num[0])*percentage[0];
            slide2 = 0;
            slide3 = 0;
            slide4 = 0;
            slide5 = 0;
            sum1 = myMoney ;
            sum2 = 0 ;
            sum3 = 0 ;
            sum4 = 0 ;
            sum5 = 0 ;
            myMoney = tx  *(myPer*1/100)
            
            
        }
        else if((myMoney) >=num[0]   ){
            
            slide1 = (num[0])*percentage[0];
            slide2 = (((myMoney) - num[0]) * percentage[1])  ;
            slide3 = 0
            slide4 = 0;
            slide5 = 0;
            tx = slide1 + slide2 + slide3 + slide4 + slide5
            sum1 =num[0] ;
            sum2 = myMoney- num[0] ;
            sum3 = 0;
            sum4 = 0 ;
            sum5 = 0 ;
            myMoney = tx  *(myPer*1/100)
        }
        setTax(tx)
        setTaxSlide1(slide1)
        setTaxSlide2(slide2)
        setTaxSlide3(slide3)
        setTaxSlide4(slide4)
        setTaxSlide5(slide5)
        setTaxSum1(sum1)
        setTaxSum2(sum2)
        setTaxSum3(sum3)
        setTaxSum4(sum4)
        setTaxSum5(sum5)
        setMoney(myMoney)


    }
    else if ( num[0] ==0) {
        if((myMoney) > num[0]   ){
            tx = ((myMoney)  * percentage[0]) 
            slide1 = myMoney*percentage[0];
            slide2 = 0;
            slide3 = 0;
            slide4 = 0;
            slide5 = 0;
            sum1 =myMoney ;
            sum2 =0;
            sum3 =0;
            sum4 = 0 ;
            sum5 = 0 ;
            myMoney = tx  *(myPer*1/100)
        
    }
    setTax(tx)
    setTaxSlide1(slide1)
    setTaxSlide2(slide2)
    setTaxSlide3(slide3)
    setTaxSlide4(slide4)
    setTaxSlide5(slide5)
    setTaxSum1(sum1)
    setTaxSum2(sum2)
    setTaxSum3(sum3)
    setTaxSum4(sum4)
    setTaxSum5(sum5)
    setMoney(myMoney)
}
   
       
        } 
  
       
        
  
        return {tax , taxSlide1, taxSlide2, taxSlide3,taxSlide4,taxSlide5,money , taxSum1 ,taxSum2 ,taxSum3 ,taxSum4 ,taxSum5}
}
