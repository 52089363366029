let k=[
 {
  ar: "كاتب",
  he: "פקיד",
  en: "clerk"
 },
 {
  ar: "كادح",
  he: "עובד הצווארון הכחול",
  en: "Blue collar worker"
 },
 {
  ar: "كارتل",
  he: "קרטל",
  en: "cartel"
 },
 {
  ar: "كتاب اعتماد",
  he: "מכתב אשראי",
  en: "Letter of credit"
 },
 {
  ar: "كتاب اعتماد تجاري",
  he: "מכתב אשראי מסחרי",
  en: "Commercial letter of credit "
 },
 {
  ar: "كتاب اعتماد دائري",
  he: "מכתב אשראי מתחדש מעצמו",
  en: "Revolving letter of credit"
 },
 {
  ar: "كتاب اعتماد دوار",
  he: "מכתב אשראי מתחדש מעצמו",
  en: "Revolving letter of credit"
 },
 {
  ar: "كتاب اعتماد غير قابل للالغاء",
  he: "מכתב אשראי בלתי חוזר\/ מכתב אשראי בלתי ניתן לביטול",
  en: "Irrevocable letter of credit"
 },
 {
  ar: "كتاب اعتماد غير قابل للتجزئة",
  he: "מכתב אשראי בלתי ניתן לחלוקה",
  en: "Undivisible letter of credit"
 },
 {
  ar: "كتاب اعتماد غير قابل للنقض",
  he: "מכתב אשראי בלתי חוזר\/ מכתב אשראי בלתי ניתן לביטול ",
  en: "Irrevocable letter of credit"
 },
 {
  ar: "كتاب اعتماد قابل للالغاء",
  he: "מכתב אשראי חוזר\/ מכתב אשראי בר ביטול",
  en: "Revocable letter of credit"
 },
 {
  ar: "كتاب اعتماد قابل للتجزئة",
  he: "מכתב אשראי ניתן לחלוקה",
  en: "Divisible letter of credit"
 },
 {
  ar: "كتاب اعتماد قابل للتحويل",
  he: "מכתב אשראי ניתן להעברה",
  en: "Transferable letter of credit"
 },
 {
  ar: "كتاب اعتماد قابل للنقض",
  he: "מכתב אשראי חוזר\/ מכתב אשראי בר ביטול",
  en: "Revocable letter of credit"
 },
 {
  ar: "كتاب اعتماد للاستيراد",
  he: "מכתב אשראי ליבוא",
  en: "Import letter of credit"
 },
 {
  ar: "كتاب اعتماد للتصدير",
  he: "מכתב אשראי ליצוא",
  en: "Export letter of credit"
 },
 {
  ar: "كتاب اعتماد مثبت",
  he: "מכתב אשראי מאושר",
  en: "Confirmed letter of credit"
 },
 {
  ar: "كتاب اعتماد محدود",
  he: "מכתב אשראי מוגבל",
  en: "Limited letter of credit"
 },
 {
  ar: "كتاب اعتماد مفتوح",
  he: "מכתב אשראי פתוח",
  en: "Opened letter of credit"
 },
 {
  ar: "كتاب اعتماد نقدي",
  he: "מכתב אשראי לתשלום במזומן",
  en: "Cash letter of credit"
 },
 {
  ar: "كتاب النوايا",
  he: "מכתב נכונות",
  en: "Letter of intent"
 },
 {
  ar: "كتاب تخصيص",
  he: "מכתב הקצאה",
  en: "Letter of allotment"
 },
 {
  ar: "كتاب تعويض",
  he: "מכתב שיפוי",
  en: "Letter of indemnity"
 },
 {
  ar: "كتاب ضمان",
  he: "מכתב ערבות",
  en: "Letter of guarantee"
 },
 {
  ar: "كتاب معاوضة",
  he: "מכתב שיפוי",
  en: "Letter of indemnity"
 },
 {
  ar: "كثيف الدراية",
  he: "עתיר ידע",
  en: "Know how intensive"
 },
 {
  ar: "كثيف العمل",
  he: "עתיר עבודה",
  en: "Labour intensive"
 },
 {
  ar: "كثيف راس المال",
  he: "עתיר הון",
  en: "Capital intensive"
 },
 {
  ar: "كري",
  he: "השכרה",
  en: "Lease\/ rent"
 },
 {
  ar: "كساد اقتصادي",
  he: "שפל כלכלי",
  en: "Depression\/ slump"
 },
 {
  ar: "كسب",
  he: "רווח",
  en: "Gain\/ profit"
 },
 {
  ar: "كشف الرواتب",
  he: "רשימת מקבלי המשכורות",
  en: "payroll"
 },
 {
  ar: "كشف حساب",
  he: "מצב חשבון",
  en: "Statement of account"
 },
 {
  ar: "كفالة",
  he: "אחריות\/ ערבות",
  en: "Responsibility\/ liability\/ guarantee"
 },
 {
  ar: "كفاية",
  he: "יעילות",
  en: "efficiency"
 },
 {
  ar: "كمبيالة",
  he: "המחאה\/ שטר חליפין",
  en: "Draft\/ bill of exchange"
 },
 {
  ar: null,
  he: "תעודה\/ שטר",
  en: "Certificate\/ scrip\/ note\/ deed\/ bill"
 },
 {
  ar: "كمبيالة تدفع عند الطلب",
  he: "שטר לתשלום על פי דרישה",
  en: "Demand draft"
 },
 {
  ar: "كمبيالة داخلية",
  he: "שטר חליפין לשימוש בסחר פנים",
  en: "Domestic bill of exchange\/ inland bill of exchange"
 },
 {
  ar: "كمبيالة صورية",
  he: "שטר טובה",
  en: "Accommodation bill "
 },
 {
  ar: "كمبيالة مجاملة",
  he: "שטר טובה",
  en: "Accommodation bill "
 },
 {
  ar: "كمبيالة مصرفية",
  he: "שטר כסף",
  en: "Bank bill\/ currency note\/ paper money"
 },
 {
  ar: "كمية",
  he: "כמות",
  en: "quantity"
 },
 {
  ar: "كمية الطلب الاقتصادية",
  he: "כמות אופטימלית להזמנה",
  en: "Economic order quantity"
 },
 {
  ar: "كمية ناتجة",
  he: "תפוקה",
  en: "output"
 },
 {
  ar: "كوتا",
  he: "מכסה",
  en: "quota"
 },
 {
  ar: "كوميكون",
  he: "קומקון",
  en: "comecon"
 },
 {
  ar: "كونسورتيوم",
  he: "קונסורציום",
  en: "Consortium "
 }
] 
export default k ;