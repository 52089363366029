let zal =[
 {
  ar: "زبون",
  he: "לקוח ",
  en: "Customer\/ client"
 },
 {
  ar: "زراعة",
  he: "חקלאות",
  en: "agriculture"
 },
 {
  ar: "زراعي",
  he: "חקלאי",
  en: "agrarian"
 },
 {
  ar: "زكاة",
  he: "צדקה",
  en: "charity"
 },
 {
  ar: "زيادة",
  he: "עלייה",
  en: "increase"
 },
 {
  ar: "زيادة القيمة",
  he: "עליית ערך",
  en: "appreciation"
 },
 {
  ar: "زيت",
  he: "שמן",
  en: "oil"
 },
 {
  ar: "زيف",
  he: "זיוף",
  en: "Forgery\/ fraud"
 },
 {
  ar: "ز",
  he: null,
  en: null
 }
]
export default zal ;