import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { AiOutlineLock } from 'react-icons/ai';
import taxData from '../../mass-taxes/national-insurance/data/output'
import { useTaxCalc } from "../../../hooks/useTaxCalc";
import { useNationallTax } from "../../../hooks/useNationallTax";
import ReactTooltip from "react-tooltip";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { massportallogo } from '../../../Entryfile/imagepath';
import { useAuthContext } from "../../../hooks/useAuthContext";
import { projectFirestore } from "../../../firebase/config";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'

import { formatNumber } from "accounting-js";
import { formatMoney } from "accounting-js";
export function RevSalesReport(props) {

  const printRef = React.useRef();
  const printRef2 = React.useRef();
  const [isActive, setIsActive] = useState(false);
  // HOOKS
  const { user } = useAuthContext()
  const [companyName, setCompanyName] = useState(' ')
  // year to get tax Year
  const [year, setYear] = useState(0)

  const [income, setIncome] = useState(0)
  const [incomeNational, setIncomeNational] = useState(0)
  const [nationalTax, setNationalTax] = useState()
  const [tax, setTax] = useState()


  const [salesServices, setSalesServices] = useState(0);
  const [buys, setBuys] = useState(0);
  const [salesSum, setSalesSum] = useState(0);

  const [goodFirst, setGoodFirst] = useState(0);
  const [goodLast, setGoodLast] = useState(0);
  const [goodsSum, setGoodsSum] = useState(0);

  const [totalSum, setTotalSum] = useState(0);

  const [salary, setSalary] = useState(0);
  const [rent, setRent] = useState(0)
  const [expenses1, setExpenses1] = useState(0)
  const [transportation, setTransportation] = useState(0)
  const [maintainance, setMaintainance] = useState(0)
  const [insurance, setInsurance] = useState(0)
  const [fuel, setFuel] = useState(0)
  const [taxeFees, setTaxeFees] = useState(0)
  const [expConsuming, setExpConsuming] = useState(0)
  const [workOther, setWorkOtherP] = useState(0)
  const [workSalesSum, setWorkSalesSum] = useState(0)
  const [workSalesSumP, setworkSalesSumP] = useState(0)
  const [totAfterworkEx, setTotAfterworkEx] = useState(0)
  const [sumAfterWorkExP, setsumAfterWorkExP] = useState(0)
  const [manageSalaryP, setManageSalaryP] = useState(0)

  const [manageSum, setManageSum] = useState(0)
  const [manageSumP, setManageSumP] = useState(0)
  const [manageServiceP, setManageServiceP] = useState(0)
  const [manageTravelP, setManageTravelP] = useState(0)
  const [manageAddP, setManageAddP] = useState(0)
  const [manageExpP, setManageExpP] = useState(0)
  const [manageOtherP, setManageOtherP] = useState(0)
  const [sumAfterManageExV, setSumAfterManageEx] = useState(0)
  const [sumAfterManageExP, setSumAfterManageExP] = useState(0)

  const [fundBank, setfundBank] = useState(0);
  const [fundInsurance, setfundInsurance] = useState(0)
  const [fundLoan, setfundLoan] = useState(0)
  const [fundSum, setFundSum] = useState(0)
  const [sumAfterFund, setSumAfterFund] = useState(0)
  // checkbox for company
  const [box, setBox] = useState(false)



  //useTaxCalc Hook
  const { tax: taX, } = useTaxCalc(income, year, box)

  //useNationallTax Hook
  const { tax: national } = useNationallTax(incomeNational, year, box)

  let perCompany = taxData.taxData[year].incomeTax.massCompany

  // احتساب جديد
  const newCalc = () => {
    window.location.reload();
  }
  // const numberT = 100000.99
  // const test = formatMoney(numberT, { symbol: "₪",  format: "%s %v" }); // €4.999,99
  // console.log("Testing ", test)
  const handleSubmit = (evt) => {

    evt.preventDefault();
    if (box) {
      setNationalTax(0)
      setTax(sumAfterFund * perCompany)
    } else {
      setNationalTax(national)
      setTax(taX)
    }



    // =============================== المبيعات handler ========================================
    let value1 = document.querySelector('#salesGoods').value;
    value1 = parseFloat(value1 ? value1 : 0)
    // setSalesGoods(value1)

    let value2 = document.querySelector('#salesServices').value;
    value2 = parseFloat(value2 ? value2 : 0)
    setSalesServices(value2)

    const sum1 = parseFloat(value1) + parseFloat(value2)
    setSalesSum(sum1);
    // =============================== كلفة المبيعات handler ========================================
    let firstGoddsValue = document.querySelector('#goodFirst').value;
    firstGoddsValue = parseFloat(firstGoddsValue ? firstGoddsValue : 0)
    setGoodFirst(firstGoddsValue)

    let goodLastValue = document.querySelector('#goodLast').value;
    goodLastValue = parseFloat(goodLastValue ? goodLastValue : 0)
    setGoodLast(goodLastValue)

    let buysValue = document.querySelector('#buys').value;
    buysValue = parseFloat(buysValue ? buysValue : 0)
    setBuys(buysValue)

    const sum2 = parseFloat(firstGoddsValue) + parseFloat(buysValue) - parseFloat(goodLastValue);
    setGoodsSum(sum2)

    // =========================================================================
    const total1 = sum1 - sum2  //الربح الاجمالي ( المبيعات - كلفة المبيعات ) 
    setTotalSum(total1);
    // =============================== المصاريف التشغيلية  handler ========================================

    let salaryValue = document.querySelector('#salary').value;
    salaryValue = parseFloat(salaryValue ? salaryValue : 0)
    setSalary(salaryValue)

    let rentValue = document.querySelector('#rent').value;
    rentValue = parseFloat(rentValue ? rentValue : 0)
    setRent(rentValue)

    let expenses1Value = document.querySelector('#expenses1').value;
    expenses1Value = parseFloat(expenses1Value ? expenses1Value : 0)
    setExpenses1(expenses1Value)

    let transportationValue = document.querySelector('#transportation').value;
    transportationValue = parseFloat(transportationValue ? transportationValue : 0)
    setTransportation(transportationValue)

    let maintainanceValue = document.querySelector('#maintainance').value;
    maintainanceValue = parseFloat(maintainanceValue ? maintainanceValue : 0)
    setMaintainance(maintainanceValue)

    let insuranceValue = document.querySelector('#insurance').value;
    insuranceValue = parseFloat(insuranceValue ? insuranceValue : 0)
    setInsurance(insuranceValue)

    let fuelValue = document.querySelector('#fuel').value;
    fuelValue = parseFloat(fuelValue ? fuelValue : 0)
    setFuel(fuelValue)

    let taxeFeesValue = document.querySelector('#taxeFees').value;
    taxeFeesValue = parseFloat(taxeFeesValue ? taxeFeesValue : 0)
    setTaxeFees(taxeFeesValue)

    let expConsumingValue = document.querySelector('#expConsuming').value;
    expConsumingValue = parseFloat(expConsumingValue ? expConsumingValue : 0)
    setExpConsuming(expConsumingValue)

    let workOtherV = document.querySelector("#workOther").value
    workOtherV = parseFloat(workOtherV ? workOtherV : 0)
    setWorkOtherP(workOtherV)

    // Total work expenses 
    const workSalesSumValue = salaryValue + rentValue + expenses1Value + transportationValue + maintainanceValue + insuranceValue + fuelValue + taxeFeesValue + expConsumingValue + workOtherV
    setWorkSalesSum(workSalesSumValue)

    setworkSalesSumP(workSalesSumValue)
    //  الربح بعد المصاريف التشغيلية
    const sumAfterWorkEx = total1 - workSalesSumValue // الربح الاجمالي - مجموع المصاريف التشغيلية 
    setTotAfterworkEx(sumAfterWorkEx)
    setsumAfterWorkExP(sumAfterWorkEx)
    //  const sum1 = parseFloat(value1) + parseFloat(value2)
    //  setSalesSum(sum1);
    // =============================== المصاريف التشغيلية handler ========================================



    // =============================== المصاريف الادارية handler ========================================

    let mSalaryValue = document.querySelector('#manageSalary').value;
    mSalaryValue = parseFloat(mSalaryValue ? mSalaryValue : 0)
    const mSalaryValueP = sum1 != 0 ? (mSalaryValue / sum1 * 100) : 0
    setManageSalaryP(mSalaryValueP.toFixed(2))

    let manageServiceValue = document.querySelector('#manageService').value;
    manageServiceValue = parseFloat(manageServiceValue ? manageServiceValue : 0)
    const manageServiceValueP = sum1 != 0 ? (manageServiceValue / sum1 * 100) : 0
    setManageServiceP(manageServiceValueP.toFixed(2))

    let manageTravelV = document.querySelector("#manageTravel").value
    manageTravelV = parseFloat(manageTravelV ? manageTravelV : 0)
    const manageTravelVp = sum1 != 0 ? (manageTravelV / sum1 * 100) : 0
    setManageTravelP(manageTravelVp.toFixed(2))

    let manageAddV = document.querySelector("#manageAdd").value
    manageAddV = parseFloat(manageAddV ? manageAddV : 0)
    const manageAddVP = sum1 != 0 ? (manageAddV / sum1 * 100) : 0
    setManageAddP(manageAddVP.toFixed(2))

    let manageExpV = document.querySelector("#manageExp").value
    manageExpV = parseFloat(manageExpV ? manageExpV : 0)
    const manageExpP = sum1 != 0 ? (manageExpV / sum1 * 100) : 0
    setManageExpP(manageExpP.toFixed(2))

    let manageOtherV = document.querySelector("#manageOther").value
    manageOtherV = parseFloat(manageOtherV ? manageOtherV : 0)
    const manageOtherPer = sum1 != 0 ? (manageOtherV / sum1 * 100) : 0
    setManageOtherP(manageOtherPer.toFixed(2))

    const manageExpSum = mSalaryValue + manageServiceValue + manageTravelV + manageAddV + manageExpV + manageOtherV
    setManageSum(manageExpSum)
    const manageExpSumP = sum1 != 0 ? (manageExpSum / sum1 * 100) : 0
    setManageSumP(manageExpSumP.toFixed(2))


    //  الربح بعد المصاريف الادارية 
    const sumAfterManageEx = sumAfterWorkEx - manageExpSum // الربح بعد المصاريف التشغيلية - مجموع المصاريف الادارية 
    setSumAfterManageEx(sumAfterManageEx)

    const smanageP = sum1 != 0 ? (sumAfterManageEx / sum1 * 100) : 0
    // console.log("sum1 val = ",sum1)
    setSumAfterManageExP(smanageP.toFixed(2))

    // =============================== المصاريف التمويلية handler ========================================


    let fundBankV = document.querySelector("#fundBank").value
    fundBankV = parseFloat(fundBankV ? fundBankV : 0)
    setfundBank(fundBankV ? fundBankV : 0)

    let fundInsuranceV = document.querySelector("#fundInsurance").value
    fundInsuranceV = parseFloat(fundInsuranceV ? fundInsuranceV : 0)
    setfundInsurance(fundInsuranceV ? fundInsuranceV : 0)

    let fundLoanV = document.querySelector("#fundLoan").value
    fundLoanV = parseFloat(fundLoanV ? fundLoanV : 0)
    setfundLoan(fundLoanV ? fundLoanV : 0)

    const fundSumV = parseFloat(fundBankV) + parseFloat(fundInsuranceV) + parseFloat(fundLoanV)
    setFundSum(fundSumV)

    const sumAfterFundV = sumAfterManageEx - fundSumV
    setSumAfterFund(sumAfterFundV)
    // Calculate Percentages Here 
    // const value2Percentage = sum1 != 0 ? (value2 / sum1 * 100) : 0;
    // setSalesServicesP(value2Percentage.toFixed(2))


    // const firstGoddsValueP = sum1 != 0 ? (firstGoddsValue / sum1 * 100) : 0
    // setGoodFirstP(firstGoddsValueP.toFixed(2))

    // const buysValueP = sum1 != 0 ? (buysValue / sum1 * 100) : 0
    // setBuysP(buysValueP.toFixed(2))
    // const goodLastValueP = sum1 != 0 ? (goodLastValue / sum1 * 100) : 0
    // setGoodLastP(goodLastValueP.toFixed(2))

  }
  function getPercentage(number) {
    const percentageV = salesSum != 0 ? (number / salesSum * 100) : 0
    return (percentageV.toFixed(2))
  }

  // Tax Percentage
  function getTaxPercentage(number) {
    const percentageV = sumAfterFund != 0 ? (number / sumAfterFund * 100) : 0
    return (percentageV.toFixed(2))
  }

  //  PDF Print Function 
  const handleDownloadPdf = async () => {
    const iaactive = true;
    await setIsActive(iaactive);
    const element = printRef.current;
    const element2 = printRef2.current;
    const canvas = await html2canvas(element);
    const canvas2 = await html2canvas(element2);

    const d = new Date().toLocaleString() + "";
    const footer = d + '             www.mass-portal.com';
    var imgData = canvas.toDataURL('image/png');
    var imgData2 = canvas2.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 290;
    var imgHeight = (canvas.height * imgWidth / canvas.width);
    var heightLeft = imgHeight;
    var doc = new jsPDF();
    var margin = 10
    var position = 18; // give some top padding to first page
    // Data
    doc.addImage(imgData, 'PNG', 2, position - 10, imgWidth -8, imgHeight);
    doc.addImage(massportallogo, 'png', 2, 0, 20, 20);
    doc.text(footer, 30, pageHeight , { baseline: 'bottom' });
    doc.addPage();
    doc.addImage(imgData2, 'PNG', 2, position - 5, imgWidth-8, imgHeight);
    doc.addImage(massportallogo, 'png', 2, 0, 20, 20);
    doc.text(footer, 30, pageHeight , { baseline: 'bottom' });
    
    doc.save('Mass-portal-بيان الأرباح والخسائر.pdf');
  };
  //  getCompanyName();
  useEffect(() => {
    const getCompanyName = () => {
      if (user != null) {

        const mydata = projectFirestore.collection('mp-clients').doc(user.uid).get().then((docRef) => { setCompanyName(docRef.data().companyName) })
          .catch((error) => { })
      }
      else
        setCompanyName(" ")
    }

    getCompanyName();
  })

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>MassPortal - بيان ايرادات و مبيعات</title>
        <meta name="description" content="MassPortal- تحضير ايرادات و مبيعات " />
      </Helmet>
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">تحضير بيان أرباح و خسائر   </h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
              </ul>
              <br></br><br></br>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className='row'>
          <div className="col-sm-12 col-md-10"></div>
          {/* <div className="col-sm-12 col-md-1"></div> */}
          <div className="col-sm-12 col-md-2">
            {/* <button className="btn " data-tip data-for="pdf-tip"> */}

            <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
              <AiOutlineFilePdf size={40} className='privacy' />
            </button>
            <button className="btn " data-tip data-for="excel-tip">
              <SiMicrosoftexcel size={40} className='privacy' />
            </button>
            <ReactTooltip id="pdf-tip" place="top" effect="solid">
              طباعة PDF
            </ReactTooltip>
            <ReactTooltip id="excel-tip" place="top" effect="solid">
              طباعة Excel
            </ReactTooltip>
          </div>

        </div>
        <div className={isActive ? "card-white tax-card col-sm-12 col-md-10 sale-card" : "card tax-card col-sm-12 col-md-10 sale-card"} >

          <div className={isActive ? "card-body-white eOfService" : "card-body eOfService"}>
            <form className={isActive ? "form-white" : " "} onSubmit={handleSubmit} >
              <div className="">
                <div ref={printRef} className={isActive ? "form-print-report" : " "}>
                  <div className="row">
                    <div className="col-sm-12 col-md-8 div8">
                      <h3 className="card-title "> بيان الأرباح و الخسائر للسنة المالية المنتهية  ب
                        <span className="privacy">
                          <span > 31 - </span>
                          <span> 12  -  </span>
                          <span className={isActive ? " " : "hide"}> {2022 - year} </span>
                        </span>
                      </h3>
                    </div>

                    <div className="col-sm-12 col-md-3  dates-div">

                      <select

                        id="dates"
                        className={isActive ? 'select floating naqaha-select hide' : 'select floating naqaha-select '}
                        name='dates'
                        // value={contact.tax}

                        onChange={(e) => setYear(e.target.value)}
                      >
                        {taxData.taxData.map((year, index) => (
                          <option value={index} key={index}>{year.taxYear}</option>
                        ))


                        }
                      </select>

                    </div>
                    {isActive && <hr ></hr>}
                  </div>


                  <div className="form-gronup row ">
                    {isActive &&
                      <>
                        <div className="row text-center form-print-report-row">
                          <div className="col-sm-12 col-md-1"> <br></br><label > من  </label></div>
                          <div className='col-sm-12 col-md-4'>

                            <input type="date"
                              className="form-control naqaha-date"

                              name="dateStart"
                            // value={dateStart}
                            // onChange={(e) => setdateStart(e.target.value)} 
                            />

                          </div>
                          <div className="col-sm-12 col-md-1"> <br></br><label> الى  </label></div>
                          <div className='col-sm-12 col-md-4'>
                            <input
                              type="date"
                              className="form-control naqaha-date"
                              name="dateFinsh"

                            />
                            <br></br>
                          </div>

                        </div>
                      </>
                    }
                    {!isActive &&
                      <>
                        <div className="row text-center">
                          <div className="col-sm-12 col-md-2"></div>
                          <div className='col-sm-12 col-md-4'>
                            <label > من  </label>
                            <input type="date"
                              className="form-control naqaha-date"

                              name="dateStart"
                            // value={dateStart}
                            // onChange={(e) => setdateStart(e.target.value)} 
                            />

                          </div>
                          <div className='col-sm-12 col-md-4'>
                            <label> الى  </label>
                            <input
                              type="date"
                              className="form-control naqaha-date"
                              name="dateFinsh"
                            // value={dateFinish}
                            // onChange={(e) => setdateFinish(e.target.value)} 
                            />
                            <br></br>
                          </div>

                        </div>
                      </>
                    }
                    {/* ========================= section one start ==================================== */}

                    {
                      <div className="row sales-div">
                        <br></br><br></br>
                        <span className="privacy card-title">المبيعات  </span>
                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label" for='salesGoods'>مبيعات بضاعة </label>

                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input type="text" className="form-control form-controll" name="salesGoods" id="salesGoods"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6">
                        </div>
                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label" for='salesServices'>دخل من خدمات </label>
                        </div>
                        <div className="col-sm-12 col-md-4">

                          <input type="text" className="form-control" name="salesServices" id="salesServices"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-4 percentage-div">
                          {/* <h4>{salesServicesP} <span className="privacy">%</span></h4> */}
                          <label className=" col-form-label"> <h4>{getPercentage(salesServices)} <span className="privacy">%</span></h4> </label>
                        </div>
                        <div className="col-sm-12 col-md-6">

                        </div>
                        <div className="col-sm-12 col-md-4">
                          <label className=" col-form-label">مجموع المبيعات   </label>
                          <br></br>
                          <input type="text" className={salesSum != 0 ? "form-control underlined" : "form-control"} name="monthlyincome"
                            // value={salesSum}
                            value= {formatMoney(salesSum, { symbol: "₪",  format: " %v %s" })}
                          // onChange={(e) => setSalesSum(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-12 col-md-2 percentage-div">
                          <br></br><br></br>
                          <h4>100 <span className="privacy">%</span></h4>


                          {/* <label className=" col-form-label"> <h4> 100 <span className="privacy">%</span></h4> </label> */}
                        </div>
                      </div>}
                    {/* ====================================== section one end ========================================*/}

                    {/* ========================= section two start ==================================== */}
                    <div className="row sales-div">
                      <br></br>
                      <span className="privacy card-title sales-title">كلفة المبيعات   </span>
                      <br></br>
                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label">بضاعة أول المدة   </label>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <input type="text" className="form-control" name="goodFirst" id='goodFirst'
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{getPercentage(goodFirst)} <span className="privacy">%</span></h4> </label>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label">المشتريات   </label>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <input type="text" className="form-control" name="buys" id='buys'
                          //  value={buys}
                          onChange={handleSubmit}
                        />
                      </div>

                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{getPercentage(buys)} <span className="privacy">%</span></h4> </label>

                        {/* <h4> {buysP}  <span className="privacy">%</span></h4> */}
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label">ينزل  بضاعة  نهاية المدة   </label>
                      </div>
                      <div className="col-sm-12 col-md-4">

                        <input type="text" className="form-control" name="goodLast" id='goodLast'
                          //  value={goodLast}
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        {/* <h4> {goodLastP} <span className="privacy">%</span></h4> */}
                        <label className=" col-form-label"> <h4>{getPercentage(goodLast)} <span className="privacy">%</span></h4> </label>

                      </div>
                      <div className="col-sm-12 col-md-6"></div>
                      <div className="col-sm-12 col-md-4">
                        <label className=" col-form-label">مجموع كلفة المبيعات    </label>
                        <br></br>
                        <input type="text" className={goodsSum != 0 ? "form-control underlined" : "form-control"} name="goodsSum" 
                        // value={goodsSum}
                        value = {formatMoney(goodsSum, { symbol: "₪",  format: " %v %s" })}
                        // onChange={(e) => setGoodsSum(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2 percentage-div">
                        {/* <h4>{salesServicesP} <span className="privacy">%</span></h4> */}
                        <br></br><br></br>

                        <label className=" col-form-label"> <h4> {getPercentage(goodsSum)} <span className="privacy">%</span></h4> </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <br></br>
                      <label className=" col-form-label sale-label privacy">الربح الاجمالي      </label>

                    </div>
                    <div className="col-sm-12 col-md-4 result">
                      <br></br>


                      <input type="text" className={totalSum != 0 ? "form-control sale-result underlined" : "form-control sale-result"} name="monthlyincome" 
                      // value={totalSum}
                      value = {formatMoney(totalSum, { symbol: "₪",  format: " %v %s" })}

                      // onChange={(e) => setTotalSum(e.target.value)}
                      />
                      <br></br>
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      <br></br>
                      <label className=" col-form-label"> <h4>{getPercentage(totalSum)} <span className="privacy">%</span></h4> </label>

                    </div>

                    {/* ====================================== section two end ========================================*/}

                    {/* ========================= section Three start ==================================== */}
                    <div className="row sales-div ">
                      <br></br>
                      <span className="privacy card-title">المصاريف التشغيلية   </span>

                      <div className={isActive && !salary ? "hide" : "row"}>
                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">مصاريف الرواتب  </label>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input type="text" className="form-control" name="salary" id="salary"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <label className=" col-form-label"> <h4>{getPercentage(salary)} <span className="privacy">%</span></h4> </label>
                        </div>

                      </div>

                      {/* =======================Form Group ======================= */}
                      <div className={isActive && !rent ? "hide" : "row"}>
                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">مصاريف الاجار    </label>
                        </div>
                        <div className="col-sm-12 col-md-4">

                          <input type="text" className="form-control" name="rent" id="rent"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <label className=" col-form-label"> <h4>{getPercentage(rent)} <span className="privacy">%</span></h4> </label>

                        </div>

                      </div>

                      {/* ====================== Form Group ======================= */}
                      <div className={isActive && !expenses1 ? "hide" : "row"}>

                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">مصاريف مقاولين    </label>
                        </div>
                        <div className="col-sm-12 col-md-4">

                          <input type="text" className="form-control" name="expenses1" id="expenses1"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <h4> {getPercentage(expenses1)} <span className="privacy">%</span></h4>
                        </div>
                      </div>
                      {/* ====================== Form Group ======================= */}
                      <div className={isActive && !expenses1 ? "hide" : "row"}>

                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">نقل و تخزين     </label>
                        </div>
                        <div className="col-sm-12 col-md-4">

                          <input type="text" className="form-control" name="transportation" id="transportation"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <h4> {getPercentage(transportation)} <span className="privacy">%</span></h4>
                        </div>
                      </div>
                      {/* =========================================================== */}
                      <div className={isActive && !maintainance ? "hide" : "row"}>

                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">مصاريف صيانة       </label>
                        </div>
                        <div className="col-sm-12 col-md-4">

                          <input type="text" className="form-control" name="maintainance" id="maintainance"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <h4> {getPercentage(maintainance)} <span className="privacy">%</span></h4>
                        </div>
                      </div>
                      {/* =========================================================== */}
                      <div className={isActive && !insurance ? "hide" : "row"}>

                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">مصاريف تأمينات        </label>
                        </div>
                        <div className="col-sm-12 col-md-4">

                          <input type="text" className="form-control" name="insurance" id="insurance"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <h4> {getPercentage(insurance)} <span className="privacy">%</span></h4>
                        </div>
                      </div>
                      {/* ====================== Form Group ======================= */}
                      <div className={isActive && !fuel ? "hide" : "row"}>

                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">وقود       </label>
                        </div>
                        <div className="col-sm-12 col-md-4">
                          <input type="text" className="form-control" name="fuel" id="fuel"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <h4> {getPercentage(fuel)} <span className="privacy">%</span></h4>
                        </div>
                      </div>
                      {/* ====================== Form Group ======================= */}
                      <div className={isActive && !taxeFees ? "hide" : "row"}>

                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">رسوم و ضرائب        </label>
                        </div>
                        <div className="col-sm-12 col-md-4">

                          <input type="text" className="form-control" name="taxes-fees" id="taxeFees"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <h4> {getPercentage(taxeFees)} <span className="privacy">%</span></h4>
                        </div>
                      </div>
                      {/* ====================== Form Group ======================= */}
                      <div className={isActive && !expConsuming ? "hide" : "row"}>

                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">مصاريف استهلاك        </label>
                        </div>
                        <div className="col-sm-12 col-md-4">


                          <input type="text" className="form-control" name="exp-consuming" id="expConsuming"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <h4> {getPercentage(expConsuming)} <span className="privacy">%</span></h4>
                        </div>
                      </div>
                      {/* ====================== Form Group ======================= */}
                      <div className={isActive && !workOther ? "hide" : "row"}>

                        <div className="col-sm-12 col-md-2">
                          <label className=" col-form-label">مصاريف أخرى         </label>
                        </div>
                        <div className="col-sm-12 col-md-4">

                          <input type="text" className="form-control" name="workOther" id="workOther"
                            onChange={handleSubmit}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 percentage-div">
                          <h4> {getPercentage(workOther)} <span className="privacy">%</span></h4>
                        </div>
                      </div>
                      {/* ====================== Form Group ======================= */}

                      <div className="col-sm-12 col-md-6">

                        {/* <div className="col-auto float-left ml-auto">
                        <a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_employee"><i className="fa fa-plus" /> أضف مصاريف أخرى</a>
                        <br></br>
                      </div> */}
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <br></br>
                        <label className=" col-form-label">مجموع المصاريف التشغيلية    </label>
                        <br></br>
                        <input type="text" className={workSalesSum != 0 ? "form-control underlined" : "form-control"} name="monthlyincome"
                          // value={workSalesSum}
                      value = {formatMoney(workSalesSum, { symbol: "₪",  format: " %v %s" })}

                        // onChange={(e) => setSalesSum(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-12 col-md-2 percentage-div">
                        {/* <h4>{salesServicesP} <span className="privacy">%</span></h4> */}
                        <br></br><br></br><br></br>

                        <label className=" col-form-label"> <h4>{getPercentage(workSalesSumP)} <span className="privacy">%</span></h4> </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <br></br>
                      <label className=" col-form-label sale-label privacy">الربح الاجمالي بعد المصاريف التشغيلية       </label>

                    </div>
                    <div className="col-sm-12 col-md-4 result">
                      <br></br>
                      <input type="text" className={totAfterworkEx != 0 ? "form-control sale-result underlined" : "form-control sale-result"} name="monthlyincome" 
                      // value={totAfterworkEx}
                      value = {formatMoney(totAfterworkEx, { symbol: "₪",  format: " %v %s" })}

                      // onChange={(e) => setTotalSum(e.target.value)}
                      />
                      <br></br>
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      <br></br>
                      <label className=" col-form-label"> <h4>{getPercentage(sumAfterWorkExP)} <span className="privacy">%</span></h4> </label>

                    </div>
                    {/* ====================================== section Three end ========================================*/}
                  </div>
                </div>
                {/* ========================= section Four start ==================================== */}
                <div ref={printRef2} className={isActive ? "form-print-report" : " "}>

                  <div className="row sales-div">
                    <br></br>
                    <span className="privacy card-title">المصاريف الادارية   </span>

                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && !manageSalaryP ? " hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label" for='manageSalary'>رواتب ادارية  </label>

                      </div>
                      <div className="col-sm-12 col-md-4">
                        <input type="text" className="form-control" name="manageSalary" id="manageSalary"
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{manageSalaryP} <span className="privacy">%</span></h4> </label>
                      </div>

                    </div>
                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && !manageServiceP ? "hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label" for='manageService'>خدمات مهنية  </label>
                      </div>
                      <div className="col-sm-12 col-md-4">

                        <input type="text" className="form-control" name="manageService" id="manageService"
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{manageServiceP} <span className="privacy">%</span></h4> </label>
                      </div>
                    </div>
                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && !manageTravelP ? "hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label" for='manageTravel'>مواصلات   </label>
                      </div>
                      <div className="col-sm-12 col-md-4">

                        <input type="text" className="form-control" name="manageTravel" id="manageTravel"
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{manageTravelP} <span className="privacy">%</span></h4> </label>
                      </div>
                    </div>
                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && !manageAddP ? "hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label" for='manageAdd'>دعاية و اعلان    </label>
                      </div>
                      <div className="col-sm-12 col-md-4">

                        <input type="text" className="form-control" name="manageAdd" id="manageAdd"
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{manageAddP} <span className="privacy">%</span></h4> </label>
                      </div>
                    </div>
                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && !manageExpP ? "hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label" for='manageExp'>مصاريف و استهلاك     </label>
                      </div>
                      <div className="col-sm-12 col-md-4">

                        <input type="text" className="form-control" name="manageExp" id="manageExp"
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{manageExpP} <span className="privacy">%</span></h4> </label>
                      </div>
                    </div>
                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && !manageOtherP ? "hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label">مصاريف أخرى         </label>
                      </div>
                      <div className="col-sm-12 col-md-4">

                        <input type="text" className="form-control" name="manageOther" id="manageOther"
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <h4> {manageOtherP} <span className="privacy">%</span></h4>
                      </div>
                    </div>
                    {/* ====================== Form Group ======================= */}

                    <div className="col-sm-12 col-md-6">

                    </div>
                    <div className="col-sm-12 col-md-4">
                      <label className=" col-form-label">مجموع المصاريف الادارية    </label>
                      <br></br>
                      <input type="text" className={manageSum != 0 ? "form-control underlined" : "form-control"} name="monthlyincome"
                        // value={manageSum}
                      value = {formatMoney(manageSum, { symbol: "₪",  format: " %v %s" })}

                      // onChange={(e) => setSalesSum(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      {/* <h4>{salesServicesP} <span className="privacy">%</span></h4> */}
                      <br></br><br></br>

                      <label className=" col-form-label"> <h4> {manageSumP} <span className="privacy">%</span></h4> </label>
                    </div>
                    {/* ====================== Form Group ======================= */}
                  </div>
                  <div className='row'>
                    <div className="col-sm-12 col-md-6">
                      <br></br>
                      <label className=" col-form-label sale-label privacy-bold">الربح الصافي قبل التمويل          </label>

                    </div>
                    <div className="col-sm-12 col-md-4 result">
                      <br></br>
                      <input type="text" className={sumAfterManageExV != 0 ? "form-control sale-result underlined bold" : "form-control sale-result"} name="monthlyincome" 
                      // value={sumAfterManageExV}
                      value = {formatMoney(sumAfterManageExV, { symbol: "₪",  format: " %v %s" })}

                      // onChange={(e) => setTotalSum(e.target.value)}
                      />
                      <br></br>
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      <br></br>
                      <label className=" col-form-label"> <h4>{sumAfterManageExP} <span className="privacy">%</span></h4> </label>

                    </div>
                  </div>

                  {/* ====================================== section Four end ========================================*/}
                  {/* ========================= section Five start ==================================== */}
                  <div className="row sales-div">
                    <br></br>
                    <span className="privacy card-title sales-title"> مصاريف التمويل    </span>
                    <br></br>
                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && fundBank == 0.00 ? "hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label">عمولات بنكية    </label>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <input type="text" className="form-control" name="fundBank" id='fundBank'
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{getPercentage(fundBank)} <span className="privacy">%</span></h4> </label>


                      </div>
                    </div>
                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && fundInsurance == 0.00 ? "hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label">عمولات شركات ائتمان    </label>

                      </div>
                      <div className="col-sm-12 col-md-4">
                        <input type="text" className="form-control" name="fundInsurance" id='fundInsurance'
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{getPercentage(fundInsurance)} <span className="privacy">%</span></h4> </label>
                      </div>
                    </div>
                    {/* ====================== Form Group ======================= */}
                    <div className={isActive && fundLoan == 0.00 ? "hide" : "row"}>

                      <div className="col-sm-12 col-md-2">
                        <label className=" col-form-label">فوائد على قروض     </label>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <input type="text" className="form-control" name="fundLoan" id='fundLoan'
                          onChange={handleSubmit}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 percentage-div">
                        <label className=" col-form-label"> <h4>{getPercentage(fundLoan)} <span className="privacy">%</span></h4> </label>
                      </div>
                    </div>
                    {/* ====================== Form Group ======================= */}

                    <div className="col-sm-12 col-md-6">

                    </div>
                    <div className="col-sm-12 col-md-4">
                      <label className=" col-form-label">مجموع مصاريف التمويل     </label>
                      <br></br>
                      <input type="text" className={manageSum != 0 ? "form-control underlined bold" : "form-control"} name="fundSum"
                        // value={fundSum}
                      value = {formatMoney(fundSum, { symbol: "₪",  format: " %v %s" })}

                      // onChange={(e) => setSalesSum(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      {/* <h4>{salesServicesP} <span className="privacy">%</span></h4> */}
                      <br></br><br></br>

                      <label className=" col-form-label"> <h4> {getPercentage(fundSum)} <span className="privacy">%</span></h4> </label>
                    </div>
                    {/* ====================== Form Group ======================= */}


                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <br></br>
                      <label className=" col-form-label sale-label privacy">الربح الصافي قبل الضريبة       </label>

                    </div>
                    <div className="col-sm-12 col-md-4 result">
                      <br></br>


                      <input type="text" className={sumAfterFund != 0 ? "form-control sale-result underlined" : "form-control sale-result"} name="monthlyincome" 
                      // value={sumAfterFund}
                      value = {formatMoney(sumAfterFund, { symbol: "₪",  format: " %v %s" })}

                      // onChange={(e) => setTotalSum(e.target.value)}
                      />
                      <br></br>
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      <br></br>
                      <label className=" col-form-label"> <h4>{getPercentage(sumAfterFund)} <span className="privacy">%</span></h4> </label>

                    </div>
                  </div>


                  {/* ====================================== section Five end ========================================*/}

                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <br></br>
                      {!isActive && <button onClick={() => { setIncome(sumAfterFund); }} className="btn btn-primary sale-btn">احتسب الضرائب  </button>
                      }
                      {isActive &&
                        <label className=" col-form-label sale-label privacy">الربح الصافي بعد الضريبة       </label>

                      }
                    </div>
                    <div className="col-sm-12 col-md-4 result ">
                      <br></br>
                      <input className=" form-control sale-result"
                      //  value={tax}
                      value = {formatMoney(tax, { symbol: "₪",  format: " %v %s" })}

                       ></input>
                      <br></br>
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      <br></br>
                      <label className=" col-form-label"> <h4>{getTaxPercentage(tax)} <span className="privacy">%</span></h4> </label>

                    </div>
                  </div>

                  {!isActive &&
                    <div className="row">
                      <label className={isActive ? "col-from-label hide-div" : "col-from-label"}> لا يأخذ بعين الاعتبار نقاط التزكية , لاحتساب مفصل  <a href="/calc-rtx" className="privacy ">إضغط هنا</a></label>
                      <div className="input-group" >
                        <label style={{ paddingLeft: "15px", paddingTop: "8px" }} htmlFor="company" className="h3">  حساب لشركة </label >
                        <input id="company" type="checkbox" onChange={() => setBox(!box)} />
                      </div>



                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <button onClick={() => { setIncomeNational(sumAfterFund); }} type="submit" className="btn btn-primary sale-btn">احتساب التأمين  <AiOutlineLock size={25} />  </button>
                      </div>
                      <div className="col-sm-12 col-md-4 result">
                        <br></br>
                        <input className=" form-control sale-result"
                        //  value={nationalTax}
                      value = {formatMoney(nationalTax, { symbol: "₪",  format: " %v %s" })}

                         ></input>
                        <br></br>
                      </div>
                      <div className="col-sm-12 col-md-2 percentage-div">
                        <br></br>
                        <label className=" col-form-label"> <h4>{getTaxPercentage(nationalTax)} <span className="privacy">%</span></h4> </label>

                      </div>
                    </div>

                  }

                  <div className='row'>
                    <div className="col-sm-12 col-md-6">
                      <br></br>
                      <label className=" col-form-label sale-label privacy-bold ">مجموع الضرائب       </label>

                    </div>
                    <div className="col-sm-12 col-md-4 result">
                      <br></br>


                      <input type="text" className="form-control sale-result bold " 
                      // value={tax + nationalTax > 0 ? tax + nationalTax : 0}
                     value= {tax + nationalTax > 0 ?formatMoney(tax + nationalTax, { symbol: "₪",  format: " %v %s" }):formatMoney("0", { symbol: "₪",  format: " %v %s" }) }
                      />
         
                      <br></br>
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      <br></br>
                      <label className=" col-form-label"> <h4>{getTaxPercentage(tax + nationalTax)} <span className="privacy">%</span></h4> </label>

                    </div>
                  </div>

                  <br />
                  <hr />
                  <div className="row">

                    <div className="col-sm-12 col-md-6">
                      <br></br>
                      <label className=" col-form-label sale-label privacy bold">الربح الصافي        </label>

                    </div>
                    <div className="col-sm-12 col-md-4 result">
                      <br></br>
                      <input type="text" className="form-control sale-result bold underlined"
                      //  value={(sumAfterFund) - (tax + nationalTax) > 0 ? (sumAfterFund) - (tax + nationalTax) : 0}
                     value= {(sumAfterFund) - (tax + nationalTax) > 0 ?formatMoney((sumAfterFund) - (tax + nationalTax), { symbol: "₪",  format: " %v %s" }):formatMoney("0", { symbol: "₪",  format: " %v %s" }) }


                      />
                      <br></br>
                    </div>
                    <div className="col-sm-12 col-md-2 percentage-div">
                      <br></br>
                      <label className=" col-form-label"> <h4>{getTaxPercentage(sumAfterFund - (tax + nationalTax))} <span className="privacy">%</span></h4> </label>

                    </div>

                  </div>


                </div>


                <br />
              </div>
            </form>

            <div className={isActive ? "form-group row hide-div" : "form-group row"}>


              <div className="col-sm-12 col-md-6  ">
                <br></br>
                <button className="btn btn-primary naqaha-btn insurance-btn" onClick={newCalc} > احتساب جديد  </button>
                <br></br> <br></br>
              </div>
              <div className="col-sm-12 col-md-4"></div>
              <div className="col-sm-12 col-md-2">
                {/* <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip"> */}
                <br></br>
                <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                  <AiOutlineFilePdf size={40} className='privacy' />
                </button>
                <button className="btn " data-tip data-for="excel-tip">
                  <SiMicrosoftexcel size={40} className='privacy' />
                </button>
                <ReactTooltip id="pdf-tip" place="top" effect="solid">
                  طباعة PDF
                </ReactTooltip>
                <ReactTooltip id="excel-tip" place="top" effect="solid">
                  طباعة Excel
                </ReactTooltip>
              </div>


              <hr></hr>
            </div>

            <br /><br />
            {/* Add Employee Modal */}
            <div id="add_employee" className="modal custom-modal fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">مصاريف أخرى </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="col-form-label">اسم المصاريف  <span className="text-danger">*</span></label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="col-form-label">القيمة </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>

                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">اضافة </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Add Employee Modal */}

          </div>
        </div>
        {/* /Page Content */}

      </div>
    </div>

  );
}