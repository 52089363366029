/**
 * TermsCondition Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export default class TermsCondition extends Component {
  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>Mass Portal | Privacy Policy</title>
          <meta name="description" content="Reactify Blank Page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="small-container">
            <div className="inner-header text-center">
              <br></br>
              <br></br>

              <h1>النظام الداخلي</h1>
              
					  <p className="privacy-bred"><a href="/">الرئيسية</a></p>
					  
					
              <br></br>
              <br></br>
              <br></br>
            </div>


            <div className="row">

              <div className="col-sm-12">
                <h4>

                  استعمال  الموقع ومحتوياته  المعروضة ملزم بشروط الأستعمال المفصلة فيما يلي :
                
                  الرجاء
                  الاطلاع عليها بدقة  لأن استعمال هذا الموقع يشهد على  موافقتك على نظام الموقع .
                </h4>
                <br></br>
                <h5 className='privacy'>
                  المذكور فى هذا النظام يتوجه سوية لأفراد الجنسين واستعمال لغة المفرد هي بهدف التسهيل
                  فقط
                </h5>
                <br></br>
                <ol className='privacy-ol'>
                  <li>
                    <p>
                      MassPortal أو أي شخص من قبلها ( فيما يلي – " مدير الموقع " ) لا يتحمل أية مسؤولية بشأن المحتويات المعروضة بالموقع
                      , مصداقيتها , دقتها وتأثيرها على حاسوب الداخلين الى الموقع . وكذلك عن أي ضرر , عدم ارتياح , فقدان
                      أو وما شابه ذلك من نتائج بشكل مباشر أو غير مباشر تسبب لك أو لأملاكك أو لأي طرف ثالث بسبب استعمال هذه المحتويات .

                    </p>


                  </li>
                  <li>
                    <p>
                      أنت ملزم بالتحقق وفحص أية معطيات , معلومة , فحوى أو حساب وردت
                      اليك  أثناء استعمال الموقع وانت غير مخول الأعتماد عليه  نحن نوصي
                      التصرف  بحذر مضاعف بشأن المحتويات المنشورة في الموقع . هذه
                      المعلومات لا تشكل بديل لنصيحة مهنية شخصية وليست بالضرورة
                      ملائم لمثال معين .
                    </p>

                  </li>
                  <li>
                    <p>

                      كما هو مذكور أعلاه مديري الموقع غير مسؤولين عن تلك المحتويات وعن
                      النتائج التي يمكن أن تتسبب لك بشكل مباشر أو غير مباشر أو لأي طرف
                      ثالث. اذا  اردت العمل على أساس هذه المحتويات  فإنها مسؤوليتك الكاملة
                      والمطلقة على أعمالك ونتائجها .
                    </p>
                  </li>
                  <li>
                    <p>


                      لا تشكل أية معلومة التي تظهر في الموقع  نصيحة لتنفيذ أو عدم تنفيذ أي عمل
                      كان , من واجبك أخذ الاستشارة قبل أية عملية تقوم بها  كذلك محظور عليك
                      كليا نشر علنيا أي جزء أو مطبوعة المحتويات في الموقع  عما اذا كانت
                      مطبوعة كنص , رسم أو تقرير عما اذا كانت معطاة كملف بوسائل مغناطيسية
                      أو باي شكل آخر .
                    </p>

                  </li>
                  <li>
                    <p>


                      يحق لمديري الموقع تغيير شروط هذا الاستعمال من حين لآخر بدون أي
                      إنذار مسبق  .
                    </p>

                  </li>
                  <li>
                    <p>


                      يحق لمديري الموقع استخدام التفاصيل التي أعطيتها في الموقع والمعلومات
                      التي  تجمع بشأن  نمادج الأستعمال لأهداف تحسين الأستعمال  و/  أو للأتصال
                      بالبريد  الألكتروني  .
                    </p>

                  </li>
                  <li>
                    <p>


                      يتم استخدام  الموقع بموجب هذه الشروط و بموجب تعليمات استعمال الموقع فقط
                      بدون المس من شمولية المذكور ممنوع اقتحام الموقع و/ او تغيير و/ او
                      الأنتقاص و / او اضافة و / او المس بفحوى هذا الموقع و / او بتنسيقه  و / او
                      او البرامج التي تستخدم له و / او باي مادة أخرى مشمولة به  .
                    </p>

                  </li>
                  <li>
                    <p>


                      الأحكام ومكان المقاضاة -  تسري على استعمال الموقع الأحكام الإسرائيلية فقط
                      مكان  القضاء المطلق عن أي شيء او موضوع يتعلق بهذا الموقع يكون فقط
                      في المحاكم المختصة  في الألوية : تل- ابيب او المركز في إسرائيل
                    </p>


                  </li>

                </ol>
                <div className="inner-header text-center">
                  <br></br>
                  <br></br>

                  <h1>تصريح مستعمل الموقع</h1>
                  <br></br>


                </div>

                {/* <p>Donec posuere dictum enim, vel sollicitudin orci tincidunt ac. Maecenas mattis ex eu elit tincidunt egestas. Vivamus posuere nunc vel metus bibendum varius. Vestibulum suscipit lacinia eros a aliquam. Sed dapibus arcu eget egestas hendrerit.</p>
              <p>Vivamus consectetur metus at nulla efficitur mattis. Aenean egestas eu odio vestibulum vestibulum. Duis nulla lectus, lacinia vitae nibh vitae, sagittis interdum lacus. Mauris lacinia leo odio, eget finibus lectus pharetra ut. Nullam in semper enim, id gravida nulla.</p>
              <p>Fusce gravida auctor justo, vel lobortis sem efficitur id. Cras eu eros vitae justo dictum tempor.</p>
              <h4>Etiam sed fermentum lectus. Quisque vitae ipsum libero</h4>
              <p>Phasellus sit amet vehicula arcu. Etiam pulvinar dui libero, vitae fringilla nulla convallis in. Fusce sagittis cursus nisl, at consectetur elit vestibulum vestibulum:</p> */}
                <ul className='privacy-ol'>
                  <li>
                    إن الخدمات المعطاة في الموقع معطاة للاستخدام كما هي ولا يكون أي ادعاء او طلب ضد
                    اصحاب  الموقع بشأن نوع الخدمات التي يعرضها الموقع , الاستعمال يكون على مسؤوليته
                    الكاملة والمطلقة  .

                  </li>
                  <li>
                    كل المعروض في هذا الموقع وخاصة تنسيق المعلومات والمحتويات هي ملك مطلق لمديري
                    الموقع فقط .

                  </li>
                  <li>
                    لا يوجد لأي شخص كان  بأية وسيلة كانت تصريح بنسخ جزء من الموقع او الموقع بكامله
                    بدون تصريح خطي والموقع من قبل مدير الموقع فقط .

                  </li>
                  <li>
                    مديري الموقع غير مسؤولين عن أي ضرر  مباشر او غير مباشر , مالي أو آخر  قد يتسبب
                    لمستعمل الموقع والذي يمكن أن ينتج من أي استعمال بالموقع  ومتفق أن استعمال الموقع هو
                    على مسؤولية المستعمل المطلقة .

                  </li>
                  <li>
                    يوافق المستعمل  يصرح ويلتزم بشكل واضح أن يأخذ على عاتقه فقط كل المسؤولية بما في
                    ذلك أية مخاطرة ممكنة ان تنتج عن استعمال الموقع , موضح بهذا وإزالة الشكوك أن مديري
                    الموقع غير مسؤولين بأي شكل كان عن اضرار و / او خسارة يمكن ان تنتج عن استناد
                    على معطيات معروضة في هذا الموقع و / او عن ضرر من أي نوع كان .

                  </li>
                </ul>
                <div className="inner-header text-center">
                  <br></br>
                  <br></br>

                  <h1 className='privacy'>محتويات هذا الموقع محمية بحقوق الإنتاج</h1>
                  <br></br>
                  <h4 className='privacy'>  يحظر نسخ , نشر ,عرض علنيا او تسليم طرف
                    ثالث أي جزء من المذكور اعلاه بدون استلام موافقة خطية من MassPortal   .
                  </h4>

                </div>

                {/* <p>Sed sollicitudin, diam nec tristique tincidunt, nulla ligula facilisis nunc, non condimentum tortor leo id ex.</p>
              <p>Vivamus consectetur metus at nulla efficitur mattis. Aenean egestas eu odio vestibulum vestibulum. Duis nulla lectus, lacinia vitae nibh vitae, sagittis interdum lacus. Mauris lacinia leo odio, eget finibus lectus pharetra ut. Nullam in semper enim, id gravida nulla.</p>
              <p>Donec posuere dictum enim, vel sollicitudin orci tincidunt ac. Maecenas mattis ex eu elit tincidunt egestas. Vivamus posuere nunc vel metus bibendum varius. Vestibulum suscipit lacinia eros a aliquam. Sed dapibus arcu eget egestas hendrerit.Donec posuere dictum enim, vel sollicitudin orci tincidunt ac. Maecenas mattis ex eu elit tincidunt egestas. Vivamus posuere nunc vel metus bibendum varius. Vestibulum suscipit lacinia eros a aliquam. Sed dapibus arcu eget egestas hendrerit.</p>
              <h4>efficitur</h4>
              <p>Fusce gravida auctor justo, vel lobortis sem efficitur id. Cras eu eros vitae justo dictum tempor.</p>
              <p><strong>Vivamus posuere nunc vel metus bibendum varius. Vestibulum suscipit lacinia eros a aliquam. Sed dapibus arcu eget egestas hendrerit.Donec posuere dictum enim, vel sollicitudin orci tincidunt ac.</strong></p> */}
              </div>
            </div>
          </div>
          {/* /Page Content */}
        </div>
      </div>
    );
  }
}
