import axios from "axios";
import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { type } from "jquery";
// import { FaGasPump } from "react-icons/fa";
// import {FaHourglassHalf} from "react-icons/fa";
// import {FaMoneyBillWave} from "react-icons/fa";
// import { FaPercent} from "react-icons/fa";

const baseURL = "https://www.boi.org.il/currency.xml?rdate=20130103";



// app.use(cors()) //and this
export default function App() {
  const [data, setData] = useState(null);
  // const [jsnOb,setJ] = useState(null);
  const [meanings, setMeanings] = useState([]);
  

  var count = 0;
  var xmlParser = require ('react-xml-parser');
  var convert = require('xml-js');
// const [objectData, setObjectData] = useState([]);
// const dictionaryApi = async () => {
// try {
  
//   const data = await axios.get(
//     // `https://cors-anywhere.herokuapp.com/https://od-api.oxforddictionaries.com/api/v2/entries/en-us/flower`
//     `https://www.boi.org.il/currency.xml?rdate=20130103`
//     , {
//       headers: {

//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//         'Access-Control-Allow-Headers': "append,delete,entries,foreach,get,has,keys,set,values,Authorization",
//       }
//     });

//   setMeanings(data.data);
// } catch (error) {
//   console.log(error);
// }
// };
// console.log(meanings);


  React.useEffect(() => {
    
    axios.get(baseURL).then((response) => {
     
      // console.log(response.data);
      // var obj = new xmlParser().parseFromString(response.data);
      setData(response.data);
    });
  }, []);


  // console.log("data  ", data);
  // console.log("typedata  ", typeof(data));
  
  var result1 = convert.xml2json(data, {compact: true, spaces: 4});
  console.log(result1);





  if (!data) return null;

  // =================FETCH
  // axios.get('https://api.github.com/users/mapbox')
  // .then((response) => {
  //   console.log(response.data);
  //   console.log(response.status);
  //   console.log(response.statusText);
  //   console.log(response.headers);
  //   console.log(response.config);
  // });
    
  // =================FETCH




  

  return (
    <div className="page-wrapper">
    <Helmet>
      <title>Mass Portal</title>
      <meta name="description" content="Dashboard"/>					
    </Helmet>
    {/* Page Content */}
    <div className="content container-fluid">
      {/* Page Header */}
      <div className="page-header">
     
            <div className="row">
           
              <div className="col-sm-12">            
                <h3 className="page-title">JSON Fitching Example</h3>                
              </div>

              <div className="col-sm-12">                
                <ul className="breadcrumb">
                  <li className="breadcrumb-item ">الرئيسية</li>   
                  {/* <li className="breadcrumb-item  ">أسعار الصرف اليوم</li>       */}
                </ul>
              </div>

            </div>
        </div>
        
 </div>
    <div className="content container-fluid">
           {/*================== Page Currency Component ===================== */}
           <div className="row">
      <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
               
                  <span className="dash-widget-icon">
                    <i className="fa fa-usd" /> 
                  
                  </span>
                 
                  {/* <span className='myspan'>شيكل</span> */}
                  
                 
                  <div className="dash-widget-info">
                    <h3>3.199</h3>
                    <span>شيكل</span>
                    <span className='span2'>دولار</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon-jordan">JD</span>
                  <div className="dash-widget-info">
                    <h3>4.512</h3>
                    <span>شيكل</span>
                    <span className='span2'>دينار</span>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><i className="fa fa-eur"  /></span>
                  <div className="dash-widget-info">
                    <h3>3.66</h3>
                    <span>شيكل</span>
                    <span className='span2'>يورو</span>
                  </div>
                </div>
              </div>
            </div>
            
            
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><i className="fa fa-jpy" aria-hidden="true"></i></span>
                  <div className="dash-widget-info">
                    <h3>0.5</h3>
                    <span>شيكل</span>
                    <span className='span2'>يوان</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {data.map((item) => 
                    <div>{item.name}</div>)} */}
          
      <h1>{data.name}</h1>
      
      {/* {data.children.map((it) => 
        <div key={count++}> 
          <p >{it.name}</p>
        </div>
      )} */}
      {/* <p>{data.children}</p> */}

      {/* <div className="stock-container">
        {result1.map((data, key) => {
          return (
            <div key={key}>
              {data.company +
                " , " +
                data.ticker +
                " ," +
                data.stockPrice +
                ", " +
                data.timeElapsed}
            </div>
          );
        })}
      </div> */}

    </div>
    </div>
  );
}