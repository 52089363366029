import React from 'react'
import { TxTblRealShebah } from './tx-tbl-real-shebah'

export default function TxTblReal() {
  return (
    <div className="col-sm-24 col-md-12  dates-div ">

        <TxTblRealShebah className='table-tb' />
    </div>
  )
}
