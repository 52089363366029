let en =[
 {
  ar: "غاز",
  he: "גז",
  en: "gas"
 },
 {
  ar: "غالي",
  he: "יקר ",
  en: "expensive"
 },
 {
  ar: "غبن",
  he: "הונאה",
  en: "fraud"
 },
 {
  ar: "غرامة",
  he: "קנס",
  en: "fine"
 },
 {
  ar: "غرفة تجارة",
  he: "לשכת מסחר",
  en: "Chamber of commerce\/ board of trade"
 },
 {
  ar: "غرفة مقاصة",
  he: "מסלקה",
  en: "Clearing house"
 },
 {
  ar: "غسل السندات",
  he: "כיבוס איגרות חוב",
  en: "Bond washing"
 },
 {
  ar: "غش",
  he: "זיוף",
  en: "Forgery\/ fraund"
 },
 {
  ar: "غطاء",
  he: "כיסוי",
  en: "cover"
 },
 {
  ar: "غلة",
  he: "תשואה",
  en: "Return\/ yield"
 },
 {
  ar: "غناء",
  he: "עושר",
  en: "Wealth\/ richness"
 },
 {
  ar: "غني",
  he: "עושר",
  en: "Wealth\/ richness"
 },
 {
  ar: "غير مربح",
  he: "לא רווחי",
  en: "Non- profit"
 }
]
export default en ;