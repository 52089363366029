/**
 * Basic Table
 */
import React, { Component, Fragment } from 'react';
import { Helmet } from "react-helmet";

class Itt22 extends Component {

	// const computTax = 5;
	render() {
		const computeTax = () => {
			console.log("Computing tax Function is here");
		}
		computeTax();

		return (


			<div >


				<div className="row">

					<h>	
						<br></br>
						<br></br>
						<br></br>
						
					</h>

					<div className="col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title mb-0 text-center privacy">شرائح ضريبة الدخل لعام 2022</h4>
								<span>  قيمة نقطة التزكية :   <b className='privacy'> 223</b></span>
							</div>

							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-nowrap table-striped mb-0">
										<thead>
											<tr>
												<th>الراتب ( شيكل جديد )</th>
												<th>الراتب التراكمي</th>
												<th>معدل الضريبة</th>
												<th>الضريبة </th>
												<th>الضريبة التراكمية</th>
												{/* <th>Country</th> */}
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>من  0  الى  6.450</td>
												<td>6.450</td>
												<td>10.0%</td>
												<td>645</td>
												<td>645</td>
											</tr>
											<tr>
												<td><span className='privacy'>+</span> 2.790</td>
												<td>9.240</td>
												<td>14.0%</td>
												<td>391</td>
												<td>1.036</td>
											</tr>
											<tr>
												<td><span className='privacy'>+</span>  5.600</td>
												<td>14.840 </td>
												<td>20.0%</td>
												<td>1.120</td>
												<td>2.156</td>
											</tr>
											<tr>
												<td> <span className='privacy'>+</span>  5.780</td>
												<td>20.620</td>
												<td>31.0%</td>
												<td>1.792</td>
												<td>3.947</td>
											</tr>
											<tr>
												<td> <span className='privacy'>+</span>  22.290</td>
												<td>42.910</td>
												<td>35.0%</td>
												<td>7.802</td>
												<td>11.749</td>
											</tr>
											<tr>
												<td> <span className='privacy'>+</span> 12.360  </td>
												<td>55.270</td>
												<td>47.0%</td>
												<td>5.809</td>
												<td>17.558</td>
											</tr>

										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

export default Itt22;
