import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import { RentPage } from './Rent';

class RentContract extends Component {
  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - عقد إيجار</title>
          <meta name="description" content="Reactify Blank Page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="small-container">
            
              <div className="inner-header text-center">
                <br></br>
                <br></br>
                <h3 className="page-title "> عقد إيجار </h3>
              
                  <p className="privacy-bred"><a href="/">الرئيسية</a></p>
               
              </div>
              
            
          {/* /Page Header */}
           
          <RentPage />
          
          
          </div>
          
        </div>
        {/* /Page Content */}
      </div>
    );
  }
}

export default RentContract;