import { useState ,useEffect} from "react" ;
import { TxTblOneAbt } from "./tx-tbl-one-abt";
import { TxTblGrtOneAbt } from "./tx-tbl-grt-one-abt";
import { TxTblAbtAgr } from "./tx-tbl-abt-agr";
import { TxTblNewIngr } from "./tx-tbl-new-ingr";
import { TxTblNonHome } from "./tx-tbl-non-home";
import { TxTblLandInv } from "./tx-tbl-land-inv";

export default function TxTbl(){
    const [tbl ,setTbl] = useState("TxTblOneAbt");
    const [txTblOneAbt,setTxTblOneAbt] = useState(false);
    const [txTblGrtOneAbt,setTxTblGrtOneAbt] = useState(false);
    const [txTblAbtAgr,setTxTblAbtAgr] = useState(false);
    const [txTblNewIngr,setTxTblNewIngr] = useState(false);
    const [txTblNonHome,setTxTblNonHome] = useState(false);
    const [txTblLandInv,setTxTblLandInv] = useState(false);
    useEffect(() => {
        tbl ==="TxTblOneAbt"
        ? setTxTblOneAbt(true) : setTxTblOneAbt(false);
        tbl ==="TxTblLandInv"
        ? setTxTblLandInv(true) : setTxTblLandInv(false);
        tbl ==="TxTblNonHome"
        ? setTxTblNonHome(true) : setTxTblNonHome(false);
        tbl ==="TxTblNewIngr"
        ? setTxTblNewIngr(true) : setTxTblNewIngr(false);
        tbl ==="TxTblAbtAgr"
        ? setTxTblAbtAgr(true) : setTxTblAbtAgr(false);
        tbl ==="TxTblGrtOneAbt"
        ? setTxTblGrtOneAbt(true) : setTxTblGrtOneAbt(false)
    },[tbl]);
    return(
        <div className="col-sm-24 col-md-12  dates-div">
           
                <select  id="dates"
                className='select floating naqaha-select decorated'
                name='dates' value={tbl} onChange={(e)=> (setTbl(e.target.value))} >
                    <option  value = "TxTblOneAbt"> جداول  شقة سكنية (واحدة)</option>
                    <option  value = "TxTblGrtOneAbt">جداول شقة سكن (أكثر من واحدة)
                    </option>
                    <option value="TxTblAbtAgr">جداول عقارات اقتصاد زراعي</option>
                    <option value="TxTblNewIngr">جداول عقارات قادم جديد</option>
                    <option value="TxTblNonHome"> جداول عقارات غير سكنية</option>
                    <option value="TxTblLandInv"> جداول عقارات مخزون تجاري</option>
                
                </select>
            
           <br></br>
           <br></br>
            {txTblOneAbt && <TxTblOneAbt />}
            {txTblAbtAgr && <TxTblAbtAgr />}
            {txTblNewIngr && <TxTblNewIngr />}
            {txTblGrtOneAbt && <TxTblGrtOneAbt />}
            {txTblNonHome && <TxTblNonHome />}
            {txTblLandInv && <TxTblLandInv />}
        </div>
    )



}
