/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";

class MgEmp extends Component {

  render() {

    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - موظف </title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <h3 className="page-title"> موظف جديد   </h3>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <form>
                <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                      <label> رقم الملف بالمكتب  </label>
                      <input className="form-control" type="text" defaultValue="" />
                    </div>
                  </div>
                  <div className="col-sm-6"></div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label> الاسم الشخصي  </label>
                      <input className="form-control" type="text" defaultValue="" />
                    </div>
                  </div> 
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>اسم العائلة</label>
                      <input className="form-control " defaultValue="" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>رقم الهوية</label>
                      <input className="form-control " defaultValue="" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>تاريخ الميلاد </label>
                      <input className="form-control " defaultValue="" type="date" />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>اسم الأب </label>
                      <input className="form-control " defaultValue="" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label>الحالة الاجتماعية </label>
                      <select className="form-control select">
                        <option>متزوج</option>
                        <option>أعزب </option>
                        <option>منفصل </option>
                        <option>أرمل  </option>

                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                  <div className="form-group">
                    <h1><br></br></h1>
                              {/* <label className="d-block">Gender:</label> */}
                              <div className="form-check form-check-inline">
                                
                                <input className="form-check-input" type="radio" name="gender" id="gender_male" defaultValue="option1" />
                                <label className="form-check-label" htmlFor="gender_male">ذكر</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="gender" id="gender_female" defaultValue="option2" />
                                <label className="form-check-label" htmlFor="gender_female">أنثى</label>
                              </div>
                            </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>العنوان</label>
                      <input className="form-control " defaultValue=" " type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label>المحافظة </label>
                      <select className="form-control select">
                        <option>القدس</option>
                        <option>الخليل </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label>المدينة</label>
                      <input className="form-control" defaultValue="القدس" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label>رمز البريد</label>
                      <input className="form-control" defaultValue="البريد" type="text" />
                      {/* <select className="form-control select">
                       <option>California</option>
                       <option>Alaska</option>
                       <option>Alabama</option>
                     </select> */}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label>صندوق البريد </label>
                      <input className="form-control" defaultValue={91403} type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>هاتف  </label>
                      <input className="form-control" defaultValue="" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    
                    <div className="form-group">
                      <label>جوال</label>
                      <input className="form-control" defaultValue="" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>فاكس</label>
                      <input className="form-control" defaultValue="" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>موقع الانترنت   </label>
                      <input className="form-control" defaultValue="" type="text" />
                    </div>
                </div>
                
                </div>
                <hr></hr>
                <div className="row">
                 <h4 className='privacy'>الملفات الضريبية</h4>
                  
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>رقم ملف ضريبة الدخل </label>
                      <input className="form-control" defaultValue="" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-4">
                   <div className="form-group">
                     <label>رقم ملف ضريبة القيمة المضافة </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-4">
                   <div className="form-group">
                     <label>رقم ملف الاقتطاعات / ضريبة الدخل  </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-6">
                   <div className="form-group">
                     <label>رقم ملف الاقتطاعات / التأمين الوطني  </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-6">
                   <div className="form-group">
                     <label>رقم ملف التأمين الوطني الشخصي   </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>

                </div>
                <hr></hr>
                <div className="row">
                 <h4 className='privacy'>أرقام الدفاتر الضريبية </h4>
                  
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>دفتر السلفيات  </label>
                      <input className="form-control" defaultValue="" type="text" />
                    </div>
                  </div>
                  <div className="col-sm-4">
                   <div className="form-group">
                     <label>دفتر الاقتطاعات ( ض . د ) </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-4">
                   <div className="form-group">
                     <label>دفتر التأمين الوطني (اقتطاعات التأمين الوطني)  </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-6">
                   <div className="form-group">
                     <label>كود في المنطقة الشخصية   </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-6">
                   <div className="form-group">
                     <label>ايميل الزبون في سلطات الضريبة   </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>

                </div>
                <hr></hr>
{/* Form Row */}
                <div className="row">
                 <h4 className='privacy'>تفاصيل المصلحة / עסק  </h4>
                  
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>تاريخ فتح الملف  </label>
                      <input className="form-control" defaultValue="" type="date" />
                    </div>
                  </div>
                  <div className="col-sm-6">
                   <div className="form-group">
                     <label>قطاع العمل  </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-6">
                   <div className="form-group">
                     <label>وصف الفعالية    </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-6">
                 <div className="form-group">
                      <label>نوع الملف </label>
                      <select className="form-control select ">
                        <option>ملف ضريبة قيمة مضافة معفي</option>
                        <option>ملف ضريبة قيمة مضافة مشتغل مرخص </option>                     
                      </select>
                    </div>
                 </div>
                
                 {/* <div className="col-sm-6">
                   <div className="form-group">
                     <label>ايميل الزبون في سلطات الضريبة   </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div> */}

                </div>
                <hr></hr>
                <div className="row">
                 <h4 className='privacy'>بنك الزبون   </h4>
                  
                  
                  <div className="col-sm-4">
                   <div className="form-group">
                     <label>كود البنك   </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                 <div className="col-sm-4">
                   <div className="form-group">
                     <label>رقم الفرع    </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>
                
                 <div className="col-sm-4">
                   <div className="form-group">
                     <label>رقم الحساب    </label>
                     <input className="form-control" defaultValue="" type="text" />
                   </div>
                 </div>

                </div>
                <hr></hr>
                {/* <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Website Url</label>
                      <input className="form-control" defaultValue="https://www.example.com" type="text" />
                    </div>
                  </div>
                </div> */}
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">اضافة </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    );
  }
}

export default MgEmp;
