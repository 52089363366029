/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export default class LawPvI extends Component {
	render() {
		return (
			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | اعلام مسبق      </title>
					<meta name="description" content="MassPortal - اعلام مسبق " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>اعلام مسبق    </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>
						<div className="row">
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<h3 >الالتزام باعطاء اعلام مسبق قبل الفصل أو الاستقالة   </h3>
								<br></br>
								<ul className='privacy-ol'>
									<li>
										<p>
										الالتزام بإعطاء إعلام مسبق للفصل أو الاستقالة غير ناتجة من قانون تعويضات نهاية الخدمة, حيث أتفق عليها اتفاقيات جماعية حتى أصبحت كعادة مقبولة. 										
										</p>
									</li>
									<li>
										<p>
										في عام 1996 تم الختم على اتفاق جماعي عام, حيث تم

به تعيين مدة الإعلام وطرق الإعلام لكل المستخدمين المنظمين في رابطة المؤسسات العامة. 
										</p>
									</li>
									<li>
										<p>
										في أكتوبر 1997 تم تعميم الاتفاقية على كل أصحاب العمل في إسرائيل.
										</p>
									</li>
								</ul>
								<h3 >اعلام مسبق حسب الاتفاق العام    </h3>
								<br></br>
							</div>

							<div className="col-lg-10">
								<div className="card">
									<div className="card-header">
										<br></br>
										<h5 className="card-title mb-0 text-center privacy">اعلام مسبق حسب الاتفاق العام</h5>
										<br></br>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead >
													<tr >
														<th> مدة الخدمة  </th>
														<th > موظف شهري    </th>
														<th>موظف يومي   </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>من 1 <span className='privacy '> - </span> 6  أشهر </td>
														<td>يوم واحد لكل شهر  </td>
														<td>يوم واحد لكل شهر  </td>
													</tr>
													<tr>
													<td>من 7 <span className='privacy '> - </span> 12  أشهر </td>
													<td> 2.5 أيام لكل شهر </td>
													<td>يوم واحد لكل شهر </td>

													</tr>
													<tr>
														<td> سنة </td>
														<td> شهر </td>
														<td> أسبوعين </td>
													</tr>
													<tr>
														<td> فوق السنة و أقل من سنتين </td>
														<td> شهر </td>
														<td>أسبوعين  <span className='privacy '> + </span> يوم واحد لكل شهر  </td>

														
													</tr>
													<tr>
														<td> سنتين </td>
														<td> شهر </td>
														<td> ثلاثة أسابيع </td>
													</tr>
													<tr>
														<td> فوق السنتين و أقل من ثلاثة سنوات </td>
														<td> شهر </td>
														<td> ثلاثة أسابيع <span className='privacy'> + </span> يوم واحد لكل شهر </td>
													</tr>
													<tr>
														<td> فوق الثلاثة سنوات  </td>
														<td> شهر </td>
														<td> شهر </td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<hr></hr>
									<ul className='privacy-ol'>
										<h5 className='privacy '>
											قانون تعويضات نهاية الخدمة (1963) يفصل عدة حالات استقالة التي تعد كفصل ويحق للموظف بمطالبة أتعابه حتى لو لم يفصل.
										</h5>
									</ul>
								</div>
							</div>
						</div>
						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
