import React, { useState, useEffect } from "react";

import { BiShekel } from 'react-icons/bi';
import { AiFillGolden } from "react-icons/ai";
import { RiExchangeDollarFill } from 'react-icons/ri'
import { SiMicrosoftexcel } from 'react-icons/si'
import ReactTooltip from "react-tooltip";
import { useGoldApi2 } from "../../../hooks/useGoldApi2";
import { useGoldApi } from "../../../hooks/useGoldApi";
import { formatMoney } from "accounting-js";
import getSymbolFromCurrency from 'currency-symbol-map'
export function MoneyToGoldSilver() {
  const [rateEl, setRateEl] = useState('');
  const [rate1, setRate1] = useState('');
  const [rate2, setRate2] = useState('');
  const [amountTwo, setAmountTwo] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [fromCurrency, setFromCurrency] = useState("ILS");
  const [toCurrency, setToCurrency] = useState();
  const [rateTo, setRateTo] = useState();
  const [currencyAp, setCap] = useState();
  const [currencyAp2, setCap2] = useState();
  const { goldOz, goldGram, ozGrams, silverPricee, goldKilo, ozPriceNumber, silverPriceNumber ,goldCoin ,goldCoinWeight} = useGoldApi();
  const [rateEx, setRateEx] = useState();
  const [cost, setCost] = useState(0);
  const [costSwap, setCostSwap] = useState(0);
  const [show, setShow] = useState(false);
  
  


  useEffect(() => {
    calculate()
    // console.log(fromCurrency)
    var selectFrom = document.querySelector('#currency-one');
    var valueFrom = selectFrom.options[selectFrom.selectedIndex].text;
    setCap(valueFrom);
  }, [fromCurrency,cost])

  function calculate() {

    const myCurrency = "ILS"
    // console.log("from currency : ", fromCurrency)
    //  To render the values in a div  //
    const urll6 = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/${fromCurrency}`;
    fetch(urll6)
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        const rate = data.conversion_rates[myCurrency];
        // console.log("Rate From URL6 : ", rate);

        var amountEl2 = (cost * rate).toFixed(2);

        setCostSwap(amountEl2);


        // console.log("Amout Two Value : ", amountEl2);

      });
      // console.log("costSwap",costSwap,"ozPriceNumber",ozPriceNumber,"getRate(fromCurrency)",getRate(fromCurrency))

  }

  function getRate(fromCurrency) {
    const currencyOne = 'ILS';
    const urll = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/${fromCurrency}`;
    
  

      fetch(urll)
        .then(res => res.json())
        .then(data => {
          // console.log(data);
          const ratee = data.conversion_rates[currencyOne];
          const rateD_four = ratee.toFixed(2);
          setRateEx(rateD_four)

        });
   

    return (rateEx);
  }



  // HOOKS




  const handleSubmit = (evt) => {
    evt.preventDefault();
    let id = Math.random();
    // ===================


    setShow(true);
    const timer = setTimeout(() => window.scroll(0, 1000), 100);
  };

  function newCalc() {
    window.location.reload();
  }
  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "")
    );
  }
  //  PDF Prin Function 


  return (
    <div className="card container">
      <div className="card-body eOfService">

        <form
          onSubmit={handleSubmit}
          className="form container
      naqaha-form"
        >
          <div className="row ">
            <h3 className="card-title">أسعار المعادن </h3>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <AiFillGolden />
                  </span>
                  <div className="dash-widget-info">
                    <h3>{commify((goldOz))} <span> < BiShekel size={18} /> </span></h3>
                    <span className="pos" > اونصة = {ozGrams.toFixed(2)}  غرام  </span>
                    <span className='span2'> ذهب (اونصة)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <AiFillGolden />
                  </span>
                  <div className="dash-widget-info">
                    <h3>{commify((goldGram))}<span>< BiShekel size={16} /> </span></h3>
                    <span></span>
                    <span className='span2'>ذهب  (غرام)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon"><AiFillGolden /></span>
                  <div className="dash-widget-info">
                    <h3>{commify((goldCoin*1).toFixed(2))}<span>< BiShekel size={18} /></span></h3>
                    {/* <span>سعر</span> */}
                    <span className="pos">ليرة انجليزي = {goldCoinWeight} غرام</span>
                    <span className='span2 '> ذهب  (ليرة انجليزي) </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    <AiFillGolden />
                  </span>
                  <div className="dash-widget-info">
                    <h3>{commify((silverPricee * 1).toFixed(2))} <span> < BiShekel size={18} /> </span></h3>
                    {/* <h3> {(1234.56789).toLocaleString(undefined, { maximumFractionDigits: 2 })}</h3> */}

                    <span></span>
                    <span className='span2'>فضة  (غرام) </span>
                  </div>
                </div>
              </div>
            </div>


          </div><hr></hr>
          <div className="">
            <br></br>
            <div id="Btn_1"></div>

            <div className="row">
              <div className="col-sm-12 col-md-12">
                <h2 className="card-title text-center privacy">  تحويل الاموال الى ذهب او فضة </h2>
              </div>
            </div>

            <div className="form-group row insurance-row">

              <div className="col-sm-12 col-md-2"></div>

              <div className=" col-sm-12 col-md-4">
                <label className="col-form-label">  <RiExchangeDollarFill className="privacy" size={25} style={{ marginLeft: "5px" }} /> اختر العملة</label>

                <div className=" form-group form-focus select-focus se-ex" style={{ marginTop: "0", paddingTop: "0" }}>
                  <select
                    id="currency-one"
                    className='select floating ex-select '
                    defaultValue={"ILS"}
                    onChange={(e) => (setFromCurrency(e.target.value) , setShowRow(true))}
                    style={{ height: "46px", width: "100%" }}
                  >
                    <option value="USD">الدولار الأمريكي ( USD )</option>
                    <option value="ILS">شيكل إسرائيلي ( ILS )</option>
                    <option value="JOD">دينار أردني ( JOD )</option>
                    <option value="GBP">الجنيه الاسترليني ( GBP ) </option>
                    <option value="JPY">الين الياباني ( JPY )</option>
                    <option value="CNY">اليوان الصيني ( CNY )</option>
                    <option value="AUD">  دولار استرالي ( AUD ) </option>
                    <option value="BHD">دينار بحريني  ( BHD )</option>
                    <option value="BEF">فرنك بلجيكي ( BEF ) </option>
                    <option value="BRL">ريال برازيلي ( BRL ) </option>
                    <option value="CAD">دولار كندي ( CAD ) </option>
                    <option value="DKK">كرون دنماركي ( DKK )</option>
                    <option value="EGP">الجنيه المصري ( EGP )</option>
                    <option value="EUR">اليورو ( EUR )</option>
                    <option value="DEM">المارك الألماني ( DEM )</option>
                    <option value="INR">الروبية الهندية ( INR )</option>
                    <option value="IQD">الدينار العراقي ( IQD )</option>
                    <option value="ITL">ليرة ايطالية ( ITL )</option>
                    <option value="KWD">دينار كويتي ( KWD )</option>
                    <option value="LBP">ليرة لبنانية ( LBP )</option>
                    <option value="LYD">دينار ليبي ( LYD )</option>
                    <option value="MXN">بيزو مكسيكي ( MXN )</option>
                    <option value="MAD">درهم مغربي ( MAD )</option>
                    <option value="NZD">الدولار النيوزيلندي ( NZD )</option>
                    <option value="OMR">ريال عماني ( OMR )</option>
                    <option value="QAR">ريال قطري ( QAR )</option>
                    <option value="RUB">روبل روسي ( RUB )</option>
                    <option value="SAR">الريال السعودي ( SAR )</option>
                    <option value="SYP">الليرة السورية ( SYP )</option>
                    <option value="TND">دينار تونسي ( TND )</option>
                    <option value="TRY">الليرة التركية ( TRY )</option>
                    <option value="UAH">الهريفنيا الأوكراني ( UAH )</option>
                    <option value="AED">درهم اماراتى ( AED )</option>
                    <option value="YER">ريال يمني ( YER )</option>
                  </select>


                  {/* <br></br>
              <br></br> */}

                </div>
              </div>

              <div className="col-sm-12 col-md-4">
                <label className="col-form-label"> ادخل المبلغ بال {currencyAp}  </label>

                <input
                  type="text"
                  className="form-control"
                  name="cost"
                  // value=  {formatMoney((cost),{ symbol: "",  format: " %v %s" })}
                  value={cost}
                  onChange={(e) => setCost(e.target.value)}
                />
              </div>
              <div className=" col-sm-12 col-md-2"></div>
              <div className=" col-sm-12 col-md-2"></div>
              <div className=" col-sm-12 col-md-2"></div>

              


              {/* <div className="col-sm-12 co"></div> */}


              <div className=" col-sm-12 col-md-2"></div>
              <div className=" col-sm-12 col-md-2"></div>



            </div>

            <br />
            <div className="form-group row">
              {/* <div className="col-sm-12 col-md-2"></div> */}
              <div className="col-sm-12 col-md-8" style={{ margin: "auto" }}>
                <button
                  type="submit"
                  className="btn btn-primary naqaha-btn insurance-btn"
                >
                  {" "}
                  احتساب{" "}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <hr></hr>
      <br />
      <br />

      {show && (
        <>

          <div className="container" >
            <div className="content container-fluid">
              <div className="page-header ">
                <div className="">
                  <div className='row'>
                    <div className="col-sm-12 col-md-10">
                    </div>
                    {/* <div className="col-sm-12 col-md-1"></div> */}

                  </div>
                  <div className="card" style={{ border: "1px solid #FF9B44" }} >
                    <br></br>
                    <div className="card-body"  >
                      <div className="row result-div result-dev-tax" >
                        <div className="col-sm-12 col-md-4 "></div>
                        <div className="col-sm-12 col-md-2 " style={{maxWidth:"fit-content"}}>
                          <label className="label-title bold">  المبلغ : </label>
                        </div>
                        <div className="col-sm-12 col-md-6 " style={{ maxWidth: "fit-content" }}>
                          <label className={"privacy reuslt-insurance text-right"}>
                            {/* {commify((cost * 1).toFixed(2))} <BiShekel /> */}
                            {formatMoney((cost), { symbol:getSymbolFromCurrency(fromCurrency)!=undefined?getSymbolFromCurrency(fromCurrency):" " , format: " %v %s" })}
                          </label>
                        </div>
                      </div><br></br>
                      <div className=" row col-sm-12 col-md-12 border-div" >
                        <div className="col-sm-12 col-md-3 " >
                          <br></br>
                          <label className={"privacy"}> ذهب (اونصة) </label>

                        </div>
                        <div className="col-sm-12 col-md-3 border-left">
                          <br></br>
                          <label className="reuslt-insurance">
                            {/* {commify((cost / (ozPriceNumber)).toFixed(2))} */}
                            {/* {((cost)/(ozPriceNumber* getRate(fromCurrency)))} */}
                            {/* {commify((cost*1/(goldOz*1)).toFixed(2))} */}
                            {commify((costSwap/(ozPriceNumber)).toFixed(2))}

                          </label>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <br></br>
                          <label className={"privacy"}> ذهب (غرام) </label>

                        </div>
                        <div className="col-sm-12 col-md-3">
                          <br></br>
                          <label className="reuslt-insurance">
                            {commify(((costSwap * 1) / (goldGram * 1)).toFixed(2))}
                          </label>
                        </div>
                      </div>
                      <div className=" row col-sm-12 col-md-12 border-div">
                        <div className="col-sm-12 col-md-3">
                          <br></br>
                          <label className={"privacy"}> فضة (اونصة) </label>

                        </div>
                        <div className="col-sm-12 col-md-3 border-left">
                          <br></br>
                          <label className="reuslt-insurance">
                            {commify(((costSwap) / (ozGrams * silverPriceNumber)).toFixed(2))}

                          </label>
                        </div>
                        <div className="col-sm-12 col-md-3">
                          <br></br>
                          <label className={"privacy"}> فضة (غرام) </label>

                        </div>
                        <div className="col-sm-12 col-md-3">
                          <br></br>
                          <label className="reuslt-insurance">
                            {commify((costSwap * 1 / silverPricee * 1).toFixed(2))}
                          </label>

                        </div>
                      </div>
                      <div className=" row col-sm-12 col-md-12 border-div">
                        
                       
                        <div className="col-sm-12 col-md-3">
                          <br></br>
                          <label className={"privacy"}> ذهب (ليرة انجليزي) </label>

                        </div>
                        <div className="col-sm-12 col-md-3">
                          <br></br>
                          <label className="reuslt-insurance">
                            {commify((costSwap * 1 / goldCoin * 1).toFixed(2))}
                          </label>

                        </div>
                      </div>
                      <br></br>
                      <br></br>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactTooltip id="pdf-tip" place="top" effect="solid">
            طباعة PDF
          </ReactTooltip>
          <ReactTooltip id="excel-tip" place="top" effect="solid">
            طباعة Excel
          </ReactTooltip>

          <div className="row">
            <div className="print-div col-sm-12 col-md-8" style={{ margin: "auto", marginBottom: "40px" }} >

              <button className="btn btn-primary naqaha-btn insurance-btn" onClick={newCalc}> احتساب جديد  </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
