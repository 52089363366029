let f=[
 {
  ar: "فائدة",
  he: "תועלת",
  en: "Utility\/ benefit"
 },
 {
  ar: null,
  he: "ריבית",
  en: "interest"
 },
 {
  ar: "فائدة اسمية",
  he: "ריבית נומינאלית",
  en: "Nominal interest"
 },
 {
  ar: "فائدة اضافية",
  he: "הטבה נלווית",
  en: "Fringe benefit"
 },
 {
  ar: "فائدة بسيطة",
  he: "ריבית פשוטה\/ ריבית שאינה ריבית דריבית",
  en: "Simple interest"
 },
 {
  ar: "فائدة دائنة",
  he: "ריבית קרדיטורית",
  en: "Credit interest"
 },
 {
  ar: "فائدة سلبية",
  he: "ריבית שלילית",
  en: "Negative interest"
 },
 {
  ar: "فائدة متجمعة",
  he: "ריבית נצברת",
  en: "Accrued interest"
 },
 {
  ar: "فائدة مدينة",
  he: "ריבית דביטורית",
  en: "Debit interest"
 },
 {
  ar: "فائدة مركبة",
  he: "ריבית דריבית",
  en: "Compound interest"
 },
 {
  ar: "فائدة مفترضة",
  he: "ריבית זקופה",
  en: "Imputed interest"
 },
 {
  ar: "فائدة مقدرة",
  he: "ריבית זקופה",
  en: "Bill\/ invoice"
 },
 {
  ar: "فاتورة",
  he: "חשבון\/ חשבונית\/ חשבונית מס",
  en: "Bill\/ invoice"
 },
 {
  ar: "فاتورة تجارية",
  he: "חשבון מסחרי",
  en: "Commercial invoice"
 },
 {
  ar: "فاتورة شحن",
  he: "שטר הובלה",
  en: "Drayage bill"
 },
 {
  ar: "فاتورة شكلية",
  he: "חשבון פרופורמה",
  en: "Pro- forma invoice"
 },
 {
  ar: "فاتورة قنصلية",
  he: "חשבונית קונסולרית",
  en: "Consular invoice"
 },
 {
  ar: "فاتورة مبدئية",
  he: "חשבון פרופורמה",
  en: "Pro-forma invoice"
 },
 {
  ar: "فاتورة مصححة",
  he: "חשבון מתוקן",
  en: "Corrected invoice"
 },
 {
  ar: "فاتورة نقل",
  he: "שטר הובלה",
  en: "Drayage bill"
 },
 {
  ar: "فاحص المصارف",
  he: "המפקח על הבנקים",
  en: "Bank examiner\/ bank commissioner"
 },
 {
  ar: "فاقة",
  he: "עוני",
  en: "poverty"
 },
 {
  ar: "فتح اعتماد",
  he: "פתיחת אשראי",
  en: "Opening of credit"
 },
 {
  ar: "فتح حساب",
  he: "פתיחת חשבון",
  en: "Opening of account"
 },
 {
  ar: "فترة استرداد راس المال",
  he: "תקופת החזר",
  en: "Payback period"
 },
 {
  ar: "فترة تجربة",
  he: "תקופת ניסיון",
  en: "Probationary period"
 },
 {
  ar: "فجوة تضخمية",
  he: "פער אינפלציוני",
  en: "Inflationary gap"
 },
 {
  ar: "فجوة دولارية",
  he: "פער דולרי",
  en: "Dollar gap\/ dollar shortage"
 },
 {
  ar: "فدرالي",
  he: "פדראלי",
  en: "federal"
 },
 {
  ar: "فدية",
  he: "כופר",
  en: "ransom"
 },
 {
  ar: "فرض الضرائب",
  he: "מיסוי",
  en: "taxation"
 },
 {
  ar: "فرع ",
  he: "סניף",
  en: "branch"
 },
 {
  ar: "فساد",
  he: "שחיתות",
  en: "corruption"
 },
 {
  ar: "فضة",
  he: "כסף (המתכת)",
  en: "silver"
 },
 {
  ar: "فعالية",
  he: "יעילות",
  en: "efficiency"
 },
 {
  ar: "فقر",
  he: "עוני",
  en: "poverty"
 },
 {
  ar: "فلاحة",
  he: "חקלאות",
  en: "agriculture"
 },
 {
  ar: "فلوس",
  he: "כסך",
  en: "money"
 },
 {
  ar: "في الحالة الراهنة",
  he: "כפי שהוא",
  en: "As is"
 },
 {
  ar: "في المئة",
  he: "אחוז",
  en: "percent"
 },
 {
  ar: "فيض نقدي",
  he: "תזרים מזומנים",
  en: "Cash flow"
 }
]
export default f ;