import { useState, useEffect } from 'react'
import { RiContactsBookLine } from 'react-icons/ri'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useRate = (currency) => {

const [rateEx, setRateEx] = useState();

// useEffect(() => {
//   setc(currency);
// },[])

  // Gold Prices 
  useEffect(() => {
    const getRate= async(currencyTwo) => {
      const currencyOne = 'ILS';
      const urll = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/${currencyTwo}`;
      
      try {
      const val =  await fetch(urll)
          .then(res => res.json())
          .then(data => {
            // console.log(data);
            const ratee = data.conversion_rates[currencyOne];
            const rateD_four = ratee.toFixed(2);
            setRateEx(rateD_four)
  
          });}
          catch{
            console.log("cant fitch")
          }
          // console.log("Currency value is :", currency )

          
         
    }
      
 
    getRate (currency);
    
  },[]);

  
  // console.log("rate from api  : ", rateEx);


  return { rateEx }
}