/**
 * TermsCondition Page
 */
import React, { Component, useState } from 'react';
import { Helmet } from "react-helmet";
import { FiSettings } from 'react-icons/fi';
import { FaSignature, FaPercent } from 'react-icons/fa';
import { AiOutlineLink } from 'react-icons/ai'
import { Mpayroll, Mpayroll2, MassReport, MassPayroll, MassAsset, MassManage, MassTax } from '../../Entryfile/imagepath';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BsCalculator } from 'react-icons/bs';
import { VscLaw } from 'react-icons/vsc';
import { RiGovernmentLine } from 'react-icons/ri';
import Attendance from '../../pr-system/attendance';
import Data from './national-insurance/data/output'
// import Attendance from '../attendance';
export default function MassTaxes() {
	const [year, setYear] = useState(0)
	//
	let creditPointValue = Data.taxData[year].creditPoint
	//
	let studyFundEmployee = Math.round(Data.taxData[year].studyFund.employee.ceiling / 12)
	let studyFundSelfEmployee = Math.round(Data.taxData[year].studyFund.selfEmployed.ceiling / 12)
	//
	let houseRent = Data.taxData[year].massHousRent
	//
	let blindIncome = Data.taxData[year].massBlindIncome
	//
	let vatExempt = Data.taxData[year].massVatExempt

	return (

		<div className="page-wrapper">
			<Helmet>
				<title>MassPortal - حاسبات الضريبة   </title>
				<meta name="description" content="Reactify Blank Page" />
			</Helmet>
			{/* Page Content */}
			<div className="content container-fluid">
				{/* Page Header */}
				<div className="page-header">
					<div className="row">
						<div className="col-sm-8 col-4">
							<h3 className="page-title"> حاسبات الضريبة       </h3>
							<ul className="breadcrumb">
								<li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
							</ul>
						</div>
					</div>
				</div>
				{/* Account Logo */}
				<div className="account-logo">
					{/* <a href="/"><img src={MassTax} alt="Mass Tax" /></a> */}
					<img src={MassTax} alt='MassPortal Taxes ' />
				</div>
				<div className="col-lg-10 mx-auto">

					<div className="col-md-12 ">
						<div className="card card-table mb-0">
							<div className="card-header">
								<h4 className="card-title mb-0 privacy">   معطيات لسنة
									<select

										onChange={(e) => setYear(e.target.value)}
										className="btn vit-select year-select "
									>
										{Data.taxData.map((year, index) => (
											<option className="text-dark year-text-dark" value={index} key={index}>
												{year.taxYear}
											</option>
										))}
									</select></h4>

							</div>
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-center mb-0">
										<thead className='text-center'>
											<tr >
												<th className='text-center table-tr-underline'>قيمة نقطة التزكية</th>
												<th className='text-center table-tr-underline'> حد أعلى راتب استكمال </th>
												<th className='text-center table-tr-underline'> حد أعلى راتب استكمال </th>
												<th className='text-center table-tr-underline'>دخل إيراد أجار بيتي </th>
												<th className='text-center table-tr-underline'>دورة صفقات    -  <span className='privacy'> سنوي </span> </th>
												<th className='text-center table-tr-underline'>حد أعلى دخل معفي  -  <span className='privacy'> شهري </span> </th>

											</tr>
											<tr>
												<th> سنة -  <span className='privacy'> {2023 - year} </span>    </th>
												<th> مهني موظف - <span className='privacy'> ش </span></th>
												<th> مهني مستقل - <span className='privacy'> ش </span></th>
												<th>معفى</th>
												<th>ملف معفي  <span className='privacy'> ض.ق.م </span></th>
												<th>أعمى<span className='privacy'> / </span> مقعد</th>

											</tr>
										</thead>
										<tbody>
											<tr className='text-center  privacy tr-givens'>
												<td className=''>{creditPointValue}</td>
												<td>{studyFundEmployee}</td>
												<td>{studyFundSelfEmployee}</td>
												<td>{houseRent}</td>
												<td>{vatExempt}</td>
												<td>{blindIncome}</td>
												{/* <td><a className="btn btn-primary btn-sm" href="">Change Plan</a></td> */}
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* /Account Logo */}
				{/* /Page Header */}
				<br />
				<br />
				<hr />
				<div className="row">

					<div className="col-lg-10 mx-auto">
						{/* Plan Tab */}
						<div className="row justify-content-center mb-4">
							<div className="col-auto">
								<nav className="nav btn-group settings-nav">
									<a href="#monthly" className="btn btn-outline-secondary active show" data-toggle="tab"> جداول </a>
									<a href="#annual" className="btn btn-outline-secondary" data-toggle="tab"> حاسبات </a>
								</nav>
							</div>
						</div>
						{/* /Plan Tab */}
						{/* Plan Tab Content */}
						<div className="tab-content">
							{/* Monthly Tab */}
							<div className="tab-pane fade active show" id="monthly">
								<div className="row mb-30 equal-height-cards">
									<div className="col-md-4">
										<div className="card pricing-box">
											<div className="card-body d-flex flex-column">
												<div className="mb-4">
													<br></br>
													<h2 className="settings-title">ضريبة الدخل  - <span className='privacy'> ( ض.ق.م )</span> </h2>
													<span className="display-6"><FaPercent className='privacy' /></span>
												</div>
												<ul className='text-left settings-mass'>
													<li><a href='/calculators/WorkLawsCalc/Tax23' ><FaPercent size={20} className='privacy' />  شرائح ضريبة الدخل لسنة-2023 </a></li>
													<li><a href='/calculators/WorkLawsCalc/Tax22' ><FaPercent size={20} className='privacy' />  شرائح ضريبة الدخل لسنة-2022 </a></li>
													<li><a href='/calculators/WorkLawsCalc/Tax21' ><FaPercent size={20} className='privacy' />  شرائح ضريبة الدخل لسنة-2021 </a></li>
													<li><a href='/vat-table' ><FaPercent size={20} className='privacy' />  نسب ضريبة القيمة المضافة لعدة سنوات </a></li>
													<li><a href='/shaam-tax' ><FaPercent size={20} className='privacy' />  لنكات</a></li>
													<li><a href='/shaam-codes' ><FaPercent size={20} className='privacy' /> שאילתות לשע"מ </a></li>




													{/* <li><i className="fa fa-check" /> 5 Projects </li>
								<li><i className="fa fa-check" /> 5 GB Storage</li>
								<li><i className="fa fa-check" /> Unlimited Message</li> */}
												</ul>

												{/* <a href="/shaam-tax" className="btn btn-lg btn-primary mt-auto">ضريبة الدخل </a> */}
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card pricing-box">
											<div className="card-body d-flex flex-column">
												<div className="mb-4">

													{/* <h3>تقارير </h3> */}
													<br></br>
													<h2 className="settings-title">ضريبة العقارات </h2>
													<span className="display-6"><RiGovernmentLine className="privacy" /></span>
													{/* <span>/mo</span> */}
												</div>
												<ul className="text-left settings-mass">
													<li><a href='/tax-table'><RiGovernmentLine size={25} className='privacy' /> نسب ضريبة الأملاك  </a></li>
													<li><a href='/tax-table-real-shebah'><RiGovernmentLine size={25} className='privacy' />  نسب ضريبة ربح العقارات (שבח)   </a></li>
													<li><a href='/tax-table-oneTime-shebah'><RiGovernmentLine size={25} className='privacy' />  الضرائب المطبقة على  مرة واحدة لبائع شقتين</a></li>
													<li><a href='/rasham-comp'><RiGovernmentLine size={25} className='privacy' /> بجث عن شركة في مراقب الشركات     </a></li>
													<li><a href='/rasham-as'><RiGovernmentLine size={25} className='privacy' /> بحث عن جمعية في مراقب الجمعيات     </a></li>

												</ul>
												{/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card pricing-box">
											<div className="card-body d-flex flex-column">
												<div className="mb-4">
													<br></br>
													<h2 className="settings-title">ضريبة القيمة المضافة </h2>
													<span className="display-6"><FaPercent className='privacy' /></span>
													{/* <span>/mo</span> */}
												</div>
												<ul className="text-left settings-mass">
													<li><a href='/vat-table'><FaPercent size={25} className='privacy' />نسب ضريبة القيمة المضافة لعدة سنوات  </a></li>
													<li><a href='#'><FaPercent size={25} className='privacy' /> أخرى ...   </a></li>
													<li><a href='#'><FaPercent size={25} className='privacy' /> أخرى ...   </a></li>
													<li><a href='#'><FaPercent size={25} className='privacy' /> أخرى ...    </a></li>
													<li><a href='#'><FaPercent size={25} className='privacy' /> أخرى ...    </a></li>
												</ul>
												{/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* /Monthly Tab */}
							{/* Yearly Tab */}
							<div className="tab-pane fade" id="annual">
								<div className="row mb-30 equal-height-cards">
									<div className="col-md-4">
										<div className="card pricing-box">
											<div className="card-body d-flex flex-column">
												<div className="mb-4">
													<br></br>
													<h2 className="settings-title">ضريبة الدخل - تأمين وطني   </h2>
													<span className="display-6"><BsCalculator className='privacy' /></span>
												</div>
												<ul className='text-left settings-mass'>
													<li><a href='/calc-rtx'><BsCalculator size={25} className='privacy' /> احتساب ضريبي مفصل متعدد السنوات </a></li>
													<li><a href='/calc-rtx-21'><BsCalculator size={25} className='privacy' /> فحص إرجاع ضريبي سريع -2021     </a></li>
													<li><a href='/nationalIns-calculator'><BsCalculator size={25} className='privacy' /> حساب التأمين الوطني   </a></li>
												</ul>
												{/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card pricing-box">
											<div className="card-body d-flex flex-column">
												<div className="mb-4">
													<br></br>
													<h2 className='settings-title'> ضريبة العقارات </h2>
													<span className="display-6"><BsCalculator className='privacy' /></span>

													{/* <h3>Professional</h3>
								<span className="display-4">$199</span>
								<span>/mo</span> */}
												</div>
												<ul className='text-left settings-mass'>
													<li><a href='/mass-tax-abt-calc'><BsCalculator size={25} className='privacy' /> احتساب ضريبة الأملاك     </a></li>
													<li><a href='#'><BsCalculator size={25} className='privacy' /> احتساب ضريبة أرباح العقارات      </a></li>
													<li><a href='#'><BsCalculator size={25} className='privacy' /> أخرى...      </a></li>
													<li><a href='#'><BsCalculator size={25} className='privacy' /> أخرى...     </a></li>
													<li><a href='#'><BsCalculator size={25} className='privacy' />  أخرى ...      </a></li>


												</ul>
												{/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card pricing-box">
											<div className="card-body d-flex flex-column">
												<div className="mb-4">
													<br></br>
													<h2 className='settings-title'> ضريبة القيمة المضافة  </h2>
													<span className="display-6"><BsCalculator className='privacy' /></span>
												</div>
												<ul className='settings-mass text-left '>
													<li ><a href="calculators/open-vat-calc" > <BsCalculator size={25} className='privacy' />   احسب ضريبتك في خمس دقائق  </a></li>
													<li><a href='/calculator-vat'><BsCalculator size={25} className='privacy' /> حساب ض.ق.م سريع  </a></li>
													<li><a href='/month-vat-rpt'><BsCalculator size={25} className='privacy' /> تعبئة كشف دوري </a></li>
													<li><a href='/calculator-geloom'><BsCalculator size={25} className='privacy' /> احتساب ضرائب تمثيلي (גילום)    </a></li>
													<li><a href='#'><BsCalculator size={25} className='privacy' /> أخرى...    </a></li>


												</ul>
												{/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* Yearly Tab */}
						</div>
						{/* /Plan Tab Content */}
						{/* Plan Details */}
						<div className='row'>
							<div className="col-lg-12 mx-auto">
								<br></br>
								{/* <div className="table-responsive">
              <table className="table table-striped custom-table table-nowrap mb-0">
                <thead>
                  <tr>                   
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>                  
                  </tr>
                </thead>
                <tbody>
                  <tr>                   
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>                  
                  </tr>                 
                </tbody>
              </table>
            </div> */}
								<br></br>
							</div>
						</div>

						{/* /Plan Details */}
					</div>
				</div>
			</div>
			{/* /Page Content */}
		</div>

	);
}

