import React, { useState, useEffect } from "react";



import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
import {IoCarSportOutline} from 'react-icons/io5'


import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useAuthContext } from "../../../hooks/useAuthContext";
import { massportallogo } from "../../../Entryfile/imagepath"
import { BiShekel } from 'react-icons/bi';
import ReactTooltip from "react-tooltip";


export function CalculatorPCar(props) {
  const printRef = React.useRef();

  const [showDev, setShowDev] = useState(false)
  const [isActive, setIsActive] = useState(false);
  const handleAddFields = () => {
    // setInputFields([...inputFields,{totalTax:'', payedTax:'', leftTax:''}]);

    // console.log("handle ");

  }
  //HOOKS
  const { user } = useAuthContext()


  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "")
    );
  }


  // const handleremoveFields = (index)=>{
  //   const values = [...inputFields];
  //   values.splice(index, 1);
  //   setInputFields(values);
  // }
  // const handleChangeInput = (index , event) =>{
  //   console.log(index, event.target.name);
  // }







  const [lincense, setLincense] = useState('0');
  const [insurance, setInsurance] = useState('0');
  const [fullInsurance, setFullInsurance] = useState('0');
  const [b7at, setB7at] = useState('0');
  const [fuel, setFuel] = useState('0');
  const [tire, setTire] = useState('0');
  const [repair, setRepair] = useState('0');
  const [another, setAnother] = useState('0');
  const [tax, setTax] = useState(0);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [totalfinal, setFinal] = useState('0')
  const [label1Value, setLabel1Value] = useState('0')
  const [label2Value, setLabel2Value] = useState('0')
  const [label3Value, setLabel3Value] = useState('0')
  const [pensvalue, setbensValue] = useState('0');
  const [donvalue, setdonValue] = useState('0');
  const [span3, setSpan3] = useState('0')
  // let pricePoint = 218;
  // let num = [
  //    6290,
  //    9030,
  //    14490,
  //    20140,
  //    41910,
  //    53970];

  // let percentage =[
  //    0.1,
  //    0.14,
  //    0.2,
  //    0.31,
  //    0.35,
  //    0.47,
  //    0.5];

  // let np =[
  // (num[0])*percentage[0],
  // (num[1]-num[0])*percentage[1],
  // (num[2]-num[1])*percentage[2],
  // (num[3]-num[2])*percentage[3],
  // (num[4]-num[3])*percentage[4],
  // (num[5]-num[4])*percentage[5]];

  const reloadWindow = (evt) => {
    window.location.reload();
  }

  const handleSubmit = (evt) => {

    evt.preventDefault();
    let id = Math.random();
    if ((lincense || repair || fuel || tire || insurance || fullInsurance || another || b7at) > 0) {
      setShowDev(true);
      setTax(calculateTax);
    }
    // setShow(true)

    function calculateTax() {


      setData(prev => [...prev,
      {
        id: id,
        tax: tax,

        total: tax,
        monthlyIncome: 0
      }])

      if (tax < 0) {
        // console.log("cancel and return ")
        tax = 0;
      }

      const taxFix = parseFloat(lincense) + parseFloat(insurance) + parseFloat(fullInsurance) + parseFloat(b7at);
      const taxVar = parseFloat(tire) + parseFloat(another) + parseFloat(fuel) + parseFloat(repair);
      const label1 = parseFloat(taxFix).toFixed(2);
      const label2 = parseFloat(taxVar).toFixed(2);
      const total = label1 * 1 + label2 * 1;
      const label3 = parseFloat(total).toFixed(2);
      setbensValue(0);
      setdonValue(0)
      setLabel1Value(label1);
      setLabel2Value(label2);
      setLabel3Value(label3);

    }


  }
  // PDF print function 
  const handleDownloadPdf = async () => {

    const element = printRef.current;
    element.style.background = '#FFFFFF';
    // element.style.color = '#212529';

    const iaactive = true;
    await setIsActive(iaactive);
    //  Current Date 
    const d = new Date().toLocaleString() + "";
    let current = d.split(',');
    const today = current[0];
    const todaySplitArr = today.split('/');
    const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
    // Current Date 

    // PDF Header And Footer Values 
    const header = todayFrm;



    const canvas = await html2canvas(element);


    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();


    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;


    // pdf.addImage(data, 'PNG', 0, 10, pdfWidth, pdfHeight);
    // // Header
    // pdf.text(header, 2, 15, { baseline: 'top' });
    // // Footer
    //  Header  - Logo
    pdf.addImage(massportallogo, 'png', 90, 5, 20, 20);
    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 25, pdfWidth, pdfHeight);
    // Header - Date 
    pdf.text(header, 10, 34, { baseline: 'top' });

    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const footer = d + '             www.mass-portal.com';
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight - 2, { baseline: 'bottom' });
    pdf.setFontSize(20);
    pdf.save(' مصاريف مركبة شخصية .pdf');
  };

  return (
    <div className="row">
      <div className="col-xl-6 d-flex">
        <div className="card flex-fill">
          <div className="card-header">

            <h4 className="card-title mb-0 privacy ">تكلفة مصاريف مركبة  -<span className="privacy"> سنوي</span> <IoCarSportOutline size={50} className="fleet-car-icon" /> </h4>
          </div>
          <div className="card-body">

            <form onSubmit={handleSubmit}>

              {/* <div className="form-group row">
                          <label className="col-lg-4 col-form-label">اختر السنة </label>
                          <div className="col-lg-8">
                            <select className="select rtx-select">
                             
                              <option value={1}>2022</option>
                              <option value={2}>2021</option>
                              <option value={3}>2020</option>
                              <option value={4}>2019</option>
                              <option value={4}>2018</option>
                              <option value={4}>2017</option>
                              <option value={4}>2016</option>
                            </select>
                          </div>
                        </div> */}

              <div className="form-group row">

                <label className="col-lg-12 col-form-label privacy">  مصاريف ثابتة  </label>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">  الترخيص </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setLincense(e.target.value)}

                  />


                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label"> تأمين الزامي </label>
                <div className="col-lg-8">
                  <input type="text"
                    className="form-control"
                    onChange={(e) => setInsurance(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">تأمين شامل </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setFullInsurance(e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="form-group row">
              <label className="col-lg-4 col-form-label">  قيمة نقاط تزكية أخرى </label>
              <div className="col-lg-8">
                <input type="number" className="form-control" />
              </div>
            </div> */}
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   الاستهلاك  </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setB7at(e.target.value)}
                  />
                </div>

              </div>
              <div className="form-group row">
                <label className="col-lg-12 col-form-label privacy">   مصاريف متغيرة  </label>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   وقود </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setFuel(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   الصيانة </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setRepair(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   إطارات </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setTire(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   اخرى </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setAnother(e.target.value)}
                  />
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-return" > احتسب  </button>
              </div>
            </form>
          </div>
        </div>
      </div>



      <div className={showDev ? "col-xl-5 d-flex" : "hide-div"} >
        <div className="card flex-fill container">
          <div className="row  text-right ">
            <div className="col-sm-12 col-md-10" >


            </div>
            <div className="col-sm-12 mt-3  "  >
              <button className="btn   " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                <AiOutlineFilePdf size={30} className='privacy ' />
              </button>
              <button className="btn " data-tip data-for="excel-tip">
                <SiMicrosoftexcel size={30} className='privacy ' />
              </button>
              <ReactTooltip id="pdf-tip" place="top" effect="solid">
                طباعة PDF
              </ReactTooltip>
              <ReactTooltip id="excel-tip" place="top" effect="solid">
                طباعة Excel
              </ReactTooltip>
            </div>

          </div>

          <div className= { isActive?"card-white":"card"} ref={printRef}>
          <br></br>
          <div className="row">
                  <br></br>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? "privacy-black-bold" : "privacy hide "} id="date-block"> </h4> </div>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? "  privacy-black-bold " : "privacy hide "} id="date-block"> مصاريف مركبة شخصية</h4> </div>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? " privacy-black-bold text-left" : "privacy hide "} id="date-block"> التاريخ </h4> </div>
                </div>
            <form  className={isActive?"form-white":" "}>
              <div className= {isActive?"card-body-white":"card-body"} >
                
                <br></br>
                <div className="form-group row">
                  <label className="col-lg-12 col-form-label"> مجموع المصاريف الثابتة </label>
                  <div className= "col-lg-12">
                    <label className= " form-control result-label" id="label1"> {commify(label1Value)} <BiShekel /></label>

                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-12 col-form-label">  مجموع المصاريف المتغيرة   </label>
                  <div className="col-lg-12">
                    <label className=" form-control result-label" > {commify(label2Value)} <BiShekel /></label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className= "col-lg-12 col-form-label">  المجموع الكلي  -<span className={isActive ? "privacy-black-bold text-center" : "col-form-control privacy"}> سنوي </span>   </label>
                  <div className= "col-lg-12">
                    <label className=" form-control result-label" > {commify(label3Value)} <BiShekel /></label>
                  </div>
                </div>
                <div className="form-group row">
                  <label className= "col-lg-12 col-form-label" >  المجموع الكلي  -<span className={isActive ? "privacy-black-bold text-center" : "col-form-control privacy"}> شهري </span> </label>
                  <div className= "col-lg-12">
                    <label className= " form-control result-label" > {commify((label3Value / 12).toFixed(2))} <BiShekel /></label>
                  </div>
                </div>

              </div>

             
              
            </form>

          </div>
          <div className="text-center">
                <button onClick={reloadWindow} className="btn btn-primary btn-return"> احتساب جديد  </button>
              </div>
        </div>
      </div>
      

    </div>
  )
}