import React ,{useState}from "react";
// import taxProperty from "../../../../../mass-modules/mass-taxes/real-state/farm";
import fuelPrice from './data/fuel'
export function TxTblFuel(){


  
    return(<div className="" id="accordion">
				
                
                {
                fuelPrice.fuelPrice.map((data,index) =>	
                        
                    <div >
                      
                       
                    <div className="card  ">
                        <div className="card-header" id={ "heading"}>
                             <h4 className="mb-0">
                                <button  className="btn btn-dark  privacy"  data-toggle="collapse" data-target={"#collapse"+index} aria-expanded="false" aria-controls={"collapse"} >
                                    {data.year}
                                </button>
                             </h4>
                        </div>

                        <div id={"collapse"+index} className={!index? "collapse show ":"collapse "} aria-labelledby={"heading"} data-parent="#accordion">
                            <div className="card-body">
                            <table className="table table-nowrap table-striped mb-0 ">
                                <thead> 
                                
                               
                                

                                </thead>
                                    <thead>
                        
                                        <tr  >
                                            <th  className="privacy ">التاريخ </th>
                                            
                                            <th className="privacy">سعر الوقود (بنزين 95)</th>
                                            
                                            
                                            
                                            
                                            
                                        
                                            {/* <th>Country</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                       
                                        <tr >
                                            <td>{data.band1.date}</td>
                                            <td>{(data.band1.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr className="privacy">
                                            <td >{data.band2.date}</td>
                                            <td>{(data.band2.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr >
                                            <td>{data.band3.date}</td>
                                            <td>{(data.band3.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr  className="privacy">
                                            <td>{data.band4.date}</td>
                                            <td>{(data.band4.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr >
                                            <td>{data.band5.date}</td>
                                            <td>{(data.band5.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr  className="privacy">
                                            <td>{data.band6.date}</td>
                                            <td>{(data.band6.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr >
                                            <td>{data.band7.date}</td>
                                            <td>{(data.band7.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr  className="privacy">
                                            <td>{data.band8.date}</td>
                                            <td>{(data.band8.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr >
                                            <td>{data.band9.date}</td>
                                            <td>{(data.band9.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr  className="privacy">
                                            <td>{data.band10.date}</td>
                                            <td>{(data.band10.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr >
                                            <td>{data.band11.date}</td>
                                            <td>{(data.band11.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                        <tr  className="privacy"> 
                                            <td>{data.band12.date}</td>
                                            <td>{(data.band12.price).toFixed(2)}</td>
                                            
                                            

                                        </tr>
                                       
                                    </tbody>
                            </table>                               
                            </div>
                        </div>
                    </div>
            
            
            </div>)
                 } 
                </div>)



{/* <div id="accordion">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Collapsible Group Item #1
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
      </div>
    </div>
  </div>
  
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Collapsible Group Item #3
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
      </div>
    </div>
  </div>
</div> */}

}