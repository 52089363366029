/**
 * TermsCondition Page
 */
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import { FaInfo } from "react-icons/fa";
import { NatCalc } from './NationalInsCalc';
import taxData from './data/output';

export default function NatInCalcP() {
	const [slide1National, setSlide1N] = useState(' ');
	const [slide1NationalE, setSlide1NE] = useState(' ');
	const [slide2National, setSlide2N] = useState(' ');
	const [slide2NationalE, setSlide2NE] = useState(' ');
	const [slide1NatEmp, setSlide1NEmp] = useState(' ');
	const [slide2NatEmp, setSlide2NEmp] = useState(' ');
	const [slide1Health, setSlide1H] = useState(' ');
	const [slide2Health, setSlide2H] = useState(' ');

	//
	const [yearIndex,setYearIndex]=useState("0")

	useEffect(() => {
		const getData = () => {
			let short1 = taxData.taxData[yearIndex].bituachLeumi.nationalInsurance.band1.rate.selfEmployed;
			setSlide1N(short1.toFixed(2))
			let short2 = taxData.taxData[yearIndex].bituachLeumi.nationalInsurance.band2.rate.selfEmployed;
			setSlide2N(short2)
			let short3 = taxData.taxData[yearIndex].bituachLeumi.nationalInsurance.band1.max;
			setSlide1NEmp(short3)
			let short4 = taxData.taxData[yearIndex].bituachLeumi.nationalInsurance.band2.max;
			setSlide2NEmp(short4)
			let short1Emp = taxData.taxData[yearIndex].bituachLeumi.nationalInsurance.band1.rate.employee;
			setSlide1NE(short1Emp)

			let short2Emp = taxData.taxData[yearIndex].bituachLeumi.nationalInsurance.band2.rate.employee;
			setSlide2NE(short2Emp)

			let short5 = taxData.taxData[yearIndex].bituachLeumi.healthInsurance.band1.rate.selfEmployed;
			setSlide1H(short5)
			let short6 = taxData.taxData[yearIndex].bituachLeumi.healthInsurance.band2.rate.selfEmployed;
			setSlide2H(short6)
		}
		getData()
	}, [yearIndex]);

	return (

		<div className="page-wrapper">
			<Helmet>
				<title>MassPortal - التأمين الوطني    </title>
				<meta name="description" content=" MassPortal - قوانين العمل   " />
			</Helmet>
			{/* Page Content */}
			<div className="content container-fluid">
				{/* Page Header */}
				<div className="page-header">
					<div className="row">
						<div className="col-sm-8 col-4">
							<h3 className="page-title"> التأمين الوطني         </h3>
							<ul className="breadcrumb">
								<li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
							</ul>
						</div>
					</div>
				</div>
				{/* Account Logo */}
				{/* <div className="account-logo">
			  <img src={MassLow} alt='MassPortal Law' />
            </div> */}
				{/* /Account Logo */}
				{/* /Page Header */}


				<div className="row">

					<div className="col-lg-10 mx-auto">
						{/* Plan Tab */}
						<div className="row justify-content-center mb-4">
							<div className="row">

								<div className="col-md-12">
									<div className="card card-table mb-0">
										<div className="card-header">
											<h4 className="card-title mb-0 privacy">معطيات - <span>{taxData.taxData[yearIndex].taxYear}</span>   </h4>
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<table className="table table-center mb-0">
													<thead>
														<tr className='' >
															<th colSpan={2} className='text-center table-tr-underline'>الشريحة الأولى -  <span className='privacy'> مستقل </span>  </th>

															<th colSpan={2} className='text-center table-tr-underline'>الشريحة الثانية - <span className='privacy'> مستقل</span>    </th>
															<th colSpan={2} className='text-center table-tr-underline'>الشريحة الأولى -  <span className='privacy'> موظف </span>  </th>
															<th colSpan={2} className='text-center table-tr-underline'>الشريحة الثانية -  <span className='privacy'> موظف </span>    </th>
															<th className='text-center table-tr-underline'>الشريحة الأولى     </th>
															<th className='text-center table-tr-underline'>الشريحة الثانية      </th>
															<th></th>
															<th></th>
															{/* <th>راتب حد أدنى / شهري </th>
																<th>راتب حد أدنى / ساعة  </th>
																<th>معدل الرواتب </th>
																<th>يوم سفريات </th> */}

														</tr>

													</thead>
													<tbody>

														<tr className='text-center '>
															<td>تأمين وطني</td>
															<td>تأمين صحي </td>
															<td>تأمين وطني</td>
															<td>تأمين صحي </td>
															<td>تأمين وطني</td>
															<td>تأمين صحي </td>
															<td>تأمين وطني</td>
															<td>تأمين صحي </td>
															<td className='text-center'>شهري      </td>
															<td className='text-center'>شهري       </td>



														</tr>
														<tr className='text-center privacy tr-givens'>
															<td> {slide1National} % </td>
															<td> {slide1Health} % </td>
															<td> {slide2National} % </td>
															<td>{slide2Health} % </td>
															<td>   {slide1NationalE} % </td>
															<td>  {slide2NationalE} % </td>
															<td> {slide1Health} % </td>
															<td> {slide2Health} % </td>
															<td>من 0 - {slide1NatEmp} </td>
															<td data-tip data-for="x-tip">حتى {slide2NatEmp}    </td>


															{/* <td><a className="btn btn-primary btn-sm" href="">Change Plan</a></td> */}
														</tr>
													</tbody>
												</table>
												<ReactTooltip id="x-tip" place="bottom" effect="solid">
													ما فوق هذا المبلغ -  معفي
												</ReactTooltip>
											</div>
										</div>
									</div>
								</div>
							</div>
							<br></br>
							<div className="col-auto">
								<br></br>
								<nav className="nav btn-group settings-nav">
									{taxData.taxData.map((year,index)=>(
										<a onClick={(e)=>{setYearIndex(index)}} key={index} value={index} href={'#tab'+(taxData.taxData.length - index)} className={ index == 0 ? "btn btn-outline-secondary active show" : "btn btn-outline-secondary "} data-toggle="tab"> {year.taxYear}</a>
									))}
									
								</nav>
							</div>
							<div className="col-auto">
								{/* Modal Button */}
								<a href="#" className="btn add-btn info-btn" data-toggle="modal" data-target="#add_employee"><FaInfo /> توضيح</a>
								{/* Modal Button */}
							</div>
							
						</div>
						<div className="tab-content ">
							{/* Yearly Tab */}
							<div className="tab-pane fade" id="tab1">
								<div className="row mb-30 equal-height-cards ">

									<div className='col-sm-12 col-md-10 insurance-cards'>
										<NatCalc
											year={'2019'}
										/>
									</div>
								</div>
							</div>
							{/* Yearly Tab */}
							<div className="tab-pane fade" id="tab2">
								<div className="row mb-30 equal-height-cards">

									<div className='col-sm-12 col-md-10 insurance-cards'>
										<NatCalc
											year={'2020'}
										/>
									</div>
								</div>
							</div>
							{/* Yearly Tab */}
							<div className="tab-pane fade" id="tab3">
								<div className="row mb-30 equal-height-cards">

									<div className='col-sm-12 col-md-10 insurance-cards'>
										<NatCalc
											year={'2021'}
										/>
									</div>
								</div>
							</div>
							{/* Yearly Tab */}
							<div className="tab-pane fade" id="tab4">
								<div className="row mb-30 equal-height-cards">

									<div className='col-sm-12 col-md-10 insurance-cards'>
										<NatCalc
											year={'2022'}
										/>


									</div>
								</div>
							</div>
							<div className="tab-pane fade  active show" id="tab5">
								<div className="row mb-30 equal-height-cards">

									<div className='col-sm-12 col-md-10 insurance-cards'>
										<NatCalc
											year={'2023'}
										/>


									</div>
								</div>
							</div>
						</div>

						{/* Modal Button */}
						<div className='col-sm-12 col-md-2'></div>
						{/* <div className=" modal-div col-sm-12 col-md-6">
										<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_employee"><FaInfo /> توضيح</a>
									</div> */}
						{/* Modal Button */}
						{/* Explaine Modal */}
						<div id="add_employee" className="modal custom-modal fade" role="dialog">
							<div className="modal-dialog modal-dialog-centered modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title title-underline">التأمين الوطني </h5>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">×</span>
										</button>
									</div>
									<div className="  modal-body">
										<div className='row'>
											<div className='col-sm-12 col-md-12'>

											
											<ul className='privacy-ol privacy-ol-large'>
												<li className='modal-title'>وفقًا للمادة 345 من قانون التأمين الوطني</li>
												<ul>
													<li>
														<p>
															يتم تحديد دخل الشخص الذي يعمل لحسابه الخاص في
															العام الحالي وفقًا للتقييم النهائي للدخل لتلك السنة ، قبل أي إعفاء واستقطاعات و ائتمانات يحق للشخص بموجبها قانون ضريبة الدخل.
														</p>
													</li>
													<li>
														<p>
															52 % من مبلغ التأمين الوطني  المستحقة على المؤمن عليه (باستثناء رسوم التأمين الصحي).
															يعترف به مصروف لغاية احتساب ضريبة الدخل
														</p>
													</li>
												</ul>
												<li className='modal-subtitle '>مثال على احتساب التأمين الوطني والتأمين الصحي  للمؤمن الذي يعمل لحسابه الخاص .</li>
												<div className='col-sm-12 '>
												<ul className='privacy-ol privacy-ol-large'>
													<li>
														<p>
															إذا كان دخله الشهري هو <span className='privacy-bold'> 10،000 </span> شيكل في سنة <span className='privacy-bold'> 2022 </span>:
														</p>
													</li>
													<ul className='privacy-ol border  '>
														<br></br>
														<li>	<h4> التأمين الوطني </h4></li>
														<ol className='privacy-ol col-sm-12'>
															<li>
																<p>
																	الدخل الشهري - 10،000 شيكل

																</p>
															</li>
															<li>
																<p>
																	يتم دفع  <span className='privacy-bold'>   2.87 ٪ </span> من دخل 1 شيكل و حتى 6331

																</p>
															</li>
															<li>
																<p>
																	يتم دفع <span className='privacy-bold '>  12.83 ٪  </span>من دخل 6332  شيكل وحتى 45075 ما فوق هذا المبلغ <span className='privacy-bold '> يعفى </span> من التأمين الوطني.
																</p>
															</li>
														</ol>
													</ul>
													<ul className='privacy-ol border '>
														<br></br>
														<li>	<h4> التأمين الصحي </h4></li>
														<ol className='privacy-ol'>
															<li>
																<p>
																	الدخل الشهري - 10،000 شيكل
																</p>
															</li>
															<li>
																<p>
																	يتم دفع <span className='privacy-bold '>   3.1 ٪ </span> من دخل 1 شيكل و حتى 6331
																</p>
															</li>
															<li>
																<p>
																	يتم دفع  <span className='privacy-bold'> 5  ٪ </span> من دخل 6332  شيكل وحتى 45075 ما فوق هذا المبلغ <span className='privacy-bold '> يعفى </span> من التأمين الصحي.
																</p>
															</li>
														</ol>
													</ul>
												</ul>
												</div>
											</ul>
											</div>
										</div>
										<form>
											<div className="submit-section">
												<button className="btn btn-primary submit-btn">اغلاق   </button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						{/* / Explaine Modal */}
						{/* /Plan Tab Content */}
						{/* Plan Details */}
						{/* <div className='row'>
				  <div className="col-lg-12 mx-auto"> 
			  <br></br>
			  <div className="table-responsive">
              <table className="table table-striped custom-table table-nowrap mb-0">
                <thead>
                  <tr>                   
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>                  
                  </tr>
                </thead>
                <tbody>
                  <tr>                   
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>                  
                  </tr>                 
                </tbody>
              </table>
            </div>
			  <br></br>
			  </div>			  
				  </div> */}

						{/* /Plan Details */}
					</div>
				</div>
			</div>
			{/* /Page Content */}
		</div>

	);

}
