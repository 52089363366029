let gem=[
 {
  ar: "جباية",
  he: "גבייה",
  en: "Collection"
 },
 {
  ar: "جرد",
  he: "מלאי",
  en: "Inventory\/ stock"
 },
 {
  ar: "جرد مستمر",
  he: "מלאי מתמשך",
  en: "Continuous inventory"
 },
 {
  ar: "الجماعة الاقتصادية الاوروبية",
  he: "הקהילה הכלכלית האירופית",
  en: "European economic"
 },
 {
  ar: "جمرك",
  he: "מכס",
  en: "Customs\/ import duty\/ import tariff"
 },
 {
  ar: null,
  he: null,
  en: "Customs house"
 },
 {
  ar: null,
  he: "בית המכס",
  en: null
 },
 {
  ar: "جمع الاموال",
  he: "גיוס כספים",
  en: "Raising of funds"
 },
 {
  ar: "جمعية",
  he: "התאחדות",
  en: "Association"
 },
 {
  ar: "الجمعية الاوروبية للتجارة الحرة",
  he: "ההתאחדות האירופית לסחר חופשי",
  en: "European free trade association"
 },
 {
  ar: "جمعية تعاونية",
  he: "אגודה שיתופית",
  en: "Cooperative society"
 },
 {
  ar: "جمعية عمومية للمساهمين",
  he: "אסיפה כללית של בעלי המניות",
  en: "Shareholders' general meeting"
 },
 {
  ar: "جيرو",
  he: "העברת אשראי",
  en: "Credit transfer\/ giro"
 }
]
export default gem ;