let tah =[
 {
  ar: "تاجر",
  he: "סוחר",
  en: "Tradesman\/ trader\/ merchant\/ dealer"
 },
 {
  ar: "تاجير",
  he: "החכרה\/ השכרה",
  en: "Lease\/ rent"
 },
 {
  ar: "تاجيل دفع الديون",
  he: "מורטוריום\/ דחיית פרעון חובות",
  en: "Moratorium"
 },
 {
  ar: "تاخير الدفع",
  he: "דחיית תשלום",
  en: "Delay of payment"
 },
 {
  ar: "تاريخ استحقاق ربح السهم",
  he: "מועד מוסדר לתשלום הדיווידנד",
  en: "Record date of dividend"
 },
 {
  ar: "تاريخ الاستحقاق",
  he: "מועד פירעון",
  en: "Maturity date"
 },
 {
  ar: null,
  he: "תאריך פירעון",
  en: "Due date"
 },
 {
  ar: "تاريخ الاقفال",
  he: "תאריך אחרון",
  en: "Closing date"
 },
 {
  ar: "تاريخ الانقضاء",
  he: "תאריך פקיעה",
  en: "Expiry date"
 },
 {
  ar: "تاريخ السريان",
  he: "תאריך כניסה לתוקף",
  en: "Date of effect"
 },
 {
  ar: "تاريخ محدد",
  he: "תאריך קבוע",
  en: "Fixed date"
 },
 {
  ar: "تاسيس شركة",
  he: "ייסוד חברה",
  en: "Formation of a company"
 },
 {
  ar: "تاكل",
  he: "שחיקה",
  en: "Erosion"
 },
 {
  ar: "تاميم",
  he: "הלאמה",
  en: "Nationalization"
 },
 {
  ar: "تامين ",
  he: "ביטוח",
  en: "Assurance\/ insurance"
 },
 {
  ar: "تامين جماعي",
  he: "ביטוח קיבוצי",
  en: "Group insurance"
 },
 {
  ar: "تامين ضد البطالة",
  he: "ביטוח אבטלה",
  en: "Unemployment insurance"
 },
 {
  ar: "تامين ضد الغير",
  he: "ביטוח צד שלישי",
  en: "Third party insurance"
 },
 {
  ar: "تامين على الحياة",
  he: "ביטוח חיים",
  en: "Life assurance"
 },
 {
  ar: "تامين قومي",
  he: "ביטוח לאומי",
  en: "National insurance"
 },
 {
  ar: "تامين مزدوج",
  he: "ביטןח יתר",
  en: "Double insurance"
 },
 {
  ar: "تبادل",
  he: "חליפין",
  en: "Exchange"
 },
 {
  ar: "تبادل الاسهم",
  he: "מסחר במניות",
  en: "Exchange of shares"
 },
 {
  ar: "تباطؤ في النشاط الاقتصادي",
  he: "האטה בפעילות הכלכלית",
  en: "Slowdown of economic activity"
 },
 {
  ar: "تبديل",
  he: "המרה",
  en: "Conversion"
 },
 {
  ar: "تبرع",
  he: "תרומה",
  en: "Contribution\/ donation"
 },
 {
  ar: "تثبيت الاسعار",
  he: "ייצוב המחירים",
  en: "Stabilization of prices"
 },
 {
  ar: "تثبيت قيمة العملة",
  he: "ייצוב ערך המטבע",
  en: "Currency stabilization"
 },
 {
  ar: "تجارة",
  he: "מסחר\/ סחר",
  en: "Commerce\/ trade"
 },
 {
  ar: "تجارة اجنبية",
  he: "סחר חוץ",
  en: "Foreign trade\/ external trade"
 },
 {
  ar: "تجارة المخدرات",
  he: "סחר בסמים",
  en: "Drug traffic"
 },
 {
  ar: "تجارة بالتجزئة",
  he: "קמעונאות",
  en: "retail"
 },
 {
  ar: "تجارة بالجملة",
  he: "סיטונאות",
  en: "Wholesale"
 },
 {
  ar: "تجارة ثلاثية",
  he: "סחר משולש",
  en: "Triangular trade"
 },
 {
  ar: "تجارة حرة",
  he: "סחר חופשי",
  en: "Free trade"
 },
 {
  ar: "تجارة خارجية",
  he: "סחר חוץ",
  en: "Foreign trade\/ external trade"
 },
 {
  ar: "تجارة داخلية",
  he: "סחר פנימי",
  en: "Domestic trade"
 },
 {
  ar: "تجارة دولية",
  he: "סחר בינלאומי",
  en: "International "
 },
 {
  ar: "تجارة ساحلية",
  he: "סחר חופים",
  en: "Coasting trade"
 },
 {
  ar: "تجارة عادلة",
  he: "מסחר הוגן",
  en: "Fair trade"
 },
 {
  ar: "تجارة غير قانونية",
  he: "סחר לא חוקי",
  en: "traffic"
 },
 {
  ar: "تجارة غير منظورة",
  he: "סחר סמוי",
  en: "Invisible trade"
 },
 {
  ar: "تجارية",
  he: "מרקנטליזם",
  en: "Mercantilism"
 },
 {
  ar: "تجديد",
  he: "חידוש",
  en: "Innovation"
 },
 {
  ar: "  تجزئة السهم",
  he: "פיצול מניה",
  en: "Stock split"
 },
 {
  ar: "تجميد الاجور",
  he: "הקפאת שכר",
  en: "Wages freeze"
 },
 {
  ar: "تجميد الاسعار",
  he: "הקפאת מחירים",
  en: "Price freeze"
 },
 {
  ar: "تجنب الضرائب",
  he: "השתמטות מתשלום מס",
  en: "Tax avoidance"
 },
 {
  ar: "تجيير",
  he: "הסבה",
  en: "Endorsement\/ indorsement"
 },
 {
  ar: "تحديد السعر",
  he: "קביעת מחיר",
  en: "Price fixing"
 },
 {
  ar: "تحركات راس المال",
  he: "העברת הון",
  en: "Capital transfers"
 },
 {
  ar: "تحسين",
  he: "שיפור\/ השבחה",
  en: "improvement"
 },
 {
  ar: "تحصيل",
  he: "גבייה",
  en: "Collection"
 },
 {
  ar: "تحقيق ربح",
  he: "עשיית רווח",
  en: "Making profit"
 },
 {
  ar: "تحليل التكاليف والعائدات",
  he: "ניתוח עלות- תועלת",
  en: "Cost- benefit analysis"
 },
 {
  ar: "تحمل",
  he: "ספיגה",
  en: "Absorption"
 },
 {
  ar: "تحمل البائع مصاريف الشحن وتحمل المشتري مصاريف التفريغ",
  he: "טעינה על חשבון המוכר ופריקה על חשבון הקונה",
  en: "Free out"
 },
 {
  ar: "تحمل المشتري مصاريف الشحن والتفريغ",
  he: "טעינה ופריקה על חשבון הקונה",
  en: "Free in and out"
 },
 {
  ar: "تحمل المشتري مصاريف الشحن وتحمل البائع مصاريف التفريغ",
  he: "טעינה על חשבון הקונה ופריקה על חשבון המוכר",
  en: "Free in"
 },
 {
  ar: "تحوط",
  he: "עסקת מגן\/ הדג'ינג",
  en: "hedging"
 },
 {
  ar: "تحويل",
  he: "העברה\/ העברת כסף",
  en: "Conveyance\/ transfer"
 },
 {
  ar: null,
  he: "המרה",
  en: "Conversion"
 },
 {
  ar: "تحويل اجنبي",
  he: "מסחר במטבע חוץ",
  en: "Foreign exchange"
 },
 {
  ar: "تحويل اعتمادي",
  he: "העברת אשראי",
  en: "Credit transfer\/ giro"
 },
 {
  ar: "تحويل الديون",
  he: "העברת חובות",
  en: "Transfer of debts"
 },
 {
  ar: "تحويل خارجي",
  he: "מסחר במטבע חוץ",
  en: "Foreign exchange"
 },
 {
  ar: "تحويل مصرفي",
  he: "המרה בנקאית",
  en: "Bank exchange"
 },
 {
  ar: "تحويلات اعتمادية",
  he: "העברות בנקאיות",
  en: "Credit transfer"
 },
 {
  ar: "تخريب مفتعل",
  he: "חבלה",
  en: "Sabotage"
 },
 {
  ar: "تخصيص",
  he: "הפרטה",
  en: "Privatization"
 },
 {
  ar: null,
  he: "הקצבה",
  en: "Appropriation"
 },
 {
  ar: null,
  he: "הקצאה",
  en: "Allotment\/ allocation"
 },
 {
  ar: "تخطيط اقتصادي",
  he: "תכנון כלכלי",
  en: "Economic planning"
 },
 {
  ar: "تخطيط الدولة",
  he: "תכנון מרכזי",
  en: "Central planning\/ state planning"
 },
 {
  ar: "تخطيط حكومي",
  he: "תכנון מרכזי",
  en: "Central planning\/ state planning"
 },
 {
  ar: "تخفيض",
  he: "הנחה",
  en: "Rebate\/ discount\/ allowance\/ cutback"
 },
 {
  ar: null,
  he: "קיצןץ",
  en: null
 },
 {
  ar: "تخفيض الاجور",
  he: "קיצןץ בשכר",
  en: "Wages cut"
 },
 {
  ar: "تخفيض التكاليف",
  he: "צמצום עלויות",
  en: "Reduction of costs"
 },
 {
  ar: "تخفيض السعر",
  he: "הורדת מחיר\/ הוזלה",
  en: "Price cutting"
 },
 {
  ar: "تخفيض الضرائب",
  he: "הורדת מיסים\/ הקלה במס",
  en: "Tax cut\/ abatement of taxes"
 },
 {
  ar: "تخفيض المواصفات",
  he: "דילול",
  en: "Dilution"
 },
 {
  ar: "تخفيض زاحف",
  he: "פיחות זוחל",
  en: "Gradual devaluation\/creeping devaluation\/ crawling devaluation"
 },
 {
  ar: "تخفيض قيمة العملة",
  he: "פיחות",
  en: "Depreciation\/ devaluation"
 },
 {
  ar: "تدفق نقدي",
  he: "תזרים מזומנים",
  en: "Cash flow"
 },
 {
  ar: "تدقيق الحسابات",
  he: "ביקורת חשבונות\/ ראיית חשבון",
  en: "audit"
 },
 {
  ar: "تدقيق الحسابات الداخلي",
  he: "ביקורת פנים",
  en: "Internal auditing"
 },
 {
  ar: "تدقيق الحسابات المستقل",
  he: "ביקורת חשבונות בלתי תלויה",
  en: "Independent audit"
 },
 {
  ar: "تدقيق الميزانية",
  he: "ביקורת מאזן",
  en: "Balance sheet audit"
 },
 {
  ar: "تدقيق نقدي",
  he: "ביקורת כספים",
  en: "Cash audit"
 },
 {
  ar: "تذكرة",
  he: "כרטיס",
  en: "Ticket"
 },
 {
  ar: "تذكرة الدوام",
  he: "כרטיס נוכחות בעבודה",
  en: "Time ticket"
 },
 {
  ar: "تراكم",
  he: "צבירה",
  en: "Accumulation"
 },
 {
  ar: "ترشيد",
  he: "ייעול",
  en: "Making efficient"
 },
 {
  ar: "ترك",
  he: "נטישה",
  en: "abandonment"
 },
 {
  ar: "تركز",
  he: "מיזוג",
  en: "Integration\/ merger\/ consolidation"
 },
 {
  ar: "تركيز اساسي",
  he: "תשתית",
  en: "Infrastructure"
 },
 {
  ar: "ترميم",
  he: "שיקום",
  en: "Restoration"
 },
 {
  ar: "ترويج المبيعات",
  he: "קידום מכירות",
  en: "Sales promotion"
 },
 {
  ar: "تزوير",
  he: "זיוף",
  en: "Forgery\/ fraud"
 },
 {
  ar: "تسجيل مدين مباشر",
  he: "העברת כסף ישירות מחשבון לחשבון",
  en: "Direct debit"
 },
 {
  ar: "تسعير",
  he: "קביעת מחיר",
  en: "pricing"
 },
 {
  ar: "تسعير حسب المناطق",
  he: "קביעת מחיר על- פי אזור",
  en: "Zone pricing"
 },
 {
  ar: "تسليف",
  he: "אשראי",
  en: "Credit"
 },
 {
  ar: "تسليم",
  he: "מסירה",
  en: "Delivery"
 },
 {
  ar: "تسليم البضاعة داخل الشاحنة",
  he: "חופשי על המשאית",
  en: "Free on truck"
 },
 {
  ar: "تسليم خارج المركب",
  he: "מחיר על הרציף",
  en: "Ex- quay"
 },
 {
  ar: "تسليم خارج المصنع",
  he: "מחיר בשער המפעל",
  en: "Ex- factory"
 },
 {
  ar: "تسليم رصيف الميناء",
  he: "מחיר על הרציף ",
  en: "Ex- quay"
 },
 {
  ar: "تسليم رصيف محطة القطار",
  he: "חופשי על הרכבת",
  en: "Free on rail"
 },
 {
  ar: "تسليم سكة الحديد ",
  he: "חופשי על הרכבת",
  en: "Free on rail"
 },
 {
  ar: "تسليم ظهر المركب",
  he: "מחיר בנמל",
  en: "Ex- ship"
 },
 {
  ar: "تسليم على ظهر السفينة",
  he: "חופשי על סיפון האונייה",
  en: "Free on board"
 },
 {
  ar: "تسليم فعلي",
  he: "מסירה בפועל",
  en: "Actual delivery"
 },
 {
  ar: "تسوية الحسابات",
  he: "סילוק חשבונות",
  en: "Credit clearing"
 },
 {
  ar: "تسويق",
  he: "שיווק",
  en: "marketing"
 },
 {
  ar: "تشريك",
  he: "קולקטיוויזציה",
  en: "collectivization"
 },
 {
  ar: "تصدير",
  he: "יצוא",
  en: "export"
 },
 {
  ar: "تصدير راس مال",
  he: "יצוא הון",
  en: "Capital export"
 },
 {
  ar: "تصدير منظور",
  he: "יצוא גלוי",
  en: "Visible export"
 },
 {
  ar: "تصديق مصرفي",
  he: "אישור בנקאי",
  en: "Bank confirmation"
 },
 {
  ar: "تصريح",
  he: "הצהרה",
  en: "Declaration"
 },
 {
  ar: null,
  he: "רשיון",
  en: "license"
 },
 {
  ar: "تصريح استيراد",
  he: "רשיון יבוא",
  en: "Import license"
 },
 {
  ar: "تصريح تصدير",
  he: "רשיון יצוא",
  en: "Export license"
 },
 {
  ar: "تصريف خاص",
  he: "הנפקה פרטית",
  en: "Private placement\/ private placing"
 },
 {
  ar: "تصفية",
  he: "סילוק (חשבון)",
  en: "Clearance\/ defrayment\/ liquidation"
 },
 {
  ar: null,
  he: "פירוק",
  en: null
 },
 {
  ar: "تصنيع",
  he: "תיעוש",
  en: "Industrialization\/ manufacturing\/ production"
 },
 {
  ar: null,
  he: "ייצור",
  en: null
 },
 {
  ar: "تضخم جامح",
  he: "אינפלציה דוהרת",
  en: "Runaway inflation\/ galloping inflation"
 },
 {
  ar: "تضخم جامع",
  he: "היפר- אינפלציה",
  en: "Hyperinflation"
 },
 {
  ar: "تضخم حلزوني",
  he: "סחרור אינפלציוני",
  en: "Inflation spiral"
 },
 {
  ar: "تضخم لولبي",
  he: "סחרור אינפלציוני",
  en: "Inflation spiral"
 },
 {
  ar: "تضخم مالي",
  he: "אינפלציה",
  en: "Inflation"
 },
 {
  ar: "تضخم مفرط",
  he: "היפר- אינפלציה",
  en: "Hyperinflation"
 },
 {
  ar: "تضخم مكبوت",
  he: "אינפלציה כבושה",
  en: "Repressed inflation"
 },
 {
  ar: "تضخم نقدي",
  he: "אינפלציה",
  en: "Inflation"
 },
 {
  ar: "تضييق الاعتمادات",
  he: "מצוקת אשראי",
  en: "Credit squeeze"
 },
 {
  ar: "تطوير",
  he: "פיתוח",
  en: "Development"
 },
 {
  ar: "تظهير",
  he: "הסבה",
  en: "Endorsement\/ Indorsement"
 },
 {
  ar: "تعاونية",
  he: "קואופרטיבי",
  en: "Cooperative"
 },
 {
  ar: "تعايش الكساد والتضخم",
  he: "סטגפלציה",
  en: "Stagflation"
 },
 {
  ar: "تعجيل في النشاط الاقتصادي",
  he: "האצה בפעילות הכלכלית",
  en: "Acceleration of economic activity"
 },
 {
  ar: "تعرفة",
  he: "תעריף",
  en: "Tariff\/ rate"
 },
 {
  ar: "تعرفة اتفاقية",
  he: "מכס חוזי",
  en: "Conventional tariff"
 },
 {
  ar: "تعرفة الترانزبت",
  he: "מכס מעבר",
  en: "Transit duty"
 },
 {
  ar: "تعرفة ايرادية",
  he: "מכס פיסקאלי",
  en: "Revenue tariff"
 },
 {
  ar: "تعرفة جمركية",
  he: "מכס",
  en: "Import tariff\/ customs\/ import duty"
 },
 {
  ar: "تعرفة عامة",
  he: "מכס כללי",
  en: "General tariff"
 },
 {
  ar: "تعرفة مانعة",
  he: "מכס מונע",
  en: "Prohibitive tariff"
 },
 {
  ar: "تعرفة مرور",
  he: "מכס מעבר",
  en: "Transit tariff"
 },
 {
  ar: "تعرفة مضادة",
  he: "מכס מאזן",
  en: "Countervailing tariff"
 },
 {
  ar: "تعويض",
  he: "פיצוי",
  en: "Compensation\/ reparation"
 },
 {
  ar: "تعويض الصرف",
  he: "פיצוי פיטורים",
  en: "Severance pay"
 },
 {
  ar: "تعويض انهاء الخدمة",
  he: "פיצוי פיטורים",
  en: "Severance pay"
 },
 {
  ar: "تغطية",
  he: "עסקת מגן\/ הדג'ינג",
  en: "hedging"
 },
 {
  ar: "تفاوت الدخول",
  he: "אי- שוויוניות בהכנסות ",
  en: "Inequality of incomes"
 },
 {
  ar: "تقاعد",
  he: "פרישה",
  en: "Retirement"
 },
 {
  ar: "تقاعد اجباري",
  he: "פרישה המחויבת על פי חוק או הסדר",
  en: "Compulsory retirement"
 },
 {
  ar: "تقدم",
  he: "התקדמות",
  en: "Progression"
 },
 {
  ar: "تقدير",
  he: "אומדן\/ הערכה ",
  en: "Estimate\/ valuation"
 },
 {
  ar: "تقدير بخس",
  he: "הערכת חסר",
  en: "Underestimate"
 },
 {
  ar: "تقدير حقيقي",
  he: "מונחים ריאלים",
  en: "Real terms"
 },
 {
  ar: "تقدير مغالى فية",
  he: "הערכת יתר",
  en: "Overestimate"
 },
 {
  ar: "تقرير",
  he: "דו\"ח",
  en: "Report"
 },
 {
  ar: "تقرير دوري",
  he: "דו\"ח תקופתי",
  en: "Periodic report"
 },
 {
  ar: "تقرير سنوي",
  he: "דו\"ח שנתי",
  en: "Annual repot"
 },
 {
  ar: "تقسيط",
  he: "תשלומים",
  en: "installments"
 },
 {
  ar: "تقسيم العمل",
  he: "חלוקת עבודה",
  en: "Division of labour"
 },
 {
  ar: "تقشف",
  he: "צנע",
  en: "Austerity"
 },
 {
  ar: "تقنين ",
  he: "קיצוב",
  en: "Rationing"
 },
 {
  ar: "تقنين الاعتماد",
  he: "קיצוב אשראי",
  en: "Credit rationing"
 },
 {
  ar: "تقييد",
  he: "הגבלה",
  en: "Restriction"
 },
 {
  ar: "تقييم",
  he: "שומה\/ הערכה",
  en: "Valuation\/ estimate"
 },
 {
  ar: "تكافؤ الفرص",
  he: "שוויון הזדמנויות",
  en: "Equalization of opportunities"
 },
 {
  ar: "تكاليف الانتاج",
  he: "עלויות ייצור",
  en: "Manufacturing costs\/ production costs"
 },
 {
  ar: "تكاليف التصنيع",
  he: "עלויות ייצור",
  en: "Manufacturing costs\/ production costs"
 },
 {
  ar: "تكاليف المعيشة",
  he: "יוקר מחיה",
  en: "Cost of living"
 },
 {
  ar: "تكاليف مختلطة",
  he: "עלויות מעורבות",
  en: "Mixed costs"
 },
 {
  ar: "تكاليف مشتركة",
  he: "עלויות משולבות",
  en: "Joint costs"
 },
 {
  ar: "تكامل افقي",
  he: "שילוב מאוזן",
  en: "Horizontal integration"
 },
 {
  ar: "تكامل عمودي",
  he: "שילוב מאונך",
  en: "Vertical integration"
 },
 {
  ar: "تكلفة",
  he: "עלות",
  en: "Cost"
 },
 {
  ar: "تكلفة اساسية",
  he: "עלות בסיסית",
  en: "Prime cost"
 },
 {
  ar: "تكلفة اصلية",
  he: "עלות מקורית",
  en: "Original cost "
 },
 {
  ar: "تكلفة الابدال",
  he: "עלות חילוף",
  en: "Replacement cost"
 },
 {
  ar: "تكلفة الاختيار",
  he: "עלות אלטרנטיבית",
  en: "Opportunity cost"
 },
 {
  ar: "تكلفة الحصول",
  he: "עלות קנייה",
  en: "Acquisition cost"
 },
 {
  ar: "تكلفة السلع المبيعة",
  he: "עלות מכירות",
  en: "Cost of goods sold"
 },
 {
  ar: "تكلفة تارخية",
  he: "עלות היסטורית",
  en: "Historical cost"
 },
 {
  ar: "تكلفة ثابتة",
  he: "עלות קבועה",
  en: "Fixed cost"
 },
 {
  ar: "تكلفة حدية",
  he: "עלות שולית",
  en: "Marginal cost"
 },
 {
  ar: "تكلفة حقيقية",
  he: "עלות בפועל",
  en: "Actual cost"
 },
 {
  ar: "تكلفة راسمالية",
  he: "עלות הון",
  en: "Cost of capital"
 },
 {
  ar: "تكلفة غير مباشرة ",
  he: "עלות עקיפה",
  en: "Indirect cost\/ on cost"
 },
 {
  ar: "تكلفة غير متكررة",
  he: "עלות שקועה",
  en: "Sunk cost"
 },
 {
  ar: "تكلفة لا يمكن تجنبها",
  he: "עלות בלתי נמנעת",
  en: "Unavoidable cost"
 },
 {
  ar: "تكلفة مباشرة",
  he: "עלות ישירה",
  en: "Direct cost"
 },
 {
  ar: "تكلفة متزايدة",
  he: "עלות תוספתית",
  en: "Incremental cost"
 },
 {
  ar: "تكلفة متغيرة",
  he: "עלות משתנה",
  en: "Variable cost"
 },
 {
  ar: "تكلفة منسوبة",
  he: "עלות ניתנת לייחוס",
  en: "Attributable cost"
 },
 {
  ar: "تكلفة يمكن تجنبها",
  he: "עלות נמנעת",
  en: "Avoidable cost"
 },
 {
  ar: "تكلفة يمكن مراقبتها",
  he: "עלות נשלטת",
  en: "Controllable cost"
 },
 {
  ar: "تكليف",
  he: "היטל",
  en: "Charge\/ duty"
 },
 {
  ar: "تكوين راس المال",
  he: "יצירת הון",
  en: "Capital formation"
 },
 {
  ar: "تكوين شركة",
  he: "יצירת חברה",
  en: "Formation of a company"
 },
 {
  ar: "تمويل",
  he: "מימון",
  en: "Financing"
 },
 {
  ar: "تمويل ذاتي",
  he: "מימון עצמי",
  en: "Self- financing"
 },
 {
  ar: "تمييز في استيفاء الاسعار",
  he: "הפליית מחירים",
  en: "Price discrimination"
 },
 {
  ar: "تنازل",
  he: "ויתור",
  en: "disclaiming"
 },
 {
  ar: "تناقص",
  he: "הפחתה",
  en: "decrease"
 },
 {
  ar: "تنبؤ اقتصادي",
  he: "תחזית כללית",
  en: "Economic forecast"
 },
 {
  ar: "تنزيل",
  he: "הנחה",
  en: "Rebate\/ discount\/ allowance"
 },
 {
  ar: "تنمية",
  he: "פיתוח",
  en: "Development"
 },
 {
  ar: "تنمية اقتصادية",
  he: "פיתוח כלכלי",
  en: "Economic development"
 },
 {
  ar: "تنويع الاستثمار",
  he: "גיוו ההשקעה",
  en: "Diversification of investment"
 },
 {
  ar: "تهريب",
  he: "הברחה",
  en: "Smuggling"
 },
 {
  ar: "توحيد",
  he: "איחוד\/ מיזוג",
  en: "Consolidation\/ integration\/ merger"
 },
 {
  ar: "توزيع",
  he: "הקצאה",
  en: "Allotment\/ allocation"
 },
 {
  ar: "توزيع الاسهم",
  he: "הקצאת מניות",
  en: "Allotment of shares"
 },
 {
  ar: "توزيع الدخل",
  he: "התפלגות הכנסות",
  en: "Income distribution"
 },
 {
  ar: "توسع المؤسسة",
  he: "הרחבת פעילות של חברה",
  en: "Expansion of a firm"
 },
 {
  ar: "توظيف",
  he: "השקעה",
  en: "Investment"
 },
 {
  ar: "توفير",
  he: "חיסכון",
  en: "Savings"
 },
 {
  ar: "توقيع",
  he: "חתימה",
  en: "signature"
 }
]
export default tah ;