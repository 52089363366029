/**
 * TermsCondition Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import {FiSettings} from 'react-icons/fi';
import {FaSignature} from 'react-icons/fa';
import{MassBill, MassFinance} from '../../Entryfile/imagepath';
import {IoDocumentTextOutline} from 'react-icons/io5';
import {VscLaw} from 'react-icons/vsc';
import Attendance from '../../pr-system/attendance';
// import Attendance from '../attendance';
export default class MassFin extends Component {
  render() {
    return (
           
			<div className="page-wrapper">
				 <Helmet>
              <title>MassPortal - Finance  </title>
              <meta name="description" content="Reactify Blank Page" />
            </Helmet>
			{/* Page Content */}
			<div className="content container-fluid">
			  {/* Page Header */}
			  <div className="page-header">
				<div className="row">
				  <div className="col-sm-8 col-4">
					<h3 className="page-title"> Mass - <span className='privacy'>  Finance </span>    </h3>
					<ul className="breadcrumb">
					  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
					</ul>
				  </div>
				</div>
			  </div>
			   {/* Account Logo */}
			   <div className="account-logo">
              {/* <a href="/"><img src={MassFinance} alt="Mass Finance " /></a> */}
			  <img src={MassFinance} alt='MassPortal Finance' />
            </div>
            {/* /Account Logo */}
			  {/* /Page Header */}
			
			  
			  <div className="row">
			  
				<div className="col-lg-10 mx-auto">
				  {/* Plan Tab */}
				  <div className="row justify-content-center mb-4">
					<div className="col-auto">
					  <nav className="nav btn-group settings-nav">
						<a href="#monthly" className="btn btn-outline-secondary active show" data-toggle="tab"> إعدادات </a>
						<a href="#annual" className="btn btn-outline-secondary" data-toggle="tab"> تقارير </a>
					  </nav>
					</div>
				  </div>
				  {/* /Plan Tab */}
				  {/* Plan Tab Content */}
				  <div className="tab-content">
					{/* Monthly Tab */}
					<div className="tab-pane fade active show" id="monthly">
					  <div className="row mb-30 equal-height-cards">
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								  <br></br>
								<h2 className="settings-title">إعدادات </h2>
								<span className="display-6"><FiSettings className='privacy'/></span>
							  </div>
							  <ul className='text-left settings-mass'>
								<li><a href='#' ><FiSettings size={20} className='privacy'/>  الشركة </a></li>
								<li><a href='#' ><FiSettings size={20} className='privacy'/>  الموظفين </a></li>
								<li><a href='#' ><FiSettings size={20} className='privacy'/>  قوائم الضرائب </a></li>
								<li><a href='#' ><FiSettings size={20} className='privacy'/>  Export And Integrations </a></li>

							
							
						
								{/* <li><i className="fa fa-check" /> 5 Projects </li>
								<li><i className="fa fa-check" /> 5 GB Storage</li>
								<li><i className="fa fa-check" /> Unlimited Message</li> */}
							  </ul>
							  
							  <a href="/shaam-tax" className="btn btn-lg btn-primary mt-auto">ضريبة الدخل </a>
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">

								{/* <h3>تقارير </h3> */}
								<br></br>
								<h2 className="settings-title">إعدادات الرواتب والعقود </h2>
								<span className="display-6"><IoDocumentTextOutline className="privacy" /></span>
								{/* <span>/mo</span> */}
							  </div>
							  <ul className="text-left settings-mass">
								<li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> إعداد رواتب   </a></li>
								<li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> إعداد عقود   </a></li>
								<li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> أخرى ...    </a></li>
								<li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> أخرى ...    </a></li>
								<li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> أخرى ...    </a></li>

							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								<br></br>
								<h2 className="settings-title">قوانين و مراجع  </h2>
								<span className="display-6"><VscLaw className='privacy' /></span>
								{/* <span>/mo</span> */}
							  </div>
							  <ul className="text-left settings-mass">
							  <li><a href='#'><VscLaw size={25} className='privacy' /> قوانين الاجازة السنوية    </a></li>
							  <li><a href='#'><VscLaw size={25} className='privacy' /> قوانين النقاهة   </a></li>
							  <li><a href='#'><VscLaw size={25} className='privacy' /> قوانين التقاعد   </a></li>
							  <li><a href='#'><VscLaw size={25} className='privacy' /> أخرى ...    </a></li>
							  <li><a href='#'><VscLaw size={25} className='privacy' /> أخرى ...    </a></li>
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
					  </div>
					</div>
					{/* /Monthly Tab */}
					{/* Yearly Tab */}
					<div className="tab-pane fade" id="annual">
					  <div className="row mb-30 equal-height-cards">
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
							  <br></br>
								<h2 className="settings-title">تقارير للموظف   </h2>
								<span className="display-6"><IoDocumentTextOutline className='privacy' /></span>
							  </div>
							  <ul className='text-left settings-mass'>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> قسيمة راتب     </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> قسيمة كشف 106 </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> قسيمة كشف 161 </a></li>
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
								  <br></br>
								  <h2 className='settings-title'> تقارير للشركة </h2>
								  <span className="display-6"><IoDocumentTextOutline className='privacy' /></span>

								{/* <h3>Professional</h3>
								<span className="display-4">$199</span>
								<span>/mo</span> */}
							  </div>
							  <ul className='text-left settings-mass'>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تكلفة الرواتب الشهرية     </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تكلفة الرواتب السنوية      </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> مخصص تعويضات نهاية الخدمة      </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> مخصص الاجازات      </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تقارير أخرى ...      </a></li>

								
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
						<div className="col-md-4">
						  <div className="card pricing-box">
							<div className="card-body d-flex flex-column">
							  <div className="mb-4">
							  <br></br>
								  <h2 className='settings-title'> تقارير لسلطات الضرائب  </h2>
								  <span className="display-6"><IoDocumentTextOutline className='privacy' /></span>
							  </div>
							  <ul className='settings-mass text-left '>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> كشف 102   </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> كشف 612   </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> كشف 126   </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تصدير كشوفات Online   </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تصريح الضرائب </a></li>
							  <li><a href='#'><IoDocumentTextOutline size={25} className='privacy' /> تصريح التأمين الوطني    </a></li>

								
							  </ul>
							  {/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
							</div>
						  </div>
						</div>
					  </div>
					</div>
					{/* Yearly Tab */}
				  </div>
				  {/* /Plan Tab Content */}
				  {/* Plan Details */}
				  <div className='row'>
				  <div className="col-lg-12 mx-auto"> 
			  <br></br>
			  <div className="table-responsive">
              <table className="table table-striped custom-table table-nowrap mb-0">
                <thead>
                  <tr>                   
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>                  
                  </tr>
                </thead>
                <tbody>
                  <tr>                   
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>                  
                  </tr>                 
                </tbody>
              </table>
            </div>
			  <br></br>
			  </div>			  
				  </div>
				  <div className="row">
					  
					<div className="col-md-12">
					  <div className="card card-table mb-0">
						<div className="card-header">
						  <h4 className="card-title mb-0 privacy">معطيات   </h4>
						</div>
						<div className="card-body">
						  <div className="table-responsive">
							<table className="table table-center mb-0">
							  <thead>
								<tr>
								  <th>رقم ملف الاقتطاعات</th>
								  <th>رقم دفتر الاقتطاعات </th>
								  <th>مجموع الرواتب من بداية السنة </th>
								  <th>مجموع الضرائب من بداية السنة </th>
								  <th>رواتب مستحقة غير مدفوعة </th>
								  <th>أخرى ... </th>
								
								</tr>
							  </thead>
							  <tbody>
								<tr className='text-center'>
								  <td>945111222</td>
								  <td>54211</td>
								  <td>81250</td>
								  <td>6212</td>
								  <td>21201</td>
								  <td>-</td>
								  {/* <td><a className="btn btn-primary btn-sm" href="">Change Plan</a></td> */}
								</tr>
							  </tbody>
							</table>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				  {/* /Plan Details */}
				</div>
			  </div>
			</div>
			{/* /Page Content */}
		  </div>
       
    );
  }
}
