import React from 'react';

const localeOptions = {
	style: 'currency',
	currency: 'ILS',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0
}

export const HeaderFigures = props => {
	return (
		<div className="grid__container" dir='rtl'>

			<div className="card dash-widget mortage-card">
				<div className="card-body">
					<div className="grid__item dash-widget-info">
						<div className='row'>
							<div className='col '>
								<h3 className='mortage-h3' > مبلغ القرض</h3>
							</div>
							<div className='col  mortage-div'>
								<h3  >
									{parseInt(props.amountToBorrow).toLocaleString('ILS', localeOptions)}
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card dash-widget mortage-card">
				<div className="card-body">
					<div className=" grid__item dash-widget-info">
						<div className='row'>
							<div className='col'>
								<h3 className='mortage-h3' > الدفع الشهري</h3>
							</div>
							<div className='col mortage-div'>
								<h3  >
									{parseInt(props.monthlyPayment).toLocaleString('ILS', localeOptions)}
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card dash-widget mortage-card">
				<div className="card-body">
					<div className=" grid__item dash-widget-info">
						<div className='row'>
							<div className='col'>
								<h3 className='mortage-h3' > إجمالي السداد</h3>
							</div>
							<div className='col mortage-div'>
								<h3  >
									{parseInt(props.totalRepaid).toLocaleString('ILS', localeOptions)}
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card dash-widget mortage-card">
				<div className="card-body">
					<div className="grid__item dash-widget-info">
						<div className='row'>
							<div className='col '>
								<h3 className='mortage-h3' >  الفوائد المدفوعة</h3>
							</div>
							<div className='col mortage-div'>
								<h3  >
									{parseInt(props.totalInterestPaid).toLocaleString('ILS', localeOptions)}
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="card dash-widget mortage-card">
				<div className="card-body">
					<div className="grid__item dash-widget-info">
						<div className='row'>
							<div className='col'>
								<h3 className='mortage-h3' >  ودائع</h3>
							</div>
							<div className='col mortage-div'>
								<h3  >
									{(props.depositAmount / props.purchasingHousePrice * 100).toFixed(1)}%
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card dash-widget mortage-card">
				<div className="card-body">
					<div className="grid__item dash-widget-info">
						<div className='row'>
							<div className='col'>
								<h3 className='mortage-h3' >  قرض بقيمة</h3>
							</div>
							<div className='col mortage-div'>
								<h3  >
									{(props.amountToBorrow / props.purchasingHousePrice * 100).toFixed(1)}%
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	)
}