import React, { useState, useEffect } from "react";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
import ReactTooltip from "react-tooltip";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { massportallogo } from "../../../Entryfile/imagepath";

import { useAuthContext } from "../../../hooks/useAuthContext";
import { projectFirestore } from "../../../firebase/config";

// import taxData from './data/output';
import taxData from './data/output'
export function NatCalc(props) {

  const printRef = React.useRef();
  const [isActive, setIsActive] = useState(false);
  const [datee, setDatee] = useState(" ");

  const [yearV, setYear] = useState(props.year);
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [tax, setTax] = useState(0)
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const year = 2023 - parseFloat(yearV)
  // HOOKS
  const { user } = useAuthContext()
  const [companyName, setCompanyName] = useState(' ')

  let short1 = taxData.taxData[year].bituachLeumi.nationalInsurance.band1.rate.selfEmployed;
  let short2 = taxData.taxData[year].bituachLeumi.nationalInsurance.band2.rate.selfEmployed;
  let short3 = taxData.taxData[year].bituachLeumi.nationalInsurance.band1.max;
  let short4 = taxData.taxData[year].bituachLeumi.nationalInsurance.band2.max;
  let massMizincome = taxData.taxData[year].bituachLeumi.nationalInsurance.band1.massMizincome;

  // let short1Emp = taxData.taxData[year].bituachLeumi.nationalInsurance.band1.rate.employee;
  // let short2Emp = taxData.taxData[year].bituachLeumi.nationalInsurance.band2.rate.employee;
  let short5 = taxData.taxData[year].bituachLeumi.healthInsurance.band1.rate.selfEmployed;
  let short6 = taxData.taxData[year].bituachLeumi.healthInsurance.band2.rate.selfEmployed;


  let num = [
    short3,
    short4
  ]


  let percentage = [
    (short1) / 100,
    (short2) / 100,
  ];
  let num1 = [
    short5,
    short6
  ]
  let percentage1 = [
    (short5) / 100,
    (short6) / 100,
  ];

  let np = [
    (num[0]) * percentage[0],
    (num[1] - num[0]) * percentage[1]
  ];
  let np1 = [
    (num[0]) * percentage1[0],
    (num[1] - num[0]) * percentage1[1]
  ];

  const handleDelete = (id) => {
    let newData = data.filter((data) =>
      data.id !== id)
    setData(newData)
    newData = [];
  }

  const newCalc = () => {
    window.location.reload();
  }
  const handleSubmit = (evt) => {
    evt.preventDefault();
    let id = Math.random();
    let income = monthlyIncome;

    if (monthlyIncome !== "") {
      if (income <= massMizincome) {
        income = massMizincome
      }
      setTax(calculateTax(parseFloat(income)));
      setShow(true)
      const timer = setTimeout(() => window.scroll(0, 2000), 100);
    }

    function calculateTax(income) {
      let slide1NationalE = 0
      let slide2NationalE = 0
      let slide1Health = 0
      let slide2Health = 0
      let tax = 0;
      let taxH = 0;
      let nis = 0;
      let slide1 = 0;
      let slide2 = 0;
      let summ = 0;
      let minusVal = 0;
      // National Insurance 


      if (income > 0 && income <= num[0]) {
        tax = (income) * percentage[0];
        taxH = (income) * percentage1[0]
        slide1 = tax
        slide1Health = taxH
        nis = monthlyIncome;
        summ = (tax + taxH)
      }
      else if (income > num[0] && income <= num[1]) {
        tax = ((income - num[0]) * percentage[1]) + (np[0]);
        const tax2 = ((income - num[0]) * percentage[1])
        const tax1 = np[0]
        slide1 = tax1.toFixed(2)
        slide2 = tax2.toFixed(2)
        nis = monthlyIncome;
        slide1NationalE = num[0]
        slide2NationalE = (num[1])

        taxH = ((income - num[0]) * percentage1[1]) + (np1[0]);
        slide1Health = np1[0]
        slide2Health = ((income - num[0]) * percentage1[1])
        summ = (tax + taxH)
        // minusVal = (income - num[0])
      }
      else if (income > num[1]) {
        tax = (((num[1] - num[0]) * percentage[1]) + (np[0]));
        nis = num[1]
        const tax2 = ((num[1] - num[0]) * percentage[1])
        const tax1 = np[0]
        slide1 = tax1
        slide2 = tax2
        slide1NationalE = (num[1])
        taxH = (((num[1] - num[0]) * percentage1[1]) + (np1[0]));
        slide2Health = ((num[1] - num[0]) * percentage1[1])
        slide1Health = np1[0]
        summ = (tax + taxH)
        minusVal = (income - num[1])
      }

      setData(prev => [...prev,
      {
        id: id,
        tax: tax.toFixed(1),
        taxH: taxH.toFixed(1),
        summ: summ.toFixed(1),
        sumyear: (summ * 12).toFixed(1),
        nis,
        total: (monthlyIncome - tax).toFixed(1),
        slide1,
        slide2,
        slide1Health: slide1Health.toFixed(1),
        slide2Health: slide2Health.toFixed(1),
        slide1NationalE,
        slide2NationalE,
        minusVal,


      }])

      if (tax < 0) {
        tax = 0;
      }
    }
  }
  // Thousands Commas Function 
  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  }

  //  getCompanyName();
  // const {docc , errr} = useDocument('mp-clients', docId)
  useEffect(() => {
    const getCompanyName = () => {
      if (user != null) {

        const mydata = projectFirestore.collection('mp-clients').doc(user.uid).get().then((docRef) => { setCompanyName(docRef.data().companyName) })
          .catch((error) => { })
      }
      else
        setCompanyName(" ")
    }

    getCompanyName();
  })


  //  PDF Prin Function 
  const handleDownloadPdf = async () => {

    const element = printRef.current;
    element.style.background = '#FFFFFF';
    element.style.color = '#212529';

    const iaactive = true;
    await setIsActive(iaactive);
    //  Current Date 
    const d = new Date().toLocaleString() + "";
    let current = d.split(',');
    const today = current[0];
    const todaySplitArr = today.split('/');
    const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
    // Current Date 

    // PDF Header And Footer Values 
    const header = todayFrm;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    //  Header  - Logo
    pdf.addImage(massportallogo, 'png', 90, 5, 20, 20);

    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 25, pdfWidth, pdfHeight);

    // Header - Date 
    pdf.text(header, 2, 29, { baseline: 'top' });

    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const footer = d + '             www.mass-portal.com';
    // Footer
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight - 2, { baseline: 'bottom' });
    pdf.setFontSize(20);
    pdf.save('التأمين الوطني .pdf');
  };

  return (
    <div className="card">

      <div className=" card-body eOfService">

        <form onSubmit={handleSubmit} className="form container naqaha-form">

          <div className="">
            <br></br>
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <h2 className="card-title text-center"> احتساب  التأمين الوطني للمستقل  - <span className="privacy"> {props.year} </span>  </h2>

              </div>


            </div>

            <div className="form-group row insurance-row">
              <div className="col-sm-12 col-md-4">
                <label className=" col-form-label">أدخل المبلغ الملزم بالتأمين الوطني - <span className="privacy"> شهري </span></label>
              </div>
              <div className="col-sm-12 col-md-5">
                <input type="text" className="form-control" name="monthlyincome" value={monthlyIncome}
                  onChange={(e) => setMonthlyIncome(e.target.value)} />
              </div>
            </div>
            <div>
              <div className="form-group row">
                <div className="col-sm-12 col-md-3"></div>
                <div className="col-sm-12 col-md-6  ">
                  <button type="submit" className="btn btn-primary naqaha-btn insurance-btn" > احتساب </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="text-right">
            <button type="submit" className="btn btn-primary">حساب</button>
          </div> */}
        </form>
      </div>
      {/* ================ SHOW HERE ================= */}

      {show && <div className=" container">
        <div className="content container-fluid">
          <div className="page-header ">

            <div className="">
              <div className='row'>
                <div className="col-sm-12 col-md-10">


                </div>
                {/* <div className="col-sm-12 col-md-1"></div> */}
                <div className="col-sm-12 col-md-2">
                  <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                    <AiOutlineFilePdf size={40} className='privacy' />
                  </button>
                  <button className="btn " data-tip data-for="excel-tip">
                    <SiMicrosoftexcel size={40} className='privacy' />
                  </button>
                  <ReactTooltip id="pdf-tip" place="top" effect="solid">
          طباعة PDF
        </ReactTooltip>
        <ReactTooltip id="excel-tip" place="top" effect="solid">
          طباعة Excel
        </ReactTooltip>
                </div>

              </div>
              <div className={isActive ? " card-white" : "card"} ref={printRef}>
                <br></br>
                <div className="row">
                  <br></br>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? " privacy-black-bold text-left company-marg " : " privacy hide"} id='date-block'>
                      الشركة : <span className="privacy-black"> {user ? companyName : " "}  </span>
                    </h4>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? "card-title mb-0 text-center privacy-black-bold " : "card-title mb-0 text-center  privacy"}>
                      نتائج احتساب التأمين الوطني للمستقل
                      <br></br>
                      <span className={isActive ? "privacy-black-bold" : "privacy-bold"}> {props.year} </span>
                    </h4>
                  </div>
                  <div className='col-sm-12 col-md-4'>
                    <h4 className={isActive ? "privacy-black-bold text-center " : " privacy hide"} id='date-block'>
                      التاريخ
                    </h4>


                  </div>
                </div>



                <div className={isActive?"card-body card-body-white" : "card-body "}>
                  {/* ================================================ */}
                  {data.map((d, index) => (
                    <div className="row result-div" key={index}>
                      <br></br>


                      <div className="col-sm-12 col-md-4 ">
                        <label className="label-title">الربح الملزم للتأمين الوطني</label>

                      </div>
                      <div className="col-sm-12 col-md-3">

                        <label className="privacy reuslt-insurance">{commify(d.nis)}</label>
                      </div>
                      <div className="col-sm-12 col-md-2 ">
                        <label className="label-title">المبلغ المعفي</label>

                      </div>
                      <div className="col-sm-12 col-md-3">

                        <label className="privacy reuslt-insurance">{commify(d.minusVal)}</label>
                      </div>
                      <div>

                      </div>

                      <div className=" row col-sm-12 col-md-6 border-div">
                        <br></br>
                        <h4 className=" mb-0  privacy text-center">التأمين الوطني  </h4>
                        <br></br>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>الشريحة الأولى  </label>
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                          <br></br>
                          <label className="reuslt-insurance">{commify(d.slide1)}</label>
                        </div>
                        {d.slide2NationalE != 0 && (
                          <>
                            <div className="col-sm-12 col-md-6">
                              <br></br>
                              <label>الشريحة الثانية   </label>
                            </div>
                            <div className="col-sm-12 col-md-6 ">
                              <br></br>
                              <label className="reuslt-insurance">{commify(d.slide2)}</label>
                            </div>
                          </>
                        )}
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>التأمين الوطني   </label>
                        </div>
                        <div className="col-sm-12 col-md-6 ">
                          <br></br>
                          <label className="reuslt-insurance privacy ">{commify(d.tax)}</label>
                        </div>
                      </div>
                      <div className="row col-sm-12 col-md-6 border-div">
                        <br></br>
                        <h4 className=" mb-0  privacy text-center">التأمين الصحي   </h4>
                        <br></br>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>الشريحة الأولى  </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance">{commify(d.slide1Health)}</label>
                        </div>
                        {d.slide2NationalE != 0 && (
                          <>
                            <div className="col-sm-12 col-md-6">
                              <br></br>
                              <label>الشريحة الثانية   </label>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <br></br>
                              <label className="reuslt-insurance">{commify(d.slide2Health)}</label>
                            </div>
                          </>
                        )}
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label>التأمين الصحي   </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance privacy">{commify(d.taxH)}</label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2"></div>
                      <div className="col-sm-12 col-md-4  ">
                        <br></br>
                        <label>مجموع التأمين الوطني و الصحي - شهري     </label>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label className="reuslt-insurance privacy">{commify(d.summ)}</label>
                      </div>
                      <div className="col-sm-12 col-md-2"></div>
                      <div className="col-sm-12 col-md-4  ">
                        <br></br>
                        <label>مجموع التأمين الوطني و الصحي - سنوي     </label>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <br></br>
                        <label className="reuslt-insurance privacy">{commify(d.sumyear)}</label>
                      </div>



                    </div>))} <br></br>


                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-12 col-md-6  btn-divv">
                  <br></br>
                  <button className="btn btn-primary naqaha-btn insurance-btn" onClick={newCalc} > احتساب جديد  </button>
                  <br></br> <br></br>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>}
      {/* ============================================================================== */}


    </div>
  );

}