let alef =[
    {
     ar: "ائتمان",
     he: "אשראי",
     en: "Credit"
    },
    {
     ar: "ابتزاز",
     he: "סחיטה",
     en: "Blackmail"
    },
    {
     ar: "اتاوة",
     he: "תמלוגים",
     en: "Royalties"
    },
    {
     ar: "اتجار",
     he: "מסחר\/ סחר",
     en: "Commerce\/ trade"
    },
    {
     ar: "اتحاد",
     he: "התאחדות",
     en: "association"
    },
    {
     ar: "اتحاد المنتجين",
     he: "קרטל",
     en: "Cartel"
    },
    {
     ar: "اتحاد جمركي",
     he: "הסכם מכס בין מדינות",
     en: "Customs union"
    },
    {
     ar: "اتحاد شركات",
     he: "קונסורציום",
     en: "consortium"
    },
    {
     ar: "اتحاد عمالي",
     he: "איגוד מקצועי",
     en: "Labour union"
    },
    {
     ar: "اتحاد نقدي",
     he: "איחוד מוניטארי",
     en: "Monetary union"
    },
    {
     ar: "اتعاب",
     he: "שכר טרחה",
     en: "Fee"
    },
    {
     ar: "اتفاق اجمالي",
     he: "עסקת חבילה",
     en: "Package deal"
    },
    {
     ar: "اتفاق اضماني",
     he: "עסקת חבילה",
     en: "Package deal"
    },
    {
     ar: "اتفاق شفوي",
     he: "הסכם בעל-פה",
     en: "Parole contract"
    },
    {
     ar: "اتفاق عمالي",
     he: "הסכם עבודה",
     en: "Labour agreement"
    },
    {
     ar: "اتفاقية تجارية تبادلية",
     he: "הסכם גומלין",
     en: "Reciprocal trade agreement"
    },
    {
     ar: "اتفاقية خيار لاجل",
     he: "אופציה",
     en: "Option"
    },
    {
     ar: "اتفاقية مقاصة",
     he: "הסכם סילוקין",
     en: "Clearing agreement"
    },
    {
     ar: "اجازة",
     he: "חופשה",
     en: "Leave\/ holiday"
    },
    {
     ar: "اجازة بدون راتب",
     he: "חופשה ללא תשלום",
     en: "Unpaid leave"
    },
    {
     ar: "اجازة سنوية",
     he: "חופשה שנתית",
     en: "Annual leave"
    },
    {
     ar: "اجر",
     he: "שכר\/ משכורת",
     en: "Salary\/ wages"
    },
    {
     ar: "الاجر بالقطعة",
     he: "שכר לפי יחידה",
     en: "Piece rate"
    },
    {
     ar: "اجر تشجيعي ",
     he: "שכר עידוד",
     en: "Incentive pay"
    },
    {
     ar: "اجر حقيقي",
     he: "שכר ריאלי",
     en: "Real wage"
    },
    {
     ar: "اجر متساو",
     he: "שכר שווה",
     en: "Equal pay"
    },
    {
     ar: "اجر متكافىء ",
     he: "שכר שווה",
     en: "Equal pay"
    },
    {
     ar: "اجراءات الافلاس",
     he: "הליכי פשיטת רגל",
     en: "Bankruptcy procedures"
    },
    {
     ar: "اجرة",
     he: "דמי שכירות",
     en: "rent"
    },
    {
     ar: "اجرة الشحن",
     he: "דמי הובלה",
     en: "Freight"
    },
    {
     ar: "اجرة الهاتف برسم التحصيل",
     he: "שיחת גוביינא",
     en: "Collect phonecall"
    },
    {
     ar: "اجمالي",
     he: "סך הכל",
     en: "total"
    },
    {
     ar: "اجور الشحن",
     he: "דמי משלוח",
     en: "Shipping charge"
    },
    {
     ar: "احتكار",
     he: "מונופול",
     en: "monopoly"
    },
    {
     ar: "احتكار الشراء",
     he: "מונופסון",
     en: "Monopsony"
    },
    {
     ar: "احتكار القلة",
     he: "מספר מעט של פירמות בענף",
     en: "oligopoly"
    },
    {
     ar: "احتكار بيع ثنائي",
     he: "דואופול (מונופול של שני יצרנים)",
     en: "Duopoly"
    },
    {
     ar: "احتكار بيع شركتين",
     he: "דואופול (מונופול של שני יצרנים)",
     en: "Duopoly"
    },
    {
     ar: "احتكار ثنائي",
     he: "מונופול דו- צדדי",
     en: "Bilateral monopoly"
    },
    {
     ar: "احتكار جزئي",
     he: "מונופול חלקי",
     en: "Partial monopoly"
    },
    {
     ar: "احتكار شراء ثنائي ",
     he: "דואופסון (מונופול של שני קונים)",
     en: "Duopsony"
    },
    {
     ar: "احتكار طبيعي",
     he: "מונופול טבעי",
     en: "Natural monopoly"
    },
    {
     ar: "احتكار قانوني",
     he: "מונופול חוקי",
     en: "Legal monopoly"
    },
    {
     ar: "اختراع",
     he: "פטנט",
     en: "Patent"
    },
    {
     ar: "اختزان",
     he: "אגירה",
     en: "Hoarding"
    },
    {
     ar: "ادارات متشابكة",
     he: "הנהלות משותפות לכמה חברות",
     en: "Interlocking directorates"
    },
    {
     ar: "ادارة",
     he: "הנהלה",
     en: "management"
    },
    {
     ar: "ادارة الاعمال",
     he: "מינהל עסקים",
     en: "Business administration\/ business management"
    },
    {
     ar: "ادارة الانتاج",
     he: "ניהול ייצור",
     en: "Production management"
    },
    {
     ar: "ادارة الجمارك",
     he: "ניהול המכס",
     en: "Customs administration"
    },
    {
     ar: "ادارة الدين",
     he: "ניהול חובות",
     en: "Debt management"
    },
    {
     ar: "ادارة المستودعات",
     he: "ניהול מלאי",
     en: "Inventory management"
    },
    {
     ar: "ادارة مالية",
     he: "ניהול פינאנסי",
     en: "Financial management"
    },
    {
     ar: "ادنى حد للاجر",
     he: "שכר מינימום",
     en: "Minimum wage"
    },
    {
     ar: "اذخار",
     he: "חיסכון",
     en: "Saving"
    },
    {
     ar: "اذخار اجباري",
     he: "חיסכון חובה",
     en: "Compulsory saving"
    },
    {
     ar: "اذخار سلبي",
     he: "חיסכון שלילי",
     en: "dissaving"
    },
    {
     ar: "ارباح غير موزعة",
     he: "רווחים שלא חולקו",
     en: "Undistributed profits\/ retained earnings"
    },
    {
     ar: "ارباح محتجزة",
     he: "רווחים שלא חולקו",
     en: "Undistributed profits\/ retained earnings"
    },
    {
     ar: "ارتباط",
     he: "הצמדה",
     en: "Undexation\/ linkage"
    },
    {
     ar: "ارث",
     he: "יורשה",
     en: "Inheritance"
    },
    {
     ar: "ارسالية",
     he: "משגור\/ קונסיגנציה ",
     en: "consignment"
    },
    {
     ar: "ارض حدية",
     he: "קרקע דלה",
     en: "Marginal land"
    },
    {
     ar: "ازدهار",
     he: "שגשוש\/ גאות (כלכלית)",
     en: "prosperity"
    },
    {
     ar: "ازدواج ضريبي",
     he: "כפל מס\/ מיסוי כפול",
     en: "Double taxation"
    },
    {
     ar: "ازمة اقتصادية",
     he: "משבר כלכלי",
     en: "Economic crisis"
    },
    {
     ar: "اساس الضريبة",
     he: "בסיס מס",
     en: "Tax base"
    },
    {
     ar: "استثمار",
     he: "השקעה",
     en: "Investment"
    },
    {
     ar: "استثمار اجنبي",
     he: "השקעה זרה",
     en: "Foreign investment"
    },
    {
     ar: "استثمار تجاري",
     he: "השקעה בתחום המסחר",
     en: "Trade investment"
    },
    {
     ar: "استثمار دائم",
     he: "השקעה קבועה",
     en: "Permanent investment"
    },
    {
     ar: "استثمار سلبي",
     he: "השקעה שלילית\/ תצרוכת הון (בחברה)",
     en: "Disinvestment"
    },
    {
     ar: "استثمار مباشر",
     he: "השקעה ישירה",
     en: "Direct investment"
    },
    {
     ar: "استثمارات اجنبية",
     he: "השקעות חוץ",
     en: "Foreign investment"
    },
    {
     ar: "استحقاق",
     he: "פירעון",
     en: "maturity"
    },
    {
     ar: "استرداد ",
     he: "החזר",
     en: "Pay pack"
    },
    {
     ar: "استرداد القيمة",
     he: "פדיון של הלוואה",
     en: "redemption"
    },
    {
     ar: "استرداد المال",
     he: "החזר כספי",
     en: "refund"
    },
    {
     ar: "استفاجة اجتماعية",
     he: "זכויות סוציאליות",
     en: "Social benefit"
    },
    {
     ar: "استقاء",
     he: "החכרת מטעלמעבד תמורת חלק מהיבול",
     en: "Leasing of a plantation to a cultivator in exchange for part of the crops"
    },
    {
     ar: "استقالة",
     he: "התפטרות",
     en: "Resignation"
    },
    {
     ar: "استنفاد",
     he: "אזילה",
     en: "Depletion"
    },
    {
     ar: "استهلاك",
     he: "צריכה",
     en: "Consumption"
    },
    {
     ar: "استهلاك بالاستعمال",
     he: "פחת",
     en: "depreciation"
    },
    {
     ar: "استهلاك راس مال",
     he: "צריכת הון",
     en: "Capital consumption"
    },
    {
     ar: "استهلاك معجل",
     he: "פחת מואץ",
     en: "Accelerated depreciation"
    },
    {
     ar: "استهلاك ناجم عن الاستعمال العادي",
     he: "בלאי",
     en: "Wear to tear"
    },
    {
     ar: "استيراد",
     he: "יבוא",
     en: "import"
    },
    {
     ar: "استيراد راس المال",
     he: "יבוא הון",
     en: "Capital import"
    },
    {
     ar: "استيراد غير منظور",
     he: "יבוא סמוי",
     en: "Invisible import"
    },
    {
     ar: "استيراد منظور",
     he: "יבוא גלוי",
     en: "Visible import"
    },
    {
     ar: "اسراف",
     he: "בזבוז",
     en: "waste"
    },
    {
     ar: "اسطول تجاري",
     he: "צי הסוחר",
     en: "Merchant navy"
    },
    {
     ar: "اسعار ثابتة",
     he: "מחירים קבועים",
     en: "Fixed prices"
    },
    {
     ar: "اسكان",
     he: "שיכון",
     en: "housing"
    },
    {
     ar: "اسكو: اللجنة الاقتصادية والاجتماعية لغرب اسيا",
     he: null,
     en: null
    },
    {
     ar: "اسهم التاسيس",
     he: "מניות יזמים\/ מניות מייסדים",
     en: "Promoter's shares\/ founder shares"
    },
    {
     ar: "اسهم المؤسسين",
     he: "מניות יזמים\/ מניות מייסדים",
     en: "Promoter's shares\/ founder shares"
    },
    {
     ar: "اسهم تاسيسية",
     he: "מניות יזמים\/ מניות מייסדים",
     en: "Promoter's shares\/ founder shares"
    },
    {
     ar: "اسهم راس المال",
     he: "הון מניות",
     en: "Capital shares\/ share capital"
    },
    {
     ar: "اسهم متداولة",
     he: "סך המניות הנסחרות",
     en: "Outstanding stocks"
    },
    {
     ar: "اشتراك",
     he: "מינוי (למשל על עיתון)",
     en: "subscription"
    },
    {
     ar: "اشتراكية",
     he: "סוציאליזם",
     en: "Socialism"
    },
    {
     ar: "اشعار",
     he: "הודעה",
     en: "Note\/ advice"
    },
    {
     ar: "اشعار بالارسال",
     he: "הודעת משלוח",
     en: "Advice of dispatch"
    },
    {
     ar: "اشعار بالاعتماد",
     he: "הודעת אשראי",
     en: "Credit advice"
    },
    {
     ar: "اشعار بالشحن",
     he: "הודעת משלוח",
     en: "Advice of dispatch"
    },
    {
     ar: "اشعار دائن",
     he: "הודעת זיכוי",
     en: "Credit memorandum\/ credit note"
    },
    {
     ar: "اشغال عامة",
     he: "עבודות ציבוריות",
     en: "Public works"
    },
    {
     ar: "اشياء ثمينة",
     he: "דברי ערך",
     en: "Valuables"
    },
    {
     ar: "اصدار",
     he: "הנפקה",
     en: "Issuing\/ issue"
    },
    {
     ar: "اصدار اسهم علاوة",
     he: "הנפקת מניות הטבה",
     en: "Scrip issue"
    },
    {
     ar: "اصدار بالمناقصة",
     he: "הנפקה באמצעת מכרז",
     en: "Issue by tender"
    },
    {
     ar: "اصدار نقدي",
     he: "הדפסת כסף",
     en: "Printing money"
    },
    {
     ar: "اصل",
     he: "נכס",
     en: "Asset"
    },
    {
     ar: "اصل حقيقي",
     he: "נכס מוחשי",
     en: "Actual asset\/ tangible asset"
    },
    {
     ar: "اصل دائم",
     he: "נכס שאינו מתכלה",
     en: "Non- wasting asset"
    },
    {
     ar: "اصل سائل",
     he: "נכס נזיל",
     en: "Liquid asset\/ near money"
    },
    {
     ar: "اصل صاف",
     he: "שווי נכס לאחר הפחתת מסים והיטלים",
     en: "Net asset"
    },
    {
     ar: "اصل عامل",
     he: "נכס פעיל",
     en: "Active asset"
    },
    {
     ar: "اصل مالي",
     he: "נכס הון",
     en: "Financial asset"
    },
    {
     ar: "اصل متناقص",
     he: "נכס מתכלה",
     en: "Wasting asset"
    },
    {
     ar: "اصل مجمد",
     he: "נכס מוקפא",
     en: "Frozen asset"
    },
    {
     ar: "اصل مرهون",
     he: "נכס משועבד",
     en: "Pledged asset"
    },
    {
     ar: "اصل نقدي",
     he: "נכס במזומן",
     en: "Cash asset"
    },
    {
     ar: "اصول",
     he: "נכסים",
     en: "assets"
    },
    {
     ar: "اصول مؤجلة",
     he: "נכסים נדחים",
     en: " Deferred asset"
    },
    {
     ar: "اضراب",
     he: "שביתה",
     en: "strike"
    },
    {
     ar: "اضراب التباطؤ",
     he: "שביתת האטה",
     en: "Go- slow strike"
    },
    {
     ar: "اضراب تاييدي",
     he: "שביתת הזדהות",
     en: "Sympathetic strike"
    },
    {
     ar: "اضراب عام",
     he: "שביתה כללית",
     en: "General strike"
    },
    {
     ar: "اضراب غير شرعي",
     he: "שביתה בלתי חוקית",
     en: "Outlawed strike\/ illegal strike"
    },
    {
     ar: "اضراب غير قانوني",
     he: "שביתה בלתי חוקית",
     en: "Outlawed strike\/ illegal strike"
    },
    {
     ar: "اعادة الاستيراد",
     he: "יבוא חוזר",
     en: "Re-import"
    },
    {
     ar: "اعادة الاصدار",
     he: "הנפקה מחדש",
     en: "Re-issue"
    },
    {
     ar: "اعادة الانتاج",
     he: "ייצור חוזר",
     en: "Re-production"
    },
    {
     ar: "اعادة البيع",
     he: "מכירה מחדש",
     en: "resale"
    },
    {
     ar: "اعادة التصدير",
     he: "יצוא חוזר",
     en: "Re-export"
    },
    {
     ar: "اعادة التقييم",
     he: "שיעורך",
     en: "Revaluation"
    },
    {
     ar: "اعادة الخصم",
     he: "ניכיון משנה",
     en: "rediscount"
    },
    {
     ar: "اعادة توزيع الدخل",
     he: "חלוקה מחדש של הכנסה",
     en: "Redistribution of income"
    },
    {
     ar: "اعارة",
     he: "הלוואה",
     en: "loan"
    },
    {
     ar: "اعانة",
     he: "סיוע\/ עזרה",
     en: "Aid "
    },
    {
     ar: null,
     he: "סובסידיה",
     en: "Subsidy"
    },
    {
     ar: "اعانة بطالة",
     he: "דמי אבטלה",
     en: "Unemployment benefit"
    },
    {
     ar: "اعتماد",
     he: "אשראי",
     en: "credit"
    },
    {
     ar: null,
     he: "הקצבה",
     en: "appropriation"
    },
    {
     ar: "اعتماد تجاري",
     he: "אשראי מסחרי",
     en: "Trade credit"
    },
    {
     ar: "اعتماد دفتري",
     he: "אשראי בספרים",
     en: "Book credit"
    },
    {
     ar: "اعتماد دوار",
     he: "אשראי מתחדש מעצמו",
     en: "Revolving credit"
    },
    {
     ar: "اعتماد طويل الاجل",
     he: "אשראי לטווח ארוך",
     en: "Long tern credit"
    },
    {
     ar: "اعتماد غير مضمون",
     he: "אשראי בלתי מובטח",
     en: "Unsecured credit"
    },
    {
     ar: "اعتماد قابل للتحويل",
     he: "אשראי ניתן להעברה",
     en: "Transferable credit"
    },
    {
     ar: "اعتماد قصير الاجل",
     he: "אשראי לטווח ארוך",
     en: "Short tern credit"
    },
    {
     ar: "اعتماد للاستيراد",
     he: "אשראי ליבוא",
     en: "Import credit"
    },
    {
     ar: "اعتماد للتصدير",
     he: "אשראי ליצוא",
     en: "Export credit"
    },
    {
     ar: "اعتماد متوسط الاجل",
     he: "אשראי לטווח בינוני",
     en: "Medium tern credit"
    },
    {
     ar: "اعتماد محلي",
     he: "אשראי מקומי",
     en: "Local credit\/ domestic credit"
    },
    {
     ar: "اعتماد مستندي",
     he: "אשראי דוקומנטרי",
     en: "Documentary credit"
    },
    {
     ar: "اعتماد مصرفي",
     he: "אשראי בנקאי",
     en: "Bank credit"
    },
    {
     ar: "اعتماد مضمون",
     he: "אשראי מובטח",
     en: "Secured credit"
    },
    {
     ar: "اعتماد مفتوح",
     he: "אשראי פתוח",
     en: "Open credit"
    },
    {
     ar: "اعتماد ممتد",
     he: "אשראי מוגדל",
     en: "Extended credit"
    },
    {
     ar: "اعفاء ضريبي",
     he: "מתן פטור מס",
     en: "Tax exemption\/ zero rating "
    },
    {
     ar: "اعلان توزيع ارباح السهم",
     he: "הכרזה על חלוקת דיווידנד",
     en: "Declaration of dividends"
    },
    {
     ar: "اعمال الاعلان",
     he: "פרסום",
     en: "Advertising"
    },
    {
     ar: "اغاثة",
     he: "סעד",
     en: "Relief\/ welfare"
    },
    {
     ar: "اغراق",
     he: "הצפה",
     en: "Dumping"
    },
    {
     ar: "افراط في الاتجار",
     he: "התרחבות יתר",
     en: "Overtrading"
    },
    {
     ar: "افلاس",
     he: "פשיטת רגל",
     en: "Insolvency\/ bankruptcy"
    },
    {
     ar: "اقتصاد",
     he: "כלכלה",
     en: "Economics\/ economy"
    },
    {
     ar: "اقتصاد اجتماعي",
     he: "כלכלה חברתית",
     en: "Socio-economy"
    },
    {
     ar: "اقتصاد السوق",
     he: "כלכלת שוק",
     en: "Market economy"
    },
    {
     ar: "اقتصاد تطبيقي",
     he: "כלכלה שימושית",
     en: "Applied economics"
    },
    {
     ar: "اقتصاد ثنائي",
     he: "כלכלה שנייה",
     en: "Dual economy"
    },
    {
     ar: "اقتصاد موجة",
     he: "כלכלה מתוכננת\/ משק מתוכנן",
     en: "Planned economy"
    },
    {
     ar: "اقتصادي",
     he: "כלכלן",
     en: "Economist"
    },
    {
     ar: "الاقتصاديات الصغرى",
     he: "מיקרו כלכלה",
     en: "Micro-economics"
    },
    {
     ar: "اقتطاع",
     he: "ניכוי",
     en: "Deduction"
    },
    {
     ar: "اقتطاعية",
     he: "פיאודליזם",
     en: "Feudalism"
    },
    {
     ar: "اقفال الحساب",
     he: "סגירת חשבון",
     en: "Closing of an account "
    },
    {
     ar: "اكتتاب",
     he: "פניה לקניית נייר ערך",
     en: "Application"
    },
    {
     ar: "الالتزام بشراء ورقة مالية في الاكتتاب العام",
     he: "התחייבות לקניית נייר ערך בהנפקה",
     en: "subscription"
    },
    {
     ar: "اكتتاب غير مغطى",
     he: "חתימת חסר",
     en: "Under- subscription"
    },
    {
     ar: "اكتتاب مفرط",
     he: "חתימת יתר",
     en: "Over- subscription"
    },
    {
     ar: "اكتفاء ذاتي",
     he: "משק אוטרקי",
     en: "Autarchy economy"
    },
    {
     ar: "اكتناز",
     he: "אגירה",
     en: "Hoarding"
    },
    {
     ar: "التزام",
     he: "התחייבות",
     en: "Obligation"
    },
    {
     ar: "الة",
     he: "מכונה",
     en: "Machine"
    },
    {
     ar: "الة بيع",
     he: "אוטומט למכירת מוצרים",
     en: "Vending machine"
    },
    {
     ar: "امانة الصندوق",
     he: "גזברות",
     en: "Treasurership"
    },
    {
     ar: "امتياز",
     he: "זיכיון",
     en: "Franchise"
    },
    {
     ar: "امدادات",
     he: "אספקה",
     en: "Supply"
    },
    {
     ar: "امر بدفع ربح السهم",
     he: "המחאת דיווידנדים",
     en: "Dividend warrant"
    },
    {
     ar: "امر بيع",
     he: "הוראת מכירה",
     en: "Sale order"
    },
    {
     ar: "امر دائم",
     he: "הוראת קבע",
     en: "Standing order\/ permanent instruction"
    },
    {
     ar: "امر شراء",
     he: "הוראת קנייה",
     en: "Buy order"
    },
    {
     ar: "اموال",
     he: "כספים",
     en: "Funds"
    },
    {
     ar: null,
     he: "נכסים",
     en: "Assets"
    },
    {
     ar: "اموال عامة",
     he: "כספי ציבור",
     en: "Public funds"
    },
    {
     ar: "اموال متنقلة",
     he: "כסף המועבר מהשקעה להשקעה כדי לצבור רווח קצר מועד",
     en: "Hot money"
    },
    {
     ar: "اموال وهمية",
     he: "נכסים מדומים",
     en: "Fictitious assets"
    },
    {
     ar: "امين",
     he: "אפוטרופוס\/ נאמן",
     en: "Fiduciary\/ trustee"
    },
    {
     ar: "امين الصندوق ",
     he: "גזבר",
     en: "Burser\/ cashier\/ treasurer"
    },
    {
     ar: "امين سر",
     he: "מזכיר",
     en: "Secretary"
    },
    {
     ar: "امين عام",
     he: "מזכיר כללי",
     en: "Secretary general"
    },
    {
     ar: "انتاج",
     he: "ייצור",
     en: "Production\/ manufacturing"
    },
    {
     ar: "انتاج بالجملة",
     he: "ייצור המוני",
     en: "Mass production\/ large scale production"
    },
    {
     ar: "انتاج حسب الطلب",
     he: "ייצור של פריט ייחודי",
     en: "One off"
    },
    {
     ar: "انتاج على نطاق واسع",
     he: "ייצור המוני",
     en: "Mass production\/ large scale production"
    },
    {
     ar: null,
     he: null,
     en: null
    },
    {
     ar: "انتاج قومي اجمالي",
     he: "תוצר לאומי גולמי",
     en: "Gross national product"
    },
    {
     ar: "انتاجية",
     he: "פריון",
     en: "Productivity"
    },
    {
     ar: "انتعاش ",
     he: "התאוששות",
     en: "Recovery"
    },
    {
     ar: "انتفاع",
     he: "הפקת תועלת",
     en: "Utilization"
    },
    {
     ar: "انتهاء الاجل",
     he: "פקיעה",
     en: "Expiration"
    },
    {
     ar: "اندماج",
     he: "מיזוג",
     en: "Merger\/ integration\/ consolidation"
    },
    {
     ar: "انعدام العيوب",
     he: "אפס ליקויים",
     en: "Zero defects"
    },
    {
     ar: "انفاق",
     he: "הוצאה",
     en: "Expenditure\/ outlay\/ expense"
    },
    {
     ar: "انقضاء",
     he: "פקיעה",
     en: "Expiration"
    },
    {
     ar: "انكماش",
     he: "דפלציה",
     en: "Deflation"
    },
    {
     ar: "اوراق النقد المتداولة",
     he: "שטרות במחזור",
     en: "Notes in circulation"
    },
    {
     ar: "اوراق النقد سحبت من التداول ",
     he: "שטרות שהוצאו מהמחזור",
     en: "Bills retired"
    },
    {
     ar: "اوقية",
     he: "אונקייה",
     en: "ounce"
    },
    {
     ar: "ايجار",
     he: "השכרה\/ שכר דירה",
     en: "Lease\/ rent"
    },
    {
     ar: "ايداع للاستيراد",
     he: "פיקדון יבוא",
     en: "Import deposit"
    },
    {
     ar: "ايد عاملة",
     he: "כוח אדם",
     en: "Manpower"
    },
    {
     ar: "ايراد",
     he: "הכנסה\/ פדיון",
     en: "Income\/ revenue"
    },
    {
     ar: "ايراد حدي",
     he: "פדיון שולי",
     en: "Marginal revenue"
    },
    {
     ar: "ايراد داخلي",
     he: "פדיון ממכירות מקומיות",
     en: "Inland revenue"
    },
    {
     ar: "ايصال",
     he: "קבלה",
     en: "Receipt"
    }
   ]
   export default alef ;