let massShebah= [
    
    {"date":2022,
        "human":
            {"band1":{
                "maxRate":47,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":23,
            "minRate":23,
            "inflonsiRate":10
        }}},
    
    
    {"date":2021,
        "human":
            {"band1":{
                "maxRate":47,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":23,
            "minRate":23,
            "inflonsiRate":10
        }}},
    
    
    {"date":2020,
        "human":
            {"band1":{
                "maxRate":47,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":23,
            "minRate":23,
            "inflonsiRate":10
        }}},
    
    
    {"date":2019,
        "human":
            {"band1":{
                "maxRate":47,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":23,
            "minRate":23,
            "inflonsiRate":10
        }}},
    
    
    {"date":2018,
        "human":
            {"band1":{
                "maxRate":47,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":23,
            "minRate":23,
            "inflonsiRate":10
        }}},
    
    
    {"date":2017,
        "human":
            {"band1":{
                "maxRate":47,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":24,
            "minRate":24,
            "inflonsiRate":10
        }}},
    
    
    {"date":2016,
        "human":
            {"band1":{
                "maxRate":48,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":25,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2015,
        "human":
            {"band1":{
                "maxRate":48,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":26.5,
            "minRate":26.5,
            "inflonsiRate":10
        }}},
    
    
    {"date":2014,
        "human":
            {"band1":{
                "maxRate":48,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":26.5,
            "minRate":26.5,
            "inflonsiRate":10
        }}},
    
    
    {"date":2013,
        "human":
            {"band1":{
                "maxRate":48,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":25,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2012,
        "human":
            {"band1":{
                "maxRate":48,
                "minRate":20,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":25,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2011,
        "human":
            {"band1":{
                "maxRate":45,
                "minRate":0,
                "idk":20,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":24,
            "minRate":24,
            "inflonsiRate":10
        }}},
    
    
    {"date":2010,
        "human":
            {"band1":{
                "maxRate":45,
                "minRate":0,
                "idk":20,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":25,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2009,
        "human":
            {"band1":{
                "maxRate":46,
                "minRate":0,
                "idk":20,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":26,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2008,
        "human":
            {"band1":{
                "maxRate":47,
                "minRate":0,
                "idk":20,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":27,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2007,
        "human":
            {"band1":{
                "maxRate":48,
                "minRate":0,
                "idk":20,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":29,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2006,
        "human":
            {"band1":{
                "maxRate":49,
                "minRate":0,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":31,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2005,
        "human":
            {"band1":{
                "maxRate":49,
                "minRate":0,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":34,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2004,
        "human":
            {"band1":{
                "maxRate":49,
                "minRate":0,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":35,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2003,
        "human":
            {"band1":{
                "maxRate":50,
                "minRate":0,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":36,
            "minRate":25,
            "inflonsiRate":10
        }}},
    
    
    {"date":2002,
        "human":
            {"band1":{
                "maxRate":50,
                "minRate":0,
                "idk":25,
                "inflonsiRate":10
            }},
         "company":
            {"band1":
            {"maxRate":36,
            "minRate":25,
            "inflonsiRate":10
        }}}
    
    
    ]
    export default {massShebah}