let haaa=[
 {
  ar: "خادم",
  he: "משרת",
  en: "Servant"
 },
 {
  ar: "خاص",
  he: "פרטי",
  en: "private"
 },
 {
  ar: "خاضع لضريبة",
  he: "חייב במס",
  en: "Liable to tax\/ taxable"
 },
 {
  ar: "خالص اجرة البريد",
  he: "דמי הדואר שולמו",
  en: "Post- free\/ post paid"
 },
 {
  ar: "خام",
  he: "חומר גלם",
  en: "Raw material"
 },
 {
  ar: "خبير حسابات تامين",
  he: "אקטואר",
  en: "actuary"
 },
 {
  ar: "خدمات اجتماعية",
  he: "שירותי רווחה",
  en: "Social services"
 },
 {
  ar: "خدمات ادارية",
  he: "שירותי מנהלה",
  en: "Administrative services"
 },
 {
  ar: "خدمات بلدية",
  he: "שירותים מוניציפאליים",
  en: "Municipal services"
 },
 {
  ar: "خدمة",
  he: "שירות",
  en: "service"
 },
 {
  ar: "خدمة البريد",
  he: "שירות הדואר",
  en: "Postal service"
 },
 {
  ar: "خدمة عامة",
  he: "שירות ציבורי",
  en: "Public service"
 },
 {
  ar: "خروج الرساميل ",
  he: "נדידת הון",
  en: "Exodus of capital"
 },
 {
  ar: "خزانة",
  he: "אוצר המדינה",
  en: "Exchequer"
 },
 {
  ar: "خزانة فولاذية",
  he: "כספת",
  en: "safe"
 },
 {
  ar: "خسائر الراسمال",
  he: "הפסד הון",
  en: "Capital loss"
 },
 {
  ar: "خسارة",
  he: "הפסד",
  en: "loss"
 },
 {
  ar: "خسارة جسيمة",
  he: "הפסד כבד",
  en: "Heavy loss"
 },
 {
  ar: "خسارة فعلية",
  he: "הפסד בפועל",
  en: "Actual loss"
 },
 {
  ar: "خسارة كلية",
  he: "נזק שאינו ניתן לתיקון",
  en: "Total loss"
 },
 {
  ar: "خسارة ناتجة",
  he: "הפסד עקיף",
  en: "Consequential loss"
 },
 {
  ar: "خصخصة",
  he: "הפרטה",
  en: "Privatization"
 },
 {
  ar: "خصم",
  he: "ניכיון",
  en: "discount"
 },
 {
  ar: "خصم مؤجل",
  he: "הטבה נדחית",
  en: "Deferred rebate"
 },
 {
  ar: "خط اعتماد",
  he: "קו אשראי",
  en: "Credit line"
 },
 {
  ar: "خط انتاج",
  he: "קו ייצור",
  en: "Production line"
 },
 {
  ar: "خطة",
  he: "תכנית",
  en: "plan"
 },
 {
  ar: "خطة خمسية",
  he: "תכנית חומש",
  en: "Five- year plan"
 },
 {
  ar: "خطر",
  he: "סיכון",
  en: "risk"
 },
 {
  ar: "خلاف عمالي",
  he: "סכסוך עבודה",
  en: "Labour dispute"
 },
 {
  ar: "خيار",
  he: "אופציה",
  en: "Option"
 },
 {
  ar: "خيار البيع",
  he: "אופציית מכר",
  en: "Put option"
 },
 {
  ar: "خيار الشراء",
  he: "אופציית רכש",
  en: "Call option"
 }
]
export default haaa ;