/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

// import Itt21 from '../../../components/Tables/basic21';
// import Itt22 from '../../../components/Tables/basic22/itt22';
// import { TaxCalculatorComponent21 } from '../../../calculators/components/TaxCalculatorComponent21';
// import { TaxCalculatorComponent22 } from '../../../calculators/components/TaxCalculatorComponent22';
export default class PricesFintech extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | أسعار خدمة المحاسبة     </title>
					<meta name="description" content="MassPortal - أسعار خدمة المحاسبة" />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container ">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>أسعار خدمة المحاسبة   </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>


						<div className="row ">

							<br></br>
							<br></br>
							<br></br>

							<div className="col-sm-10  ">

								{/* <h3  >خلال إجازة الصيف القريبة، يطلب أصحاب عمل كثيرين لاستخدام القاصرين بأعمال مختلفة </h3> */}
								<br></br>
								{/* <ul className='privacy-ol'>
									<li>
										<p>
										
											<br></br>
											
										</p>
									</li>	

								</ul>
								 */}


							</div>
							<div className="col-lg-12">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy"> أسعار خدمة المحاسبة  <span className='privacy-white'> ( حسب مؤشر ماس ) </span></h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0" >
												{/* <thead>
													<tr >
														<th colspan="2"> أقدمية قطاع خاص  </th>
													
														<th colspan="2">أقدمية قطاع عام </th>
														
														<th colspan="2">أقدمية موظف هستدروت </th>
													
												

													</tr>
												</thead> */}
												<tbody className='fintech-tbl'>
													{/* <tr >
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														

													</tr> */}

													<tr>
														<td>التقرير السنوي للشركات الصغيرة</td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li> ابتداءً من 5,000 ش.ج</li>
															</ul>
														</td>

													</tr>


													<tr>
														<td>تقرير التدفق النقدي للشركات التي يبلغ حجم مبيعاتها مليون إلى مليون ونصف المليون شيكل في السنة </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li> ابتداءً من7,500 ش.ج</li>
															</ul>
														</td>

													</tr>


													<tr>
														<td >مسك الدفاتر ملف مستقل ، معفى( ض.ق.م) ، لتسجيل المصاريف و الايرادات تصريح ضريبي  شهري  .</td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 100 الى 300 ش.ج </li>
															</ul>
														</td>

													</tr>


													<tr>
														<td>مسك الدفاتر ملف مستقل ، مشتغل مرخص ( ض.ق.م) ، لتسجيل المصاريف و الايرادات تصريح ضريبي  شهري  .  </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 300 الى 700 ش.ج</li>
															</ul>
														</td>
													</tr>




													<tr>
														<td>مسك دفاتر شركة صغيرة يبلغ حجم مبيعاتها حتى نصف مليون شيكل في السنة    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 900 الى 2,300 ش.ج </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>مسك دفاتر شركة متوسطة يصل حجم مبيعاتها إلى مليون ونصف شيكل في السنة    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 1,200 الى 3,500 ش.ج</li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>مسك دفاتر مزدوج لتسجيل جميع العمليات المالية في الأعمال    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 800 الى 2,000 ش.ج
																</li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>تدقيق الميزانية العمومية  لشركة صغيرة أو متوسطة الحجم    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 7,500 الى 12,000 ش.ج
																</li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>تدقيق الميزانية العمومية  لشركة كبيرة    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>ابتداءً من 20,000 ش.ج    </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>تحضير الكشف السنوي لملف مستقل لضريبة الدخل     </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 1,000 الى 2,500 ش.ج
																</li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>فحص الجدوى الاقتصادية لشركة صغيرة أو متوسطة الحجم    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 5,000 الى 8,000 ش.ج</li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>بناء موازنة تقديرية لشركة صغيرة أو متوسطة السعر لكل ساعة عمل    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 300 الى 700 ش.ج   </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>إصدار قسائم الأجور السعر لكل قسيمة    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 30 الى 75 ش.ج    </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>تخزين الملفات السنوي يصل إلى ملف واحد ، السعر السنوي    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>75 ش.ج    </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>تخزين المواد السنوي لكل ملف إضافي ، السعر السنوي    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>25 ش.ج    </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>إعادة تحضير تقارير وكشوفات رأس المال قديمة     </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>ابتداءً من 1,000 ش.ج    </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>تحضير تقارير وكشف رأس المال    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>ابتداءً من 1,000 ش.ج    </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>استشارات مالية للعائلات بسعر ساعة الاستشارة    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>ابتداءً من 200 ش,ج   </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>استشارات الأعمال لتنشيط  المصالح  السعر لساعة الاستشارة    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 250 الى 600 ش.ج    </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>الاستشارات و مرافقة الأعمال للشركات والمؤسسات سعر الساعة الاستشارية    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>من 300 الى 1,000 ش.ج    </li>
															</ul>
														</td>
													</tr>


													<tr>
														<td>تأسيس شركة    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>ابتداءً من 1,500 ش.ج
																</li>
															</ul>
														</td>
													</tr>


												</tbody>
											</table>

										</div>

									</div>
									

								</div>
								{/* ========================TABLE One End ============================ */}


								{/* <ol className='privacy-ol  text-left' >
																<li>حتى جيل 16- 13.49 شيكل جديد للساعة.</li>
																<li>حتى جيل 17- 14.46 شيكل جديد للساعة.</li>
																<li>حتى جيل 18 – 16 شيكل جديد للساعة</li>
															</ol> */}
								<br></br>
							</div>



						</div>



						{/* /Content End */}
					</div>

					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
