import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import { Simpleroicalculator } from './Simple-ROI-calculator';
// import {} from "../../../Entryfile/imagepath"
// import { Applogo } from '../Entryfile/imagepath';
class SimpleRoi extends Component {
  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - عائد الاستثمار </title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title"> عائد الاستثمار <span className="privacy"> (ROI )</span> </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
                </ul>
              </div>
              
            </div>
          </div>
          {/* /Page Header */}
        
          <Simpleroicalculator />
          
          
          
        </div>
        {/* /Page Content */}
      </div>
    );
  }
}

export default SimpleRoi;
