/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { FaSignature } from "react-icons/fa";
import { AiOutlineFileWord, AiOutlineFilePdf } from "react-icons/ai";
import {SiMicrosoftexcel} from "react-icons/si"

class Contracts extends Component {

  render() {

    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - Contracts</title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">عقود باللغة العبرية (עברית)</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
                <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> יחסי עובד מעביד < FaSignature size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                  <li>
                    <tr>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F001.pdf?alt=media&token=3f537800-9832-4fec-b2a3-c39a5464a5de" target='_blank' className='contract-a'>
                          <AiOutlineFilePdf size={30} className="privacy contract-icon" />
                        </a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F001.doc?alt=media&token=3ce957a7-b7a4-4b92-b524-aef38bf59eea" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F001.pdf?alt=media&token=3f537800-9832-4fec-b2a3-c39a5464a5de" target='_blank'>
                          כתב התחייבות עובד לשמירת סודיות
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F002.pdf?alt=media&token=df91c7e0-8f4c-4669-995e-64899b96f348" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F002.doc?alt=media&token=a663eae3-4a1c-4d0e-8b90-2b5b76a2915d" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F002.pdf?alt=media&token=df91c7e0-8f4c-4669-995e-64899b96f348" target='_blank'>
                          כתב התחייבות עובד היוצא להשתלמות
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F003.pdf?alt=media&token=f3e2895c-6522-463f-827d-092c9935f8af" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F003.doc?alt=media&token=45558c5e-7259-486b-af8b-d71712ad7d90" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F003.pdf?alt=media&token=f3e2895c-6522-463f-827d-092c9935f8af" target='_blank'>
                          התחייבות עובד לשמור על ציוד שקיבל ממעביד
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F004.pdf?alt=media&token=44f94ded-b52e-4d34-8303-28446e9bb811" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F004.doc?alt=media&token=1e7155bc-91c9-4bc4-ac4f-b6a233163b66" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F004.pdf?alt=media&token=44f94ded-b52e-4d34-8303-28446e9bb811" target='_blank'>
                          הסכם עבודה אישי
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F005.pdf?alt=media&token=29560b15-210a-46a3-a3d2-209dc6ce0925" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F005.doc?alt=media&token=149de76c-ddd5-4f45-87de-bcfc212ff7af" >
                          <AiOutlineFileWord size={30} className="privacy contract-icon" />
                        </a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F005.pdf?alt=media&token=29560b15-210a-46a3-a3d2-209dc6ce0925" target='_blank'>
                          הסכם העמדת הלוואה לעובד
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F006.pdf?alt=media&token=751c6f73-4ae1-4ec9-b540-f612ac375cba" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F006.doc?alt=media&token=e033c248-7dde-47a4-8dfe-8c9980351581" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F006.pdf?alt=media&token=751c6f73-4ae1-4ec9-b540-f612ac375cba" target='_blank'>
                          הסכם לסיום יחסי עובד ומביד (הפסקת עבודה)
                        </a>
                      </td>
                    </tr>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-xl-6   ">
              <div className="topics">
                <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> הסכמי מתן שירוותים < FaSignature size={30} className="privacy" /></h3>
                <ul className="topics-list">
                  {/* Contract Item  */}
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F007.pdf?alt=media&token=7f86a9c4-97c5-415a-8f49-0705b485b5b7" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F007.doc?alt=media&token=66a451cb-b51b-4a53-a416-d35bb4c7be36" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F007.pdf?alt=media&token=7f86a9c4-97c5-415a-8f49-0705b485b5b7" target='_blank'>
                        הסכם לביצוע שירותים
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F008.pdf?alt=media&token=6d9d037f-95a6-4d65-b58c-b9bee6dc3287" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F008.doc?alt=media&token=6c06760e-a6f5-4f7a-8e85-96bca2e9113a" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F008.pdf?alt=media&token=6d9d037f-95a6-4d65-b58c-b9bee6dc3287" target='_blank'>
                        הסכם ייעוץ
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F009.pdf?alt=media&token=ab5314bf-d7d5-4e17-ae16-102abb4870cb" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F009.doc?alt=media&token=ba012410-84b6-4137-a183-796a107917a4" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F009.pdf?alt=media&token=ab5314bf-d7d5-4e17-ae16-102abb4870cb" target='_blank'>
                        הסכם סוכנות ושיווק בלעדי
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F010.pdf?alt=media&token=ab494249-e776-4fa7-89e5-fc88be19b86e" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F010.doc?alt=media&token=56a276d4-4974-40b4-83c0-529dd6420f23" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F010.pdf?alt=media&token=ab494249-e776-4fa7-89e5-fc88be19b86e" target='_blank'>
                        הסכם שירות פרסום
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F011.pdf?alt=media&token=dd169534-b8ed-40fd-9622-d2dcb56a16cf" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F011.doc?alt=media&token=8ebc85d7-be0e-4661-b0b1-d2fcc6783e01" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F011.pdf?alt=media&token=dd169534-b8ed-40fd-9622-d2dcb56a16cf" target='_blank'>
                        הסכם להזמנת עבודה
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F012.pdf?alt=media&token=cc96ade7-cb00-401d-950a-eb8dabe9e2f4" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F012.doc?alt=media&token=d239d9fb-7fcc-47e8-a78e-fe9f70e1d2d4" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F012.pdf?alt=media&token=cc96ade7-cb00-401d-950a-eb8dabe9e2f4" target='_blank'>
                        הסכם לניהול נכס מקרקעין
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                  


                 


                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
              <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> תאגידים ( חברות, שותפויות ) < FaSignature size={30} className="privacy" /></h3>
                <ul className="topics-list">
                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F013.pdf?alt=media&token=3d5b9102-bc2a-4595-a55a-2184030fddd5" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F013.doc?alt=media&token=5e8d1b4e-5562-44e4-b2fe-6f0b4af33e48" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F013.pdf?alt=media&token=3d5b9102-bc2a-4595-a55a-2184030fddd5" target='_blank'>
                        הסכם יסוד שותפות
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F014.pdf?alt=media&token=741cc33c-4401-420f-84bf-dd27c889f66a" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F014.doc?alt=media&token=7bcee431-2b8b-4d3a-babd-9e65e24918e5" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F014.pdf?alt=media&token=741cc33c-4401-420f-84bf-dd27c889f66a" target='_blank'>
                        הסכם לצירוף שותף חדש לשותפות
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F015.pdf?alt=media&token=e8cc05d0-0269-409c-b29b-a474066e1842" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F015.doc?alt=media&token=ec7b5e7f-537e-4ef2-be74-7f9f7a04ce31" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F015.pdf?alt=media&token=e8cc05d0-0269-409c-b29b-a474066e1842" target='_blank'>
                        הסכם לפירוק שותפות
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F016.pdf?alt=media&token=7acc2980-1d9a-40eb-b282-a07839c3a4f8" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F016.doc?alt=media&token=ea36b3ce-097d-4ffb-87ec-8b3a6b0e13a3" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F016.pdf?alt=media&token=7acc2980-1d9a-40eb-b282-a07839c3a4f8" target='_blank'>
                        כתב התחייבות לשמירה על סודיות ואי תחרות
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F017.pdf?alt=media&token=2dd6f74b-35ab-40f3-a27b-313be4eea5b5" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F017.doc?alt=media&token=c4f8f161-3124-4b8c-8d8f-f219d800336c" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F017.pdf?alt=media&token=2dd6f74b-35ab-40f3-a27b-313be4eea5b5" target='_blank'>
                        הסכם מייסדים להקמת חברה
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F018.pdf?alt=media&token=76b62c6b-0e39-4c0d-a27c-2c62f03b2977" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F018.doc?alt=media&token=8106e8c8-9822-44d4-b8e8-5b7c0ee0412c" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F018.pdf?alt=media&token=76b62c6b-0e39-4c0d-a27c-2c62f03b2977" target='_blank'>
                        הסכם פרישה מחברה
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F019.pdf?alt=media&token=1dba98aa-100d-45b3-adf0-a5c061838816" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F019.doc?alt=media&token=4925e2b1-7a0d-4f13-88b6-28296a360d22" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F019.pdf?alt=media&token=1dba98aa-100d-45b3-adf0-a5c061838816" target='_blank'>
                        הסכם הלוואת בעלים
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F020.pdf?alt=media&token=bd5e9091-fefa-4df2-add8-0bdec0a9600d" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F020.doc?alt=media&token=25918409-d08a-495a-aee5-c844128d3e08" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F020.pdf?alt=media&token=bd5e9091-fefa-4df2-add8-0bdec0a9600d" target='_blank'>
                        הסכם לצירוף שותף אשר משקיעים לחברה
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F021.pdf?alt=media&token=c53ff0d2-7508-41f2-89ec-3c60c5eb9810" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F021.doc?alt=media&token=d56076cb-1b3e-443d-9b9e-f42044db04e3" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F021.pdf?alt=media&token=c53ff0d2-7508-41f2-89ec-3c60c5eb9810" target='_blank'>
                        הסכם לזכות סירוב ראשונה בחברה
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}
                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F022.pdf?alt=media&token=57ea50a8-e0d6-4f70-a460-3a702343a244" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F022.doc?alt=media&token=e6aa63a4-f873-4d8f-90b4-111b250eeab0" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F022.pdf?alt=media&token=57ea50a8-e0d6-4f70-a460-3a702343a244" target='_blank'>
                        הסכם להחזקת מניות בנאמנות בחברה
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
              <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> הסכמי מכר < FaSignature size={30} className="privacy" /></h3>
                <ul className="topics-list">

                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F023.pdf?alt=media&token=f4d38288-940f-4720-951c-4f5ae334adac" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F023.doc?alt=media&token=10155147-9c5e-4844-b8da-da30d5006cca" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F023.pdf?alt=media&token=f4d38288-940f-4720-951c-4f5ae334adac" target='_blank'>
                        הסכם למכירת נכס מקרקעין
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F024.pdf?alt=media&token=0e6d3d17-58c4-474c-b174-0228f488a18d" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F024.doc?alt=media&token=92b16fbf-7cb9-48ef-b6c6-3327cef963d4" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F024.pdf?alt=media&token=0e6d3d17-58c4-474c-b174-0228f488a18d" target='_blank'>
                        הסכם למכירת עסק 
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F025.pdf?alt=media&token=b7ac0366-ca25-40da-b0d9-b2d2cceb4876" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F025.doc?alt=media&token=1c24d99b-0816-4352-af15-a4237a519ba4" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F025.pdf?alt=media&token=b7ac0366-ca25-40da-b0d9-b2d2cceb4876" target='_blank'>
                        הסכם למכירת מניות
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F026.pdf?alt=media&token=ca350f68-c367-4e29-afa4-5881551ac7e6" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F026.doc?alt=media&token=5540357f-b0df-4b0f-80fb-9a4ce191c8d2" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F026.pdf?alt=media&token=ca350f68-c367-4e29-afa4-5881551ac7e6" target='_blank'>
                        הסכם למכירת טובין
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F027.pdf?alt=media&token=fc0fd230-554b-41a9-82ff-792c06b6dad0" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F027.doc?alt=media&token=da798415-6095-4b6f-810f-c0a1d0f3bfaa" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F027.pdf?alt=media&token=fc0fd230-554b-41a9-82ff-792c06b6dad0" target='_blank'>
                        הסכם למכירת רכב
                        </a>
                      </td>
                    </tr>
                  </li>
                
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
              <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> عقود ديناميكية< FaSignature size={30} className="privacy" /></h3>
                <ul className="topics-list">

                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F023.pdf?alt=media&token=f4d38288-940f-4720-951c-4f5ae334adac" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F023.doc?alt=media&token=10155147-9c5e-4844-b8da-da30d5006cca" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="/pages/sidebar-pages/contracts/rent-contract">
                        عقد إيجار
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F024.pdf?alt=media&token=0e6d3d17-58c4-474c-b174-0228f488a18d" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F024.doc?alt=media&token=92b16fbf-7cb9-48ef-b6c6-3327cef963d4" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F024.pdf?alt=media&token=0e6d3d17-58c4-474c-b174-0228f488a18d" target='_blank'>
                        اخر
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F025.pdf?alt=media&token=b7ac0366-ca25-40da-b0d9-b2d2cceb4876" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F025.doc?alt=media&token=1c24d99b-0816-4352-af15-a4237a519ba4" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F025.pdf?alt=media&token=b7ac0366-ca25-40da-b0d9-b2d2cceb4876" target='_blank'>
                        اخر
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F026.pdf?alt=media&token=ca350f68-c367-4e29-afa4-5881551ac7e6" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F026.doc?alt=media&token=5540357f-b0df-4b0f-80fb-9a4ce191c8d2" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F026.pdf?alt=media&token=ca350f68-c367-4e29-afa4-5881551ac7e6" target='_blank'>
                        اخر
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F027.pdf?alt=media&token=fc0fd230-554b-41a9-82ff-792c06b6dad0" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F027.doc?alt=media&token=da798415-6095-4b6f-810f-c0a1d0f3bfaa" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F027.pdf?alt=media&token=fc0fd230-554b-41a9-82ff-792c06b6dad0" target='_blank'>
                        اخر
                        </a>
                      </td>
                    </tr>
                  </li>
                
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
              <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> הסכמי שונים < FaSignature size={30} className="privacy" /></h3>
                <ul className="topics-list">
                   {/* Contract Item  */}
                   <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F028.pdf?alt=media&token=c690026a-e3dc-4469-b954-1da85bc70715" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F028.doc?alt=media&token=39ea396d-8816-428c-bcaa-8c705a30e425" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F028.pdf?alt=media&token=c690026a-e3dc-4469-b954-1da85bc70715" target='_blank'>
                        הסכם שכירות ( בלתי מוגנת)
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F029.pdf?alt=media&token=2162ee45-8953-4989-a414-bdd0c720aade" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F029.doc?alt=media&token=b1a217dc-aa67-400d-8965-7da443419805" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F029.pdf?alt=media&token=2162ee45-8953-4989-a414-bdd0c720aade" target='_blank'>
                        הסכם הלוואה
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F030.pdf?alt=media&token=681c39f1-c17a-4669-bed4-4762b57cfca4" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F030.doc?alt=media&token=adcab087-bf00-4d94-ad1d-cce1b5a1fb36" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F030.pdf?alt=media&token=681c39f1-c17a-4669-bed4-4762b57cfca4" target='_blank'>
                        הסכם זיכיון 
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F031.pdf?alt=media&token=0c545d5b-91b9-4ad3-8077-238f20a59c84" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F031.doc?alt=media&token=dea5caee-a860-4ef1-bcb0-a9239ad7f6d7" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F031.pdf?alt=media&token=0c545d5b-91b9-4ad3-8077-238f20a59c84" target='_blank'>
                        הסכם לשיתוף פעולה עסקי
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F032.pdf?alt=media&token=dd359603-41e7-4ff9-b54e-b55544660f66" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F032.doc?alt=media&token=d4286a59-c14c-466a-abd2-5799463c5aca" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F032.pdf?alt=media&token=dd359603-41e7-4ff9-b54e-b55544660f66" target='_blank'>
                        הסכם בין ממציא ליזם
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F033.pdf?alt=media&token=910f7d58-2e7c-446c-b14b-a1f78c0dbe6a" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F033.doc?alt=media&token=dfa6e395-dbdd-49bf-9be2-66f26389ffea" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F033.pdf?alt=media&token=910f7d58-2e7c-446c-b14b-a1f78c0dbe6a" target='_blank'>
                        כתב התחייבות לשמירת סודיות בטרם מצגת
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F034.pdf?alt=media&token=43c9758a-7f64-4b73-9e03-7165b7d4fdce" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F034.doc?alt=media&token=4757853d-15b1-43aa-a34d-c497f79dc313" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F034.pdf?alt=media&token=43c9758a-7f64-4b73-9e03-7165b7d4fdce" target='_blank'>
                        כתב ערבות אישית בלתי חוזרת
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F035.pdf?alt=media&token=8d9c4482-4ddd-4eda-89b6-73256b5129be" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F035.doc?alt=media&token=9278ed77-96cf-4092-b7b3-49029de43e3e" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F035.pdf?alt=media&token=8d9c4482-4ddd-4eda-89b6-73256b5129be" target='_blank'>
                        שטר חוב לביטחון בלבד
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F036.pdf?alt=media&token=708aad45-3c5e-4bca-9036-fcd7cd044381" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F036.doc?alt=media&token=348a2230-a01b-4e12-8187-9e20c057c233" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F036.pdf?alt=media&token=708aad45-3c5e-4bca-9036-fcd7cd044381" target='_blank'>
                        כתב אחריות למוצרים
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F037.pdf?alt=media&token=3256b6f0-1668-4a0c-a41e-ba7c11252b8f" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F037.doc?alt=media&token=5ab5f446-5c99-450a-b52f-bea2a704bf22" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F037.pdf?alt=media&token=3256b6f0-1668-4a0c-a41e-ba7c11252b8f" target='_blank'>
                        הסכם נאמנות
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F038.pdf?alt=media&token=c22652d4-8c7c-4ced-9ec2-ec83f13bfe79" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F038.doc?alt=media&token=282068fa-6e64-4445-b1ee-d77cf65bc64b" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F038.pdf?alt=media&token=c22652d4-8c7c-4ced-9ec2-ec83f13bfe79" target='_blank'>
                        ייפוי כוח כללי
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F039.pdf?alt=media&token=ca23b447-5bb6-4f97-a64c-0cdedf25e2a9" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F039.doc?alt=media&token=cf69c16d-880d-49be-b3de-d196540b5286" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F039.pdf?alt=media&token=ca23b447-5bb6-4f97-a64c-0cdedf25e2a9" target='_blank'>
                        הסכם בוררות
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                    {/* Contract Item  */}
                    <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F040.pdf?alt=media&token=aebe3d14-8b42-4bb6-9aac-35c4234e4cc7" target='_blank' className='contract-a'><AiOutlineFilePdf size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                      <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fword%2F040.doc?alt=media&token=e3a53343-14d8-40b7-8675-c31f2dc52782" ><AiOutlineFileWord size={30} className="privacy contract-icon" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/heb-cnt%2Fpdf%2F040.pdf?alt=media&token=aebe3d14-8b42-4bb6-9aac-35c4234e4cc7" target='_blank'>
                        הסכם פשרה
                        </a>
                      </td>
                    </tr>
                  </li>
                   {/*  /Contract Item / */}

                </ul>
              </div>
            </div>

            {/* <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Getting Started <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6 ">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        {/* /Page Content */}
      </div>

    );
  }
}

export default Contracts;
