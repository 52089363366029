import React,{useState,useEffect} from 'react'
import taxProperty1 from "../mass-modules/mass-taxes/realSates/tables/real-state/output"
import taxProperty2 from "../mass-modules/mass-taxes/realSates/tables/real-state/court"
import taxProperty3 from "../mass-modules/mass-taxes/realSates/tables/real-state/grtOneAbt"
import taxProperty4 from "../mass-modules/mass-taxes/realSates/tables/real-state/newOne"
import taxProperty5 from "../mass-modules/mass-taxes/realSates/tables/real-state/NoAbt"
import taxProperty6 from "../mass-modules/mass-taxes/realSates/tables/real-state/farm"


export const useIntervalNumPerc = (date,tbl) => {

    const [num,setNum]=useState([])
    const [perc,setPerc]=useState([])
    const [np,setNp]=useState([])
    const [datas1,setDatas1]=useState(taxProperty1.taxProperty)
    const [datas2,setDatas2]=useState(taxProperty2.taxProperty)
    const [datas3,setDatas3]=useState(taxProperty3.taxProperty)
    const [datas4,setDatas4]=useState(taxProperty4.taxProperty)
    const [datas5,setDatas5]=useState(taxProperty5.taxProperty)
    const [datas6,setDatas6]=useState(taxProperty6.taxProperty)
    
    if(tbl =="oneAbt"){
    useEffect(()=>{
        getNumPerc(date)
        
      
    },[date,tbl])

 function getNumPerc(date){
       
          let year = 0; 
         
          datas1.reverse().map(d => {
            if( d.yearMil <= date ){ 
              year = d
              
              setNum([
                        year.band2.min,
                        year.band3.min,
                        year.band4.min,
                        year.band5.min,
            ])
            setPerc([
                (year.band1.rate)/100,
                (year.band2.rate)/100,
                (year.band3.rate)/100,
                (year.band4.rate)/100,
                (year.band5.rate)/100,
            ])

            setNp([(num[0])*perc[0],
            (num[1]-num[0])*perc[1],
            (num[2]-num[1])*perc[2],
            (num[3]-num[2])*perc[3],
            ])
              
            }
        }
       
        )
        setDatas1(datas1.reverse())}}
        else if(tbl =="noAbt"){
            useEffect(()=>{
                getNumPercNoAbt(date)
            },[date,tbl])
 function getNumPercNoAbt(date){
       
          let year = 0; 
         
          datas5.reverse().map(d => {
            if( d.yearMil <= date ){ 
              year = d
              
              setNum([
                        year.band1.min,
                        
            ])
            setPerc([
                (year.band1.rate)/100,
                
            ])

            setNp([(num[0])*perc[0],
            
            ])
              
            }
        }
       
        )
        setDatas5(datas5.reverse())}}
        else if(tbl =="court"){
            useEffect(()=>{
                getNumPercCourt(date)
                
              
            },[date,tbl])

 function getNumPercCourt(date){
       
          let year = 0; 
         
          datas2.reverse().map(d => {
            if( d.yearMil <= date ){ 
              year = d
              
              setNum([
                        year.band1.min,
                       
            ])
            setPerc([
                (year.band1.rate)/100,
                
            ])

            setNp([(num[0])*perc[0],
            
            ])
              
            }
        }
       
        )
        setDatas2(datas2.reverse())}}
        else if(tbl =="grtOneAbt"){
            useEffect(()=>{
                getNumPercGrtOneAbt(date)
            },[date,tbl])
 function getNumPercGrtOneAbt(date){
       
          let year = 0; 
         
          datas3.reverse().map(d => {
            if( d.yearMil <= date ){ 
              year = d
              
              setNum([
                        year.band2.min,
                        year.band3.min,
                        year.band4.min,
                        year.band5.min,
            ])
            setPerc([
                (year.band1.rate)/100,
                (year.band2.rate)/100,
                (year.band3.rate)/100,
                (year.band4.rate)/100,
                (year.band5.rate)/100,
            ])

            setNp([(num[0])*perc[0],
            (num[1]-num[0])*perc[1],
            (num[2]-num[1])*perc[2],
            (num[3]-num[2])*perc[3],
            ])
              
            }
        }
       
        )
        setDatas3(datas3.reverse())}}
        else if(tbl =="newOne"){
            useEffect(()=>{
                getNumPercNewOne(date)
            },[date,tbl])
 function getNumPercNewOne(date){
       
          let year = 0; 
         
          datas4.reverse().map(d => {
            if( d.yearMil <= date ){ 
              year = d
              
              setNum([
                        year.band2.min,
                        year.band3.min,
                       
            ])
            setPerc([
                (year.band1.rate)/100,
                (year.band2.rate)/100,
               
            ])

            setNp([(num[0])*perc[0],
           
            ])
              
            }
        }
       
        )
        setDatas4(datas4.reverse())}}
       
        else if(tbl =="farm"){ useEffect(()=>{
            getNumPercFarm(date)
        },[date,tbl])
 function getNumPercFarm(date){
       
          let year = 0; 
         
          datas6.reverse().map(d => {
            if( d.yearMil <= date ){ 
              year = d
              
              setNum([
                        year.band2.min,
                        year.band3.min,
                       
            ])
            setPerc([
                (year.band1.rate)/100,
                (year.band2.rate)/100,
               
            ])

            setNp([(num[0])*perc[0],
           
            ])
              
            }
        }
       
        )
        setDatas6(datas6.reverse())}}
       
        
        
        return {num,perc,np}
}
