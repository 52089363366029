import React, { useState, } from "react";

export function NatoTaxCalc21(props) {

  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [points, setPoints] = useState(0);
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  let pricePoint = 218;

  let num = [
    5661,
    8017.4,
    12385.4,
    16283.9,
    30434.4,
    36826.2];

  let percentage = [
    0.1,
    0.14,
    0.2,
    0.31,
    0.35,
    0.47,
    0.5];

  let np = [
    (num[0]) / (1 - percentage[0]),
    (num[1] - num[0]) / (1 - percentage[1]),
    (num[2] - num[1]) / (1 - percentage[2]),
    (num[3] - num[2]) / (1 - percentage[3]),
    (num[4] - num[3]) / (1 - percentage[4]),
    (num[5] - num[4]) / (1 - percentage[5])];

  const handleSubmit = (evt) => {

    evt.preventDefault();
    let id = Math.random();

    if (monthlyIncome !== "") {
      setTotal(calculateNato(parseFloat(monthlyIncome)));
      setShow(true)
    }

    function calculateNato(income) {

      let total = 0;

      if (income > 0 && income <= num[0]) {
        total = ((income) - (points * pricePoint)) / (1 - percentage[0]);
      }
      else if (income > num[0] && income <= num[1]) {
        total = ((income - num[0]) - (points * pricePoint)) / (1 - percentage[1]) + (np[0]);
      }
      else if (income > num[1] && income <= num[2]) {
        total = ((income - num[1]) - (points * pricePoint)) / (1 - percentage[2]) + (np[1]) + (np[0]);
      }
      else if (income > num[2] && income <= num[3]) {
        total = ((income - num[2]) - (points * pricePoint)) / (1 - percentage[3]) + (np[2]) + (np[1]) + (np[0]);
      }
      else if (income > num[3] && income <= num[4]) {
        total = ((income - num[3]) - (points * pricePoint)) / (1 - percentage[4]) + (np[3]) + (np[2]) + (np[1]) + (np[0]);
      }
      else if (income > num[4] && income <= num[5]) {
        total = ((income - num[4]) - (points * pricePoint)) / (1 - percentage[5]) + (np[4]) + (np[3]) + (np[2]) + (np[1]) + (np[0]);
      }
      else if (income > num[5]) {
        total = ((income - num[5]) - (points * pricePoint)) / (1 - percentage[6]) + (np[5]) + (np[4]) + (np[3]) + (np[2]) + (np[1]) + (np[0]);
      }

      if (total < income) {
        total = monthlyIncome;
      } else {
        total = total.toFixed(1)
      }

      setData(prev => [...prev,
      {
        id: id,
        total: total,
        points: points,
        tax: (total - monthlyIncome).toFixed(1),
        monthlyIncome: monthlyIncome,
      }])

      return total;

    }

  }
  const handleDelete = (id) => {
    let newData = data.filter((data) =>
      data.id !== id)
    setData(newData)
    newData = [];
  }

  return (
    <div className="row eOfService">
       
     
      
        
          <div className="col-md-6 card tax-card  eofS  ">
          <div className="card-body">
          <br></br>
      
          <h3 className="card-title text-center privacy">2021-Nato</h3>

            <form onSubmit={handleSubmit} className="form ">
              <div className="">
                <br></br>
      <br></br>

                <div className="form-gronum row">
                  <label className=" col-form-label"> الراتب الشهري</label>
                  <br></br>
                  <div className="">
                    <input type="text" className="form-control" name="monthlyincome" value={monthlyIncome}
                      onChange={(e) => setMonthlyIncome(e.target.value)} />
                      <br></br>

                    <div className="form-gronum row">
                      <label className=" col-form-label">نقاط التزكية</label>
                      <br></br>
                      <div className="">
                        <input type="text" className="form-control m-b-0" name="points" value={points}
                          onChange={(e) => setPoints(e.target.value)} />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="text-center ">
                <button type="submit" className="btn btn-primary calc-button">حساب</button>
              </div>
              <br></br>
            </form>
            </div>
            </div>

         



          <div className="col-md-6   ">
            {show && <div className="card">
              <div className="card-body ">
                

                  <div className=" ">
                    <div className="">
                      <div className="card-header">
                        <h3 className="card-title mb-0 text-center privacy"> الضريبة الشهرية لعام <span className="privacy"> 2021 </span></h3>
                      </div>
                      <div className="">
                        <div className="table-responsive">
                          <table className="table table-striped mb-0 ">
                            <thead>
                              <tr>
                                <th></th>
                                <th>الراتب قبل الخصم</th>
                                <th>الضريبة </th>
                                <th>نقاط التزكية</th>
                                <th>الراتب الشهري</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                data.map((d, index) => (
                                  <tr key={index}>
                                     <td>{index + 1}</td>
                                    <td>{d.total}</td>
                                    <td>{d.tax}</td>
                                    <td>{d.points}</td>
                                    <td>{d.monthlyIncome}</td>
                                    <td onClick={() => handleDelete(d.id)}>حذف</td>

                                   
                                  </tr>
                                ))}

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>}

          </div>





          <br /><br />


       
      
    </div>
  );
}