import React,{useState,useEffect} from 'react'
import { set } from 'react-hook-form'
import { BiShekel } from 'react-icons/bi'
import {BsCheckLg } from 'react-icons/bs'


export default function TableInvoice({
   
    list,
    total,
    amounts,
    tax,
    discount,
    totalAfDiscount,
    information,
    perTax,
    date,
    lang,
    exemptionAmount,
    totalAfTax,
    
}) {

   
  const[CATALOGNUMBER,setCATALOGNUMBER]=useState('')
  const[DESCRIPTION,setDESCRIPTION]=useState('')
  const[CATEGORY,setCATEGORY]=useState('')

  const[PRICE,setPRICE]=useState('')
  const[QUANTITY,setQUANTITY]=useState('')
  const[DISCOUNT,setDISCOUNT]=useState('')
  const[AMOUNT,setAMOUNT]=useState('')
  const[AMOUNTMUSTTAX,setAMOUNTMUSTTAX]=useState('')
  const[AMOUNTAFTERDISCOUNT,setAMOUNTAFTERDISCOUNT]=useState('')
  const[AMOUNTAFTERTAX,setAMOUNTAFTERTAX]=useState('')
  const[AMOUNTWITHOUTTAX,setAMOUNTWITHOUTTAX]=useState('')
  const[TAX,setTAX]=useState('')
  const[TOTAL,setTOTAL]=useState('')
  const[NOTE,setNOTE]=useState('')
  const[SIGNATURE,setSIGNATURE]=useState('')
  const[EXEMPT,setEXEMPT] = useState('')


  useEffect(()=>{
    const Translate = () =>{
      if(lang === 'en'){
        setCATALOGNUMBER('Catalog no.')
        setDESCRIPTION('Decription')
        setCATEGORY('Category')
        setPRICE('Price')
        setQUANTITY('Quantity')
        setDISCOUNT('Discount')
        setAMOUNT('Amount')
        setAMOUNTMUSTTAX('Amount to be taxed ')
        setTOTAL('Total ')
        setAMOUNTAFTERDISCOUNT('Amount After Discount ')
        setAMOUNTAFTERTAX('Amount After Tax ')
        setAMOUNTWITHOUTTAX('Tax-exempt amount')
        setTAX('Tax ')
        setEXEMPT('Exempt from tax ')
        // setNOTE('notes')
        // setSIGNATURE('signature')
      }
      else if (lang === 'he'){
        setCATALOGNUMBER('מספר קטלוגי')
        setDESCRIPTION('תיאור')
        setPRICE('מחיר')
        setQUANTITY('כמות')
        setDISCOUNT('הנחה')
        setAMOUNT('סה"כ')
        setAMOUNTMUSTTAX('סה"כ חייב למס')
        setAMOUNTAFTERTAX('סה"כ לאחר למס')
        setAMOUNTAFTERDISCOUNT('סה"כ לאחר הנחה')
        setTAX('מע"מ ')
        setTOTAL('סה"כ לתשלום : ')
        setAMOUNTWITHOUTTAX('סה"כ פטור ממס')
        setEXEMPT('פטור ממ"ע')
        // setNOTE('')
        // setSIGNATURE('')
      }else {
        
        setCATALOGNUMBER('رقم المستند')
        setDESCRIPTION('نوع المستند')
        setCATEGORY('نوع القيد')
        setPRICE('المبلغ')
        setQUANTITY('الكمية')
        setDISCOUNT('خصم')
        setAMOUNT('المجموع')
        setAMOUNTMUSTTAX('المجموع الملزم بالضريبة')
        setAMOUNTAFTERTAX('المجموع شامل الضريبة')
        setAMOUNTAFTERDISCOUNT('المجموع  بعد الخصم')
        setTAX('ضريبة')
        setTOTAL('المبلغ الكلي ')
        setAMOUNTWITHOUTTAX('المجموع المعفي ')
        setEXEMPT('معفي ض.ق.م')
        // setNOTE('')
        // setSIGNATURE('')
       
      }
    }
    Translate()
  },[lang])
    
  return (

     <>
        <div className="table-responsive ">
       
  
        <table className="table table-striped-mk table-hover-mk table-invoice-mk   my-animation text-left ">
                    <thead className='table-invoice-head-mk '>
                      <tr>
                        <th className="thead-num-mk">#</th>
                        <th className="">{CATALOGNUMBER} </th>
                        <th className="">  {DESCRIPTION}</th>
                        <th className="">  {CATEGORY}</th>
                        <th  className="">  {PRICE} </th>
                        {/* <th className="thead-quantity-mk" > {QUANTITY}</th> */}
                        <th className="thead-quantity-mk" title='معفي من ضريبة القيمة المضافة' > {EXEMPT}</th>
                        {/* <th className="thead-discount-mk" > {DISCOUNT} </th> */}
                        <th className=""> {AMOUNT}</th>
                        {/* <th> </th> */}
                      </tr>
                    </thead>
                    <tbody className='table-invoice-body-mk '>
                    {list.map((item,index)=>(
                              
                              <tr key={item.id}>
                              <td>{index+1}</td>
                              <td>
                                {(item.catalogNum)}  
                              </td>
                              <td>
                              {(item.invoiceDetails)}
                              </td>
                              <td>
                              {(item.catagory).toLocaleString()}
                              </td>
                              <td>
                              {(item.price).toLocaleString()}
                              </td>
                              <td>
                              {item.isExemption? <BsCheckLg color='orange' size={30}/> : ""}
                              </td>
                              {/* <td>
                              {((item.discountForProduct) > 0 ? item.discountForProduct : 0).toLocaleString()+"%"}
                              </td> */}
                              <td>
                              {(item.amount).toLocaleString()}
                              </td>
                            </tr>
                              ))}
                     
                    </tbody>
                  </table>
         
                 
        
                      </div>

                      <br></br>
                       <div className="table-responsive ">
                  <table className="table  table-white  table-white-sp my-animation  ">
                    <tbody className='tbody-invoice-mk' >
                      <tr>
                      <td  style={{"width" : "100%"}}></td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td className={lang ==='en'? "title-en bold": "title bold "}> {AMOUNTMUSTTAX}</td>
                        <td className={lang ==='en'? "result-en bold": "result bold"}>{amounts.toLocaleString()}</td>
                      </tr>
                      
                      <tr>
                      <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className={lang ==='en'? "title-en bold": "title bold"}> {TAX}
                         { lang ==='ar' ?
                           (perTax != 0 ? " ( ض.ق.م ) " +perTax+"%": "( معفى) ") 
                           :lang ==='he'? (perTax != 0 ? perTax+"%": "(פטור)")
                           :lang === 'en' ? (perTax !=0 ? perTax+"%": "(no tax)")
                           :" " 
                          }
                          </td>
                        <td className={lang ==='en'? "result-en bold": "result bold"}>
                          {tax.toLocaleString()}
                        </td>
                      </tr>
                      <tr >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td className={lang ==='en'? "title-en bold": "title bold"}>
                          {AMOUNTAFTERTAX}
                        </td>
                        <td style={{borderBottom:"gray 2px solid"}} className={lang ==='en'? "result-en bold": "result bold"}>
                         {totalAfTax.toLocaleString()}
                        </td>
                      </tr>
                      
                      <tr >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td className={lang ==='en'? "title-en bold": "title bold"}>
                          {AMOUNTWITHOUTTAX}
                        </td>
                        <td style={{borderBottom:"gray 2px solid"}} className={lang ==='en'? "result-en bold": "result bold"}>
                         {exemptionAmount.toLocaleString()}
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="title bold">تدفع حتى : </td>
                        <td className="result bold">
                          {date}
                        </td>
                      </tr> */}
                      <tr>
                      <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td  className={lang ==='en'? "title-en bold": "title bold"}>
                        {TOTAL} 
                        </td>
                        <td style={{borderBottom:"#FF9B44 8px double"}} className={lang ==='en'? "result-en bold": "result bold"}>
                          {total.toLocaleString()}  <BiShekel />
                        </td>
                      </tr>
                    </tbody>
                  </table> 
                                                
                </div>
                
                      </>
  )
}
