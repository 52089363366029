/**
 * Faq Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";


class LawRecFaq extends Component {

	render() {
		return (
			<div className="page-wrapper">
			<Helmet>
				<title>MassPortal  | أسئلة و أجوبة </title>
				<meta name="description" content="Subscriptions" />
			</Helmet>
			{/* Page Content */}
			<div className="content container-fluid">
			  {/* Page Header */}
			  <div className="page-header">
				<div className="row">
				  <div className="col-sm-12">
					<h3 className="page-title">أسئلة و أجوبة </h3>
					<ul className="breadcrumb">
					  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>

					</ul>
					
				  </div>
				</div>
			  </div>
			  {/* /Page Header */}
			  <div className="faq-card">
				  <h4 className='privacy text-center'>أسئلة وأجوبة بموضوع تغييرات القانون تبعا لبرنامج نقاهة اقتصاد إسرائيل.</h4>
				<br></br>
				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseOne">لدي عامل أجير الذي يسكن في إيلات . في شهر 7 راتبه الصافي كان اقل من شهر 6, لماذا ؟</a>
					</h4>
				  </div>
				  <div id="collapseOne" className="card-collapse collapse">
					<div className="card-body">
					  <p>لأنه منذ بداية شهر 7 بطلت الفائدة بقيمة 8% لانه يوجد لهذا العامل خصم بالضريبة لذلك الضريبة كثرت والراتب الصافي قل بالمقارنة مع الشهر الذي مضى.</p>
					</div>
				  </div>
				</div>
				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseTwo">لدي عمال الذين يسكنون في شمالي البلاد وتمتعوا بالخصم لسكان الشمال . هل حصل أي تغيير لهم من بداية شهر  2003/7 ؟
</a>
					</h4>
				  </div>
				  <div id="collapseTwo" className="card-collapse collapse">
					<div className="card-body">
					  <p>سكان شمالي البلاد قسموا لمجموعتين مع اسقف مختلفة .</p>
					  <p>عليك أن تعرف لكل عامل تعريف التزكية المناسبة للمكان الذي يسكن فيه حتى يحصل على تزكية والسقف المعرف بالقانون.</p>
					</div>
				  </div>
				</div>
				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseThree">عامل بدأ العمل عندي في شهر 7 وهو يسكن في منطقة التي تشمل خصم بقيمة 6% ولسبب كان البرنامج لم يحتسب له خصم منطقة في الإصلاح ( تحت الاصلاح ) لماذا ؟</a>
					</h4>
				  </div>
				  <div id="collapseThree" className="card-collapse collapse">
					<div className="card-body">
					  <p>حسب تعليمات ضريبة الدخل عامل بدأ بالعمل ابتداء من شهر 7 وهو مواطن في أحد المناطق التي ألغت له الخصومات لا يستطيع أن يأخذ خصم من صاحب العمل إلا فقط بصورة إرجاع ضريبة بعد انتهاء السنة</p>
					  <p>بكل الأحوال هذا العامل له الحق فقط الخصم من أجل منتصف الأجر السنوي وبالتحديد منتصف الاسقف السنوية</p>
					</div>
				  </div>
				</div>
				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseFour">بكشف 102 لضريبة الدخل ظهرت مبالغ بقائمة ضريبة اصحاب العمل . ماذا حصل فجأة ؟</a>
					</h4>
				  </div>
				  <div id="collapseFour" className="card-collapse collapse">
					<div className="card-body">
					  <p>حسب ما نرى انك تشغل عمال لا يحملون الجنسية الاسرائيلية وبداية من شهر 7 فرض عليهم ضريبة اضافية بقيمة 8%</p>
					  <p>المسرح عنه بكشف 102 في قسم ضريبة أصحاب العمل.</p>
					</div>
				  </div>
				</div>


				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseFive">قرأت في مكان ما أن عامل لا يحمل الجنسية الاسرائيلية والذي يعمل بعلاج طبي معفي من فرض الضريبة الاضافية, ماذا علي ان اعمل في البرنامج حتى يعفي هذا العامل من فرض الضريبة الاضافية ؟</a>
					</h4>
				  </div>
				  <div id="collapseFive" className="card-collapse collapse">
					<div className="card-body">
					  <p>في شاشة العمال يجب الدخول لكرت تفاصيل العمل ووضع رقم محدد تحت</p>
					  <p>التأمين الوطني وتعريفه كموظف لا يحمل الجنسية الاسرائيلية الذي يعمل بعلاج طبي.</p>
					</div>
				  </div>
				</div>
				
				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseSix"> العمل معرف انه معفي من الضريبة وبما أنه من ضريبة أصحاب العمل كبر الشهر بصورة مقنعة وليس 4 % من الراتب كما كان طوال الوقت , لماذا؟ </a>
					</h4>
				  </div>
				  <div id="collapseSix" className="card-collapse collapse">
					<div className="card-body">
					  <p>انظر جواب السؤال الرابع من الواضح أن هؤلاء المعفيين من الضريبة ايضا يعملون كعمال</p>
					  <p> لا يحملون الجنسية الاسرائيلية وفرض عليهم ضريبة اضافية بقيمة 8% المنضم لكشف 102 لضريبة أصحاب العمل.</p>
					</div>
				  </div>
				</div>
				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseSeven">لدي عامل الذي يأخذ راتبه الصافي في شهر 7 راتبه المجمل ارتفع بصورة كبيرة, ماذا حصل ؟</a>
					</h4>
				  </div>
				  <div id="collapseSeven" className="card-collapse collapse">
					<div className="card-body">
					  <p>في حال قلت أو بطلت قيمة الفائدة لسكان منطقة في الإصلاح وكبرت ضريبة العامل............</p>
					</div>
				  </div>
				</div>
				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseEight">قرأت في الصحيفة انه منذ بداية شهر  7 توجد تقليصات اقتصادية على العمال , فقد كبر الراتب الصافي لعمالي هل يعقل؟</a>
					</h4>
				  </div>
				  <div id="collapseEight" className="card-collapse collapse">
					<div className="card-body">
					  <p>في إطار البرنامج الاقتصادي تعدلوا وخفضوا درجات الضريبة منذ بداية شهر  2003/1 حيث انه يظهر بالاحتساب ان العامل</p>
					  <p>يحصل على إرجاع من ضريبة الدخل الذي يسبب زيادة في الراتب الصافي للعامل.</p>
					</div>
				  </div>
				</div>

				<div className="card">
				  <div className="card-header">
					<h4 className="card-title">
					  <a className="collapsed" data-toggle="collapse" href="#collapseNine">لدي عمال الذين يأخذون راتب صافي بشكل ثابت وفي شهر 7 راتبهم المجمل كان أصغر بالمقارنة مع شهر 6 هل هذا يعقل ؟</a>
					</h4>
				  </div>
				  <div id="collapseNine" className="card-collapse collapse">
					<div className="card-body">
					  <p>بالطبع نعم , انظروا جواب السؤال رقم 8 أنه يقوم بدمجهم عندما.....
 وعندما توجد فوائد يتمتع صاحب العمل بها</p>
					  <p>حيث انه بهذه الحالة صاحب العمل يتمتع بإرجاع ضريبة العامل والراتب المجمل يقل بالمقارنة مع أشهر ماضية.</p>
					  <p>الراتب الصافي للعامل يبقى بدون تغيير</p>
					</div>
				  </div>
				</div>

			  </div>
			</div>
			{/* /Page Content */}
		  </div>
		)
	}
}

export default LawRecFaq;
