let taxProperty =[
  {
    yearMil:1642291200000,
    date: "16/01/2022",
    band1: {
      rate: 0,
      min: 0,
      max: "1805545"
    },
    band2: {
      min: 1805545,
      rate: 3.5,
      max: 2141605
    },
    band3: {
      min: 2141605,
      max: 5525070,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 5525070,
      max: 18416900
    },
    band5: {
      min: 18416900,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  {
    yearMil: 1610755200000,
    date: "16/01/2021",
    band1: {
      rate: 0,
      min: 0,
      max: 1747865
    },
    band2: {
      min: 1747865,
      rate: 3.5,
      max: 2073190
    },
    band3: {
      min: 2073190,
      max: 5348565,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 5348565,
      max: 17828555
    },
    band5: {
      min: 17828555,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  {
    yearMil: 1579132800000,
    date: "16/01/2020",
    band1: {
      rate: 0,
      min: 0,
      max: 1744505
    },
    band2: {
      min: 1744505,
      rate: 3.5,
      max: 2069205
    },
    band3: {
      min: 2069205,
      max: 5338290,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 5338290,
      max: 17794305
    },
    band5: {
      min: 17794305,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  {
    yearMil: 1547596800000,
    date: "16/01/2019",
    band1: {
      rate: 0,
      min: 0,
      max: 1696750
    },
    band2: {
      min: 1696750,
      rate: 3.5,
      max: 2012560
    },
    band3: {
      min: 2012560,
      max: 5192150,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 5192150,
      max: 17307170
    },
    band5: {
      min: 17307170,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  {
    yearMil: 1516060800000,
    date: "16/01/2018",
    band1: {
      rate: 0,
      min: 0,
      max: 1664520
    },
    band2: {
      min: 1664520,
      rate: 3.5,
      max: 1974335
    },
    band3: {
      min: 1974335,
      max: 5093535,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 5093535,
      max: 16978450
    },
    band5: {
      min: 16978450,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  {
    yearMil: 1484524800000,
    date: "16/01/2017",
    band1: {
      rate: 0,
      min: 0,
      max: 1623320
    },
    band2: {
      min: 1623320,
      rate: 3.5,
      max: 1925460
    },
    band3: {
      min: 1925460,
      max: 4967445,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 4967445,
      max: 16558150
    },
    band5: {
      min: 16558150,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  {
    yearMil: 1452902400000,
    date: "16/01/2016",
    band1: {
      rate: 0,
      min: 0,
      max: 1600175
    },
    band2: {
      min: 1600175,
      rate: 3.5,
      max: 1898005
    },
    band3: {
      min: 1898005,
      max: 4896615,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 4896615,
      max: 16322055
    },
    band5: {
      min: 16322055,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  {
    yearMil: 1421366400000,
    date: "16/01/2015",
    band1: {
      rate: 0,
      min: 0,
      max: 1568800
    },
    band2: {
      min: 1568800,
      rate: 3.5,
      max: 1860790
    },
    band3: {
      min: 1860790,
      max: 4800605,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 4800605,
      max: 16002015
    },
    band5: {
      min: 16002015,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  {
    yearMil: 1389830400000,
    date: "16/01/2014",
    band1: {
      rate: 0,
      min: 0,
      max: 1517210
    },
    band2: {
      min: 1517210,
      rate: 3.5,
      max: 1799605
    },
    band3: {
      min: 1799605,
      max: 4642750,
      rate: 5
    },
    band4: {
      rate: 8,
      min: 4642750,
      max: 15475835
    },
    band5: {
      min: 15475835,
      rate: 10,
      max: "أكثر من ذلك"
    }
  },
  
  {
    yearMil:1375315200000,
    date: "01/08/2013",
    band1: {
    max: 1470560,
    min: 0,
    rate: 0
  },
  band2: {
    max: 1744270,
    min: 1470560,
    rate: 3.5
  },
  band3: {
    max: 4500000,
    min: 1744270,
    rate: 5
  },
  band4: {
    max: 15000000,
    min: 4500000,
    rate: 8
  },
  band5: {
    min: 15000000,
    rate: 10,
    max : "أكثر من ذلك"
  },
},
{
  yearMil:1358294400000,
  date: "16/01/2013",
  band1: {
    max: 1470560,
    min: 0,
    rate: 0
  },
  band2: {
    max: 1744270,
    min: 1470560,
    rate: 3.5
  },
  band3: {
    max: "أكثر من ذلك",
    min: 1744270,
    rate: 5
  },
  band4: {
    max: "",
    min: "",
    rate: ""
  },
  band5: {
    min: "",
    rate: ""
  },
},
  
  {
    yearMil: 1326672000000,
    date: "16/01/2012",
    band1: {
      rate: 0,
      min: 0,
      max: 1421760
    },
    band2: {
      min: 1421760,
      rate: 3.5,
      max: 1686395
    },
    band3: {
      min: 1686395,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1298246400000,
    date: "21/02/2011",
    band1: {
      rate: 0,
      min: 0,
      max: 1350000
    },
    band2: {
      min: 1350000,
      rate: 3.5,
      max: 1601210
    },
    band3: {
      min: 1601210,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1295136000000,
    date: "16/01/2011",
    band1: {
      rate: 0,
      min: 0,
      max: 1139320
    },
    band2: {
      min: 1139320,
      rate: 3.5,
      max: 1601210
    },
    band3: {
      min: 1601210,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1263600000000,
    date: "16/01/2010",
    band1: {
      rate: 0,
      min: 0,
      max: 1084935
    },
    band2: {
      min: 1084935,
      rate: 3.5,
      max: 1524770
    },
    band3: {
      min: 1524770,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1232064000000,
    date: "16/01/2009",
    band1: {
      rate: 0,
      min: 0,
      max: 1026660
    },
    band2: {
      min: 1026660,
      rate: 3.5,
      max: 1442870
    },
    band3: {
      min: 1442870,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1200441600000,
    date: "16/01/2008",
    band1: {
      rate: 0,
      min: 0,
      max: 907700
    },
    band2: {
      min: 907700,
      rate: 3.5,
      max: 1275690
    },
    band3: {
      min: 1275690,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1193875200000,
    date: "01/11/2007",
    band1: {
      rate: 0,
      min: 0,
      max: 925000
    },
    band2: {
      min: 925000,
      rate: 3.5,
      max: 1300000
    },
    band3: {
      min: 1300000,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1185926400000,
    date: "01/08/2007",
    band1: {
      rate: 0,
      min: 0,
      max: 850000
    },
    band2: {
      min: 850000,
      rate: 3.5,
      max: 1300000
    },
    band3: {
      min: 1300000,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1184544000000,
    date: "16/07/2007",
    band1: {
      rate: 0,
      min: 0,
      max: 602260
    },
    band2: {
      min: 602260,
      rate: 3.5,
      max: 746820
    },
    band3: {
      min: 746820,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1176681600000,
    date: "16/04/2007",
    band1: {
      rate: 0,
      min: 0,
      max: 600400
    },
    band2: {
      min: 600400,
      rate: 3.5,
      max: 744520
    },
    band3: {
      min: 744520,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1168905600000,
    date: "16/01/2007",
    band1: {
      rate: 0,
      min: 0,
      max: 557420
    },
    band2: {
      min: 557420,
      rate: 3.5,
      max: 691240
    },
    band3: {
      min: 691240,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1160956800000,
    date: "16/10/2006",
    band1: {
      rate: 0,
      min: 0,
      max: 575490
    },
    band2: {
      min: 575490,
      rate: 3.5,
      max: 713625
    },
    band3: {
      min: 713625,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1153008000000,
    date: "16/07/2006",
    band1: {
      rate: 0,
      min: 0,
      max: 574865
    },
    band2: {
      min: 574865,
      rate: 3.5,
      max: 712850
    },
    band3: {
      min: 712850,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1145145600000,
    date: "16/04/2006",
    band1: {
      rate: 0,
      min: 0,
      max: 596050
    },
    band2: {
      min: 596050,
      rate: 3.5,
      max: 739120
    },
    band3: {
      min: 739120,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1137369600000,
    date: "16/01/2006",
    band1: {
      rate: 0,
      min: 0,
      max: 594795
    },
    band2: {
      min: 594795,
      rate: 3.5,
      max: 737550
    },
    band3: {
      min: 737550,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1129420800000,
    date: "16/10/2005",
    band1: {
      rate: 0,
      min: 0,
      max: 581725
    },
    band2: {
      min: 581725,
      rate: 3.5,
      max: 721345
    },
    band3: {
      min: 721345,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1121472000000,
    date: "16/07/2005",
    band1: {
      rate: 0,
      min: 0,
      max: 559350
    },
    band2: {
      min: 559350,
      rate: 3.5,
      max: 693600
    },
    band3: {
      min: 693600,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1120176000000,
    date: "01/07/2005",
    band1: {
      rate: 0,
      min: 0,
      max: 550000
    },
    band2: {
      min: 550000,
      rate: 3.5,
      max: 682020
    },
    band3: {
      min: 682020,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1113609600000,
    date: "16/04/2005",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 439430
    },
    band2: {
      min: 439430,
      rate: 3.5,
      max: 682020
    },
    band3: {
      min: 682020,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1105833600000,
    date: "16/01/2005",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 444910
    },
    band2: {
      min: 444910,
      rate: 3.5,
      max: 690530
    },
    band3: {
      min: 690530,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1097884800000,
    date: "16/10/2004",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 464315
    },
    band2: {
      min: 464315,
      rate: 3.5,
      max: 720650
    },
    band3: {
      min: 720650,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1089936000000,
    date: "16/07/2004",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 463315
    },
    band2: {
      min: 463315,
      rate: 3.5,
      max: 719095
    },
    band3: {
      min: 719095,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1082073600000,
    date: "16/04/2004",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 456855
    },
    band2: {
      min: 456855,
      rate: 3.5,
      max: 709065
    },
    band3: {
      min: 709065,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1074211200000,
    date: "16/01/2004",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 456855
    },
    band2: {
      min: 456855,
      rate: 3.5,
      max: 709065
    },
    band3: {
      min: 709065,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1072915200000,
    date: "01/01/2004",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 464815
    },
    band2: {
      min: 464815,
      rate: 3.5,
      max: 721425
    },
    band3: {
      min: 721425,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1066262400000,
    date: "16/10/2003",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 464815
    },
    band2: {
      min: 464815,
      rate: 3.5,
      max: 721425
    },
    band3: {
      min: 721425,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1058313600000,
    date: "16/07/2003",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 457855
    },
    band2: {
      min: 457855,
      rate: 3.5,
      max: 710620
    },
    band3: {
      min: 710620,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1050451200000,
    date: "16/04/2003",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 494685
    },
    band2: {
      min: 494685,
      rate: 3.5,
      max: 767785
    },
    band3: {
      min: 767785,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1042675200000,
    date: "16/01/2003",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 491245
    },
    band2: {
      min: 491245,
      rate: 3.5,
      max: 762450
    },
    band3: {
      min: 762450,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1041379200000,
    date: "01/01/2003",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 499385
    },
    band2: {
      min: 499385,
      rate: 3.5,
      max: 775075
    },
    band3: {
      min: 775075,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1034726400000,
    date: "16/10/2002",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 499385
    },
    band2: {
      min: 499385,
      rate: 3.5,
      max: 775075
    },
    band3: {
      min: 775075,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1026777600000,
    date: "16/07/2002",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 516940
    },
    band2: {
      min: 516940,
      rate: 3.5,
      max: 802325
    },
    band3: {
      min: 802325,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1018915200000,
    date: "16/04/2002",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 491680
    },
    band2: {
      min: 491680,
      rate: 3.5,
      max: 763125
    },
    band3: {
      min: 763125,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1011139200000,
    date: "16/01/2002",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 454460
    },
    band2: {
      min: 454460,
      rate: 3.5,
      max: 705350
    },
    band3: {
      min: 705350,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1005091200000,
    date: "07/11/2001",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 454895
    },
    band2: {
      min: 454895,
      rate: 3.5,
      max: 706030
    },
    band3: {
      min: 706030,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 1003190400000,
    date: "16/10/2001",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 454895
    },
    band2: {
      min: 454895,
      rate: 3.5,
      max: 706030
    },
    band3: {
      min: 706030,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 995241600000,
    date: "16/07/2001",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 440340
    },
    band2: {
      min: 440340,
      rate: 3.5,
      max: 683440
    },
    band3: {
      min: 683440,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 987379200000,
    date: "16/04/2001",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 438620
    },
    band2: {
      min: 438620,
      rate: 3.5,
      max: 680770
    },
    band3: {
      min: 680770,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 979603200000,
    date: "16/01/2001",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 430530
    },
    band2: {
      min: 430530,
      rate: 3.5,
      max: 668210
    },
    band3: {
      min: 668210,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 971654400000,
    date: "16/10/2000",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 427050
    },
    band2: {
      min: 427050,
      rate: 3.5,
      max: 662810
    },
    band3: {
      min: 662810,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 963705600000,
    date: "16/07/2000",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 433575
    },
    band2: {
      min: 433575,
      rate: 3.5,
      max: 672935
    },
    band3: {
      min: 672935,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 955843200000,
    date: "16/04/2000",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 420500
    },
    band2: {
      min: 420500,
      rate: 3.5,
      max: 652645
    },
    band3: {
      min: 652645,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 947980800000,
    date: "16/01/2000",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 443605
    },
    band2: {
      min: 443605,
      rate: 3.5,
      max: 688505
    },
    band3: {
      min: 688505,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 946684800000,
    date: "01/01/2000",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 459400
    },
    band2: {
      min: 459400,
      rate: 3.5,
      max: 713015
    },
    band3: {
      min: 713015,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 940032000000,
    date: "16/10/1999",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 459400
    },
    band2: {
      min: 459400,
      rate: 3.5,
      max: 713015
    },
    band3: {
      min: 713015,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 932083200000,
    date: "16/07/1999",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 434010
    },
    band2: {
      min: 434010,
      rate: 3.5,
      max: 673615
    },
    band3: {
      min: 673615,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 924220800000,
    date: "16/04/1999",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 424855
    },
    band2: {
      min: 424855,
      rate: 3.5,
      max: 659405
    },
    band3: {
      min: 659405,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 916444800000,
    date: "16/01/1999",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 452285
    },
    band2: {
      min: 452285,
      rate: 3.5,
      max: 701975
    },
    band3: {
      min: 701975,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 908496000000,
    date: "16/10/1998",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 440970
    },
    band2: {
      min: 440970,
      rate: 3.5,
      max: 684420
    },
    band3: {
      min: 684420,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 900547200000,
    date: "16/07/1998",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 428570
    },
    band2: {
      min: 428570,
      rate: 3.5,
      max: 665170
    },
    band3: {
      min: 665170,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 892684800000,
    date: "16/04/1998",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 424875
    },
    band2: {
      min: 424875,
      rate: 3.5,
      max: 659430
    },
    band3: {
      min: 659430,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 884908800000,
    date: "16/01/1998",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 419435
    },
    band2: {
      min: 419435,
      rate: 3.5,
      max: 650990
    },
    band3: {
      min: 650990,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 876960000000,
    date: "16/10/1997",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 423135
    },
    band2: {
      min: 423135,
      rate: 3.5,
      max: 656730
    },
    band3: {
      min: 656730,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 869011200000,
    date: "16/07/1997",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 415300
    },
    band2: {
      min: 415300,
      rate: 3.5,
      max: 644575
    },
    band3: {
      min: 644575,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 861148800000,
    date: "16/04/1997",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 405510
    },
    band2: {
      min: 405510,
      rate: 3.5,
      max: 629380
    },
    band3: {
      min: 629380,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 853372800000,
    date: "16/01/1997",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 391370
    },
    band2: {
      min: 391370,
      rate: 3.5,
      max: 607435
    },
    band3: {
      min: 607435,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 845424000000,
    date: "16/10/1996",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 378100
    },
    band2: {
      min: 378100,
      rate: 3.5,
      max: 586835
    },
    band3: {
      min: 586835,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 837475200000,
    date: "16/07/1996",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 385060
    },
    band2: {
      min: 385060,
      rate: 3.5,
      max: 597640
    },
    band3: {
      min: 597640,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 829612800000,
    date: "16/04/1996",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 359175
    },
    band2: {
      min: 359175,
      rate: 3.5,
      max: 557460
    },
    band3: {
      min: 557460,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 821750400000,
    date: "16/01/1996",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 344600
    },
    band2: {
      min: 344600,
      rate: 3.5,
      max: 534840
    },
    band3: {
      min: 534840,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 813801600000,
    date: "16/10/1995",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 263495
    },
    band2: {
      min: 263495,
      rate: 3.5,
      max: 511205
    },
    band3: {
      min: 511205,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 805852800000,
    date: "16/07/1995",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 252705
    },
    band2: {
      min: 252705,
      rate: 3.5,
      max: 490270
    },
    band3: {
      min: 490270,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 797990400000,
    date: "16/04/1995",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 245045
    },
    band2: {
      min: 245045,
      rate: 3.5,
      max: 475410
    },
    band3: {
      min: 475410,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 790214400000,
    date: "16/01/1995",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 240000
    },
    band2: {
      min: 240000,
      rate: 3.5,
      max: 465620
    },
    band3: {
      min: 465620,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 782265600000,
    date: "16/10/1994",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 182715
    },
    band2: {
      min: 182715,
      rate: 3.5,
      max: 452790
    },
    band3: {
      min: 452790,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 774316800000,
    date: "16/07/1994",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 173180
    },
    band2: {
      min: 173180,
      rate: 3.5,
      max: 429150
    },
    band3: {
      min: 429150,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 766454400000,
    date: "16/04/1994",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 158870
    },
    band2: {
      min: 158870,
      rate: 3.5,
      max: 393700
    },
    band3: {
      min: 393700,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 758678400000,
    date: "16/01/1994",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 147035
    },
    band2: {
      min: 147035,
      rate: 3.5,
      max: 364380
    },
    band3: {
      min: 364380,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 750729600000,
    date: "16/10/1993",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 138860
    },
    band2: {
      min: 138860,
      rate: 3.5,
      max: 344120
    },
    band3: {
      min: 344120,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 742780800000,
    date: "16/07/1993",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 136560
    },
    band2: {
      min: 136560,
      rate: 3.5,
      max: 338410
    },
    band3: {
      min: 338410,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 734918400000,
    date: "16/04/1993",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 131875
    },
    band2: {
      min: 131875,
      rate: 3.5,
      max: 326810
    },
    band3: {
      min: 326810,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 727056000000,
    date: "15/01/1993",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 117100
    },
    band2: {
      min: 117100,
      rate: 3.5,
      max: 290190
    },
    band3: {
      min: 290190,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 719193600000,
    date: "16/10/1992",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 118135
    },
    band2: {
      min: 118135,
      rate: 3.5,
      max: 292760
    },
    band3: {
      min: 292760,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 711244800000,
    date: "16/07/1992",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 113070
    },
    band2: {
      min: 113070,
      rate: 3.5,
      max: 280205
    },
    band3: {
      min: 280205,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 695520000000,
    date: "16/01/1992",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 113300
    },
    band2: {
      min: 113300,
      rate: 3.5,
      max: 280775
    },
    band3: {
      min: 280775,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 679622400000,
    date: "16/07/1991",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 98485
    },
    band2: {
      min: 98485,
      rate: 3.5,
      max: 224060
    },
    band3: {
      min: 224060,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 671760000000,
    date: "16/04/1991",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 91730
    },
    band2: {
      min: 91730,
      rate: 3.5,
      max: 227320
    },
    band3: {
      min: 227320,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 663984000000,
    date: "16/01/1991",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 89005
    },
    band2: {
      min: 89005,
      rate: 3.5,
      max: 220565
    },
    band3: {
      min: 220565,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  },
  {
    yearMil: 648086400000,
    date: "16/07/1990",
    band1: {
      rate: 0.5,
      min: 0.5,
      max: 76880
    },
    band2: {
      min: 76880,
      rate: 3.5,
      max: 190510
    },
    band3: {
      min: 190510,
      max: "أكثر من ذلك" ,
      rate: 5
    },
    band4: {
      rate: "",
      min: "",
      max: ""
    },
    band5: {
      min: "",
      rate: ""
    }
  }
]
export default{ taxProperty }
