import React, { useState, useEffect } from "react";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
import html2canvas from 'html2canvas';
import { FaInfo } from "react-icons/fa";
import { jsPDF } from 'jspdf';

import { massportallogo } from "../../../../../Entryfile/imagepath"
import { HandClick } from "tabler-icons-react";


export function RentPage(props) {
  const printRef = React.useRef();
  const [isActive,setIsActive] = useState(false);
  const [day,setDay] = useState('الأحد');
  const [year,setYear] = useState('');
  const [coin,setCoin] = useState(' شيكل إسرائيلي ');
  const [dateStart,setDateStart] = useState('');
  const [dateFinish,setDateFinish] = useState('');
  const [date,setDate] = useState('');
  const [city,setCity] = useState('القدس');
  const [streetName,setStreetName] = useState('');
  const [streetNumber,setStreetNumber] = useState('');
  const [town,setTown] = useState('القدس');
  const [price,setPrice] = useState('');
  const [lessorName,setLessorName] = useState('');
  const [renterName,setRenterName] = useState('');
  const [lessorNationality,setLessorNationality] = useState('');
  const [lessorSignature,setLessorSignature] = useState('');
  const [renterNationality,setRenterNationality] = useState('');
  const [renterSignature,setRenterSignature] = useState('');
  const [lessorNumber,setLessorNumber] = useState('');
  const [renterNumber,setRenterNumber] = useState('');
  function addZero(i) {
    if (i < 10) {i = "0" + i}
    return i;
  }
  const date1 = new Date();
  let h = addZero(date1.getHours());
  let m = addZero(date1.getMinutes());
  let s = addZero(date1.getSeconds());
  let time = h + ":" + m + ":" + s;
  const d = new Date().toLocaleString() + "";
    let current = d.split(',');
    const today = current[0];
    const todaySplitArr = today.split('/');
    const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
  
    const handleSubmit = (evt) => {
  
      evt.preventDefault();
      if(lessorName ==''|| lessorName ==' ' ){
        setLessorName('______________')
     }
     if(renterName ==''|| renterName ==' ' ){
        setRenterName('______________')
     }
     if(day ==''|| day ==' ') {
        setDay('______________')
     }
     if(date==''|| date ==' ' ){
        setDate('______________')
     }
     if(city ==''|| city ==' ' ){
        setCity('______________')
     }
     if(town ==''|| town ==' ' ){
        setTown('______________')
     }
     if(streetName ==''|| streetName ==' ' ){
        setStreetName('______________')
     }
     if(streetNumber ==''|| streetNumber ==' ' ){
        setStreetNumber('______________')
     }
     if(lessorNationality ==''|| lessorNationality ==' ' ){
        setLessorNationality('______________')
     }
     if(lessorNumber ==''|| lessorNumber ==' ' ){
        setLessorNumber('______________')
     }
     if(lessorSignature ==''|| lessorSignature ==' ' ){
        setLessorSignature('______________')
     }
     if(dateStart ==''|| dateStart ==' ' ){
        setDateStart('______________')
     }
     if(dateFinish==''|| dateFinish ==' ' ){
        setDateFinish('______________')
     }
     if(renterNationality ==''|| renterNationality ==' ' ){
        setRenterNationality('______________')
     }
     if(renterNumber ==''|| renterNumber ==' ' ){
        setRenterNumber('______________')
     }
     if(renterSignature ==''|| renterSignature ==' ' ){
        setRenterSignature('______________')
     }
     if(price ==''|| price ==' ' ){
        setPrice('______________')
     }
     
      
     
    
    
 }
 const click = (e) =>{
  setIsActive(!isActive)
 } 

  const handleDownloadPdf = async () => {
    
    const iaactive = true;
    await setIsActive(iaactive);
    const element = printRef.current;
   
  
   
    //  Current Date 
    
    // Current Date 
  
    // PDF Header And Footer Values 
    const header = todayFrm;
  
  
    
    const canvas = await html2canvas(element);
  
    element.style.background = '#FFFFFF';
    element.style.color = '#212529';
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
  
  
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
  
  
    // pdf.addImage(data, 'PNG', 0, 10, pdfWidth, pdfHeight);
    // // Header
    // pdf.text(header, 2, 15, { baseline: 'top' });
    // // Footer
      //  Header  - Logo
   

    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 5, pdfWidth, pdfHeight);
    pdf.addImage(massportallogo, 'png', 2, -2, 20, 20);
    // Header - Date 
    // pdf.text(header, 10, 40, { baseline: 'top' });
   
    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
  
    const footer = todayFrm + '   '+ time + '             www.mass-portal.com';
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight-3, { baseline: 'bottom' });
    pdf.setFontSize(8);
    pdf.save(' عقد إيجار .pdf');
  };

  return(

   <div className="row" >
      <div className="col-xl-12 d-flex  " >
        <div className="card flex-fill">
         
            <form onSubmit={handleSubmit}>
              <div className="form-group row" >
                
               
                <div className=" col-sm-12 col-md-12">
                
                <div className="col-sm-11 mt-3  text-right "  >
                    <button className="btn   " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                      <AiOutlineFilePdf size={30} className='privacy ' />
                    </button>
                    <button className="btn " onClick={click} data-tip data-for="excel-tip">
                      <SiMicrosoftexcel size={30} className='privacy ' />
                    </button>
               
              </div>
                </div>
                

              </div>
            </form>
           
            {/* <div className="form-group row">
                <div className="col-lg-5">
                <label className="col-lg-6 col-form-label">التوقيع</label>
                <input type="text" 
                  className="form-control col-lg-6 "
                  onChange={(e) => setLessorSignature(e.target.value)}
                 />
               
               
              </div>

                <label className="col-lg-2 col-form-label"></label>
                <div className="col-lg-5">
                <label className="col-lg-6 col-form-label">التوقيع</label>
                <input type="text" 
                  className="form-control col-lg-6 "
                  onChange={(e) => setRenterSignature(e.target.value)}
                 />
               
               
              </div>
                
                
                </div> */}
            
       {!isActive && <div className=""  ref={printRef}>
       <div className="card-body  "  >
          <div  className="col-sm-12 col-md-12 form-group row ">
          <h4 className="card-title mb-0 privacy  text-center ">عقد ايجار </h4>
          <br></br> <br></br><br></br> 
          <form>
              
                <div className=" form-group row">
                <div className="col-sm-12 col-md-12">
                    <span className="col-lg-2 ">
              إنه في يوم </span><select  className="  col-lg-2 select-zq form-control-zq"
                  onChange={(e) => setDay(e.target.value)}>
                    <option className="option-zq" value={"الأحد"}>الأحد</option>
                    <option className="option-zq"   value={"الاثنين"}>الاثنين</option>
                    <option  className="option-zq" value={" الثلاثاء"}>الثلاثاء</option>
                    <option className="option-zq"  value={" الأربعاء"}>الأربعاء</option>
                    <option className="option-zq"  value={" الخميس"}>الخميس</option>
                    <option className="option-zq"  value={" الجمعة"}>الجمعة</option>
                    <option  className="option-zq" value={"السبت"}>السبت</option>
                    
                    
                    
                    </select> <span className="col-lg-2  " >الموافق </span> <input  type="date" 
                  className="    col-lg-2  form-control-zq"
                  onChange={(e) => setDate(e.target.value)}></input> <span>م</span> بمدينة <input  type="text" 
                  className="   col-lg-2 form-control-zq" placeholder="القدس" value={city}
                  onChange={(e) => setCity(e.target.value)}></input> جرى إبرام هذا العقد والتوقيع عليه بين كل من :- 
                  </div></div>
                  </form>
              <br></br>
              <br></br>
              <ol className="privacy-ol">
                <li >
                   السيد/ة <input  type="text" 
                  className="form-control-zq"
                  onChange={(e) => setLessorName(e.target.value)}></input> , <input type="text" 
                  className="form-control-zq"
                  onChange={(e) => setLessorNationality(e.target.value)}></input> الجنسية , بموجب السجل المدني رقم <input type="number" 
                  className="form-control-zq"
                  onChange={(e) => setLessorNumber(e.target.value)}></input> والمعبر عنه بالمؤجر . 
                </li>
                <br></br>
                
                <li >
                السيد/ة <input type="text" 
                  className="form-control-zq"
                  onChange={(e) => setRenterName(e.target.value)}></input> , <input
                  type="text" 
                  className="form-control-zq"
                  onChange={(e) => setRenterNationality(e.target.value)}></input> الجنسية , بموجب السجل المدني رقم <input type="number " 
                  className="form-control-zq"
                  onChange={(e) => setRenterNumber(e.target.value)}></input> والمعبر عنه بالمستأجر . 
                </li>
                
              </ol>

           
          </div> 
        </div>
        <div className="card-header">
            <h4 className="card-title mb-0 privacy ">شروط العقد  </h4>
            
        </div>
        <div className="card-body ">
          <div  className="col-sm-12 col-md-12">
          
           وبعد أن أقر الطرفان بأهليتهما الشرعية والنظامية المعتبرة شرعا اتفقا على الشروط ألأتيه :-
           <br></br>
           <br></br>
           <ol className="privacy-ol">
            <li > أجر المؤجر على المستأجر بموجب هذا العقد والمعبر عنها هنا بالعين المؤجرة الواقعة بشارع  <input type="text"  placeholder="اسم الشارع"
                  className="form-control-zq"
                  onChange={(e) => setStreetName(e.target.value)}></input>  رقم  <input  type="text" placeholder="رقم الشارع"
                  className="form-control-zq"
                  onChange={(e) => setStreetNumber(e.target.value)}></input>  بمدينة <input  type="text" 
                  className="form-control-zq" placeholder="القدس" value={town}
                  onChange={(e) => setTown(e.target.value)}></input> وذلك لاستعمالها .
              <br></br>
              
              </li>
              
              <br></br>
                <li >
                   إن هذا الإيجار معقود لمدة  <select  className="  col-lg-2 select-zq form-control-zq"
                  onChange={(e) => setYear(e.target.value)}>
                    <option className="option-zq" value={"نصف سنة"}>نصف سنة</option>
                    <option className="option-zq" value={"سنة واحدة"}>سنة واحدة</option>
                    <option className="option-zq" value={" سنتان"}>سنتان</option>
                    <option className="option-zq" value={" 3 سنوات"}>3 سنوات</option>
                    <option className="option-zq" value={" 4 سنوات"}>4 سنوات</option>
                    <option className="option-zq" value={" 5 سنوات"}>5 سنوات</option>
                    
                    
                    
                    
                    </select> تبدأ في <input  type="date" 
                  className="form-control-zq"  onChange={(e) => setDateStart(e.target.value)}
                ></input> وتنتهي في  <input  type="date" 
                  className="form-control-zq"  onChange={(e) => setDateFinish(e.target.value)}
                 ></input>
                </li>
                <br></br>
                
                <li >
                  إن القيمة ألإيجاريه السنوية مبلغ وقدره <input type="number" 
                  className="form-control-zq" onChange={(e) => setPrice(e.target.value)}
                  ></input> <select className="select-zq form-control-zq" onChange={(e) => setCoin(e.target.value)}>
                    <option value="شيكل إسرائيلي ">شيكل إسرائيلي ( ILS )</option> <option value="دينار أردني ">دينار أردني ( JOD )</option> <option value="دولار الأمريكي">دولار الأمريكي ( USD )</option> <option value="يورو">يورو ( EUR )</option></select> يلتزم المستأجر بسداد نصف القيمة ألإيجاريه للمؤجر  كل ستة أشهر بموجب إيصالات موقعه منه أو من ينوب عنه وهذه الإيصالات وحدها وسيلة إثبات دفع ألأجره عند حدوث نزاع فيها  
                </li><br></br>
                <li >
                   يقر المستأجر أنه عاين العين المؤجرة المعاينة النافية للجهالة ووجد أن حالتها الحاضرة سليمة وصالحه للاستعمال وخاليه من أي عيب .
                </li><br></br>
                <li >
                 لا يحق للمستأجر إجراء أي تغيير في الواجهات الخارجية أو الترتيبات الداخلية إلا بعد الحصول على موافقة خطيه من المؤجر أو من ينوب عنه وإلا كان على نفقته إعادة العين المؤجرة إلى حالتها التي كانت عليها إما فوراً وإما عند انتهاء عقد الإيجار وتسليم العين المؤجرة وهذا الاختيار حسب رغبة المؤجر . وكذا عند نهاية الإيجار لا يحق للمستأجر أن يجري أي تبديل أو تغيير تم بموافقة المؤجر الخطية ولا أن ينتزع أي وضع أو تركيب أحدثه إلا وفقاً للشروط المتفق عليها عند إحداث التغيير هذا وليس للمستأجر حق المطالبة بأي تعويض من المؤجر نظير التحسينات والتعديلات والمصروفات الواقعة على العين المؤجرة - محل العقد - علماً بأن كل الإصلاحات والتغييرات التي سيوافق عليها المؤجر خطياً تكون على نفقة المستأجر بكاملها .
                </li><br></br>
                <li >
                 إن ثمن الماء والتيار الكهربائي و الصرف الصحي ( شفط البيارة ) وكافة المنافع والخدمات الأخرى التي تقدم إلى المستأجر فيما يتعلق بالعين المؤجرة على نفقة المستأجر بكاملها .

                </li><br></br>
                <li >
                لا يحق للمستأجر تأجير العين المؤجرة على الغير ولا التنازل عن عقد الإيجار إلى الغير إلا بإذن خطي من المؤجر .

                </li><br></br>
                <li >
                 كل حريق يحدث في العين المؤجرة يجعل المستأجر مسئولاً عنه وعن نتائجه المباشرة وغير المباشرة وعن التعويضات عما لحق العين المؤجرة وما يجاورها من أضرار .

                </li><br></br>
                <li >
                 كل تغيير في شروط هذا العقد لا يعتد بها إلا إذا كانت بتوقيع المؤجر وبموافقته الخطية ، وكل إيصال بدفع الأجرة أو قسط منها أو أي حق للمؤجر ولا يكون بتوقيعه أو من ينوب عنه لا يعتد به .

                </li><br></br>
                <li >
                 عند إخلاء العين المؤجرة على المستأجر تسليمها إلى المؤجر بحاله سليمة وصالحه للاستعمال كما استلمها بموجب أمر التسليم وإلا فعليه إصلاح أو دفع قيمة كل ما تسبب فيه من أضرار بالعين المؤجرة نتيجة استعماله لها .

                </li><br></br>
                <li >
                المؤجر غير مسئول عن الأسباب الطارئة بسبب سد المجاري أو سقوط مياه الأمطار أو قطع المياه أو الكهرباء وعلى المستأجر أن يتخذ الإجراءات اللازمة بنفسه أمام الجهات الأخصاص .

                </li><br></br>
                <li >
                 لا يحق للمستأجر حمل أي شيء من أمتعته من العين المؤجرة قبل أن يسدد ما عليه من الإيجار و إستهلاكات الماء والكهرباء وغيرها وإلا كان للمؤجر الحق في إجراء الحجز التحفظي على الأمتعة حتى يتم السداد .

                </li><br></br>
                <li >
                 لا يجوز للمستأجر استعمال العين المؤجرة أو الانتفاع بها إلا بوجود عائلته وفي حالة إخلاله بهذا الشرط خلال مدة سريان العقد يحق للمؤجر فسخ عقد الإيجار دون الرجوع إلى للمستأجر .

                </li><br></br>
                <li >
                 يتم فسخ هذا العقد تلقائياً في حالة مخالفة المستأجر لأياً من التزاماته التعاقدية ودون الحاجة إلى أي إجراء أياً كان نوعه لتمام هذا الفسخ .

                </li><br></br>
                <li >
                 حرر هذا العقد من نسختين موقعتين من قبل الطرفين بيد كل طرف منهما نسخه للعمل بموجبها ويعتبر هذا العقد ساري المفعول من تاريخ التوقيع عليه .

                </li><br></br>
                
              </ol>
              <div  className="text-center">
              <span>
              والله ولي التوفيق,,,,
              </span>
              </div>

          
          </div> 
        </div>
        <div className="card-header">
            <h4 className="card-title mb-0 privacy ">توقيع العقود  </h4>
            
        </div>
        <div className="card-body">
          <form>
            <div className="form-group row">
              <div className="col-sm-12 col-md-12">
                <div className="form-group row">
                <label className="col-lg-5 col-form-label"> الطرف الأول (المؤجر)</label>
                <label className="col-lg-2 col-form-label"></label>
                <label className="col-lg-5 col-form-label"> الطرف الثاني (المستأجر)</label>
                </div>
                <div className="form-group row">
                <div className="col-lg-5">
                <label className="col-lg-12 col-form-label">   الاسم :  <span className="privacy"> {lessorName}</span></label>
               
               
              </div>
                <label className="col-lg-2 col-form-label"></label>
                <div className="col-lg-5">
                <label className="col-lg-12 col-form-label">   الاسم :  <span className="privacy"> {renterName}</span></label>
                
                
              </div>
                </div>
                <div className="form-group row">
                <div className="col-lg-5">
                <label className="col-lg-6 col-form-label">التوقيع</label>
                <input type="text" 
                  className="form-control-zq col-lg-6 "
                  onChange={(e) => setLessorSignature(e.target.value)}
                 />
               
               
              </div>

                <label className="col-lg-2 col-form-label"></label>
                <div className="col-lg-5">
                <label className="col-lg-6 col-form-label">التوقيع</label>
                <input type="text" 
                  className="form-control-zq col-lg-6 "
                  onChange={(e) => setRenterSignature(e.target.value)}
                 />
               
               
              </div>
                
                
                </div>
              </div>
            </div>
          </form>
       

        </div>
        </div> } 
        
        {isActive && <div className=" bg-light text-dark " ref={printRef} >
        <div className="card-body card-body-white-p ">
          <div  className="col-sm-12 col-md-12">
            <h4 className="card-title mb-0 privacy  text-center ">عقد ايجار </h4>
            <hr></hr>
            
           <p className="privacy-black-bold">
              إنه في يوم <label>{day}</label> الموافق <label>{date}</label> <span>م</span> بمدينة <label> {city}</label> جرى إبرام هذا العقد والتوقيع عليه بين كل من :- 
              <br></br>
              
              <ol className="ol-white">
                <li className=" ">
                  1- 
                   السيد <label>{lessorName}</label> , <label>{lessorNationality}</label> الجنسية , بموجب السجل المدني رقم <label>{lessorNumber}</label> والمعبر عنه بالمؤجر . 
                </li>
                
                <li className=" ">
                  2- 
                   السيد <label>{renterName}</label> , <label>{renterNationality}</label> الجنسية , بموجب السجل المدني رقم <label>{renterNumber}</label> والمعبر عنه بالمستأجر . 
                </li>
                
              </ol>

           </p>
          </div> 
        </div>
        <div className="card-header bg-light text-dark card-white-header">
            <h4 className="card-title mb-0 privacy ">شروط العقد  </h4>
        </div>
        <div className="card-body-white ">
          <div  className="col-sm-12 col-md-12 div-white">
           <p className="">
<p className="privacy-black-bold">           
  وبعد أن أقر الطرفان بأهليتهما الشرعية والنظامية المعتبرة شرعا اتفقا على الشروط الأتية :-
</p>
         
           <ol className="ol-white">
<li className="text-center privacy"></li>
            <li className=" ">
              1 - 
               أجر المؤجر على المستأجر بموجب هذا العقد والمعبر عنها هنا بالعين المؤجرة الواقعة بشارع     <label> {streetName} </label>   رقم    <label> {streetNumber} </label>  بمدينة <label>{town}</label> وذلك لاستعمالها .
              
              </li>
              
                <li className=" ">
                  2 - 
                   إن هذا الإيجار معقود لمدة  <span > {year}</span> تبدأ في <label> {dateStart} </label> وتنتهي في <label> {dateFinish}</label>
                </li>
                
                <li className=" ">
                  3 - 
                 إن القيمة ألإيجاريه السنوية مبلغ وقدره <label>{price} ({coin})</label>  يلتزم المستأجر بسداد نصف القيمة ألإيجاريه للمؤجر  كل ستة أشهر بموجب إيصالات موقعه منه أو من ينوب عنه وهذه الإيصالات وحدها وسيلة إثبات دفع ألأجره عند حدوث نزاع فيها . 
                </li>
                <li className="">
                  4 - 
                  يقر المستأجر أنه عاين العين المؤجرة المعاينة النافية للجهالة ووجد أن حالتها الحاضرة سليمة وصالحه للاستعمال وخاليه من أي عيب .
                </li>
                <li className=" ">
                  5 - 
                لا يحق للمستأجر إجراء أي تغيير في الواجهات الخارجية أو الترتيبات الداخلية إلا بعد الحصول على موافقة خطيه من المؤجر أو من ينوب عنه وإلا كان على نفقته إعادة العين المؤجرة إلى حالتها التي كانت عليها إما فوراً وإما عند انتهاء عقد الإيجار وتسليم العين المؤجرة وهذا الاختيار حسب رغبة المؤجر . وكذا عند نهاية الإيجار لا يحق للمستأجر أن يجري أي تبديل أو تغيير تم بموافقة المؤجر الخطية ولا أن ينتزع أي وضع أو تركيب أحدثه إلا وفقاً للشروط المتفق عليها عند إحداث التغيير هذا وليس للمستأجر حق المطالبة بأي تعويض من المؤجر نظير التحسينات والتعديلات والمصروفات الواقعة على العين المؤجرة - محل العقد - علماً بأن كل الإصلاحات والتغييرات التي سيوافق عليها المؤجر خطياً تكون على نفقة المستأجر بكاملها .
                </li>
                <li className=" ">
                  6 - 
                 إن ثمن الماء والتيار الكهربائي و الصرف الصحي ( شفط البيارة ) وكافة المنافع والخدمات الأخرى التي تقدم إلى المستأجر فيما يتعلق بالعين المؤجرة على نفقة المستأجر بكاملها .

                </li>
                <li className=" ">
                  7 - 
                 لا يحق للمستأجر تأجير العين المؤجرة على الغير ولا التنازل عن عقد الإيجار إلى الغير إلا بإذن خطي من المؤجر .

                </li>
                <li className=" ">
                  8 - 
                 كل حريق يحدث في العين المؤجرة يجعل المستأجر مسئولاً عنه وعن نتائجه المباشرة وغير المباشرة وعن التعويضات عما لحق العين المؤجرة وما يجاورها من أضرار .

                </li>
                <li className=" ">
                  9 - 
                 كل تغيير في شروط هذا العقد لا يعتد بها إلا إذا كانت بتوقيع المؤجر وبموافقته الخطية ، وكل إيصال بدفع الأجرة أو قسط منها أو أي حق للمؤجر ولا يكون بتوقيعه أو من ينوب عنه لا يعتد به .

                </li>
                <li className=" ">
                  10- 
                 عند إخلاء العين المؤجرة على المستأجر تسليمها إلى المؤجر بحاله سليمة وصالحه للاستعمال كما استلمها بموجب أمر التسليم وإلا فعليه إصلاح أو دفع قيمة كل ما تسبب فيه من أضرار بالعين المؤجرة نتيجة استعماله لها .

                </li>
                <li className=" ">
                  11- 
                 المؤجر غير مسئول عن الأسباب الطارئة بسبب سد المجاري أو سقوط مياه الأمطار أو قطع المياه أو الكهرباء وعلى المستأجر أن يتخذ الإجراءات اللازمة بنفسه أمام الجهات الأخصاص .

                </li>
                <li className=" ">
                  12- 
                 لا يحق للمستأجر حمل أي شيء من أمتعته من العين المؤجرة قبل أن يسدد ما عليه من الإيجار و إستهلاكات الماء والكهرباء وغيرها وإلا كان للمؤجر الحق في إجراء الحجز التحفظي على الأمتعة حتى يتم السداد .

                </li>
                <li className=" ">
                  13- 
                 لا يجوز للمستأجر استعمال العين المؤجرة أو الانتفاع بها إلا بوجود عائلته وفي حالة إخلاله بهذا الشرط خلال مدة سريان العقد يحق للمؤجر فسخ عقد الإيجار دون الرجوع إلى للمستأجر .

                </li>
                <li className="">
                  14 
                 يتم فسخ هذا العقد تلقائياً في حالة مخالفة المستأجر لأياً من التزاماته التعاقدية ودون الحاجة إلى أي إجراء أياً كان نوعه لتمام هذا الفسخ .

                </li>
                <li className=" ">
                  15- 
                 حرر هذا العقد من نسختين موقعتين من قبل الطرفين بيد كل طرف منهما نسخه للعمل بموجبها ويعتبر هذا العقد ساري المفعول من تاريخ التوقيع عليه .

                </li>
                
              </ol>
              <div  className="text-center privacy-black">
              <span>
              والله ولي التوفيق,,,,
              </span>
              </div>

           </p>
          </div> 
        </div>
        <div className="card-header bg-light text-dark card-white-header-2 div-white">
            <h4 className="card-title mb-0 privacy ">توقيع العقود  </h4>
            
        </div>
        <div className="card-body div-white">
          <form className="sign-fomr-white">
            <div className="black-bold  col-lg-12 col-form-label ">
              <div className="col-sm-12 col-md-12">
                <div className="black-bold  col-lg-12 col-form-label">
                <label className="col-lg-5 col-5  col-sm-5 col-form-label"> الطرف الأول (المؤجر)</label>
                <label className="col-lg-2  col-2  col-sm-2 col-form-label"></label>
                <label className="col-lg-5 col-5  col-sm-5 col-form-label"> الطرف الثاني (المستأجر)</label>
                </div>
                <div className="black-bold  col-lg-12 col-sm-12 col-form-label">
                
                <label className="col-lg-5 col-5 col-sm-5 col-form-label">   الاسم :  <span > {lessorName}</span></label>
               
              
                <label className="col-lg-2 col-2 col-sm-2 col-form-label"></label>
                
                <label className="col-lg-5 col-5 col-sm-5 col-form-label">   الاسم :  <span > {renterName}</span></label>
                
                
              
                </div>
                <div className="black-bold  col-lg-12 col-sm-12 col-form-label">
                
                <label className="col-lg-5 col-5 col-sm-5 col-form-label">  التوقيع : <span>{lessorSignature}</span></label>
                
               
               
             

                <label className="col-lg-2 col-2 col-sm-2 col-form-label"></label>
                
                <label className="col-lg-5 col-5 col-sm-5 col-form-label"> التوقيع : <span>{renterSignature}</span></label>
                
               
               
              
                
                
                </div>
                
              </div>
            </div>
          </form>
       

        </div>
          
          
          </div>}

  
        </div>
      </div>
    </div>
   


)
} 