import React,{useState} from 'react'
import TableInvoice from './TableInvoice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DOMPurify from 'dompurify';
import { BiShekel, } from 'react-icons/bi'
import { MassBLogo, massportallogo } from '../../Entryfile/imagepath';
export default function InvoiceStyle({
    lang,
    DEAR,
    TAXNUMBER,
    TAXINVOICENUMBER,
    DATE,
    PAYUNTIL,
    TOTAL,
    NOTE,
    SIGNATURE,
    ORIGINAL,
    name,
    printRef,
    taxNum,
    address,
    cityAddress,
    phone,
    email,
    logo,
    coData,
    revenues,
    revenuesExempt,
    expenses,
    fixedExpenses,
    list,
    amounts,
    tax,
    discount,
    total,
    information,
    totalAfDiscount,
    perTax,
    dueDate,
    totalAfTax,
    exemptionAmount,
    headerDetails,
    handleDownloadPdf,
    handleSubmit,
    show,
    setShow,
    invoiceNumber,
    
    dateInvoice,
    showModal,
    setShowModal

}) {

    //MODAL
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const createMarkup = (html) => {
      return {
          __html: DOMPurify.sanitize(html)
      }
    }
  return (
    <div>
        <Modal show={showModal} onHide={0}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>تم إنشاء الفاتورة</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal} href="/invoices-create">
            إنشاء فاتورة جديدة
          </Button>
          <Button variant="info" onClick={handleCloseModal} href="/" >
            عودة للصفحة الرئيسية
          </Button>
        </Modal.Footer>
      </Modal>
        <div    className={lang ==='en' ? "my-animation invoice-en-mk": "my-animation"} >   {/* Page Content */}
    <div className='invoice-mk' ref={printRef}  >
      {/* Page Header */}
      {/* <div className="header-invoice-mk text-center  ">
        <h1 className='privacy-black privacy-black-bold'>ضريبة قيمة مضافة مفصلة </h1>
      
      </div> */}
      <div className='header-mk '>

      <div className={lang === 'en' ? "header-address-en-mk col-md-4" : "header-address-mk col-md-4"}>
        <h4 className="h-black-mk invoce-co-name-mk" >الشركة : {headerDetails.companyName}</h4>
        {/* <h4 className="h-black-mk" > <span>  الزبون :  </span> {headerDetails.customerName}</h4> */}
        {/* <h4 className="h-black-mk" > {phone}</h4>
        <h4 className="h-black-mk" > {email}</h4> */}
      </div>
      
      <div className='logo-div-mk col-md-3 text-center'>
      <img  src={massportallogo} className="logo" style={{marginTop:"0px", height:"70px", width:"70px"}}/> 
      </div>
      <div className={lang === 'en' ? "header-address-en-mk col-md-4" : "header-address-mk  col-md-4 "} >
        {/* <h4 className="h-black-mk left"  >   التاريخ : {headerDetails.year} - {headerDetails.month}</h4> */}
        <h4 className="h-black-mk left" > نسبة سلفيات ضريبة الدخل : {headerDetails.taxRate?headerDetails.taxRate:0} %</h4>
      </div>
      {/* {coData && <div className={lang === 'en' ? "header-invoice-en-mk" : "header-invoice-mk"}>
        <h2 className='invoce-co-name-mk h-black-mk'> {coData.companyName}</h2>
        <h4 className="h-black-mk" > {coData.companyName} </h4>
        <h4 className="h-black-mk" > {TAXNUMBER} <span>{coData.vatFileNumber}</span></h4>
      <div className='header-adderss-mk'>
        <h4 className="h-black-mk" > {coData.cityName} , {coData.stateName} , {coData.address}</h4>
        <h4 className="h-black-mk" > {coData.phoneNumber}</h4>
        <h4 className="h-black-mk" > {coData.email}</h4>
      </div>
      </div>} */}
     
     
      
        </div>
       <br></br>
      <div className='title-invoice-mk '>
        <div className='right'>
        <h3 className="h-black-mk" > # {headerDetails.companyNumber}</h3>
          
          {/* <h3 className='bold h-black-mk'>{TOTAL} {total.toLocaleString()} <BiShekel /></h3> */}
        </div>
        <div className='center'>
        {/* <h3 className="h-black-mk invoce-co-name-mk bold" >{headerDetails.companyName}</h3> */}
            <h3 className="h-black-mk invoce-co-name-mk bold"> قيود الفترة الضريبية <span className='privacy' style={{marginRight:"6px"}}>  {headerDetails.year} - {headerDetails.month}</span></h3>
          {/* <h3 className='bold h-black-mk'> {TAXINVOICENUMBER} {invoiceNumber }</h3>
          <h4 className="bold h-black-mk">{ORIGINAL} </h4> */}
        </div>
        <div className={lang === 'en' ? "left-en": ""}>
        {/* <h4 className="h-black-mk left"  >  {headerDetails.year} - {headerDetails.month}</h4> */}

          {/* <h4 className="h-black-mk"> <span> {DATE} {headerDetails.year} - {headerDetails.month} </span>{dateInvoice}</h4>
          <h4 className="h-black-mk"> <span>{PAYUNTIL} </span>{dueDate}</h4> */}
        </div>
      </div>
      {/* /Page Header */}
      <div className="">
        <div className="">
          <div
           //onSubmit={handleSubmit}
           >
            {/* <div className="row"> */}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>العميل </label> */}
                  {/* ////<select 
                  className="select select-sales"
                   value={name}
                    onChange={e=>{
                        setName(e.target.value);
                        setValue(e.target.value)
                        }}
                        >
                    {Customers.map((customer,i)=>
                    
                        <option key={customer.id} value={i} >{customer.name}</option>
                    )}
                  </select> */}
{/* 
                    <input className="form-control" type="name" value={name}/>
                </div>
              </div> */}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>رقم الملف الضريبي  </label>
                  <input 
                  className="form-control"
                  value={taxNum}
                  />
                  
                </div>
              </div> */}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>البريد الالكتروني</label> */}
                  {/* /////<input className="form-control" type="email" value={email}/> */}
                  {/* <input className="form-control" type="email" value={email}/>

                </div>
              </div> */}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>ضريبة </label>
                  <input className="form-control" value={perTax + "%"}/>
                  </div>
                   
              </div> */}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>عنوان العميل </label> */}
                  {/* <textarea className="form-control " rows={1} defaultValue={""} /> */}
                  {/* <input className="form-control " type="text" value={address}/> */}
                  {/* <input className="form-control"  value={address}/>

                
                </div>
              </div> */}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label >عنوان وصول الفاتورة</label> */}
                  {/* <textarea className="form-control" rows={1} defaultValue={""} /> */}
                  {/* <input className="form-control "  value={invoiceAddress === "" ? address: invoiceAddress}/>
                </div>
              </div> */}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>تاريخ الفاتورة  </label>
                  <div className="cal-icon">
                    <input className="form-control datetimepicker" type="text" value={dateInvoice}/>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>تاريخ الاستحقاق </label>
                  <div className="cal-icon">
                    <input className="form-control datetimepicker" type="text" value={date}/>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            <br></br>
            <div className="row ">
              <div className="col-md-12 col-sm-12">
                {/* ---------------------- Table Invoice -------------------------- */}
                <TableInvoice 
                list={list}
                amounts={amounts}
                tax={tax}
                discount={discount}
                total={total}
                information={information}
                totalAfDiscount={totalAfDiscount}
                perTax={perTax}
                dueDate={dueDate}
                lang={lang}
                totalAfTax={totalAfTax}
                exemptionAmount={exemptionAmount}
                />
                {/* <div className="table-responsive">
                  <table className="table table-hover table-white">
                    <thead>
                      <tr>
                        <th style={{width: '20px'}}>#</th>
                        <th className="col-sm-2">فاتورة </th>
                        <th className="col-md-6">تفاصيل الفاتورة </th>
                        <th style={{width: '100px'}} >التكلفة  </th>
                        <th style={{width: '80px'}} >الكمية </th>
                        <th>مجموع</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <input
                           className="form-control"
                            type="text"
                             style={{minWidth: '150px'}}
                            //  onChange={e=>setInvoiceNumber(e.target.value)}
                             />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            type="text" 
                            style={{minWidth: '150px'}} 
                            // onChange={e=>setDetails(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            // onChange={e=>setPrice(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            style={{width: '80px'}}
                             type="text"
                            //  onChange={e=>setQuantity(e.target.value)} 
                             />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            readOnly 
                            style={{width: '120px'}} 
                            type="text" 
                            // onChange={e=>setAmount(e.target.value)}
                            />
                        </td>
                        <td><a href="javascript:void(0)" className="text-success font-18" title="Add"><i className="fa fa-plus" /></a></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <input className="form-control" type="text" style={{minWidth: '150px'}} />
                        </td>
                        <td>
                          <input className="form-control" type="text" style={{minWidth: '150px'}} />
                        </td>
                        <td>
                          <input className="form-control" style={{width: '100px'}} type="text" />
                        </td>
                        <td>
                          <input className="form-control" style={{width: '80px'}} type="text" />
                        </td>
                        <td>
                          <input className="form-control" readOnly style={{width: '120px'}} type="text" />
                        </td>
                        <td><a href="javascript:void(0)" className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a></td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                {/* <div className="table-responsive">
                  <table className="table table-hover table-white">
                    <tbody>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td className="text-right">المجموع </td>
                        <td style={{textAlign: 'right', paddingRight: '30px', width: '230px'}}>0</td>
                      </tr>
                      <tr>
                        <td colSpan={5} className="text-right">ضريبة </td>
                        <td style={{textAlign: 'right', paddingRight: '30px', width: '230px'}}>
                          <input className="form-control text-right" defaultValue={0} readOnly type="text" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} className="text-right">
                          خصم %
                        </td>
                        <td style={{textAlign: 'right', paddingRight: '30px', width: '230px'}}>
                          <input className="form-control text-right" type="text" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5} style={{textAlign: 'right', fontWeight: 'bold'}}>
                          المجموع الكلي 
                        </td>
                        <td style={{textAlign: 'right', paddingRight: '30px', fontWeight: 'bold', fontSize: '16px', width: '230px'}}>
                          0.00  <BiShekel />
                        </td>
                      </tr>
                    </tbody>
                  </table>                               
                </div> */}
                <div className="row ">
                  <hr></hr>
                  <div className="col-sm-12 col-md-9">
                    <div className={lang ==='en'? "form-group description-en-mk": "form-group description-mk"}>
                      <label className='h-black-mk bold' dangerouslySetInnerHTML=  {createMarkup(NOTE)}></label>
                      <div className=" information-mk">{information}</div>
                    </div>
                  </div>
               <div className='col-sm-12 col-md-3 text-right'>
               <div className={lang ==='en'? "signature-en-mk": "signature-mk"}>
                      <h4 className="h-black-mk bold" > {SIGNATURE} </h4>
                      {/* <div className=" information-mk">{information}</div> */}
                </div>
               </div>
                
                </div>
              </div>
            </div>
           
            
            </div>
        </div>
      </div>
    </div>
    <div className="submit-section">
              <button 
              className="btn btn-back-mk submit-btn m-r-10"
              data-tip data-for="pdf-tip"
              //  onClick={()=>{
              //   // setShow(!show);
              //   handleDownloadPdf
              //   }}

                onClick={()=>{
                  handleDownloadPdf();
                  handleSubmit();
                }} 
                 >طباعة PDF 
                 </button>
              <button 
              className="btn btn-back-mk submit-btn m-r-10"
               onClick={()=>setShow(!show)}>حفظ مسودة </button>
              <button 
              className="btn btn-back-mk submit-btn m-r-10"
               onClick={()=>setShow(!show)}>رجوع </button>
              {/* <button className="btn btn-primary submit-btn">حفظ </button> */}
            </div>
            <br></br>
    {/* /Page Content */}</div>
    </div>
  )
}
