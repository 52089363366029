let aen =[
 {
  ar: "عائد",
  he: "תשואה",
  en: "Yield\/ return"
 },
 {
  ar: "عائد اجمالي للاسهم المستهلكة",
  he: "תשואה שנתית לפדיון",
  en: "Yield to maturity\/ redemption yield"
 },
 {
  ar: "عائد الاستثمار",
  he: "תשואה להשקעה ",
  en: "Return on investment"
 },
 {
  ar: "عائد راس المال",
  he: "תשואה להון",
  en: "Return to capital"
 },
 {
  ar: "عائد مقياس",
  he: "תשואה לגודל",
  en: "Return to scale"
 },
 {
  ar: "عارض",
  he: "מציע מחיר במכרז\/ מציע מחיר במכירה פומבית",
  en: "bidder"
 },
 {
  ar: "عاطل عن العمل",
  he: "מובטל",
  en: "Jobless\/ unemployed"
 },
 {
  ar: "العالم الثالث",
  he: "העולם השלישי",
  en: "Third world"
 },
 {
  ar: "عام الميزانية",
  he: "שנת תקציב",
  en: "Budgetary year"
 },
 {
  ar: "عامل",
  he: "עובד\/ פועל",
  en: "Employee\/ worker"
 },
 {
  ar: "عامل ابيض الياقة",
  he: "עובד הצווארון הלבן",
  en: "White collar worker"
 },
 {
  ar: "عامل ازرق الياقة",
  he: "עובד הצווארון הכחול",
  en: "Blue collar worker"
 },
 {
  ar: "عبء الضريبة",
  he: "תחולת מס",
  en: "Tax incidence"
 },
 {
  ar: "عبودية",
  he: "עבדות",
  en: "slavery"
 },
 {
  ar: "عجز",
  he: "גירעון",
  en: "deficit"
 },
 {
  ar: "عدد",
  he: "מספר",
  en: "number"
 },
 {
  ar: "عديم القيمة",
  he: "חסר ערך",
  en: "worthless"
 },
 {
  ar: "عربون",
  he: "עירבון",
  en: "deposit"
 },
 {
  ar: "عرض",
  he: "היצע",
  en: "Supply"
 },
 {
  ar: null,
  he: "הצעה",
  en: "offer"
 },
 {
  ar: null,
  he: "הצעת מחיר במכרז",
  en: "bid"
 },
 {
  ar: "عرض بخس",
  he: "הצעה נמוכה מההערכה",
  en: "underbid"
 },
 {
  ar: "عرض سعر",
  he: "הצעת מחיר",
  en: "quotation"
 },
 {
  ar: "عرض سعر اقل",
  he: "הצעה נמוכה מההערכה",
  en: "underbid"
 },
 {
  ar: "عرض فائض",
  he: "עודף היצע",
  en: "Excess supply"
 },
 {
  ar: "عرض متلازم",
  he: "היצע משולב",
  en: "Joint supply"
 },
 {
  ar: "عرض مقابل",
  he: "הצעה נגדית",
  en: "Counter offer"
 },
 {
  ar: "العرض والطلب",
  he: "היצע וביקוש",
  en: "Supply and demand"
 },
 {
  ar: "عطاء",
  he: "הצעת מחיר במכרז",
  en: "bid"
 },
 {
  ar: "عطاء بالظرف المختوم",
  he: "מכרז חתום",
  en: "Sealed bid"
 },
 {
  ar: "عطلة",
  he: "חופשה",
  en: "Holiday\/ leave"
 },
 {
  ar: "عطلة البنوك",
  he: "בנק הולידיי (חופשה שבה הבנקים סגורים)",
  en: "Bank holiday"
 },
 {
  ar: "عقد",
  he: "חוזה",
  en: "contract"
 },
 {
  ar: "عقد الشراكة",
  he: "הסכם שותפות",
  en: "Deed of partnership"
 },
 {
  ar: "عقد بيع",
  he: "חוזה מכירה",
  en: "Sale contract"
 },
 {
  ar: "عقد خيار",
  he: "חוזה אופציה",
  en: "Option contract"
 },
 {
  ar: "عقد عمل ",
  he: "חוזה עבודה",
  en: "Labour contract"
 },
 {
  ar: "عقد غير مشروع",
  he: "חוזה לא חוקי",
  en: "Illegal contract"
 },
 {
  ar: "عقد قابل للتنفيذ",
  he: "חוזה בר ביצוע",
  en: "Executory contract"
 },
 {
  ar: "عقد مشروط",
  he: "חוזה מותנה",
  en: "Conditional contract"
 },
 {
  ar: "عقد مفتوح",
  he: "חוזה פתוח",
  en: "Open contract"
 },
 {
  ar: "عقد مكتوب",
  he: "חוזה כתוב",
  en: "Written contract"
 },
 {
  ar: "عقوبات اقتصادية",
  he: "סנקציות כלכליות",
  en: "Economic sanctions"
 },
 {
  ar: "عقوبة",
  he: "סנקציה",
  en: "sanction"
 },
 {
  ar: "على الحساب",
  he: "בהקפה\/ באשראי\/ על חשבון",
  en: "On account"
 },
 {
  ar: "علاقات العمل",
  he: "יחסי עבודה",
  en: "Labour relations"
 },
 {
  ar: "علامة تجارية",
  he: "סמל מסחרי\/ סימן מסחרי",
  en: "Trade mark"
 },
 {
  ar: "علامة تجارية نسجلة",
  he: "סימן מסחרי רשום",
  en: "Registered trade mark"
 },
 {
  ar: "علاوة",
  he: "תוספת",
  en: "allowance"
 },
 {
  ar: "علاوة على السند",
  he: "פרמיה ביחס למחיר איגרת חוב",
  en: "Premium on bond"
 },
 {
  ar: "علاوة غلاء المعيشة",
  he: "תוספת יוקר",
  en: "Cost of living allowance"
 },
 {
  ar: "علم الاقتصاد الشامل",
  he: "מקרו כלכלה",
  en: "Macro economics"
 },
 {
  ar: "عمالة",
  he: "תעסוקה",
  en: "employment"
 },
 {
  ar: "عمالة كاملة",
  he: "תעסוקה מלאה",
  en: "Full employment"
 },
 {
  ar: "عمل",
  he: "עבודה",
  en: "Work\/ labour\/ job"
 },
 {
  ar: "عمل جماعي",
  he: "עבודת צוות",
  en: "teamwork"
 },
 {
  ar: "عمل يدوي",
  he: "עבודה פיזית\/ עבודת כפיים ",
  en: "Manual work\/ labour work"
 },
 {
  ar: "عملة",
  he: "מטבע",
  en: "currency"
 },
 {
  ar: "عملة اجنبية",
  he: "מטבע זר\/ מטבע חוץ",
  en: "Foreign currency"
 },
 {
  ar: "عملة احتياطية",
  he: "עתודות מטבע",
  en: "Currency reserves"
 },
 {
  ar: "عملة سهلة",
  he: "מטבע רך",
  en: "Soft currency "
 },
 {
  ar: "عملة شرعية",
  he: "הילך חוקי",
  en: "Legal tender\/ lawful money"
 },
 {
  ar: "عملة صعبة",
  he: "מטבע קשה",
  en: "Hard currency"
 },
 {
  ar: "عملة ضعيفة",
  he: "מטבע רך",
  en: "Soft currency"
 },
 {
  ar: "عملة قابلة للتحويل",
  he: "מטבע ניתן להמרה\/ כסף בר המרה",
  en: "Convertible money"
 },
 {
  ar: "عملة قانونية",
  he: "הילך חוקי",
  en: "Legal tender\/ lawful money"
 },
 {
  ar: "عملة متداولة",
  he: "כסף במחזור",
  en: "Money in circulation"
 },
 {
  ar: "عملة مجمدة",
  he: "מטבע חסום",
  en: "Blocked currency"
 },
 {
  ar: "عملة مدارة",
  he: "מטבע חוץ שמחירו אינו חופשי לחלוטין",
  en: "Managed currency"
 },
 {
  ar: "عملة معدنية",
  he: "מטבע",
  en: "coin"
 },
 {
  ar: "عملة مقومة باقل من قيمتها",
  he: "מטבע שמוערך בפחות משוויו",
  en: "Undervalued currency"
 },
 {
  ar: "عملة موجهة",
  he: "מטבע חוץ שמחירו אינו חופשי לחלוטין",
  en: "Managed currency"
 },
 {
  ar: "عملة ورقية",
  he: "שטר כסף",
  en: "Paper money\/ bank bill\/ currency note"
 },
 {
  ar: "عملة يسرة",
  he: "מטבע רך",
  en: "Soft currency"
 },
 {
  ar: "عمليات اجلة التسليم",
  he: "חוזים עתידיים",
  en: "futures"
 },
 {
  ar: "عملية الاوفست",
  he: "עסקת קיזוז",
  en: "Offset transaction"
 },
 {
  ar: "عمولة",
  he: "דמי תיווך",
  en: "Brockerage"
 },
 {
  ar: null,
  he: "עמלה",
  en: "commission"
 },
 {
  ar: "عمولة الباعة",
  he: "עמלת סוכן",
  en: "Salesmen commission"
 },
 {
  ar: "عمولة التحويل",
  he: "עמלת חליפין",
  en: "Exchange charge"
 },
 {
  ar: "عمولة الصرافة",
  he: "אגיו",
  en: "agio"
 },
 {
  ar: "عمولة المصرف",
  he: "עמלה בנקאית",
  en: "Bank charge"
 },
 {
  ar: "عميل",
  he: "סוכן",
  en: "Agent"
 },
 {
  ar: null,
  he: "לקוח",
  en: "customer\/ client"
 },
 {
  ar: "عناصر الانتاج",
  he: "גורמי ייצור",
  en: "Earnings per share"
 },
 {
  ar: "عوائد الرصيف",
  he: "דמי רציף",
  en: "Quayage\/ wharfage\/ quay handling charges "
 },
 {
  ar: "عوائد السهم",
  he: "רווחים למניה",
  en: "Earning per share"
 },
 {
  ar: "عوامل الانتاج",
  he: "גורמי ייצור",
  en: "Factors of production"
 },
 {
  ar: "عوز",
  he: "עוני",
  en: "poverty"
 },
 {
  ar: "عيب",
  he: "פגם",
  en: "defect"
 },
 {
  ar: "عينة",
  he: "דוגמה",
  en: "ample"
 }
]
export default aen ;