let tha =[
 {
  ar: "ثروة",
  he: "עושר",
  en: "Wealth\/ richness"
 },
 {
  ar: "ثمن",
  he: "עלות\/ מחיר",
  en: "Cost\/ price"
 },
 {
  ar: "ثمن الوحدة",
  he: "עלות ליחידה",
  en: "Unit cost"
 },
 {
  ar: "ثمن مفترض",
  he: "עלות זקופה",
  en: "Imputed cost "
 },
 {
  ar: "ثمن مقدر",
  he: "עלות זקופה",
  en: "Imputed cost "
 },
 {
  ar: "الثروة الصناعية",
  he: "המהפכה התעשייתית",
  en: "industrial revolution"
 }
]
export default tha ;