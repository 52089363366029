

import React, { useState ,useRef} from "react";
import { DownloadTableExcel, downloadExcel  } from "react-export-table-to-excel";
import { SiMicrosoftexcel } from 'react-icons/si'
import ReactTooltip from 'react-tooltip';

import taxProperty from "../real-state/output"


export function TxTblOneAbt(props) {
    
	const tableRef = useRef(null);

    let header = ["النسبة %","حتى مبلغ","النسبة %","حتى مبلغ","النسبة %",  "حتى مبلغ","النسبة %", "حتى مبلغ", "النسبة %", "من صفر", "تاريخ الشراء"];
    header = header.reverse()
    
    function commify(n) {
        var parts = n.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    }

    // console.log("tax property is : ", JSON.stringify(taxProperty.taxProperty))
    let tableBody=[]
    taxProperty.taxProperty.map((data) => {
        // console.log(data.band1)
        let trData = {
            date:data.date,
            band1M:commify(data.band1.max),
            rate1: data.band1.rate,
            band2M: commify(data.band2.max),
            rate2:data.band2.rate,
            band3M: commify(data.band3.max),
            rate3:data.band3.rate,
            band4M:commify(data.band4.max),
            rate4:data.band4.rate,
            band5M: (data.band5.max),
            rate5:data.band5.rate,
        }
        tableBody.push({...trData})
    })
  function handleDownloadExcel() {
    downloadExcel({
      fileName: "MP-apt-table",
      sheet: "MP-apt",
      tablePayload: {
        header,
        body: tableBody,
      },
    });
  }

    return (<div className="card tax-card ">


        <div className="card-body">
            <div className="card-header">
                <h4 className="card-title mb-0 text-center privacy">جداول  شقة سكنية   ( واحدة )
                     <span style={{float:"left"}}>
                     <button className="btn " onClick={handleDownloadExcel} data-tip data-for="excel-tip">
                            <SiMicrosoftexcel size={25} className='privacy ' />
                        </button>
                     </span>
                </h4>

            </div>
            <ReactTooltip id="excel-tip" place="top" effect="solid">
					طباعة Excel
				</ReactTooltip>
            <div className="table-responsive">
                <table className="table table-nowrap table-striped mb-0" ref={tableRef}>
                    <thead>

                        <tr >
                            <th></th>
                            <th colSpan={2} className='table-tr-underline bold'>  الشريحة الاولى</th>
                            <th colSpan={2} className='table-tr-underline bold' > الشريحة الثانية </th>
                            <th colSpan={2} className='table-tr-underline bold' > الشريحة الثالثة</th>
                            <th colSpan={2} className='table-tr-underline bold' > الشريحة الرابعة</th>
                            <th colSpan={2} className='table-tr-underline bold' > الشريحة الخامسة </th>

                        </tr>


                    </thead>
                    <thead>

                        <tr>
                            <th className="privacy">تاريخ الشراء</th>

                            <th>من صفر</th>
                            <th className="privacy">النسبة %</th>

                            <th>حتى مبلغ</th>
                            <th className="privacy">النسبة %</th>

                            <th>حتى مبلغ</th>
                            <th className="privacy">النسبة %</th>

                            <th>حتى مبلغ</th>
                            <th className="privacy">النسبة %</th>

                            <th>حتى مبلغ</th>
                            <th className="privacy">النسبة %</th>

                            {/* <th>Country</th> */}
                        </tr>
                    </thead>
                    <tbody>


                        {

                            taxProperty.taxProperty.map((data) => {


                                return (
                                    <tr>
                                        <th className="privacy">        {data.date}</th>

                                        <th>  {commify(data.band1.max)}</th>
                                        <th className="privacy"> {data.band1.rate}</th>

                                        <th>   {commify(data.band2.max)}</th>
                                        <th className="privacy"> {data.band2.rate}</th>

                                        <th>   {commify(data.band3.max)}</th>
                                        <th className="privacy"> {data.band3.rate}</th>

                                        <th>   {commify(data.band4.max)}</th>
                                        <th className="privacy"> {data.band4.rate}</th>

                                        <th>   {(data.band5.max)}</th>
                                        <th className="privacy"> {data.band5.rate}</th>


                                    </tr>)
                            })



                        }


                        {/* <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr>
 <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr>
 <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    </div>)

}
