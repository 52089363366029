let insurance=[
    {year:2022,
        bands:{
            
            age1:{ value: "17-21",
            band1:{
                min:"2,136"
            },
            band2:{
                min:"2,136"
            },
            band3:{
                min:"2,756"
            },
            band4:{
                min:"2,756"
            }},
            age2:{ value: "21-24",
            band1:{
                min:"2,136"
            },
            band2:{
                min:"2,136"
            },
            band3:{
                min:"2,756"
            },
            band4:{
                min:"2,756"
            }},
            age3:{ value: "24-30",
            band1:{
                min:"1,552"
            },
            band2:{
                min:"1,552"
            },
            band3:{
                min:"2,002"
            },
            band4:{
                min:"2,002"
            }},
            age4:{ value: "30-40",
            band1:{
                min:"1,283"
            },
            band2:{
                min:"1,283"
            },
            band3:{
                min:"1,656"
            },
            band4:{
                min:"1,656"
            }},
            
            age5:{ value: "40-50",
            band1:{
                min:"1,028"
            },
            band2:{
                min:"1,028"
            },
            band3:{
                min:"1,326"
            },
            band4:{
                min:"1,326"
            }},
            age6:{ value: "أكثر من ذلك ",
            band1:{
                min:"1,028"
            },
            band2:{
                min:"1,028"
            },
            band3:{
                min:"1,326"
            },
            band4:{
                min:"1,326"
            }},
            

        }
     
    
   
  }
]
export default{insurance};