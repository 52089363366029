let dad=[
 {
  ar: "ضامن السندات",
  he: "חתם",
  en: "underwriter"
 },
 {
  ar: "ضبط",
  he: "ויסות",
  en: "Regulation\/ control"
 },
 {
  ar: "ضد جميع الاخطار",
  he: "כל הסיכונים",
  en: "Against all risks"
 },
 {
  ar: "ضرر",
  he: "נזק",
  en: "damage"
 },
 {
  ar: "ضريبة",
  he: "מס",
  en: "Tax\/ duty"
 },
 {
  ar: "ضريبة ارباح الشركات",
  he: "מס חברות",
  en: "Corporate tax\/ company tax\/ corporation tax"
 },
 {
  ar: "ضريبة اضافية",
  he: "מס נוסף",
  en: "surtax"
 },
 {
  ar: "ضريبة اضافية على الواردات",
  he: "היטל על יבוא",
  en: "Import surcharge"
 },
 {
  ar: "ضريبة الارث",
  he: "מס יורשה\/ מס עיזבון",
  en: "Inheritance tax\/ succession tax"
 },
 {
  ar: "ضريبة الاعناق",
  he: "מס גולגולת",
  en: "Head tax\/ poll tax"
 },
 {
  ar: "ضريبة الدخل",
  he: "מס הכנסה",
  en: "Income tax"
 },
 {
  ar: "ضريبة الراس",
  he: "מס גולגולת",
  en: "Head tax\/ poll tax"
 },
 {
  ar: "ضريبة الربح",
  he: "מס על דיווידנד",
  en: "Dividend tax"
 },
 {
  ar: "ضريبة الربح الراسمالي",
  he: "מס רווחי הון",
  en: "Capital gains tax"
 },
 {
  ar: "ضريبة الشركات",
  he: "מס חברות",
  en: "Corporate tax\/ company tax\/ corporation tax"
 },
 {
  ar: "ضريبة العقار",
  he: "מס רכוש",
  en: "Property tax"
 },
 {
  ar: "ضريبة القيمة المضافة",
  he: "מס ערך מוסף",
  en: "Value added tax"
 },
 {
  ar: "ضريبة الملاهي",
  he: "היטל עינוגים",
  en: "Entertainment duty"
 },
 {
  ar: "ضريبة الممتلكات",
  he: "מס רכוש",
  en: "Property tax"
 },
 {
  ar: "ضريبة انتاج",
  he: "בלו",
  en: "Excise tax"
 },
 {
  ar: "ضريبة ايلولة الميراث",
  he: "מס יורשה\/ מס עיזבון",
  en: "Inheritance tax\/ succession tax"
 },
 {
  ar: "ضريبة بلدية",
  he: "ארנונה",
  en: "Municipal tax"
 },
 {
  ar: "ضريبة تصاعدية",
  he: "מס פרוגרסיבי",
  en: "Progressive tax"
 },
 {
  ar: "ضريبة تفاضلية",
  he: "מס מפלה",
  en: "Discriminating tax"
 },
 {
  ar: "ضريبة تمييزية",
  he: "מס מפלה",
  en: "Discriminating tax"
 },
 {
  ar: "ضريبة تنازلية",
  he: "מס רגרסיבי",
  en: "Regressive tax"
 },
 {
  ar: "ضريبة جملة المبيعات",
  he: "מס מחזור",
  en: "Turnover tax"
 },
 {
  ar: "ضريبة حركة راس المال",
  he: "מס מחזור",
  en: "Turnover tax"
 },
 {
  ar: "ضريبة حماية جمركية",
  he: "מס מגן",
  en: "Protective duty"
 },
 {
  ar: "ضريبة خفية",
  he: "מס סמוי",
  en: "Hidden tax"
 },
 {
  ar: "ضريبة رقم الاعمال",
  he: "מס מחזור",
  en: "Turnover tax"
 },
 {
  ar: "ضريبة شراء",
  he: "מס קנייה",
  en: "Purchase tax "
 },
 {
  ar: "ضريبة عقارية",
  he: "מס רווחי הון על נדל\"ן",
  en: "Real estate tax"
 },
 {
  ar: "ضريبة على اساس القيمة",
  he: "מס על פי ערך",
  en: "Ad valorem tax"
 },
 {
  ar: "ضريبة عند المنبع",
  he: "מס במקור",
  en: "Tax at the source"
 },
 {
  ar: "ضريبة غير مباشرة",
  he: "מס עקיף",
  en: "Indirect tax"
 },
 {
  ar: "ضريبة مباشرة",
  he: "מס ישיר",
  en: "Direct tax"
 },
 {
  ar: "ضريبة متدرجة",
  he: "מס מדורג",
  en: "Graduated tax"
 },
 {
  ar: "ضريبة موزعة",
  he: "מס קצוב",
  en: "Apportioned tax"
 },
 {
  ar: "ضريبة نسبية",
  he: "מס יחסי",
  en: "Proportional tax"
 },
 {
  ar: "ضريبة نوعية",
  he: "מס ייחודי",
  en: "Specific tax"
 },
 {
  ar: "ضمان",
  he: "אחריות\/ ערבות",
  en: "guarantee"
 },
 {
  ar: "ضمان اجتماعي",
  he: "ביטוח סוציאלי",
  en: "Social insurance"
 },
 {
  ar: "ضمان شخصي",
  he: "ערבות אישית",
  en: "Personal guarantee"
 },
 {
  ar: "ضمان عطاء",
  he: "ערבות מכרז",
  en: "Bid bond\/ tender bond"
 },
 {
  ar: "ضمان مصرفي",
  he: "ערבות בנקאית",
  en: "Bank guarantee"
 },
 {
  ar: "ضمانات",
  he: "ביטחונות",
  en: "securities"
 }
] 
export default dad ;