import { formatMoney } from "accounting-js";
import React, { Component, useState } from 'react';
import { Helmet } from "react-helmet";
import { Avatar_01, Avatar_04, Avatar_05, Avatar_09, Avatar_10, Avatar_11, Avatar_12, Avatar_13, Avatar_16 } from "../../../Entryfile/imagepath"
import { IoSwapHorizontalOutline } from "react-icons/io5";

// Form initial state
const INITIAL_STATE = {
  amount: "",
  points: "",
  tax: "",
  bool: "",


};

export default function VatCalc() {
  const [included, setBool] = useState("");
  const [contact, setContact] = useState(INITIAL_STATE);
  const [tax_amount, setTaxAmount] = useState(0);
  const [total_amount, setTotalAmount] = useState(0);
  const handleChange = e => {
    const { name, value } = e.target;
    setContact(prevState => ({ ...prevState, [name]: value }));
    // console.log(contact)
  }
  //================== Submit Handler  ======================

  function Calculate(e){
   
    var tax = document.querySelector('#tax').value;
    
    var amount = document.querySelector('#amount').value;

    var bool = document.querySelector('#bool').checked;

    if (amount !== '' && tax !== '') {
      if (bool) {
        var amountNew = amount / ((tax / 100) + 1);
        const amountTaxx = parseFloat(amount) - parseFloat(amountNew);
        setTaxAmount(amountTaxx.toFixed(2));
        const amountTotall = amountNew.toFixed(2);
        setTotalAmount(amountTotall);
      } else {
        const amountTax2 = (amount * tax) / 100;
        setTaxAmount(amountTax2.toFixed(2))
        const amountTotal2 = parseFloat(amount) + parseFloat(amountTax2);
        setTotalAmount(amountTotal2.toFixed(2));
      }
      // $('#tax_amount').val(parseFloat(calcObject.amountTax).toFixed(2));
      // $('#total_amount').val(parseFloat(calcObject.amountTotal).toFixed(2));
    } else {
      setTaxAmount("0.00")
      setTotalAmount("0.00")
      // $('#tax_amount').val(calcObject.amountNull);
      // $('#total_amount').val(calcObject.amountNull);
    }



  }
 
  return (
 

    <div className="card text-left">
      {/* Page Header */}

      <div className=" card-body eOfService">
        <br></br>
        <form onSubmit={Calculate} >
          <h2 className="card-title text-center"> حاسبة   <span className="privacy">( ض . ق . م )</span> </h2>
          <br></br>
          <div className="">

            <br></br>
            <div className="form-gronum row">
              <div className="col-12">
                <label className=" col-form-label">أدخل المبلغ </label>
                <br></br>
                <input
                  type="number"
                  className="form-control"
                  name="amount"
                  id='amount'
                  // value={contact.amount}
                  onChange={Calculate}
                />
             
              </div>
              <div className='col-12'>
                <br></br>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type="checkbox"
                    id='bool'
                    name='bool'
                    onChange={Calculate} 
                    />
                  <label className='form-check-label' > شامل الضريبة </label>
                </div>
              </div>

             
              <div className='col-12'>
                <br></br>
                <label className=" col-form-label label-vit">نسبة ضريبة القيمة المضافة ( ض . ق . م ) </label>
               
                <select
                  id="tax"
                  className='select floating vit-select '
                  name='tax'
                  // value={contact.tax}

                  onChange={Calculate}
                >
                  <option>--</option>
                  <option value="20">  20 %</option>
                  <option value="17"> 17 % </option>
                  <option value="16"> 16 %  </option>
<br></br>
                </select>

              </div><br></br>

             
              <div className="col-12">
                <br></br>
                <label className=" col-form-label">ض . ق . م  </label>
                <br></br>
                <input
                  type="text"
                  className="form-control"
                  name="vit"

                  value={formatMoney(tax_amount,{ symbol: "₪",  format: " %v %s" })}
                />
                <br></br>
              </div>

              <div className="col-12">
                <label className=" col-form-label"> المبلغ    </label>
                <br></br>
                <input type="text"
                  className="form-control"
                  name="vit"
                  value={formatMoney(total_amount,{ symbol: "₪",  format: " %v %s" })}
                />
                <br></br>
              </div>

            </div>

          </div>
          {/* <div className="text-center button-height">
            <button type="submit" className="btn btn-primary calc-button ">حساب</button>
          </div> */}

          {/* /Page Content */}
        </form>
      </div>

    </div>
  );
}



