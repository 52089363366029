/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { FaInfo } from "react-icons/fa";

class ShaamCodes extends Component {

  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - Codes</title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">أكواد ال ( שע"ם ) </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                  <br></br>
                  {/* <li className="breadcrumb-item active">Blank Page</li>
							 */}
                </ul>

              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className='small-container'>

            {/* Buttons With Links - Start */}
            <div className='row'>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
              
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <br></br>
                <br></br>
                <h2 className='links-title text-center '> שירות עיבודים ממוכנים (שע"ם)  </h2>
                 
                <br></br>
                <br></br>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
               
              </div>
            </div>
            {/* Buttons With Links - End */}
            <div className="row">


              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h6 className="card-title m-b-20 text-center">ריכוז קודי שאילתות לשע"מ</h6>
                
                <div className="m-b-30">

                </div>
                <div className="table-responsive-code">
                  <table className="table table-striped custom-table table-code">
                    <thead className=''>
                      <tr className='privacy table-code-title th-code-sticky'>
                        <th>פירוט הפעולה </th>
                        <th className="text-center">שאילתא</th>
                        <th className="text-center">קוד</th>

                      </tr>
                    </thead>
                    <tbody className='table-code-body'>
                    

                      <tr className='table-code-tr'>
                        <td>מדדים, ושערי מטבע</td>
                        <td className="text-center">
                          11


                        </td>
                        <td className="text-center">
                          002


                        </td>
                      </tr>

                      <tr className='table-code-tr'>
                        <td>גלגולו של מטבע</td>
                        <td className="text-center">
                          16




                        </td>
                        <td className="text-center">
                          002


                        </td>
                      </tr>

                      <tr className='table-code-tr'>
                        <td>כלי עזר לחישובי הצמדה ריבית וקנס </td>
                        <td className="text-center">
                          66


                        </td>
                        <td className="text-center">
                          002


                        </td>
                      </tr>

                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>חלונות התלם </td>
                        <td className="text-center">
                          68
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}

                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת אחוז ממוצע ענפי לצורך מקדמות


                        </td>
                        <td className="text-center">
                          105
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          רשימת אישורי מלכרים



                        </td>
                        <td className="text-center">
                          107
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מספר מזהה לצורך תשלום מקדמות
                        </td>
                        <td className="text-center">
                          114
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}

                      <tr className='table-code-tr'>
                        <td>
                          מעקב החלטות לשינוי גובה דרישת המקדמות

                        </td>
                        <td className="text-center">
                          115
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          ביקורת משלוח פלטים (כללי)-פרוט מכתבים שנשלחו לנישום במס הכנסה
                        </td>
                        <td className="text-center">
                          116
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מעקב אחר משלוח פלטים בנושא אישורים

                        </td>
                        <td className="text-center">
                          117
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מעקב אחר שלבי החזר מס


                        </td>
                        <td className="text-center">
                          119
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          כרטיסי הנח"ש

                        </td>
                        <td className="text-center">
                          121
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת כרטיסי הנח"ש


                        </td>
                        <td className="text-center">
                          123
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מעקב אחר גובה דרישת המקדמות

                        </td>
                        <td className="text-center">
                          124
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מענק קורונה

                        </td>
                        <td className="text-center">
                          129
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מאגר הקפאות -


                        </td>
                        <td className="text-center">
                          131
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          פרטי חוב באכיפה:

                        </td>
                        <td className="text-center">
                          132
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מאגר פלט אכיפה - הצגת הודעות אכיפה


                        </td>
                        <td className="text-center">
                          133
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          דיווחי מקדמות - מחזורים ניכויים במקור

                        </td>
                        <td className="text-center">
                          134
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מצ"ח מסכם

                        </td>
                        <td className="text-center">
                          137
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          השהיית הגדלת מקדמות


                        </td>
                        <td className="text-center">
                          144
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          החזרי גביה  -

                        </td>
                        <td className="text-center">
                          145
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          החזרים - מעקב אישורים לשנת המס (אישור החזר מס)


                        </td>
                        <td className="text-center">
                          146
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת תשלומים לתיק במ"ה



                        </td>
                        <td className="text-center">
                          147
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת השלכות חשבונאיות עבור שנה צוברת

                        </td>
                        <td className="text-center">
                          148
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          נתוני חיוב ושומה במ"ה עבור שנה צוברת


                        </td>
                        <td className="text-center">
                          149
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          החלטות משרד מס הכנסה  בנושא קנסות



                        </td>
                        <td className="text-center">
                          150
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מידע כללי לשנות מס

                        </td>
                        <td className="text-center">
                          154
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          נתונים שונים לגבי החברה


                        </td>
                        <td className="text-center">
                          157
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת יתרות ישנות במ"ה עבור שנה צוברת

                        </td>
                        <td className="text-center">
                          158
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת נתוני גביה במ"ה לשנים עד 93


                        </td>
                        <td className="text-center">
                          161
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>




                        </td>
                        <td className="text-center">
                          162
                        </td>
                        <td className="text-center">
                          002


                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          יומן פיזור תשלומים - בקרה על תשלומים



                        </td>
                        <td className="text-center">
                          166
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          פירוט תשלומים - למס הכנסה

                        </td>
                        <td className="text-center">
                          170
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מניעת קיזוז בהחזרים


                        </td>
                        <td className="text-center">
                          175
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          יומן מקדמות מיוחדות



                        </td>
                        <td className="text-center">
                          178
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          פרטים כלליים על הנישום כולל ענף כלכלי שנת פתיחת וסגירה של העסק

                        </td>
                        <td className="text-center">
                          181
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת כתובות למשלוח דאר


                        </td>
                        <td className="text-center">
                          185
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת מרשם חשבונות בנקים

                        </td>
                        <td className="text-center">
                          191
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מידע כל הבנקים וסניפיהם


                        </td>
                        <td className="text-center">
                          192
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת קיזוזים שבוצעו למערכים אחרים



                        </td>
                        <td className="text-center">
                          194
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מידע על אישור מס במקור לתיק בודד,

                        </td>
                        <td className="text-center">
                          213
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          חתכים לחסרי דרישה

                        </td>
                        <td className="text-center">
                          223
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          חתכים לנדרשי מקדמה


                        </td>
                        <td className="text-center">
                          224
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מקדמות מידע על דיווחים - קבלת מידע

                        </td>
                        <td className="text-center">
                          234
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          חתכים על קנסות
                        </td>
                        <td className="text-center">
                          250
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          חתכים על שנות מס
                        </td>
                        <td className="text-center">
                          254
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          סטטוס לקוחות מיוצגים במערכות המס


                        </td>
                        <td className="text-center">
                          270
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          חתכים על זהותון

                        </td>
                        <td className="text-center">
                          284
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          פנקס חוזר ובקשות שינוי מקדמה (נראה ב 415


                        </td>
                        <td className="text-center">
                          315
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          תשלומים יזומים



                        </td>
                        <td className="text-center">
                          362
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          טיפול בדואר חוזר

                        </td>
                        <td className="text-center">
                          385
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הפקת אישורים שנתיים על מס במקור


                        </td>
                        <td className="text-center">
                          413
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הקטנה או הגדלת  מקדמות

                        </td>
                        <td className="text-center">
                          415
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          עדכון מחזור וניכוי במקור


                        </td>
                        <td className="text-center">
                          461
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          שידור הוראות תשלום של לקוחות ע"י המייצג עבר ל מערכת החדשה



                        </td>
                        <td className="text-center">
                          465
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          שידור מקדמות מיוחדות:כגון רווח הון ודיבידנד -לרשום אפ 4

                        </td>
                        <td className="text-center">
                          478
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          עדכון כתובות דואר (בתלם בלבד) - לצ


                        </td>
                        <td className="text-center">
                          485
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          עדכון פרטי חשבון בנק במס הכנסה

                        </td>
                        <td className="text-center">
                          491
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          קוד פינק מקדמות


                        </td>
                        <td className="text-center">
                          114
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מעקב על החזר מס

                        </td>
                        <td className="text-center">
                          119
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          כרטסט להנה"ח


                        </td>
                        <td className="text-center">
                          121
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מרוקז מקדמות

                        </td>
                        <td className="text-center">
                          124
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          ריכוז מקדמות תשלומים לפי שנה


                        </td>
                        <td className="text-center">
                          134
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מצב חשבון לישות

                        </td>
                        <td className="text-center">
                          137
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מצב קנסות ועיצומים


                        </td>
                        <td className="text-center">
                          150
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מידע כללי לשנת מס

                        </td>
                        <td className="text-center">
                          154
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          הצגת כתובות למשלוח דואר


                        </td>
                        <td className="text-center">
                          185
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                          מפרט את ההחזר  רישום לאיזה בנק יועבר החזר המס
                        </td>
                        <td className="text-center">
                          191
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        מידע על אישור מס במקור לתיק בודד                        
                        </td>
                        <td className="text-center">
                        213
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        ריכוז חיוב מקדמות של לקוחות                        
                        </td>
                        <td className="text-center">
                        234
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        דו"ח ליקויים של כל הלקוחות                        
                        </td>
                        <td className="text-center">
                        270
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        הזמנת פנקס מקדמות                        
                        </td>
                        <td className="text-center">
                        315
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        הזמנת שוברים לאחר שידור פרטי התשלום                        
                        </td>
                        <td className="text-center">
                        362
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        הפקת אישורי ניכוי מס וניהול ספרים                        
                        </td>
                        <td className="text-center">
                        413
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        עדכון/ביטול מקדמות                        
                        </td>
                        <td className="text-center">
                        415
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        קליטת דוחות מקדמות                        
                        </td>
                        <td className="text-center">
                        461
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        עדכון כתובת למשלוח דואר ישן                        
                        </td>
                        <td className="text-center">
                        485
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        עדכון חשבון בנק לישות                        
                        </td>
                        <td className="text-center">
                        491
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>
                        שידור מקדמות בשל רווח הון -לרשום אפ 4                         
                        </td>
                        <td className="text-center">
                        478
                        </td>
                        <td className="text-center">
                          002
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>איתור שומות מס שבח</td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        905M
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>מעקב הגשת דו"חות במס הכנסה</td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IDOM
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>הצגת הנמקות בשומה</td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IHAN
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>הצגה וחישוב רווח הון</td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IHOG
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>העתק נספחי רווח הון</td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IHOG
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>סימולטור לחישוב תאום מס והצגתו </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IMAG
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr'>
                        <td>שידור תאום מס באמצעות מייצגים </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IMTM
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td>תיאום תקרות הכנסה </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        INIK
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td>העתקי שומה של נישום </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IPRS
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td>העתק השומה-,לקבלת הכנסות מחול-אנטר+ אפ 6  </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IPRS
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> פרטי נישום  </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        IPRT
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> תמצית מידע כללי ושומתי למספר שנות  </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        ITAG
                        </td>
                      </tr>
                      {/* Table Row  */}

                      <tr>
                      <td> העתק שומת מס שבח , ראה פרטים נוספים בהמשך  </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        M905
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> שאילתת הזדהות והחלפת סיסמא  </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        NWLG
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> מאגר ההדפסות  </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        TPRN
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> הזדהות טלפונית במס הכנסה  </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                        <td className="text-center">
                        VTMZ
                        </td>
                      </tr>
                      {/* Table Row  */}

                      <tr>
                      <td> הפקת טופס 106 ממעסיק (נדרש תיק ניכויים)</td>
                        <td className="text-center">
                        4601
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> מועדי להצהרת הון (כללי ופרטני)</td>
                        <td className="text-center">
                        AHZM
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> הצגת אורכות למייצגים (20)</td>
                        <td className="text-center">
                        AORM
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> AZFM 24 בירור מועד הפיכת חברה לחברה משפחתית</td>
                        <td className="text-center">
                        AZFM
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td> לבדוק – הפקת שוברים לתשלום  </td>
                        <td className="text-center">
                        EHDP
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td>  IDOM 21 מעקב אחר הגשת דו"חות במס הכנסה</td>
                        <td className="text-center">
                        IDOM
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td>   IPRT (פרטים כלליים במס הכנסה( סוג תיק, כתובת, פ"ש וכודומה   </td>
                        <td className="text-center">
                        IPRT
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}

                       {/* Table Row  */}
                       <tr>
                      <td>    ISUF 22 שידור הדו"ח השנתי של מס הכנסה ליחידים וחברות</td>
                        <td className="text-center">
                        ISUF
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td>      ISUG  23 חישוב מס החל משנת המס 1994</td>
                        <td className="text-center">
                        ISUG
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}
                      <tr>
                      <td>      ריכוז מידע על חובה ומצב הגשות לפי נישום   </td>
                        <td className="text-center">
                        ITAG
                        </td>
                        <td className="text-center">
                          <b><FaInfo size={15} className='privacy table-info-icon' /></b>
                        </td>
                       
                      </tr>
                      {/* Table Row  */}


                      










                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}


      </div>
    );
  }
}

export default ShaamCodes;
