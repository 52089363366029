let dal =[
 {
  ar: "دائرة المبيعات",
  he: "מחלקת מכירות",
  en: "Sales department"
 },
 {
  ar: "دائن",
  he: "מלווה",
  en: "Lender\/ creditor"
 },
 {
  ar: "دائن ممتاز",
  he: "נושה מועדף",
  en: "Preferred creditor"
 },
 {
  ar: "دار سك العملة",
  he: "מטבעה",
  en: "mint"
 },
 {
  ar: "دافع",
  he: "משלם",
  en: "Payer"
 },
 {
  ar: "دافع الضريبة",
  he: "משלמם מסים",
  en: "taxpayer"
 },
 {
  ar: "دخل",
  he: "הכנסה",
  en: "Income\/ revenue"
 },
 {
  ar: "دخل اجمالي",
  he: "הכנסה פרוטו",
  en: "Gross income"
 },
 {
  ar: "دخل الانفاق",
  he: "הכנסה פנויה",
  en: "Disposable income\/ franked income"
 },
 {
  ar: "دخل حدي",
  he: "הכנסה שולית",
  en: "Marginal income"
 },
 {
  ar: "دخل حقيقي",
  he: "הכנסה ריאלית",
  en: "Real income"
 },
 {
  ar: "دخل صافي",
  he: "הכנסה נקייה",
  en: "Net income"
 },
 {
  ar: "دخل غير مكتسب",
  he: "הכנסה שלא מגיעה אישית",
  en: "Unearned income"
 },
 {
  ar: "دخل قومي",
  he: "הכנסה לאומית",
  en: "National income"
 },
 {
  ar: "دخل لكل شخص",
  he: "הכנסה לנפש",
  en: "Per capita income"
 },
 {
  ar: "دخل متاح",
  he: "הכנסה פניוה",
  en: "Disposable income\/ franked income"
 },
 {
  ar: "دخل متجمع",
  he: "הכנסה נצברת",
  en: "Accrued income"
 },
 {
  ar: "دخل مدفوع الضريبة",
  he: "הכנסה פנויה",
  en: "Disposable income\/ franked income"
 },
 {
  ar: "دخل مفترض",
  he: "הכנסה זקופה",
  en: "Imputed income"
 },
 {
  ar: "دخل مقدر",
  he: "הכנסה זקופה",
  en: "Imputed income"
 },
 {
  ar: "دخل مكتسب",
  he: "הכנסה מיגיעה אישית",
  en: "Earned money"
 },
 {
  ar: "دخل نقدي",
  he: "הכנסה כספית",
  en: "Money income"
 },
 {
  ar: "الدرجة الاولى",
  he: "מחלקה ראשונה\/ מובחר",
  en: "First class"
 },
 {
  ar: "دفتر الجرد",
  he: "ספר מלאי",
  en: "Book of inventory"
 },
 {
  ar: "دفتر الفواتير",
  he: "ספר חשבוניות",
  en: "Invoice book"
 },
 {
  ar: "دفتر المحاسبة",
  he: "ספר חשבונות",
  en: "Accounting book"
 },
 {
  ar: "دفتر المودع",
  he: "פנקס הפקדות",
  en: "Depositor's book"
 },
 {
  ar: "دفتر شيكات",
  he: "פנקס שיקים",
  en: "chequebook"
 },
 {
  ar: "دفع",
  he: "תשלום ",
  en: "payment"
 },
 {
  ar: "دفع بشيك",
  he: "תשלום בשיק",
  en: "Payment by cheque"
 },
 {
  ar: "دفع سلفا",
  he: "תשלום מראש",
  en: "Payment in advice\/ Prepayment"
 },
 {
  ar: "دفع عند الاستلام",
  he: "תשלום בעת הקבלה",
  en: "Pay on receipt"
 },
 {
  ar: "دفع عند التسليم",
  he: "תשלום בעת המסירה",
  en: "Pay on delivery"
 },
 {
  ar: "دفع عيني",
  he: "תשלום בעין (בסחורה עצמה)",
  en: "Payment in kind"
 },
 {
  ar: "دفع لقاء المستندات",
  he: "מזומן כנגד מסמכים",
  en: "Cash against documents"
 },
 {
  ar: "دفع مقدما",
  he: "תשלום מראש",
  en: "Payment in advice\/ Prepayment"
 },
 {
  ar: "دفع نقدا",
  he: "תשלום במזומן",
  en: "Cash payment"
 },
 {
  ar: "دفع نقدا عند التسليم",
  he: "תשלום במזומן בעת המסירה",
  en: "Cash on delivery"
 },
 {
  ar: "دفع نوعي",
  he: "תשלום בעין (בסחורה עצמה)",
  en: "Payment in kind"
 },
 {
  ar: "دفعة",
  he: "תשלום ",
  en: "payment"
 },
 {
  ar: "دفعة اضافية",
  he: "תשלום נוסף",
  en: "surcharge"
 },
 {
  ar: "دفعة جزئية",
  he: "תשלום חלקי",
  en: "Partial payment"
 },
 {
  ar: "دفعة رمزية",
  he: "תשלום סמלי",
  en: "Token payment"
 },
 {
  ar: "دفعة كاملة",
  he: "תשלום מלא",
  en: "Full pay"
 },
 {
  ar: "دفعة مؤجلة",
  he: "תשלום דחוי",
  en: "Deferred payment"
 },
 {
  ar: "دكان",
  he: "חנות",
  en: "Shop\/ store"
 },
 {
  ar: "دلال",
  he: "כרוז במכירה פומבית",
  en: "auctioneer"
 },
 {
  ar: "دليل",
  he: "מדד",
  en: "index"
 },
 {
  ar: "دمج",
  he: "מיזוג",
  en: "Integration\/ merger\/ consolidation"
 },
 {
  ar: "دورة",
  he: "מחזור",
  en: "turnover"
 },
 {
  ar: "دورة تجارية",
  he: "מחזור עסקים",
  en: "Trade cycle"
 },
 {
  ar: "دولار النفط",
  he: "פטרו- דולר",
  en: "petrodollar"
 },
 {
  ar: "دولار اوروبي",
  he: "יורו דולר",
  en: "Eurodollar"
 },
 {
  ar: "دولة اكثر رعاية",
  he: "אומה מועדפת ביותר",
  en: "Most favoured nation"
 },
 {
  ar: "دولة الرفاهية",
  he: "מדינת סעד\/ מדינת רווחה",
  en: "Welfare state"
 },
 {
  ar: "دولة دائنة",
  he: "מדינה נושה\/ מדינה בעלת מאזן מסחרי חיובי",
  en: "Creditor nation"
 },
 {
  ar: "دولة صناعية",
  he: "מדינה מתועשת",
  en: "Industrialized country"
 },
 {
  ar: "دولة متطورة",
  he: "מדינה מפותחת",
  en: "Developed country"
 },
 {
  ar: "دولة مدينة",
  he: "מדינה בעלת חוב\/ מדינה חייבת\/ מדינה בעלת מאזן מסחרי שלילי",
  en: "Debtor nation"
 },
 {
  ar: "دولة مصنعة",
  he: "מדינה מתועשת",
  en: "Industrialized country"
 },
 {
  ar: "دولة نامية",
  he: "מדינה מתפתחת",
  en: "Developing country"
 },
 {
  ar: "ديموقراطية صناعية",
  he: "דמוקרטיה תעשייתית",
  en: "Industrial country"
 },
 {
  ar: "دين ثابت يحكم",
  he: "חוב שבית הדין חייב לשלמו\/ חוב פסוק",
  en: "Judgment country"
 },
 {
  ar: "دين خارجي",
  he: "חוב חיצוני",
  en: "External country"
 },
 {
  ar: "دين داخلي",
  he: "חוב פנימי",
  en: "Internal country"
 },
 {
  ar: "دين دفتري",
  he: "חוב בספרים",
  en: "Book debt"
 },
 {
  ar: "دين غير مضمون",
  he: "חוב מסופק",
  en: "Doubtful debt"
 },
 {
  ar: "دين قومي",
  he: "חוב לאומי",
  en: "National debt"
 },
 {
  ar: "دين قومي داخلي",
  he: "חוב לאומי פנימי",
  en: "Internal national debt"
 },
 {
  ar: "دين مؤجل الدفع",
  he: "אשראי נדחה",
  en: "Deferred credit"
 },
 {
  ar: "دين مستحق",
  he: "חוב שטרם נפרע",
  en: "Outstanding debt"
 },
 {
  ar: "دين مضمون",
  he: "חוב מובטח",
  en: "Secured debt"
 },
 {
  ar: "دين مقسط",
  he: "חוב המוחזר בתשלומים",
  en: "Installment debt"
 },
 {
  ar: "دين مكفول",
  he: "חוב מיוצב",
  en: "Funded debt"
 },
 {
  ar: "دين موحد",
  he: "חוב מאוחד",
  en: "Consolidated debt"
 },
 {
  ar: "ذهب",
  he: "זהב",
  en: "gold"
 },
 {
  ar: " ",
  he: null,
  en: null
 }
]
export default dal ;