let s=[
 {
  ar: "ساري المفعول",
  he: "בר תוקף",
  en: "valid"
 },
 {
  ar: "ساعات الدوام",
  he: "שעות העבודה",
  en: "Office hours\/ hours of work"
 },
 {
  ar: "ساعات العمل",
  he: "שעות העבודה",
  en: "Office hours\/ hours of work"
 },
 {
  ar: "ساعة عمل",
  he: "שעת עבודה",
  en: "Working hour"
 },
 {
  ar: "سبيكة",
  he: "מטיל (של זהב, כסף)",
  en: "bullion"
 },
 {
  ar: "سحب",
  he: "משיכה",
  en: "Drawing\/ draft"
 },
 {
  ar: "سحب الاستثمار",
  he: "תצרוכת הון (בחברה)\/ השקעה שלילית",
  en: "disinvestment"
 },
 {
  ar: "سحب على المكشوف",
  he: "משיכת יתר",
  en: "overdraft"
 },
 {
  ar: "سداد",
  he: "סילוק (חשבון)",
  en: "Defrayment\/ clearance"
 },
 {
  ar: "سرقة",
  he: "גניבה",
  en: "theft"
 },
 {
  ar: "سعر",
  he: "שער",
  en: "Rate"
 },
 {
  ar: null,
  he: "מחיר",
  en: "price\/ cost"
 },
 {
  ar: "سعر اداري",
  he: "מחיר שבפיקוח",
  en: "Administered price"
 },
 {
  ar: "سعر ادنى محدد لسلعة مطروحة بالمزاد",
  he: "מחיר מינימאלי במכירה פומבית\/ מחיר פתיחה",
  en: "Upset price\/ first price\/ opening price"
 },
 {
  ar: "سعر اعادة الخصم",
  he: "שער ניכיון המשנה",
  en: "Rediscount rate"
 },
 {
  ar: "سعر الاصدار",
  he: "מחיר הנפקה",
  en: "Issue price"
 },
 {
  ar: "سعر الافتتاح",
  he: "מחיר התחלתי\/ מחיר פתיחה",
  en: "Upset price\/ first price\/ opening price"
 },
 {
  ar: "سعر الاقفال",
  he: "שער סגירה",
  en: "Closing price"
 },
 {
  ar: "سعر البضاعة مع التامين والشحن",
  he: "עלות ביטוח תובלה",
  en: "Cost insurance freight"
 },
 {
  ar: "سعر البيع",
  he: "מחיר מכירה",
  en: "Selling price"
 },
 {
  ar: "سعر التبادل",
  he: "שער חליפין",
  en: "Exchange rate"
 },
 {
  ar: "سعر التحويل",
  he: "שער חליפין",
  en: "Exchange rate"
 },
 {
  ar: "سعر التحويل الرسمي",
  he: "שער חליפין רשמי",
  en: "Official exchange rate"
 },
 {
  ar: "سعر التسليم",
  he: "מחיר מסירה",
  en: "Delivered price"
 },
 {
  ar: "سعر التكلفة مضاف الية ربح معين",
  he: "עלות פלוס (רווח)",
  en: "Cost plus"
 },
 {
  ar: "سعر الخصم",
  he: "שער ניכיון",
  en: "Discount rate"
 },
 {
  ar: "سعر الخصم الادنى لدى المصارف",
  he: "ריבית הפריים\/ שער הריבית הבסיסי ",
  en: "Prime interest rate"
 },
 {
  ar: "سعر الخصم لدى المصارف",
  he: "שער בנקאי\/ שער הריבית הבנקאי",
  en: "Bank rate"
 },
 {
  ar: "سعر السلعة العائد للمنتج",
  he: "עלות גורמי הייצור",
  en: "Factor cost"
 },
 {
  ar: "سعر السوق",
  he: "מחיר השוק",
  en: "Market price"
 },
 {
  ar: "سعر السوق الحرة",
  he: "מחיר הוגן",
  en: "Arm's length price\/ fair price"
 },
 {
  ar: "سعر الشراء",
  he: "מחיר קניה",
  en: "Buying price\/ purchase price"
 },
 {
  ar: "سعر الصرف",
  he: "שער חליפין",
  en: "Exchange rate"
 },
 {
  ar: "سعر الصرف الاجل",
  he: "שער עתידי",
  en: "Forward rate"
 },
 {
  ar: "سعر الطلب",
  he: "מחיר ביקוש",
  en: "Demand rate"
 },
 {
  ar: "سعر العقد",
  he: "מחיר הננקב בחוזה",
  en: "Contract rate"
 },
 {
  ar: "سعر الفائدة",
  he: "שער הריבית",
  en: "Interest rate\/ rate of interest"
 },
 {
  ar: "سعر الفائدة الرئيسي",
  he: "ריבית הפריים\/ שער הריבית הבסיסי",
  en: "Prime interest rate "
 },
 {
  ar: "سعر الفتح",
  he: "מחיר התחלתי\/ מחיר פתיחה",
  en: "Upset price\/ first price\/ opening price"
 },
 {
  ar: "سعر القطع لدى المصارف",
  he: "שער בנקאי\/ שער הריבית הבנקאי",
  en: "Bank rate"
 },
 {
  ar: "سعر المفرق",
  he: "מחיר קמעוני",
  en: "Retail price"
 },
 {
  ar: "سعر الوحدة",
  he: "מחיר ליחידה",
  en: "Unit price"
 },
 {
  ar: "سعر باهظ",
  he: "מחיר מופרז",
  en: "Prohibitive price\/ excessive price "
 },
 {
  ar: "سعر تنافسي",
  he: "מחיר תחרות",
  en: "Competitive price"
 },
 {
  ar: "سعر ثابت",
  he: "שער בסיסי",
  en: "Flat rate\/ basic rate"
 },
 {
  ar: "سعر خارج البورصة",
  he: "מחיר מחוץ לבורסה",
  en: "Street price"
 },
 {
  ar: "سعر صافي",
  he: "מחיר נטו",
  en: "Net price"
 },
 {
  ar: "سعر صرف ثابت",
  he: "שער חליפין קבוע",
  en: "Fixed exchange rate"
 },
 {
  ar: "سعر عائم",
  he: "שער נייד",
  en: "Floating rate"
 },
 {
  ar: "سعر عادل",
  he: "מחיר הוגן",
  en: "Arm's length price\/ fair price "
 },
 {
  ar: "سعر عادي",
  he: "מחיר נורמאלי",
  en: "Normal price"
 },
 {
  ar: "سعر فاحش",
  he: "מחיר מופרז",
  en: "Prohibitive price\/ excessive price"
 },
 {
  ar: "سعر مخفض",
  he: "מחיר מופחת",
  en: "Reduced price"
 },
 {
  ar: "سعر معتدل",
  he: "מחיר סביר",
  en: "Moderate price"
 },
 {
  ar: "سعر معدل",
  he: "שער יציג",
  en: "Representative rate"
 },
 {
  ar: "سعر معروض",
  he: "מחיר מוצע",
  en: "quotation"
 },
 {
  ar: "سعر مفروض",
  he: "מחיר שבפיקוח",
  en: "Administered price"
 },
 {
  ar: "سفتجة",
  he: "שטר חליפין",
  en: "Bill of exchange\/ draft"
 },
 {
  ar: "سقف",
  he: "תקרה",
  en: "ceiling"
 },
 {
  ar: "س ك النقود",
  he: "הטבעת מטבעת",
  en: "minting"
 },
 {
  ar: "سكرتير",
  he: "מזכיר",
  en: "secretary"
 },
 {
  ar: "سلة العملات",
  he: "סל מטבעות",
  en: "Currency basket"
 },
 {
  ar: "سلطة ادارية",
  he: "רשות מנהלית",
  en: "Administrative authority"
 },
 {
  ar: "سلع",
  he: "טובין",
  en: "goods"
 },
 {
  ar: "سلع متكاملة",
  he: "מוצרים משלימים",
  en: "Complementary goods"
 },
 {
  ar: "سلع متنافسة",
  he: "מוצרים תחליפיים",
  en: "Competitive goods\/ substitutes"
 },
 {
  ar: "سلع مستوردة",
  he: "סחורות מיובאות",
  en: "Import goods"
 },
 {
  ar: "سلع مصدرة",
  he: "סחורות מיוצאות",
  en: "Export goods"
 },
 {
  ar: "سلعة",
  he: "מצרך\/ סחורה\/ מוצר",
  en: "Commodity\/ good\/ product"
 },
 {
  ar: "سلعة استهلاكية",
  he: "מוצר צריכה",
  en: "Consumers' good"
 },
 {
  ar: "سلعة اقتصادية",
  he: "מצרך כלכלי",
  en: "Economic good"
 },
 {
  ar: "سلعة اكثر رواجا",
  he: "רב מכר",
  en: "Best seller"
 },
 {
  ar: "سلعة دنيا",
  he: "מוצר נחות",
  en: "Inferior good"
 },
 {
  ar: "سلعة شبة متينة",
  he: "מוצר בר קיימא למחצה",
  en: "Semi durable good"
 },
 {
  ar: "سلعة كمالية",
  he: "מוצר מותרות",
  en: "Luxury article"
 },
 {
  ar: "سلعة متينة",
  he: "מוצר בר קיימא",
  en: "Durable good"
 },
 {
  ar: "سلعة مغرية",
  he: "מוצר פיתוי",
  en: "bait"
 },
 {
  ar: "سلعة منتجة",
  he: "מוצר ייצור",
  en: "Producer's good"
 },
 {
  ar: "سلف",
  he: "הלוואה ללא ריבית",
  en: "Interest free loan"
 },
 {
  ar: "سلفة",
  he: "מקדמה",
  en: "advance"
 },
 {
  ar: "سمسار",
  he: "ברוקר",
  en: "Broker"
 },
 {
  ar: null,
  he: "ברוקר העוסק במניות\/ סוכן בורסה",
  en: "stock broker"
 },
 {
  ar: "سمسار العقارات",
  he: "סוחר בנדל\"ן",
  en: "Real estate broker"
 },
 {
  ar: "سمسار اوراق مالية",
  he: "ג'ובר",
  en: "Jobber"
 },
 {
  ar: null,
  he: "סוחר מניות\/ סוכן מניות",
  en: "stock jobber"
 },
 {
  ar: "سمسار تامين",
  he: "נציג חברת ביטוח",
  en: "Insurance broker"
 },
 {
  ar: "سمسار مرخص لة",
  he: "סוכן מורשה",
  en: "Licensed broker\/ licensed dealer"
 },
 {
  ar: "سمسرة",
  he: "דמי תיווך",
  en: "brokerage"
 },
 {
  ar: "سمعة",
  he: "מוניטין",
  en: "reputation"
 },
 {
  ar: "سن التقاعد",
  he: "גיל פרישה",
  en: "Retirement age"
 },
 {
  ar: "سناهية",
  he: "קצבה שנתית",
  en: "annuity"
 },
 {
  ar: "سنة الاساس",
  he: "שנת בסיס",
  en: "Base year"
 },
 {
  ar: "سنة مالية",
  he: "שנת כספים",
  en: "Financial year\/ fiscal year"
 },
 {
  ar: "سند",
  he: "איגרת חוב",
  en: "Debenture\/ bond"
 },
 {
  ar: null,
  he: "נייר ערך",
  en: " security"
 },
 {
  ar: null,
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "سند اجنبي",
  he: "איגרת חוב זרה",
  en: "Foreign bond"
 },
 {
  ar: "سند اختياري",
  he: "איגרת חוב אופציה",
  en: "Optional bond"
 },
 {
  ar: "سند ادنى",
  he: "שטר חוב",
  en: "Promissory note"
 },
 {
  ar: "سند اسمي",
  he: "נייר ערך על שם",
  en: "Registered security"
 },
 {
  ar: "سند اولي",
  he: "איגרת חוב ראשונית",
  en: "Primary bond"
 },
 {
  ar: "سند بدون فائدة",
  he: "איגרת חוב שאינה נושאת ריבית",
  en: "Non- interest bearing bond"
 },
 {
  ar: "سند بفائدة",
  he: "איגרת חוב נושאת ריבית ",
  en: "Interest bearing bond"
 },
 {
  ar: "سند بلدي",
  he: "איגרת חוב מוניציפאלית",
  en: "Municipal bond"
 },
 {
  ar: "سند بيع",
  he: "שטר מכר",
  en: "Bill of sale"
 },
 {
  ar: "سند حكومي",
  he: "איגרת חוב ממשלתית",
  en: "Government bond"
 },
 {
  ar: null,
  he: "נייר ערך ממשלתי",
  en: "Government security"
 },
 {
  ar: "سند دين",
  he: "איגרת חוב",
  en: "Debenture\/ bond"
 },
 {
  ar: "سند ذو اجل",
  he: "איגרת חוב בלתי צמיתה",
  en: "Tern bond"
 },
 {
  ar: "سند ذو انصبة",
  he: "איגרת חוב נושאת פרסים",
  en: "Premium bond"
 },
 {
  ar: "سند ذوعوائد ثابتة  ",
  he: "נייר ערך בעל תשואה קבועה",
  en: "Fixed yield security"
 },
 {
  ar: "سند شركة",
  he: "איגרת חוב המונפקת על ידי חברה",
  en: "Corporate bond"
 },
 {
  ar: "سند على الخزينة",
  he: "שטר אוצר",
  en: "Treasury bill"
 },
 {
  ar: "سند غير قابل للتداول",
  he: "איגרת חוב בלתי סחירה",
  en: "Non negotiable bond"
 },
 {
  ar: "سند قابل للتحويل",
  he: "איגרת חוב ניתנת להמרה במניות\/ איגרת חוב בת המרה",
  en: "Convertible bond"
 },
 {
  ar: "سند لا ترد قيمتة",
  he: "נייר ערך שאינו בר פדיון",
  en: "Irredeemable security"
 },
 {
  ar: "سند لحاملة",
  he: "נייר ערך למוכ\"ז",
  en: "Dearer security"
 },
 {
  ar: "سند لحاملة بقسائم",
  he: "איגרת חוב נושאת תלושים",
  en: "Coupon bond"
 },
 {
  ar: "سند مالي",
  he: "בטוחה\/ נייר ערך",
  en: "security"
 },
 {
  ar: "سند مستحق",
  he: "שטר שחל זמן פירעונו",
  en: "Due bill"
 },
 {
  ar: "سند مستحق الدفع",
  he: "שטר לפירעון",
  en: "Bill payable"
 },
 {
  ar: "سند مستحق القبض",
  he: "שטר לגבייה",
  en: "Bill receivable"
 },
 {
  ar: "سند مسجل في قائمة البورصة",
  he: "נייר ערך רשום בבורסה",
  en: "Listed security"
 },
 {
  ar: "سند مسوق",
  he: "נייר ערך סחיר",
  en: "Marketable security"
 },
 {
  ar: "سند مشارك",
  he: "איגרת חוב משתתפת",
  en: "Participating bond"
 },
 {
  ar: "سند مصرفي",
  he: "שטר כסף",
  en: "Bank bill\/ paper money\/ currency note"
 },
 {
  ar: "سند مضمون",
  he: "איגרת חוב מובטחת",
  en: "Guaranteed bond\/ secured bond"
 },
 {
  ar: "سند من فئة أ",
  he: "איגרת חוב מסוג א",
  en: "A bond"
 },
 {
  ar: "سند منهج",
  he: "איגרת חוב מווסתת",
  en: "Managed bond"
 },
 {
  ar: "سنديك",
  he: "כונס נכסים",
  en: "Official receiver"
 },
 {
  ar: "سهم",
  he: "מניה",
  en: "Stock\/ share"
 },
 {
  ar: "سهم الدرجة الاولى",
  he: "מניה בטוחה",
  en: "Bluechip share"
 },
 {
  ar: "سهم بلا قيمة اسمية",
  he: "מניה ללא ערך נקוב",
  en: "No-par share"
 },
 {
  ar: "سهم تصويت",
  he: "מניה בעלת זכות הצבעה",
  en: "Voting share"
 },
 {
  ar: "سهم ربح",
  he: "מניית הטבה",
  en: "Bonus share"
 },
 {
  ar: "سهم عادي",
  he: "מניה רגילה",
  en: "Ordinary share\/ common share"
 },
 {
  ar: "سهم علاوة",
  he: "מניית הטבה",
  en: "Bonus share"
 },
 {
  ar: "سهم غير تصويتي",
  he: "מניה ללא זכות הצבעה",
  en: "Non-voting share"
 },
 {
  ar: "سهم غير مسجل في البورصة",
  he: "מניה שאינה רשומה למסחר בבורסה",
  en: "Unlisted share"
 },
 {
  ar: "سهم غير مصدر",
  he: "מניה שלא הונפקה",
  en: "Unissued share"
 },
 {
  ar: "سهم قائد",
  he: "מניה מובילה",
  en: "Leading share"
 },
 {
  ar: "سهم قابل للاسترداد",
  he: "מניה בת פדיון",
  en: "Redeemable share"
 },
 {
  ar: "سهم قابل للتحويل",
  he: "מניה בת המרה",
  en: "Convertible share"
 },
 {
  ar: "سهم مؤجل",
  he: "מניה נדחית ",
  en: "Deferred share"
 },
 {
  ar: "سهم متعدد الاصوات",
  he: "מניה בעלת זכות הצבעה כפולה",
  en: "Multiple voting share"
 },
 {
  ar: "سهم مدرج",
  he: "מניה רשומה",
  en: "Quoted share\/ authorized share"
 },
 {
  ar: "سهم مدفوع القيمة جزئيا",
  he: "מניה שנפרעה בחלקה",
  en: "Part paid share"
 },
 {
  ar: "سهم مسترد",
  he: "מניה המוחזקת על ידי החברה המנפיקה",
  en: "Treasury share"
 },
 {
  ar: "سهم مسجل في البورصة",
  he: "מניה רשומה למסחר בבורסה",
  en: "Listed share"
 },
 {
  ar: "سهم مسدد القيمة",
  he: "מניה נפרעת במלואה",
  en: "Paid up share"
 },
 {
  ar: "سهم مسقط",
  he: "מניה חלוטה",
  en: "Forfeited share "
 },
 {
  ar: "سهم مصرح بة",
  he: "מניה רשומה",
  en: "Quoted share\/ authorized share"
 },
 {
  ar: "سهم مطروح",
  he: "מניה המוחזקת למטרת ספקולציה",
  en: "Floating share"
 },
 {
  ar: "سهم مكافاة",
  he: "מניית הטבה",
  en: "Bonus share"
 },
 {
  ar: "سهم ممتاز",
  he: "מניית בכורה",
  en: "Preference share"
 },
 {
  ar: "سهم ممتاز غير متراكم الارباح",
  he: "מניית בכורה שאינה צוברת רווחים",
  en: "Non cumulative preferred share"
 },
 {
  ar: "سهم ممتاز محدود الارباح",
  he: "מנית בכורה בלי משתתפת",
  en: "Non participating preferred share"
 },
 {
  ar: "سهم منحة",
  he: "מניית הטבה",
  en: "Bonus share"
 },
 {
  ar: "سهم نصفي",
  he: "מניה בערך נקוב של חמישים דולר",
  en: "Half stock"
 },
 {
  ar: "سوق",
  he: "שוק",
  en: "market"
 },
 {
  ar: "سوق الاسهم",
  he: "שוק המניות",
  en: "Stock market"
 },
 {
  ar: "سوق الاوراق المالية",
  he: "שוק ניירות הערך",
  en: "Stock market"
 },
 {
  ar: "سوق الاوراق المالية الجديدة",
  he: "שוק ההנפקות המקורי\/ שוק ההנפקות הראשון\/ שוק ראשוני לניירות ערך",
  en: "New issue market"
 },
 {
  ar: "السوق الاوروبية المشتركة",
  he: "השוק האירופי המשותף",
  en: "European common market"
 },
 {
  ar: "السوق الحرة",
  he: "דיוטי פרי\/ חנות פטורה ממכס",
  en: "Duty free (shop) "
 },
 {
  ar: "سوق الخفاء",
  he: "מסחר בניירות ערך שאינם רשומים בבורסה",
  en: "Crub market\/ overs accounter"
 },
 {
  ar: "سوق الراسمال",
  he: "שוק הון",
  en: "Capital market"
 },
 {
  ar: "سوق الرساميل",
  he: "שוק הון",
  en: "Capital market"
 },
 {
  ar: "سوق السلع",
  he: "שוק הסחורות",
  en: "Commodity market"
 },
 {
  ar: "سوق الصفقات الانية",
  he: "שור שבו המסירה מיידית",
  en: "Spot market"
 },
 {
  ar: "سوق الصفقات الفورية",
  he: "שוק שבו המסירה מיידית",
  en: "Spot market"
 },
 {
  ar: "سوق العمل",
  he: "שוק העבודה",
  en: "Labour market\/ work market"
 },
 {
  ar: "سوق القروض",
  he: "שוק ההלוואות",
  en: "Loan market"
 },
 {
  ar: "سوق القطع الاجلة",
  he: "שוק לסחורות עתידיות",
  en: "Forward exchange market"
 },
 {
  ar: "سوق النفط",
  he: "שוק הנפט",
  en: "Oil market"
 },
 {
  ar: "سوق ثانوية",
  he: "שוק משני לניירות ערך",
  en: "Secondary market"
 },
 {
  ar: "سوق حرة",
  he: "שוק חופשי",
  en: "Free market\/ open market"
 },
 {
  ar: "سوق سوداء",
  he: "שוק שחור",
  en: "Black market"
 },
 {
  ar: "سوق غير كاملة",
  he: "שוק בלתי משוכלל",
  en: "Imperfect market"
 },
 {
  ar: "سوق كاملة",
  he: "שוק משוכלל",
  en: "Perfect market"
 },
 {
  ar: "سوق مالية",
  he: "שוק כספים",
  en: "Financial market\/ money market"
 },
 {
  ar: "سوق مشتركة",
  he: "שוק משותף",
  en: "Common market"
 },
 {
  ar: "سوق نشطة",
  he: "שוק פעיל",
  en: "Active market"
 },
 {
  ar: "سوق نقدية",
  he: "שוק מוניטארי",
  en: "Monetary market"
 },
 {
  ar: "سوق يورو",
  he: "שור יורו",
  en: "Euromarket"
 },
 {
  ar: "سياحة",
  he: "תיירות",
  en: "tourism"
 },
 {
  ar: "سياسة اقتصادية",
  he: "מדיניות כלכלית",
  en: "Economic policy"
 },
 {
  ar: "سياسة الاسعار",
  he: "מדיניות מחירים",
  en: "Price policy"
 },
 {
  ar: "سياسة تحريك الاقتصاد",
  he: "מדיניות של מתן עבודות יוזמה לשם האצת הפעילות הכלכלית",
  en: "Pump policy"
 },
 {
  ar: "سياسة دفع ارباح الاسهم",
  he: "מדיניות דיווידנד",
  en: "Dividend policy"
 },
 {
  ar: "سياسة سعرية",
  he: "מדיניות מחירים",
  en: "Price policy"
 },
 {
  ar: "سياسة ضريبية",
  he: "מדיניות פיסקאלית",
  en: "Fiscal policy"
 },
 {
  ar: "سياسة مالية",
  he: "מדיניות פיסקאלית",
  en: "Fiscal policy"
 },
 {
  ar: "سياسة نقدية",
  he: "מדיניות מוניטארית",
  en: "Monetary policy"
 },
 {
  ar: "سيولة",
  he: "נזילות",
  en: "liquidity"
 },
 {
  ar: "سيولة زائدة",
  he: "נזילות עודפת",
  en: "Over liquidity"
 }
]
export default s ;