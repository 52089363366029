import React, {useState} from 'react';
import { Helmet } from "react-helmet";

import {HeaderFigures} from './HeaderFigures';
import {CalculatorControls} from './CalculatorControls';
import {PaymentGraph} from './PaymentGraph';
import {PaymentTable} from './PaymentTable';
import { handleMortgageDataChange } from './utils';
import '../style.css';

export const MortageCalculator = () => {
	const [depositAmount, setDepositAmount] = useState(72000);
	const [purchasingHousePrice, setPurchasingHousePrice] = useState(285000);
	const [mortgageTerm, setMortgageTerm] = useState(25);
	const [interestRate, setInterestRate] = useState(1.8);

	//Set initial values for the whole mortgage term
	const amountToBorrow = purchasingHousePrice - depositAmount;
	const monthlyPayment = ((interestRate / 100 / 12) * amountToBorrow) / (1 - (Math.pow((1 + (interestRate / 100 / 12)),((0 - mortgageTerm) * 12))));
	const totalRepaid = monthlyPayment * 12 * mortgageTerm;
	const totalInterestPaid = totalRepaid - amountToBorrow;

	const yearlyPayments = handleMortgageDataChange(amountToBorrow, mortgageTerm, interestRate, monthlyPayment);

	return(
		<>
		<div className="page-wrapper">
		<Helmet>
      <title>Mass Portal</title>
      <meta name="description" content="Dashboard"/>					
    </Helmet>
	       {/* Page Content */}
		   <div className="content container-fluid">
		   <div className="inner-header text-center">
              <br></br>
             

              <h1>قروض</h1>
              
					  <p className="privacy-bred"><a href="/">الرئيسية</a></p>
					  
					
              <br></br>
           
            </div>
			<HeaderFigures 
				amountToBorrow={amountToBorrow}
				monthlyPayment={monthlyPayment}
				totalRepaid={totalRepaid}
				totalInterestPaid={totalInterestPaid}
				depositAmount={depositAmount}
				purchasingHousePrice={purchasingHousePrice}
			/>
			<CalculatorControls 
				depositAmount={depositAmount}
				setDepositAmount={setDepositAmount}
				purchasingHousePrice={purchasingHousePrice}
				setPurchasingHousePrice={setPurchasingHousePrice}
				mortgageTerm={mortgageTerm}
				setMortgageTerm={setMortgageTerm}
				interestRate={interestRate}
				setInterestRate={setInterestRate}
			/>
			{/* <PaymentGraph
				mortgageTerm={mortgageTerm}
				yearlyPayments={yearlyPayments}
			/> */}
			<PaymentTable
				amountToBorrow={amountToBorrow}
				yearlyPayments={yearlyPayments}
			/>

			</div>
			</div>
		</>
	);

}