/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Itt21 from '../../../components/Tables/basic21';
import Itt22 from '../../../components/Tables/basic22/itt22';
import { TaxCalculatorComponent21 } from '../../components/TaxCalculatorComponent21';
import { TaxCalculatorComponent22 } from '../../components/TaxCalculatorComponent22';
export default class VatTable extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | جدول نسب القيمة المضافة     </title>
					<meta name="description" content="MassPortal - جدول نسب القيمة المضافة  " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>جدول نسب القيمة المضافة   </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>
						<div className="row">

							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className='col-lg-1'>
							</div>
							<div className="col-lg-10 " >
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy">جدول نسب القيمة المضافة </h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead>
													<tr >
														<th > تاريخ التغيير  </th>
														<th >نسبة الضريبة / مشغل  </th>
														<th >نسبة الضريبة / جمعيات  </th>
														<th>نسبة الضريبة / مؤسسة تمويلية  </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>01/10/2015</td>
														<td> 17.00 <span className='privacy'> % </span> </td>
														<td> 7.50 <span className='privacy'> % </span> </td>
														<td> 17.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>02/06/2013</td>
														<td> 18.00 <span className='privacy'> % </span> </td>
														<td> 7.50 <span className='privacy'> % </span> </td>
														<td> 18.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/06/2013</td>
														<td> 17.00 <span className='privacy'> % </span> </td>
														<td> 7.50 <span className='privacy'> % </span> </td>
														<td> 18.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/09/2012</td>
														<td> 17.00 <span className='privacy'> % </span> </td>
														<td> 7.50 <span className='privacy'> % </span> </td>
														<td> 17.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/01/2010</td>
														<td> 16.00 <span className='privacy'> % </span> </td>
														<td> 7.50 <span className='privacy'> % </span> </td>
														<td> 16.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/07/2009</td>
														<td> 16.50 <span className='privacy'> % </span> </td>
														<td> 7.50 <span className='privacy'> % </span> </td>
														<td> 16.50 <span className='privacy'> % </span> </td>
													</tr>

													<tr>
														<td>01/07/2006</td>
														<td> 15.50 <span className='privacy'> % </span> </td>
														<td> 7.50 <span className='privacy'> % </span> </td>
														<td> 15.50 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/09/2005</td>
														<td> 16.50 <span className='privacy'> % </span> </td>
														<td> 8.50 <span className='privacy'> % </span> </td>
														<td> 17.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/03/2004</td>
														<td> 17.00 <span className='privacy'> % </span> </td>
														<td> 8.50 <span className='privacy'> % </span> </td>
														<td> 17.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/07/2002</td>
														<td> 18.00 <span className='privacy'> % </span> </td>
														<td> 9.00 <span className='privacy'> % </span> </td>
														<td> 18.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>15/06/2002</td>
														<td> 18.00 <span className='privacy'> % </span> </td>
														<td> 8.75 <span className='privacy'> % </span> </td>
														<td> 17.50 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/06/2002</td>
														<td> 17.00 <span className='privacy'> % </span> </td>
														<td> 8.75 <span className='privacy'> % </span> </td>
														<td> 17.50 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/01/1993</td>
														<td> 17.00 <span className='privacy'> % </span> </td>
														<td> 8.50 <span className='privacy'> % </span> </td>
														<td> 17.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/01/1991</td>
														<td> 18.00 <span className='privacy'> % </span> </td>
														<td> 9.00<span className='privacy'> % </span> </td>
														<td> 18.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/03/1990</td>
														<td> 16.00 <span className='privacy'> % </span> </td>
														<td> 6.70<span className='privacy'> % </span> </td>
														<td> 16.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/07/1985</td>
														<td> 15.00 <span className='privacy'> % </span> </td>
														<td> 6.25<span className='privacy'> % </span> </td>
														<td> 15.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/06/1985</td>
														<td> 17.00 <span className='privacy'> % </span> </td>
														<td> 6.25<span className='privacy'> % </span> </td>
														<td> 17.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/08/1982</td>
														<td> 15.00 <span className='privacy'> % </span> </td>
														<td> 6.25<span className='privacy'> % </span> </td>
														<td> 15.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/11/1977</td>
														<td> 12.00 <span className='privacy'> % </span> </td>
														<td> 5.00<span className='privacy'> % </span> </td>
														<td> 9.00 <span className='privacy'> % </span> </td>
													</tr>
													<tr>
														<td>01/07/1976</td>
														<td> 8.00 <span className='privacy'> % </span> </td>
														<td> 3.00<span className='privacy'> % </span> </td>
														<td> 6.00 <span className='privacy'> % </span> </td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-1'>
							</div>

							{/* =============================================================== */}
						</div>
						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>
		);
	}
}
