import React from 'react';

const localeOptions = {
	style:'currency',
	currency:'ILS',
	minimumFractionDigits: 0,
    maximumFractionDigits: 0
}

export const CalculatorControls = props => {
	return(
		
		<div className="grid__container card-group card m-b-30" dir='rtl'>			
			<div className="grid__item  ">
				<span className="grid__item--header">{parseInt(props.purchasingHousePrice).toLocaleString('ILS', localeOptions)}</span>
				<input 
					type="range"
					className="grid__item--range-slider"
					id="purchasingHousePrice"  
					min="50000" 
					max="750000" 
					step="1000" 
					value={props.purchasingHousePrice} 
					onChange={(event) => 
						props.setPurchasingHousePrice(event.target.value)
					} />
				<label className="grid__item--label" htmlFor="purchasingHousePrice">المبلغ المطلوب</label>
			</div>
			<div className="grid__item" dir='rtl'>
				<span className="grid__item--header">{parseInt(props.depositAmount).toLocaleString('ILS', localeOptions)}</span>
				<input 
					type="range"
					className="grid__item--range-slider "
					id="depositAmount" 
					min="1000" 
					max="150000" 
					step="1000" 
					value={props.depositAmount}
         			onChange={(event) =>
						props.setDepositAmount(event.target.value)
         			} />
				<label className="grid__item--label" htmlFor="points">ودائع </label>
			</div>
			<div className="grid__item" dir='rtl'>
				<span className="grid__item--header">{props.mortgageTerm}سنة</span>
				<input 
					type="range"
					className="grid__item--range-slider"
					id="mortgageTerm"  
					min="5" 
					max="35" 
					step="1" 
					value={props.mortgageTerm} 
					onChange={(event) => 
						props.setMortgageTerm(event.target.value)
					} />
				<label className="grid__item--label" htmlFor="mortgageTerm">مدة القرض</label>
			</div>
			
			<div className="grid__item" dir='rtl'>
				<span className="grid__item--header">{props.interestRate}%</span>
				<input 
					type="range"
					className="grid__item--range-slider"
					id="interestRate"  
					min="0.1" 
					max="8" 
					step="0.1" 
					value={props.interestRate} 
					onChange={(event) => 
						props.setInterestRate(event.target.value)
					} />
				<label className="grid__item--label" htmlFor="interestRate">سعر الفائدة</label>
			</div>
		</div>
		
	);
}