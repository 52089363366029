/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
// import { CalculatorAnnual } from './CalculatorAnnual';
import { Tax21Calc } from '../components/Tax21Calc';
import Itt21 from '../../components/Tables/basic21';
// import { NatoTaxCalc21 } from './NatoTaxCalc21';
import { NatoTaxCalc22 } from '../WorkLawsCalc/NatoTaxCalc22';
export default class Nato22 extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | 2022 - Calculator</title>
					<meta name="description" content="Reactify Blank Page" />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					{/* Page Header */}
					<div className="page-header">
						<div className="row">
							<div className="col-sm-12">
							<h3 className="page-title">     Nato -   <span className='privacy'> 2022 </span>      </h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
									{/* <li className="breadcrumb-item active">Blank Page</li>
							 */}
								</ul>
							</div>
						</div>
					</div>
					{/* /Page Header */}
					{/* Content Starts */}

					<div className="row">
						
							<div className='row'>

								{/* <div className="col-md-6 tax22-calc ">
									<br></br>
									<br></br>
									<br></br>
									<Tax22Calc className="tax22-calc " />
									<br></br>
									<br></br>
								</div>

								<div className="col-md-6  ">
									<Itt22 />
								</div> */}

								<div className="col-md-12  ">
									<NatoTaxCalc22 />
								</div>

							</div>




						
					</div>
					{/* /Content End */}
				</div>
				{/* /Page Content */}
			</div>

		);
	}
}
