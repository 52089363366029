/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { AiOutlineFilePdf, AiOutlineLink, AiFillCalculator, AiOutlineCalculator } from "react-icons/ai";
import { massportallogo } from '../../../../Entryfile/imagepath';
import ShaamTaxHe from './ShaamTax-hebrew';
import ShaamTaxAr from './ShaamTax-ar';

class ShaamTax extends Component {

  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - Tax</title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">

          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">تصريح لسلطات الضريبة  </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                  <br></br>
                  {/* <li className="breadcrumb-item active">Blank Page</li>
							 */}
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className='row'>
            <ShaamTaxAr />

          </div>

          <div className='row'>
            <ShaamTaxHe />
          </div>


{/* Buttons With Links - Start */}
<div className='row'>
            <h2 className='text-center '> دفعات للتأمين الوطني  </h2>
            <div className='col-md-1'>

            </div>
          
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 btn-div">
            <br></br>
            <br></br>
            <a href="https://b2b.btl.gov.il/btl.ilg.payments/GalashForm.aspx" target='_blank' class="btn btn-primary btn-block btn-download ">
              <span className='button-links'>دفعات تأمين وطني ( ملف مستقل )</span>
            </a>
            <br></br>
            <br></br>
          </div>

          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 btn-div">
            <br></br>
            <br></br>
            <a href="https://b2b.btl.gov.il/BTL.ILG.Payments/ChovDochMaasikForm.aspx" target='_blank' class="btn btn-primary btn-block btn-download ">
              <span className='button-links'> دفعات تأمين وطني ( اقتطاعات )</span>
            </a>
            <br></br>
            <br></br>
          </div>

         
        </div>
        {/* Buttons With Links - End */}


          {/* Buttons With Links - Start */}
          <div className='row'>
          <h2 className='text-center '> فحص ملفات  </h2>
          
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 btn-div">
              <br></br>
              <br></br>
              <a href="https://secapp.taxes.gov.il/EmDvhmfrt/wUploadFileHeshboniotSim.aspx" target='_blank' class="btn btn-primary btn-block btn-download ">
                <span className='button-links'> فحص ملف ( PCN )</span>
              </a>
              <br></br>
              <br></br>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 btn-div">
              <br></br>
              <br></br>
              <a href="https://www.misim.gov.il/ShWebLogiot126856/frmSetup08.aspx" target='_blank' class="btn btn-primary btn-block btn-download ">
                <span className='button-links'>فحص ( 856 \ 126 ) ( 2021 - 2008 )</span>
              </a>
              <br></br>
              <br></br>
            </div>

            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 btn-div">
              <br></br>
              <br></br>
              <a href="https://www.misim.gov.il/ShWebLogiot126856/frmSetup04.aspx" target='_blank' class="btn btn-primary btn-block btn-download ">
                <span className='button-links'>فحص ( 856 \ 126 ) ( 2007 - 2004 )</span>

              </a>
              <br></br>
              <br></br>
            </div>
          </div>
          {/* Buttons With Links - End */}
            
        </div>
        {/* /Page Content */}



      </div>
    );
  }
}

export default ShaamTax;
