import React, { useState, useEffect,useRef } from "react";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
import html2canvas from 'html2canvas';
import { FaInfo } from "react-icons/fa";
import { jsPDF } from 'jspdf';
import { useAuthContext } from "../../../hooks/useAuthContext";
import { massportallogo } from "../../../Entryfile/imagepath"
import { BiShekel } from 'react-icons/bi';
import ReactTooltip from "react-tooltip";
// import { useForm } from 'react-hook-form';
import { useForm } from 'react-hook-form';

export function AltmanCalc(props) {
  const printRef = React.useRef();

  const [showDev, setShowDev] = useState(false)
  const [isActive, setIsActive] = useState(false);

  //HOOKS
  const { user } = useAuthContext()
  // const {  register, handleSubmit, errors} = useForm();
  // const form = useRef();
//================  Form Validation : =================
const { register, handleSubmit, formState: {errors} } = useForm();
// const onSubmit = data => console.log(data);
// {"email":"abc@gmail.com"}

// ====================================================

  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "")
    );
  }
  const [altDate,setCurrentDate]=useState(' ')
  const [bankrupt, setBankrupt] = useState('');
  const [NonBankrupt, setNonBankrupt] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyType, setCompanyType] = useState('private');
  const [hon, setHon] = useState('');
  const [fixed, setFixed] = useState('');
  const [profit, setProfit] = useState('');
  const [capital, setCapital] = useState('');
  const [wanted, setWanted] = useState('');
  const [sales, setSales] = useState('');
  const [netProfit, setNetProfit] = useState('');
  const [tax, setTax] = useState(0);
  const [label3Value, setLabel3Value] = useState('')
  const [situation, setSituation] = useState('')
  const [style, setStyle] = useState('')
  function addZero(i) {
    if (i < 10) { i = "0" + i }
    return i;
  }
  const date = new Date();
  let h = addZero(date.getHours());
  let m = addZero(date.getMinutes());
  let s = addZero(date.getSeconds());
  let time = h + ":" + m + ":" + s;
  const d = new Date().toLocaleString() + "";
  let current = d.split(',');
  const today = current[0];
  const todaySplitArr = today.split('/');
  const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];

  const reloadWindow = (evt) => {
    window.location.reload();
  }

  useEffect(() => {
    calculateTax(hon, fixed, profit, capital, wanted, sales, netProfit, companyType, style)


      , [hon, fixed, profit, capital, wanted, sales, netProfit, companyType, style]
  })

  function calculateTax(data) {




    if (tax < 0) {
      // console.log("cancel and return ")
      tax = 0;
    }


    const labelPublic = ((parseFloat(data.hon) / parseFloat(data.fixed)) * 1.2) + (1.4 * ((parseFloat(data.profit)) / parseFloat(data.fixed))) + (3.3 * (parseFloat(data.netProfit)) / parseFloat(data.fixed)) + (0.6 * ((parseFloat(data.capital)) / parseFloat(data.wanted))) + (parseFloat(data.sales) / parseFloat(data.fixed))
    const labelPrivate = ((parseFloat(data.hon) / parseFloat(data.fixed)) * 0.717) + (0.847 * ((parseFloat(data.profit)) / parseFloat(data.fixed))) + (3.107 * (parseFloat(data.netProfit)) / parseFloat(data.fixed)) + (0.42 * ((parseFloat(data.capital)) / parseFloat(data.wanted))) + (parseFloat(data.sales) / parseFloat(data.fixed))

console.log("labelPublic : ",labelPublic)


    if (companyType == 'private' && labelPrivate >= 2.9) {
      setNonBankrupt('4.14')
      setBankrupt('0.15')
      setLabel3Value(labelPrivate.toFixed(2));
      setSituation(' وضع الشركة سليم من الناحية المالية ')
      setStyle('text-success')
    }

    else if (companyType == 'public' && labelPublic >= 3) {
      setNonBankrupt('5.02')
      setBankrupt('0.29-')
      setLabel3Value(labelPublic.toFixed(2))
      setSituation(' وضع الشركة سليم من الناحية المالية ')
      setStyle('text-success')
    }
    else if (companyType == 'public' && labelPublic < 3 && labelPublic >= 2.77) {
      setNonBankrupt('5.02')
      setBankrupt('0.29-')
      setLabel3Value(labelPublic.toFixed(2))
      setSituation(' الحذر مطلوب وضع الشركة غير سليم من الناحية المالية  ')
      setStyle('text-secondary')
    }
    else if (companyType == 'public' && labelPublic < 2.77 && labelPublic >= 1.88) {
      setNonBankrupt('5.02')
      setBankrupt('0.29-')
      setLabel3Value(labelPublic.toFixed(2))
      setSituation('    وضع الشركة غير سليم من الناحية المالية من المحتمل إفلاسها في غضون عامين  ')
      setStyle('text-warning')
    }
    else if (companyType == 'public' && labelPublic < 1.88) {
      setNonBankrupt('5.02')
      setBankrupt('0.29-')
      setLabel3Value(labelPublic.toFixed(2))
      setSituation(' وضع الشركة غير سليم من الناحية المالية احتمال الإفلاس مرتفع ')
      setStyle('text-danger')
    }
    else if (companyType == 'private' && labelPrivate < 1.23) {
      setNonBankrupt('4.14')
      setBankrupt('0.15')
      setLabel3Value(labelPrivate.toFixed(2))
      setSituation(' وضع الشركة غير سليم من الناحية المالية احتمال الإفلاس مرتفع ')
      setStyle('text-danger')
    }


    console.log(`${style}`)
  }


  const onSubmit = (data,evt) => {

    evt.preventDefault();
    let id = Math.random();
    if ((data.hon && data.fixed && data.profit && data.capital && data.wanted && data.sales && data.netProfit) > 0) {
      setShowDev(true);
      setTax(calculateTax(data));
    }
    // setShow(true)




  }
  // PDF print function 
  const handleDownloadPdf = async () => {

    const element = printRef.current;
    element.style.background = '#FFFFFF';
    element.style.color = '#212529';

    const iaactive = true;
    await setIsActive(iaactive);
    //  Current Date 

    // Current Date 

    // PDF Header And Footer Values 
    const header = todayFrm;



    const canvas = await html2canvas(element);


    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();


    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;


    // pdf.addImage(data, 'PNG', 0, 10, pdfWidth, pdfHeight);
    // // Header
    // pdf.text(header, 2, 15, { baseline: 'top' });
    // // Footer
    //  Header  - Logo
    pdf.addImage(massportallogo, 'png', 90, 5, 20, 20);
    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 25, pdfWidth, pdfHeight);
    // Header - Date 
    // pdf.text(header, 10, 40, { baseline: 'top' });

    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    const footer = todayFrm + '   ' + time + '             www.mass-portal.com';
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight - 2, { baseline: 'bottom' });
    pdf.setFontSize(20);
    pdf.save('Mass-portal-مؤشر ألتمان.pdf');

  };

  return (

    
    <div className="row">
      <div className="col-xl-6 d-flex">
        <div className="card flex-fill">
          <div className="card-header">
            <div className="row">
        

              <div className="col-sm-12 col-md-6">
                <h4 className="card-title mb-0 privacy ">مؤشر التمان </h4>
              </div>

              <div className="col-sm-12 col-md-6">
                {/* Modal Button */}
                <a href="#" className="btn add-btn info-btn" data-toggle="modal" data-target="#add_employee"><FaInfo /> توضيح</a>
                {/* Modal Button */}
              </div>
            </div>

          </div>
          {/* <div className=" modal-div col-sm-12 col-md-6">
										<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_employee"><FaInfo /> توضيح</a>
									</div> */}
          {/* Modal Button */}
   
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} className="form form-alt">
              <div className="form-group row">
                <label className="col-lg-4 col-form-label"> اسم الشركة </label>
                <div className="col-lg-8">
                  <input type="text"
                    name="companyName"
                    className="form-control"
                    // ref={register({ required: true })}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  {/* <div className='invalid-feedback' style={{ display: 'block' }}>
                    {errors.companyName && 'الاسم مطلوب .'}
                  </div> */}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label"> تاريخ الميزانية  </label>
                <div className="col-lg-8">
                  <input type="date"
                    className="form-control"
                    onChange={(e) => setCurrentDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group-row">
                <label className="col-lg-4 col-form-label"> نوع الشركة </label>
                <select id="dates"
                  className=' col-lg-8 select floating tax-abt-select'
                  name='dates' value={companyType} onChange={(e) => (setCompanyType(e.target.value))} >
                  <option value="private">خاصة</option>
                  <option value="public">عمومية</option>
                </select>
                <br></br><br></br>
              </div>
              <div className="form-group row">
                <h4 className="privacy-bold" >  معطيات الشركة حسب الميزانية العمومية اليوم </h4>
                <br></br>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">  رأس المال الجاري <span className="privacy"> اصول متداولة </span></label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                   {...register("hon", { required: true})}
                    onChange={(e) => setHon(e.target.value)}
                  />
                   {errors.hon?.type === 'required' && <span className="result-label-red"> الرجاء ادخال رأس المال الجاري </span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label"> مجموع الأصول الثابتة</label>
                <div className="col-lg-8">
                  <input type="text"
                    className="form-control"
                    onChange={(e) => setFixed(e.target.value)}
                   {...register("fixed", { required: true})}

                  />
                  {errors.fixed?.type === 'required' && <span className="result-label-red"> الرجاء ادخال مجموع الأصول الثابتة </span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">رصيد الربح المتراكم في الميزانية</label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setProfit(e.target.value)}
                   {...register("profit", { required: true})}

                  />
                  {errors.profit?.type === 'required' && <span className="result-label-red"> الرجاء ادخال رصيد الربح  </span>}

                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   رأس المال  </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setCapital(e.target.value)}
                   {...register("capital", { required: true})}

                  />
                  {errors.capital?.type === 'required' && <span className="result-label-red"> الرجاء ادخال رأس المال  </span>}

                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   مجموع المطلوبات  </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setWanted(e.target.value)}
                   {...register("wanted", { required: true})}

                  />
                  {errors.wanted?.type === 'required' && <span className="result-label-red"> الرجاء ادخال المطلوبات     </span>}

                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   مجموع المبيعات  </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setSales(e.target.value)}
                    {...register("sales", { required: true})}
                  />
                  {errors.sales?.type === 'required' && <span className="result-label-red"> الرجاء ادخال مجموع المبيعات      </span>}
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">   صافي الربح قبل الفوائد والضرائب  </label>
                <div className="col-lg-8">
                  <input type="text" className="form-control"
                    onChange={(e) => setNetProfit(e.target.value)}
                    {...register("netProfit", { required: true})}
                  />
                  {errors.netProfit?.type === 'required' && <span className="result-label-red"> الرجاء ادخال صافي الربح       </span>}
                </div>
              </div>

              <div className="text-center form-group row">
                <button type="submit" className="btn btn-primary btn-return" > احتسب  </button>
              </div>


            </form>
          </div>
        </div>
      </div>
      <div className={showDev ? "col-xl-5 d-flex" : "hide-div"} >
        <div className="card flex-fill container">
          <div className="row  text-right ">
            <div className="col-sm-12 col-md-10" >


            </div>
            <div className="col-sm-12 mt-3  "  >
              <button className="btn   " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                <AiOutlineFilePdf size={30} className='privacy ' />
              </button>
              <button className="btn " data-tip data-for="excel-tip">
                <SiMicrosoftexcel size={30} className='privacy ' />
              </button>
              <ReactTooltip id="pdf-tip" place="top" effect="solid">
                طباعة PDF
              </ReactTooltip>
              <ReactTooltip id="excel-tip" place="top" effect="solid">
                طباعة Excel
              </ReactTooltip>
            </div>
          </div>
          <div className={isActive ? "card-body-white" : "card-body"}>
            <form onSubmit={reloadWindow} className={isActive ? "form-white" : "form-margin"}>
              <div className={isActive ? " card-white" : "card"} ref={printRef}>
                <br></br>
                <div className="row">

                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? "privacy-black-bold text-center   " : "privacy hide "} id="date-block">اسم الشركة : {companyName}  </h4> </div>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? "privacy-black-bold text-center   " : "privacy hide "} id="date-block"> 
                    مؤشر التمان 
                    <br></br><br></br>
                    تاريخ الميزانية
                    <br></br>
                    <span className="privacy-bold"> {altDate} </span>
                    </h4> 
                   
                    
                    </div>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? "privacy-black-bold text-center  " : "privacy hide "} id="date-block"> التاريخ : {todayFrm} </h4> </div>
                </div>
                <br></br>
                <div className="form-group row">
                  <label className={isActive ? "black-bold text-center col-lg-12 col-form-label black-bold-large" : "col-lg-12 col-form-label privacy black-bold-large"}>  ناتج مؤشر التمان   </label>
                  <div className={isActive ? "text-center" : "col-lg-6"}>
                    <label className={isActive ? " privacy-black-bold text-center black-bold-large" : "form-control result-label black-bold-large " + `${style}`
                    } > Z =  {(commify(label3Value))} </label>

                  </div>
                </div>
                <div className="form-group row">
                  <label className={isActive ? "privacy-black-bold text-center col-lg-12 col-form-label black-bold-large" : "col-lg-12 col-form-label  privacy black-bold-large"}>  {situation}   </label>
                  <label className={isActive ? "black-bold text-center col-lg-12 col-form-label black-bold-large" : "col-lg-12 col-form-label  black-bold-large"}>   المتوسط للشركات غير المفلسة  <span className={isActive ? "privacy-black-bold" : "text-success bold black-bold-large "}> {NonBankrupt}  </span></label>
                  <label className={isActive ? "black-bold text-center col-lg-12 col-form-label black-bold-large" : "col-lg-12 col-form-label black-bold-large "}>   المتوسط للشركات  المفلسة  <span className={isActive ? "privacy-black-bold" : "text-danger bold black-bold-large"}> {bankrupt} </span></label>
                </div>
              </div>
              <div className={isActive ? "hide" : "text-center"}>
                <button type="submit" className="btn btn-primary btn-return"> احتساب جديد  </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Explaine Modal */}
      <div id="add_employee" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title title-underline privacy-bold">نموذج ألتمان في التنبؤ بالفشل المالي </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="  modal-body">
              <div className='row'>
                <div className='col-sm-12 col-md-12'>


                  <ul className='privacy-ol privacy-ol-large'>
                    <li className='modal-title'>مؤشر ألتمان </li>
                    <ul>
                      <li>
                        <p>
                          يعد فشل الشركات من المواضيع الهامة نظراً لكون أغلب الشركات تعمل في ظروف محفوفة‎ ‎بمخاطر تهدد وجودها وتزيد من ‏احتمالات تعرضها للفشل، وما ينتج عنه من آثار سلبية‎ ‎على الاقتصاد القومي وعلى المجتمع ككل‎.‎
                        </p>
                      </li>
                      <li>
                        <p>
                          وبسبب ذلك، تم الاهتمام بشكل كبير بالتنبؤ بالفشل المالي من قبل الجهات ذات العلاقة‎ ‎مع الشركة، سواء كانت داخلية أو خارجية ‏‏(المصارف، المستثمرون، الإدارة، مدققو‏‎ ‎الحسابات، والجهات الحكومية)، وذلك بغية التنبؤ بالفشل قبل حدوثه لمساعدة الشركات‎ ‎على ‏اتخاذ القرارات التصحيحية في الوقت المناسب‎.‎
                        </p>
                      </li>
                      <li>
                        <p>
                          وتكاثفت الجهود للتوصل إلى أساليب كمية تساعد المؤسسات على معرفة الاحتمالات التي‎ ‎تواجهها بخصوص موضوع الفشل المالي، ‏ونتيجةً لذلك، ظهرت العديد من الدراسات التي‎ ‎تستخدم النسب المالية بوصفها أدوات لبعض النماذج الإحصائية‎ ‎ومن أهمها نموذج ‏‎ <span className="privacy-bold"> ‎ Altman‏ </span>.‏
                        </p>
                      </li>
                    </ul>
                    {/* ======================= */}
                    <li className='modal-title'>مفهوم الفشل المالي </li>
                    <ul>
                      <li>
                        <p>
                          يطلق مصطلح "الفشل المالي" على المرحلة التي تتعرض فيها المؤسسة الاقتصادية إلى‎ ‎اضطرابات مالية خطيرة تضطرها إلى عدم ‏القدرة على سداد التزاماتها تجاه الغير في‎ ‎تواريخ الاستحقاق، وهي المرحلة التي تسبق لحظة إعلان الإفلاس، أي المرحلة التي‎ ‎يتبعها ‏تنازل عن ممتلكات أو أصول لصالح الدائنين، أو عدم القدرة على استرجاع العقار‎ ‎المرهون مع الحجز على ممتلكات المؤسسة قبل ‏إصدار الحكم عليها‎.‎
                        </p>
                      </li>
                      <li>
                        <p>
                          وبالتالي، يوصف الفشل المالي بأنه عجز عوائد الشركة عن تغطية كل التكاليف، وضمنها‎ ‎كلفة تمويل رأس المال، وعدم قدرة الإدارة ‏على تحقيق عائد على رأس المال المستثمر‎ ‎يتناسب والمخاطر المتوقعة لتلك الاستثمارات، بمعنى أن تكون خصوم والتزامات الشركة‎ ‎أكثر من أصولها وإيراداتها، أي أن صافي رأس المال أقل من صفر‎.‎
                        </p>
                      </li>
                    </ul>
                    {/* ======================= */}
                    {/* ======================= */}
                    <li className='modal-title'>أهمية التنبؤ بفشل المؤسسة الاقتصادية </li>
                    <ul>
                      <li>
                        <p>
                          من الضروري إيجاد طريقة أو آلية تساعد في التنبؤ باحتمال تعثر الشركة قبل ذلك بسنوات بغية اتخاذ الإجراءات التي تحول دون ذلك ‏في الوقت المناسب، فالمستثمر يهتم‎ ‎بموضوع التنبؤ بفشل المؤسسة الاقتصادية من أجل اتخاذ قراراته الاستثمارية المختلفة، ‏والمفاضلة بين كل البدائل المتاحة، وتجنب الاستثمارات الخطرة جداً، أما المصارف‎ ‎فتهتم به من أجل اتخاذ قرار بمنح الائتمان من ‏عدمه، وتحديد سعر الفائدة وغير ذلك من‎ ‎الشروط، في حين تهتم به الإدارة لاتخاذ الإجراءات التصحيحية الضرورية لإنقاذ‎ ‎المؤسسة ‏في الوقت المناسب، وكذلك تهتم الجهات الحكومية بهذا الموضوع لتتمكن من أداء‎ ‎وظيفتها الرقابية على المؤسسات العاملة في ‏الاقتصاد حرصاً منها على سلامته، بينما‎ ‎يعود اهتمام مراجعو الحسابات بالتنبؤ بالفشل إلى مسؤوليتهم الكبيرة في تدقيق‎ ‎القوائم المالية ‏لتلك المؤسسات الاقتصادية‎.‎
                        </p>
                      </li>
                    </ul>
                    {/* ======================= */}
                    <li className='modal-subtitle  '>
                      نموذج ألتمان <span className="privacy-bold "> (‏Altman‏) </span> للتنبؤ بالفشل المالي

                    </li>
                    <div className='col-sm-12 '>
                      <ul className='privacy-ol privacy-ol-large'>
                        <li>
                          <p>
                            يتكون هذا النموذج من خمس نسب مالية مجتمعة، وهو على الشكل التالي‎:‎

                          </p>
                        </li>
                        <ul className='privacy-ol border  '>
                          <br></br>
                          <li>
                            <h3 className="privacy-bold"> Z = 1.2 X1 + 1.4 X2 + 3.3 X3 + 0.6 X 4 + 1 X5‎ </h3>

                          </li>
                          <ol className='privacy-ol col-sm-12'>
                            <li>
                              <p>
                                X1‎‏ = صافي رأس المال العامل / مجموع الموجودات (نسبة نشاط)‏
                                <br></br>
                                X1 = working capital / total assets.‎
                              </p>
                            </li>
                            <li>
                              <p>
                                X2‎‏ = الأرباح المحتجزة في الميزانية / مجموع الموجودات (نسبة ترتبط بسياسة الادارة‎ ‎في توزيع الارباح)‏
                                <br></br>
                                X2 = retained earnings / total assets.‎
                              </p>
                            </li>
                            <li>
                              <p>
                                X3‎‏ = ربح التشغيل قبل الفوائد والضرائب / مجموع الموجودات (نسبة ربحية)‏
                                <br></br>
                                X3 = earnings before interest and taxes / total assets.‎
                              </p>
                            </li>
                            <li>
                              <p>
                                X4‎‏ = القيمة السوقية لحقوق المساهمين / مجموع المطلوبات (نسبة ملاءة أو الرفع‎ ‎المالي)‏
                                <br></br>
                                X4 = market value of equity / book value of total liabilities.‎
                              </p>
                            </li>
                            <li>
                              <p>
                                X5‎‏ = المبيعات / مجموع الموجودات (نسبة نشاط)‏
                                <br></br>
                                X5 = sales / total assets.‎
                              </p>
                            </li>
                          </ol>
                        </ul>
                        <ul className='privacy-ol border '>
                          <br></br>
                          <li>	<h4 className="privacy-bold"> حيث‎ Z ‎مؤشر التعثر المالي فإنه :‏‎ ‎ </h4></li>
                          <ol className='privacy-ol'>
                            <li>
                              <p>
                                عندما تكون‎ Z ‎أكبر من <span className="privacy-bold"> 2.99 </span>‏‎ ‎فإن النموذج يتوقع أن المؤسسة لن تفلس وهي مؤسسة ناجحة‎.‎
                              </p>
                            </li>
                            <li>
                              <p>
                                عندما تكونZ ‎‏ أقل من <span className="privacy-bold"> 1.81 </span>‏‎ ‎فإن النموذج يتوقع أن المؤسسة سوف تفلس وهي مؤسسة فاشلة‎
                              </p>
                            </li>
                            <li>
                              <p>
                                عندما تكون‎ Z ‎ما بين <span className="privacy-bold"> 2.99 </span> و <span className="privacy-bold"> 1.81 </span> (وهي ما تعرف "بالمنطقة الرمادية")، فإن النموذج‎ ‎لا يستطيع الحكم بدقة على احتمالية ‏إفلاس المؤسسة أو عدم إفلاسها‎.
                              </p>
                            </li>
                          </ol>
                        </ul>
                      </ul>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-3"></div>
                <div className="col-sm-12 col-md-6">
                  <button type="button" className="btn btn-primary close-btn text-center" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" >اغلاق </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}