/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { GoInfo } from "react-icons/go";
import { AiOutlineFileWord, AiOutlineFilePdf, AiOutlineDownload } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { BsCalculator } from "react-icons/bs";

//  Current Date 
const d = new Date().toLocaleString() + "";
let current = d.split(',');
const today = current[0];
const todaySplitArr = today.split('/');
const todayFrm = " " + todaySplitArr[1] + ' / ' + todaySplitArr[0] + ' / ' + todaySplitArr[2];

export default class WhatIsIt extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal - Info</title>
					<meta name="description" content="Reactify Blank Page" />
				</Helmet>

				{/* Page Content */}

				<div className="content container-fluid">
					{/* Current Date */}
					<div className='row'>
						<div className="col-sm-12">
							<br></br>
							<h3 className="page-title"> <span className="date-d" dir="ltr">{todayFrm}</span></h3>
						</div>
					</div>
					{/* Current Date */}


					<div className="small-container">
						{/* Page Header */}
						<div className="inner-header text-center">
							<h1>كشف رأس المال</h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>

						</div>
						{/* /Page Header */}


						{/* Content Starts */}
						<div className='row'>
							<div className='col-sm-10 offset-1'>
								<h4 className='privacy'>
									من أهم الكشوفات التي تستخدمها ضريبة الدخل لإظهار الدخل المخفي على يد المكلف هو كشف رأس المال.
								</h4>
								<br></br>
								<ul id='content-menu' className='privacy-ol'>

									<li>
										<p className='zakat-content'>
											<GoInfo size={20} className='li-icon privacy' />
											<span>
												يختلف كشف راس المال عن الكشف السنوي بالمعطيات المصرح بها حيث يصرح في كشف راس المال على الموجودات ومطلوبات المكلف ولكن في الكشف السنوي يصرح عن دخله ومصاريفه.											</span>

										</p>
									</li>
									<li>
										<p className='zakat-content'>
											<GoInfo size={20} className='li-icon privacy' />
											<span>
												يقدم كشف راس المال ل 31 - ديسمبر من السنة المطلوب بها ويجب تقديمه خلال 120 يوم من يوم استلامه.
											</span>

										</p>
									</li>

									<li>
										<p className='zakat-content'>
											<GoInfo size={20} className='li-icon privacy' />
											<span>
												يطلب كشف راس المال من كل مكلف جديد ويعاود يطلب منه بشكل عام كل 4 سنوات. إذا كان فرق بين كشفين متتالين في راس المال الغير مفسر يفرض على المكلف ضريبة على هذا الفرق.
											</span>

										</p>
									</li>
								</ul>



							</div>

							<div className='col-sm-10 offset-1'>

								<h4 className='privacy'>
									الوثائق اللازمة لتحضير كشف راس المال ليوم
									<span className='date-d'> 2022 - 12 - 31</span>
								</h4>
								<br></br>


								<ol className='privacy-ol'>
									<li>
										<p className='zakat-content'>
											شهادات بأرصدة البنوك على رصيدكم ورصيد الزوج/ الزوجة وأولادكم حتى جيل 18 سنة بما فيه الحساب الجاري, صناديق ادخار, حسابات بالعملة الصعبة, استثمارات بالأسهم وما شابه ليوم 31 ديسمبر 2020
										</p>

									</li>
									<li>
										<p className='zakat-content'>
											العقود التي تدل على مشتريات ( بيوت , سيارات, قائمة بمحتويات البيت مثل التلفاز والفيديو وما شابه ليوم 31 ديسمبر 2020).

										</p>
									</li>

									<li>
										<p className='zakat-content'>
											معطيات ووثائق لممتلكات أو مطلوبات مثل أراضى ,ذمم مدينة و ذمم دائنة وما شابه ليوم 31 ديسمبر 2020 .

										</p>
									</li>

									<li>
										<p className='zakat-content'>
											وثائق التي تؤكد المطلوبات والقروض من البنوك ليوم 31 ديسمبر2020 .

										</p>
									</li>

									<p className='zakat-content'>
										<GoInfo size={20} className='li-icon privacy' />
										<span>
											بالنسبة للقروض البنكية يجب تقديم تصريح بها كما ذكر في البند 1 آنف الذكر.
										</span>

									</p>
									<p className='zakat-content'>
										<GoInfo size={20} className='li-icon privacy' />
										<span>
											وبالنسبة للقروض من الآخرين يجب تقديم تصاريح ووثائق لتؤكد القرض.
										</span>

									</p>


								</ol>











							</div>
							<br></br>
							<h4 className='zakat-content text-center privacy'>
								<GoInfo size={20} className='li-icon privacy' />
								<span>
									المعطيات آنفة الذكر هي عامة, وبعد توفر الوثائق بين يدي المكلف يفضل تلقي استشارة قانونيه لتعبئة الكشف.
								</span>
								<GoInfo size={20} className='li-icon privacy' />

							</h4>
						</div>
						<div className="row" >

							<div className="col-xl-4 col-md-6 col-sm-6 ">
								<br></br>
								<br></br>
								<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Ft-1219.pdf?alt=media&token=5e43f952-573f-451c-aae7-d18aa2dc705c" target='_blank' class="btn btn-dark btn-block btn-download ">
									<AiOutlineFilePdf size={30} className=" contract-icon privacy" />
									<span >
										تنزيل كشف رأس المال T-1219  (ע)
									</span>
								</a>
							</div>
							<div className="col-xl-4 col-md-6 col-sm-6 ">
								<br></br>
								<br></br>
								<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Fformat_hon%20.xls?alt=media&token=0d5a3df7-05b0-4ae1-b549-cab9fd3a6258" class="btn btn-dark btn-block btn-download ">
									<SiMicrosoftexcel size={30} className=" contract-icon privacy" />
									<span >
										تنزيل ورقة عمل Excel  (ע)
									</span>
								</a>
							</div>
							<div className="col-xl-4 col-md-6 col-sm-6 ">
								<br></br>
								<br></br>
								<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Fcust-letter.doc?alt=media&token=3d64f2ae-6b7f-41e5-aad3-5287c70b9e85" target='_blank' class="btn btn-dark btn-block btn-download ">
									<AiOutlineFileWord size={30} className=" contract-icon privacy" />
									<span >
										تنزيل كتاب للمكلف
									</span>
								</a>
							</div>




						</div>

						<div className='row topics'>
							<br></br>
							<h3 className="topic-title text-center">< AiOutlineDownload size={30} className="privacy" /> قوائم مصاريف المعيشة ( ע ) < AiOutlineDownload size={30} className="privacy" />  </h3>

							<div className="col-sm-12 col-md-12 col-xl-6 ">
								<br></br>
								<div className='topics'>
									<ul className="topics-list">
										<li>
											<tr>
												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2011.pdf?alt=media&token=c08dab16-1f89-4eaf-8f6a-db8d61c7ec47" target='_blank' className='contract-a'>
														<AiOutlineFilePdf size={30} className="privacy contract-icon" />
													</a>
												</td>

												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2011.pdf?alt=media&token=c08dab16-1f89-4eaf-8f6a-db8d61c7ec47" target='_blank'>
														הוצאות מחיה לשנת המס ( 2011 )
													</a>
												</td>
											</tr>
										</li>



									</ul>
								</div>
								<div className='topics' >
									<ul className="topics-list">
										<li>
											<tr>
												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2012.pdf?alt=media&token=1e6f0d80-133d-4124-800e-575fea20226d" target='_blank' className='contract-a'>
														<AiOutlineFilePdf size={30} className="privacy contract-icon" />
													</a>
												</td>

												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2012.pdf?alt=media&token=1e6f0d80-133d-4124-800e-575fea20226d" target='_blank'>
														הוצאות מחיה לשנת המס ( 2012 )
													</a>
												</td>
											</tr>
										</li>

									</ul>
								</div>

								<div className='topics' >
									<ul className="topics-list">
										<li>
											<tr>
												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2013.pdf?alt=media&token=9c72b10d-9dc0-478d-a7ed-eccfcaa5f50c" target='_blank' className='contract-a'>
														<AiOutlineFilePdf size={30} className="privacy contract-icon" />
													</a>
												</td>

												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2013.pdf?alt=media&token=9c72b10d-9dc0-478d-a7ed-eccfcaa5f50c" target='_blank'>
														הסבר לשימוש בטבלאות הוצאות מחיה ( 2013 )
													</a>
												</td>
											</tr>
										</li>

									</ul>
								</div>

								<div className='topics' >
									<ul className="topics-list">
										<li>
											<tr>
												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2014.pdf?alt=media&token=cab4f3ea-459c-48d1-bb6d-e7cd45d58584" target='_blank' className='contract-a'>
														<AiOutlineFilePdf size={30} className="privacy contract-icon" />
													</a>
												</td>

												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2014.pdf?alt=media&token=cab4f3ea-459c-48d1-bb6d-e7cd45d58584" target='_blank'>
														הסבר לשימוש בטבלאות הוצאות מחיה ( 2014 )
													</a>
												</td>
											</tr>
										</li>

									</ul>
								</div>
							</div>



							<div className="col-sm-12 col-md-12 col-xl-6 ">
								<br></br>
								<div className='topics' >
									<ul className="topics-list">
										<li>
											<tr>
												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2015.pdf?alt=media&token=c178f8c6-95cc-40c9-9ff8-45992740fc30" target='_blank' className='contract-a'>
														<AiOutlineFilePdf size={30} className="privacy contract-icon" />
													</a>
												</td>

												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2015.pdf?alt=media&token=c178f8c6-95cc-40c9-9ff8-45992740fc30" target='_blank'>
														הסבר לשימוש בטבלאות הוצאות מחיה ( 2015 )
													</a>
												</td>
											</tr>
										</li>

									</ul>
								</div>

								<div className='topics' >
									<ul className="topics-list">
										<li>
											<tr>
												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2016.pdf?alt=media&token=9e77829a-14dd-4c68-b705-babe899aa724" target='_blank' className='contract-a'>
														<AiOutlineFilePdf size={30} className="privacy contract-icon" />
													</a>
												</td>

												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2016.pdf?alt=media&token=9e77829a-14dd-4c68-b705-babe899aa724" target='_blank'>
														הסבר לשימוש בטבלאות הוצאות מחיה ( 2016 )
													</a>
												</td>
											</tr>
										</li>

									</ul>
								</div>

								<div className='topics' >
									<ul className="topics-list">
										<li>
											<tr>
												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2017.pdf?alt=media&token=b240e801-705c-4c41-99be-080b66a91444" target='_blank' className='contract-a'>
														<AiOutlineFilePdf size={30} className="privacy contract-icon" />
													</a>
												</td>

												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2017.pdf?alt=media&token=b240e801-705c-4c41-99be-080b66a91444" target='_blank'>
														הוצאות מחיה לשנת המס ( 2017 )
													</a>
												</td>
											</tr>
										</li>

									</ul>
								</div>

								<div className='topics' >
									<ul className="topics-list">
										<li>
											<tr>
												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2018.pdf?alt=media&token=aef0eede-236c-414a-997b-56d8f5a5215c" target='_blank' className='contract-a'>
														<AiOutlineFilePdf size={30} className="privacy contract-icon" />
													</a>
												</td>

												<td>
													<a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/income-tax%2Flists-expences%2F2018.pdf?alt=media&token=aef0eede-236c-414a-997b-56d8f5a5215c" target='_blank'>
														הוצאות מחיה לשנת המס ( 2018 )
													</a>
												</td>
											</tr>
										</li>

									</ul>
								</div>
							</div>


						</div>

						<div className="row text-center" >

							<div className="col-xl-8 col-md-6 col-sm-6 text-center offset-xl-2 offset-sm-2  ">
								<br></br>
								<br></br>
								<a href="/pages/sidebar-pages/payments-taxes/payments-loans-calc/moneyreport/moneyList" class="btn btn-primary btn-block btn-download ">
									<BsCalculator size={30} className=" contract-icon  privacy-white" />
									<span className='btn-span' >
										تحضير كشف رأس المال 
									</span>

									<BsCalculator size={30} className=" contract-icon privacy-white" />
								</a>
							</div>




						</div>
						{/* /Content End */}
					</div>


				</div>
				{/* /Page Content */}
			</div>

		);
	}
}
