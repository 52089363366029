let n=[
 {
  ar: "نائب رئيس",
  he: "סגן נשיא",
  en: "Vice president"
 },
 {
  ar: "ناتج",
  he: "מוצר",
  en: "Good\/ product"
 },
 {
  ar: "نادي بريس",
  he: "מועדון פריס",
  en: "Paris club"
 },
 {
  ar: "نادي لندن",
  he: "מודון לונדון",
  en: "London club"
 },
 {
  ar: "ناقلة يترول",
  he: "מכלית נפט",
  en: "Tanker"
 },
 {
  ar: "نزع الملكية",
  he: "נישול",
  en: "Eviction"
 },
 {
  ar: "نسبة",
  he: "ערך\/ יחס\/ שיעור",
  en: "Rate\/ ratio"
 },
 {
  ar: "نسبة التبادل الدولية",
  he: "תנאי סחר",
  en: "Terms of trade"
 },
 {
  ar: "نسبة الزيادة",
  he: "שיעור העלייה",
  en: "Rate of increase"
 },
 {
  ar: "نسبة السيولة",
  he: "יחס הנזילות",
  en: "Liquidity ratio\/ cash ratio"
 },
 {
  ar: null,
  he: "שיעור נזילות",
  en: "Liquidity rate "
 },
 {
  ar: "نشاط اقتصادي",
  he: "פעילות כלכלית",
  en: "Economic activity"
 },
 {
  ar: "نشرة الاكتتاب",
  he: "תשקיף",
  en: "Prospectus"
 },
 {
  ar: "نشرة اولية",
  he: "תשקיף",
  en: "Prospectus"
 },
 {
  ar: "نظام اقتصادي",
  he: "שיטה כלכלית",
  en: "Economic system"
 },
 {
  ar: "نظام الاحتياطي الفدرالي",
  he: "מערכת העתודות הפדראליות",
  en: "Federal reserve system"
 },
 {
  ar: "نظام نقدي",
  he: "מערכת מוניטארית",
  en: "Monetary system"
 },
 {
  ar: "نظم",
  he: "ויסות",
  en: "Control\/ regulation"
 },
 {
  ar: "نفاد",
  he: "אזילה",
  en: "Depletion"
 },
 {
  ar: "نفط",
  he: "נפט",
  en: "Oil"
 },
 {
  ar: "نفقات ادارية",
  he: "הוצאות ניהול",
  en: "Administrative expenses"
 },
 {
  ar: "نفقات البيع",
  he: "הוצאות מכירה",
  en: "Selling costs"
 },
 {
  ar: "نفقات التشغيل",
  he: "הוצאות תפעוליות",
  en: "Operating costs"
 },
 {
  ar: "نفقات التعبئة",
  he: "דמי טיפול",
  en: "Handling charges"
 },
 {
  ar: "نفقات التفريغ",
  he: "הוצאות פריקה",
  en: "Unloading charges"
 },
 {
  ar: "نفقات الخصم",
  he: "הוצאות ניכיון",
  en: "Discount charges"
 },
 {
  ar: "نفقات السفر",
  he: "הוצאות נסיעה",
  en: "Travel expenses"
 },
 {
  ar: "نفقات المحكمة",
  he: "הוצאות משפט",
  en: "Court costs"
 },
 {
  ar: "نفقات النقل",
  he: "הוצאות הובלה",
  en: "Transportation charges"
 },
 {
  ar: "نفقات اولية",
  he: "הוצאות ראשוניות",
  en: "Preliminary expenses"
 },
 {
  ar: "نفقات راسمالية",
  he: "הוצאות הון",
  en: "Capital expenditures"
 },
 {
  ar: "نفقات عامة",
  he: "הוצאות הממשלה",
  en: "Public expenditure"
 },
 {
  ar: null,
  he: "הוצאות כלליות",
  en: "General expenses "
 },
 {
  ar: "نفقات فعلية",
  he: "הוצאות בפועל",
  en: "Actual expenses"
 },
 {
  ar: "نفقات مباشرة",
  he: "הוצאות ישירות  ",
  en: "Direct charges"
 },
 {
  ar: "نفقات متجمعة",
  he: "הוצאות נצברות",
  en: "Accrued expenses"
 },
 {
  ar: "نفقة",
  he: "הוצאה",
  en: "Outlay\/ expenditure\/ expense"
 },
 {
  ar: null,
  he: "עלות",
  en: "cost"
 },
 {
  ar: "نفقة الارتفاق",
  he: "עלות השימוש",
  en: "User cost"
 },
 {
  ar: "نفقة الاستخدام",
  he: "עלות השימוש",
  en: "User cost"
 },
 {
  ar: "نفقة دائمة",
  he: "הוצאה קבועה",
  en: "Standing charge"
 },
 {
  ar: "نفقة عامة",
  he: "הוצאה ציבורית",
  en: "Public expenditure"
 },
 {
  ar: "نفقة مؤجلة",
  he: "חיוב נדחה",
  en: "Deferred charge"
 },
 {
  ar: "نقابة",
  he: "איגןד",
  en: "Guild\/ gild"
 },
 {
  ar: null,
  he: "איגוד מקצועי",
  en: "Labour union"
 },
 {
  ar: null,
  he: "סינדיקט",
  en: "syndicate"
 },
 {
  ar: "نقد",
  he: "כסף מזומן",
  en: "Cash"
 },
 {
  ar: null,
  he: "מטבע",
  en: "Currency"
 },
 {
  ar: "نقدي",
  he: "כספי",
  en: "Financial"
 },
 {
  ar: "نقص",
  he: "מחסור",
  en: "Shortage"
 },
 {
  ar: "نقص القيمة المعجل",
  he: "פחת מואץ",
  en: "Accelerated depreciation"
 },
 {
  ar: "نقص القيمة بالاستعمال",
  he: "פחת",
  en: "depreciation"
 },
 {
  ar: "نقطة",
  he: "נקודה",
  en: "Point"
 },
 {
  ar: "نقطة التشبع",
  he: "נקודת רוויה",
  en: "Saturation point"
 },
 {
  ar: "نقل",
  he: "משלוח ",
  en: "Delivery\/ shipment"
 },
 {
  ar: null,
  he: "העברה",
  en: "Transfer\/conveyance"
 },
 {
  ar: "نقود قابلة للتحويل",
  he: "כסף בר המרה",
  en: "Convertible money"
 },
 {
  ar: "نمو اقتصادي",
  he: "צמיחה כלכלית",
  en: "Economic growth"
 },
 {
  ar: "نهب",
  he: "שוד",
  en: "Robbery"
 },
 {
  ar: "نوبة ليلة",
  he: "משמרת לילה",
  en: "Night shift"
 },
 {
  ar: "نوعية",
  he: "איכות",
  en: "quality"
 }
]
export default n ;