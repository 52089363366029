let taxProperty = [
  {
    yearMil:1642291200000,
    date: "16/01/2022",
    band1: {
      min: 0,
      rate: 0.5,
      max: 504900
    },
    band2: {
      rate: 5,
      min: 504900,
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1610755200000,
    date: "16/01/2021",
    band1: {
      min: 0,
      rate: "0.5",
      max: "456905"
    },
    band2: {
      rate: "5",
      min: "456905",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1579132800000,
    date: "16/01/2020",
    band1: {
      min: 0,
      rate: "0.5",
      max: "443480"
    },
    band2: {
      rate: "5",
      min: "443480",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1547596800000,
    date: "16/01/2019",
    band1: {
      min: 0,
      rate: "0.5",
      max: "428255"
    },
    band2: {
      rate: "5",
      min: "428255",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1516060800000,
    date: "16/01/2018",
    band1: {
      min: 0,
      rate: "0.5",
      max: "437665"
    },
    band2: {
      rate: "5",
      min: "437665",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1484524800000,
    date: "16/01/2017",
    band1: {
      min: 0,
      rate: "0.5",
      max: "477215"
    },
    band2: {
      rate: "5",
      min: "477215",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1452902400000,
    date: "16/01/2016",
    band1: {
      min: 0,
      rate: "0.5",
      max: "354710"
    },
    band2: {
      rate: "5",
      min: "354710",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1421366400000,
    date: "16/01/2015",
    band1: {
      min: 0,
      rate: "0.5",
      max: "347755"
    },
    band2: {
      rate: "5",
      min: "347755",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1389830400000,
    date: "16/01/2014",
    band1: {
      min: 0,
      rate: "0.5",
      max: "336320"
    },
    band2: {
      rate: "6",
      min: "336320",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil:1375315200000,
    date: "01/08/2013",
    band1: {
      min: 0,
      rate: "0.5",
      max: "325980"
    },
    band2: {
      rate: "6",
      min: "325980",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil:1358294400000,
    date: "16/01/2013",
    band1: {
      min: 0,
      rate: "0.5",
      max: "325980"
    },
    band2: {
      rate: "5",
      min: "325980",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1326672000000,
    date: "16/01/2012",
    band1: {
      min: 0,
      rate: "0.5",
      max: "315160"
    },
    band2: {
      rate: "5",
      min: "315160",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1295136000000,
    date: "16/01/2011",
    band1: {
      min: 0,
      rate: "0.5",
      max: "299240"
    },
    band2: {
      rate: "5",
      min: "299240",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1263600000000,
    date: "16/01/2010",
    band1: {
      min: 0,
      rate: "0.5",
      max: "284960"
    },
    band2: {
      rate: "5",
      min: "284960",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1232064000000,
    date: "16/01/2009",
    band1: {
      min: 0,
      rate: "0.5",
      max: "269660"
    },
    band2: {
      rate: "5",
      min: "269660",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1200441600000,
    date: "16/01/2008",
    band1: {
      min: 0,
      rate: "0.5",
      max: "238420"
    },
    band2: {
      rate: "5",
      min: "238420",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
   yearMil: 1192492800000,
    date: "16/10/2007",
    band1: {
      min: 0,
      rate: "0.5",
      max: "242955"
    },
    band2: {
      rate: "5",
      min: "242955",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1184544000000,
    date: "16/07/2007",
    band1: {
      min: 0,
      rate: "0.5",
      max: "230550"
    },
    band2: {
      rate: "5",
      min: "230550",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1176681600000,
    date: "16/04/2007",
    band1: {
      min: 0,
      rate: "0.5",
      max: "229840"
    },
    band2: {
      rate: "5",
      min: "229840",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1168905600000,
    date: "16/01/2007",
    band1: {
      min: 0,
      rate: "0.5",
      max: "213395"
    },
    band2: {
      rate: "5",
      min: "213395",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1160956800000,
    date: "16/10/2006",
    band1: {
      min: 0,
      rate: "0.5",
      max: "220305"
    },
    band2: {
      rate: "5",
      min: "220305",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1153008000000,
    date: "16/07/2006",
    band1: {
      min: 0,
      rate: "0.5",
      max: "220065"
    },
    band2: {
      rate: "5",
      min: "220065",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1145145600000,
    date: "16/04/2006",
    band1: {
      min: 0,
      rate: "0.5",
      max: "228175"
    },
    band2: {
      rate: "5",
      min: "228175",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1137369600000,
    date: "16/01/2006",
    band1: {
      min: 0,
      rate: "0.5",
      max: "227690"
    },
    band2: {
      rate: "5",
      min: "227690",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1129420800000,
    date: "16/10/2005",
    band1: {
      min: 0,
      rate: "0.5",
      max: "222685"
    },
    band2: {
      rate: "5",
      min: "222685",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1121472000000,
    date: "16/07/2005",
    band1: {
      min: 0,
      rate: "0.5",
      max: "214120"
    },
    band2: {
      rate: "5",
      min: "214120",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1113609600000,
    date: "16/04/2005",
    band1: {
      min: 0,
      rate: "0.5",
      max: "210550"
    },
    band2: {
      rate: "5",
      min: "210550",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1105833600000,
    date: "16/01/2005",
    band1: {
      min: 0,
      rate: "0.5",
      max: "213170"
    },
    band2: {
      rate: "5",
      min: "213170",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1089936000000,
    date: "16/07/2004",
    band1: {
      min: 0,
      rate: "0.5",
      max: "221990"
    },
    band2: {
      rate: "5",
      min: "221990",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1082073600000,
    date: "16/04/2004",
    band1: {
      min: 0,
      rate: "0.5",
      max: "218895"
    },
    band2: {
      rate: "5",
      min: "218895",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1074211200000,
    date: "16/01/2004",
    band1: {
      min: 0,
      rate: "0.5",
      max: "218895"
    },
    band2: {
      rate: "5",
      min: "218895",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1072915200000,
    date: "01/01/2004",
    band1: {
      min: 0,
      rate: "0.5",
      max: "222710"
    },
    band2: {
      rate: "5",
      min: "222710",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1066262400000,
    date: "16/10/2003",
    band1: {
      min: 0,
      rate: "0.5",
      max: "222710"
    },
    band2: {
      rate: "4.75",
      min: "222710",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1058313600000,
    date: "16/07/2003",
    band1: {
      min: 0,
      rate: "0.5",
      max: "219375"
    },
    band2: {
      rate: "4.75",
      min: "219375",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1050451200000,
    date: "16/04/2003",
    band1: {
      min: 0,
      rate: "0.5",
      max: "237025"
    },
    band2: {
      rate: "4.75",
      min: "237025",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1042675200000,
    date: "16/01/2003",
    band1: {
      min: 0,
      rate: "0.5",
      max: "235375"
    },
    band2: {
      rate: "4.75",
      min: "235375",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1041379200000,
    date: "01/01/2003",
    band1: {
      min: 0,
      rate: "0.5",
      max: "239275"
    },
    band2: {
      rate: "4.75",
      min: "239275",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1034726400000,
    date: "16/10/2002",
    band1: {
      min: 0,
      rate: "0.5",
      max: "239275"
    },
    band2: {
      rate: "4.5",
      min: "239275",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1026777600000,
    date: "16/07/2002",
    band1: {
      min: 0,
      rate: "0.5",
      max: "247690"
    },
    band2: {
      rate: "4.5",
      min: "247690",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1018915200000,
    date: "16/04/2002",
    band1: {
      min: 0,
      rate: "0.5",
      max: "235585"
    },
    band2: {
      rate: "4.5",
      min: "235585",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1011139200000,
    date: "16/01/2002",
    band1: {
      min: 0,
      rate: "0.5",
      max: "217750"
    },
    band2: {
      rate: "4.5",
      min: "217750",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1005091200000,
    date: "07/11/2001",
    band1: {
      min: 0,
      rate: "0.5",
      max: "217960"
    },
    band2: {
      rate: "4.5",
      min: "217960",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 1003190400000,
    date: "16/10/2001",
    band1: {
      min: 0,
      rate: "0.5",
      max: "217960"
    },
    band2: {
      rate: "5",
      min: "217960",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 995241600000,
    date: "16/07/2001",
    band1: {
      min: 0,
      rate: "0.5",
      max: "210985"
    },
    band2: {
      rate: "5",
      min: "210985",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 987379200000,
    date: "16/04/2001",
    band1: {
      min: 0,
      rate: "0.5",
      max: "210160"
    },
    band2: {
      rate: "5",
      min: "210160",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 979603200000,
    date: "16/01/2001",
    band1: {
      min: 0,
      rate: "0.5",
      max: "206285"
    },
    band2: {
      rate: "5",
      min: "206285",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 971654400000,
    date: "16/10/2000",
    band1: {
      min: 0,
      rate: "0.5",
      max: "204,620"
    },
    band2: {
      rate: "5",
      min: "204620",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 963705600000,
    date: "16/07/2000",
    band1: {
      min: 0,
      rate: "0.5",
      max: "207745"
    },
    band2: {
      rate: "5",
      min: "207745",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 955843200000,
    date: "16/04/2000",
    band1: {
      min: 0,
      rate: "0.5",
      max: "201480"
    },
    band2: {
      rate: "5",
      min: "201480",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 947980800000,
    date: "16/01/2000",
    band1: {
      min: 0,
      rate: "0.5",
      max: "212550"
    },
    band2: {
      rate: "5",
      min: "212550",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 946684800000,
    date: "01/01/2000",
    band1: {
      min: 0,
      rate: "0.5",
      max: "220115"
    },
    band2: {
      rate: "5",
      min: "220115",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 940032000000,
    date: "16/10/1999",
    band1: {
      min: 0,
      rate: "0.5",
      max: "220115"
    },
    band2: {
      rate: "4.5",
      min: "220115",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 932083200000,
    date: "16/07/1999",
    band1: {
      min: 0,
      rate: "0.5",
      max: "207950"
    },
    band2: {
      rate: "4.5",
      min: "207950",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 924220800000,
    date: "16/04/1999",
    band1: {
      min: 0,
      rate: "0.5",
      max: "203565"
    },
    band2: {
      rate: "4.5",
      min: "203565",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 916444800000,
    date: "16/01/1999",
    band1: {
      min: 0,
      rate: "0.5",
      max: "216705"
    },
    band2: {
      rate: "4.5",
      min: "216705",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 908496000000,
    date: "16/10/1998",
    band1: {
      min: 0,
      rate: "0.5",
      max: "211285"
    },
    band2: {
      rate: "4.5",
      min: "211285",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 900547200000,
    date: "16/07/1998",
    band1: {
      min: 0,
      rate: "0.5",
      max: "205345"
    },
    band2: {
      rate: "4.5",
      min: "205345",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 892684800000,
    date: "16/04/1998",
    band1: {
      min: 0,
      rate: "0.5",
      max: "203575"
    },
    band2: {
      rate: "4.5",
      min: "203575",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil:888624000000,
    date: "28/02/1998",
    band1: {
      min: 0,
      rate: "0.5",
      max: "200970"
    },
    band2: {
      rate: "4.5",
      min: "200970",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 884908800000,
    date: "16/01/1998",
    band1: {
      min: 0,
      rate: "0.5",
      max: "200970"
    },
    band2: {
      rate: "5",
      min: "200970",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 876960000000,
    date: "16/10/1997",
    band1: {
      min: 0,
      rate: "0.5",
      max: "202740"
    },
    band2: {
      rate: "5",
      min: "202740",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 869011200000,
    date: "16/07/1997",
    band1: {
      min: 0,
      rate: "0.5",
      max: "198985"
    },
    band2: {
      rate: "5",
      min: "198985",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 861148800000,
    date: "16/04/1997",
    band1: {
      min: 0,
      rate: "0.5",
      max: "194295"
    },
    band2: {
      rate: "5",
      min: "194295",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 853372800000,
    date: "16/01/1997",
    band1: {
      min: 0,
      rate: "0.5",
      max: "187520"
    },
    band2: {
      rate: "5",
      min: "187520",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 845424000000,
    date: "16/10/1996",
    band1: {
      min: 0,
      rate: "0.5",
      max: "181165"
    },
    band2: {
      rate: "5",
      min: "181165",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 837475200000,
    date: "16/07/1996",
    band1: {
      min: 0,
      rate: "0.5",
      max: "184500"
    },
    band2: {
      rate: "5",
      min: "184500",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 829612800000,
    date: "16/04/1996",
    band1: {
      min: 0,
      rate: "0.5",
      max: "172095"
    },
    band2: {
      rate: "5",
      min: "172095",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 821750400000,
    date: "16/01/1996",
    band1: {
      min: 0,
      rate: "0.5",
      max: "165110"
    },
    band2: {
      rate: "5",
      min: "165110",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 813801600000,
    date: "16/10/1995",
    band1: {
      min: 0,
      rate: "0.5",
      max: "157815"
    },
    band2: {
      rate: "5",
      min: "157815",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 805852800000,
    date: "16/07/1995",
    band1: {
      min: 0,
      rate: "0.5",
      max: "151350"
    },
    band2: {
      rate: "5",
      min: "151350",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 797990400000,
    date: "16/04/1995",
    band1: {
      min: 0,
      rate: "0.5",
      max: "146735"
    },
    band2: {
      rate: "5",
      min: "146735",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 790214400000,
    date: "16/01/1995",
    band1: {
      min: 0,
      rate: "0.5",
      max: "143740"
    },
    band2: {
      rate: "5",
      min: "143740",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 782265600000,
    date: "16/10/1994",
    band1: {
      min: 0,
      rate: "0.5",
      max: "139780"
    },
    band2: {
      rate: "5",
      min: "139780",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 774316800000,
    date: "16/07/1994",
    band1: {
      min: 0,
      rate: "0.5",
      max: "132480"
    },
    band2: {
      rate: "5",
      min: "132480",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  },
  {
    yearMil: 766454400000,
    date: "16/04/1994",
    band1: {
      min: 0,
      rate: "0.5",
      max: "121540"
    },
    band2: {
      rate: "5",
      min: "121540",
      max:"أكثر من ذلك "
    },
    band3:{
      min:""
    }
  }
]
export default {taxProperty}