/**
 * Signin Firebase
 */
import { formatMoney } from "accounting-js";
import React, { Component , useState} from 'react';
import { Helmet } from "react-helmet";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useAuthContext } from "../../../hooks/useAuthContext";
import { massportallogo } from "../../../Entryfile/imagepath"
import { BiShekel } from 'react-icons/bi';
import ReactTooltip from "react-tooltip";
export default function MnthVPt() {
// States
const[resultColor , setRcolor] = useState("bbc4cc");
const bool = true;
const [month, setMonth] = useState(""); // Month 
const [year, setYear] = useState(""); // Year 
const [tax, setTax] = useState(""); // نسبة الضريبة 
const [s, setS] = useState(0); // المبيعات / شامل 
const [a, setA] = useState(0); // المبيعات / معفى  
const [capex, setCapex] = useState(0); // المصاريف / أصول ثابتة 
const [exp, setExp] = useState(0); // المصاريف العادية / شامل   
const [b, setB] = useState(0); // صفقات ملزمة = المبلغ 
const [c, setC] = useState(0); // ض.ق.م على الصفقات  
const [dd, setD] = useState(0); // ( ض . ق . م ) مدخلات أصول ثابتة ( תשומות )  
const [ee, setE] = useState(0);// ( ض . ق . م ) مدخلات عادية ( תשומות )
const [result, setResult] = useState(0); //المبلغ للدفع / للارجاع
const [showDiv, setShowDiv]= useState(false)
const printRef = React.useRef();

const [isActive, setIsActive] = useState(false);
const { user } = useAuthContext()

// Form initial state
const INITIAL_STATE = {
  month:" ",
  year: " ",
  tax: "17",
  s: " ",
  a: " ",
  capex: " ",
  exp:" ",
  b: " ",
  c : " ",
  dd: " ",
  ee: " ",
  result: " ",


};
// var monthV = document.querySelector('#month').value;

const [values, setValues] = useState(INITIAL_STATE);

// Handle Change Function 
const handleChange = e => {
  const { name, value } = e.target;
  setValues(prevState => ({ ...prevState, [name]: value }));
  // console.log(values)


 
}
function clearForm(){
  document.getElementById("calcForm").reset();
}
  //  احتساب جديد
const newCalc = () => {
  window.location.reload();
}




  // Current Date 
  const d = new Date().toLocaleString() + "";
  let current = d.split(',');
  const today = current[0];
  const todaySplitArr = today.split('/');
  const todayFrm = " " + todaySplitArr[1] + ' / ' + todaySplitArr[0] + ' / ' + todaySplitArr[2];

  //=================  Form Submit Handler====================
  function Calculate(e){

    e.preventDefault();
    setShowDiv(true)
    setMonth(values.month);
    setYear(values.year);
    setTax(values.tax);
    setA(values.a);
      // values calculations 
      const amount = values.s;
      if (amount !== '' && values.tax !== '') {
        if (bool) {
          var amountNew = values.s / ((values.tax / 100) + 1);
          const amountTaxx = parseFloat(values.s) - parseFloat(amountNew);
          var cValue = (amountTaxx.toFixed(2));
          setC(Math.round(amountTaxx));
          const amountTotall = amountNew.toFixed(2);
          setB((Math.round(amountTotall)));

          // D and E values 
          var dValue = values.exp / ((values.tax / 100) + 1);
          var eValue = values.capex / ((values.tax / 100) + 1);
          var tvalue=(values.tax/100);
          var ddd = dValue* tvalue;
          var eee = eValue* tvalue;
          
          setD(Math.round(ddd));
          setE(Math.round(eee));
          // Total result value 
          var calcResult = cValue - (eee + ddd);
        
          setResult(Math.round(calcResult));
            if(calcResult < 0 )
            {
              setRcolor("red")
            }else
            {
              setRcolor("green");
            }
          
        } else {
          const amountTax2 = (values.s * values.tax) / 100;
          setC(amountTax2.toFixed(2))
          const amountTotal2 = parseFloat(amovalues.sunt) + parseFloat(amountTax2);
          setB(amountTotal2.toFixed(2));
        }
        // $('#tax_amount').val(parseFloat(calcObject.amountTax).toFixed(2));
        // $('#total_amount').val(parseFloat(calcObject.amountTotal).toFixed(2));
      } else {
        setC("0.00")
        setB("0.00")
        // $('#tax_amount').val(calcObject.amountNull);
        // $('#total_amount').val(calcObject.amountNull);
      }
   
  
  }
//=================  Form Submit Handler====================
 // PDF print function 
 const handleDownloadPdf = async () => {

  const element = printRef.current;
  element.style.background = '#FFFFFF';
  // element.style.color = '#212529';

  const iaactive = true;
  await setIsActive(iaactive);
  //  Current Date 
  const d = new Date().toLocaleString() + "";
  let current = d.split(',');
  const today = current[0];
  const todaySplitArr = today.split('/');
  const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
  // Current Date 

  // PDF Header And Footer Values 
  const header = todayFrm;



  const canvas = await html2canvas(element);


  const data = canvas.toDataURL('image/png');
  const pdf = new jsPDF();


  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight =
    (imgProperties.height * pdfWidth) / imgProperties.width;


  // pdf.addImage(data, 'PNG', 0, 10, pdfWidth, pdfHeight);
  // // Header
  // pdf.text(header, 2, 15, { baseline: 'top' });
  // // Footer
  //  Header  - Logo
  pdf.addImage(massportallogo, 'png', 90, 5, 20, 20);
  // PDF -  DAta 
  pdf.addImage(data, 'PNG', 0, 25, pdfWidth, pdfHeight);
  // Header - Date 
  pdf.text(header, 10, 34, { baseline: 'top' });

  const pageSize = pdf.internal.pageSize;
  const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

  const footer = d + '             www.mass-portal.com';
  pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight - 2, { baseline: 'bottom' });
  pdf.setFontSize(20);
  pdf.save(' massPortal-تقرير كشف دوري .pdf');
};
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>MassPortal - كشف دوري  </title>
        <meta name="description" content="تعبئة كشف دوري" />
      </Helmet>
      {/* Page Content */}
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <br></br><br></br>
                  <h3 className="page-title"> تعبئة كشف دوري  </h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* // Form Data To Use In The Calculator  */}

            <div className="card ">
              <div className=" card-body eOfService">
                <br></br>
                <form onSubmit={Calculate} id="calcForm" >
                  <br></br>
                  <div className=" row ">
                  <h2 className="card-title text-center">ادخال معطيات عن الفترة    </h2>
                    <div className="col-sm-4">
                    <br></br>
                      <div className="form-group text-center">
                        
                        <label className='year-text'> سنة     </label>
                        <input 
                        className="form-control" 
                        type="text" 
                        defaultValue="" 
                        name='year'
                        placeholder=' سنة ' 
                        onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                    <br></br>
                        <label className=" col-form-label label-vit ">شهر  </label>
                        <select  
                        className=" select floating tax-select  "
                        name='month'
                        id="month"
                        onChange={handleChange}
                         >
                           <option>--</option>
                          <option value='1' >1</option>
                          <option value='2'>2</option>
                          <option value='1-2'>1 - 2</option>
                          <option value='3'>3</option>
                          <option value='4'>4</option>
                          <option value='3-4'>3 - 4 </option>
                          <option value='5'>5</option>
                          <option value='6'>6</option>
                          <option value='5-6'> 5 - 6 </option>
                          <option value='7'>7</option>
                          <option value='8'>8</option>
                          <option value='7-8'> 7 - 8 </option>
                          <option value='9'>9</option>
                          <option value='10'>10</option>
                          <option value='9-10'>9 - 10 </option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                          <option value='11-12'>11 - 12 </option>
                         
                        </select>
               
               
                    
                    </div>
                    <div className='col-sm-4'>
                      <br></br>
                    <label className=" col-form-label label-vit ">نسبة ضريبة القيمة المضافة ( ض . ق . م ) </label>
               
               <select
                 id="tax"
                 className='select floating  tax-select '
                 name='tax'
                 // value={contact.tax}

                 onChange={handleChange}
               >
                 
                 <option value="17"> 17 % </option>
                 <option value="20">  20 %</option>
                 <option value="16"> 16 %  </option>

               </select><br></br>
                    </div>
                  </div>
                  <br></br>
                  <div className='row'>
                  </div>
                  <div className='row form-border-green'> 
                  <br></br>
                     <h2 className="card-title text-center"><br></br>المبيعات    </h2>
                  <div className='col-sm-2'></div>
                    <br></br>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>أدخل المبيعات / <span> شامل </span>  </label>
                        <input 
                        className="form-control "
                         defaultValue="" 
                         type="number" 
                         name='s'
                         onChange={handleChange}

                         />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                      <label>أدخل المبيعات / <span> معفى </span>  </label>
                        <input className="form-control"
                        defaultValue="" 
                        type="number"
                        name='a'
                        onChange={handleChange}

                         />
                      </div>
                      <br></br>
                    </div>
                  </div><br></br>
                  <div className='row form-border-red '>
                    <br>
                    </br>
                  <h2 className="card-title text-center"><br></br>المصاريف     </h2>
                  <div className='col-sm-2'></div>
                    <div className="col-sm-4">
                      <br></br>
                      <div className="form-group">
                      <label>المصاريف العادية  / <span> شامل  </span>  </label>
                        <input className="form-control " 
                        defaultValue="" 
                        type="number"
                        name='exp'
                        onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <br></br>
                      <div className="form-group">
                      <label>المصاريف / أصول  / <span> ثابتة  </span>  </label>
                        <input className="form-control " 
                        defaultValue="" 
                        type="number"
                        name='capex'
                        onChange={handleChange}
                         />
                      </div>
                      <br></br>
                    </div>

                    
                  </div><br></br>
                  <div className='row'>
                    <div className='col-sm-2'></div>
                  <div className="col-sm-4">
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">احسب   </button>
                  </div> 
                  </div>
                  <div className="col-sm-4">
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn" onClick={clearForm}>تقرير جديد    </button>
                  </div> 
                  </div>
                  <div className='col-sm-2'></div>

                  </div>
                 
                  
                  
                </form>
              </div>
            </div>

            {/* // End OF Form Data Here */}

    {/* ====================== Report Start ========================================= */}
    { showDiv&&
    <>
           <div className="row  text-right ">
            <div className="col-sm-12 col-md-10" >


            </div>
            <div className="col-sm-12 mt-3  "  >
              <button className="btn   " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                <AiOutlineFilePdf size={30} className='privacy ' />
              </button>
              <button className="btn " data-tip data-for="excel-tip">
                <SiMicrosoftexcel size={30} className='privacy ' />
              </button>
              <ReactTooltip id="pdf-tip" place="top" effect="solid">
                طباعة PDF
              </ReactTooltip>
              <ReactTooltip id="excel-tip" place="top" effect="solid">
                طباعة Excel
              </ReactTooltip>
            </div>

          </div>
          <div  ref={printRef}>

           <div className={ isActive?"card-white":"card"}>
              <div className={ isActive ?"card-body-white eOfService":" card-body eOfService"}>
                <br></br>
                <form className={isActive?"form-white":" "}>
                  <div className='row'>
                    <br></br>
                    {isActive&& 
                    <div className='col-sm-12 col-md-2'></div>
                    }
                    <div className='col-6'>
                      <h2 className={isActive?"card-title privacy-black-bold text-right":"card-title text-left"}>تقرير كشف دوري   <span className="privacy">( ض . ق . م )</span> </h2>
                    </div>

                    {!isActive && 
                    <div className='col-6'>
                      <h2 className="card-title text-right">التاريخ     <span className="privacy"> {todayFrm} </span> </h2>
                      <br></br> <br></br>
                    </div>}
                  </div>

                  {isActive&& <><br></br><br></br></>}

                  <div className=" row ">
                    
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label> الفترة  / سنة      </label>
                        <input className="form-control" 
                        type="text" defaultValue="" 
                        placeholder={isActive?" ":'شهر'}
                        value={year}
                        />
                      </div>


                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>الفترة / شهر   </label>
                        <input className="form-control " defaultValue=""
                         type="text" placeholder={isActive?" ":'سنة'}
                         value={month}
                          />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label> نسبة القيمة المضافة    </label>
                        <input className="form-control text-center" type="text" 
                        defaultValue=""
                         placeholder=' 17 %  ' 
                         value={ tax + " % " }
                         />
                     
                      </div>
                    </div>


                    <div className="col-sm-4">
                      <div className="form-group">
                        <label> اسم المشتغل  </label>
                        <input className="form-control" type="text" defaultValue="" />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>العنوان </label>
                        <input className="form-control " defaultValue="" type="text" />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>رقم المشتغل </label>
                        <input className="form-control " defaultValue="" type="text" />
                      </div>
                      <br></br>

                    </div>
                  </div>
                  <br></br>
                  <div className='row'>

                  </div>
                  <div className='row form-border-green'>
                    <br></br>
                    <div className="col-sm-4">

                      <div className="form-group">
                        <label>(ض . ق . م ) على الصفقات   </label>
                        <input className="form-control " 
                        defaultValue=""
                         type="text"
                         value={formatMoney(c,{symbol: "₪",  format: " %v %s" })} 
                         />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label> صفقات ملزمة    </label>
                        <input className="form-control " 
                        defaultValue="" 
                        type="text"
                        value={formatMoney(b,{symbol: "₪",  format: " %v %s" })}
                         />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label> صفقات معفية    </label>
                        <input className="form-control " defaultValue="" 
                        type="text"
                        value={formatMoney(a,{symbol: "₪",  format: " %v %s" })+" "}
                         />
                      </div>
                      <br></br>
                    </div>

                  </div><br></br>

                  {/* <div className='row'>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>ضريبة مدخلات   </label>
                        <input className="form-control " defaultValue="" type="text" />
                      </div>
                    </div>
                  </div> */}

                  <div className='row form-border-red '>

                    <div className="col-sm-4">
                      <br></br>
                      <div className="form-group">
                        <label>( ض . ق . م ) مدخلات أصول ثابتة ( תשומות )   </label>
                        <input className="form-control "
                         defaultValue=""
                          type="text"
                          value={formatMoney(ee,{symbol: "₪",  format: " %v %s"})}
                           />
                      </div>
                    </div>
                    <div className="col-sm-8"></div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>( ض . ق . م ) مدخلات عادية ( תשומות )   </label>
                        <input className="form-control "
                        
                          type="text" 
                          value={formatMoney(dd,{symbol: "₪",  format: " %v %s"})}
                          />
                      </div>

                    </div>



                  </div><br></br>
                  <div className='row form-border-green'>
                    <div className="col-sm-4 ">
                      <div className="form-group">
                        <br></br>
                        <label > المبلغ للدفع /   <span className={isActive ? "":"text-danger"}>للارجاع  </span></label>
                       {!isActive&&
                        <input className="form-control " 
                        style={{color: resultColor , fontWeight: "bold"} }
                     
                        type="text"
                        value={formatMoney(result,{symbol: "₪",  format: " %v %s"})}
                         />}
                               {isActive&&
                        <input className="form-control " 
                     
                        type="text"
                        value={formatMoney(result,{symbol: "₪",  format: " %v %s"})}
                         />}
                      </div>
                      <br></br>
                    </div>
                    <br></br>
                  </div>
                  {!isActive&&<div className='row'>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <br></br>
                        <label>المبلغ بالكلمات </label>
                        <input className="form-control " defaultValue=" " type="text" />
                      </div>
                    </div>
                  </div>}



                  {/* <div className="submit-section">
                    <button className="btn btn-primary submit-btn">حفظ  </button>
                  </div> */}
                   <div className="form-group row">


  <br></br>
  {!isActive&&<button className="btn btn-primary naqaha-btn insurance-btn" onClick={newCalc} > احتساب جديد  </button>}
  <br></br> <br></br>

</div>
                </form>

              </div>


            </div>
           </div>
           

            <br></br>
            </>}
           {/*============================Report End==========================  */}
            {/* Buttons With Links - Start */}
            <div className='row'>



              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 btn-div">
                <br></br>
                <br></br>
                <a href="https://secapp.taxes.gov.il/EMHANDOCH/STARTPAGEINTERNET.ASPX" target='_blank' className="btn btn-primary btn-block btn-download ">
                  <span className='button-links'> تصريح </span>
                </a>
                <br></br>
                <br></br>
              </div>

              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 btn-div">
                <br></br>
                <br></br>
                <a href="https://secapp.taxes.gov.il/EMHANPAYMENT/HAZANATPIRTEYTASHLUM.ASPX" target='_blank' className="btn btn-primary btn-block btn-download ">
                  <span className='button-links'> دفع </span>

                </a>
                <br></br>
                <br></br>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 btn-div">
              <br></br>
                <br></br>
                <a href="https://secapp.taxes.gov.il/EmDvhmfrt/wUploadFileHeshboniotSim.aspx" target='_blank' className="btn btn-primary btn-block btn-download ">
                  <span className='button-links'> فحص ملف ( PCN )</span>


                </a>
                <br></br>
                <br></br>
              </div>

            </div>
            {/* Buttons With Links - End */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>
  );
}

