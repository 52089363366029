let ra=[
 {
  ar: "راتب",
  he: "משכורת",
  en: "Salary\/ wages"
 },
 {
  ar: "راس مال",
  he: "הון\/ קרן",
  en: "Capital\/ fund"
 },
 {
  ar: "راسمال ",
  he: "הון\/ קרן",
  en: "Capital\/ fund"
 },
 {
  ar: "راسمال اسمي",
  he: "הון מניות רשום",
  en: "Authorized share capital\/ registered share capital"
 },
 {
  ar: "راسمال اولي",
  he: "הון התחלתי",
  en: "Initial capital"
 },
 {
  ar: "راسمال بشري",
  he: "הון אנושי",
  en: "Human capital"
 },
 {
  ar: "راسمال ثابت",
  he: "הון קבוע",
  en: "Fixed capital"
 },
 {
  ar: "راسمال جاهز",
  he: "הון נזיל",
  en: "Fluid capital"
 },
 {
  ar: "راسمال حر",
  he: "הון חופשי",
  en: "Free capital"
 },
 {
  ar: "راسمال ذو اخطار",
  he: "הון סיכון",
  en: "Risk capital\/ venture capital"
 },
 {
  ar: "راسمال سائل",
  he: "הון נזיל",
  en: "Fluid capital"
 },
 {
  ar: "راسمال عاطل",
  he: "הון לא מנוצל",
  en: "Idle capital"
 },
 {
  ar: "راسمال عامل",
  he: "הון חוזר",
  en: "Working capital\/ circulating capital"
 },
 {
  ar: "راسمال لم يطالب بة",
  he: "הון שלא נקרא לתשלום",
  en: "Uncalled capital"
 },
 {
  ar: "راسمال متداول",
  he: "הון חוזר",
  en: "Working capital\/ circulating capital"
 },
 {
  ar: "راسمال مدفوع ",
  he: "הון נפרע",
  en: "Paid- up capital"
 },
 {
  ar: "راسمال مرخص",
  he: "הון מניות רשום",
  en: "Authorized share capital\/ registered share capital"
 },
 {
  ar: "راسمال مستثمر",
  he: "הון מושקע",
  en: "Invested capital"
 },
 {
  ar: "راسمال مصدر",
  he: "הון מונפק",
  en: "Issued capital"
 },
 {
  ar: "راسمال مصرح بة",
  he: "הון רשום",
  en: "Authorized  capital"
 },
 {
  ar: "راسمال موظف",
  he: "הון מועסק",
  en: "Capital employed"
 },
 {
  ar: "راسمال نشط",
  he: "הון פעיל",
  en: "Active capital"
 },
 {
  ar: "راسمال نقدي",
  he: "הון כספי",
  en: "Money capital"
 },
 {
  ar: "راسمال وطني",
  he: "הון לאומי",
  en: "National capital"
 },
 {
  ar: "راسمالية ",
  he: "קפיטליזם",
  en: "capitalism"
 },
 {
  ar: "راهن",
  he: "לוקח משכנתא",
  en: "Mortgagor\/ mortgager"
 },
 {
  ar: "رئيس",
  he: "נשיא",
  en: "president"
 },
 {
  ar: "رئيس اداري",
  he: "מנהל אדמיניסטרטיבי",
  en: "Administrative head"
 },
 {
  ar: "رب العمل",
  he: "מעסיק",
  en: "employer"
 },
 {
  ar: "ربا",
  he: "ריבית",
  en: "Interest"
 },
 {
  ar: null,
  he: "ריביצ קצוצה",
  en: "usurious interest"
 },
 {
  ar: "ربح",
  he: "רווח",
  en: "Profit\/ gain revenue\/ income"
 },
 {
  ar: null,
  he: "הכנסה",
  en: null
 },
 {
  ar: "ربح اجمالي",
  he: "רווח גולמי",
  en: "Gross profit"
 },
 {
  ar: "ربح التشغيل",
  he: "רווח תפעולי",
  en: "Operating profit"
 },
 {
  ar: "ربح التصفية",
  he: "דיווידנד פירוק",
  en: "Liquidation dividend"
 },
 {
  ar: "ربح الراسمال",
  he: "רווח הון",
  en: "Capital gain"
 },
 {
  ar: "ربح الصرف",
  he: "פרמיית חילפין",
  en: "Exchange premium"
 },
 {
  ar: "ربح راسمالي",
  he: "רווח הון",
  en: "Capital gain"
 },
 {
  ar: "ربح سهم مؤقت",
  he: "דיווידנד ביניים",
  en: "Interim dividend"
 },
 {
  ar: "ربح صافي",
  he: "רווח נקי",
  en: "Net profit"
 },
 {
  ar: "لربح صوري",
  he: "רווח מדומה",
  en: "Fictitious profit"
 },
 {
  ar: "ربح قانوني",
  he: "דיווידנד שיש לחלקו על פי חוק",
  en: "Statutory dividend"
 },
 {
  ar: "ربح لم يطالب بة",
  he: "דיווידנד שלא נדרש",
  en: "Unclaimed dividend"
 },
 {
  ar: "ربح مامول",
  he: "רווח שלא מומש",
  en: "Paper profit"
 },
 {
  ar: "ربح متوقع",
  he: "רווח חזוי",
  en: "Anticipated profit"
 },
 {
  ar: "ربح محض",
  he: "רווח טהור",
  en: "Pure profit"
 },
 {
  ar: "ربح محقق",
  he: "רווח כתוצאה ממימוש נייר ערך",
  en: "Realized profit"
 },
 {
  ar: "رجل اعمال",
  he: "איש עסקים",
  en: "businessman"
 },
 {
  ar: "رخاء",
  he: "גאות( כלכלית)\/ שגשוג",
  en: "prosperity"
 },
 {
  ar: "رخصة",
  he: "רשיון",
  en: "license"
 },
 {
  ar: "رخصة استيراد",
  he: "רשיון יבוא",
  en: "Import license"
 },
 {
  ar: "رخصة تصدير",
  he: "רשיון יצוא",
  en: "Export license"
 },
 {
  ar: "رد المال",
  he: "החזר הון",
  en: "Capital recovery"
 },
 {
  ar: null,
  he: "החזר כספי",
  en: "refund"
 },
 {
  ar: "ردهة المبادلات التجارية",
  he: "זירת המסחר",
  en: "Trading floor"
 },
 {
  ar: "رسم",
  he: "אגרה",
  en: "Fee"
 },
 {
  ar: null,
  he: "היטל\/ מס",
  en: "charge\/ duty\/ tax"
 },
 {
  ar: "رسم ارساء",
  he: "דמי עגינה",
  en: "keelage"
 },
 {
  ar: "رسم استيراد",
  he: "מכס",
  en: "Import duty\/ customs\/ import tariff"
 },
 {
  ar: "رسم الجمرك",
  he: "מכס",
  en: "Import duty\/ customs\/ import tariff"
 },
 {
  ar: "رسم الرصيف",
  he: "דמי רציף",
  en: "Quayage\/ wharfage\/ quay handling charges"
 },
 {
  ar: "رسم الطوابع",
  he: "מס בולים",
  en: "Stamp duty"
 },
 {
  ar: "رسم بياني للفعالية",
  he: "תרשים פעילות",
  en: "Activity chart"
 },
 {
  ar: "رسم تاخير",
  he: "דמי השהיה",
  en: "Demurrage free"
 },
 {
  ar: "رسم تسجيل",
  he: "דמי הרשמה",
  en: "Registration fee"
 },
 {
  ar: "رسم جمركي",
  he: "דמי מכס",
  en: "Customs duty"
 },
 {
  ar: "رسم خدمة",
  he: "דמי שירות",
  en: "Service charge"
 },
 {
  ar: "رسم دخول",
  he: "דמי כניסה",
  en: "Admission charge\/ admission fee"
 },
 {
  ar: "رسم مقطوع",
  he: "תשלום קבוע",
  en: "Fixed fee"
 },
 {
  ar: "رسوم جمركية مستعادة",
  he: "הישבון (החזרי כספים)",
  en: "Customs drawback"
 },
 {
  ar: "رشوة",
  he: "שוחד",
  en: "bribe"
 },
 {
  ar: "رصيد",
  he: "יתרה",
  en: "Balance"
 },
 {
  ar: null,
  he: "פיקדון",
  en: "deposit"
 },
 {
  ar: "رصيد دائن",
  he: "יתרת זכות",
  en: "Credit balance"
 },
 {
  ar: "رصيد في المصلرف",
  he: "יתרה בבנק",
  en: "Bank balance"
 },
 {
  ar: "رصيد مدين",
  he: "יתרת חובה",
  en: "Debit balance"
 },
 {
  ar: "رفاهية",
  he: "רווח\/ סעד",
  en: "Welfare\/ relief"
 },
 {
  ar: "رفاهية اجتماعية",
  he: "רווחה חברתית",
  en: "Social welfare"
 },
 {
  ar: "رفض الاعتراف بالدين",
  he: "השתמטות מתשלום חוב",
  en: "repudiation"
 },
 {
  ar: "رفع السعر",
  he: "מרווח (שיעור התוספת במחיר)",
  en: "Mark up"
 },
 {
  ar: "رفع قيمة عملة",
  he: "ייסוף מטבע",
  en: "Currency appreciation"
 },
 {
  ar: "رق",
  he: "עבדות",
  en: "slavery"
 },
 {
  ar: "رقابة على الصرف",
  he: "פיקוח על מטבע",
  en: "Exchange control"
 },
 {
  ar: "رقم",
  he: "מספר",
  en: "number"
 },
 {
  ar: "رقم الحساب",
  he: "מספר החשבון",
  en: "Account number"
 },
 {
  ar: "رقم قياسي",
  he: "מדד",
  en: "index"
 },
 {
  ar: "ركود",
  he: "מיתון",
  en: "recession"
 },
 {
  ar: "ركود اقتصادي",
  he: "שפל כלכלי",
  en: "Depression\/ slump"
 },
 {
  ar: "رهن",
  he: "משכנתא",
  en: "mortgage"
 },
 {
  ar: "رهن متحرك",
  he: "שיעבוד צף",
  en: "Floating charge"
 },
 {
  ar: "رواج ",
  he: "סחירות",
  en: "marketability"
 },
 {
  ar: "ريع",
  he: "הכנסה",
  en: "Income\/ revenue"
 }
]
export default ra ;