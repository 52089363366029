/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Itt21 from '../../../components/Tables/basic21';
import Itt22 from '../../../components/Tables/basic22/itt22';
import { TaxCalculatorComponent21 } from '../../../calculators/components/TaxCalculatorComponent21';
import { TaxCalculatorComponent22 } from '../../../calculators/components/TaxCalculatorComponent22';
export default class LawSick extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | قانون الاجازة المرضية   </title>
					<meta name="description" content="MassPortal - قانون الاجازة السنوية " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>قانون الاجازة المرضية </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>


						<div className="row">

							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<h3 >يحدد قانون الإجازة المرضية (1976) حقوق الموظف بغيابه عن العمل بسبب المرض. </h3>
								<br></br>
								<ol className='privacy-ol'>
									<li>
										<p>
										حيث يحدد القانون أي أيام الغياب المرضية يحق للموظف أن يدفع له راتبه عنها. وأيضا ما هو عدد الأيام المرضية التي يمكن أن يتقاضى عنها الموظف.
											<br></br>
										</p>
									</li>
									<li>
										<p>
										قانون الإجازة المرضية (غياب بسبب مرض الأبناء) 1993 يمنح الوالدين في ظروف معينة أن يتقاضوا بدل أيام مرض الأولاد. حيث تخصم من أيامهم.


										</p>
									</li>
									<li>
										<p>
										قانون الإجازة المرضية (1993), يمنح الموظف في ظروف معينة أن يتقاضى أيضا عن أيام مرضية للوالدين.
										</p>
									</li>
									<li>
										<p>
										قانون الإجازة المرضية في ظروف معينة يمنح الموظف أن يتقاضى أيضا عن أيام مرضية (عن الزوج/الزوجة), حيث أيضا في هذه الحالة تخصم أيام الغياب من أيام الإجازة المرضية للموظف.
										</p>
									</li>

								</ol>
							


							</div>

							<div className="col-lg-10">
									
								<h2 >نفقات احتساب أيام الإجازة المرضية  </h2>
								<br></br>
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy">نفقات احتساب أيام الإجازة المرضية</h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead>
													<tr>
														<th> أيام الغياب  </th>
														<th>نفقات</th>
														<th>نسبة الدفع </th>
														
														{/* <th>الضريبة </th>
												<th>الضريبة التراكمية</th> */}

													</tr>
												</thead>
												<tbody>
													
													<tr>
														
														<td>الأول</td>
														<td>لا يدفع</td>
														<td>--</td>
														{/* <td>1.013</td> */}
													</tr>
													<tr>
														<td>الثاني  </td>
														<td>يدفع ( 1/2 ) نفقات مرضية </td>
														<td>( 50% ) من راتب يومي </td>
													</tr>

													<tr>
														<td>الثالث</td>
														<td>يدفع ( 1/2 ) نفقات مرضية </td>
														<td>( 50% ) من راتب يومي </td>
														
													</tr>
													<tr>
														<td>الرابع</td>
														<td>يدفع النفقات كاملة </td>
														<td>( 100% ) من راتب يومي </td>
													</tr>
													
												</tbody>
											</table>

										</div>

									</div>
									
								</div>
								<h2 >تراكم: </h2>
								<br></br>
								<ol className='privacy-ol'>
									<li>
										<p>
											يحق للموظف أن يجمع 1.5 أيم مرضية للشهر 
																						<br></br>
										</p>
									</li>
									<li>
										<p>
اذا عمل قسم من الشهر فيحق له نسبة القسم من هذا الشهر 										</p>
									</li>
									<li>
										<p>
											يحق للموظف أن يجمع 90 يوم على الأقل 
											</p>
									</li>
								

								</ol>
				
								
							</div>

							{/* =============================================================== */}
							<div className="col-lg-10">
							<h2 >الغياب بسبب المرض  </h2>
								<br></br>
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy">الغياب بسبب المرض </h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead>
													<tr>
														<th> تعد كأيام مرضية   </th>
														<th>لا تعد كأيام مرضية </th>
													
														{/* <th>الضريبة </th>
												<th>الضريبة التراكمية</th> */}

													</tr>
												</thead>
												<tbody>
													<tr>
														<td >مرض الابن </td>
														<td >حوادث عمل   </td>
													

													</tr>
													<tr>
														<td>مرض أبو الابن  </td>
														<td>حوادث طرق  </td>
													</tr>
													<tr>
														<td>مرض الزوج / الزوجة   </td>
														<td> اجازة ولادة   </td>
													</tr>
													<tr>
														<td>اجهاض   </td>
														<td>اجازة قبل الولادة   </td>
													</tr>
													<tr>
														<td>معالجة للولادة   </td>
														<td>--  </td>
													</tr>
													
												</tbody>
											</table>

										</div>

									</div>
									
								</div>
								
								
							</div>

						</div>



						{/* /Content End */}
					</div>

					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
