import React, { useState, } from "react";

export function TaxCalculatorComponent21(props) {
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [points, setPoints] = useState(0);
  const [tax, setTax] = useState(0);
  const [show, setShow] = useState(false)
  const [data, setData] = useState([]);


  const handleSubmit = (evt) => {
    evt.preventDefault();
    let id = Math.random();
    if (monthlyIncome !== "") {
      setTax(calculateTax(parseFloat(monthlyIncome)));
      setShow(true)
    }

    function calculateTax(income) {
      /*
       YEAR 2021
       Price Point 223
      up to 6290 10%
      6290 => 9030 14%
      9030 => 14490 20%
      14490 => 20140 31%
      20140 => 41910 35%
      41910 => 53970 47%
      53970 => up  50%
      */
      const num1 = 6290;
      const num2 = 9030;
      const num3 = 14490;
      const num4 = 20140;
      const num5 = 41910;
      const num6 = 53970;
      const percentage1 = 10 / 100;
      const percentage2 = 14 / 100;
      const percentage3 = 20 / 100;
      const percentage4 = 31 / 100;
      const percentage5 = 35 / 100;
      const percentage6 = 47 / 100;
      const percentage7 = 50 / 100;
      const np1 = num1 * percentage1;
      const np2 = num2 * percentage2;
      const np3 = num3 * percentage3;
      const np4 = num4 * percentage4;
      const np5 = num5 * percentage5;
      const np6 = num6 * percentage6;
      let tax = 0;
      const pricePoint = 218;

      if (income > 0 && income <= num1) {
        tax = (income) * percentage1 - (points * pricePoint);
        
      }
      else if (income > num1 && income <= num2) {
        tax = ((income - num1) * percentage2) + (np1) - (points * pricePoint);
      }
      else if (income > num2 && income <= num3) {
        tax = ((income - num2) * percentage3) + (np1 + np2) - (points * pricePoint);
      }
      else if (income > num3 && income <= num4) {
        tax = ((income - num3) * percentage4) + (np1 + np2 + np3) - (points * pricePoint);
      }
      else if (income > num4 && income <= num5) {
        tax = ((income - num4) * percentage5) + (np1 + np2 + np3 + np4) - (points * pricePoint);
      }
      else if (income > num5 && income <= num6) {
        tax = ((income - num5) * percentage6) + (np1 + np2 + np3 + np4 + np5) - (points * pricePoint);
      }
      else if (income > num6) {
        tax = ((income - num6) * percentage7) + (np1 + np2 + np3 + np4 + np5 + np6) - (points * pricePoint);
      }

      setData(prev => [...prev,
      {
        id: id,
        tax: tax,
        points: points,
        monthlyIncome: monthlyIncome
      }])

      if (tax < 0) {
        tax = 0;
      } else {
        tax = tax.toFixed(1);
      }
      return tax;

    }

  }
  const handleDelete = (id) => {
    let newData = data.filter((data) =>
      data.id !== id)
    setData(newData)
    newData = [];
  }
  return (
    <div className="card tax-card">
      <br></br>
      <br></br>
      <div className="card-body">
        <form onSubmit={handleSubmit} >
          <div className="">
            <h3 className="card-title text-center"> احسب ضريبتك لعام <span className="privacy">2021</span> </h3>

            <div className="form-gronum row">
              <div className="col-6">
                <label className=" col-form-label">الراتب الشهري</label>
                <input type="text" className="form-control" name="monthlyincome" value={monthlyIncome}
                  onChange={(e) => setMonthlyIncome(e.target.value)} />
              </div>

              <div className="col-6">



                <label className=" col-form-label">نقاط التزكية</label>
                <div className="">
                  <input type="text" className="form-control m-b-0" name="points" value={points}
                    onChange={(e) => setPoints(e.target.value)} />


                </div>
              </div>
            </div>



          </div>
          <br />
          <div className="text-center">
            <button type="submit" className="btn btn-primary calc-button">حساب</button>
          </div>
        </form>

        <br /><br />

        {show &&

          <div className="card-body">

            <h3 className="card-title text-center privacy">احتساب الضريبة الشهرية</h3>


            <div className="table-responsive">
              <table className="table table-striped mb-0 ">
                <thead>
                  <tr>
                    <th></th>
                    <th>الضريبة المستحقة</th>
                    <th>نقاط التزكية</th>
                    <th>الراتب الشهري </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((d, index) => (
                      <tr key={index}>
                        <td onClick={() => handleDelete(d.id)}>حذف</td>
                        <td>{d.tax}</td>
                        <td>{d.points}</td>
                        <td>{d.monthlyIncome}</td>
                        <td>{index + 1}</td>
                      </tr>
                    ))}

                </tbody>
              </table>
            </div>
          </div>

        }
      </div>
    </div>
  );
}