

import React  from "react";
import massShebah from "../real-state-shebah/one-time"
//
export function TxTblOneTimeShebah(props) {
    function commify(n) {
        var parts = n.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
      }
 return(<div className="card tax-card ">
							
 <div className="card-body">
 <div className="card-header">
     <h4 className="card-title mb-0 text-center privacy">نسب الضرائب المطبقة على التقدير الحقيقي</h4>
    
 </div>
     <div className="table-responsive">
         <table className="table table-nowrap table-striped mb-0">
         <thead> 
         
         <tr > 
            
             <th colSpan={3} className='table-tr-underline bold'>  شخص واحد</th>
             
            
         
         </tr>
         
         
         </thead>
             <thead>
 
                 <tr>
                     <th className="privacy">التاريخ</th>
                     
                     <th></th>
                     <th ></th>
                     
                     
                     
                    
                    
                     {/* <th>Country</th> */}
                 </tr>
             </thead>
             <tbody>
             {
 
                massShebah.massShebah.map((d) => 
                <tr>
                 <th className="privacy">        {d.date}</th> 
                 
                 <th>   {commify(d.band1.max)}</th> 
                 <th > { commify(d.band2.max)}</th> 
                 
                 
                 
                
                
                </tr>
                )
 
 
             }
                
                 {/* <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr>
 <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr>
 <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr> */}
             </tbody>
         </table>
     </div>
 </div>
 </div>)
}
