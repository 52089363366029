/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Itt21 from '../../../components/Tables/basic21';
import Itt22 from '../../../components/Tables/basic22/itt22';
import { TaxCalculatorComponent21 } from '../../../calculators/components/TaxCalculatorComponent21';
import { TaxCalculatorComponent22 } from '../../../calculators/components/TaxCalculatorComponent22';
export default class LawEid extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | أيام العيد     </title>
					<meta name="description" content="MassPortal - قانون الاجازة السنوية " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container ">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>أيام العيد   </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>


						<div className="row ">

							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<h2 >مستحقات بدل ايام العيد </h2>
								<br></br>
								<ol className='privacy-ol'>
									<li>
										<p>
											موظف شهري :

											<br></br>
											<ul className='privacy-ol'>
												<br></br>
												<li>
													<h5>
														يحق للموظف الشهري أن يتقاضى بدل ايام راتبه الكامل دون أن يخصم منه أي يوم من أيام العيد التي تغيبها بسبب العيد.
														<br></br>
													</h5>

												</li>
												<li>
													<h5>
														الأساس في هذا الموضوع ان الموظف يتقاضى راتبه على أساس شهري وبذلك يحق له ان يستلم راتبه الشهري بأكمله. بدون أي علاقة لعدد أيام عمله. او الاستراحه الاسبوعيه المترتبة في ذلك الشهر,  وبالطبع ان الموظف عمل كل أيام العمل الممكن عملها في ذلك الشهر.
													</h5>

												</li>
												<li>
													<h5>
														يجب الانتباه انه لا يوجد تمييز بين موظف جديد وموظف قديم لذا أيضا" الموظف الجديد الذي يتقاضى راتبه على أساس شهري يحق له استلام كامل راتبه بدون ان يخصم منها أي يوم من أيام العيد أو أيام الاستراحه الاسبوعيه.


													</h5>
												</li>
											</ul>
										</p>
									</li>
									<li>
										<p>
										موظف مياومه او حسب الساعة :

											<br></br>
											<ul className='privacy-ol'>
												<br></br>
												<li>
													<h5>
													إلزام صاحب العمل بدفع أيام العيد لموظف مياومة أو موظف يعمل حسب الساعة . معموله به من تاريخ <span className='privacy'> 1 - 7 - 2000 </span> على كل اصحاب العمل.
														<br></br>
													</h5>

												</li>
												<li>
													<h5>
													الإلزام مشروط بأن يعمل الموظف على الأقل ثلاثة شهور عند صاحب العمل . وبشرط أن لا يتغيب الموظف عن العمل ( يوم قبل أيام العيد ويوم بعد أيام العيد ) الا بموافقة صاحب العمل.
													</h5>

												</li>
												<li>
													<h5>
													بالنسبة لموظف المياومة أو الذي يعمل بالساعة لا يحق له مستحقات بدل ايام العيد الواقعة في يوم العطلة الأسبوعية. ( الجمعة, السبت او الاحد).

													</h5>
												</li>
											</ul>
										</p>
									</li>
									

								</ol>
								{/* <ul className='privacy-ol'>
									<li>
										<h4>
											يفسر القانون أيضا الأيام الغير محسوبة كأيام إجازة, وما هي الطرق التي تعطى بها الإجازة.


											<br></br>
										</h4>

									</li>
									<li>
										<h4>
											هدف القانون الأول هو جعل الموظف استغلال أيام إجازته وليس إعطاء علاوة في قيمة الراتب.

											<br></br>
										</h4>

									</li>

								</ul> */}
								<h2 >ايام العيد للموظف الغير يهودي  </h2>
								<br></br>
								<ul className='privacy-ol'>
									<li>
										<h5>
										بناء على البند رقم  <span className='privacy'> [ 18 (أ) ] </span> لقانون تنظيم الحكم والقضاء سنة (1948) ,موظف غير يهودي من حقه
										 أن يختار كأيام استراحة ليوم العيد اما 
										اعياد دولة اسرائيل او اعياد ديانته , هذا الحق مقدم للموظف
										 كحق الغير قابل للعزل ولصاحب العمل لا توجد أي سيطرة عليه
										 بإجبار الموظف بالعمل بأيام عيده. لذا لا يمكن إجبار موظف
										 غير يهودي العمل بأيام عيد ديانته اذا اختار ان يعيد بها.

											<br></br>
										</h5>

									</li>
									<li>
										<h5>
											<span className='privacy'> والسؤال هو ما وضع ايام اعياد دولة إسرائيل التي يتغيب بها موظف غير اليهودي عن العمل عندما يختار أيام عيده كاستراحة حسب ديانته؟ </span>

											<br></br>
										</h5>
										<ul>
											<br></br>
											<li>تم البت في هذا السؤال في السابقة رقم (3 - 49 - 472) ( يتسحاق اهرونوف ضد عبد القادر وتد)</li>
											<li>محكمة العمل القطرية حكمت بهذا الموضوع ونظرت الى ايام العيد الإسلامية كأيام عيد, من حق الموظف حسب القانون,  وبالنسبة لأيام العيد الاسرائيلية تحدد انه لا يمكن ان ينظر اليها كأيام إجازة سنوية بالاخص ان هذه الاستراحة مفروضة على الموظف بدون أي علاقة للإجازة السنوية.</li>
											<li>يجب الانتباه أن المحكمة لم تبت في السؤال: هل يحق للموظف ان يتقاضى راتب بدل ايام العيد الاسرائيلية ؟ بناء على التفسيرات المقبولة صرح بأن الموظف الغير يهودي لا يحق له تقاضي بدل هذه الايام بحيث يصبح راتب الموظف مضاعف. اذا استلم راتب بدل ايام اعياده , وبدل أيام الأعياد الاسرائيلية, إذا اختار كأيام العيد أيام العيد حسب ديانته.</li>
											<li>بالرغم من ذلك هناك تفسيرات اخرى وحسبها موظف غير يهودي يمكنه أن يطلب من صاحب عمله بأن يستبدل أيام غيابه الاعياد الاسرائيلية على حساب ايام الاجازة السنوية.</li>
										</ul>

									</li>

								</ul>

								<h2 >هل يحق للموظف هدية من صاحب العمل في العيد؟   </h2>
								<br></br>
								<ul className='privacy-ol'>
									<li>
										<h5>
										قوانين العمل لا تلزم صاحب العمل بأن يعيد موظفيه بهدية, ولكن يجب على صاحب العمل بأن يفحص اذا تم إلزامه بعيدية لموظفيه من أتباعه اتفاقية جماعية أو أمر موسع .
لذا إذا الزم صاحب العمل باتفاقية جماعية أو أمر موسع فيجب عليه العمل حسب المطلوب في الاتفاقية أو الأمر. 


											<br></br>
										</h5>

									</li>
									<li>
										<h5>
											<span className='privacy'> اضافة الى ذلك يجب الفحص إذا تم تعييد الموظف في السابق! </span>

											<br></br>
										</h5>
										<ul>
											<br></br>
											<li>إذا كان الجواب بنعم يجب فحص إذا تم تعييد الموظف في السابق بحيث ان هذا التصرف من قبل صاحب العمل يمكن أن يخلق عادة الملزمة به أن يستمر ويعمل بها.</li>
											<li>اضافة الى ذلك يجب الانتباه أن هدية صاحب العمل للموظف بيوم العيد لا تحسب كهدية المقدمة من علاقة عمل المعفاة من ضريبة الدخل والملزمة كلها بالضريبة. لذا يجب إلزام الموظف بدخل عيني على الهدية ابتداء من الشيكل الأول.</li>
											
										</ul>

									</li>

									<li>
										<h5>
											<span className='privacy'> العادة  </span>

											<br></br>
										</h5>
										<ul>
											<br></br>
											<li>قوانين العمل تلزم صاحب العمل بدفع مستحقات المقدمة للموظف الناتجة عن عادة اتبعها في السابق  وغير مدونة في قوانين العمل.</li>
											<li> لذا إذا قدم صاحب العمل هدية لموظف في السنوات السابقة تصبح هذه الهدية مع الوقت كحق الناتج عن عادة المدونة في اتفاقية العمل الشخصية لكل موظف وموظف. </li>
											
										</ul>

									</li>

								</ul>



							</div>

						


						</div>



						{/* /Content End */}
					</div>

					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
