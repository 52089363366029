/**
 * App Header
 */
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MassBCalculator from '../../calculators/massb-calculator/MassBCalc';
import ReactTooltip from "react-tooltip";
import { useLogout } from '../../hooks/useLogout'
import {
  headerlogo, lnEnglish, lnFrench, lnSpanish, lnGerman,
  Avatar_02, Avatar_03, Avatar_05, Avatar_06, Avatar_08,
  Avatar_09, Avatar_13, Avatar_17, Avatar_21
} from '../../Entryfile/imagepath';


//  import {headerlogo} from '../../Entryfile/imagepath'
import { massportallogo } from '../../Entryfile/imagepath';
// import '../../assets/css/style.css';
import { MdLanguage, MdOutlineAccountCircle } from 'react-icons/md';
import { FaGraduationCap, FaUserCog, FaUserCircle, FaRegUserCircle } from 'react-icons/fa';
import { VscStarEmpty } from 'react-icons/vsc';
import { GiGraduateCap } from 'react-icons/gi';
import { RiLogoutBoxLine, RiUserStarLine } from 'react-icons/ri';
import { BsCalculator } from 'react-icons/bs';
import { AiOutlineAppstoreAdd, AiOutlineFile, AiFillCalculator } from "react-icons/ai";
// <MdLanguage size={23} />
import { useDocument } from '../../hooks/useDocument';
// import { otherStorage, otherFirestore } from '../../../../firebase/config';
import { projectFirestore, projectStorage } from '../../firebase/config';
import { element } from 'prop-types';

// ==================================================

const pathname = window.location.pathname;

export default function Header() {

  const { logout } = useLogout()
  const { user } = useAuthContext()
  const [companyName, setCompanyName] = useState(' ')
  //MODAL_Calc
  const [showCalc, setshowCalc] = useState(false)
  const handleCloseCalc = () => setshowCalc(false);
  const handleShowCalc = () => setshowCalc(true);
  // const { document, error } = useDocument('mp-clients', user.uid)

  //MODAL_User
  const [showUser, setshowUser] = useState(false)
  const handleCloseUser = () => setshowUser(false);
  const handleMore =() => window.location.href="/mass-manage-form"
  const handleShowUser = () => setshowUser(true);
  const [userDetails, setUserDetails] = useState("")
  useEffect(() => {
    if (document) {
      setUserDetails(document)
      // console.log("user is : ", userDetails)
    }
  }, [document])
  //  getCompanyName();
  // const {docc , errr} = useDocument('mp-clients', docId)
  useEffect(() => {
    const getCompanyName = () => {
      if (user != null) {

        const mydata = projectFirestore.collection('mp-clients').doc(user.uid).get().then((docRef) => { setCompanyName(docRef.data().companyName);setUserDetails(docRef.data()) })
          .catch((error) => { })
      }
      else
        setCompanyName(" ")
    }

    getCompanyName();
  })

  // console.log(document)
  // const {  location } = this.props
  // let pathname = location.pathname

  return (

    <div className="header" >
      {/* Logo */}
      <div className="header-left">
        <a href="/" className="logo">
          <img src={massportallogo} width={40} height={40} alt="" />
        </a>
      </div>
      {/* /Logo */}

      <a id="toggle_btn" href="" style={{ display: pathname.includes('tasks') ? "none" : pathname.includes('compose') ? "none" : "" }}>
        <span className="bar-icon"><span />
          <span />
          <span />
        </span>
      </a>
      {/* Header Title */}
      <div className="page-title-box">
        <h3>MassPortal | {user && (
          <>
            مرحبا <span className='userName'>{user.displayName}</span>

          </>
        )} </h3>
      </div>
      <div className="page-title-box">
        <h3> {user && (
          <>
            <span className='userName'> {companyName}</span>

          </>
        )} </h3>
      </div>
      {/* /Header Title */}
      <a id="mobile_btn" className="mobile_btn" href="#sidebar"><i className="fa fa-bars" /></a>

      {/* Header Menu */}
      <div className='row'>
        <div className='col-3'>
          <ul className="nav user-menu ">
            {/* Math Fintech */}

            <li className="nav-item  has-arrow flag-nav" data-tip data-for="x-tip">
              <a className="nav-link " href="/math-courses" role="button">
                <GiGraduateCap size={28} className='privacy' /> <span> دورات  </span>
              </a>
              <ReactTooltip id="x-tip" place="top" effect="solid">
                دورات Math Fintech
              </ReactTooltip>
            </li>

            {/* Math Fintech  */}


            {/* NEW */}
            {/* <li className="nav-item  has-arrow flag-nav" data-tip data-for="new-tip">
              <a className="nav-link " href="https://mass-billing.web.app/" role="button">
                <VscStarEmpty size={20} className='privacy' /> <span> MassBill</span>
              </a>
              <ReactTooltip id="new-tip" place="top" effect="solid">
                تعرف على - Mass Billing
              </ReactTooltip>

            </li> */}
          </ul>
        </div>
        <div className='col-9 admin-div'>
          <ul className="nav user-menu ">

            <li className="nav-item  has-arrow flag-nav" data-tip data-for="new-tip">
              <a className="nav-link " href="https://massbill.com/" target='_blank' role="button">
                <VscStarEmpty size={20} className='privacy' /> <span> MassBill</span>
              </a>
              <ReactTooltip id="new-tip" place="top" effect="solid">
                MassBill.com

              </ReactTooltip>

            </li>
            <li className="nav-item  has-arrow flag-nav" data-tip data-for="calc-tip">
              <a className="nav-link " href="#" role="button" onClick={handleShowCalc}>
                <BsCalculator size={28} className='privacy' />
              </a>
              <ReactTooltip id="calc-tip" place="top" effect="solid">
                MassP_Calculator

              </ReactTooltip>

            </li>
            <li className="nav-item  has-arrow flag-nav" data-tip data-for="ex-tip">
              <a className="nav-link " href="/mass-modules" role="button">
                <AiOutlineAppstoreAdd size={30} className='privacy' />
              </a>
              <ReactTooltip id="ex-tip" place="top" effect="solid">
                تطبيقات
              </ReactTooltip>
            </li>

            <li className="nav-item dropdown has-arrow main-drop li-admin ">
              <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                <span className="user-img"> <FaRegUserCircle size={30} className='privacy admin-icon' />
                </span>

                {/* <span className="user-img"><FaRegUserCircle size={30} className='privacy'/>
              {user && ( <span className="status online" /> )}</span> */}

                {/* <span> Admin </span> */}

              </a>
              <div className="dropdown-menu massp-menu">
                <a className="dropdown-item" href="#">
                  <MdOutlineAccountCircle size={20} className='privacy' />
                  <span> الحساب الشخصي </span>
                </a>
                <a className="dropdown-item" href="/mass-manage-form">
                  <FaUserCog size={20} className="privacy" />
                  <span> اعدادات </span>
                </a>
                <a className="dropdown-item " href="#" role="button" onClick={handleShowUser}>
                  <RiUserStarLine size={20} className='privacy' /> <span> Profile Info  </span>
                </a>
                <a className="dropdown-item" href="/mass-modules" >
                  <AiOutlineAppstoreAdd size={20} className='privacy' /> <span> Lobby </span>
                </a>

                <a className="dropdown-item " href="/math-courses">
                  <GiGraduateCap size={20} className='privacy' /> <span> دورات Math Fintech </span>
                </a>

                <a className=" dropdown-item " href="https://massbill.com/" target='_blank' role="button">
                  <VscStarEmpty size={20} className='privacy' /> <span> New </span>
                </a>
                <a className="dropdown-item " href="#" role="button" onClick={handleShowCalc}>
                  <BsCalculator size={20} className='privacy' /> <span> MassP_Calculator</span>
                </a>

                {user && (
                  <>
                    <a className="dropdown-item" href="/loginpage" onClick={logout}>
                      <RiLogoutBoxLine size={20} className='privacy' /><span> تسجيل الخروج</span></a>
                  </>
                )}
                {/* <a className="dropdown-item" href="/loginpage" onClick={logout}>تسجيل الخروج</a> */}
              </div>
            </li>

          </ul>
        </div>
      </div>



      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu ">
        <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" /></a>
        <div className="dropdown-menu dropdown-menu-right massp-menu">
          {/* <a className="dropdown-item" href="/rtl/app/profile/employee-profile">My Profile</a> */}
          {/* <a className="dropdown-item" href="/rtl/settings/companysetting">Settings</a> */}
          <a className="dropdown-item" href="#">
            <MdOutlineAccountCircle size={20} className='privacy' />
            <span> الحساب الشخصي </span>
          </a>
          <a className="dropdown-item" href="/mass-manage-form">
            <FaUserCog size={20} className="privacy" />
            <span> اعدادات </span>
          </a>
          <a className="dropdown-item " href="#" role="button" onClick={handleShowUser}>
            <RiUserStarLine size={20} className='privacy' /> <span> Profile Info </span>
          </a>
          <a className="dropdown-item" href="/mass-modules" >
            <AiOutlineAppstoreAdd size={20} className='privacy' /> <span> Lobby </span>
          </a>
          <a className="dropdown-item " href="#" role="button" onClick={handleShowCalc}>
            <BsCalculator size={20} className='privacy' /> <span> MassP_Calculator</span>
          </a>
          <a className="dropdown-item " href="https://massbill.com/" target='_blank' role="button">
            <VscStarEmpty size={20} className='privacy' /> <span> MassBill</span>
          </a>

          <a className="dropdown-item " href="/math-courses">
            <GiGraduateCap size={20} className='privacy' /> <span> دورات Math Fintech </span>
          </a>
          <a className="dropdown-item" href="/loginpage" onClick={logout}>
            <RiLogoutBoxLine size={20} className='privacy' /><span> تسجيل الخروج</span></a>


        </div>
      </div>
      {/* /Mobile Menu */}

      {/* Calculator _ MODAL */}
      <Modal id="wrapper" show={showCalc} onHide={handleCloseCalc} className="calcM" dialogClassName="modal-calc">

        <Modal.Body id="appp" className='modal-calc ' dir='ltr'>
          <MassBCalculator />
          {/* <ReactCalculator className="calc-react" /> */}
        </Modal.Body>

      </Modal>
      {/* User_Details _ MODAL */}
	
      <Modal id="" show={showUser} onHide={handleCloseUser} dialogClassName="modal-calc user-details" >
        <Modal.Header  >
          <h1 className=' privacy' style={{width:"100%"}} > 
          <span>Profile Info </span>
          <span style={{float:"left"}}> <RiUserStarLine size={30} className='privacy' />  </span>
           </h1>
        </Modal.Header>
        <Modal.Body id="appp" className='modal-calc card-body  privacy'  >
         <br />
          {userDetails &&
            <div className='row  text-left'  >
                <p className='col-sm-12 col-md-6'>اسم الشركة  :  <span> {userDetails.companyName} </span> </p>
                <p className='col-sm-12 col-md-6'>اسم المدير  : <span> {userDetails.userName}</span> </p>
                <br />
                <p className='col-sm-12 col-md-6'>البريد الالكتروني  : <span> {userDetails.email}</span> </p>
                <p className='col-sm-12 col-md-6'>العنوان   : <span> {userDetails.address}</span> </p>
                <br />
                <p className='col-sm-12 col-md-6'>هاتف - جوال  : <span> {userDetails.phoneNumber}</span> </p>
                <p className='col-sm-12 col-md-6'>هاتف - أرضي   : <span> {userDetails.officeNumber}</span> </p>
            
            </div>}
  <br /> 
          {/* <ReactCalculator className="calc-react" /> */}
        </Modal.Body>
<Modal.Footer className='text-center' >
  
<Button variant="secondary" onClick={handleCloseUser} className='col-sm-12 col-md-4' style={{marginTop:"20px", marginBottom:"20px"}} >


                    إغلاق
                  </Button>
                  <Button className='col-sm-12 col-md-4' variant="primary" onClick={handleMore}  style={{marginTop:"20px", marginBottom:"20px"}}>
                    المزيد 
                  </Button>
                  <br />
</Modal.Footer>
      </Modal>

    </div>

  );
}


