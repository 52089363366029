/**
 * Basic Table
 */
import React, { Component, Fragment } from 'react';
import { Helmet } from "react-helmet";

class Itt20 extends Component {

// const computTax = 5;
	render() {
	const computeTax = ()=>{
		console.log("Computing tax Function is here");
	}
computeTax();

		return (
			<div className="page-wrapper ">
			<Helmet className='tableHead'>
				  <title>شرائح ضريبة الدخل </title>
				  <meta name="description" content="Login page"/>					
			</Helmet>
			<div className="content container-fluid">
			  {/* Page Header */}
			  <div className="page-header">
				<div className="row">
				  <div className="col">
					<h3 className="page-title"></h3>
					<ul className="breadcrumb">
					  <li className="breadcrumb-item"><a href="/home">الرئيسية</a></li>
					  <li className="breadcrumb-item active">Itt-2020</li>
					</ul>
				  </div>
				</div>
			  </div>
			  {/* /Page Header */}
			  {/* <div className="row">
				<div className="col-lg-6">
				  <div className="card">
					<div className="card-header">
					  <h4 className="card-title mb-0">Basic Table</h4>
					</div>
					<div className="card-body">
					  <div className="table-responsive">
						<table className="table mb-0">
						  <thead>
							<tr>
							  <th>First Name</th>
							  <th>Last Name</th>
							  <th>Email</th>
							</tr>
						  </thead>
						  <tbody>
							<tr>
							  <td>John</td>
							  <td>Doe</td>
							  <td>john@example.com</td>
							</tr>
							<tr>
							  <td>Mary</td>
							  <td>Moe</td>
							  <td>mary@example.com</td>
							</tr>
							<tr>
							  <td>July</td>
							  <td>Dooley</td>
							  <td>july@example.com</td>
							</tr>
						  </tbody>
						</table>
					  </div>
					</div>
				  </div>
				</div>
				<div className="col-lg-6">
				  <div className="card">
					<div className="card-header">
					  <h4 className="card-title mb-0">Striped Rows</h4>
					</div>
					<div className="card-body">
					  <div className="table-responsive">
						<table className="table table-striped mb-0">
						  <thead>
							<tr>
							  <th>First Name</th>
							  <th>Last Name</th>
							  <th>Email</th>
							</tr>
						  </thead>
						  <tbody>
							<tr>
							  <td>John</td>
							  <td>Doe</td>
							  <td>john@example.com</td>
							</tr>
							<tr>
							  <td>Mary</td>
							  <td>Moe</td>
							  <td>mary@example.com</td>
							</tr>
							<tr>
							  <td>July</td>
							  <td>Dooley</td>
							  <td>july@example.com</td>
							</tr>
						  </tbody>
						</table>
					  </div>
					</div>
				  </div>
				</div>
			  </div> */}
			  {/* <div className="row">
				<div className="col-lg-6">
				  <div className="card">
					<div className="card-header">
					  <h4 className="card-title mb-0">Bordered Table</h4>
					</div>
					<div className="card-body">
					  <div className="table-responsive">
						<table className="table table-bordered mb-0">
						  <thead>
							<tr>
							  <th>First Name</th>
							  <th>Last Name</th>
							  <th>Email</th>
							</tr>
						  </thead>
						  <tbody>
							<tr>
							  <td>John</td>
							  <td>Doe</td>
							  <td>john@example.com</td>
							</tr>
							<tr>
							  <td>Mary</td>
							  <td>Moe</td>
							  <td>mary@example.com</td>
							</tr>
							<tr>
							  <td>July</td>
							  <td>Dooley</td>
							  <td>july@example.com</td>
							</tr>
						  </tbody>
						</table>
					  </div>
					</div>
				  </div>
				</div>
				<div className="col-lg-6">
				  <div className="card">
					<div className="card-header">
					  <h4 className="card-title mb-0">Hover Rows</h4>
					</div>
					<div className="card-body">
					  <div className="table-responsive">
						<table className="table table-hover mb-0">
						  <thead>
							<tr>
							  <th>First Name</th>
							  <th>Last Name</th>
							  <th>Email</th>
							</tr>
						  </thead>
						  <tbody>
							<tr>
							  <td>John</td>
							  <td>Doe</td>
							  <td>john@example.com</td>
							</tr>
							<tr>
							  <td>Mary</td>
							  <td>Moe</td>
							  <td>mary@example.com</td>
							</tr>
							<tr>
							  <td>July</td>
							  <td>Dooley</td>
							  <td>july@example.com</td>
							</tr>
						  </tbody>
						</table>
					  </div>
					</div>
				  </div>
				</div>
			  </div> */}
			  <div className="row">
				{/* <div className="col-lg-6">
				  <div className="card">
					<div className="card-header">
					  <h4 className="card-title mb-0">Contextual Classes</h4>
					</div>
					<div className="card-body">
					  <div className="table-responsive">
						<table className="table mb-0">
						  <thead>
							<tr>
							  <th>First Name</th>
							  <th>Last Name</th>
							  <th>Email</th>
							</tr>
						  </thead>
						  <tbody>
							<tr>
							  <td>Default</td>
							  <td>Defaultson</td>
							  <td>def@somemail.com</td>
							</tr>
							<tr className="table-primary">
							  <td>Primary</td>
							  <td>Doe</td>
							  <td>john@example.com</td>
							</tr>
							<tr className="table-secondary">
							  <td>Secondary</td>
							  <td>Moe</td>
							  <td>mary@example.com</td>
							</tr>
							<tr className="table-success">
							  <td>Success</td>
							  <td>Dooley</td>
							  <td>july@example.com</td>
							</tr>
							<tr className="table-danger">
							  <td>Danger</td>
							  <td>Refs</td>
							  <td>bo@example.com</td>
							</tr>
							<tr className="table-warning">
							  <td>Warning</td>
							  <td>Activeson</td>
							  <td>act@example.com</td>
							</tr>
							<tr className="table-info">
							  <td>Info</td>
							  <td>Activeson</td>
							  <td>act@example.com</td>
							</tr>
							<tr className="table-light">
							  <td>Light</td>
							  <td>Activeson</td>
							  <td>act@example.com</td>
							</tr>
							<tr className="table-dark">
							  <td>Dark</td>
							  <td>Activeson</td>
							  <td>act@example.com</td>
							</tr>
						  </tbody>
						</table>
					  </div>
					</div>
				  </div>
				</div> */}
				<div className="col-lg-12">
				  <div className="card">
					<div className="card-header tableCard">
					  <h4 className="card-title mb-0">شرائح ضريبة الدخل لعام 2020</h4>
					</div>
					<div className="card-body">
					  <div className="table-responsive">
						<table className="table table-nowrap mb-0">
						  <thead>
							<tr>
							  <th>الراتب ( شيكل جديد )</th>
							  <th>الراتب التراكمي</th>
							  <th>معدل الضريبة</th>
							  <th>الضريبة </th>
							  <th>الضريبة التراكمية</th>
							  {/* <th>Country</th> */}
							</tr>
						  </thead>
						  <tbody>
							<tr>
							  <td>من 6.290</td>
							  <td>6.290</td>
							  <td>10.0%</td>
							  <td>629</td>
							  <td>629</td>
							</tr>
							<tr>
							  <td>من 2.740</td>
							  <td>9.030</td>
							  <td>14.0%</td>
							  <td>384</td>
							  <td>1.013</td>
							</tr>
							<tr>
							  <td>من 5.460</td>
							  <td>14.490 </td>
							  <td>20.0%</td>
							  <td>1.092</td>
							  <td>2.105</td>
							</tr>
							<tr>
							  <td>من 5.650</td>
							  <td>20.140</td>
							  <td>31.0%</td>
							  <td>1.752</td>
							  <td>3.856</td>
							</tr>
							<tr>
							  <td>من 21.770</td>
							  <td>41.910</td>
							  <td>35.0%</td>
							  <td>7.620</td>
							  <td>11.476</td>
							</tr>
							<tr>
							  <td>12.060 من </td>
							  <td>53.970</td>
							  <td>47.0%</td>
							  <td>5.668</td>
							  <td>17.144</td>
							</tr>
							{/* <tr>
							  <td>1</td>
							  <td>Anna</td>
							  <td>Pitt</td>
							  <td>35</td>
							  <td>New York</td>
							</tr>
							<tr>
							  <td>1</td>
							  <td>Anna</td>
							  <td>Pitt</td>
							  <td>35</td>
							  <td>New York</td>
							</tr>
							<tr>
							  <td>1</td>
							  <td>Anna</td>
							  <td>Pitt</td>
							  <td>35</td>
							  <td>New York</td>
							</tr> */}
						  </tbody>
						</table>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			</div>			
		  </div>
		);
	}
}

export default Itt20;
