/**
 * TermsCondition Page
 */
import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import {Avatar_02,Avatar_05,Avatar_09,Avatar_10,Avatar_16 } from '../Entryfile/imagepath'
const EmployeeProfile = () => {
  useEffect( ()=>{
    if($('.select').length > 0) {
      $('.select').select2({
        minimumResultsForSearch: -1,
        width: '100%'
      });
    }
  });  
  
    return (
        <div className="page-wrapper">
            <Helmet>
              <title>MassBill - Employee Profile</title>
              <meta name="description" content="Reactify Blank Page" />
            </Helmet>
            {/* Page Content */}
            <div className="content container-fluid" >
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <h3 className="page-title">بروفايل</h3>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><Link to = "/employees">موظفين</Link></li>
                      <li className="breadcrumb-item active">بروفايل</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="card mb-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-view">
                        <div className="profile-img-wrap">
                          <div className="profile-img">
                            <a href="#"><img alt="" src={Avatar_02} /></a>
                          </div>
                        </div>
                        <div className="profile-basic">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="profile-info-left">
                                <h3 className="user-name m-t-0 mb-0">محمد حسن</h3>
                                <h6 className="text-muted">فريق تطوير الويب</h6>
                                <small className="text-muted">مطور ويب</small>
                                <div className="staff-id">معرّف الموظف:  FT-0001 </div>
                                <div className="small doj text-muted">تاريخ الانضمام: 10-12-2023</div>
                                <div className="staff-msg"><Link onClick={()=>localStorage.setItem("minheight","true")} className="btn btn-custom" to="/conversation/chat">ارسال رسالة</Link></div>
                              </div>
                            </div>
                            <div className="col-md-7">
                              <ul className="personal-info">
                                <li>
                                  <div className="title">هاتف: </div>
                                  <div className="text"><a href="">9876543210</a></div>
                                </li>
                                <li>
                                  <div className="title">أيميل: </div>
                                  <div className="text"><a href="">johndoe@example.com</a></div>
                                </li>
                                <li>
                                  <div className="title">تاريخ الميلاد</div>
                                  <div className="text">24th July</div>
                                </li>
                                <li>
                                  <div className="title">العنوان</div>
                                  <div className="text">القدس، القلعة 3</div>
                                </li>
                                <li>
                                  <div className="title">النوع: </div>
                                  <div className="text">ذكر</div>
                                </li>
                                <li>
                                  <div className="title">يقدم تقارير ل: </div>
                                  <div className="text">
                                    <div className="avatar-box">
                                      <div className="avatar avatar-xs">
                                        <img src={Avatar_16} alt="" />
                                      </div>
                                    </div>
                                    <Link to = "/app/profile/employee-profile">
                                      حسن مصاروة
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="pro-edit"><a data-target="#profile_info" data-toggle="modal" className="edit-icon" href="#"><i className="fa fa-pencil" /></a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card tab-box">
                <div className="row user-tabs">
                  <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item"><a href="#emp_profile" data-toggle="tab" className="nav-link active">بروفايل</a></li>
                      <li className="nav-item"><a href="#emp_projects" data-toggle="tab" className="nav-link">مشاريع</a></li>
                      <li className="nav-item"><a href="#bank_statutory" data-toggle="tab" className="nav-link">البنك  &amp; القانونيات <small className="text-danger">(للإدارة فقط)</small></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                {/* Profile Info Tab */}
                <div id="emp_profile" className="pro-overview tab-pane fade show active">
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3 className="card-title">المعلومات الشخصية <a href="#" className="edit-icon" data-toggle="modal" data-target="#personal_info_modal"><i className="fa fa-pencil" /></a></h3>
                          <ul className="personal-info">
                            <li>
                              <div className="title">رقم جواز السفر: </div>
                              <div className="text">9876543210</div>
                            </li>
                            <li>
                              <div className="title">تاريخ انتهاء صلاحية جواز السفر:</div>
                              <div className="text">9876543210</div>
                            </li>
                            <li>
                              <div className="title">هاتف</div>
                              <div className="text"><a href="">9876543210</a></div>
                            </li>
                            <li>
                              <div className="title">الجنسية: </div>
                              <div className="text">فلسطيني</div>
                            </li>
                            <li>
                              <div className="title">الديانة:  </div>
                              <div className="text">مسلم</div>
                            </li>
                            <li>
                              <div className="title">الحالة الاجتماعية:</div>
                              <div className="text">متزوج</div>
                            </li>
                            <li>
                              <div className="title">وظيفة الزوج/الزوجة: </div>
                              <div className="text">لا</div>
                            </li>
                            <li>
                              <div className="title">عدد الأطفال</div>
                              <div className="text">2</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3 className="card-title">جهة الاتصال في حالات الطوارئ <a href="#" className="edit-icon" data-toggle="modal" data-target="#emergency_contact_modal"><i className="fa fa-pencil" /></a></h3>
                          <h5 className="section-title">أساسي</h5>
                          <ul className="personal-info ">
                            <li>
                              <div className="title">الاسم</div>
                              <div className="text">حسن علي</div>
                            </li>
                            <li>
                              <div className="title">صلة القرابة</div>
                              <div className="text">أب</div>
                            </li>
                            <li>
                              <div className="title">هاتف </div>
                              <div className="text">9876543210, 9876543210</div>
                            </li>
                          </ul>
                          <hr />
                          <h5 className="section-title">ثانوي</h5>
                          <ul className="personal-info">
                            <li>
                              <div className="title">الاسم</div>
                              <div className="text">أحمد حسن</div>
                            </li>
                            <li>
                              <div className="title">صلة القرابة</div>
                              <div className="text">أخ</div>
                            </li>
                            <li>
                              <div className="title">هاتف </div>
                              <div className="text">9876543210, 9876543210</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3 className="card-title">معلومات البنك </h3>
                          <ul className="personal-info">
                            <li>
                              <div className="title">اسم البنك</div>
                              <div className="text"> بنك ICICI</div>
                            </li>
                            <li>
                              <div className="title">رقم حساب البنك </div>
                              <div className="text">159843014641</div>
                            </li>
                            <li>
                              <div className="title"> كود IFSC</div>
                              <div className="text">ICI24504</div>
                            </li>
                            <li>
                              <div className="title"> رقم ال PAN</div>
                              <div className="text">TC000Y56</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3 className="card-title">معلومات العائلة <a href="#" className="edit-icon" data-toggle="modal" data-target="#family_info_modal"><i className="fa fa-pencil" /></a></h3>
                          <div className="table-responsive">
                            <table className="table table-nowrap">
                              <thead>
                                <tr>
                                  <th>الاسم </th>
                                  <th>صلة القرابة </th>
                                  <th>تاريخ الميلاد</th>
                                  <th>هاتف</th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>أحمد</td>
                                  <td>أخ</td>
                                  <td>Feb 16th, 2019</td>
                                  <td>9876543210</td>
                                  <td className="text-end">
                                    <div className="dropdown dropdown-action">
                                      <a aria-expanded="false" data-toggle="dropdown" className="action-icon dropdown-toggle" href="#"><i className="material-icons">more_vert</i></a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <a href="#" className="dropdown-item"><i className="fa fa-pencil m-r-5" /> تعديل</a>
                                        <a href="#" className="dropdown-item"><i className="fa fa-trash-o m-r-5" /> حذف</a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3 className="card-title">معلومات التعليم <a href="#" className="edit-icon" data-toggle="modal" data-target="#education_info"><i className="fa fa-pencil" /></a></h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="experience-user">
                                  <div className="before-circle" />
                                </div>
                                <div className="experience-content">
                                  <div className="timeline-content">
                                    <a href="/" className="name">الجامعة الدولية للفنون والعلوم </a>
                                    <div>بكالوريوس علوم الحاسوب</div>
                                    <span className="time">2000 - 2003</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="experience-user">
                                  <div className="before-circle" />
                                </div>
                                <div className="experience-content">
                                  <div className="timeline-content">
                                    <a href="/" className="name">الجامعة الدولية للفنون والعلوم</a>
                                    <div>ماجستير علوم الحاسوب</div>
                                    <span className="time">2015 - 2020</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3 className="card-title">خبرات <a href="#" className="edit-icon" data-toggle="modal" data-target="#experience_info"><i className="fa fa-pencil" /></a></h3>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="experience-user">
                                  <div className="before-circle" />
                                </div>
                                <div className="experience-content">
                                  <div className="timeline-content">
                                    <a href="/" className="name">مصمم ويب في شركة MassDev</a>
                                    <span className="time">Jan 2013 -  (5 years 2 months)</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="experience-user">
                                  <div className="before-circle" />
                                </div>
                                <div className="experience-content">
                                  <div className="timeline-content">
                                    <a href="/" className="name">مصمم ويب في شركة MassBill</a>
                                    <span className="time">Jan 2013 -  (5 years 2 months)</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="experience-user">
                                  <div className="before-circle" />
                                </div>
                                <div className="experience-content">
                                  <div className="timeline-content">
                                    <a href="/" className="name">مصمم ويب في شركة Levant</a>
                                    <span className="time">Jan 2013 - Present (5 years 2 months)</span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Profile Info Tab */}
                {/* Projects Tab */}
                <div className="tab-pane fade" id="emp_projects">
                  <div className="row">
                    <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="dropdown profile-action">
                            <a aria-expanded="false" data-toggle="dropdown" className="action-icon dropdown-toggle" href="#"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a data-target="#edit_project" data-toggle="modal" href="#" className="dropdown-item"><i className="fa fa-pencil m-r-5" /> تعديل </a>
                              <a data-target="#delete_project" data-toggle="modal" href="#" className="dropdown-item"><i className="fa fa-trash-o m-r-5" /> حذف</a>
                            </div>
                          </div>
                          <h4 className="project-title"><Link to = "/app/projects/projects-view">أدارة المكتب</Link></h4>
                          <small className="block text-ellipsis m-b-15">
                            <span className="text-xs">1</span> <span className="text-muted"> مهمات قيد التنفيذ </span>
                            <span className="text-xs">9</span> <span className="text-muted">مهمات مكتملة</span>
                          </small>
                          <p className="text-muted">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. When an unknown printer took a galley of type and
                            scrambled it...
                          </p>
                          <div className="pro-deadline m-b-15">
                            <div className="sub-title">
                              الموعد النهائي
                            </div>
                            <div className="text-muted">
                              17 Apr 2019
                            </div>
                          </div>
                          <div className="project-members m-b-15">
                            <div>مدير المشروع :</div>
                            <ul className="team-members">
                              <li>
                                <a href="#" data-toggle="tooltip" title="Jeffery Lalor"><img alt="" src={Avatar_16} /></a>
                              </li>
                            </ul>
                          </div>
                          <div className="project-members m-b-15">
                            <div>الفريق :</div>
                            <ul className="team-members">
                              <li>
                                <a href="#" data-toggle="tooltip" title="John Doe"><img alt="" src={Avatar_02} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="Richard Miles"><img alt="" src={Avatar_09} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="John Smith"><img alt="" src={Avatar_10} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="Mike Litorus"><img alt="" src={Avatar_05} /></a>
                              </li>
                              <li>
                                <a href="#" className="all-users">+15</a>
                              </li>
                            </ul>
                          </div>
                          <p className="m-b-5">التقدم <span className="text-success float-end">40%</span></p>
                          <div className="progress progress-xs mb-0">
                            <div style={{width: '40%'}} data-toggle="tooltip" role="progressbar" className="progress-bar bg-success" data-original-title="40%" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="dropdown profile-action">
                            <a aria-expanded="false" data-toggle="dropdown" className="action-icon dropdown-toggle" href="#"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a data-target="#edit_project" data-toggle="modal" href="#" className="dropdown-item"><i className="fa fa-pencil m-r-5" /> تعديل</a>
                              <a data-target="#delete_project" data-toggle="modal" href="#" className="dropdown-item"><i className="fa fa-trash-o m-r-5" /> حذف</a>
                            </div>
                          </div>
                          <h4 className="project-title"><Link to = "/app/projects/projects-view">ادارة المشروع</Link></h4>
                          <small className="block text-ellipsis m-b-15">
                            <span className="text-xs">2</span> <span className="text-muted">مهمات قيد التنفيذ: </span>
                            <span className="text-xs">5</span> <span className="text-muted">مهمات مكتملة</span>
                          </small>
                          <p className="text-muted">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. When an unknown printer took a galley of type and
                            scrambled it...
                          </p>
                          <div className="pro-deadline m-b-15">
                            <div className="sub-title">
                              الموعد النهائي
                            </div>
                            <div className="text-muted">
                              17 Apr 2019
                            </div>
                          </div>
                          <div className="project-members m-b-15">
                            <div>مدير المشروع: </div>
                            <ul className="team-members">
                              <li>
                                <a href="#" data-toggle="tooltip" title="Jeffery Lalor"><img alt="" src={Avatar_16} /></a>
                              </li>
                            </ul>
                          </div>
                          <div className="project-members m-b-15">
                            <div>الفريق: </div>
                            <ul className="team-members">
                              <li>
                                <a href="#" data-toggle="tooltip" title="John Doe"><img alt="" src={Avatar_02} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="Richard Miles"><img alt="" src={Avatar_09} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="John Smith"><img alt="" src={Avatar_10} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="Mike Litorus"><img alt="" src={Avatar_05} /></a>
                              </li>
                              <li>
                                <a href="#" className="all-users">+15</a>
                              </li>
                            </ul>
                          </div>
                          <p className="m-b-5">التقدم <span className="text-success float-end">40%</span></p>
                          <div className="progress progress-xs mb-0">
                            <div style={{width: '40%'}} data-toggle="tooltip" role="progressbar" className="progress-bar bg-success" data-original-title="40%" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="dropdown profile-action">
                            <a aria-expanded="false" data-toggle="dropdown" className="action-icon dropdown-toggle" href="#"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a data-target="#edit_project" data-toggle="modal" href="#" className="dropdown-item"><i className="fa fa-pencil m-r-5" /> Edit</a>
                              <a data-target="#delete_project" data-toggle="modal" href="#" className="dropdown-item"><i className="fa fa-trash-o m-r-5" /> Delete</a>
                            </div>
                          </div>
                          <h4 className="project-title"><Link to = "/app/projects/projects-view">تطبيق مكالمات فيديو</Link></h4>
                          <small className="block text-ellipsis m-b-15">
                            <span className="text-xs">3</span> <span className="text-muted">مهمات قيد التنفيذ: </span>
                            <span className="text-xs">3</span> <span className="text-muted">مهمات مكتملة</span>
                          </small>
                          <p className="text-muted">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. When an unknown printer took a galley of type and
                            scrambled it...
                          </p>
                          <div className="pro-deadline m-b-15">
                            <div className="sub-title">
                              الموعد النهائي
                            </div>
                            <div className="text-muted">
                              17 Apr 2019
                            </div>
                          </div>
                          <div className="project-members m-b-15">
                            <div>مدير المشروع: </div>
                            <ul className="team-members">
                              <li>
                                <a href="#" data-toggle="tooltip" title="Jeffery Lalor"><img alt="" src={Avatar_16} /></a>
                              </li>
                            </ul>
                          </div>
                          <div className="project-members m-b-15">
                            <div>الفريق: </div>
                            <ul className="team-members">
                              <li>
                                <a href="#" data-toggle="tooltip" title="John Doe"><img alt="" src={Avatar_02} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="Richard Miles"><img alt="" src={Avatar_09} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="John Smith"><img alt="" src={Avatar_10} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="Mike Litorus"><img alt="" src={Avatar_05} /></a>
                              </li>
                              <li>
                                <a href="#" className="all-users">+15</a>
                              </li>
                            </ul>
                          </div>
                          <p className="m-b-5">التقدم <span className="text-success float-end">40%</span></p>
                          <div className="progress progress-xs mb-0">
                            <div style={{width: '40%'}} data-toggle="tooltip" role="progressbar" className="progress-bar bg-success" data-original-title="40%" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="dropdown profile-action">
                            <a aria-expanded="false" data-toggle="dropdown" className="action-icon dropdown-toggle" href="#"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a data-target="#edit_project" data-toggle="modal" href="#" className="dropdown-item"><i className="fa fa-pencil m-r-5" /> Edit</a>
                              <a data-target="#delete_project" data-toggle="modal" href="#" className="dropdown-item"><i className="fa fa-trash-o m-r-5" /> Delete</a>
                            </div>
                          </div>
                          <h4 className="project-title"><Link to = "/app/projects/projects-view">ادارة مستشفى</Link></h4>
                          <small className="block text-ellipsis m-b-15">
                            <span className="text-xs">12</span> <span className="text-muted">مهمات قيد التنفيذ: </span>
                            <span className="text-xs">4</span> <span className="text-muted">مهمات مكتملة</span>
                          </small>
                          <p className="text-muted">Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. When an unknown printer took a galley of type and
                            scrambled it...
                          </p>
                          <div className="pro-deadline m-b-15">
                            <div className="sub-title">
                              الموعد النهائي
                            </div>
                            <div className="text-muted">
                              17 Apr 2019
                            </div>
                          </div>
                          <div className="project-members m-b-15">
                            <div>مدير المشروع: </div>
                            <ul className="team-members">
                              <li>
                                <a href="#" data-toggle="tooltip" title="Jeffery Lalor"><img alt="" src={Avatar_16} /></a>
                              </li>
                            </ul>
                          </div>
                          <div className="project-members m-b-15">
                            <div>الفريق: </div>
                            <ul className="team-members">
                              <li>
                                <a href="#" data-toggle="tooltip" title="John Doe"><img alt="" src={Avatar_02} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="Richard Miles"><img alt="" src={Avatar_09} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="John Smith"><img alt="" src={Avatar_10} /></a>
                              </li>
                              <li>
                                <a href="#" data-toggle="tooltip" title="Mike Litorus"><img alt="" src={Avatar_05} /></a>
                              </li>
                              <li>
                                <a href="#" className="all-users">+15</a>
                              </li>
                            </ul>
                          </div>
                          <p className="m-b-5">التقدم <span className="text-success float-end">40%</span></p>
                          <div className="progress progress-xs mb-0">
                            <div style={{width: '40%'}} data-toggle="tooltip" role="progressbar" className="progress-bar bg-success" data-original-title="40%" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Projects Tab */}
                {/* Bank Statutory Tab */}
                <div className="tab-pane fade" id="bank_statutory">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title"> معلومات الراتب الأساسية</h3>
                      <form>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">أساس الراتب <span className="text-danger">*</span></label>
                              <select className="select">
                                <option>اختر أساس الراتب</option>
                                <option>ساعيً</option>
                                <option>يومي</option>
                                <option>أسبوعي</option>
                                <option>شهري</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">كمية الراتب <small className="text-muted">شهريا</small></label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">₪</span>
                                </div>
                                <input type="text" className="form-control" placeholder="Type your salary amount" defaultValue={0.00} />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">كيفية الدفع</label>
                              <select className="select">
                                <option>اختر كيفية الدفع</option>
                                <option>حويل بنكي</option>
                                <option>شيك</option>
                                <option>نقدي</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h3 className="card-title"> معلومات صندوق التقاعد</h3>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">المساهمة في صندوق التقاعد</label>
                              <select className="select">
                                {/* <option>Select PF contribution</option> */}
                                <option>نعم</option>
                                <option>لا</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">رقم صندوق التقاعد <span className="text-danger">*</span></label>
                              <select className="select">
                                {/* <option>Select PF contribution</option> */}
                                <option>نعم</option>
                                <option>لا</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">معدل مساهمة الموظف في صندوق التقاعد</label>
                              <select className="select">
                                {/* <option>Select PF contribution</option> */}
                                <option>نعم</option>
                                <option>لا</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">معدل إضافي <span className="text-danger">*</span></label>
                              <select className="select">
                                <option>-</option>
                                <option>0%</option>
                                <option>1%</option>
                                <option>2%</option>
                                <option>3%</option>
                                <option>4%</option>
                                <option>5%</option>
                                <option>6%</option>
                                <option>7%</option>
                                <option>8%</option>
                                <option>9%</option>
                                <option>10%</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">المعدل الإجمالي</label>
                              <input type="text" className="form-control" placeholder="N/A" defaultValue="11%" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">Employee PF rate</label>
                              <select className="select">
                                <option>Select PF contribution</option>
                                <option>Yes</option>
                                <option>No</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">Additional rate <span className="text-danger">*</span></label>
                              <select className="select">
                                <option>Select additional rate</option>
                                <option>0%</option>
                                <option>1%</option>
                                <option>2%</option>
                                <option>3%</option>
                                <option>4%</option>
                                <option>5%</option>
                                <option>6%</option>
                                <option>7%</option>
                                <option>8%</option>
                                <option>9%</option>
                                <option>10%</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">Total rate</label>
                              <input type="text" className="form-control" placeholder="N/A" defaultValue="11%" />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <h3 className="card-title"> معلومات التأمينات الاجتماعية للموظفين</h3>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">المساهمة في التأمينات الاجتماعية للموظفين</label>
                              <select className="select">
                                {/* <option>Select ESI contribution</option> */}
                                <option>نعم</option>
                                <option>لا</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">رقم التأمين الاجتماعي للموظف <span className="text-danger">*</span></label>
                              <select className="select">
                                {/* <option>Select ESI contribution</option> */}
                                <option>نعم</option>
                                <option>لا</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">معدل ( ESI ) للموظف</label>
                              <select className="select">
                                {/* <option>Select ESI contribution</option> */}
                                <option>نعم</option>
                                <option>لا</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">معدل اضافي <span className="text-danger">*</span></label>
                              <select className="select">
                                {/* <option>Select additional rate</option> */}
                                <option>0%</option>
                                <option>1%</option>
                                <option>2%</option>
                                <option>3%</option>
                                <option>4%</option>
                                <option>5%</option>
                                <option>6%</option>
                                <option>7%</option>
                                <option>8%</option>
                                <option>9%</option>
                                <option>10%</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="col-form-label">اجمالي</label>
                              <input type="text" className="form-control" placeholder="N/A" defaultValue="11%" />
                            </div>
                          </div>
                        </div>
                        <div className="submit-section">
                          <button className="btn btn-primary submit-btn" type="submit">حفظ</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* /Bank Statutory Tab */}
              </div>
            </div>
            {/* /Page Content */}
            {/* Profile Modal */}
            <div id="profile_info" className="modal custom-modal fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">معلومات البروفايل </h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-img-wrap edit-img">
                            <img className="inline-block" src={Avatar_02} alt="user" />
                            <div className="fileupload btn">
                              <span className="btn-text">تعديل</span>
                              <input className="upload" type="file" />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>الاسم الشخصي </label>
                                <input type="text" className="form-control" defaultValue="John" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>اسم العائلة</label>
                                <input type="text" className="form-control" defaultValue="Doe" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>تاريخ الميلاد</label>
                                <div>
                                  <input className="form-control datetimepicker" type="date" defaultValue="05/06/1985" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>النوع</label>
                                <select className="select form-control">
                                  <option value="male selected">ذكر</option>
                                  <option value="female">أنثى</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>العنوان</label>
                            <input type="text" className="form-control" defaultValue="4487 Snowbird Lane" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>المحافظة</label>
                            <input type="text" className="form-control" defaultValue="New York" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>المدينة</label>
                            <input type="text" className="form-control" defaultValue="United States" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Pin Code</label>
                            <input type="text" className="form-control" defaultValue={10523} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>رقم الهاتف</label>
                            <input type="text" className="form-control" defaultValue="631-889-3206" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>القسم <span className="text-danger">*</span></label>
                            <select className="select">
                              <option>اختر القسم</option>
                              <option>Web Development</option>
                              <option>IT Management</option>
                              <option>Marketing</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>الوظيفة <span className="text-danger">*</span></label>
                            <select className="select">
                              <option>اختر الوظيفة</option>
                              <option>Web Designer</option>
                              <option>Web Developer</option>
                              <option>Android Developer</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>يقدم التقارير ل <span className="text-danger">*</span></label>
                            <select className="select">
                              <option>-</option>
                              <option>Wilmer Deluna</option>
                              <option>Lesley Grauer</option>
                              <option>Jeffery Lalor</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">حفظ</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Profile Modal */}
            {/* Personal Info Modal */}
            <div id="personal_info_modal" className="modal custom-modal fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">المعلومات الشخصية</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>رقم جواز السفر</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>تاريخ انتهاء جواز السفر </label>
                            <div>
                              <input className="form-control datetimepicker" type="date" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>هاتف</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>الجنسية <span className="text-danger">*</span></label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>الدايانة</label>
                            <div>
                              <input className="form-control" type="date" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>الحالة الاجتماعية <span className="text-danger">*</span></label>
                            <select className="select form-control">
                              <option>-</option>
                              <option>أعزب \ عزباء</option>
                              <option>متزوج \ ة</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>وظيفة الزوج \ الزوجة</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>عدد الأطفال </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">حفظ</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Personal Info Modal */}
            {/* Family Info Modal */}
            <div id="family_info_modal" className="modal custom-modal fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title"> معلومات العائلة</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-scroll">
                        <div className="card">
                          <div className="card-body">
                            <h3 className="card-title">معلومات العائلة <a href="" className="delete-icon"><i className="fa fa-trash-o" /></a></h3>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>الاسم <span className="text-danger">*</span></label>
                                  <input className="form-control" type="text" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>صلة القرابة <span className="text-danger">*</span></label>
                                  <input className="form-control" type="text" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>تاريخ الميلاد <span className="text-danger">*</span></label>
                                  <input className="form-control" type="text" />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>هاتف <span className="text-danger">*</span></label>
                                  <input className="form-control" type="text" />
                                </div>
                              </div>
                            </div>
                            <div className="add-more">
                              <a href=""><i className="fa fa-plus-circle" /> اضافة المزيد</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">حفظ</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Family Info Modal */}
            {/* Emergency Contact Modal */}
            <div id="emergency_contact_modal" className="modal custom-modal fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">جهات الاتصال</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="card">
                        <div className="card-body">
                          <h3 className="card-title">جهة اتصال أساسية</h3>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>الاسم  <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>صلة القرابة <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>هاتف <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>هاتف 2</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h3 className="card-title">جهة اتصال ثانوية</h3>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>الاسم <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>صلة القرابة  <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>هاتف <span className="text-danger">*</span></label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>هاتف 2</label>
                                <input className="form-control" type="text" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="add-more">
                              <a href=""><i className="fa fa-plus-circle" /> اضافة المزيد</a>
                            </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Emergency Contact Modal */}
            {/* Education Modal */}
            <div id="education_info" className="modal custom-modal fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">  التعليم</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-scroll">
                        <div className="card">
                          <div className="card-body">
                            <h3 className="card-title">معلومات التعليم <a href="" className="delete-icon"><i className="fa fa-trash-o" /></a></h3>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <input type="text" defaultValue="جامعة بيرزيت" className="form-control floating" />
                                  <label className="focus-label">المؤسسة</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <input type="text" defaultValue="علم حاسوب" className="form-control floating" />
                                  <label className="focus-label">التخصص</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <div>
                                    <input type="date" defaultValue="01/06/2002" className="form-control floating datetimepicker" />
                                  </div>
                                  <label className="focus-label">تاريخ الالتحاق</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <div>
                                    <input type="date" defaultValue="31/05/2006" className="form-control floating datetimepicker" />
                                  </div>
                                  <label className="focus-label">تاريخ الانتهاء</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <input type="text" defaultValue="بكالوريوس علم حاسوب" className="form-control floating" />
                                  <label className="focus-label">شهادة</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <input type="text" defaultValue=" A" className="form-control floating" />
                                  <label className="focus-label">معدل</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="card-title">معلومات أخرى <a href="" className="delete-icon"><i className="fa fa-trash-o" /></a></h3>
                            <div className="row">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <input type="text" defaultValue="جامعة بيرزيت" className="form-control floating" />
                                  <label className="focus-label">المؤسسة</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <input type="text" defaultValue="علم حاسوب" className="form-control floating" />
                                  <label className="focus-label">التخصص</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <div>
                                    <input type="date" defaultValue="01/06/2002" className="form-control floating datetimepicker" />
                                  </div>
                                  <label className="focus-label">تاريخ الالتحاق</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <div>
                                    <input type="date" defaultValue="31/05/2006" className="form-control floating datetimepicker" />
                                  </div>
                                  <label className="focus-label">تاريخ الانتهاء</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <input type="text" defaultValue="بكالوريوس علم حاسوب" className="form-control floating" />
                                  <label className="focus-label">شهادة</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus focused">
                                  <input type="text" defaultValue=" A" className="form-control floating" />
                                  <label className="focus-label">معدل</label>
                                </div>
                              </div>
                            </div>
                          </div>
                            <div className="add-more">
                              <a href=""><i className="fa fa-plus-circle" /> اضافة المزيد</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">حفظ</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Education Modal */}
            {/* Experience Modal */}
            <div id="experience_info" className="modal custom-modal fade" role="dialog">
              <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">خبرات</h5>
                    <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-scroll">
                        <div className="card">
                          <div className="card-body">
                            <h3 className="card-title">معلومات الخبرة <a href="" className="delete-icon"><i className="fa fa-trash-o" /></a></h3>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <input type="text" className="form-control floating" defaultValue="MassDev" />
                                  <label className="focus-label">اسم الشركة</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <input type="text" className="form-control floating" defaultValue="القدس" />
                                  <label className="focus-label">الموقع</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <input type="text" className="form-control floating" defaultValue="مطور ويب" />
                                  <label className="focus-label">الوصف الوظيفي</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <div>
                                    <input type="date" className="form-control floating datetimepicker" defaultValue="01/07/2007" />
                                  </div>
                                  <label className="focus-label">تريخ الالتحاق</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <div>
                                    <input type="date" className="form-control floating datetimepicker" defaultValue="08/06/2018" />
                                  </div>
                                  <label className="focus-label">تاريخ الانتهاء</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-body">
                            <h3 className="card-title">معلومات الخبرة <a href="" className="delete-icon"><i className="fa fa-trash-o" /></a></h3>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <input type="text" className="form-control floating" defaultValue="MassDev" />
                                  <label className="focus-label">اسم الشركة</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <input type="text" className="form-control floating" defaultValue="القدس" />
                                  <label className="focus-label">الموقع</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <input type="text" className="form-control floating" defaultValue="مطور ويب" />
                                  <label className="focus-label">الوصف الوظيفي</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <div>
                                    <input type="date" className="form-control floating datetimepicker" defaultValue="01/07/2007" />
                                  </div>
                                  <label className="focus-label">تريخ الالتحاق</label>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group form-focus">
                                  <div>
                                    <input type="date" className="form-control floating datetimepicker" defaultValue="08/06/2018" />
                                  </div>
                                  <label className="focus-label">تاريخ الانتهاء</label>
                                </div>
                              </div>
                            </div>
                            <div className="add-more">
                              <a href=""><i className="fa fa-plus-circle" /> اضافة المزيد</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">حفظ</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* /Experience Modal */}
          </div>
       
    );
  }
  export default EmployeeProfile;
