
import React, { useState, useEffect } from 'react';
import "../../firebase/config";
import { analytics } from '../../firebase/config';
import { MdDeleteOutline } from 'react-icons/md'
import InvoiceStyle from './InvoiceStyle'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactTooltip from "react-tooltip";
import { BiShekel, BiSearchAlt, BiArrowBack, BiExit } from 'react-icons/bi'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { BsInfoCircle, BsCalculator } from 'react-icons/bs'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { VscNewFile } from 'react-icons/vsc'
import { v4 as uuidv4 } from 'uuid'
import TableForm from './TableForm'
import MnthVPt from './Mnth_Vat_Rpt'
import MassBCalculator from '../massb-calculator/MassBCalc';
// import {Calculator} from '../massb-calculator/MassBCalc'
// import TableInvoice from './TableInvoice'
// import Customers from '../../../data/customers'
// import Contacts from '../../../data/contacts'
import { massportallogo } from '../../Entryfile/imagepath'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { set } from 'react-hook-form'

// import { useAuthContext } from '../../../../hooks/useAuthContext'
// import { usePostNestDocs } from '../../../../hooks/usePostNestDocs'
// import { useFetchNestDocs } from '../../../../hooks/useFetchNestDocs'
// import { useCollection } from '../../../../hooks/useCollection'
// import { useUpdateNestDoc } from '../../../../hooks/useUpdateNestDoc'


import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export default function CreateInvoice({
  logo,

}) {


  
  // const {user} = useAuthContext()
  // const { documents:CoData, err } = useCollection('mpbclients', ["id", "==", user.uid],)
  // const { documents:customers, error } = useFetchNestDocs('mpbclients', user.uid, "customers")
  // const { documents:invoiceNum, er } = useFetchNestDocs('mpbclients', user.uid, "invoicenumbers",["title", "==", "invoices"])
  // const { addDocument, response } = usePostNestDocs('mpbclients', user.uid ,"invoices")
  // const { addDocument:UpdateInvoiceNum, response:invRes } = useUpdateNestDoc('mpbclients', user.uid ,"invoicenumbers","invoices")

  const printRef = React.useRef()

  const [show, setShow] = useState(false)
  const [name, setName] = useState('')
  const [value, setValue] = useState(0)
  const [taxNum, setTaxNum] = useState("")
  const [year, setYear] = useState('')
  const [month, setMonth] = useState('--')
  const [perTax, setPerTax] = useState(0)
  const [perTax2, setPerTax2] = useState(0)
  const [address, setAddress] = useState('')
  const [cityAddress, setCityAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [invoiceAddress, setInvoiceAddress] = useState('')
  const [dateInvoice, setDateInvoice] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [invoiceNumber, setInvoiceNumber] = useState(0)
  const [startinvoiceNumber, setStartInvoiceNumber] = useState(0)
  const [companyName, setCompanyName] = useState('')
  const [companyNumber, setCompanyNumber] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [customerNumber, setCustomerNumber] = useState('')
  const [taxRate, setTaxRate] = useState(0)
  const [details, setDetails] = useState('')
  const [catalogNum, setCatalogNum] = useState('')
  const [invoiceDetails, setInvoiceDetails] = useState(' فاتورة ضريبية')
  const [catagory, setCatagory] = useState('إيرادات')
  // const [date1, setDate1] = useState('')
  // Date
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  // console.log("defaultDate:", date)
  const [date1, setDate1] = useState(date)
  const [date2, setDate2] = useState(date)
  const [price, setPrice] = useState('')
  const [quantity, setQuantity] = useState(1)
  const [discountForProduct, setDiscountForProduct] = useState(0)
  const [exemptionAmount, setExemptionAmount] = useState(0)
  const [amount, setAmount] = useState(0)
  const [amounts, setAmounts] = useState(0)
  const [tax, setTax] = useState(0)
  const [isExemption, setIsExemption] = useState(false)
  const [totalAfTax, setTotalAfTax] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalAfDiscount, setTotalAfDiscount] = useState(0)
  const [list, setList] = useState([])
  const [revenues, setRevenues] = useState([])
  const [revenuesExempt, setRevenuesExempt] = useState([])
  const [expenses, setExpenses] = useState([])
  const [fixedExpenses, setFixedExpenses] = useState([])
  const [information, setInformation] = useState('')
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [convertedContent, setConvertedContent] = useState();

  const [lastInvoiceNum, setLastInvoiceNum] = useState(0)
  const [invoiceCounter, setInvoiceCounter] = useState(0)
  const [isSearch, setIsSearch] = useState(false)
  const [contacts, setContacts] = useState([])
  const [isResult, setIsResult] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [filtered, setFiltered] = useState('')
  const [isAddContact, setIsAddContact] = useState(true)
  const [data, setData] = useState([])
  const [coData, setCoData] = useState()
  const [s, setS] = useState(0); // المبيعات / شامل 
  const [a, setA] = useState(0); // المبيعات / معفى  
  const [capex, setCapex] = useState(0); // المصاريف / أصول ثابتة 
  const [exp, setExp] = useState(0); // المصاريف العادية / شامل   
  // const [b, setB] = useState(0); // صفقات ملزمة = المبلغ 
  // const [c, setC] = useState(0); // ض.ق.م على الصفقات  
  // const [dd, setD] = useState(0); // ( ض . ق . م ) مدخلات أصول ثابتة ( תשומות )  
  // const [ee, setE] = useState(0);// ( ض . ق . م ) مدخلات عادية ( תשומות )
  // const [result, setResult] = useState(0); //المبلغ للدفع / للارجاع

  // Header Details 
  const [NOTE, setNOTE] = useState('')
  const savedHDetails = localStorage.getItem('headerDetails') ? JSON.parse(localStorage.getItem('headerDetails')) : [];
  const [headerDetails, setHDeatils] = useState(savedHDetails)

  useEffect(() => {
    let headDetails = {
      companyName,
      companyNumber,
      month,
      year,
      customerName,
      taxRate,
      NOTE
    }

    setHDeatils(headDetails)
  }, [companyName, companyNumber, month, year, customerName, taxRate, NOTE])
  // console.log("headerDetails : ", headerDetails)
  // Add To local Storage
  useEffect(() => {
    localStorage.setItem('headerDetails', JSON.stringify(headerDetails));
  }, [headerDetails]);
  useEffect(() => {
    const detailss = JSON.parse(localStorage.getItem('headerDetails'));
    if (detailss) {
      setHDeatils(detailss);
      setCompanyName(detailss.companyName)
      setCompanyNumber(detailss.companyNumber)
      setMonth(detailss.month)
      setYear(detailss.year)
      setCustomerName(detailss.customerName)
      setTaxRate(detailss.taxRate)
      setNOTE(detailss.NOTE)
    }
  }, []);

  //MODAL
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  //MODAL
  const [showMssg, setShowMssg] = useState(false)
  const handleCloseMssg = () => setShowMssg(false);
  const handleShowMssg = () => setShowMssg(true);
  //MODAL_Calc
  const [showCalc, setshowCalc] = useState(false)
  const handleCloseCalc = () => setshowCalc(false);
  const handleShowCalc = () => setshowCalc(true);
  // ModalExit
  const [showExit, setShowExit] = useState(false);
  const handleShowExit = () => setShowExit(true);
  const handleCloseExit = () => setShowExit(false);
  // LANGUAGE
  const [lang, setLang] = useState('')
  const [DEAR, setDEAR] = useState('')
  const [TAXNUMBER, setTAXNUMBER] = useState('')
  const [DATE, setDATE] = useState('')
  const [PAYUNTIL, setPAYUNTIL] = useState('')
  const [TOTAL, setTOTAL] = useState('')
  const [TAXINVOICENUMBER, setTAXINVOICENUMBER] = useState('')

  const [SIGNATURE, setSIGNATURE] = useState('')
  const [ORIGINAL, setORIGINAL] = useState('')

  // const[EXEMPT,setEXEMPT] = useState('')

  // useEffect(() => {
  //   console.log("catagory", catagory)
  //   console.log("invoiceDetails", invoiceDetails)
  // }, [catagory, invoiceDetails])

  let nam = ''
  let addres = ''
  let mail = ''
  let txnum = 0
  let phon = ''
  let city = ''

  // Text Editor Handler
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();

  }

  const handleClear = () => {
    localStorage.clear();

    window.location.reload(true);


  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
    setNOTE(currentContentAsHTML)
    // console.log("Editor state:", currentContentAsHTML)
  }
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }

  // handleClear()

  //     useEffect(()=>{
  //       setData(customers)
  //       // console.log(data,"data")
  //       if(invoiceNum){
  //         setInvoiceCounter((invoiceNum[0].counter)*1)
  //         setInvoiceNumber((invoiceNum[0].start)*1 + invoiceCounter )
  //         setStartInvoiceNumber(invoiceNum[0].start)
  //         // setLastInvoiceNum((invoiceNum[0].start)*1 + (invoiceNum[0].counter)*1 - 1)
  //         if (invoiceCounter > 0) {
  //            setLastInvoiceNum(invoiceNumber -1)}
  //         else setLastInvoiceNum(0) 
  //       }

  //      if (CoData){
  //       setCoData(CoData[0])
  //       setPerTax(CoData[0].taxPer)
  //       setPerTax2(perTax )
  //       // console.log(perTax,"PER TAX")
  //      }
  //       if( customers && data){
  //         console.log('invoiceNum',invoiceNumber)
  //         nam =  data[value].firstName +" " + data[value].lastName;
  //         // console.log(nam,"nam")
  //         addres = data[value].address
  //         mail = data[value].email
  //         txnum = data[value].vatFileNumber
  //         phon = data[value].phoneNumber
  //         city = data[value].cityName
  //          }
  //          let date = new Date();
  //          let current_date = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+ date.getDate();
  //          setDateInvoice(current_date)
  //          setDueDate(current_date)
  //            setAddress(addres)
  //            setEmail(mail)
  //            setTaxNum(txnum)
  //            setName(nam);
  //            setPhone(phon);
  //            setCityAddress(city)
  //           //  console.log(name,"name")
  //           },[customers,data,value,CoData,coData,invoiceNum,invoiceNumber])

  //           // useEffect(()=>{


  //     // },[customers,data,CoData,coData])



  // const handleSubmit = (e)=>{
  //   // e.preventDefault()
  //   addDocument({
  //     name,
  //     taxNum,
  //     address,
  //     city,
  //     phone,
  //     email,
  //     total,
  //     lastInvoiceNum,
  //     dateInvoice,
  //     dueDate,
  //     amounts,
  //     tax,
  //     discount,
  //     information,
  //     totalAfDiscount,
  //     perTax,
  //     lang,
  //     invoiceNumber,
  //     exemptionAmount,
  //     totalAfTax,
  //     // UserID: user.uid,
  //     list,
  //     CustomersID: data[value].id,
  //     paid:false,
  //     amountPaid:0,
  //   }).then(()=>{
  //     // console.log("list",list)
  //     console.log("تم إنشاء الفاتورة")

  //   })

  //   console.log("handleSubmit Run!")



  //   // alert("تم إنشاء الفاتورة")



  // }

  // // reset the form fields
  // useEffect(() => {
  //   // console.log("response is: ",response)
  //   if (response.success) {
  //     UpdateInvoiceNum({
  //       counter:invoiceCounter*1 +1,
  //     }).then(()=>{
  //       handleDownloadPdf()
  //       setShowModal2(true)
  //     }).then(()=>{
  //     //   setName('');
  //     // setTaxNum('')
  //     // setAddress('')
  //     // setPhone('');
  //     // setEmail('')
  //     setTotal(0)
  //     setTotalAfDiscount(0)
  //     // setDateInvoice('')
  //     // setDueDate('')
  //     setList([])
  //     setAmounts(0)
  //     setTax(0.00)
  //     setDiscount(0)
  //     setInformation('')
  //     // setPerTax(17)
  //     // setCityAddress('')
  //     setTotalAfTax(0)
  //     setExemptionAmount(0)
  //     // setValue(0)

  //     // setLang('')
  //     // setShow(!show)
  //     })


  //   }
  // }, [response.success])

  //    useEffect(()=>{  
  //     console.log(customers)
  //     const Translate=()=>{
  //       if(lang === 'en'){
  //         setDEAR('Dear : ')
  //         setTAXNUMBER('Tax Number :')
  //         setDATE('Invoice Date :')
  //         setPAYUNTIL('Pay Until :')
  //         setTOTAL('Total :')
  //         setTAXINVOICENUMBER('Tax Invoice No. ')
  //         setNOTE('Notes :')
  //         setSIGNATURE('Signature :')
  //         setORIGINAL('Original')
  //         // setEXEMPT('Exempt from tax ')
  //       }
  //       else if (lang === 'he'){
  //         setDEAR('לכבוד :')
  //         setTAXNUMBER("  מספר ע'מ :")
  //         setDATE('תאריך חשבונית : ')
  //         setPAYUNTIL('לתשלום עד :')
  //         setTOTAL('סה"כ לתשלום :')
  //         setTAXINVOICENUMBER("חשבונית מס מס'")
  //         setNOTE('הערות : ')
  //         setSIGNATURE('חתימה')
  //         setORIGINAL('מקור')
  //         // setEXEMPT('פטור ממ"ע')
  //       }else {
  //         setDEAR('حضرة :')
  //         setTAXNUMBER(' رقم الملف الضريبي  :')
  //         setDATE('تاريخ الفاتورة :')
  //         setPAYUNTIL('تاريخ الاستحقاق :')
  //         setTOTAL('المبلغ الكلي')
  //         setTAXINVOICENUMBER('فاتورة رقم')
  //         setNOTE('ملاحظات :')
  //         setSIGNATURE('التوقيع :')
  //         setORIGINAL('أصلية')
  //         // setEXEMPT('معفي ض.ق.م')


  //       }
  //     }
  //     Translate()


  //    },[lang])

  let revenuestot = 0
  const revenuesTotal = revenues ? revenues.map((value) => (
    revenuestot = revenuestot + (value.price * 1)
    
    // console.log("revs are: ", value.price)
  )) : 0
  //console.log("REVS are : ", revenues)
  let revenuesExempttot = 0

  const revenuesExemptTotal = revenuesExempt ? revenuesExempt.map((value) => (
    // console.log("EXEMPTTTTTTTT: ",value.price * 1)
    revenuesExempttot = revenuesExempttot + (value.price * 1)


  )) : 0
  //console.log("revenuesExempt are : ", revenuesExempt)



  let expensestot = 0
  const expensesTotal = expenses?expenses.map((value) => (
    expensestot = expensestot + (value.price * 1)
  )):0
  let fixedExpensestot = 0
  const fixedExpensesTotal =fixedExpenses? fixedExpenses.map((value) => (
    fixedExpensestot = fixedExpensestot + (value.price * 1)
  )):0

  //console.log("fixedExpenses are : ", fixedExpenses)


  const handleDownloadPdf = async () => {
    // handleSubmit()
    // console.log("new invoice created !!")
    // console.log(" here ")
    const iaactive = true;
    const element = printRef.current;
    element.style.background = '#FFFFFF';
    element.style.fontSize = "30px";

    const canvas = await html2canvas(element);

    const d = new Date().toLocaleString() + "";
    const footer = d + "            Issued using 'www.mass-portal.com' ";
    var imgData = canvas.toDataURL('image/png');
    var imgWidth = 210;
    var pageHeight = 290;
    var imgHeight = (canvas.height * imgWidth / canvas.width);
    var doc = new jsPDF();
    var position = 18; // give some top padding to first page
    // Data
    doc.addImage(imgData, 'PNG', 2, position - 10, imgWidth - 8, imgHeight);
    // doc.addImage(massportallogo, 'png', 2, 0, 20, 20);
    doc.text(footer, 30, pageHeight, { baseline: 'bottom' });
    // doc.setFontSize(40);

    doc.save('MassBill_invoice.pdf');
  };


  //     function addZero(i) {
  //       if (i < 10) {i = "0" + i}
  //       return i;
  //     }
  //     const date = new Date();
  //     let h = addZero(date.getHours());
  //     let m = addZero(date.getMinutes());
  //     let s = addZero(date.getSeconds());
  //     let time = h + ":" + m + ":" + s;
  //     const d = new Date().toLocaleString() + "";
  //       let current = d.split(',');
  //       const today = current[0];
  //       const todaySplitArr = today.split('/');
  //       const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];


  //     //-----------------get the date of today---------------------
  //     //---------------- calculate amounts of products-------------
  //     // - get the data for every contact ==> name,address, email ,etc... -

  //     // useEffect(()=>{

  //     //   },[value,])




  //     // ------------- function search contact---------

  //     useEffect(()=>{
  //       // console.log(contacts,"contacts")
  //       const searchContact = ()=>{
  //         let filter = [];
  //         let fullname;

  //         if(isSearch && customers && data ){
  //         for(let i=0; i < data.length; i++){
  //           fullname = data[i].firstName + data[i].lastName
  //          for(let j=0; j <= inputValue.length; j++ ){
  //             if (
  //               (
  //                (data[i].firstName[j] === inputValue[j] && data[i].firstName[j-1] === inputValue[j-1]) ||
  //                (data[i].lastName[j] === inputValue[j] && data[i].lastName[j-1] === inputValue[j-1]) || 
  //                (fullname[j] === inputValue[j] && fullname[j-1] === inputValue[j-1])
  //                ) 
  //                &&
  //               (j == (inputValue.length)-1) 
  //                ){
  //               filter.push(data[i],)
  //             }

  //          }
  //         }
  //         if (filter.length !=0 ){
  //           setIsResult(true)
  //           setFiltered(filter)
  //         } 
  //       }
  //       else if(!isSearch){
  //         setFiltered([])
  //         setIsResult(false)
  //         filter=[];
  //       }       
  //         }
  //         searchContact()
  //     },[inputValue,data])



  //     // -function get index of contact by id when click on contact in result table

  //     const getIndexById = (id)=>{
  //       data.map((c,index)=>{
  //         if (c.id == id ){
  //           setValue(index)
  //         }
  //       }
  //       )
  //     }

  //     //---------------- function handleChange ---------------------
  //     // function nameChange(e) {
  //     //   if(nam === ""){
  //     //     setName(e.target.value)
  //     //   }
  //     // }
  //     // function nameChange(e) {
  //     //   if(name === "" || name === ""){
  //     //     setName(e.target.value)
  //     //   }
  //     // }
  //     // function addressChange(e) {
  //     //   if(addres === ""){
  //     //     setAddress(e.target.value)
  //     //   }
  //     // }
  //     // function emailChange(e) {
  //     //   if(mail === ""){
  //     //     setEmail(e.target.value)
  //     //   }
  //     // }
  //     // function taxNumChange(e) {
  //     //   if(txnum === ""){
  //     //     setTaxNum(e.target.value)
  //     //   }
  //     // }

  //     const ONCHANGE = (e)=>{
  //       if(e.target.value > -1 ){
  //         setValue(e.target.value)
  //       }
  //     }

  //-------------------start rendering--------------------------


  return (
    <div className="page-wrapper">
      <div className='content container-fluid'>
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <h3 className="page-title privacy">حاسبة ضريبة قيمة مضافة مفصلة </h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
                {/* <li className="breadcrumb-item active">Create Invoice</li> */}
              </ul>
              <br></br>

            </div>

          </div>
          <div className="row">
            <div className='col-sm-12 col-md-2'></div>
            <div className="col-sm-12 col-md-8">

              <br></br>
              {invoiceNumber > 0 && <h1 className="page-title text-center bold">JV رقم <span className='text-success'>{invoiceNumber + '#'}</span> </h1>}
              {invoiceNumber == 0 && <h1 className="page-title text-center bold">احسب ضرائبك بخمس دقائق  </h1>}


              {/* {lastInvoiceNum && <h4 className=" text-right ">رقم اخر قيد أصدر  <span className='bold'>{lastInvoiceNum + '#'}</span></h4>} */}

            </div>
           
          </div>
        </div>
        {/* /Page Header */}
        {/* ---------------INVOICE START HERE--------------------- */}

        {show ?
          <div className='row ' >
            <InvoiceStyle
              lang={lang}
              DEAR={DEAR}
              TAXNUMBER={TAXNUMBER}
              TAXINVOICENUMBER={TAXINVOICENUMBER}
              DATE={DATE}
              PAYUNTIL={PAYUNTIL}
              TOTAL={TOTAL}
              NOTE={NOTE}
              SIGNATURE={SIGNATURE}
              ORIGINAL={ORIGINAL}
              name={name}
              printRef={printRef}
              taxNum={taxNum}
              address={address}
              cityAddress={cityAddress}
              phone={phone}
              companyName={companyName}
              companyNumber={companyNumber}
              customerName={customerName}
              customerNumber={customerNumber}
              year={year}
              month={month}
              logo={logo}
              coData={coData}
              revenues={revenues}
              setRevenues={setRevenues}
              revenuesExempt={revenuesExempt}
              setRevenuesExempt={setRevenuesExempt}
              expenses={expenses}
              setExpenses={setExpenses}
              fixedExpenses={fixedExpenses}
              setFixedExpenses={setFixedExpenses}
              list={list}
              amounts={amounts}
              tax={tax}
              discount={discount}
              total={total}
              information={information}
              totalAfDiscount={totalAfDiscount}
              perTax={perTax}
              dueDate={dueDate}
              totalAfTax={totalAfTax}
              exemptionAmount={exemptionAmount}
              headerDetails={headerDetails}
              handleDownloadPdf={handleDownloadPdf}
              // handleSubmit={handleSubmit}

              show={show}
              setShow={setShow}
              showModal={showModal2}
              setShowModal={setShowModal2}
              invoiceNumber={invoiceNumber}
              dateInvoice={dateInvoice}


            />
          </div>

          :
          ////////////////////////////////////// ENGLISH INVOICE ////////////////////////




          ////////////////////////// form start here///////////////////////////////////// 



          <>
            {/* Page Content */}


            {/* ========================= JV_SECTION======================= */}
            <div className=" col-md-12 content container-fluid ">
              <div className='jv-section '>

                <div className="row">
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label>اسم الشركة    <span className="text-danger">*</span></label>

                      <input className="form-control" type="name"
                        onChange={(e) => {
                          // nam === "زبون  طيار" || name == undefined ? "name" :  setName(e.target.value) 
                          setCompanyName(e.target.value)
                        }}
                        value={companyName}
                        required
                      />




                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label>رقم الشركة   <span className="text-danger">*</span></label>
                      <input
                        className="form-control"
                        //  onChange={taxNumChange}
                        onChange={e => setCompanyNumber(e.target.value)}
                        value={companyNumber}
                        required
                      />

                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label>نسبة سلفيات ضريبة الدخل   </label>
                      <input className="form-control " type="text"
                        onChange={(e) => setTaxRate(e.target.value)}
                        value={taxRate}
                      />
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-2'></div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group form-focuss">
                      <label>فترة الكشف </label>
                      <select
                        className=" select floating tax-select  test"
                        // name='month'
                        // id="month"
                        // style={{fontSize:"100px"}}
                        onChange={(e) => {
                          (
                            // nam === "زبون  طيار" || name == undefined ? "name" :  setName(e.target.value) 
                            setMonth(e.target.value)


                          )
                        }}
                        value={month}

                      >
                        <option value={'--'}>--</option>
                        <option value={'1'}>1</option>
                        <option value={'2'}>2</option>
                        <option value={'1-2'}>1 - 2</option>
                        <option value={'3'}>3</option>
                        <option value={'4'}>4</option>
                        <option value={'3-4'}>3 - 4 </option>
                        <option value={'5'}>5</option>
                        <option value={'6'}>6</option>
                        <option value={'5-6'}> 5 - 6 </option>
                        <option value={'7'}>7</option>
                        <option value={'8'}>8</option>
                        <option value={'7-8'}> 7 - 8 </option>
                        <option value={'9'}>9</option>
                        <option value={'10'}>10</option>
                        <option value={'9-10'}>9 - 10 </option>
                        <option value={'11'}>11</option>
                        <option value={'12'}>12</option>
                        <option value={'11-12'}>11 - 12 </option>

                      </select>
                      {/* <input className="form-control" type="email" onChange={e=>setEmail(e.target.value)}/> */}

                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label>السنة </label>
                      <input className="form-control"
                        type="text"
                        value={year}
                        //  onChange={emailChange}
                        onChange={(e) => setYear(e.target.value)}

                      />
                      {/* <input className="form-control" type="email" onChange={e=>setEmail(e.target.value)}/> */}

                    </div>
                  </div>
                  {/* <div className="col-sm-6 col-md-3">
                <div className="form-group">
                  <label>ضريبة </label>
                  <select className="select select-sales" onChange={e=>setPerTax(e.target.value)} value={perTax}>
                    <option value={perTax2 }>ض.ق.م - {perTax2 + "%"}</option>
                    <option  value={0}>معفى - {0 + "%"}</option>
                    <option value={17}>GST</option>
                  </select>
                </div>
              </div> */}



                  {/* <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label>اسم الزبون  </label>
                      <input className="form-control " type="text"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                      />
                    </div>
                  </div>
                   */}
                  
                  {/* <div className='col-sm-6 col-md-4'>
                    <label className=" col-form-label label-vit ">نسبة ضريبة القيمة المضافة ( ض . ق . م ) </label>
                    <select
                      id="tax"
                      className='select floating  tax-select '
                      name='tax'
                   
                    >
                      <option value="17"> 17 % </option>
                      <option value="20">  20 %</option>
                      <option value="16"> 16 %  </option>

                    </select><br></br>
                  </div> */}
                  {/* <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label >رقم الزبون   </label>
                      <textarea className="form-control" rows={1} defaultValue={""} />
                      <input className="form-control " type="text"
                        placeholder={customerNumber}
                        onChange={e => setCustomerNumber(e.target.value)} />

                    </div>
                  </div> */}
                  {/* <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>من الفترة <span className="text-danger">*</span></label>
                          <div className="">
                            <input className="form-control " type="date"
                              onChange={e=>setDateInvoice(e.target.value)}
                              value={dateInvoice} />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>الى الفترة   <span className="text-danger">*</span></label>
                          <div className="">
                            <input className="form-control " type="date"
                              onChange={e => setDueDate(e.target.value)}
                              value={dueDate} />
                          </div>
                        </div>
                      </div> */}
                </div>
                <br></br>
              </div>
              <div className="row">
                <div >
                  {/* ---------------- Table Form --------------------- */}
                  <TableForm
                    month={month}
                    setMonth={setMonth}
                    details={details}
                    setDetails={setDetails}
                    headerDetails={headerDetails}
                    setHDeatils={setHDeatils}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    price={price}
                    setPrice={setPrice}
                    amount={amount}
                    setAmount={setAmount}
                    total={total}
                    setTotal={setTotal}
                    totalAfDiscount={totalAfDiscount}
                    setTotalAfDiscount={setTotalAfDiscount}
                    totalAfTax={totalAfTax}
                    setTotalAfTax={setTotalAfTax}
                    catalogNum={catalogNum}
                    setInvoiceDetails={setInvoiceDetails}
                    invoiceDetails={invoiceDetails}
                    setCatagory={setCatagory}
                    catagory={catagory}
                    date1={date1}
                    date2={date2}
                    setDate1={setDate1}
                    setDate2={setDate2}
                    setCatalogNum={setCatalogNum}
                    perTax={perTax}
                    setPerTax={setPerTax}
                    tax={tax}
                    setTax={setTax}
                    discountForProduct={discountForProduct}
                    setDiscountForProduct={setDiscountForProduct}
                    amounts={amounts}
                    setAmounts={setAmounts}
                    discount={discount}
                    setDiscount={setDiscount}
                    isExemption={isExemption}
                    setIsExemption={setIsExemption}
                    exemptionAmount={exemptionAmount}
                    setExemptionAmount={setExemptionAmount}
                    list={list}
                    setList={setList}
                    revenues={revenues}
                    setRevenues={setRevenues}
                    revenuesExempt={revenuesExempt}
                    setRevenuesExempt={setRevenuesExempt}
                    expenses={expenses}
                    setExpenses={setExpenses}
                    fixedExpenses={fixedExpenses}
                    setFixedExpenses={setFixedExpenses}
                    companyName={companyName}
                    setCompanyName={setCompanyName}



                  />

                  {/* <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>نص اضافي للقيد  </label>
                        <div style={{ border: "1px solid black", padding: '10px', minHeight: '300px', backgroundColor: "#16191c" }}>
                          <Editor
                            editorState={editorState}
                            toolbarClassName="rdw-editor-toolbar"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"

                            onEditorStateChange={handleEditorChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>

              <Modal show={showMssg} onHide={handleCloseMssg}>
                <Modal.Header closeButton>
                  {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>يرجى تعبئة الحقول المطلوبة, لايمكنك إنشاء قيد مالي</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseMssg}  >
                    إغلاق
                  </Button>

                </Modal.Footer>
              </Modal>
              <Modal show={showExit} onHide={handleCloseExit}>
                <Modal.Header closeButton>
                  {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>هل تريد حذف المعلومات و فتح ملف جديد ؟ </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseExit}  >
                    لا
                  </Button>
                  <Button variant="primary" onClick={handleClear}  >
                    نعم
                  </Button>

                </Modal.Footer>
              </Modal>
              <div className="submit-section">
                {/* <button
                  type='submit'
                  className="btn btn-primary submit-btn m-r-10"
                  //  onClick={handleDownloadPdf} data-tip data-for="pdf-tip"
                  onClick={() => {
                    total ? setShow(!show) : handleShowMssg();
                    setLang('ar');
                  }}
                >
                  معاينة القيود
                </button> */}
                {/* <button
                      className="btn btn-primary submit-btn"
                      type='submit'
                      onClick={() => {
                        total ? setShow(!show) : handleShowMssg();
                        setLang('en');
                      }}
                    >
                      معاينة EN
                    </button> */}
                {/* <button
                      type='submit'
                      className="btn btn-primary submit-btn m-r-10"
                      onClick={() => {
                        total ? setShow(!show) : handleShowMssg();
                        setLang('he');
                      }}
                    >
                      معاينة HE
                    </button> */}
              </div>
            </div>
            {/* ===========================JV_SEction======================= */}
            <div className='row mathvpt-section '>

              <MnthVPt
                s={revenuestot}
                setS={setS}
                a={revenuesExempttot}
                setA={setA}
                exp={expensestot}
                setExp={setExp}
                capex={fixedExpensestot}
                setCapex={setCapex}
                companyName={companyName}
                companyNumber={companyNumber}
                year={year}
                month={month}
                taxRate={taxRate}
                setMonth={setMonth}
                customerName={customerName}
                customerNumber={customerNumber}
                setCompanyName={setCompanyName}
                setCompanyNumber={setCompanyNumber}
                setCustomerName={setCustomerName}
                setCustomerNumber={setCustomerNumber}
                setYear={setYear}
                lang={lang}
                DEAR={DEAR}
                TAXNUMBER={TAXNUMBER}
                TAXINVOICENUMBER={TAXINVOICENUMBER}
                DATE={DATE}
                PAYUNTIL={PAYUNTIL}
                TOTAL={TOTAL}
                NOTE={NOTE}
                SIGNATURE={SIGNATURE}
                ORIGINAL={ORIGINAL}
                name={name}
                printRef={printRef}
                taxNum={taxNum}
                address={address}
                cityAddress={cityAddress}
                phone={phone}
                logo={logo}
                coData={coData}
                revenues={revenues}
                setRevenues={setRevenues}
                revenuesExempt={revenuesExempt}
                setRevenuesExempt={setRevenuesExempt}
                expenses={expenses}
                setExpenses={setExpenses}
                fixedExpenses={fixedExpenses}
                setFixedExpenses={setFixedExpenses}
                list={list}
                amounts={amounts}
                tax={tax}
                discount={discount}
                total={total}
                information={information}
                totalAfDiscount={totalAfDiscount}
                perTax={perTax}
                dueDate={dueDate}
                totalAfTax={totalAfTax}
                exemptionAmount={exemptionAmount}
                headerDetails={headerDetails}
                handleDownloadPdf={handleDownloadPdf}
                // handleSubmit={handleSubmit}
  
                show={show}
                setShow={setShow}
                showModal={showModal2}
                setShowModal={setShowModal2}
                invoiceNumber={invoiceNumber}
                dateInvoice={dateInvoice}


              />
            </div>
            <div className='helping-nav-mk row text-center'>
              <div className='col-sm-12 col-md-8' >
                <p dir='rtl' className='privacy-large privacy-bold'>
                  <BsInfoCircle size={23} className="privacy-icon" />
                  كل المعلومات سوف تحفظ على متصفحك الخاص ويمكنك الوصول اليها بمجرد الدخول الى صفحة الحاسبة واذا اردت حذفها واحتساب  جديد اضغط حذف
                </p>
              </div>
              <div className='col-sm-12 col-md-2  btn btn-primary submit-btn text-center' onClick={handleShowExit} >
                <VscNewFile size={25} /> <span>ملف جديد</span>
              </div>
            </div>

            {/* /Page Content */}



          </>
        }

      
      </div>
    </div>
  )
}

