/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import {massicon, massportallogo} from './Entryfile/imagepath';
class ThankYou extends Component {

    render() {
     
      return (
         <div className="page-wrapper">  
         <div className="main-wrapper">
            <Helmet>
                <title>Mass Portal</title>
                <meta name="description" content="error page "/>					
            </Helmet>
         <div className="error-box">
            <br></br>
            <br></br>
             {/* Account Logo */}
             <div className="account-logo">
              <a href="/home"><img src={massportallogo} alt="Dreamguy's Technologies" /></a>
            </div>
            {/* /Account Logo */}
           <h1>Mass Portal</h1>
           <br></br>
           {/* <h3> الصفحة التي بحثت عنها غير موجودة !</h3> */}
           <h2>   شكرا لك , تم الارسال بنجاح </h2>
<br></br>
           <a onClick={()=>localStorage.setItem("firstload","true")} href="/home" className="btn btn-custom"> الرجوع الى الصفحة الرئيسية</a>
         </div>
       </div>
      </div>  
      );
   }
}

export default ThankYou;
