
    let massShebah = [
      {
        "date": "01/01/2022",
        "band1":{"max": 2070000}
        ,"band2":{"max": 3443000}
      },
      {
        "date": "01/01/2021",
        "band1":{"max": 2022000}
        ,"band2":{"max": 3362000}
      },
      {
        "date": "01/01/2020",
        "band1":{"max": 2034000}
        ,"band2":{"max": 3383000}
      },
      {
        "date": "01/01/2019",
        "band1":{"max": 2028000}
        ,"band2":{"max": 3372000}
      },
      {
        "date": "01/01/2018",
        "band1":{"max": 2004000}
        ,"band2":{"max": 3332000}
      },
      {
        "date": "01/01/2017",
        "band1":{"max": 1998000}
        ,"band2":{"max": 3324000}
      },
      {
        "date": "01/01/2016",
        "band1":{"max": 2004000}
        ,"band2":{"max": 3334000}
      },
      {
        "date": "01/01/2015",
        "band1":{"max": 2022000}
        ,"band2":{"max": 3364000}
      },
      {
        "date": "01/01/2014",
        "band1":{"max": 2024000}
        ,"band2":{"max": 3367000}
      },
      {
        "date": "01/01/2013",
        "band1":{"max": 1986000}
        ,"band2":{"max": 3303000}
      },
      {
        "date": "01/01/2012",
        "band1":{"max": 1957000}
        ,"band2":{"max": 3256000}
      },
      {
        "date": "01/01/2011",
        "band1":{"max": 1908000}
        ,"band2":{"max": 3175000}
      },
      {
        "date": "01/01/2010",
        "band1":{"max": 1865000}
        ,"band2":{"max": 3103000}
      },
      {
        "date": "01/01/2009",
        "band1":{"max": 1796000}
        ,"band2":{"max": 2989000}
      },
      {
        "date": "01/01/2008",
        "band1":{"max": 1718000}
        ,"band2":{"max": 2860000}
      },
      {
        "date": "01/01/2007",
        "band1":{"max": 1671000}
        ,"band2":{"max": 2782000}
      },
      {
        "date": "01/01/2006",
        "band1":{"max": 1675000}
        ,"band2":{"max": 2790000}
      },
      {
        "date": "01/01/2005",
        "band1":{"max": 1631000}
        ,"band2":{"max": 2717000}
      },
      {
        "date": "01/01/2004",
        "band1":{"max": 1616000}
        ,"band2":{"max": 2692000}
      },
      {
        "date": "01/01/2003",
        "band1":{"max": 1648000}
        ,"band2":{"max": 2745000}
      },
      {
        "date": "01/01/2002",
        "band1":{"max": 1544000}
        ,"band2":{"max": 2573000}
      },
      {
        "date": "01/01/2001",
        "band1":{"max": 1522000}
        ,"band2":{"max": 2537000}
      },
      {
        "date": "01/01/2000",
        "band1":{"max": 1521000}
        ,"band2":{"max": 2535000}
      },
      {
        "date": "01/02/1999",
        "band1":{"max": 1500000}
        ,"band2":{"max": 2500000}
      },
      {
        "date": "01/01/1999",
        "band1":{"max": 1405000}
        ,"band2":{"max": 0}
      },
      {
        "date": "01/01/1998",
        "band1":{"max": 1298000}
        ,"band2":{"max": 0}
      },
      {
        "date": "28/02/1997",
        "band1":{"max": 1200000}
        ,"band2":{"max": 0}
      }
    ]
  export default {massShebah}