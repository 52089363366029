import { useEffect, useState } from "react"
import taxData from "../mass-modules/mass-taxes/national-insurance/data/output";

export const useTaxCalc = (income,year) => {
    const [tax, setTax] = useState(0);
   
    let num = [
        taxData.taxData[year].incomeTax.band1.max / 12,
        taxData.taxData[year].incomeTax.band2.max / 12,
        taxData.taxData[year].incomeTax.band3.max / 12,
        taxData.taxData[year].incomeTax.band4.max / 12,
        taxData.taxData[year].incomeTax.band5.max / 12,
        taxData.taxData[year].incomeTax.band6.max / 12,
        taxData.taxData[year].incomeTax.band7.max / 12,
      ];
    
      let percentage = [
        taxData.taxData[year].incomeTax.band1.rate / 100,
        taxData.taxData[year].incomeTax.band2.rate / 100,
        taxData.taxData[year].incomeTax.band3.rate / 100,
        taxData.taxData[year].incomeTax.band4.rate / 100,
        taxData.taxData[year].incomeTax.band5.rate / 100,
        taxData.taxData[year].incomeTax.band6.rate / 100,
        taxData.taxData[year].incomeTax.band7.rate / 100,
      ];
    
      let np = [
        num[0] * percentage[0],
        (num[1] - num[0]) * percentage[1],
        (num[2] - num[1]) * percentage[2],
        (num[3] - num[2]) * percentage[3],
        (num[4] - num[3]) * percentage[4],
        (num[5] - num[4]) * percentage[5],
      ];
    
 useEffect(()=>{
    setTax(Calc(income / 12))
},[income,year])
  
function Calc(income){
    let tax1 = 0 ;

    if (income > 0 && income <= num[0]) {
      tax1 = income * percentage[0];
    } else if (income > num[0] && income <= num[1]) {
      tax1 = (income - num[0]) * percentage[1] + np[0];
    } else if (income > num[1] && income <= num[2]) {
      tax1 = (income - num[1]) * percentage[2] + (np[0] + np[1]);
    } else if (income > num[2] && income <= num[3]) {
      tax1 = (income - num[2]) * percentage[3] + (np[0] + np[1] + np[2]);
    } else if (income > num[3] && income <= num[4]) {
      tax1 =
        (income - num[3]) * percentage[4] + (np[0] + np[1] + np[2] + np[3]);
    } else if (income > num[4] && income <= num[5]) {
      tax1 =
        (income - num[4]) * percentage[5] +
        (np[0] + np[1] + np[2] + np[3] + np[4]);
    } else if (income > num[5]) {
      tax1 =
        (income - num[5]) * percentage[6] +
        (np[0] + np[1] + np[2] + np[3] + np[4] + np[5]);
    }
    return tax1 = Math.round(tax1 * 12 );
    
    
    
}

  return { tax }
}