
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Avatar_19, Avatar_29, Avatar_07, Avatar_06, Avatar_14, Avatar_18, Avatar_28, Avatar_13 } from "../../../Entryfile/imagepath"
// import ExchngRate from './ExchngRate';
import VatCalc from './VatCalc';
class VatPage extends Component {
  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - حاسبة ( ض . ق . م )</title>
          <meta name="description" content="حاسبة ضريبة القيمة المضافة " />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">حاسبة ضريبة القيمة المضافة ( ض . ق . م ) </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
                </ul>
                <br></br><br></br>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* /Page Content */}
          <div className='row'>
            <div className='col-sm-12 col-md-4'>

            </div>
            <div className='col-sm-12 col-md-4'>
            <VatCalc />
            </div>
          </div>
          {/* <VatCalc /> */}
        </div>
      </div>
    );
  }
}

export default VatPage;
