/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { GiArabicDoor } from "react-icons/gi";
import { BiBookBookmark } from "react-icons/bi";
import { FaMosque } from "react-icons/fa";

export default class ZakatInfo extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal - Zakat Calculator</title>
					<meta name="description" content="Reactify Blank Page" />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">

						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>عن نصاب الزكاة</h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>
						{/* Content Starts */}

						<div className="row">
							<div className="col-sm-12 ">
								<p className='text-center '><FaMosque size={25} className='mosque-flip' />  <span className='quraan'>وَأَقِيمُوا الصَّلَاةَ وَآتُوا الزَّكَاةَ ۚ وَمَا تُقَدِّمُوا لِأَنْفُسِكُمْ مِنْ خَيْرٍ تَجِدُوهُ عِنْدَ اللَّهِ ۗ إِنَّ اللَّهَ بِمَا تَعْمَلُونَ بَصِيرٌ </span> <FaMosque size={25} /> (  سورة البقرة  - 110 ) </p>
							</div>
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<h2 >نصاب زكاة المال 2022</h2>
								<p className='zakat-content'>
									كما أكدت دار الإفتاء المصرية فى وقت سابق أن نصاب زكاة المال
									<span className='privacy'><b> 85 جراما من الذهب عيار 21 </b> </span>.<br></br>
									وتخرج الزكاة عما قيمته ذلك أو أكثر إذا مر على المال حول قمرى بواقع ربع العشر؛ أى اثنين ونصف بالمائة <span className='privacy'><b> 2.5%  </b></span> .

								</p>
								<h2 >نصاب زكاة المال ربع العشر</h2>
								<p className='zakat-content'>
									ومقدار الزكاة هو ربع العشر، أي <span className='privacy'><b> 2.5%  </b></span>  من الأصل والعائد ويشترط أن يكون مر عليه سنة هجرية كاملة،<br></br>
									ويتم حساب النسبة حسب السعر المحدد فى يوم دفع الزكاة.

								</p>
								<br></br>

								<h2>شروط نصاب زكاة المال</h2>
								<br></br>
								<ol className='privacy-ol'>
									<li>
										<p>
											بلوغ النصاب: أن يكون المبلغ كامل النصاب (85 جرام ذهب عيار 21) سعر الجرام بسعر اليوم،
											<br></br> أى نقوم بضرب عدد الجرامات فى سعر الجرام الواحد فى يوم إخراج الزكاة، ويتم حساب الزكاة 2.5% من الأصل.
										</p>
									</li>
									<li>
										<p>
											حولان الحول: عام هجرى كامل على النصاب.
										</p>
									</li>
									<li>
										<p>
											أن تكون خاليا من الديون وفائض عن الحاجة أى يكون المال مدخر.
										</p>
									</li>
								</ol>
								<ul className='privacy-ol'>
									<li>
										<h4>كما أن حلى المرأة الذى تستخدمه للزينة ليس عليه زكاة، لأنها تتزين به، طالما أن النية منه الزينة،
											<br></br>أما إذا كان الغرض منه الإدخار فعليها الزكاة، لكنها قد تخرج عليه صدقة من باب الورع.
										</h4>

									</li>

								</ul>
								<div className='row text-center'>
									<div className='col-sm-12 col-md-12'>
									<a href='/calculators/zakat-calc/ZakatCalc' className='btn btn-primary zakat-btn'>احسب زكاتك الأن</a>

									</div>
								</div>

							</div>
						</div>

						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
