
import React, { Component, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { BiShekel, BiSearchAlt, BiArrowBack, BiExit } from 'react-icons/bi'
import { BsCardList } from 'react-icons/bs'
// import { useCollection } from "../../hooks/useCollection"
// import { useAuthContext } from "../../hooks/useAuthContext"
// import { useDocument } from '../../hooks/useDocument'
// import { timeStamp } from "../../firebase/config"
// import { useAuthContext } from "../../../hooks/useAuthContext"
// import { usePostNestDocs } from "../../../hooks/usePostNestDocs"
// import { useFetchNestDocs } from '../../../hooks/useFetchNestDocs';
// import { useUpdateNestDoc } from '../../../hooks/useUpdateNestDoc';
// import { useHistory } from 'react-router'
import { Placeholder } from 'react-bootstrap';
import { number } from 'prop-types';
// import { Select } from 'react-select';
export default function ExpNew() {


  // const { user } = useAuthContext()
  // const { addDocument, response } = usePostNestDocs('mpbclients', user.uid , "customers")
  // const { documents:NUMS, err} = useFetchNestDocs('mpbclients', user.uid, "invoicenumbers" )
  // const { addDocument:UpdateCustomerNumber, response:customerRes } = useUpdateNestDoc('mpbclients',user.uid,'invoicenumbers',"customernumber")

  //  const history = useHistory();
  //  const { updateDocument, response}= useFirestore('mp-clients')

  //  const {clientInfo, error2} = useDocument('mp-clients', user.uid)

  //  const { documents } = useCollection( 'users')
  //  const [users, setUsers] = useState([])
  //  Form Valid Values :
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyNumber, setCompanyNumber] = useState('')
  const [cardNumber, setCardNumber] = useState(null)
  const [numOfCust, setNumOfCust] = useState(null)
  const [jobType, setJobType] = useState('')
  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [cityName, setCityName] = useState('')
  const [stateName, setStateName] = useState('')
  const [address, setAddress] = useState('')
  const [mailCode, setMailCode] = useState('')
  // const [mailBox, setMailBox] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [officeNumber, setOfficeNumber] = useState('')
  const [faxNumber, setFaxNumber] = useState('')
  const [taxFileNumber, setTFNumber] = useState('')
  const [vatFileNumber, setVatFNumber] = useState('')
  const [cutFileNumber, setcFileNumber] = useState('')
  const [notes, setNotes] = useState('')
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const [convertedContent, setConvertedContent] = useState();

  //MODAL
  const [showModal, setShowModal] = useState(false);
  const [showModal2,setShowModal2]= useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  // Text Editor Handler
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();

  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
    // console.log("Editor state:", currentContentAsHTML)
  }

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }
  // const { document, error } = useDocument('mp-clients',  user.uid)
  // console.log("document: ", document?document.userName: 'nth')
  // Submit Handler 

  // Add Document to firestore

  // useEffect(()=>{
  // if (NUMS){
  //   NUMS.map((doc)=>{
  //     if (doc.id == 'customernumber'){
  //       setCardNumber(doc.currentNumber)
  //       setNumOfCust(doc.start)
  //     }
  //   })
  // }
  // },[NUMS])
  // const CreateNewCard = async (e)=>{
  //   e.preventDefault()
  //   addDocument({
  //     firstName,
  //     lastName,
  //     companyName,
  //     companyNumber,
  //     cardNumber,
  //     jobType,
  //     bankAccountNumber,
  //     cityName,
  //     stateName,
  //     address,
  //     mailCode,
  //     email,
  //     phoneNumber,
  //     officeNumber,
  //     faxNumber,
  //     vatFileNumber,
  //     taxFileNumber,
  //     cutFileNumber,
  //     notes,

  //   }).then(()=>{
  //     UpdateCustomerNumber({
  //       currentNumber: cardNumber+1,
  //       number: cardNumber+1 - numOfCust,
  //     })
  //   }).catch((err)=>{
  //     alert(err.message)
  //   })





  // }

  // // reset the form fields
  // useEffect(() => {
  //   if (response.success) {
  //     setFirstName('')
  //     setLastName('')
  //     setCompanyName('')
  //     setCompanyNumber('')
  //     setCardNumber('')
  //     setJobType('')
  //     setBankAccountNumber('')
  //     setCityName('')
  //     setStateName('')
  //     setAddress('')
  //     setMailCode('')
  //     setEmail('')
  //     setPhoneNumber('')
  //     setOfficeNumber('')
  //     setFaxNumber('')
  //     setVatFNumber('')
  //     setTFNumber('')
  //     setcFileNumber('')
  //     setNotes('')
  //     setShowModal(handleShowModal)
  //   }
  // }, [response.success])

  // const CreateNewCard = (e)=>{
  //   e.preventDefault()
  //   const newContact = {
  //     id: (Math.random()) * 10 ,
  //     firstName,
  //     lastName,
  //     companyName,
  //     companyNumber,
  //     cardNumber,
  //     jobType,
  //     bankAccountNumber,
  //     cityName,
  //     stateName,
  //     address,
  //     mailCode,
  //     email,
  //     phoneNumber,
  //     officeNumber,
  //     faxNumber,
  //     vatFileNumber,
  //     taxFileNumber,
  //     cutFileNumber,
  //     notes,
  //   }
  // setFirstName('')
  // setLastName('')
  // setCompanyName('')
  // setCompanyNumber('')
  // setCardNumber('')
  // setJobType('')
  // setBankAccountNumber('')
  // setCityName('')
  // setStateName('')
  // setAddress('')
  // setMailCode('')
  // setEmail('')
  // setPhoneNumber('')
  // setOfficeNumber('')
  // setFaxNumber('')
  // setVatFNumber('')
  // setTFNumber('')
  // setcFileNumber('')
  // setNotes('')

  //     console.log("new Contact Created successfully !")
  //     console.log(newContact)
  // }



  return (
    <div className="page-wrapper">
      <Helmet>
        <title>MassPortal - انشاء حساب مصاريف \ مبيعات   </title>
        <meta name="description" content="Login page" />
      </Helmet>

      {/* ---------------- MODAL ---------------- */}
      {/* <div className='left' onClick={handleShowModal} >
          <BiExit size={25}/> <span>خروج</span>
          </div>
          */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>تم انشاء الحساب  بنجاح</Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={handleCloseModal} href="/invoices-create">
            إنشاء حساب
          </Button>
          <Button variant="primary" onClick={handleCloseModal} href="/">
            الذهاب للصفحة الرئيسية
          </Button>
          <Button variant="secondary" onClick={handleCloseModal} >
            إضافة حساب جديد
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Page Content */}
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title"> انشاء حساب مصاريف \ مبيعات   </h3>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className='row search-exp'>
              <div className='helping-nav-mk'>
                <div className='right'>
                  {!isSearch && <div>
                    <a href="/client-new" className='text-success ' ><FaFileInvoiceDollar size={25} /> حساب مصاريف جديدة </a>

                    {/* <a 
                  href="create-quick-invoice" 
                  className='text-success  add-mk' 
                  
                  > <span > < BsFillFileEarmarkPersonFill size={25} /> زبون طيار </span></a> */}
                    <a
                      href="#"
                      className='text-success  m-4 add-mk'
                      onClick={() => setIsSearch(!isSearch)}
                    > <span > < BiSearchAlt size={25} /> بحث </span></a>
                  </div>
                  }
                  {isSearch &&
                    <a
                      href="#"
                      className='text-success text-right m-4 add-mk fs-5'
                      onClick={() => {
                        setIsSearch(!isSearch);
                        setInputValue('')
                      }}
                    >
                      رجوع  < BiArrowBack size={25} />
                    </a>}
                </div>
                 {/* ---------------- MODAL ---------------- */}
                 <div className='left' onClick={handleShowModal} >
                  <BiExit size={25} /> <span>خروج</span>
                </div>
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>هل تود حفظ مسودة؟</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal} href="/" >
                      خروج
                    </Button>
                    <Button variant="primary" onClick={handleCloseModal}>
                      نعم, حفظ مسودة
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal show={showModal2} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>تم انشاء الحساب  بنجاح</Modal.Body>
                  <Modal.Footer>
                    <Button variant="info" onClick={handleCloseModal} href="/invoices-create">
                      إنشاء حساب
                    </Button>
                    <Button variant="primary" onClick={handleCloseModal} href="/">
                      الذهاب للصفحة الرئيسية
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal} >
                      إضافة حساب جديد
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              {isSearch && <div>
                      <div className="form-group">
                        <input className='form-control input-mk' 
                        // onChange={(e) => {
                        //   setInputValue(e.target.value);
                        //   setFiltered([])
                        // }}
                         placeholder="ابحث " />


                      </div>
                    </div>
                    }
              {!isSearch && <div className='mb-4 input-group'>
                <a
                  href="#"
                  className='text-success  m-2  input-group-text'
                // onClick={() => {
                //   setIsAddContact(false)
                //   setIsSearch(false)
                //   setName(nam)
                //   setAddress(addres)
                //   setEmail(mail)
                //   setTaxNum(txnum)
                // }
                // }
                > <span > <BsCardList size={25} /> قائمة المصاريف  </span>
                </a>
                <div className='m-2 w-50-mk'>
                  <div>
                    <select className="select select-sales-mk ">
                      <option > مشتريات </option>
                      <option > مصاريف الهاتف </option>
                      <option > مصاريف العقود </option>
                    </select>
                  </div>
                </div>
              </div>}
              <br></br>
            </div>
            <form
            //  onSubmit={CreateNewCard}
            >
              <div className="row">
                   <div className='col-sm-12'>
                   <br></br><hr></hr><br></br>
                   </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label> اسم الحساب<span className="text-danger">*</span></label>
                    <input className="form-control"
                      type="text"
                      // required
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    // placeholder= {document?document.companyName: " "}
                    // placeholder={document.companyName}
                    // value={updateDocument.companyName} 
                    />
                  </div>
                </div>
                <div className=" col-sm-6  form-group form-focus select-focus  w-100" >
                  <label> نوع الحساب <span className="text-danger">*</span></label>

                  <select id="currency-two"
                    className='select floating ex-select w-100'
                  >
                    <option>مبيعات  </option>
                    <option> كلفة مبيعات </option>
                    <option> مصاريف تشغيلية </option>
                    <option>مصاريف ادارية </option>
                    <option>مصاريف تمويل  </option>
                    <option>مصاريف ضريبة الدخل  </option>
                    <option> مصاريف تأمين وطني  </option>
                    <option>  أصول ثابتة </option>
                    <option>  رصيد افتتاحي </option>
                  </select>
                </div>

                {/* <div className="col-sm-6">
                    <div className="form-group">
                      <label>اسم العائلة</label>
                      <input className="form-control "
                       onChange={(e) => setLastName(e.target.value)}
                       value={lastName}
                      //placeholder= {document?document.userName: " "}
                      //  required
                       type="text" />
                    </div>
                  </div> */}
              </div>
              <div className='row ' style={{ marginBottom: "20px" }}>
                <div className=" col-sm-6  form-group form-focus select-focus  w-100">
                  <label> حساب يشترك في <span className="text-danger">*</span></label>

                  <select id="currency-two"
                    className='select floating ex-select w-100'
                  >
                    <option>يشترك في ضريبة  المبيعات   </option>
                    <option> يشترك في الضريبة العادية  </option>
                    <option> يشترك في ضريبة الأصول الثابتة  </option>
                  </select>
                </div>
                <div className=" col-sm-6  form-group form-focus select-focus  w-100" >
                  <label> يشترك في حساب تدفق النقد  <span className="text-danger">*</span></label>

                  <select id="currency-two"
                    className='select floating ex-select w-100'
                  >
                    <option>نعم  </option>
                    <option> لا  </option>

                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>رقم الحساب <span className="text-danger">*</span></label>
                    <input className="form-control"
                      type="text"
                      value="85000"
                    // required
                    // onChange={(e) => setCardNumber(e.target.value)}
                    // value={cardNumber}
                    //placeholder= {document?document.companyName: " "}
                    // placeholder={document.companyName}
                    // value={updateDocument.companyName} 
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>تاريخ الانشاء</label>
                    <input className="form-control "
                      onChange={(e) => setJobType(e.target.value)}
                      value={jobType}
                      // placeholder= {document?document.userName: " "}
                      //  required
                      type="text" />
                  </div>
                </div>
                <div className=" col-md-12 col-lg-4">
                  <div className="form-group">
                    <label>تاريخ التعديل </label>
                    <input className="form-control"
                      type="number"
                      onChange={(e) => (setBankAccountNumber(e.target.value))}
                      value={bankAccountNumber}
                    //  placeholder= {document?document.cutFileNumber: " "}

                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>رصيد الحساب </label>
                    <input className="form-control"
                      type="text"
                      // required
                      onChange={(e) => setCityName(e.target.value)}
                      value={cityName}
                    // placeholder= {document?document.cityName: " "}

                    />
                  </div>
                </div>

                <div className=" col-sm-6  form-group form-focus select-focus  w-100" >
                  <label> العملة الرئيسية للحساب   <span className="text-danger">*</span></label>

                  <select id="currency-two"
                    className='select floating ex-select w-100'
                  >
                    <option value="AUD">  دولار استرالي ( AUD ) </option>
                    <option value="BHD">دينار بحريني  ( BHD )</option>
                    <option value="BEF">فرنك بلجيكي ( BEF ) </option>
                    <option value="BRL">ريال برازيلي ( BRL ) </option>
                    <option value="GBP">الجنيه الاسترليني ( GBP ) </option>
                    <option value="CAD">دولار كندي ( CAD ) </option>
                    <option value="CNY">اليوان الصيني ( CNY )</option>
                    <option value="DKK">كرون دنماركي ( DKK )</option>
                    <option value="EGP">الجنيه المصري ( EGP )</option>
                    <option value="EUR">اليورو ( EUR )</option>
                    <option value="DEM">المارك الألماني ( DEM )</option>
                    <option value="INR">الروبية الهندية ( INR )</option>
                    <option value="IQD">الدينار العراقي ( IQD )</option>
                    <option value="ILS">شيكل إسرائيلي ( ILS )</option>
                    <option value="ITL">ليرة ايطالية ( ITL )</option>
                    <option value="JPY">الين الياباني ( JPY )</option>
                    <option value="JOD">دينار أردني ( JOD )</option>
                    <option value="KWD">دينار كويتي ( KWD )</option>
                    <option value="LBP">ليرة لبنانية ( LBP )</option>
                    <option value="LYD">دينار ليبي ( LYD )</option>
                    <option value="MXN">بيزو مكسيكي ( MXN )</option>
                    <option value="MAD">درهم مغربي ( MAD )</option>
                    <option value="NZD">الدولار النيوزيلندي ( NZD )</option>
                    <option value="OMR">ريال عماني ( OMR )</option>
                    <option value="QAR">ريال قطري ( QAR )</option>
                    <option value="RUB">روبل روسي ( RUB )</option>
                    <option value="SAR">الريال السعودي ( SAR )</option>
                    <option value="SYP">الليرة السورية ( SYP )</option>
                    <option value="TND">دينار تونسي ( TND )</option>
                    <option value="TRY">الليرة التركية ( TRY )</option>
                    <option value="UAH">الهريفنيا الأوكراني ( UAH )</option>
                    <option value="AED">درهم اماراتى ( AED )</option>
                    <option value="USD">الدولار الأمريكي ( USD )</option>
                    <option value="YER">ريال يمني ( YER )</option>
                  </select>
                </div>
                <div className=" col-sm-6  form-group form-focus select-focus  w-100" >
                  <label> مشروع   <span className="text-danger">*</span></label>

                  <select id="currency-two"
                    className='select floating ex-select w-100'
                  >
                    <option>الأول  </option>
                    <option> الثاني  </option>
                    <option> الثالث   </option>
                  </select>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>تم انشاء الحساب على يد  </label>
                    <input className="form-control"
                      type="text"
                      onChange={(e) => setMailCode(e.target.value)}
                      value={mailCode}
                      placeholder={document ? document.mailCode : " "}
                    // required
                    />
                    {/* <select className="form-control select">
                       <option>California</option>
                       <option>Alaska</option>
                       <option>Alabama</option>
                     </select> */}
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="form-group">
                      <label>صندوق البريد </label>
                      <input className="form-control"  
                      type="text"
                      onChange={ (e) => setMailBox(e.target.value)}
                      value={mailBox}
                       />
                    </div>
                  </div> */}
              </div>
              <div className="row">
                <div className=" col-sm-6  form-group form-focus select-focus  w-100" >
                  <label> معفي من ضريبة القيمة المضافة   <span className="text-danger">*</span></label>

                  <select id="currency-two"
                    className='select floating ex-select w-100'
                  >
                    <option>نعم  </option>
                    <option> لا  </option>

                  </select>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>رقم حساب في نظام خارجي </label>
                    <input className="form-control"
                      type="text"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      placeholder={document ? document.phoneNumber : " "}
                    // required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>تعريف  أخر  </label>
                    <input className="form-control"
                      type="text"
                      onChange={(e) => setOfficeNumber(e.target.value)}
                      value={officeNumber}
                      placeholder={document ? document.officeNumber : " "}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>تعريف  أخر</label>
                    <input className="form-control"
                      type="text"
                      onChange={(e) => setFaxNumber(e.target.value)}
                      value={faxNumber}
                      placeholder={document ? document.faxNumber : " "}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>تعريف  أخر </label>
                    <input className="form-control"
                      type="number"
                      onChange={(e) => setTFNumber(e.target.value)}
                      value={taxFileNumber}
                      placeholder={document ? document.taxFileNumber : " "}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>تعريف  أخر </label>
                    <input className="form-control"
                      type="number"
                      onChange={(e) => setVatFNumber(e.target.value)}
                      value={vatFileNumber}
                      placeholder={document ? document.vatFileNumber : " "}
                    />
                  </div>
                </div>
                <div className=" col-md-12 col-lg-4">
                  <div className="form-group">
                    <label>تعريف  أخر </label>
                    <input className="form-control"
                      type="number"
                      onChange={(e) => (setcFileNumber(e.target.value))}
                      placeholder={document ? document.cutFileNumber : " "}
                      value={cutFileNumber}

                    />
                  </div>
                </div>


              </div>
              {/* <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Website Url</label>
                      <input className="form-control" defaultValue="https://www.example.com" type="text" />
                    </div>
                  </div>
                </div> */}
              <hr />
              <div className='form-group'>
                <label>ملاحظات :-</label>
                <div style={{ border: "1px solid black", padding: '10px', minHeight: '200px', backgroundColor: "#16191c" }}>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="rdw-editor-toolbar"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"

                    onEditorStateChange={handleEditorChange}
                  />
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                //  onClick={CreateNewCard}
                >انشاء  </button>
                <a href='#' className="btn btn-primary submit-btn">تعديل   </a>
                <a href='#' className="btn btn-primary submit-btn">حذف  </a>

              </div>

            </form>
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>
  );
}

