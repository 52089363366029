

import React from "react";
import massShebah from "../real-state-shebah/real-Shebah"
//
import { FaInfo } from 'react-icons/fa'
import ReactTooltip from "react-tooltip";
export function TxTblRealShebah(props) {
    function commify(n) {
        var parts = n.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    }
    return (<div className="card ">

        <div className="card-body tbl-cdb">
            <div className="card-header">
                <a href="#" className="btn add-btn " data-toggle="modal" data-target="#add_employee"><FaInfo /> توضيح</a>
                <h4 className="card-title mb-0 text-center privacy">نسب الضرائب المطبقة على التقدير الحقيقي </h4>
                {/* Modal Button */}
                {/* Modal Button */}
            </div>
            <div className="table-responsive">
                <table className="table table-nowrap table-striped mb-0">
                    <thead>

                        <tr >

                            <th  className='table-tr-underline bold  privacy'>  فرد</th>
							<th></th><th></th>
							<th></th><th></th>
                            <th  className='table-tr-underline bold  privacy'>  شركة </th>
							<th></th>
							<th></th>
							<th></th>
                        </tr>
                        {/* <tr > 
             <th></th>
             <th  className='table-tr-underline bold'>  %</th>
             <th  className='table-tr-underline bold'>  %</th>
             <th  className='table-tr-underline bold'>  %</th>
             <th  className='table-tr-underline bold'>  %</th>
             <th></th>
             <th  className='table-tr-underline bold'>  %</th>
             <th  className='table-tr-underline bold'>  %</th>
             <th  className='table-tr-underline bold'>  %</th>
            
         
         </tr> */}


                    </thead>
                    <thead>

                        <tr>
                            <th className="privacy">التاريخ</th>

                            <th data-tip data-for="x-tip"> تقدير حقيقي حتى البداية</th>
                            <th > تقدير حقيقي حتى التغيير</th>
                            <th> متبقي التقدير الحقيقي</th>
                            <th> مبلغ التضخم المالي الملزم  </th>


                            <th className="privacy">التاريخ</th>
                            <th> تقدير حقيقي حتى البداية</th>
                            <th> متبقي التقدير الحقيقي</th>

                            <th> مبلغ التضخم المالي الملزم  </th>



                            {/* <th>Country</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {

                            massShebah.massShebah.map((d) =>
                                <tr>
                                    <th className="privacy">        {d.date}</th>

                                    <th>  % {commify(d.human.band1.maxRate)}</th>
                                    <th > %{d.human.band1.minRate}</th>

                                    <th>  % {commify(d.human.band1.idk)}</th>
                                    <th > %{d.human.band1.inflonsiRate}</th>
                                    <th className="privacy">        {d.date}</th>

                                    <th>   %{commify(d.company.band1.maxRate)}</th>
                                    <th > %{commify(d.company.band1.minRate)}</th>

                                    <th>   %{commify(d.company.band1.inflonsiRate)}</th>



                                </tr>
                            )


                        }

                        {/* <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr>
 <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr>
 <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr> */}
                    </tbody>
                </table>

                <ReactTooltip id="x-tip" place="top" effect="solid">
                    من 6 - 11 - 2001
                </ReactTooltip>

            </div>
        </div>
        
                {/* Explaine Modal */}
						<div id="add_employee" className="modal custom-modal fade" role="dialog">
							<div className="modal-dialog modal-dialog-centered modal-lg">
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title title-underline">ايضاح <span className="privacy"> שבח </span> </h5>
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">×</span>
										</button>
									</div>
									<div className="  modal-body">
										<div className='row'>
											<div className='col-sm-12 col-md-12'>

											
											<ul className='privacy-ol privacy-ol-large'>
												<li className='modal-title'>وفقًا للمادة 345 من قانون التأمين الوطني</li>
												<ul>
													<li>
														<p>
															يتم تحديد دخل الشخص الذي يعمل لحسابه الخاص في
															العام الحالي وفقًا للتقييم النهائي للدخل لتلك السنة ، قبل أي إعفاء واستقطاعات و ائتمانات يحق للشخص بموجبها قانون ضريبة الدخل.
														</p>
													</li>
													<li>
														<p>
															52 % من مبلغ التأمين الوطني  المستحقة على المؤمن عليه (باستثناء رسوم التأمين الصحي).
															يعترف به مصروف لغاية احتساب ضريبة الدخل
														</p>
													</li>
												</ul>
												<li className='modal-subtitle '>مثال على احتساب التأمين الوطني والتأمين الصحي  للمؤمن الذي يعمل لحسابه الخاص .</li>
												<div className='col-sm-12 '>
												<ul className='privacy-ol privacy-ol-large'>
													<li>
														<p>
															إذا كان دخله الشهري هو <span className='privacy-bold'> 10،000 </span> شيكل في سنة <span className='privacy-bold'> 2022 </span>:
														</p>
													</li>
													<ul className='privacy-ol border  '>
														<br></br>
														<li>	<h4> التأمين الوطني </h4></li>
														<ol className='privacy-ol col-sm-12'>
															<li>
																<p>
																	الدخل الشهري - 10،000 شيكل

																</p>
															</li>
															<li>
																<p>
																	يتم دفع  <span className='privacy-bold'>   2.87 ٪ </span> من دخل 1 شيكل و حتى 6331

																</p>
															</li>
															<li>
																<p>
																	يتم دفع <span className='privacy-bold '>  12.83 ٪  </span>من دخل 6332  شيكل وحتى 45075 ما فوق هذا المبلغ <span className='privacy-bold '> يعفى </span> من التأمين الوطني.
																</p>
															</li>
														</ol>
													</ul>
													<ul className='privacy-ol border '>
														<br></br>
														<li>	<h4> التأمين الصحي </h4></li>
														<ol className='privacy-ol'>
															<li>
																<p>
																	الدخل الشهري - 10،000 شيكل
																</p>
															</li>
															<li>
																<p>
																	يتم دفع <span className='privacy-bold '>   3.1 ٪ </span> من دخل 1 شيكل و حتى 6331
																</p>
															</li>
															<li>
																<p>
																	يتم دفع  <span className='privacy-bold'> 5  ٪ </span> من دخل 6332  شيكل وحتى 45075 ما فوق هذا المبلغ <span className='privacy-bold '> يعفى </span> من التأمين الصحي.
																</p>
															</li>
														</ol>
													</ul>
												</ul>
												</div>
											</ul>
											</div>
										</div>
										{/* <form>
											<div className="submit-section">
												<button className="btn btn-primary submit-btn">اغلاق   </button>
											</div>
										</form> */}
									</div>
								</div>
							</div>
						</div>
						{/* / Explaine Modal */}
    </div>)
}
