let taxData = [ 
  {
    taxYear: 2023,
    creditPoint: 235,
    recovery: {
       private:   {
         recDay: 378,
            bandP1: {
              day : 0,
              min : 0,
              max : 1
            },
            bandP2: {
              day : 5,
              min : 1,
              max : 2
            },
            bandP3: {
              day : 6,
              min : 2,
              max : 4
            },
            bandP4: {
              day : 7,
              min : 4,
              max : 11
            },
            bandP5: {
              day : 8,
              min : 11,
              max : 16
            },
            bandP6: {
              day : 9,
              min : 16,
              max : 20
            },
            bandP7: {
              day : 10,
              min : 20,
              
            }},
     public:  { 
        recDay:449,
            band1: {
              day : 0,
              min : 0,
              max : 1
              },
            band2: {
              day : 7,
              min : 1,
              max : 4
            },
            band3: {
              day : 9,
              min : 4,
              max : 11
            },
            band4: {
              day : 10,
              min : 11,
              max : 16
            },
            band5: {
              day : 11,
              min : 16,
              max : 20
            },
            band6: {
              day : 12,
              min : 20,
              max : 25
            },
            band7: {
              day : 13,
              min : 25,
              
            }}
     


    },
    incomeTax: {
      band1: {
        rate: 10,
        min: 0,
        max: 81480
      },
      band2: {
        rate: 14,
        min: 81481,
        max: 116760
      },
      band3: {
        rate: 20,
        min: 116761,
        max: 187440
      },
      band4: {
        rate: 31,
        min: 187441,
        max: 260520
      },
      band5: {
        rate: 35,
        min: 260521,
        max: 542160
      },
      band6: {
        rate: 47,
        min: 542161,
        max: 698280
      },
      band7: {
        rate: 50,
        min: 698281
      },
      massCompany:0.23
    },
    bituachLeumi: {
      averageSalary: 10551,
      selfEmployedNationalInsuranceDiscount: 52,
      nationalInsurance: {
        band1: {
          rate: {
            employee: 0.4,
            selfEmployed: 2.87
          },
          min: 0,
          massMizincome:2968,
          max: 7122,
        },
        band2: {
          rate: {
            employee: 7,
            selfEmployed: 12.83
          },
          min: 7123,
          max: 47465
        }
      },
      healthInsurance: {
        band1: {
          rate: {
            employee: 3.1,
            selfEmployed: 3.1
          },
          min: 0,
          max: 7122
        },
        band2: {
          rate: {
            employee: 5,
            selfEmployed: 5
          },
          min: 7123,
          max: 47465
        }
      }
    },
    pension: {
      legalMin: {
        employee: 6,
        selfEmployed: {
          reducedRate: 4.45,
          fullRate: 12.55
        }
      },
      taxRelief: {
        employee: {
          percentLimit: 7,
          shekelLimit: 623,
          taxCreditRate: 35
        },
        selfEmployed: {
          taxDeductableLimit: 11,
          taxCreditLimit: 5.5,
          taxCreditRate: 35,
          eligibleIncome: 151200,
          ceiling: 213600
        }
      }
    },
    studyFund: {
      employee: {
        rate: 2.5,
        ceiling: 188544
      },
      employer: {
        rate: 7.5,
        ceiling: 188544
      },
      selfEmployed: {
        rate: 4.5,
        ceiling: 270000
      }
    },
    massHousRent:5200,
    massBlindIncome:52400,
    massVatExempt:102293,
  },
   {
      taxYear: 2022,
      creditPoint: 223,
      recovery: {
         private:   {
           recDay: 378,
              bandP1: {
                day : 0,
                min : 0,
                max : 1
              },
              bandP2: {
                day : 5,
                min : 1,
                max : 2
              },
              bandP3: {
                day : 6,
                min : 2,
                max : 4
              },
              bandP4: {
                day : 7,
                min : 4,
                max : 11
              },
              bandP5: {
                day : 8,
                min : 11,
                max : 16
              },
              bandP6: {
                day : 9,
                min : 16,
                max : 20
              },
              bandP7: {
                day : 10,
                min : 20,
                
              }},
       public:  { 
          recDay:449,
              band1: {
                day : 0,
                min : 0,
                max : 1
                },
              band2: {
                day : 7,
                min : 1,
                max : 4
              },
              band3: {
                day : 9,
                min : 4,
                max : 11
              },
              band4: {
                day : 10,
                min : 11,
                max : 16
              },
              band5: {
                day : 11,
                min : 16,
                max : 20
              },
              band6: {
                day : 12,
                min : 20,
                max : 25
              },
              band7: {
                day : 13,
                min : 25,
                
              }}
       


      },
      incomeTax: {
        band1: {
          rate: 10,
          min: 0,
          max: 77400
        },
        band2: {
          rate: 14,
          min: 77401,
          max: 110880
        },
        band3: {
          rate: 20,
          min: 110881,
          max: 178080
        },
        band4: {
          rate: 31,
          min: 178081,
          max: 247440
        },
        band5: {
          rate: 35,
          min: 247441,
          max: 514920
        },
        band6: {
          rate: 47,
          min: 502921,
          max: 663240
        },
        band7: {
          rate: 50,
          min: 663241
        },
        massCompany:0.23
      },
      bituachLeumi: {
        averageSalary: 10551,
        selfEmployedNationalInsuranceDiscount: 52,
        nationalInsurance: {
          band1: {
            rate: {
              employee: 0.4,
              selfEmployed: 2.87
            },
            min: 0,
            massMizincome:2638,
            max: 6331,
          },
          band2: {
            rate: {
              employee: 7,
              selfEmployed: 12.83
            },
            min: 6332,
            max: 45075
          }
        },
        healthInsurance: {
          band1: {
            rate: {
              employee: 3.1,
              selfEmployed: 3.1
            },
            min: 0,
            max: 6331
          },
          band2: {
            rate: {
              employee: 5,
              selfEmployed: 5
            },
            min: 6332,
            max: 45075
          }
        }
      },
      pension: {
        legalMin: {
          employee: 6,
          selfEmployed: {
            reducedRate: 4.45,
            fullRate: 12.55
          }
        },
        taxRelief: {
          employee: {
            percentLimit: 7,
            shekelLimit: 623,
            taxCreditRate: 35
          },
          selfEmployed: {
            taxDeductableLimit: 11,
            taxCreditLimit: 5.5,
            taxCreditRate: 35,
            eligibleIncome: 151200,
            ceiling: 213600
          }
        }
      },
      studyFund: {
        employee: {
          rate: 2.5,
          ceiling: 188544
        },
        employer: {
          rate: 7.5,
          ceiling: 188544
        },
        selfEmployed: {
          rate: 4.5,
          ceiling: 270000
        }
      },
      massHousRent:5200,
      massBlindIncome:52400,
      massVatExempt:102293,
    },
    {
      taxYear: 2021,
      Recovery: {
        private: {
         recDay: 378,
               bandP1: {
                 day : 0,
                 min : 0,
                 max : 1
               },
               bandP2: {
                 day : 5,
                 min : 1,
                 max : 2
               },
               bandP3: {
                 day : 6,
                 min : 2,
                 max : 4
               },
               bandP4: {
                 day : 7,
                 min : 4,
                 max : 11
               },
               bandP5: {
                 day : 8,
                 min : 11,
                 max : 16
               },
               bandP6: {
                 day : 9,
                 min : 16,
                 max : 20
               },
               bandP7: {
                 day : 10,
                 min : 20,
                 
               }},
        public:  { 
           recDay:432,
               band1: {
                 day : 0,
                 min : 0,
                 max : 1
                 },
               band2: {
                 day : 7,
                 min : 1,
                 max : 4
               },
               band3: {
                 day : 9,
                 min : 4,
                 max : 11
               },
               band4: {
                 day : 10,
                 min : 11,
                 max : 16
               },
               band5: {
                 day : 11,
                 min : 16,
                 max : 20
               },
               band6: {
                 day : 12,
                 min : 20,
                 max : 25
               },
               band7: {
                 day : 13,
                 min : 25,
                 
               }}
        
 
 
       },
      creditPoint: 218,
      incomeTax: {
        band1: {
          rate: 10,
          min: 0,
          max: 75480
        },
        band2: {
          rate: 14,
          min: 75481,
          max: 108360
        },
        band3: {
          rate: 20,
          min: 108361,
          max: 173880
        },
        band4: {
          rate: 31,
          min: 173881,
          max: 241680
        },
        band5: {
          rate: 35,
          min: 241681,
          max: 502920
        },
        band6: {
          rate: 47,
          min: 502921,
          max: 647640
        },
        band7: {
          rate: 50,
          min: 647641
        },
        massCompany:0.23
      },
      bituachLeumi: {
        averageSalary: 10551,
        selfEmployedNationalInsuranceDiscount: 52,
        nationalInsurance: {
          band1: {
            rate: {
              employee: 0.4,
              selfEmployed: 2.87
            },
            min: 0,
            massMizincome:2638,
            max: 6331
          },
          band2: {
            rate: {
              employee: 7,
              selfEmployed: 12.83
            },
            min: 6332,
            max: 44020
          }
        },
        healthInsurance: {
          band1: {
            rate: {
              employee: 3.1,
              selfEmployed: 3.1
            },
            min: 0,
            max: 6331
          },
          band2: {
            rate: {
              employee: 5,
              selfEmployed: 5
            },
            min: 6332,
            max: 44020
          }
        }
      },
      pension: {
        legalMin: {
          employee: 6,
          selfEmployed: {
            reducedRate: 4.45,
            fullRate: 12.55
          }
        },
        taxRelief: {
          employee: {
            percentLimit: 7,
            shekelLimit: 609,
            taxCreditRate: 35
          },
          selfEmployed: {
            taxDeductableLimit: 11,
            taxCreditLimit: 5.5,
            taxCreditRate: 35,
            eligibleIncome: 147600,
            ceiling: 208800
          }
        }
      },
      studyFund: {
        employee: {
          rate: 2.5,
          ceiling: 188544
        },
        employer: {
          rate: 7.5,
          ceiling: 188544
        },
        selfEmployed: {
          rate: 4.5,
          ceiling: 263000
        }
      },
      massHousRent:5074,
      massBlindIncome:51200,
      massVatExempt:99893,

    },
    {
      taxYear: 2020,
      Recovery: {
        private: {
         recDay: 378,
               bandP1: {
                 day : 0,
                 min : 0,
                 max : 1
               },
               bandP2: {
                 day : 5,
                 min : 1,
                 max : 2
               },
               bandP3: {
                 day : 6,
                 min : 2,
                 max : 4
               },
               bandP4: {
                 day : 7,
                 min : 4,
                 max : 11
               },
               bandP5: {
                 day : 8,
                 min : 11,
                 max : 16
               },
               bandP6: {
                 day : 9,
                 min : 16,
                 max : 20
               },
               bandP7: {
                 day : 10,
                 min : 20,
                 
               }},
        public:  { 
           recDay:429,
               band1: {
                 day : 0,
                 min : 0,
                 max : 1
                 },
               band2: {
                 day : 7,
                 min : 1,
                 max : 4
               },
               band3: {
                 day : 9,
                 min : 4,
                 max : 11
               },
               band4: {
                 day : 10,
                 min : 11,
                 max : 16
               },
               band5: {
                 day : 11,
                 min : 16,
                 max : 20
               },
               band6: {
                 day : 12,
                 min : 20,
                 max : 25
               },
               band7: {
                 day : 13,
                 min : 25,
                 
               }}
        
 
 
       },
        creditPoint: 219,
        incomeTax: {
          band1: {
            rate: 10,
            min: 0,
            max: 75960
          },
          band2: {
            rate: 14,
            min: 75961,
            max: 108960
          },
          band3: {
            rate: 20,
            min: 108961,
            max: 174960
          },
          band4: {
            rate: 31,
            min: 174961,
            max: 243120
          },
          band5: {
            rate: 35,
            min: 243121,
            max: 505920
          },
          band6: {
            rate: 47,
            min: 505921,
            max: 651600
          },
          band7: {
            rate: 50,
            min: 651601
          },
          massCompany:0.23
        },
        bituachLeumi: {
          averageSalary: 10551,
          selfEmployedNationalInsuranceDiscount: 52,
          nationalInsurance: {
            band1: {
              rate: {
                employee: 0.4,
                selfEmployed: 2.87
              },
              min: 0,
              massMizincome:2638,
              max: 6331
            },
            band2: {
              rate: {
                employee: 7,
                selfEmployed: 12.83
              },
              min: 6332,
              max: 44020
            }
          },
          healthInsurance: {
            band1: {
              rate: {
                employee: 3.1,
                selfEmployed: 3.1
              },
              min: 0,
              max: 6331
            },
            band2: {
              rate: {
                employee: 5,
                selfEmployed: 5
              },
              min: 6332,
              max: 44020
            }
          }
        },
        pension: {
          legalMin: {
            employee: 6,
            selfEmployed: {
              reducedRate: 4.45,
              fullRate: 12.55
            }
          },
          taxRelief: {
            employee: {
              percentLimit: 7,
              shekelLimit: 616,
              taxCreditRate: 35
            },
            selfEmployed: {
              taxDeductableLimit: 11,
              taxCreditLimit: 5.5,
              taxCreditRate: 35,
              eligibleIncome: 148800,
              ceiling: 211200
            }
          }
        },
        studyFund: {
          employee: {
            rate: 2.5,
            ceiling: 188544
          },
          employer: {
            rate: 7.5,
            ceiling: 188544
          },
          selfEmployed: {
            rate: 4.5,
            ceiling: 265000
          }
        },
        massHousRent:5105,
        massBlindIncome:51500,
        massVatExempt:100491,

      },
    {
      taxYear: 2019,
      Recovery: {
        private: {
         recDay: 378,
               bandP1: {
                 day : 0,
                 min : 0,
                 max : 1
               },
               bandP2: {
                 day : 5,
                 min : 1,
                 max : 2
               },
               bandP3: {
                 day : 6,
                 min : 2,
                 max : 4
               },
               bandP4: {
                 day : 7,
                 min : 4,
                 max : 11
               },
               bandP5: {
                 day : 8,
                 min : 11,
                 max : 16
               },
               bandP6: {
                 day : 9,
                 min : 16,
                 max : 20
               },
               bandP7: {
                 day : 10,
                 min : 20,
                 
               }},
        public:  { 
           recDay:432,
               band1: {
                 day : 0,
                 min : 0,
                 max : 1
                 },
               band2: {
                 day : 7,
                 min : 1,
                 max : 4
               },
               band3: {
                 day : 9,
                 min : 4,
                 max : 11
               },
               band4: {
                 day : 10,
                 min : 11,
                 max : 16
               },
               band5: {
                 day : 11,
                 min : 16,
                 max : 20
               },
               band6: {
                 day : 12,
                 min : 20,
                 max : 25
               },
               band7: {
                 day : 13,
                 min : 25,
                 
               }}
        
 
 
       },
      creditPoint: 218,
      incomeTax: {
        band1: {
          rate: 10,
          min: 0,
          max: 75720
        },
        band2: {
          rate: 14,
          min: 75721,
          max: 108600
        },
        band3: {
          rate: 20,
          min: 108601,
          max: 174360
        },
        band4: {
          rate: 31,
          min: 174361,
          max: 242400
        },
        band5: {
          rate: 35,
          min: 242401,
          max: 504360
        },
        band6: {
          rate: 47,
          min: 504361,
          max: 649560
        },
        band7: {
          rate: 50,
          min: 649561
        },
        massCompany:0.23
      },
      bituachLeumi: {
        averageSalary: 10273,
        selfEmployedNationalInsuranceDiscount: 52,
        nationalInsurance: {
          band1: {
            rate: {
              employee: 0.4,
              selfEmployed: 2.87
            },
            min: 0,
            massMizincome:2568,
            max: 6164
          },
          band2: {
            rate: {
              employee: 7,
              selfEmployed: 12.83
            },
            min: 6165,
            max: 43890
          }
        },
        healthInsurance: {
          band1: {
            rate: {
              employee: 3.1,
              selfEmployed: 3.1
            },
            min: 0,
            max: 6164
          },
          band2: {
            rate: {
              employee: 5,
              selfEmployed: 5
            },
            min: 6165,
            max: 43890
          }
        }
      },
      pension: {
        legalMin: {
          employee: 6,
          selfEmployed: {
            reducedRate: 4.45,
            fullRate: 12.55
          }
        },
        taxRelief: {
          employee: {
            percentLimit: 7,
            shekelLimit: 616,
            taxCreditRate: 35
          },
          selfEmployed: {
            taxDeductableLimit: 11,
            taxCreditLimit: 5.5,
            taxCreditRate: 35,
            eligibleIncome: 148800,
            ceiling: 211200
          }
        }
      },
      studyFund: {
        employee: {
          rate: 2.5,
          ceiling: 188544
        },
        employer: {
          rate: 7.5,
          ceiling: 188544
        },
        selfEmployed: {
          rate: 4.5,
          ceiling: 264000
        }
      },
      massHousRent:5089,
      massBlindIncome:51300,
      massVatExempt:100187,
    },
  ]

  
  export default {taxData}