/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export default class LawDM extends Component {
	render() {
		return (
			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | التمييز بين موظف شهري و موظف يومي      </title>
					<meta name="description" content="MassPortal - التمييز بين موظف شهري و موظف يومي  " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>التمييز بين موظف شهري و موظف يومي    </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>
						<div className="row">
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<p>
									لقد ميزت قوانين العمل بين نوعين من الموظفين الاول هو <span className='privacy'> ( موظف براتب )  </span>والثاني <span className='privacy'> ( موظف باجرة )  </span>والقصد من موظف باجرة هو الموظف الذي يعمل بمياومة او حسب الساعة, والسبب هو الفرق بين حقوق هؤلاء الموظفين لطبيعة عملهم, براتب او باجرة.

									ومع هذا يجب الاشارة ان قوانين العمل لا تحوي تعريف مفسر لانواع الموظفين وفقط موجود التعريف الذي يميز موظف براتب وموظف باجرة, والاول هو موظف الذي يتقاضى راتب على اساس شهري, والثاني هو موظف الذي يتقاضى بدل عمله كله او قسم منه ويدفع على اساس غير شهري.


								</p>
								{/* <h3 >تعويضات نهاية الخدمة : </h3> */}
								<br></br>
								<ul className='privacy-ol'>
									<li>
										<h4 className='privacy'>موظف براتب : <br></br></h4>
										<ul className='privacy-ol'>
											<li>هو موظف الذي يتقاضى راتبه كل شهر بنسبة ثابتة وبدون ان تتعلق الدفعة بعدد ايام الشهر </li>
											<li>والقصد ان الموظف يتقاضى بدل راتب نفس المبلغ اذا كان المبلغ مدفوع بدل شهر الذي عدد ايامه 28 يوم او 31 يوم وهذا بشرط ان الموظف لم ينقص من ساعات او ايام عمله التي التزم بها.</li>
										</ul>
									</li>
									<li>
									<h4 className='privacy'>موظف بأجرة ( يومي أو حسب الساعة ) : <br></br></h4>
										<ul className='privacy-ol'>
											<li>هو موظف الذي يتقاضى راتبه حسب الساعات او الايام التي عمل بها فعليا وهنا يمكن ان يتغير الراتب من شهر الى شهر حسب الساعات او الايام التي عمل بها الموظف فعليا.</li>
											<li> وهنا يجب الاشارة ان طريقة احتساب الراتب يجب ان تكون موحدة والقصد انه لا يمكن ان تحدد راتب شهري للموظف ويتم الدفع له حسب ساعات عمله التي عملها فعليا في نفس الشهر, عندما تتغير قيمة ساعة العمل من شهر الى اخر وعندما يريد صاحب العمل ان يدفع للموظف راتبه حسب الساعة يجب ان تكون قيمة ساعة العمل ثابتة.</li>
										</ul>
									</li>
								</ul>
								<h4 ><b> كيف يتم التمييز بين موظف يومي وموظف شهري وما هو الفرق في حقوق هؤلاء الموظفين؟ </b> </h4>
								<br></br>
								<ul className='privacy-ol'>
									<li>
										<h4 className='privacy'>الفروقات هي في  : <br></br></h4>
										<ol className='privacy-ol'>
											<li>احتساب قيمة الاجازة السنوية.</li>
											<li>احتساب قيمة الاجازة المرضية.</li>
											<li>الاعلام المسبق.</li>
											<li>دفعات بدل ايام العيد.</li>
											<li>قيمة راتب الحد الادنى.</li>
										</ol>
									</li>
									
								</ul>
								
								<h4 ><b> تفصيل قيمة راتب الحد الادنى:</b> </h4>
								<br></br>
								<ul className='privacy-ol'>
									<li>موظف شهري الذي يعين على اساس راتب حد ادنى يحق له قيمة راتب حد ادنى للشهر, موظف يومي يحق له قيمة راتب حد ادنى لليوم, وموظف يعمل حسب الساعة يحق له قيمة راتب حد ادنى للساعة</li>
									<li>وهنا يجب لفت الانتباه انه اذا عمل ثلاثة انواع موظفين انفي الذكر نفس عدد الساعات في الشهر يمكن ان يتقاضون مبالغ بدل رواتب بقيم مختلفة وليس بالشرط ان يكون نفس قيمة الراتب.</li>
									<li>
									<p className='privacy'>معطيات عن قيمة راتب الحد الادنى : </p>
									<ul className='privacy-ol'>
									
										<li>
										قانون راتب الحد الادنى (1987) هو قانون اجتماعي الذي يحدد حق الموظف ان يتقاضى على الاقل الحد الادنى من الرواتب للشهر, والقانون يحدد انه اذا لم يدفع صاحب العمل على الاقل الحد الادنى من الرواتب للموظف فهناك مخالفة جنائية.
										</li>
									</ul>


									</li>
								</ul>

								<br></br>
								<h4><b> <h4 ><b> معطيات قيمة راتب الحد الادنى <span className='privacy'> ش.ج </span> </b> </h4> </b></h4>
							</div>

							<div className="col-lg-10">
								<br></br>
								<div className="card">
									<div className="card-header">
										
										<h5 className="card-title mb-0 text-center privacy">معطيات قيمة راتب الحد الادنى </h5>
									
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead >
													<tr >
														<th>تاريخ التعديل  </th>
														<th > شهري   </th>
														<th>مياومة <span className='privacy'> 5 </span> أيام </th>
														<th>مياومة <span className='privacy'> 6 </span> أيام </th>
														<th> ساعة </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td> 4 / 2022  </td>
														<td>5.300 </td>
														<td>244.62 </td>
														<td>212</td>
														<td>29.12</td>
													</tr>
													
												</tbody>
											</table>
										</div>
									</div>
									
									
								</div>
								<ol className='privacy-ol'>
										<li>
											<p>حسب قانون راتب الحد الادنى تم تحديد الحد الادنى لنوعين من الموظفين الذين يعملون بمياومة الاول يعمل 5 ايام والثاني يعمل 6 ايام, واحتساب اليوم هو اخذ راتب الحد الادنى الشهري وتقسيمه على 25 يوم, في تاريخ 97/4/2 تم تعديل القانون وتم التصريح عن الموظف الذي يعمل 5 ايام بالاسبوع ان يحتسب راتبه اليومي بطريقة تقسيم الحد الادنى للراتب الشهري على 21.66 يوم.</p>
										</li>
										<li>
											<p>حتى 97/4/2 تم احتساب راتب الحد الادنى للساعة بقسمة راتب الحد الادنى الشهري على 200 ساعة وابتداء من 97/4/2 تم تعديل القانون وتم الاحتساب بقسمة الراتب الشهري على 191 ساعة وابتداء من 97/6/30 عدل القانون مرة اخرى وتم الاحتساب بقيمة الحد الادنى للراتب الشهري على 186 ساعة.</p>
										</li>
									</ol>
							</div>
						</div>
						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
