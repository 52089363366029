/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Itt21 from '../../../components/Tables/basic21';
import Itt22 from '../../../components/Tables/basic22/itt22';
import { TaxCalculatorComponent21 } from '../../../calculators/components/TaxCalculatorComponent21';
import { TaxCalculatorComponent22 } from '../../../calculators/components/TaxCalculatorComponent22';
export default class LawMinor extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | قانون عمل القاصرين     </title>
					<meta name="description" content="MassPortal - قانون عمل القاصرين " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container ">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>شروط لعمل القاصرين   </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>


						<div className="row ">

							<br></br>
							<br></br>
							<br></br>

							<div className="col-sm-10  ">

								<h3  >خلال إجازة الصيف القريبة، يطلب أصحاب عمل كثيرين لاستخدام القاصرين بأعمال مختلفة </h3>
								<br></br>
								{/* <ul className='privacy-ol'>
									<li>
										<p>
										
											<br></br>
											
										</p>
									</li>	

								</ul>
								 */}


							</div>
							<div className="col-lg-10">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy"> شروط استخدام القاصر (حتى جيل 18).</h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												{/* <thead>
													<tr >
														<th colspan="2"> أقدمية قطاع خاص  </th>
													
														<th colspan="2">أقدمية قطاع عام </th>
														
														<th colspan="2">أقدمية موظف هستدروت </th>
													
												

													</tr>
												</thead> */}
												<tbody>
													{/* <tr >
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														

													</tr> */}
													<tr className=''>
														<td>جيل العمل المسموح به خلال سنة التعليم</td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>15 سنة</li>
															</ul>
														</td>

													</tr>
													<tr>
														<td>جيل العمل المسموح به خلال إجازة التعليم</td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>14 سنة</li>
															</ul>
														</td>

													</tr>

													<tr>
														<td>ساعات العمل المسموحة باليوم </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li> حتى 8 ساعات باليوم (أسبوع عمل بين 6 أيام)</li>
																<li>حتى 9 ساعات باليوم (أسبوع عمل بين 5 أيام)</li>
																<li>حتى 7 ساعات بأيام الجمعة ومساء العيد</li>
															</ul>
														</td>

													</tr>
													<tr>
														<td>الاستراحات </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>بيوم عمل يعلو على 6 ساعات – استراحة 4/3 ساعه</li>
																<li>على الأقل (حتى 3 ساعات), من خلالها 2/1 ساعه لاستراحة متواصله</li>
																<li>بأيام جمعه ومساء العيد – استراحة 2/1 ساعه على الأقل.</li>
															</ul>
														</td>

													</tr>

													<tr>

														<td>ساعات العمل المسموحة بالأسبوع </td>
														<td>
														<ul className='privacy-ol  text-left' >
																<li>حتى 40 ساعه بالأسبوع</li>
															</ul>
															</td>


													</tr>
													<tr>
														<td>العمل الليلي</td>
														<td>
														<ul className='privacy-ol  text-left' >
																<li>ممنوعه ( ويسمح  بظروف خاصه بعد المصادقة عليها )</li>
															</ul>
															</td>

													</tr>

													<tr>
														<td>العمل بساعات إضافية </td>
														<td>
														<ul className='privacy-ol  text-left' >
																<li>ممنوعة</li>
															</ul>
															</td>
													</tr>
													<tr>
														<td>عمل بالاستراحة الأسبوعية  </td>
														<td>
														<ul className='privacy-ol  text-left' >
																<li>ممنوعة</li>
															</ul>
															</td>
													</tr>
													<tr>
														<td>إجازة سنويه   </td>
														<td>
														<ul className='privacy-ol  text-left' >
																<li>للأربع سنوات عمل الأولى – 18 يوم إجازة سنويه</li>
															</ul>
															</td>
														
													</tr>
													<tr>
														<td>ضريبة الدخل    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>الدخل يخضع للضريبه. القاصر بجيل 16-18 يستحق</li>
																<li>لنقطة تزكيه إضافية بالإضافة لنقاط التزكية العادية</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>تأمين وطني    </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>صاحب العمل ملزم بدفع رسوم تأمين بنسبه مخفضه</li>
																<li>ل %0.66 لا يوجد خصم من الراتب .</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>دفتر عمل     </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>على القاصر تجهيز دفتر عمل من قبل مكتب العمل</li>
																<li>الذي يكون بسلطة صاحب العمل في كل وقت القاصر يعمل لديه</li>
																<li>طلب لاستلام دفتر العمل يمكن ان يكون عن طريق صاحب  العمل.</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>تقرير طبي  </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>لقاصر ملزم بفحوصات على يد طبيب العائلة</li>
																<li>وإحضار تقرير طبي لصاحب العمل.</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>راتب الحد الأدنى   </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>حتى جيل 16- 13.49 شيكل جديد للساعة.</li>
																<li>حتى جيل 17- 14.46 شيكل جديد للساعة.</li>
																<li>حتى جيل 18 – 16 شيكل جديد للساعة</li>
															</ul>
														</td>
													</tr>

													<tr>
														<td>العمل الليلي   </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>بخصوص القاصر  حتى جيل 16 – من الساعة 8:00 حتى 20:00</li>
																<li>بخصوص القاصر فوق  جيل 16 – من الساعة 6:00 حتى 22:00</li>
																<li>حتى جيل 18 – 16 شيكل جديد للساعة</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>الاستراحة الأسبوعية   </td>
														<td>
															<ul className='privacy-ol  text-left' >
																<li>على الأقل 36 ساعه متواصله ويتخللاها لأجل القاصر اليهودي يوم السبت</li>
																<li>ولأجل القاصر الغير يهودي يوم السبت أو يوم الأحد او يوم الجمعة – حسب المتعارف عليه</li>
															</ul>
														</td>
													</tr>

												</tbody>
											</table>

										</div>

									</div>
									<hr></hr>
									<ul className='privacy-ol'>

										<h4 className='privacy '>
										القانون اعتمد وزير العمل لإخراج مسموحات بخصوص أنواع العمل , التي أعطيت لتشغيل  القاصر  بالليل , الأعمال المسموحة كالتالي مثل : تعبئة الفطير وطحين الفطير, نجمة داود الحمراء,وأماكن خزن الحمضيات
										</h4>

									</ul>
									
								</div>
								{/* ========================TABLE One End ============================ */}


								{/* <ol className='privacy-ol  text-left' >
																<li>حتى جيل 16- 13.49 شيكل جديد للساعة.</li>
																<li>حتى جيل 17- 14.46 شيكل جديد للساعة.</li>
																<li>حتى جيل 18 – 16 شيكل جديد للساعة</li>
															</ol> */}
								<br></br>
							</div>



						</div>



						{/* /Content End */}
					</div>

					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
