let taxProperty = [

  {
    yearMil:1642291200000,
    date: "16/01/2022",
    band1: {
      rate: "8",
      max: "5525070",
      min: "0"
    },
    band2: {
      min: "5525070",
      rate: "10",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: "",
    }
  },
  {
    yearMil:1638057600000,
    date: "28/11/2021",
    band1: {
      rate: "8",
      max: "5348565",
      min: "0"
    },
    band2: {
      min: "5348565",
      rate: "10",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: "",
      
    }
  },
  {
    yearMil: 1610755200000,
    date: "16/01/2021",
    band1: {
      rate: "5",
      max: "1294770",
      min: "0"
    },
    band2: {
      min: "1294770",
      rate: "6",
      max: "3884295"
    },
    band3: {
      rate: "7",
      max: "5348565",
      min: "3884295"
    },
    band4: {
      rate: "8",
      max: "17828555",
      min: "5348565"
    },
    band5: {
      rate: "10",
      min: "17828555",
      max:"أكثر من ذلك "
    }
  },
  {
    yearMil:1595980800000,
    date: "29/07/2020",
    band1: {
      rate: "5",
      max: "1292280",
      min: "0"
    },
    band2: {
      min: "1292280",
      rate: "6",
      max: "3876835"
    },
    band3: {
      rate: "7",
      max: "5338290",
      min: "3876835"
    },
    band4: {
      rate: "8",
      max: "17794305",
      min: "5338290"
    },
    band5: {
      rate: "10",
      min: "17794305",
      max:"أكثر من ذلك "
    }
  },
  {
    yearMil: 1579132800000,
    date: "16/01/2020",
    band1: {
      rate: "8",
      max: "5340425",
      min: "0"
    },
    band2: {
      min: "5340425",
      rate: "10",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1547596800000,
    date: "16/01/2019",
    band1: {
      rate: "8",
      max: "5194225",
      min: "0"
    },
    band2: {
      min: "5194225",
      rate: "10",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1516060800000,
    date: "16/01/2018",
    band1: {
      rate: "8",
      max: "5095570",
      min: "0"
    },
    band2: {
      min: "5095570",
      rate: "10",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1484524800000,
    date: "16/01/2017",
    band1: {
      rate: "8",
      max: "4967445",
      min: "0"
    },
    band2: {
      min: "4967445",
      rate: "10",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1452902400000,
    date: "16/01/2016",
    band1: {
      rate: "8",
      max: "4896615",
      min: "0"
    },
    band2: {
      min: "4896615",
      rate: "10",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1435104000000,
    date: "24/06/2015",
    band1: {
      rate: "8",
      max: "4800605",
      min: "0"
    },
    band2: {
      min: "4800605",
      rate: "10",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1421366400000,
    date: "16/01/2015",
    band1: {
      rate: "5",
      max: "1162120",
      min: "0"
    },
    band2: {
      min: "1162120",
      rate: "6",
      max: "3486350"
    },
    band3: {
      rate: "7",
      max: "4800605",
      min: "3486350"
    },
    band4: {
      rate: "8",
      max: "16002015",
      min: "4800605"
    },
    band5: {
      rate: "10",
      min: "16002015",
      max: "أكثر من ذلك "
    }
  },
  {
    yearMil: 1389830400000,
    date: "16/01/2014",
    band1: {
      rate: "5",
      max: "1123910",
      min: "0"
    },
    band2: {
      min: "1123910",
      rate: "6",
      max: "3371710"
    },
    band3: {
      rate: "7",
      max: "4642750",
      min: "3371710"
    },
    band4: {
      rate: "8",
      max: "15475835",
      min: "4642750"
    },
    band5: {
      rate: "10",
      min: "15475835",
      max: "أكثر من ذلك "
    }
  },
  {
    yearMil:1375315200000,
    date: "01/08/2013",
    band1: {
      rate: "5",
      max: "1089350",
      min: "0"
    },
    band2: {
      min: "1089350",
      rate: "6",
      max: "3268040"
    },
    band3: {
      rate: "7",
      max: "4500000",
      min: "3268040"
    },
    band4: {
      rate: "8",
      max: "15000000",
      min: "4500000"
    },
    band5: {
      rate: "10",
      min: "15000000",
      max: "أكثر من ذلك "
    }
  },
  {
    yearMil:1358294400000,
    date: "16/01/2013",
    band1: {
      rate: "5",
      max: "1089350",
      min: "0"
    },
    band2: {
      min: "1089350",
      rate: "6",
      max: "3268040"
    },
    band3: {
      rate: "7",
      
      min: "3268040",
      max: "أكثر من ذلك "
    },
    band4: {
      rate: "",
      max: "",
      min:""
      
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1326672000000,
    date: "16/01/2012",
    band1: {
      rate: "5",
      max: "1053200",
      min: "0"
    },
    band2: {
      min: "1053200",
      rate: "6",
      max: "3159600"
    },
    band3: {
      rate: "7",
     
      min: "3159600",
      max: "أكثر من ذلك "
    },
    band4: {
      rate: "",
      max: "",
      min:""
      
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1298246400000,
    date: "21/02/2011",
    band1: {
      rate: "5",
      max: "1000000",
      min: "0"
    },
    band2: {
      min: "1000000",
      rate: "6",
      max: "3000000"
    },
    band3: {
      rate: "7",
     
      min: "3000000",
      max: "أكثر من ذلك "
    },
    band4: {
      rate: "",
      max: "",
      min:""
      
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1295136000000,
    date: "16/01/2011",
    band1: {
      rate: "3.5",
      max: "969330",
      min: "0"
    },
    band2: {
      min: "969330",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1263600000000,
    date: "16/01/2010",
    band1: {
      rate: "3.5",
      max: "923070",
      min: "0"
    },
    band2: {
      min: "923070",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1232064000000,
    date: "16/01/2009",
    band1: {
      rate: "3.5",
      max: "873500",
      min: "0"
    },
    band2: {
      min: "873500",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1200441600000,
    date: "16/01/2008",
    band1: {
      rate: "3.5",
      max: "772310",
      min: "0"
    },
    band2: {
      min: "772310",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil:1192492800000,
    date: "16/10/2007",
    band1: {
      rate: "3.5",
      max: "787000",
      min: "0"
    },
    band2: {
      min: "787000",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1184544000000,
    date: "16/07/2007",
    band1: {
      rate: "3.5",
      max: "746820",
      min: "0"
    },
    band2: {
      min: "746820",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1176681600000,
    date: "16/04/2007",
    band1: {
      rate: "3.5",
      max: "744520",
      min: "0"
    },
    band2: {
      min: "744520",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1168905600000,
    date: "16/01/2007",
    band1: {
      rate: "3.5",
      max: "691240",
      min: "0"
    },
    band2: {
      min: "691240",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1160956800000,
    date: "16/10/2006",
    band1: {
      rate: "3.5",
      max: "713625",
      min: "0"
    },
    band2: {
      min: "713625",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1153008000000,
    date: "16/07/2006",
    band1: {
      rate: "3.5",
      max: "712850",
      min: "0"
    },
    band2: {
      min: "712850",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1145145600000,
    date: "16/04/2006",
    band1: {
      rate: "3.5",
      max: "739120",
      min: "0"
    },
    band2: {
      min: "739120",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1137369600000,
    date: "16/01/2006",
    band1: {
      rate: "3.5",
      max: "737550",
      min: "0"
    },
    band2: {
      min: "737550",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1129420800000,
    date: "16/10/2005",
    band1: {
      rate: "3.5",
      max: "721345",
      min: "0"
    },
    band2: {
      min: "721345",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1121472000000,
    date: "16/07/2005",
    band1: {
      rate: "3.5",
      max: "693600",
      min: "0"
    },
    band2: {
      min: "693600",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1113609600000,
    date: "16/04/2005",
    band1: {
      rate: "3.5",
      max: "682020",
      min: "0"
    },
    band2: {
      min: "682020",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1105833600000,
    date: "16/01/2005",
    band1: {
      rate: "3.5",
      max: "690530",
      min: "0"
    },
    band2: {
      min: "690530",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1097884800000,
    date: "16/10/2004",
    band1: {
      rate: "3.5",
      max: "720650",
      min: "0"
    },
    band2: {
      min: "720650",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1089936000000,
    date: "16/07/2004",
    band1: {
      rate: "3.5",
      max: "719095",
      min: "0"
    },
    band2: {
      min: "719095",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1082073600000,
    date: "16/04/2004",
    band1: {
      rate: "3.5",
      max: "709065",
      min: "0"
    },
    band2: {
      min: "709065",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1074211200000,
    date: "16/01/2004",
    band1: {
      rate: "3.5",
      max: "709065",
      min: "0"
    },
    band2: {
      min: "709065",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1072915200000,
    date: "01/01/2004",
    band1: {
      rate: "3.5",
      max: "721425",
      min: "0"
    },
    band2: {
      min: "721425",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1066262400000,
    date: "16/10/2003",
    band1: {
      rate: "3.5",
      max: "721425",
      min: "0"
    },
    band2: {
      min: "721425",
      rate: "4.75",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1058313600000,
    date: "16/07/2003",
    band1: {
      rate: "3.5",
      max: "710620",
      min: "0"
    },
    band2: {
      min: "710620",
      rate: "4.75",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1050451200000,
    date: "16/04/2003",
    band1: {
      rate: "3.5",
      max: "767785",
      min: "0"
    },
    band2: {
      min: "767785",
      rate: "4.75",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1042675200000,
    date: "16/01/2003",
    band1: {
      rate: "3.5",
      max: "762450",
      min: "0"
    },
    band2: {
      min: "762450",
      rate: "4.75",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1041379200000,
    date: "01/01/2003",
    band1: {
      rate: "3.5",
      max: "775075",
      min: "0"
    },
    band2: {
      min: "775075",
      rate: "4.75",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1034726400000,
    date: "16/10/2002",
    band1: {
      rate: "3.5",
      max: "775075",
      min: "0"
    },
    band2: {
      min: "775075",
      rate: "4.5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1026777600000,
    date: "16/07/2002",
    band1: {
      rate: "3.5",
      max: "802325",
      min: "0"
    },
    band2: {
      min: "802325",
      rate: "4.5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1018915200000,
    date: "16/04/2002",
    band1: {
      rate: "3.5",
      max: "763125",
      min: "0"
    },
    band2: {
      min: "763125",
      rate: "4.5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1011139200000,
    date: "16/01/2002",
    band1: {
      rate: "3.5",
      max: "705350",
      min: "0"
    },
    band2: {
      min: "705350",
      rate: "4.5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1005091200000,
    date: "07/11/2001",
    band1: {
      rate: "3.5",
      max: "706030",
      min: "0"
    },
    band2: {
      min: "706030",
      rate: "4.5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 1003190400000,
    date: "16/10/2001",
    band1: {
      rate: "3.5",
      max: "706030",
      min: "0"
    },
    band2: {
      min: "706030",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 995241600000,
    date: "16/07/2001",
    band1: {
      rate: "3.5",
      max: "683440",
      min: "0"
    },
    band2: {
      min: "683440",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 987379200000,
    date: "16/04/2001",
    band1: {
      rate: "3.5",
      max: "680770",
      min: "0"
    },
    band2: {
      min: "680770",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 979603200000,
    date: "16/01/2001",
    band1: {
      rate: "3.5",
      max: "668210",
      min: "0"
    },
    band2: {
      min: "668210",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 971654400000,
    date: "16/10/2000",
    band1: {
      rate: "3.5",
      max: "662810",
      min: "0"
    },
    band2: {
      min: "662810",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 963705600000,
    date: "16/07/2000",
    band1: {
      rate: "3.5",
      max: "672935",
      min: "0"
    },
    band2: {
      min: "672935",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 955843200000,
    date: "16/04/2000",
    band1: {
      rate: "3.5",
      max: "652645",
      min: "0"
    },
    band2: {
      min: "652645",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 947980800000,
    date: "16/01/2000",
    band1: {
      rate: "3.5",
      max: "688505",
      min: "0"
    },
    band2: {
      min: "688505",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  },
  {
    yearMil: 946684800000,
    date: "01/01/2000",
    band1: {
      rate: "3.5",
      max: "713015",
      min: "0"
    },
    band2: {
      min: "713015",
      rate: "5",
      max : "أكثر من ذلك "
      
    },
    band3: {
      rate: "",
      max: "",
      min:""
      
    },
    band4: {
      rate: "",
      max: "",
      min: ""
    },
    band5: {
      rate: "",
      min: ""
    }
  }
] 
export default{taxProperty}