import { useEffect, useState } from "react"
import taxData from "../mass-modules/mass-taxes/national-insurance/data/output";

export const useNationallTax = (income,year) => {
  
  const [tax, setTax] = useState(0)

  let short1 = taxData.taxData[year].bituachLeumi.nationalInsurance.band1.rate.selfEmployed;
  let short2 = taxData.taxData[year].bituachLeumi.nationalInsurance.band2.rate.selfEmployed;
  let short3 = taxData.taxData[year].bituachLeumi.nationalInsurance.band1.max;
  let short4 = taxData.taxData[year].bituachLeumi.nationalInsurance.band2.max;
  let short5 = taxData.taxData[year].bituachLeumi.healthInsurance.band1.rate.selfEmployed;
  let short6 = taxData.taxData[year].bituachLeumi.healthInsurance.band2.rate.selfEmployed;


  let num = [
    short3,
    short4
  ]


  let percentage = [
    (short1) / 100,
    (short2) / 100,
  ];
  let num1 = [
    short5,
    short6
  ]
  let percentage1 = [
    (short5) / 100,
    (short6) / 100,
  ];

  let np = [
    (num[0]) * percentage[0],
    (num[1] - num[0]) * percentage[1]
  ];
  let np1 = [
    (num[0]) * percentage1[0],
    (num[1] - num[0]) * percentage1[1]
  ];
    
 useEffect(()=>{
    setTax(calculateTax(income / 12))
},[income,year])
  
function calculateTax(income){
    
      let tax = 0;
      let taxH = 0;
      let summ = 0;
     
      // National Insurance 
      if (income > 0 && income <= num[0]) {
        tax = (income) * percentage[0];
        taxH = (income) * percentage1[0]
        summ = (tax + taxH)
      }
      else if (income > num[0] && income <= num[1]) {
        tax = ((income - num[0]) * percentage[1]) + (np[0]);
        taxH = ((income - num[0]) * percentage1[1]) + (np1[0]);
        summ = (tax + taxH)
      }
      else if (income > num[1]) {
        tax = (((num[1] - num[0]) * percentage[1]) + (np[0]));
        taxH = (((num[1] - num[0]) * percentage1[1]) + (np1[0]));
        summ = (tax + taxH)
      }
      return summ = Math.round(summ * 12 )
    }
   
  return { tax }
}