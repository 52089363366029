/**
 * TermsCondition Page
 */





/**
 * Check This Link For multiPage Printing using JSPDF 
 * https://shamaahlawat.medium.com/page-split-using-jspdf-and-html2canvas-in-react-js-while-downloading-pdf-6c75da960019
 */












import React, { Component, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import ReactTooltip from "react-tooltip";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { massportallogo } from '../../../../Entryfile/imagepath';
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { projectFirestore } from "../../../../firebase/config";
import { AiOutlineFilePdf } from 'react-icons/ai'
import { SiMicrosoftexcel } from 'react-icons/si'
export default function RashamAs() {


  const [searchTerm, setSearchTerm] = useState("");
  const [details, setDetails] = useState("");
  const apiURL = 'https://data.gov.il/api/3/action/datastore_search?resource_id=85e40960-5426-4f4c-874f-2d1ec1b94609&q=';
  const printRef = React.useRef();
  const [isActive, setIsActive] = useState(false);
  // HOOKS
  const { user } = useAuthContext()
  const [companyName, setCompanyName] = useState(' ')
  // Search by song or artist
  async function searchSongs(term) {
    const res = await fetch(`${apiURL}+${term}`);
    const data = await res.json();
    // console.log("🚀 ~ ~ data", data)

    const record = data.result.records;

    // console.log("🚀 ~ record", record)

    const obj = record[0];
    // console.log(" ~ obj", obj)

    const entries = Object.entries(obj);
    setDetails(entries);
    // console.log("🚀 ~ details", details);


    // Object.entries(obj).forEach(entry => {
    //   const [key, value] = entry;
    //   console.log(key, value);
    // });  


    //  showData(entries);
  }
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (!searchTerm) {
      alert('Please type in a search term');
    } else {
      searchSongs(searchTerm);
    }

    // console.log("Function Call Here"); 
    // console.log("text Value : ", searchTerm);

  }
  
  //  PDF Prin Function 
  const handleDownloadPdf = async () => {

    const element = printRef.current;
    element.style.background = '#FFFFFF';
    element.style.color = '#212529';

    const iaactive = true;
    await setIsActive(iaactive);
    //  Current Date 
    const d = new Date().toLocaleString() + "";
    let current = d.split(',');
    const today = current[0];
    const todaySplitArr = today.split('/');
    const todayFrm = "" + todaySplitArr[1] + '/' + todaySplitArr[0] + '/' + todaySplitArr[2];
    // Current Date 

    // PDF Header And Footer Values 
    const header = todayFrm;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;

    //  Header  - Logo
    pdf.addImage(massportallogo, 'png', 90, 1, 20, 20);

    // PDF -  DAta 
    pdf.addImage(data, 'PNG', 0, 22, pdfWidth, pdfHeight);

    // Header - Date 
    pdf.text(header, 2, 26, { baseline: 'top' });

    const pageSize = pdf.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    const footer = d + '             www.mass-portal.com';
    // Footer
    pdf.text(footer, pageWidth / (pdf.getTextWidth(footer) / 2), pageHeight - 2, { baseline: 'bottom' });
    pdf.setFontSize(20);
    pdf.save('مراقب الجمعيات   .pdf');
  };
  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Mass Portal - Rasham</title>
        <meta name="description" content="Reactify Blank Page" />
      </Helmet>
      {/* Page Content */}
      <div className="content container-fluid">
        <div className="small-container">

          <div className="inner-header text-center">
            <br></br>
            <br></br>
            <h1 className='privacy'>مراقب الجمعيات</h1>
            <p className="privacy-bred"><a href="/">الرئيسية</a></p>
            <br></br>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <p className='comp-content'>
              للبحث عن  تفاصيل  جمعية يمكنك  البحث عن طريق اسم الجمعية أو الرقم. 
أداة بسيطة وسهلة الاستخدام ،, نتيجة البحث باللغة العبرية<span className='privacy'>  ( עברית ) </span>.
يتم تقديم هذه الخدمة <span className='privacy'> مجانًا </span> ، ويتم تحديث المعلومات الواردة فيها بانتظام من مسجل الجمعيات.
تتضمن المعلومات المقدمة معلومات تتعلق بالجمعيات والأحزاب. 
              </p>
              <br></br>

              {/* Search Form */}
              <form onSubmit={handleSubmit} className="form card ">
                <div className="card-body">
                  <div className="form-gronum row ">
                    <br></br>
                    <label className=" col-form-label comp-control">رقم الجمعية أو اسمها</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control comp-control"
                        name="compName"
                        placeholder="أدخل رقم الجمعية أو اسمها ..."
                        required
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <div className="invalid-feedback">
                        الرجاء ادخال رقم الجمعية أو اسمها.
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <br />
                <div className="text-center">
                  <button type="submit" className="btn btn-primary calc-button">ابحث </button>
                </div>
                <br></br>
              </form>
              {/* Search Form  */}

              <br></br>
              {details && (
                <div className='container'>
                   <div className='row'>
                <div className="col-sm-12 col-md-10">


                </div>
                {/* <div className="col-sm-12 col-md-1"></div> */}
                <div className="col-sm-12 col-md-2">
                  <button className="btn " onClick={handleDownloadPdf} data-tip data-for="pdf-tip">
                    <AiOutlineFilePdf size={40} className='privacy' />
                  </button>
                  <button className="btn " data-tip data-for="excel-tip">
                    <SiMicrosoftexcel size={40} className='privacy' />
                  </button>
                  <ReactTooltip id="pdf-tip" place="top" effect="solid">
          طباعة PDF
        </ReactTooltip>
        <ReactTooltip id="excel-tip" place="top" effect="solid">
          طباعة Excel
        </ReactTooltip>
                </div>

              </div>
              <div className={isActive ? " card-white" : "card"} ref={printRef}>
              <br></br>
                <div className="row">
                
                  <div className="col-sm-12 col-md-4">
                    {/* <h4 className={isActive ? " privacy-black-bold text-left company-marg " : " privacy hide"} id='date-block'>
                      الشركة : <span className="privacy-black"> {user ? companyName : " "}  </span>
                    </h4> */}
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <h4 className={isActive ? "card-title mb-0 text-center privacy-black-bold " : "card-title mb-0 text-center  privacy"}>
                      مراقب الجمعيات  
                      <br></br>
                      <span className={isActive ? "privacy-black-bold" : "privacy-bold"}> 
                      {/* {compName} */}
                       </span>
                    </h4>
                  </div>
                  <div className='col-sm-12 col-md-4'>
                    <h4 className={isActive ? "privacy-black-bold text-center " : " privacy hide"} id='date-block'>
                      التاريخ
                    </h4>


                  </div>

                </div>
                <div className={isActive?"card-body card-body-white" : "card-body "}>
                      <div className="table-responsive">
                        <table className="table table-striped mb-0 comp-table-cont">
                          <thead>
                            <tr>
                              <th></th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {details.map((data) =>
                            (
                              <tr key={data[0]}>
                                <td className='comp-table m-l-15'>{data[0]}</td>
                                <td className='just-table ' >{data[1]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>

  );

}
