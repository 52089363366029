let l=[
 {
  ar: "لائحة الاسعار",
  he: "מחירון",
  en: "Price list\/ quotation board"
 },
 {
  ar: "اللجنة الاقتصادية والاجتماعية لغرب اسيا",
  he: "הוועדה הכלכלית חברתית למערב אסיה",
  en: "Economic and social commission for Western Asia "
 },
 {
  ar: "لحاملة",
  he: "למוסר כתב זה",
  en: "To bearer"
 },
 {
  ar: "لحساب المستفيد فقط",
  he: "למוטב בלבד",
  en: "Account payee only"
 },
 {
  ar: "لوازم",
  he: "אספקה",
  en: "supply"
 },
 {
  ar: "لوحة الاسعار",
  he: "מחירון",
  en: "Price list\/ quotation board"
 },
 {
  ar: "لويدز",
  he: "לוידס",
  en: "Lloyd's"
 }
] 
export default l ;