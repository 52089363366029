

import { BrowserRouter, Link, Route, Routes, Navigate } from 'react-router-dom'
// import { useAuthContext } from './hooks/useAuthContext'
import { useAuthContext } from './hooks/useAuthContext';
// import {AuthProvider} from "./contexts/AuthContext";
import {AuthProvider} from './contexts/AuthContext';
import { analytics } from './firebase/config';
import './assets/js/app.js';
// import 'font-awesome/css/font-awesome.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/line-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// ==================================================
import './assets/js/bootstrap.min.js';
import './assets/css/select2.min.css';
import './assets/js/popper.min.js';
import './assets/js/select2.min.js';
import './assets/js/jquery-3.2.1.min.js';
import './assets/js/jquery.slimscroll.min.js';
import './assets/js/jquery.slimscroll.js';
import "./assets/js/bootstrap-datetimepicker.min.js"
import "./assets/js/jquery-ui.min.js"
import "./assets/js/task.js"
import "./assets/js/multiselect.min.js"
import "./assets/plugins/bootstrap-tagsinput/bootstrap-tagsinput.css"
import "./assets/plugins/bootstrap-rtl/css/bootstrap.min.css"
import "./assets/plugins/bootstrap-rtl/js/bootstrap.min.js"
import "./assets/plugins/bootstrap-rtl/js/bootstrap.js"
// import './assets/css/style.css'
import './assets/css/style-dark.css';
import MathCourses from './components/pages/sidebar-pages/courses-math/math-courses';

// import CalculatorPay from './payPal-calc/CalculatorPay';

//=======Pages
// import PrintApp from './components/pages/pdfPrint';


import Loginpage from './components/pages/loginpage';
import ForgotPassword from './components/pages/forgotpwd';
import Registrationpage from './components/pages/RegistrationPage'
import Header from './components/layout/Header';
import Sidebar from './components/layout/Sidebar';
import Home from './components/pages/home';
import About from './components/pages/About';
import AboutMp from './components/pages/sidebar-pages/About';
import Itt20 from './components/Tables/basic20';
// import Itt22 from './components/Tables/basic22';
import Itt21 from './components/Tables/basic22';
import Itt22 from './components/Tables/basic22/itt22';
import ExchngCalc from './calculators/exchange-rate/ExchngCalc';
import Error404 from './Error404';
import ThankYou from './thankYou';

// ===================SideBar Pages ================
import EmpBillCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/empBillsCalc';
import TotalTabel from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/TotalTable';
import TaxCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/taxCalc';
import Tax22Rtl from './calculators/WorkLawsCalc/Taxt22-rtl';
import Tax21Rtl from './calculators/WorkLawsCalc/Taxt21-rtl';
import Tax23Rtl from './calculators/WorkLawsCalc/Taxt23-rtl';
import PaymentCalcRank from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/paymentCalcRank';
import CmoparePaymentDev from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/comparPaymentDev';
import EmployeeSalaryCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/employeeSalaryCalc';
import InsuranceCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/insurancePayments';
import DeffCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/compensationDeffCalc';
import OutsideTaxCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/outside-employee-tax-calc/ousideTaxCalc';
import CompensationCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/Compensation-Calculations/compensationCalc';
import NonCompCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/non-compensation-clac/nonCompCalc';
import DeductWageCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/deducted-wages-calc/deductWageCalc';
import RecupCalc from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/recuperation-allowance-calc/recuperationCalc';
import MoneyList from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/moneyreport/moneyList';
import WhatIsIt from './components/pages/sidebar-pages/payments-taxes/payments-loans-calc/moneyreport/Info';
import MinistryOfHealth from './components/pages/sidebar-pages/payments-taxes/tabels-data/MinistryOfHealth';
import LivingCoast from './components/pages/sidebar-pages/payments-taxes/tabels-data/LivingCoast';
import ProtectedRent from './components/pages/sidebar-pages/payments-taxes/tabels-data/protectedRent';
import AverageSalary from './components/pages/sidebar-pages/payments-taxes/tabels-data/AverageSalary';
import MinCoast from './components/pages/sidebar-pages/payments-taxes/tabels-data/minimumCoast';
import MinCoastYouth from './components/pages/sidebar-pages/payments-taxes/tabels-data/minCoastYouth';
// import Tabel22 from './components/pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel22';
import Tabel21 from './components/pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel21';
import Table20 from './components/pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel20';
import InsTable from './components/pages/sidebar-pages/payments-taxes/tabels-data/insurance-tax-tabels/InsuranceTaxTabel';
import AddedValTax from './components/pages/sidebar-pages/payments-taxes/tabels-data/added-value-taxtabels/AddedVTax';
import LawTabel from './components/pages/sidebar-pages/payments-taxes/tabels-data/work-law-tabel/LawTabel';
import Report from './components/pages/sidebar-pages/reports/report';
import Scale from './components/pages/sidebar-pages/scales/Scales';
import Loan from './components/pages/sidebar-pages/loans/Loans';
import Contracts from './components/pages/sidebar-pages/contracts/contracts-heb';
import ContractsAr from './components/pages/sidebar-pages/contracts/contracts-ar';
import ReportsHe from './components/pages/sidebar-pages/contracts/reports-he';
import AllEmployees from './Employees/Employees/allemployees.jsx';
import EmployeeProfile from './Profile/employeeprofile.jsx';
import Employeeslist from './Employees/Employees/employeeslist.jsx';
import PricesFintech from './components/pages/sidebar-pages/mass-service-prices/PricesFintech';
// Employees
import Employee from './components/pages/sidebar-pages/Employees/allemployees';
import Department from './Employees/Employees/department.jsx';
//====================== Claculators ==============================
import Calculator from './calculators/main/Calculator';
import { MortageCalculator } from './calculators/sd-mortage/Calculator';
import ZakatCalc from './calculators/zakat-calc/ZakatCalc';
import ZakatInfo from './calculators/zakat-calc/ZakatInfo';
import EofSCalc from './calculators/WorkLawsCalc/EndOfServiceCalc';
import Tax22 from './calculators/WorkLawsCalc/Taxt22';
import Tax21 from './calculators/WorkLawsCalc/Taxt21';
import Tax23 from './calculators/WorkLawsCalc/Taxt23';
import Nato21 from './calculators/nato-calculator/Nato21';
import Nato22 from './calculators/nato-calculator/Nato22';
import NatInCalcP from './mass-modules/mass-taxes/national-insurance/natIn-calculator-page';
// Privacy Policy
import TermsCondition from './components/pages/sidebar-pages/privacypolicy';
// Cars Pages 
import CarEx from './components/pages/sidebar-pages/cars/carExp';
import TotalPrice from './components/pages/sidebar-pages/cars/TotalPrices';
import FuelPrice from './components/pages/sidebar-pages/cars/FuelPrice';
import InsurancePrice from './components/pages/sidebar-pages/cars/InsurancePrice';
import LicencePrice from './components/pages/sidebar-pages/cars/LicensePrice';
import CarReport from './components/pages/sidebar-pages/cars/Report';
// Shaam Pages //
import ShaamTax from './components/pages/sidebar-pages/shaam/ShaamTax';
import ShaamCodes from './components/pages/sidebar-pages/shaam/ShaamCodes';
import FormElements from './calculators/exchange-rate';
// Dictionary //
import Dictionary from './components/pages/sidebar-pages/kawamees/Dictionary';
// Rasham 
import RashamC from './components/pages/sidebar-pages/rasham/RashamC';
import RashamAs from './components/pages/sidebar-pages/rasham/RashamAs';
import RashamCtt from './components/pages/sidebar-pages/rasham/RashamCt';
import Payslip from './pr-system/payslip';
import SubscriptionCompany from './pr-system/subscriptioncompany';
import CalculatorRtx from './pr-system/CalcRtx';
import CalculatorRtx_21 from './pr-system/CalcRtx-21';
// Mass Modules
import MassModules from './mass-modules/massModules';
import MassManagement from './mass-modules/mass-management/mass-manage';
//  Fleets 
import MassFleets from './mass-modules/mass-fleet/Fleet-Dash';
import CalculatorPCaR from './mass-modules/mass-fleet/fleet-calcs/privateCars';
import SimpleRoi from './mass-modules/mass-fleet/fleet-calcs/roi'
import MassAssets from './mass-modules/mass-asstes/MassAsset';
import TaxFuelTable from './mass-modules/mass-fleet/fleet-tbls/TaxFuelTbl';
import TaxPriceGroupTable from './mass-modules/mass-fleet/fleet-tbls/TaxGroupTbl';
import TaxCompulsoryInsuranceTable from './mass-modules/mass-fleet/fleet-tbls/TaxCompulsoryInsuranceTbl';
  // import Ass from './mass-modules/mass-asstes/defMenu/deprData/usool';
  import AssetTaxRate from './mass-modules/mass-asstes/defMenu/deprData/mass-assets-taxRate';
import MassBillingg from './mass-modules/mass-billing/mass-billing';
import BillingAsset from './mass-modules/mass-billing/assets';
import MassFin from './mass-modules/mass-finance/MassFinance';
// Mass Contracts 
import MassContracts from './mass-modules/mass-contracts/mass-contracts';
// LAWS 
import MassLows from './mass-modules/mass-law/pr-law/mass-law';
import LawYear from './mass-modules/mass-law/vacation/Law-Yearlly';
import LawSick from './mass-modules/mass-law/vacation/Law-sickness';
import LawRecovery from './mass-modules/mass-law/vacation/Law-recovery';
import { NaqahaCalc } from './calculators/naqaha-calc/naqaha-calc-general';
import NaqahaCalculator from './calculators/naqaha-calc/NakahaCalculator';
import LawRecFaq from './mass-modules/mass-law/vacation/mass-law-recovery-faq';
import LawEid from './mass-modules/mass-law/vacation/Law-eid';
import LawEos from './mass-modules/mass-law/end-of-service/Law-eos';
import LawPvI from './mass-modules/mass-law/end-of-service/Law-prev-inf';
import LawRights from './mass-modules/mass-law/end-of-service/Law-rights';
import Halanat_Skhar from './mass-modules/mass-law/end-of-service/halanatSkhar/Halanat_sh';
import LawMinor from './mass-modules/mass-law/vacation/Law-minor-work';
// Vat 
import VatTable from './calculators/WorkLawsCalc/vat-calc/Vat-table';
import LawDM from './mass-modules/mass-law/end-of-service/Law-day-month';
import MnthVPt from './calculators/WorkLawsCalc/vat-calc/Mnth_Vat_Rpt';
// Mass Reports
import MassReports from './mass-modules/mass-reports/mass-reports';
import { RevSalesReport } from './mass-modules/mass-reports/financial-reports/revenues-sailes-report';
import MassRepT from './mass-modules/mass-reports/massR-tareef';
import CalculatorAltman from './mass-modules/mass-reports/altmanCalculater/altman';
import MassTaxes from './mass-modules/mass-taxes/mass-taxes';
import TaxAbtCalc from './mass-modules/mass-taxes/calculator/tax-abt-calc';
import  GoldSilver from './mass-modules/mass-reports/gold-silver/silver-gold';
import  ExChange from './mass-modules/mass-reports/exchange/exchange';
import SettingMg from './mass-modules/mass-management/settingsMg';
import MgClient from './mass-modules/mass-management/Mg-client';
import MgEmp from './mass-modules/mass-management/Mg-employee';
import ExpNew from './mass-modules/mass-reports/jv-expenses/NewExpAcc';
import SuppNew from './mass-modules/mass-reports/jv-expenses/NewSuppAcc';
// import ForgotPwd from './components/pages/ForgotPassword';
// import TxTbl from './mass-modules/mass-taxes/realSates/tables/table/TxTblAbt';
import TaxtTable from './mass-modules/mass-taxes/realSates/tables/table/TaxTable';
import TaxtTableReal from './mass-modules/mass-taxes/realSates/tables/table/TaxTableRealShebah';
import TaxtTableOneTime from './mass-modules/mass-taxes/realSates/tables/table/TaxTableOneTimeShebah';
// Calculators 
import VatPage from './calculators/WorkLawsCalc/vat-calc/Vat-page';
import GeloomPage from './calculators/WorkLawsCalc/vat-calc/Geloompage';
import CreateInvoice from './calculators/openVatCalc/CreateInvoice';
import NaqahaCalcc from './calculators/naqaha-calc/naqaha-calculator';
// Contact Page
import Contact from './components/pages/contact';
import RentContract from './components/pages/sidebar-pages/contracts/rent-contract/rentContract';
import CreateJv from './mass-modules/mass-reports/jv-expenses/CreateJv';
function App() {

  const { authIsReady, user } = useAuthContext()

  return (
    // className="main-wrapper"
    <div className="main-wrapper">

      <Sidebar />
      
      {authIsReady && (

        <BrowserRouter>
 <AuthProvider>
          <Header />

          {/* {user?<Sidebar />:null} */}

         

          <Routes>
     
            {/* <Route path="/" element={<Home />} />
          <Route exact={true} path="/home" element={<Home />} />        
          <Route exact={true} path="/loginpage/*" element={<Login />} />
          <Route path="tables/basic20" element={<Itt20 />} />
          <Route path="tables/basic22" element={<Itt22 />} />
          <Route path="tables/basicto" element={<Itt21 />} /> */}
            <Route path="about" element={<About />} />
            <Route path="forgotpassword" element={< ForgotPassword />} />
            <Route path="/" element={!user ? <Loginpage /> : <Home />} />
            <Route path="home" element={!user ? <Loginpage /> : <Home />} />
            {/* <Route path="print-app" element={!user ? <Loginpage /> : <PrintApp />} /> */}
            <Route path="loginpage" element={user ? <Navigate to="/" /> : <Loginpage />} />
            
            <Route path="employees/all-employees" element={user ? <AllEmployees /> : <Loginpage />} /> 
            <Route path="employees/employee-profile" element={user ? <EmployeeProfile /> : <Loginpage />} /> 
            <Route path="employees/employees-list" element={user ? <Employeeslist /> : <Loginpage />} /> 
            {/* <Route path="employees/departments" element={user ? <Department /> : <Loginpage />} />  */}


            <Route path="register" element={user ? <SettingMg /> : <Registrationpage />} />
            <Route path="tables/basic20" element={user ? <Itt20 /> : <Loginpage />} />
            <Route path="tables/basic22" element={user ? <Itt22 /> : <Loginpage />} />
            <Route path="tables/basicto" element={user ? <Itt21 /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/empBillsCalc" element={user ? <EmpBillCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/TotalTabel" element={user ? <TotalTabel /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/tax-calc" element={user ? <TaxCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/PaymentCalcRank" element={user ? <PaymentCalcRank /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/CmoparePaymentDev" element={user ? <CmoparePaymentDev /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/EmployeeSalaryCalc" element={user ? <EmployeeSalaryCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/InsuranceCalc" element={user ? <InsuranceCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/DeffCalc" element={user ? <DeffCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/OutsideTaxCalc" element={user ? <OutsideTaxCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/CompensationCalc" element={user ? <CompensationCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/NonCompCalc" element={user ? <NonCompCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/DeductWageCalc" element={user ? <DeductWageCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/RecupCalc" element={user ? <RecupCalc /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/moneyreport/moneyList" element={user ? <MoneyList /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/payments-loans-calc/moneyreport/info" element={user ? <WhatIsIt /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/MinistryOfHealth" element={user ? <MinistryOfHealth /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/LivingCoast" element={user ? <LivingCoast /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/ProtectedRent" element={user ? <ProtectedRent /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/AverageSalary" element={user ? <AverageSalary /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/MinCoast" element={user ? <MinCoast /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/MinCoastYouth" element={user ? <MinCoastYouth /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/contracts/contracts-heb" element={user ? <Contracts /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/contracts/rent-contract" element={user ? <RentContract /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/contracts/contracts-ar" element={user ? <ContractsAr /> : <Loginpage />} />
            <Route path="reports-he" element={user ? <ReportsHe /> : <Loginpage />} />
            {/* <Route path="paypal" element={user ? <CalculatorPay /> : <Loginpage />} /> */}

            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel21" element={user ? <Tabel21 /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/Income-tax-tabels/tabel20" element={user ? <Table20 /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/insurance-tax-tabels/InsuranceTaxTabel" element={user ? <InsTable /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/added-value-taxtabels/AddedVTax" element={user ? <AddedValTax /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/payments-taxes/tabels-data/work-law-tabel/LawTabel" element={user ? <LawTabel /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/reports/report" element={user ? <Report /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/scales/Scales" element={user ? <Scale /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/loans/Loans" element={user ? <Loan /> : <Loginpage />} />
            <Route path="privacy-policy" element={user ? <TermsCondition /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/Employees/allemployees" element={user ? <Employee /> : <Loginpage />} />
            
            {/* Calculators */}
            <Route path="calculators/main" element={user ? <Calculator /> : <Loginpage />} />
            <Route path="calculators/open-vat-calc" element={user ? <CreateInvoice /> : <Loginpage />} />

            <Route path="calculators/main/Calculator" element={user ? <MortageCalculator /> : <Loginpage />} />
            <Route path="calculators/zakat-calc/ZakatCalc" element={user ? <ZakatCalc /> : <Loginpage />} />
            <Route path="calculators/zakat-calc/ZakatInfo" element={user ? <ZakatInfo /> : <Loginpage />} />
            <Route path="calculators/WorkLawsCalc/EndOfServiceCalc" element={user ? <EofSCalc /> : <Loginpage />} />
            <Route path="calculators/WorkLawsCalc/Tax23" element={user ? <Tax23 /> : <Loginpage />} />
            <Route path="calculators/WorkLawsCalc/Tax22" element={user ? <Tax22 /> : <Loginpage />} />
            <Route path="calculators/WorkLawsCalc/Tax21" element={user ? <Tax21 /> : <Loginpage />} />
            <Route path="tax-2023" element={user ? <Tax23Rtl /> : <Loginpage />} />
            <Route path="tax-2022" element={user ? <Tax22Rtl /> : <Loginpage />} />
            <Route path="tax-2021" element={user ? <Tax21Rtl /> : <Loginpage />} />
            <Route path="calculators/nato-calculator/Nato21" element={user ? <Nato21 /> : <Loginpage />} />
            <Route path="calculators/nato-calculator/Nato22" element={user ? <Nato22 /> : <Loginpage />} />
            <Route path="exchange-rate" element={user ? <ExchngCalc /> : <Loginpage />} />
            <Route path="calc-rtx" element={user ? <CalculatorRtx /> : <Loginpage />} />
            <Route path="calc-rtx-21" element={user ? <CalculatorRtx_21 /> : <Loginpage />} />
            <Route path="halanat-skhar" element={user ? <Halanat_Skhar /> : <Loginpage />} />
            <Route path="calculator-vat" element={user ? <VatPage /> : <Loginpage />} />
            <Route path="calculator-geloom" element={user ? <GeloomPage /> : <Loginpage />} />
            <Route path="vat-table" element={user ? <VatTable /> : <Loginpage />} />
            <Route path="naqaha-calculator" element={user ? <NaqahaCalcc /> : <Loginpage />} />
            <Route path="nationalIns-calculator" element={user ? <NatInCalcP /> : <Loginpage />} />
            <Route path="tax-table" element={user ? <TaxtTable /> : <Loginpage />} />
            <Route path="tax-table-real-shebah" element={user ? <TaxtTableReal /> : <Loginpage />} />
            <Route path="tax-table-oneTime-shebah" element={user ? <TaxtTableOneTime /> : <Loginpage />} />
          
            <Route path="forms" element={user ? <FormElements /> : <Loginpage />} />

            {/* CARS Pages */}
            <Route path="pages/sidebar-pages/cars/carExp" element={user ? <CarEx /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/cars/TotalPrices" element={user ? <TotalPrice /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/cars/FuelPrice" element={user ? <FuelPrice /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/cars/InsurancePrice" element={user ? <InsurancePrice /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/cars/LicensePrice" element={user ? <LicencePrice /> : <Loginpage />} />
            <Route path="pages/sidebar-pages/cars/Report" element={user ? <CarReport /> : <Loginpage />} />
            {/* Shaam Pages  */}
            <Route path="shaam-tax" element={user ? <ShaamTax /> : <Loginpage />} />
            <Route path="shaam-codes" element={user ? <ShaamCodes /> : <Loginpage />} />
            <Route path="dictionary" element={user ? <Dictionary /> : <Loginpage />} />
            <Route path="rasham-comp" element={user ? <RashamC /> : <Loginpage />} />
            <Route path="Rasham-as" element={user ? <RashamAs /> : <Loginpage />} />
            <Route path="Rasham-ct" element={user ? <RashamCtt /> : <Loginpage />} />
            <Route path="payslip" element={user ? <Payslip /> : <Loginpage />} />
            <Route path="dash" element={user ? <SubscriptionCompany /> : <Loginpage />} />
           {/* Mass Modules  */}
            <Route path="mass-modules" element={user ? <MassModules /> : <Loginpage />} />
            <Route path="mass-management" element={user ? <MassManagement /> : <Loginpage />} />
            <Route path="mass-asstes" element={user ? <MassAssets /> : <Loginpage />} />
            <Route path="mass-billing" element={user ? <MassBillingg /> : <Loginpage />} />
            <Route path="mass-billing-assets" element={user ? <BillingAsset /> : <Loginpage />} />
            <Route path="mass-assets-rates" element={user ? <AssetTaxRate /> : <Loginpage />} />
            {/* Mass Fleets  */}
            <Route path="mass-fleets" element={user ? <MassFleets /> : <Loginpage />} />
            <Route path="mass-fleets-privateCalc" element={user ? <CalculatorPCaR /> : <Loginpage />} />
            <Route path="mass-fleets-simpleROI" element={user ? <SimpleRoi /> : <Loginpage />} />
            <Route path="mass-fleets-fuel-table" element={user ? <TaxFuelTable /> : <Loginpage />} />
            <Route path="mass-fleets-CompulsoryInsurance-table" element={user ? <TaxCompulsoryInsuranceTable /> : <Loginpage />} />
            <Route path="mass-fleets-priceGroup-table" element={user ? <TaxPriceGroupTable /> : <Loginpage />} />
            {/* Mass Fleets */}
            <Route path="mass-finance" element={user ? <MassFin /> : <Loginpage />} />
            {/* Law Pages */}
            <Route path="mass-law" element={user ? <MassLows /> : <Loginpage />} />
            <Route path="mass-law-year" element={user ? <LawYear /> : <Loginpage />} />
            <Route path="mass-law-sick" element={user ? <LawSick /> : <Loginpage />} />
            <Route path="mass-law-recovery" element={user ? <LawRecovery /> : <Loginpage />} />
            <Route path="mass-law-recovery-faqs" element={user ? <LawRecFaq /> : <Loginpage />} />
            <Route path="mass-law-eid" element={user ? <LawEid /> : <Loginpage />} />
            <Route path="mass-law-eos" element={user ? <LawEos /> : <Loginpage />} />
            <Route path="mass-law-pvi" element={user ? <LawPvI /> : <Loginpage />} />
            <Route path="mass-law-rights" element={user ? <LawRights /> : <Loginpage />} />
            <Route path="mass-law-dm" element={user ? <LawDM /> : <Loginpage />} />
            <Route path="mass-law-minors" element={user ? <LawMinor /> : <Loginpage />} />
            {/* Mass Contracts Pages  */}
            <Route path="mass-contracts" element={user ? <MassContracts /> : <Loginpage />} />

            {/* Mass Reports Pages */}
            <Route path="mass-report" element={user ? <MassReports /> : <Loginpage />} />
            <Route path="mass-revsaile-rep" element={user ? <RevSalesReport /> : <Loginpage />} />
            <Route path="mass-reports-altman-calc" element={user ? <CalculatorAltman /> : <Loginpage />} />
            <Route path="mass-tax" element={user ? <MassTaxes /> : <Loginpage />} />
            <Route path="mass-tax-abt-calc" element={user ? <TaxAbtCalc /> : <Loginpage />} />
            <Route path="mass-reports-gold-silver" element={user ? <GoldSilver /> : <Loginpage />} />
            <Route path="mass-reports-exchange" element={user ? <ExChange /> : <Loginpage />} />
            <Route path="mass-report-jv" element={user ? <CreateJv /> : <Loginpage />} />
           
            <Route path="/mass-manage-form" element={user ? <SettingMg /> : <Loginpage />} />
            <Route path="massmg-client" element={user ? <MgClient /> : <Loginpage />} />
            <Route path="massmg-report-newexp" element={user ? <ExpNew /> : <Loginpage />} />
            <Route path="massmg-report-newsupp" element={user ? <SuppNew /> : <Loginpage />} />
            <Route path="massmg-employee" element={user ? <MgEmp /> : <Loginpage />} />
            <Route path="month-vat-rpt" element={user ? <MnthVPt /> : <Loginpage />} />

            {/* /pages/sidebar-pages/shaam/ShaamCodes */}

            <Route path="contact" element={user ? <Contact /> : <Loginpage />} />
            <Route path="math-courses" element={user ? <MathCourses /> : <Loginpage />} />
            <Route path="about-us" element={user ? <AboutMp /> : <Loginpage />} />
            <Route path="mass-fintec-prices" element={user ? <PricesFintech /> : <Loginpage />} />



            <Route path="*" element={<Error404 />} />
            <Route path="thank-you" element={<ThankYou />} />
          </Routes>

          </AuthProvider>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
