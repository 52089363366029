/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { Helmet } from "react-helmet";
import { FaSignature } from "react-icons/fa";
import { AiOutlineFileWord, AiOutlineFilePdf } from "react-icons/ai";
import {SiMicrosoftexcel} from "react-icons/si"

class ReportsHeComponent extends Component {

  render() {

    return (
      <div className="">
       
        {/* Page Content */}
        <div className="content container-fluid">
        
          <div className="row">
            {/*=================================== Report Div ============================================= */}
            <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
                <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> אישורים < FaSignature size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F314.docx?alt=media&token=6c37f09a-c0ba-4df0-a50b-684a9e1aa99b" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip"/></a>
                        <ReactTooltip id="downloadTip" place="top" effect="solid" className='tooltipp'>
                          Download .dox
                      </ReactTooltip>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F314.docx?alt=media&token=6c37f09a-c0ba-4df0-a50b-684a9e1aa99b" data-tip data-for="downloadTip" >
                                            אישור על ניהול פנקסי חשבונות ורשומות לפי חוק עסקאות
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F315.docx?alt=media&token=51b459c5-7ab7-43e7-b7f6-42fc375670ea" ><AiOutlineFileWord size={30} className="privacy contract-icon"  data-tip data-for="downloadTip"/></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F315.docx?alt=media&token=51b459c5-7ab7-43e7-b7f6-42fc375670ea" data-tip data-for="downloadTip">
                        דו"ח הכנסות השנתי                           
                        </a>
                      </td>
                    </tr>
                  </li>
                  
                </ul>
              </div>
            </div>
           
           {/* ========================= Report Div ================================== */}
           <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
                <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> מכתבים - לקוחות < FaSignature size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F323.docx?alt=media&token=46282c81-189f-4bab-b49d-de2f82be3aa8" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip"/></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F323.docx?alt=media&token=46282c81-189f-4bab-b49d-de2f82be3aa8" data-tip data-for="downloadTip">
                        נתונים להצהרת הון 
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F325.docx?alt=media&token=dddbf9cd-a262-4fbe-8932-206854195b20" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F325.docx?alt=media&token=dddbf9cd-a262-4fbe-8932-206854195b20" data-tip data-for="downloadTip">
                        הצהרת עוסק מייצג
                        </a>
                      </td>
                    </tr>
                  </li>
               

                  
                </ul>
              </div>
            </div>
           {/* ========================= Report Div ================================== */}
           <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
                <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> דוחות כספיים < FaSignature size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                <br></br>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F322.docx?alt=media&token=18befe29-478c-4acd-9d8c-ba2d88958a1c" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F322.docx?alt=media&token=18befe29-478c-4acd-9d8c-ba2d88958a1c" data-tip data-for="downloadTip" >
                        דו"ח התאמה לצורכי מס הכנסה   
                        </a>
                      </td>
                      
                    </tr>
                    <br></br>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F327.docx?alt=media&token=980f696f-3568-4543-bf75-1aae2c82869d" ><AiOutlineFileWord size={30} className="privacy contract-icon"  data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F327.docx?alt=media&token=980f696f-3568-4543-bf75-1aae2c82869d" data-tip data-for="downloadTip" >
                        דו"ח כספי מסוקר לחברה                                   
                        </a>
                      </td>
                    </tr>
                    <br></br>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F330.docx?alt=media&token=56a9202f-f930-4515-9cdd-15f32c6624e2" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F330.docx?alt=media&token=56a9202f-f930-4515-9cdd-15f32c6624e2" data-tip data-for="downloadTip" >
                        נספח להצהרת המנהלים – מלאי                                        
                        </a>
                      </td>
                    </tr>
                    <br></br>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F331.docx?alt=media&token=c9089566-b82e-454b-8589-ddb2447f7393" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F331.docx?alt=media&token=c9089566-b82e-454b-8589-ddb2447f7393" data-tip data-for="downloadTip" >
                        פרוטוקול  מינוי מורשה חתימה לחברה פרטית                                         
                        </a>
                      </td>
                    </tr>
                    <br></br>
                  </li>
                  

                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F337.docx?alt=media&token=eb48a733-e408-4152-83d8-a9bc563b005d" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip"/></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F337.docx?alt=media&token=eb48a733-e408-4152-83d8-a9bc563b005d" data-tip data-for="downloadTip" >
                        הצהרת  מנהלים                                         
                        </a>
                      </td>
                    </tr>
                    <br></br>
                  </li>
                   

                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F338.docx?alt=media&token=0ad76a20-2909-4df9-8db1-10de07d0e281" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F338.docx?alt=media&token=0ad76a20-2909-4df9-8db1-10de07d0e281" data-tip data-for="downloadTip" >
                        הצהרת מנהלים בדבר דו"ח ביניים                                         
                        </a>
                      </td>
                    </tr>
                    <br></br>
                  </li>
                  <br></br>
                </ul>
                <br></br>
              </div>
            </div>
           {/* ========================= Report Div ================================== */}
           <div className="col-sm-12 col-md-12 col-xl-6 ">
              <div className="topics">
                <h3 className="topic-title text-center">< FaSignature size={30} className="privacy" /> מכתבים לרשויות המס < FaSignature size={30} className="privacy" />  </h3>
                <ul className="topics-list">
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F316.docx?alt=media&token=c88ccc83-29be-4119-ba64-2c3196b31b79" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F316.docx?alt=media&token=c88ccc83-29be-4119-ba64-2c3196b31b79" data-tip data-for="downloadTip" >
                        בקשה לביטול קנסות לנישום – מס הכנסה 
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F317.docx?alt=media&token=863759c8-e780-418c-98c4-46a4e1a4ab5d" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F317.docx?alt=media&token=863759c8-e780-418c-98c4-46a4e1a4ab5d" data-tip data-for="downloadTip" >
                        בקשה לביטול קנסות לעוסק - מעמ 
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F318.docx?alt=media&token=d3e6b129-1163-41fd-b46a-eb8b457744d2" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F318.docx?alt=media&token=d3e6b129-1163-41fd-b46a-eb8b457744d2" data-tip data-for="downloadTip" >
                        בקשת אורכה לשנת המס
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F319.docx?alt=media&token=2468b485-f924-412e-a340-f1e68db6593a" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F319.docx?alt=media&token=2468b485-f924-412e-a340-f1e68db6593a" data-tip data-for="downloadTip" >
                                      הודעה על פי סעיף 91 (ד) (2)
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F321.docx?alt=media&token=946f3d76-0d24-46c4-8122-b78476712de6" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F321.docx?alt=media&token=946f3d76-0d24-46c4-8122-b78476712de6" data-tip data-for="downloadTip" >
                        שינוי כתובת                                      
                        </a>
                      </td>
                    </tr>
                  </li>
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F329.docx?alt=media&token=584ff570-f17d-4a46-a6cb-5b9f1097c2df" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F329.docx?alt=media&token=584ff570-f17d-4a46-a6cb-5b9f1097c2df" data-tip data-for="downloadTip" >
                        מס במקור שנוכה                                     
                        </a>
                      </td>
                    </tr>
                  </li> 

                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F332.docx?alt=media&token=932edae0-7844-45a7-a543-a23cae31ef2e" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F332.docx?alt=media&token=932edae0-7844-45a7-a543-a23cae31ef2e" data-tip data-for="downloadTip"  >
                        ני"ע לתיאום הוצאות רכב                                         
                        </a>
                      </td>
                    </tr>
                  </li> 

                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F333.docx?alt=media&token=44000860-7f48-43cc-a5d0-1a2b87f53d5f" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F333.docx?alt=media&token=44000860-7f48-43cc-a5d0-1a2b87f53d5f" data-tip data-for="downloadTip" >
                        תצהיר וכתב שחרור סופי, מלא ומוחלט                                         
                        </a>
                      </td>
                    </tr>
                  </li> 

                  
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F334.docx?alt=media&token=58535670-8ebc-4be7-9266-f0d3f7ace518" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F334.docx?alt=media&token=58535670-8ebc-4be7-9266-f0d3f7ace518" data-tip data-for="downloadTip" >
                        דו"ח תשלומים ע"ח הוצאות עודפות                                          
                        </a>
                      </td>
                    </tr>
                  </li> 

                  
                  
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F335.docx?alt=media&token=05e8fb81-64bb-43fd-8a27-da2c8dc6f04e" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F335.docx?alt=media&token=05e8fb81-64bb-43fd-8a27-da2c8dc6f04e" data-tip data-for="downloadTip" >
                        אישור יועץ המס על קיזוז ניכויים במקור                             
                                          
                        </a>
                      </td>
                    </tr>
                  </li> 

                  
                  
                  <li>
                    <tr>
                     
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F340.doc?alt=media&token=2e522ade-5027-4f5c-8eee-7130f63bed99" ><AiOutlineFileWord size={30} className="privacy contract-icon" data-tip data-for="downloadTip" /></a>
                      </td>
                      <td>
                        <a href="https://firebasestorage.googleapis.com/v0/b/massportall.appspot.com/o/contracts-reports-he%2F340.doc?alt=media&token=2e522ade-5027-4f5c-8eee-7130f63bed99" data-tip data-for="downloadTip" >
                        התחייבות עובד לרישום תקבול                                          
                        </a>
                      </td>
                    </tr>
                  </li> 

                  
                  
                </ul>
              </div>
            </div>
           {/* ========================= Report Div ================================== */}

         


            {/* <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Getting Started <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-6 ">
              <div className="topics">
                <h3 className="topic-title"><a href="/dark/app/administrator/knowledgebase-view"><i className="fa fa-folder-o" /> Lorem ipsum dolor <span>11</span></a></h3>
                <ul className="topics-list">
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                  <li><a href="/dark/app/administrator/knowledgebase-view"> Sed ut perspiciatis unde omnis? </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        {/* /Page Content */}
      </div>

    );
  }
}

export default ReportsHeComponent;
