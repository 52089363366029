import React ,{useState}from "react";
import { FaInfo } from "react-icons/fa";
// import taxProperty from "../../../../../mass-modules/mass-taxes/real-state/farm";
import priceGroup from './data/priceGroup'
import { BiShekel } from 'react-icons/bi'
export function TxTblPriceGroup(){

   

    
  
    return(<div className="row">
                         <div className="col-sm-10 col-md-10"></div>
                         <div className="col-sm-2 col-md-2">
                {/* Modal Button */}
                <a href="#" className="btn add-btn info-btn" data-toggle="modal" data-target="#add_employee"><FaInfo /> ملاحظة هامة </a>
                {/* Modal Button */}
              </div>
              <div id="add_employee" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title title-underline privacy-bold"></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="model-body">
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <ul className="privacy-ol privacy-ol-large ">
                            <li>
                                <p>اعتبارًا من 01/01/2004 ، تم تقسيم السيارات إلى 7 مجموعات أسعار
</p>
                            </li>
                            <li><p>من 01/01/1997 إلى 31/12/2003 تم تصنيف السيارات الجديدة إلى 6 مجموعات أسعار حسب سعر السيارة الجديدة للمستهلك.
</p></li>
                            <li><p>في رخصة السيارة يمكنك رؤية مجموعة الأسعار المحددة للسيارة
</p></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
            
            </div>
            </div>
            </div>
              <br></br>
              <br></br>
              <br></br>
				
                
                {
                priceGroup.priceGroup.map((data,index) =>	
                        
                    <div id="accordion">
                      
                       
                    <div className="card">
                        <div className="card-header" id={"heading"+index}>
                             <h4 className="mb-0">
                                <button  className="btn btn-dark  privacy"  data-toggle="collapse" data-target={"#collapse"+index} aria-expanded="true" aria-controls={"collapse"+index}>
                                    {data.year}
                                </button>
                             </h4>
                        </div>

                        <div id={"collapse"+index} className="collapse" aria-labelledby={"heading"+index} data-parent="#accordion">
                            <div className="card-body">
                            <table className="table table-nowrap table-striped mb-0 ">
                                <thead> 
                                
                               
                                

                                </thead>
                                    <thead>
                        
                                        <tr  >
                                            <th  className="privacy "> رقم المجموعة  </th>
                                            <th  className="privacy ">ابتداء من </th>
                                            
                                            <th className="privacy">حتى سعر</th>
                                            
                                            
                                            
                                            
                                            
                                        
                                            {/* <th>Country</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                       
                                        <tr >
                                            
                                            <td>{data.months.groups.group1.group}</td>
                                            <td>{data.months.groups.group1.min}</td>
                                            <td>{data.months.groups.group1.max} </td>
                                            
                                            

                                        </tr>
                                        <tr className="privacy ">
                                            
                                            <td>{data.months.groups.group2.group}</td>
                                            <td>{data.months.groups.group2.min}</td>
                                            <td>{data.months.groups.group2.max} </td>
                                            
                                            

                                        </tr>
                                        <tr >
                                            
                                            <td>{data.months.groups.group3.group}</td>
                                            <td>{data.months.groups.group3.min}</td>
                                            <td>{data.months.groups.group3.max} </td>
                                            
                                            

                                        </tr>
                                        <tr className="privacy ">
                                            
                                            <td>{data.months.groups.group4.group}</td>
                                            <td>{data.months.groups.group4.min}</td>
                                            <td>{data.months.groups.group4.max} </td>
                                            
                                            

                                        </tr>
                                        <tr >
                                            
                                            <td>{data.months.groups.group5.group}</td>
                                            <td>{data.months.groups.group5.min}</td>
                                            <td>{data.months.groups.group5.max} </td>
                                            
                                            

                                        </tr>
                                        <tr className="privacy ">
                                            
                                            <td>{data.months.groups.group6.group}</td>
                                            <td>{data.months.groups.group6.min}</td>
                                            <td>{data.months.groups.group6.max} </td>
                                            
                                            

                                        </tr>
                                        
                                         <tr  >
                                            
                                            <td>{data.months.groups.group7.group}</td>
                                            <td>{data.months.groups.group7.min}</td>
                                            <td>{data.months.groups.group7.max} </td>
                                            
                                            

                                        </tr> 
                                       
                                       
                                    </tbody>
                            </table>                               
                            </div>
                        </div>
                    </div>
            
            
              </div>)
                 } 
                  


                </div>)



}