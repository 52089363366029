let ha =[
 {
  ar: "حالة",
  he: "מצב",
  en: " Condition"
 },
 {
  ar: "حامل اسهم",
  he: "בעל מניות",
  en: "Shareholder\/ stockholder"
 },
 {
  ar: "حجم الاعمال التجارية",
  he: "פדיון של עסק\/ מחזור",
  en: "Turnover"
 },
 {
  ar: "حد ادنى",
  he: "מינימום",
  en: "Minimum"
 },
 {
  ar: "حد اقصى",
  he: "מקסימום",
  en: "Maximum"
 },
 {
  ar: "حد الربح",
  he: "שולי רווח",
  en: "Margin of profit"
 },
 {
  ar: "حدود التبادل",
  he: "תנאי סחר",
  en: "Terms of trade"
 },
 {
  ar: "حرب الاسعار",
  he: "מלחמת מחירים",
  en: "Price war"
 },
 {
  ar: "حرفة ",
  he: "מקצוע",
  en: "Profession"
 },
 {
  ar: "حركة",
  he: "תנועה",
  en: "Traffic"
 },
 {
  ar: "حركة النقد",
  he: "תזרים מזומנים",
  en: "Cash flow"
 },
 {
  ar: "حساب",
  he: "חשבון",
  en: "Account\/ invoice\/ bill"
 },
 {
  ar: "حساب اسمي",
  he: "חשבון נומינאלי",
  en: "Nominal account"
 },
 {
  ar: "حساب اعتماد",
  he: "חשבון אשראי",
  en: "Credit account"
 },
 {
  ar: "حساب السلف والقروض",
  he: "חשבון הלוואות",
  en: "Loan account"
 },
 {
  ar: "حساب المبيعات",
  he: "חשבון מכירות",
  en: "Sales account"
 },
 {
  ar: "حساب المراقبة",
  he: "חשבון ראשי",
  en: "Control account"
 },
 {
  ar: "حساب النقص",
  he: "חשבון גירעוני",
  en: "Deficiency account"
 },
 {
  ar: "حساب الودائع",
  he: "חשבון פיקדון",
  en: "Deposit account"
 },
 {
  ar: "حساب ايداع",
  he: "חשבון פיקדון",
  en: "Deposit account"
 },
 {
  ar: "حساب تقسيط الدفع",
  he: "חשבון תשלומים",
  en: "Installment account"
 },
 {
  ar: "حساب توفير",
  he: "חשבון חיסכון",
  en: "Savings account"
 },
 {
  ar: "حساب جاري",
  he: "חשבון שוטף",
  en: "Running account"
 },
 {
  ar: null,
  he: "חשבון עובר ושב",
  en: "Checking account\/ current account"
 },
 {
  ar: "حساب جاري خاص",
  he: "חשבון עובר ושב מיוחד",
  en: "Special checking account"
 },
 {
  ar: "حساب خارجي",
  he: "חשבון חיצוני",
  en: "External account"
 },
 {
  ar: "حساب ختامي",
  he: "חשבון סגור",
  en: "Closing account"
 },
 {
  ar: null,
  he: "חשבון סופי",
  en: "Final account"
 },
 {
  ar: "حساب شيكات",
  he: "חשבון שיקים",
  en: "Drawing account"
 },
 {
  ar: "حساب غير المقيم",
  he: "חשבון תושב חוץ",
  en: "Non- resident account"
 },
 {
  ar: "حساب غير شخصي",
  he: "חשבון לא אישי",
  en: "Impersonal account"
 },
 {
  ar: "حساب غير نشط",
  he: "חשבון לא פעיל",
  en: "Inactive account"
 },
 {
  ar: "حساب مدين",
  he: "חשבון במשיכת יתר",
  en: "Bank debit"
 },
 {
  ar: "حساب مشترك",
  he: "חשבון משותף",
  en: "Joint account"
 },
 {
  ar: "حساب مصرفي",
  he: "חשבון בנק",
  en: "Bank account"
 },
 {
  ar: "حساب مفتوح",
  he: "חשבון פתוח",
  en: "Open account"
 },
 {
  ar: "حساب مقيم",
  he: "פיקדון תושב מקומי\/ חשבון תושב מקומי",
  en: "Resident account"
 },
 {
  ar: "حساب مكشوف",
  he: "חשבון שבוצעה בו משיכת יתר",
  en: "Overdrawn account"
 },
 {
  ar: "حساب نشط",
  he: "חשבון פעיל",
  en: "Active account"
 },
 {
  ar: "حسابات مرحلية",
  he: "דו\"ח ביניים",
  en: "Interim account"
 },
 {
  ar: "حسابات موحدة",
  he: "חשבונות מאוחדים",
  en: "Consolidated accounts"
 },
 {
  ar: "حسب القيمة",
  he: "לפי הערך",
  en: "Ad valorem"
 },
 {
  ar: "حسم",
  he: "ניכוי",
  en: "Deduction"
 },
 {
  ar: null,
  he: "הנחה",
  en: "Allowance\/ discount\/ rebate"
 },
 {
  ar: "حسم تجاري",
  he: "הנחה קמעונית",
  en: "Trade discount"
 },
 {
  ar: null,
  he: null,
  en: null
 },
 {
  ar: "حصار",
  he: "בלוקדה",
  en: "Blockade"
 },
 {
  ar: "حصر",
  he: "הגבלה",
  en: "restriction"
 },
 {
  ar: "حصة",
  he: "מכסה",
  en: "Quota"
 },
 {
  ar: null,
  he: "מניה",
  en: "Share\/ stock"
 },
 {
  ar: "حصة ارباح",
  he: "דיווידנד",
  en: "Dividend"
 },
 {
  ar: "حصة ارباح اضافية",
  he: "דיווידנד נוסף",
  en: "Extra dividend"
 },
 {
  ar: "حصة ارباح عادية",
  he: "דיווידנד רגיל",
  en: "Regular dividend"
 },
 {
  ar: "حصة ارباح متجمعة",
  he: "דיווידנד נצבר",
  en: "Accumulated account "
 },
 {
  ar: "حصة ارباح مستحقة",
  he: "דיווידנד נצבר שעדיין לא שולם",
  en: "Accrued dividend"
 },
 {
  ar: "حصة ارباح نهاية السنة",
  he: "דיווידנד לסוף שנה",
  en: "Year end dividend"
 },
 {
  ar: "حضيض",
  he: "שפל",
  en: "through"
 },
 {
  ar: "حظر",
  he: "אמברגו",
  en: "Embargo"
 },
 {
  ar: "حظر الاستيراد",
  he: "איסור יבוא",
  en: "Import ban"
 },
 {
  ar: "حق التاليف والنشر",
  he: "זכויות יוצרים",
  en: "copyright"
 },
 {
  ar: "حق التوقيع",
  he: "זכות חתימה",
  en: "Right to sign"
 },
 {
  ar: "حق الحجز",
  he: "שיעבוד",
  en: "lien"
 },
 {
  ar: "حق بيع الاسهم بسعر محدود",
  he: "אופציית מכר",
  en: "Put option"
 },
 {
  ar: "حق شراء الاسهم بسعر محدود",
  he: "אופצצית רכש",
  en: "Call option"
 },
 {
  ar: "حقن",
  he: "הזרמה",
  en: "injection"
 },
 {
  ar: "حقوق الاختراع",
  he: "זכויות פטנט",
  en: "Patent rights"
 },
 {
  ar: "حقوق السحب الخاصة",
  he: "זכויות משיכה מיוחדים",
  en: "Special drawing rights"
 },
 {
  ar: "حقوق المساهم",
  he: "הון עצמי של פירמה",
  en: "equity"
 },
 {
  ar: "حكم",
  he: "בורר",
  en: "Arbiter\/ arbitrator"
 },
 {
  ar: "حمولة",
  he: "מטען",
  en: "Cargo\/ lading\/ freight"
 },
 {
  ar: "حمولة بالطن",
  he: "טונאז'\/ מספר הטונות",
  en: "tonnage"
 },
 {
  ar: "حواجز جمركية",
  he: "חומות מכס",
  en: "Tariff walls "
 },
 {
  ar: " حوافز التصدير",
  he: "תמריצי יצוא",
  en: "Export incentives"
 },
 {
  ar: "حوالة",
  he: "שיק\/ המחאה\/ שטר חליפין",
  en: "Check\/ cheque\/ draft\/ bill of exchange"
 }
]
export default ha;