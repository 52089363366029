import { Helmet } from "react-helmet";
import React, { Component, useEffect, useState } from 'react';
import MassBCalculator from "../../calculators/massb-calculator/MassBCalc";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../../firebase/config";
import XEMICON from '../../assets/img/xem.svg'
import LiteCoin from '../../assets/img/litecoin-cryptocurrency.svg'
import { analytics } from "../../firebase/config";
import { renderToString } from 'react-dom/server'
import jsPDF from 'jspdf';
import Calculator from '../../calculators/main/Calculator';
import { BiShekel } from "react-icons/bi";
import { FaGasPump, FaEthereum ,FaHourglassHalf,FaMoneyBillWave,FaPercent} from "react-icons/fa";
import { AiFillGolden } from "react-icons/ai";
import { RiExchangeDollarLine } from "react-icons/ri";
import { IoLogoBitcoin } from 'react-icons/io'
import { SiLitecoin, SiRipple, SiDash, SiMonero, SiBitcoincash } from 'react-icons/si'
import { User, Avatar_19, Avatar_07, Avatar_06, Avatar_14 } from '../../Entryfile/imagepath.jsx'
import {
  BarChart, Bar, Cell, ResponsiveContainer,
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import MassLows from "../../mass-modules/mass-law/pr-law/mass-law";
// import '../../assets/css/style.css'
import { TaxCalculatorComponent21 } from "../../calculators/components/TaxCalculatorComponent21";
// import Itt21 from "../Tables/basic22";
import { TaxCalculatorComponent22 } from "../../calculators/components/TaxCalculatorComponent22";
import Table21 from "../Tables/basic21/Table21C";
import Table22 from "../Tables/basic22/Table22C";
import Table23 from "../Tables/basic23/Table23C";
import ShaamTaxHe from "./sidebar-pages/shaam/ShaamTax-hebrew";
import ShaamTaxAr from "./sidebar-pages/shaam/ShaamTax-ar";
import ShaamTaxArHe from "./sidebar-pages/shaam/ShaamTax-ar-he";
import { Tax22Calc } from "../../calculators/components/Tax22Calc";
import { Tax21Calc } from "../../calculators/components/Tax21Calc";
import { Tax23Calc } from "../../calculators/components/Tax23Calc";
import ExchngRate from "../../calculators/exchange-rate/ExchngRate";
import EofSCalc from "../../calculators/WorkLawsCalc/EndOfServiceCalc";
import { CalculatorAnnual } from "../../calculators/WorkLawsCalc/CalculatorAnnual";
import MassLowsComp from "../../mass-modules/mass-law/pr-law/mass-law-comp";
import ReportsHeComp from "./sidebar-pages/contracts/reports-heComp";
import MpNew from "../massPortal-newComp";
import '../../assets/js/app.js';
import '../../index.css';
import ReactTooltip from "react-tooltip";
import VatCalc from "../../calculators/WorkLawsCalc/vat-calc/VatCalc";
// e.preventDefault();
// Current Date 
import { useGoldApi } from "../../hooks/useGoldApi";
import { useGoldApi2 } from "../../hooks/useGoldApi2";


const d = new Date().toLocaleString() + "";
let current = d.split(',');
const today = current[0];

const todaySplitArr = today.split('/');
const todayFrm = " " + todaySplitArr[1] + ' / ' + todaySplitArr[0] + ' / ' + todaySplitArr[2];

const barchartdata = [
  { y: '2006', "Total Income": 100, 'Total Outcome': 90 },
  { y: '2007', "Total Income": 75, 'Total Outcome': 65 },
  { y: '2008', "Total Income": 50, 'Total Outcome': 40 },
  { y: '2009', "Total Income": 75, 'Total Outcome': 65 },
  { y: '2010', "Total Income": 50, 'Total Outcome': 40 },
  { y: '2011', "Total Income": 75, 'Total Outcome': 65 },
  { y: '2012', "Total Income": 100, 'Total Outcome': 90 }
];
const linechartdata = [
  { y: '2006', "Total Sales": 50, 'Total Revenue': 90 },
  { y: '2007', "Total Sales": 75, 'Total Revenue': 65 },
  { y: '2008', "Total Sales": 50, 'Total Revenue': 40 },
  { y: '2009', "Total Sales": 75, 'Total Revenue': 65 },
  { y: '2010', "Total Sales": 50, 'Total Revenue': 40 },
  { y: '2011', "Total Sales": 75, 'Total Revenue': 65 },
  { y: '2012', "Total Sales": 100, 'Total Revenue': 50 }
];

// import {User} from '../../Entryfile/imagepath.jsx'


export default function Home() {
  // const {  goldOz, goldGram, goldKilo, silverPricee } = useGoldApi2();
  const {  goldOz, goldGram, goldKilo, silverPricee,priceOfGold } = useGoldApi();
  const [coins, setCoins] = useState({ coin: [{}, {}, {}, {}, {}, {}, {}, {}], loaded: false })
  const [coinType, setCoinType] = useState(['BTC', 'ETH', 'LTC', 'XRP', 'DASH', 'XMR', 'XEM', 'BCH'])
  const [coinTypeSmall, setCoinTypeSmall] = useState(['btc', 'eth', 'ltc', 'xrp', 'dash', 'xmr', 'xem', 'bch'])
 
  function getRate(currencyTwo) {
    const currencyOne = 'ILS';
    const urll = `https://v6.exchangerate-api.com/v6/0574ace0c52797d6db69b8f7/latest/${currencyTwo}`;
    const [rateEx, setRateEx] = useState();
    useEffect(() => {

      fetch(urll)
        .then(res => res.json())
        .then(data => {
          // console.log(data);
          const ratee = data.conversion_rates[currencyOne];
          const rateD_four = ratee.toFixed(2);
          setRateEx(rateD_four)

        });
    });

    return (rateEx);
  }
  const url = 'https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BTC,ETH,LTC,XRP,DASH,XMR,XEM,BCH&tsyms=USD';

  useEffect(() => {
    function getCrypto() {

      fetch(url)
        .then(res => res.json())
        .then(data => {
          // console.log(data);
          let exchange = [];

          coinType.forEach(type => {
            exchange.push({
              'coin': data['DISPLAY'][`${type}`]['USD']['FROMSYMBOL'],
              'price': data['DISPLAY'][`${type}`]['USD']['PRICE'],
              'percent': data['DISPLAY'][`${type}`]['USD']['CHANGEPCT24HOUR']
            });

          })
          // console.log(exchange);
          setCoins({
            loaded: true,
            coin: exchange
          })

        });

    }
    getCrypto();
  }, [url]);

  //commify function 
  function commify(n) {
    // console.log("commify: ", n )
    // console.log("commify: ", typeof(n) )
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  }






  //MODAL_Calc
  const [showCalc, setshowCalc] = useState(true)
  const handleCloseCalc = () => setshowCalc(false);
  const handleShowCalc = () => setshowCalc(true);

  // const Prints = () => (
  //   <div>
  //     <h3>Time & Materials Statement of Work (SOW)</h3>
  //     <h4>General Information</h4>
  //     <table id="tab_customers" class="table table-striped">
  //       <colgroup>
  //         <col span="1" />
  //         <col span="1" />
  //       </colgroup>
  //       <thead>
  //         <tr class="warning">
  //           <th>SOW Creation Date</th>
  //           <th>SOW Start Date</th>
  //           <th>Project</th>
  //           <th>Last Updated</th>
  //           <th>SOW End Date</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         <tr>
  //           <td>Dec 13, 2017</td>
  //           <td>Jan 1, 2018</td>
  //           <td>NM Connect - NMETNMCM</td>
  //           <td>Dec 13, 2017</td>
  //           <td>Dec 31, 2018</td>
  //         </tr>
  //       </tbody>
  //     </table>
  //     <p>
  //       This is a Time and Materials Statement of Work between Northwestern Mutual
  //       Life Insurance Company and Infosys with all general terms and conditions
  //       as described in the current Master Agreement and its related documents
  //     </p>
  //   </div>
  // );


  return (
    <div className="page-wrapper">
      <Helmet>
        <title>MassPortal</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      {/* Page Content */}

      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">

          <div className="row">

            <div className="col-sm-12">
              <h3 className="page-title"> <span className="date-d" dir="ltr">{todayFrm}</span></h3>
            </div>




            {/* <div className="col-sm-12">                
                <ul className="breadcrumb">
                  <li className="breadcrumb-item ">الرئيسية</li>   
                   <li className="breadcrumb-item  ">أسعار الصرف اليوم</li>       
                </ul>
              </div> */}

          </div>
        </div>
        {/* /Page Header */}
        {/* Calculator Mdal  */}


        {/*================== Page Currency Component ===================== */}
        <div className="row">
          <h3 className="card-title">  أسعار الصرف </h3>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <i className="fa fa-usd" />
                </span>
                <div className="dash-widget-info">
                  {/* <h3>0.00</h3> */}
                  <h3>{getRate('USD')}</h3>
                  <a href="exchange-rate" data-tip data-for="ex-tip">
                    <RiExchangeDollarLine className="privacy exhange-icon" size={25} />
                  </a>
                  <ReactTooltip id="ex-tip" place="right" effect="solid">
                    احسب سعر الصرف
                  </ReactTooltip>
                  <span>شيكل   </span>
                  <span className='span2'>دولار</span>
                </div>
              </div>

            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon-jordan">JD</span>
                <div className="dash-widget-info">
                  {/* <h3>0.00</h3> */}
                  <h3>{getRate('JOD')}</h3>
                  <a href="exchange-rate" data-tip data-for="ex-tip">
                    <RiExchangeDollarLine className="privacy exhange-icon" size={25} />
                  </a>
                  <ReactTooltip id="ex-tip" place="right" effect="solid">
                    احسب سعر الصرف
                  </ReactTooltip>
                  <span>شيكل</span>
                  <span className='span2'>دينار</span>

                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon"><i className="fa fa-eur" /></span>
                <div className="dash-widget-info">
                  <h3>{getRate('EUR')}</h3>
                  {/* <h3>0.00</h3> */}
                  <a href="exchange-rate" data-tip data-for="ex-tip">
                    <RiExchangeDollarLine className="privacy exhange-icon" size={25} />
                  </a>
                  <ReactTooltip id="ex-tip" place="right" effect="solid">
                    احسب سعر الصرف
                  </ReactTooltip>
                  <span>شيكل</span>
                  <span className='span2'>يورو</span>
                </div>
              </div>
            </div>
          </div>


          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon"><i className="fa fa-jpy" aria-hidden="true"></i></span>
                <div className="dash-widget-info">
                  {/* <h3>0.00</h3> */}
                  {/* <h3>{getRate('CNY')}</h3> */}
                  <h3>{getRate('CNY')}</h3>
                  <a href="exchange-rate" data-tip data-for="ex-tip">
                    <RiExchangeDollarLine className="privacy exhange-icon" size={25} />
                  </a>
                  <ReactTooltip id="ex-tip" place="right" effect="solid">
                    احسب سعر الصرف
                  </ReactTooltip>
                  <span>شيكل</span>
                  <span className='span2'>يوان</span>
                </div>
              </div>
            </div>
          </div>
        </div>





        {/*============================= Page Currency Component ============================ */}
        <div className="row">
          <h3 className="card-title">معطيات مهمة </h3>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <FaGasPump />
                </span>
                <div className="dash-widget-info">
                  <h3>7.78</h3>
                  <span>شيكل</span>
                  <span className='span2'> بنزين 95</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <FaPercent />
                </span>
                <div className="dash-widget-info">
                  <h3>242</h3>
                  <span>شيكل</span>
                  <span className='span2'> نقطة التزكية</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon"><FaMoneyBillWave /></span>
                <div className="dash-widget-info">
                  <h3>5880.02</h3>
                  <span>شيكل</span>
                  <span className='span2'>راتب شهري</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon"><FaHourglassHalf /></span>
                <div className="dash-widget-info">
                  <h3>32.30</h3>
                  <span>ساعة</span>
                  <span className='span2'>ساعة عمل</span>
                </div>
              </div>
            </div>
          </div>


        </div>

        {/* =====================================Gold And Silver Prices ===================== */}

        <div className="row ">
          <h3 className="card-title">أسعار المعادن </h3>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <AiFillGolden />
                </span>
                <div className="dash-widget-info">
                  <h3>{commify((goldOz))} <span> < BiShekel size={18} /> </span></h3>

                  <span className='span2'> ذهب (OZ)</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <AiFillGolden />
                </span>
                <div className="dash-widget-info">
                  <h3>{commify(goldGram)}<span>< BiShekel size={16} /> </span></h3>
                  <span></span>
                  <span className='span2'>ذهب  (غرام)</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon"><AiFillGolden /></span>
                <div className="dash-widget-info">
                  <h3>{commify((goldKilo))}<span>< BiShekel size={18} /></span></h3>
                  {/* <span>سعر</span> */}
                  <span className='span2 '> ذهب  (كغم) </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div className="card dash-widget">
              <div className="card-body">
                <span className="dash-widget-icon">
                  <AiFillGolden />
                </span>
                <div className="dash-widget-info">
                  <h3>{commify((silverPricee*1).toFixed(2))} <span> < BiShekel size={18} /> </span></h3>
                  {/* <h3> {(1234.56789).toLocaleString(undefined, { maximumFractionDigits: 2 })}</h3> */}

                  <span></span>
                  <span className='span2'>فضة  (غرام) </span>
                </div>
              </div>
            </div>
          </div>


        </div>

        {/* ===================================== Gold And Silver Prices ==================== */}

        {/* ===================================== Cryptocurrency ==================== */}
        <div className="row">
          <h3 className="card-title">   أسعار العملات الرقمية </h3>
          {coins.coin && coins.coin.map((crypto, i) => (

            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3" dir="ltr">
              <div className="card dash-widget">
                <div className="card-body">
                  <span className="dash-widget-icon">
                    {coinTypeSmall[i] == 'btc' && <IoLogoBitcoin style={{ transform: "rotate(20deg)" }} />}
                    {coinTypeSmall[i] == 'eth' && <FaEthereum />}
                    {/* {coinTypeSmall[i] == 'ltc' && <SiLitecoin />} */}
                    {coinTypeSmall[i] == 'xrp' && <SiRipple />}
                    {coinTypeSmall[i] == 'dash' && <SiDash />}
                    {coinTypeSmall[i] == 'xmr' && <SiMonero />}
                    {coinTypeSmall[i] == 'bch' && <IoLogoBitcoin style={{ transform: "rotate(-20deg)" }} />}
                    {coinTypeSmall[i] == 'xem' && <img style={{ height: 24, width: 24 }} src={XEMICON} />}
                    {coinTypeSmall[i] == 'ltc' && <img style={{ height: 24, width: 24 }} src={LiteCoin} />}
                    {/* <img  style={{height:65, width:62}} src={`https://s3-us-west-2.amazonaws.com/s.cdpn.io/1040483/${coinTypeSmall[i]}.png`} /> */}
                  </span>
                  <div className="dash-widget-info">
                    {/* <h3>0.00</h3> */}
                    <h3>{crypto.price}</h3>


                    <span className={(crypto.percent) * 1 >= 0 ? "pos" : "neg "}> {crypto.percent} %  </span>
                    <span className='span2'> {coinType[i]}</span>
                  </div>
                </div>

              </div>
            </div>
          ))}







        </div>
        {/* ===================================== Cryptocurrency ==================== */}


        {/* ===================== Currency EXCHANGE ======================*/}
        <div >
          <ExchngRate />
        </div>
        {/* ===================== Currency EXCHANGE ======================*/}
        {/*============================= New in MassPortal ==== */}
        <div className="row">
          <MpNew />
        </div>
        {/* ======================================================== */}
        <div className="row">
          <ShaamTaxArHe />
        </div>

      

        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Tax23Calc />
          </div>
          <div className="col-dm-12 col-md-6">
            <Table23 />

          </div>
        </div>
        <div className="row">

          <div className="col-md-6 ">
            <Tax22Calc />
          </div>
          <div className="col-md-6 ">

            <Table22 />

          </div>



        </div>
        {/* <div className="row">

          <div className="col-md-6 ">

            <Tax21Calc />

          </div>


          <div className="col-md-6 ">

            <Table21 />

          </div>

        </div> */}




{/* 
        <div className="row">

          <div className="col-md-6 text-center">
            <br />
            <VatCalc />
          </div>
          <div className="col-md-6 text-center" >
           
            <MassLowsComp />
          </div>
        </div> */}
      </div>

      {/* /Page Content */}
    </div>


  );

}

