import React , { useState , useEffect } from 'react'
import {v4 as uuidv4} from 'uuid'
import { BiShekel,BiEdit } from 'react-icons/bi'
import { CgPlayListAdd} from 'react-icons/cg'
import { MdDeleteOutline} from 'react-icons/md'



 export  function Simpleroicalculator() {
    const [totalPercentage,setTotalPercentage]=useState(0)
    const [amountsRev,setAmountsRev]=useState(0)
    const [isEditingRev, setIsEditingRev] = useState(false);
    const [invoiceNumberRev,setInvoiceNumberRev] = useState('');
    const [discrepitionRev,setDiscrepitionRev] = useState('');
    const [firstCashRev,setFirstCashRev] = useState(0);
    const [monthlyCashRev,setMonthlyCashRev] = useState(0);
    const [firstCashRevTotal,setFirstCashRevTotal] = useState(0);
    const [monthlyCashRevTotal,setMonthlyCashRevTotal] = useState(0);
    const [amountRev,setAmountRev] = useState(0);
    const [listRev,setListRev] = useState([]);
    const [amountsExp,setAmountsExp]=useState(0)
    const [isEditingExp, setIsEditingExp] = useState(false);
    const [invoiceNumberExp,setInvoiceNumberExp] = useState('');
    const [discrepitionExp,setDiscrepitionExp] = useState('');
    const [firstCashExp,setFirstCashExp] = useState(0);
    const [monthlyCashExp,setMonthlyCashExp] = useState(0);
    const [firstCashExpTotal,setFirstCashExpTotal] = useState(0);
    const [monthlyCashExpTotal,setMonthlyCashExpTotal] = useState(0);
    const [amountExp,setAmountExp] = useState(0);
    const [listExp,setListExp] = useState([]);
    
   const handleSubmitExp = (e) => {
    e.preventDefault()
    
    const newItemsExp = {
        id: uuidv4(),
        invoiceNumberExp,
        discrepitionExp,
        firstCashExp,
        monthlyCashExp,
        amountExp,
     
      }
      
      setDiscrepitionExp("")
      setFirstCashExp("")
      setAmountExp("")
      setInvoiceNumberExp("")
      setMonthlyCashExp("")
      setListExp([...listExp, newItemsExp])
      setIsEditingExp(false)
   
   }
  
  useEffect(() => {
    const calculateAmountExp = (amount) => {
      let disExp = (monthlyCashExp *12 )
      let rsExp = ( disExp+(firstCashExp*1) )
      setAmountExp(rsExp)
      //console.log(quantity,"quantity",price,"price",discountForProduct,"discount",amount,"amout")
    //  console.log("result",(quantity * (price - (price * discountForProduct / 100 ))))
    }

    calculateAmountExp(amountExp)
  }, [amountExp, firstCashExp, discrepitionExp, setAmountExp,monthlyCashExp,setMonthlyCashExp])
  const editRowExp = (id) => {
    const editingExp = listExp.find((row) => row.id === id)
    setListExp(listExp.filter((row) => row.id !== id))
    setIsEditingExp(true)
    setDiscrepitionExp(editingExp.discrepitionExp)
    setFirstCashExp(editingExp.firstCashExp)
    setMonthlyCashExp(editingExp.monthlyCashExp)
  }


    const handleSubmitRev = (e) => {
        
        e.preventDefault()
          const newItemsRev = {
            id: uuidv4(),
            invoiceNumberRev,
            
            discrepitionRev,
            firstCashRev,
            monthlyCashRev,
            amountRev,
         
          }
          setDiscrepitionRev("")
          setFirstCashRev("")
          setAmountRev("")
          setInvoiceNumberRev("")
          setMonthlyCashRev("")
          setListRev([...listRev, newItemsRev])
          setIsEditingRev(false)
        }
        useEffect(() => {
           
            let ams=0
            listRev.map((item)=>{
              ams += item.amountRev
              setAmountsRev(ams)
             
        
            })
            let firstRevTotal=0
            listRev.map((item)=>{
              firstRevTotal += (item.firstCashRev)*1
              setFirstCashRevTotal(firstRevTotal)
             
        
            })
            let monthlyRevTotal=0
            listRev.map((item)=>{
              monthlyRevTotal += (item.monthlyCashRev)*1
              setMonthlyCashRevTotal(monthlyRevTotal)
             
        
            })
           
             
           
          },[amountRev,monthlyCashRev,firstCashRev,discrepitionRev,listRev,amountsRev,firstCashRevTotal,monthlyCashRevTotal])
        useEffect(() => {
           
            let ams=0
            listExp.map((item)=>{
              ams += item.amountExp
              setAmountsExp(ams)
             
        
            })
            let firstExpTotal=0
            listExp.map((item)=>{
              firstExpTotal += (item.firstCashExp)*1
              setFirstCashExpTotal(firstExpTotal)
             
        
            })
            let monthlyExpTotal=0
            listExp.map((item)=>{
              monthlyExpTotal += (item.monthlyCashExp)*1
              setMonthlyCashExpTotal(monthlyExpTotal)
             
        
            })
             
           
          },[amountExp,monthlyCashExp,firstCashExp,discrepitionExp,listExp,amountsExp,firstCashExpTotal,monthlyCashExpTotal])
         
      useEffect(() => {
        const calculateAmountRev = (amount) => {
          let disRev = (monthlyCashRev *12 )
          let rsReV = ( disRev+(firstCashRev*1) )
          setAmountRev(rsReV)
          //console.log(quantity,"quantity",price,"price",discountForProduct,"discount",amount,"amout")
        //  console.log("result",(quantity * (price - (price * discountForProduct / 100 ))))
        }
    
        calculateAmountRev(amountRev)
      }, [amountRev, firstCashRev, discrepitionRev, setAmountRev,monthlyCashRev,setMonthlyCashRev])
     
      // Calculate total amount of items in table
  

  // Edit function
  const editRowRev = (id) => {
    const editingRev = listRev.find((row) => row.id === id)
    setListRev(listRev.filter((row) => row.id !== id))
    setIsEditingRev(true)
    setDiscrepitionRev(editingRev.discrepitionRev)
    setFirstCashRev(editingRev.firstCashRev)
    setMonthlyCashRev(editingRev.monthlyCashRev)
  }
  const deleteRowRev = (id) => setListRev(listRev.filter((row) => row.id !== id))
  const deleteRowExp = (id) => setListExp(listExp.filter((row) => row.id !== id))

  if(amountsRev!==0){
    setTotalPercentage((((amountsRev-amountsExp)/amountsRev).toFixed(2))*100);
  }

    return(
        <div>
        <div className='card-header '><h3 className='privacy'> الايرادات</h3></div>
        <form onSubmit={handleSubmitRev}>
        <div className="table-responsive">
                  <table className="table table-hover table-white">
                    <thead>
                      <tr>
                        {/* <th style={{width: '20px'}}>#</th> */}
                        <th className="col-sm-2">الوصف </th>
                      
                        
                        <th className="col-sm-2" >الدفعة الاولى  </th>
                        <th className="col-sm-2">دفعة شهرية <span className='privacy'>12شهر</span></th>
                        <th className="col-sm-2">مجموع</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                     
                      <tr>
                        {/* <td>1</td> */}
                        <td>
                          <input
                           className="form-control"
                            type="text"
                             style={{minWidth: '150px'}}
                             value={discrepitionRev}
                             onChange={e=>setDiscrepitionRev(e.target.value)}
                             />
                        </td>
                        
                        
                        <td>
                          <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            value={firstCashRev}
                            onChange={e=>setFirstCashRev(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            value={monthlyCashRev}
                            onChange={e=> setMonthlyCashRev(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            readOnly 
                            style={{width: '120px'}} 
                            type="text" 
                            value={(amountRev)}
                            />
                        </td>
                        {/* <td>
                            <a 
                            // href="javascript:void(0)"
                             className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a></td> */}
                      </tr>
                      <tr>
                      <td 
                      colSpan={7}
                      className='text-center'
                       >
                        <button 
                        type='submit'
                        //  onClick={handleSubmit}
                        // href="javascript:void(0)"
                         className="text-success font-18 btn"
                         
                          >
                            {isEditingRev ? <span className='fs-4'  title="تعديل ">
                            تعديل  <BiEdit size={40} /> </span> : <span className='fs-4'  title="إضافة ">
                            إضافة  <CgPlayListAdd size={40} /> </span>}</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
        </form>
        <table className="table table-hover table-white">
        <thead>
          <tr>
            <th style={{width: '20px'}}>#</th>
           
            
            <th className="col-sm-2" >الوصف </th>
            <th className="col-sm-2" >الدفعة الأولى  </th>
            <th className="col-sm-2" >دفعة شهرية <span className='privacy'>12شهر</span></th>
            <th  className="col-sm-2">مجموع</th>
            <th> تعديل</th>
            <th>حذف </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {listRev.map((item,index)=>(
                  
          <tr key={item.id}>
          <td>{index+1}</td>
          <td>
            <input
             className="form-control"
              type="text"
               style={{minWidth: '150px'}}
              value={item.discrepitionRev}
               />
          </td>
          
         
          <td>
            <input
             className="form-control"
              style={{width: '100px'}} 
              type="text" 
              value={item.firstCashRev}
              />
          </td>
          <td>
            <input
             className="form-control"
              style={{width: '80px'}} 
              type="text" 
              value={item.monthlyCashRev}
              />
          </td>
          <td>
            <input
             className="form-control amount"
              readOnly 
              style={{width: '120px'}} 
              type="text" 
              value={item.amountRev}
              />
          </td>
          <td>
      <a onClick={() => editRowRev(item.id)}
      // href="javascript:void(0)"
               className="text-success font-18" title="تعديل"><BiEdit size={30}/>
      </a>
    </td>
    <td>
      <a onClick={() => deleteRowRev(item.id)}
      // href="javascript:void(0)"
               className="text-danger font-18" title="حذف"><MdDeleteOutline size={30}/>
      </a>
    </td>
          {/* <td>
              <a 
              // href="javascript:void(0)"
               className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a>
               </td> */}
        </tr>
    
          ))}
          <tr>
          <td />
          <td />
         
          <td className="page-title">{firstCashRevTotal}</td>
          <td className="page-title">{monthlyCashRevTotal} </td>
          <td className="page-title">
          {amountsRev.toLocaleString()}</td>
          <td />
          <td />
          
          
     </tr>

        
         
        </tbody>
      </table>
        <div className='card-header '><h3 className='privacy'> النفقات</h3></div>
        <form onSubmit={handleSubmitExp}>
        <div className="table-responsive">
                  <table className="table table-hover table-white">
                    <thead>
                      <tr>
                        {/* <th style={{width: '20px'}}>#</th> */}
                        <th className="col-sm-2">الوصف </th>
                      
                        
                        <th className="col-sm-2" >الدفعة الاولى  </th>
                        <th className="col-sm-2" >دفعة شهرية <span className='privacy'>12شهر</span></th>
                        <th className="col-sm-2">مجموع</th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                     
                      <tr>
                        {/* <td>1</td> */}
                        <td>
                          <input
                           className="form-control"
                            type="text"
                             style={{minWidth: '150px'}}
                             value={discrepitionExp}
                             onChange={e=>setDiscrepitionExp(e.target.value)}
                             />
                        </td>
                        
                        
                        <td>
                          <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            value={firstCashExp}
                            onChange={e=>setFirstCashExp(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            style={{width: '100px'}} 
                            type="text" 
                            value={monthlyCashExp}
                            onChange={e=> setMonthlyCashExp(e.target.value)}
                            />
                        </td>
                        <td>
                          <input
                           className="form-control"
                            readOnly 
                            style={{width: '120px'}} 
                            type="text" 
                            value={(amountExp)}
                            />
                        </td>
                        {/* <td>
                            <a 
                            // href="javascript:void(0)"
                             className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a></td> */}
                      </tr>
                      <tr>
                      <td 
                      colSpan={7}
                      className='text-center'
                       >
                        <button 
                        type='submit'
                        //  onClick={handleSubmit}
                        // href="javascript:void(0)"
                         className="text-success font-18 btn"
                         
                          >
                            {isEditingExp ? <span className='fs-4'  title="تعديل ">
                            تعديل  <BiEdit size={40} /> </span> : <span className='fs-4'  title="إضافة ">
                            إضافة  <CgPlayListAdd size={40} /> </span>}</button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
        </form>
        <table className="table table-hover table-white">
        <thead>
          <tr>
            <th style={{width: '20px'}}>#</th>
           
            
            <th className="col-sm-2">الوصف </th>
            <th className="col-sm-2">الدفعة الأولى  </th>
            <th className="col-sm-2">دفعة شهرية <span className='privacy'>12شهر</span></th>
            <th className="col-sm-2">مجموع</th>
            <th> تعديل</th>
            <th>حذف </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {listExp.map((item,index)=>(
                  
          <tr key={item.id}>
          <td>{index+1}</td>
          <td>
            <input
             className="form-control"
              type="text"
               style={{minWidth: '150px'}}
              value={item.discrepitionExp}
               />
          </td>
          
         
          <td>
            <input
             className="form-control"
              style={{width: '100px'}} 
              type="text" 
              value={item.firstCashExp}
              />
          </td>
          <td>
            <input
             className="form-control"
              style={{width: '80px'}} 
              type="text" 
              value={item.monthlyCashExp}
              />
          </td>
          <td>
            <input
             className="form-control amount"
              readOnly 
              style={{width: '120px'}} 
              type="text" 
              value={item.amountExp}
              />
          </td>
          <td>
      <a onClick={() => editRowExp(item.id)}
      // href="javascript:void(0)"
               className="text-success font-18" title="تعديل"><BiEdit size={30}/>
      </a>
    </td>
    <td>
      <a onClick={() => deleteRowExp(item.id)}
      // href="javascript:void(0)"
               className="text-danger font-18" title="حذف"><MdDeleteOutline size={30}/>
      </a>
    </td>
          {/* <td>
              <a 
              // href="javascript:void(0)"
               className="text-danger font-18" title="Remove"><i className="fa fa-trash-o" /></a>
               </td> */}
        </tr>
          ))}
          <tr>
               <td />
               <td />
              
               <td className="page-title">{firstCashExpTotal}</td>
               <td className="page-title">{monthlyCashExpTotal} </td>
               <td className="page-title">
               {amountsExp.toLocaleString()}</td>
               <td />
               <td />
               
               
          </tr>
         
        </tbody>
      </table>
      <div className="table-responsive">
      <table className="table  table-white">
      <thead>
               <tr>
                <th></th>
                <th className='privacy page-title'>دفعة اولى </th>
                <th className='privacy page-title'> دفعة شهرية <soan className='privacy'>12شهر</soan></th>
                <th className='privacy page-title'> المجموع</th>
               </tr>
      </thead>
        <tbody>
          <tr>
            <td className='privacy page-title'>الايرادات </td>
            <td className="page-title"> {firstCashRevTotal.toFixed(2)}<BiShekel /></td>
            <td className="page-title" >{monthlyCashRevTotal.toFixed(2)}<BiShekel /></td>
            <td className="page-title">{amountsRev.toFixed(2)}<BiShekel /></td>
           
          </tr>
          <tr>
            <td className='privacy page-title'>
              النفقات
            </td>
            <td className="page-title"> {firstCashExpTotal.toFixed(2)}<BiShekel /></td>
            <td className="page-title" >{monthlyCashExpTotal.toFixed(2)}<BiShekel /></td>
            <td className="page-title">{amountsExp.toFixed(2)}<BiShekel /></td>
          </tr>
          <tr>
            <td />
            <td  className='privacy page-title'> { (firstCashRevTotal - firstCashExpTotal).toFixed(2) }<BiShekel /></td>
            <td className='privacy page-title' > { (monthlyCashRevTotal - monthlyCashExpTotal).toFixed(2) }<BiShekel /></td>
            <td  className='privacy page-title'>
            {(amountsRev-amountsExp).toFixed(2)}<BiShekel />
            </td>
           <br></br>
          </tr>
          <tr>
          <td />
          <td />
          <td className='privacy page-title'>
          نسبة الربح  </td>
          <td className='privacy page-title' > {totalPercentage}%</td>

          </tr>

         
          
        </tbody>
      </table>                               
    </div>
    
        </div> 
        
    )
   
//     const [showDev, setShowDev] = useState(false)
//     const [intervalZero,setIntervalZero]=useState('');
//     const [intervalOne,setIntervalOne]=useState('');
//     const [intervalTwo,setIntervalTwo]=useState('');
//     const [intervalThree,setIntervalThree]=useState('');
//     const [intervalFour,setIntervalFour]=useState('');
//     const [intervalFive,setIntervalFive]=useState('');
//     const [intervalSix,setIntervalSix]=useState('');
//     const [intervalSeven,setIntervalSeven]=useState('');
//     const [intervalEight,setIntervalEight]=useState('');
//     const [intervalNine,setIntervalNine]=useState('');
//     const [percentageFrom,setPercentageFrom]=useState('');
//     const [percentageTo,setPercentageTo]=useState('');
//     const [typeZero,setTypeZero] = useState('');
//     const [typeOne,setTypeOne] = useState('');
//     const [typeTwo,setTypeTwo] = useState('');
//     const [typeThree,setTypeThree] = useState('');
//     const [typeFour,setTypeFour] = useState('');
//     const [typeFive,setTypeFive] = useState('');
//     const [typeSix,setTypeSix] = useState('');
//     const [typeSeven,setTypeSeven] = useState('');
//     const [typeEight,setTypeEight] = useState('');
//     const [typeNine,setTypeNine] = useState('');
//     const [colorZero,setColorZero] = useState(false);
//     const [colorOne,setColorOne] = useState(false);
//     const [colorTwo,setColorTwo] = useState(false);
//     const [colorThree,setColorThree] = useState(false);
//     const [colorFour,setColorFour] = useState(false);
//     const [colorFive,setColorFive] = useState(false);
//     const [colorSix,setColorSix] = useState(false);
//     const [colorSeven,setColorSeven] = useState(false);
//     const [colorEight,setColorEight] = useState(false);
//     const [colorNine,setColorNine] = useState(false);
//     const [showZero,setShowZero] = useState(false);
//     const [showOne,setShowOne] = useState(false);
//     const [showTwo,setShowTwo] = useState(false);
//     const [showThree,setShowThree] = useState(false);
//     const [showFour,setShowFour] = useState(false);
//     const [showFive,setShowFive] = useState(false);
//     const [showSix,setShowSix] = useState(false);
//     const [showSeven,setShowSeven] = useState(false);
//     const [showEight,setShowEight] = useState(false);
//     const [showNine,setShowNine] = useState(false);
   
//     const handleSubmit = (e) =>{
//         e.preventDefault();
//         if(((percentageFrom && percentageTo) && (intervalZero||intervalOne||intervalTwo||intervalThree||intervalFour||intervalFive||intervalSix||intervalSeven||intervalEight||intervalNine) ) >0)
//         {setShowDev(true);}
//         if(intervalZero>0){setShowZero(true)}
//         if(intervalOne>0){setShowOne(true)}
//         if(intervalTwo>0){setShowTwo(true)}
//         if(intervalThree>0){setShowThree(true)}
//         if(intervalFour>0){setShowFour(true)}
//         if(intervalFive>0){setShowFive(true)}
//         if(intervalSix>0){setShowSix(true)}
//         if(intervalSeven>0){setShowSeven(true)}
//         if(intervalEight>0){setShowEight(true)}
//         if(intervalNine>0){setShowNine(true)}
//         if(typeZero == '' || typeZero == 'minus'){
    
//             setColorZero(false)
//         }
//          if( typeZero =='plus'){
//             setColorZero(true)
//         }
//          if(typeOne == '' || typeOne == 'minus'){
    
//             setColorOne(false)
//         }
//          if( typeOne =='plus'){
//             setColorOne(true)
//         }
//          if(typeTwo == '' || typeTwo == 'minus'){
    
//             setColorTwo(false)
//         }
//          if( typeTwo =='plus'){
//             setColorTwo(true)
//         }
//          if(typeThree == '' || typeThree == 'minus'){
    
//             setColorThree(false)
//         }
//          if( typeThree =='plus'){
//             setColorThree(true)
//         }
//          if(typeFour == '' || typeFour == 'minus'){
    
//             setColorFour(false)
//         }
//          if( typeFour =='plus'){
//             setColorFour(true)
//         }
//          if(typeFive == '' || typeFive == 'minus'){
    
//             setColorFive(false)
//         }
//          if( typeFive =='plus'){
//             setColorFive(true)
//         }
//          if(typeSix == '' || typeSix == 'minus'){
    
//             setColorSix(false)
//         }
//          if( typeSix =='plus'){
//             setColorSix(true)
//         }
//          if(typeSeven == '' || typeSeven == 'minus'){
    
//             setColorSeven(false)
//         }
//          if( typeSeven =='plus'){
//             setColorSeven(true)
//         }
//          if(typeEight == '' || typeEight == 'minus'){
    
//             setColorEight(false)
//         }
//          if( typeEight =='plus'){
//             setColorEight(true)
//         }
//          if(typeNine == '' || typeNine == 'minus'){
    
//             setColorNine(false)
//         }
//          if( typeNine =='plus'){
//             setColorNine(true)
//         }
        
//     }


//   return (
    
//     <div className='row'>
//         <div className='col-xl-10 d-flex'>
//             <div className='card flex-fill'>
//                 <div className='card-header'>
//                     <h4 className='card-title mb-0 privacy '> حاسبة عائد الاستثمار  <span className='privacy'> (RIO) </span></h4>
//                 </div>
//                 <div className='card-body'>
//                     <form onSubmit={handleSubmit}>
//                         <div className='form-group row'>
//                             <label className='col-lg-1 col-form-label' > بين   <span className='privacy'>%</span> </label> 
//                             <div className='col-lg-4'>
//                                 <input className='form-control' type="number" onChange={(e) => setPercentageFrom(e.target.value)}></input>
//                             </div>
//                             <label className='col-lg-1 col-form-label' > حتى   <span className='privacy'> %</span> </label> 
//                             <div className='col-lg-4'>
//                                 <input className='form-control' type="number" onChange={(e) => setPercentageTo(e.target.value)}></input>
//                             </div>
//                         </div>
//                         <div className='form-group row '> 
//                             <label className='col-lg-4  col-form-label privacy'>التدفق النقدي</label>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> فترة صفر </label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalZero(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeZero(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة الأولى</label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalOne(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeOne(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة الثانية</label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalTwo(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeTwo(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة الثالثة</label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalThree(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeThree(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة الرابعة</label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalFour(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeFour(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة الخامسة</label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalFive(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeFive(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة السادسة</label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalSix(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeSix(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة السابعة</label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalSeven(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeSeven(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة الثامنة </label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalEight(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeEight(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className='form-group row'>
//                             <label className='col-form-label col-lg-2 '> الفترة التاسعة </label>
//                             <div className='col-lg-3'>
//                                 <input className='form-control' type="number" onChange={(e) => setIntervalNine(e.target.value)}></input>
//                             </div>
//                             <label className='col-form-label col-lg-2 privacy ' > شيكل جديد </label>
//                             <select className='col-lg-2 select-zq form-control-zq' onChange={(e) => setTypeNine(e.target.value)}>
//                                 <option value='minus'> استثمار</option>
//                                 <option value='plus'> إيراد</option>
//                             </select>
//                         </div>
//                         <div className="text-center">
//                 <button type="submit" className="btn btn-primary btn-return" > احتسب  </button>
//               </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//         {showDev && <div className='col-xl-10 d-flex'>
//                         <div className='card flex-fill'>
//                             <div className='card-header'>
//                                     <h4 className='card-title mb-0 privacy text-center'> ناتج الاحتساب  </h4>
//                             </div>
//                             <div className="card-body">
//                                  <div className='form-group row'>
//                                         <h2 className='col-lg-12 col-form-label text-center' ><span>  بين  </span>  <span className='privacy'>{percentageFrom}%</span> <span>-</span> <span className='privacy'>{percentageTo}%</span> </h2> 
                                       
//                                 </div>
//                                 <table className="table table-nowrap table-striped mb-0 ">
//                                     <thead>
//                                         <tr>
//                                             <th className='privacy'> الفترة</th>
//                                             <th className='privacy'> المدخلات  </th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {showZero&&
//                                     <tr> 
//                                             <td>صفر</td>
//                                         <td className={colorZero ? "text-success":"text-danger"}>{intervalZero} <BiShekel /></td>
//                                     </tr> }
//                                     {showOne &&
//                                     <tr> 
//                                             <td>الأولى</td>
//                                         <td className={colorOne ? "text-success":"text-danger"}>{intervalOne} <BiShekel /></td>
//                                     </tr>}
//                                     {showTwo&&
//                                     <tr> 
//                                             <td>الثانية</td>
//                                         <td className={colorTwo ? "text-success":"text-danger"}>{intervalTwo} <BiShekel /></td>
//                                     </tr>}
//                                     {showThree&&
//                                     <tr> 
//                                             <td>الثالثة</td>
//                                         <td className={colorThree ? "text-success":"text-danger"}>{intervalThree} <BiShekel /></td>
//                                     </tr>}
//                                     {showFour&&
//                                     <tr> 
//                                             <td>الرابعة</td>
//                                         <td className={colorFour ? "text-success":"text-danger"}> {intervalFour} <BiShekel /></td>
//                                     </tr>}
//                                     {showFive&&
//                                     <tr> 
//                                             <td> الخامسة</td>
//                                         <td className={colorFive ? "text-success":"text-danger"}>{intervalFive} <BiShekel /></td>
//                                     </tr>}
//                                     {showSix&&
//                                     <tr> 
//                                             <td>السادسة</td>
//                                         <td className={colorSix? "text-success":"text-danger"}>{intervalSix} <BiShekel /></td>
//                                     </tr>}
//                                     {showSeven&&
//                                     <tr>     
//                                             <td>السابعة</td>
//                                         <td className={colorSeven ? "text-success":"text-danger"}>{intervalSeven} <BiShekel /></td>
//                                     </tr>}
//                                     {showEight&&
//                                     <tr> 
//                                             <td>الثامنة</td>
//                                         <td className={colorEight ? "text-success":"text-danger"}>{intervalEight} <BiShekel /></td>
//                                     </tr>}
//                                     {showNine&&
//                                     <tr>     
//                                             <td>التاسعة</td>
//                                         <td className={colorNine ? "text-success":"text-danger"}>{intervalNine} <BiShekel /></td>
//                                     </tr>}
//                                     </tbody>

//                                 </table>
//                             </div>
//                         </div>
//                     </div>}
//     </div>
//   )
}
