/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Itt21 from '../../../components/Tables/basic21';
import Itt22 from '../../../components/Tables/basic22/itt22';
import { TaxCalculatorComponent21 } from '../../../calculators/components/TaxCalculatorComponent21';
import { TaxCalculatorComponent22 } from '../../../calculators/components/TaxCalculatorComponent22';
export default class LawRecovery extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | قانون رسوم النقاهة    </title>
					<meta name="description" content="MassPortal - قانون رسوم النقاهة " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>قانون رسوم النقاهة  </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>


						<div className="row">

							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<h2 >قانون رسوم النقاهة : </h2>
								<br></br>
								<ol className='privacy-ol'>
									<li>
										<p>
										حسب الاتفاقية العامة بموضوع رسوم النقاهة ، وحسب أمر التوسيع الذي فرض العمل بهذه الاتفاقية على جميع القطاعات ، فقد تحدد به نسبة رسوم النقاهة التي هي من  حق الأجير عن كل يوم نقاهة ، كما وحددت الاتفاقية عدد أيام النقاهة التي من حق الأجير ( وذلك حسب سنوات التقادم ) . 
											<br></br>
										</p>
									</li>
									<li>
										<p>
										بحسب الاتفاقية يحق للأجير رسوم نقاهة <span className='privacy'> فقط </span> بعد انتهاء العام الأول من العمل 
										( أي أن على الأجير أن يكمل سنة كاملة من العمل عند صاحب العمل،
										 ولا يحق له رسوم نقاهة اذا انهى عمله قبل مرور عام كامل على بداية عمله ) . 

										</p>
									</li>
									<li>
										<p>بالنسبة للاجئين الذين يعملون بوظيفة جزئية يحق لهم رسوم نقاهة بحسب جزئية الوظيفة.</p>
									</li>
									<li>
										<p> نذكّر بأنه يحق للأجير رسوم نقاهة، دون أن تكون هناك حاجة بان يُثبت أنه كان في بيت نقاهة او في اي مكان نقاهة آخر.</p>
									</li>
									<li>
										<p>
										يحق للأجير رسوم نقاهة حتى بعد أن يكون قد ترك مكان العمل ( متعلق بالفترة والتي هي حتى عامين قبل ترك مكان العمل )، وذلك إذا لم يكن الأجير قد استلم رسوم النقاهة أثناء فترة العمل.

										</p>
									</li>
									<li><p>الاتفاقية العامة و أمر التوسيع تقرران  الحد الأدنى لرسوم النقاهة، ولذلك يمكن ان يضاف عليها إذا تم ذلك عن طريق أي اتفاق اخر.</p></li>


								</ol>
								
								<h2 >استحقاق أيام النقاهة   </h2>
								<br></br>


							</div>

							<div className="col-lg-10">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy">استحقاق أيام النقاهة</h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead>
													<tr >
														<th colspan="2"> أقدمية قطاع خاص  </th>
													
														<th colspan="2">أقدمية قطاع عام </th>
														
														<th colspan="2">أقدمية موظف هستدروت </th>
													
														{/* <th>الضريبة </th>
												<th>الضريبة التراكمية</th> */}

													</tr>
												</thead>
												<tbody>
													<tr >
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														<td className='privacy '>بالسنوات</td>
														<td className='privacy '>بالأيام</td>
														

													</tr>
													<tr>
														<td>1</td>
														<td>5</td>
														<td>3 <span className='privacy'>-</span> 1</td>
														<td>7</td>
														<td> 1 </td>
														<td>9</td>
														{/* <td>1.013</td> */}
													</tr>
													<tr>
													<td>3 <span className='privacy'>-</span> 2</td>
														<td>6</td>
														<td>10 <span className='privacy'>-</span> 4</td>
														<td>9</td>
														<td>4 <span className='privacy'>-</span> 2</td>
														<td>11</td>

													</tr>

													<tr>
													<td>10 <span className='privacy'>-</span>4</td>
														<td>7</td>
														<td>15 <span className='privacy'>-</span> 11</td>
														<td>10</td>
														<td>10 <span className='privacy'>-</span> 5</td>

														<td>12</td>
													</tr>
													<tr>
													<td>15 <span className='privacy'>-</span> 11</td>
														<td>8</td>
														<td>19 <span className='privacy'>-</span> 16</td>
														<td>11</td>
														<td>15 <span className='privacy'>-</span> 11</td>
														<td>13</td>
													</tr>

													<tr>
														
													<td>19 <span className='privacy'>-</span> 16</td>														
														<td>9</td>
														<td>24 <span className='privacy'>-</span> 20</td>
														<td>12</td>
														<td>20 <span className='privacy'>-</span> 16</td>
														<td>14</td>
														
													</tr>
													<tr>
													<td> 20 <span className='privacy'> + </span> </td>
														<td>10</td>
														<td> 25 <span className='privacy'> + </span> </td>
														<td>13</td>
														<td> 21 <span className='privacy'> + </span> </td>
														<td>15</td>
													</tr>
													

												</tbody>
											</table>

										</div>

									</div>
									<hr></hr>
									<ul className='privacy-ol'>

										<h4 className='privacy '>
										للتذكير : سعر يوم النقاهة للقطاع الخاص  حدد   بـ 400  شيكل جديد لليوم الواحد   – دفعة رسوم النقاهة نسبية لأجزاء الوظيفة.
										</h4>

									</ul>
								</div>
								{/* ========================TABLE One End ============================ */}


								<h2 > بدل أيام النقاهة  </h2>
								<br></br>
							</div>

							{/* =============================================================== */}
							<div className="col-lg-10">
								<div className="card">
									<div className="card-header">
										<h4 className="card-title mb-0 text-center privacy">بدل أيام النقاهة  </h4>
										{/* <span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span> */}
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
											<thead>
													<tr >
														<th colspan="2">  قطاع خاص  </th>
													
														<th colspan="2"> قطاع عام </th>
														
														<th colspan="2"> موظف هستدروت </th>
													
														{/* <th>الضريبة </th>
												<th>الضريبة التراكمية</th> */}

													</tr>
												</thead>
												<tbody>
													<tr >
														<td className='privacy '>ابتداء من شهر</td>
														<td className='privacy '>التعرفة</td>
														<td className='privacy '>ابتداء من شهر</td>
														<td className='privacy '>التعرفة</td>
														<td className='privacy '>ابتداء من شهر</td>
														<td className='privacy '>التعرفة</td>
														

													</tr>
													<tr>
														<td>2022 / 07</td>
														<td>400.00</td>
														<td>2022 / 06</td>
														<td> 449.00 </td>
														<td>2022 / 06</td>
														<td>449.00</td>
													</tr>
													<tr>
														<td>2021 / 07</td>
														<td>378.00</td>
														<td>2021 / 06</td>
														<td> 432.00 </td>
														<td>2021 / 06</td>
														<td>432.00</td>
													</tr>
													<tr>
														<td>2020 / 07 </td>
														<td>378.00</td>
														<td>2020 / 06</td>
														<td>429.00</td>
														<td>2020 / 06</td>
														<td>429.00</td>

													</tr>

													<tr>
													<td>2019 / 07 </td>
														<td>378.00</td>
														<td>2019 / 06 </td>
														<td>432.00</td>
														<td>2019 / 06 </td>
														<td>432.00</td>
														
													</tr>
													<tr>
													<td>2018 / 07 </td>
														<td>378.00</td>
														<td>2018 / 06 </td>
														<td>426.00</td>
														<td>2018 / 06 </td>
														<td>426.00</td>
													</tr>

													<tr>
														
													<td>2017 / 07 </td>														
														<td>378.00</td>
														<td>2017 / 06 </td>
														<td>424.00</td>
														<td>2017 / 06 </td>
														<td>424.00</td>
														
													</tr>
													<tr>
													<td> 2016 / 07 </td>
														<td>378.00</td>
														<td> 2019 / 06  </td>
														<td>421.00</td>
														<td> 2019 / 06  </td>
														<td>421.00</td>
													</tr>
													

												</tbody>
											</table>

										</div>

									</div>
									{/* <hr></hr>
									<ul className='privacy-ol'>

										<li>
										<h4 className='privacy '>
										الراتب العادي المقرر للموظف للفترة الزمنية المحددة, لو لم يخرج لإجازة وأكمل عمله بشكل عادي.										
										</h4>
										</li>
										<li>
										<h4 className='privacy'>
										الراتب المقرر لاحتساب قيمة الإجازة فهو الراتب المحسوب فقط بدل ساعات العمل العادية إلا إذا تم

الاتفاق على غير ذلك, حيث لا تحسب في قيمة الراتب مصاريف لا تدفع في فترة الإجازة.

								 </h4>
										</li>

									</ul> */}
								</div>
								
								<div className='row naq-row'>
									{/* <div className='col-sm-12 col-md-2'></div> */}
									<div className='col-sm-12 col-md-6 '>
									<a href="/naqaha-calculator" className="btn  btn-primary  text-center" >احتساب النقاهة   </a>

									</div>
									<div className='col-sm-12 col-md-6 '>
									<a href="/mass-law-recovery-faqs" className="btn  btn-primary text-center" >أسئلة و أجوبة  </a>

									</div>
								</div>


								
							</div>
							

						</div>


						{/* /Content End */}
					</div>

					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
