/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { CalculatorAnnual } from './CalculatorAnnual';
import { Tax21Calc } from '../components/Tax21Calc';
import Itt21 from '../../components/Tables/basic21';
import { NatoTaxCalc21 } from './NatoTaxCalc21';
export default class Tax21 extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | 2021 - Calculator</title>
					<meta name="description" content="Reactify Blank Page" />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					{/* Page Header */}
					<div className="page-header">
						<div className="row">
							<div className="col-sm-12">
							<h3 className="page-title"> احتساب الضرائب <span className='privacy'> 2021 </span> </h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
									{/* <li className="breadcrumb-item active">Blank Page</li>
							 */}
								</ul>
							</div>
						</div>
					</div>
					{/* /Page Header */}
					{/* Content Starts */}

					<div className="row">
						<div className="col-md-12">
							<div className='row'>

								<div className="col-md-6 tax22-calc ">
									<br></br>
									<br></br>
									<br></br>
									<Tax21Calc className="tax22-calc " />
									<br></br>
									<br></br>
								</div>

								<div className="col-md-6  ">
									<Itt21 />
								</div>

								

							</div>




						</div>
					</div>
					{/* /Content End */}
				</div>
				{/* /Page Content */}
			</div>

		);
	}
}
