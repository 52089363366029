import firebase from 'firebase/app'
import "firebase/analytics";
import 'firebase/firestore'
import 'firebase/auth'
import "firebase/storage"
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCsf1OXnK0C1KuntKLVi12_SQNHSJI78bs",
  authDomain: "massportall.firebaseapp.com",
  projectId: "massportall",
  storageBucket: "massportall.appspot.com",
  messagingSenderId: "96808776534",
  appId: "1:96808776534:web:6f57767eeda912d15b5cf7",
  measurementId: "G-Y306F1HD9H"
};
// Math Academy Config
const otherProjectFirebaseConfig = {
  apiKey: "AIzaSyDjjfQQBtufHaS1Do1_l5Z264UCOLd7-b0",
  authDomain: "math-ac2.firebaseapp.com",
  databaseURL: "https://math-ac2-default-rtdb.firebaseio.com",
  projectId: "math-ac2",
  storageBucket: "math-ac2.appspot.com",
  messagingSenderId: "256085130049",
  appId: "1:256085130049:web:165c7d4a17bf49c2b53fc0"
};

// Init MassPortal firebase
firebase.initializeApp(firebaseConfig)
const analytics = firebase.analytics();
firebase.analytics().logEvent('notification_received');

  
// init Mass  services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const auth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp
// Init Math Academy  firebase
var otherProject = firebase.initializeApp(otherProjectFirebaseConfig, "Math - Fintech Database ");

  // "otherProject"
  // console.log("Math Academy project Name : ",otherProject.name);  
var otherStorage = otherProject.storage();
var otherFirestore = otherProject.firestore();

export { projectFirestore, projectAuth, auth,otherFirestore, otherStorage ,timestamp, projectStorage, analytics}