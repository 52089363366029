import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { FiSettings } from 'react-icons/fi';
import { BiShekel } from 'react-icons/bi';
import { MassBill, MassFleet, MassFinance } from '../../Entryfile/imagepath';
import { IoDocumentTextOutline, IoCarSportOutline } from 'react-icons/io5';
// import {TbFiretruck} from 'react-icons'
import { Firetruck ,Bus} from 'tabler-icons-react';
import { BsTable } from 'react-icons/bs';
import { AiOutlineCalculator } from 'react-icons/ai';
export default class MassFleets extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal - Fleet  </title>
					<meta name="description" content="Reactify Blank Page" />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					{/* Page Header */}
					<div className="page-header">
						<div className="row">
							<div className="col-sm-8 col-4">
								<h3 className="page-title"> مركبات - <span className='privacy'>  Fleet </span>    </h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
								</ul>
							</div>
						</div>
					</div>
					{/* Account Logo */}
					<div className="account-logo">
						<img src={MassFleet} alt='MassPortal Fleets' />
					</div>
					{/* /Account Logo */}
					{/* /Page Header */}
					<div className="row">
						<div className="col-lg-10 mx-auto">
							<div className="card card-table mb-0">
								<div className="card-header">
									<div className='row'>
										<div className='col-md-5'>
										<h4 className="card-title mb-0 privacy">معطيات لسنة -  <span className='privacy-white'> 2022 </span>   </h4>
										</div>
										<div className='col-md-2 text-center'>
											<IoCarSportOutline size={50} className='privacy'/>
										</div>
									</div>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-center mb-0">
											<thead>
												<tr className='text-center'>
													<th className='text-center table-tr-underline'>سعر الوقود </th>
													<th className='text-center table-tr-underline'>تأمين الزامي </th>
													<th className='text-center table-tr-underline'>ترخيص مركبة  </th>
													<th className='text-center table-tr-underline'>ترخيص شاحنة  </th>
													<th className='text-center table-tr-underline'>ترخيص مركبة قديمة  </th>
													<th className='text-center table-tr-underline'>ترخيص باص </th>
												</tr>
											</thead>
											<tbody>
												<tr className='privacy text-center'>
													<th>-</th>
													<th >مركبة 1051 - 1550</th>
													<th>ترخيص 3 سنوات </th>
													<th> <Firetruck size={30}/></th>
													<th> <IoCarSportOutline size={30}/></th>
													<th> <Bus  size={30}/></th>
												</tr>
												<tr className='text-center privacy-white-large'>
													<td className=''>6.58  <BiShekel className='privacy' /></td>
													<td>2136  <BiShekel className='privacy' /></td>
													<td>1102  <BiShekel className='privacy' /></td>
													<td>1996  <BiShekel className='privacy' /></td>
													<td>231   <BiShekel className='privacy' /></td>
													<td>1057  <BiShekel className='privacy' /></td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<br></br>
						</div>
						<div className="col-lg-10 mx-auto">
							{/* Plan Tab */} <br></br>
							<div className="row justify-content-center mb-4">
								<div className="col-auto">
									<nav className="nav btn-group settings-nav">
										<a href="#monthly" className="btn btn-outline-secondary active show" data-toggle="tab"> جداول  </a>
										<a href="#annual" className="btn btn-outline-secondary" data-toggle="tab"> حاسبات  </a>
									</nav>
								</div>
							</div>
							{/* /Plan Tab */}
							{/* Plan Tab Content */}
							<div className="tab-content">
								{/* Monthly Tab */}
								<div className="tab-pane fade active show" id="monthly">
									<div className="row mb-30 equal-height-cards">
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className="settings-title">جداول معطيات  </h2>
														<span className="display-6"><BsTable className='privacy' /></span>
													</div>
													<ul className="text-left settings-mass">
														<li><a href='/mass-fleets-fuel-table'><BsTable size={25} className='privacy' /> جدول أسعار الوقود - <span className='privacy'> بنزين 95 </span>    </a></li>
														<li><a href='/mass-fleets-priceGroup-table'><BsTable size={25} className='privacy' />مجموعة أسعار المركبات -<span className='privacy'>  ( קבוצת מחיר)   </span></a></li>
														<li><a href='/mass-fleets-CompulsoryInsurance-table'><BsTable size={25} className='privacy' /> تعريف تأمين الزامي للمركبة   </a></li>
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className="settings-title">جداول Leasing </h2>
														<span className="display-6"><BsTable className="privacy" /></span>
													</div>
													<ul className="text-left settings-mass">
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className="settings-title">قواعد معلومات التنزيل   </h2>
														<span className="display-6"><BsTable className='privacy' /></span>
													</div>
													<ul className="text-left settings-mass">
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
														<li><a href='#'><BsTable size={25} className='privacy' /> أخرى ...    </a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="annual">
									<div className="row mb-30 equal-height-cards">
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className="settings-title">تكاليف    </h2>
														<span className="display-6"><IoCarSportOutline className='privacy' /></span>
													</div>
													<ul className='text-left settings-mass'>
														<li><a href='/mass-fleets-privateCalc'><IoCarSportOutline size={25} className='privacy' /> تكلفة مركبة  شخصية      </a></li>
														<li><a href='#'><IoCarSportOutline size={25} className='privacy' /> أخرى ......... </a></li>
														<li><a href='#'><IoCarSportOutline size={25} className='privacy' /> أخرى ......... </a></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className='settings-title'> حاسبات Leasing  </h2>
														<span className="display-6"><AiOutlineCalculator className='privacy' /></span>
													</div>
													<ul className='text-left settings-mass'>
														<li><a href='/mass-fleets-simpleROI'><AiOutlineCalculator size={25} className='privacy' />  احتساب عائد الاستثمار <span className='privacy'> ( ROI )</span>     </a></li>
														<li><a href='#'><AiOutlineCalculator size={25} className='privacy' />  أخرى .........     </a></li>
														<li><a href='#'><AiOutlineCalculator size={25} className='privacy' />  أخرى .........     </a></li>
														<li><a href='#'><AiOutlineCalculator size={25} className='privacy' />  أخرى .........     </a></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className='settings-title'> قوانين و مراجع  </h2>
														<span className="display-6"><IoCarSportOutline className='privacy' /></span>
													</div>
													<ul className='settings-mass text-left '>
														<li><a href='#'><IoCarSportOutline size={25} className='privacy' /> أخرى .........    </a></li>
														<li><a href='#'><IoCarSportOutline size={25} className='privacy' /> أخرى .........    </a></li>
														<li><a href='#'><IoCarSportOutline size={25} className='privacy' /> أخرى .........    </a></li>
														<li><a href='#'><IoCarSportOutline size={25} className='privacy' /> أخرى .........    </a></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}