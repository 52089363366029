let m =[
 {
  ar: "مادة",
  he: "חומר",
  en: "material"
 },
 {
  ar: "مادة اولية",
  he: "חומר גלם",
  en: "Raw material"
 },
 {
  ar: "مادية",
  he: "חמרנות\/ מטריאליזם",
  en: "materialism"
 },
 {
  ar: "مؤسسة ",
  he: "פירמה",
  en: "Firm"
 },
 {
  ar: null,
  he: "קבוצה\/ קונצרן",
  en: "group\/ concern"
 },
 {
  ar: null,
  he: "עסק",
  en: "business"
 },
 {
  ar: null,
  he: "חברה",
  en: "corporation\/ company"
 },
 {
  ar: "مؤسسة عامة",
  he: "עסק חי",
  en: "Going concern"
 },
 {
  ar: "مؤشر",
  he: "מדד",
  en: "index"
 },
 {
  ar: "مؤشر اسعار المستهلكين",
  he: "מדד המחירים לצרכן",
  en: "Consumer prices index"
 },
 {
  ar: "مؤشر داو جونز",
  he: "מדד דאו ג'ונס",
  en: "Daw jones index"
 },
 {
  ar: "مؤشر نيقي",
  he: "מדד ניקיי",
  en: "Nikkei index"
 },
 {
  ar: "ماكنة",
  he: "מכונה",
  en: "machine"
 },
 {
  ar: "مال",
  he: "כסף",
  en: "money"
 },
 {
  ar: "مالك",
  he: "בעלים",
  en: "owner"
 },
 {
  ar: "مالك فرد",
  he: "בעלות יחיד",
  en: "Sole proprietorship"
 },
 {
  ar: "مالك وحيد",
  he: "בעלות יחיד",
  en: "Sole proprietorship"
 },
 {
  ar: "مالي",
  he: "פינאנסי\/ כספי",
  en: "financial"
 },
 {
  ar: "المالية",
  he: "משרד האוצר",
  en: "Exchequer\/ treasury\/ ministry of finance"
 },
 {
  ar: "مؤمن",
  he: "חתם",
  en: "Under writer"
 },
 {
  ar: "مأمور",
  he: "פקיד",
  en: "clerk"
 },
 {
  ar: "مأمور تصفية",
  he: "מפרק",
  en: "liquidator"
 },
 {
  ar: "مأمور تفليسة",
  he: "כונס נכסים",
  en: "Official receiver"
 },
 {
  ar: "مأمور شراء",
  he: "קניין",
  en: "buyer"
 },
 {
  ar: "مؤهل",
  he: "מוסמך\/ בעל הכשרה",
  en: "qualified"
 },
 {
  ar: "مبادرة",
  he: "יוזמה",
  en: "intiative"
 },
 {
  ar: "مبادلة الاسهم",
  he: "מסחר במניות",
  en: "Exchange of shares"
 },
 {
  ar: "مبدل العملات الاجنبية",
  he: "חלפן כספים",
  en: "Money changer\/ changer"
 },
 {
  ar: "مبلغ",
  he: "סכום",
  en: "Sum\/ amount"
 },
 {
  ar: "مبلغ مدين",
  he: "חיוב\/ חובה",
  en: "debit"
 },
 {
  ar: "مبيعات",
  he: "מכירות",
  en: "sales"
 },
 {
  ar: "متاجر متعددة الفروع",
  he: "רשת חנויות",
  en: "Chain stores\/ multiple store"
 },
 {
  ar: "متاجرة",
  he: "מסחר\/ סחר",
  en: "Commerce\/ trade"
 },
 {
  ar: "متجر",
  he: "חנות",
  en: "Shop\/ store"
 },
 {
  ar: "متجر الخدمة الذاتية",
  he: "סופרמרקט",
  en: "supermarket"
 },
 {
  ar: "متجر متعدد الاقسام",
  he: "חנות כלבו",
  en: "Department store"
 },
 {
  ar: "متجر مصنف",
  he: "חנות כלבו",
  en: "Department store"
 },
 {
  ar: "متطلبات",
  he: "דרישות",
  en: "requirements"
 },
 {
  ar: "متعطل",
  he: "מובטל",
  en: "Unemployed\/ jobless"
 },
 {
  ar: "متعهد",
  he: "יזם",
  en: "entrepreneur"
 },
 {
  ar: "متقاعد",
  he: "גימלאי\/ פנסיונר",
  en: "pensioner"
 },
 {
  ar: "متوسط الايراد",
  he: "פדיון ממוצע",
  en: "Average revenue"
 },
 {
  ar: "مثمن",
  he: "שמאי",
  en: "assessor"
 },
 {
  ar: "مجانا",
  he: "חינם",
  en: "Free of charge"
 },
 {
  ar: "مجلة اقتصادية",
  he: "כתב עת לענייני כלכלה",
  en: "Economic journal"
 },
 {
  ar: "مجلس ادارة",
  he: "מועצת מנהלים",
  en: "Board of management\/ board of directors"
 },
 {
  ar: "مجلس حكام",
  he: "מועצת נגידים",
  en: "Board of governors"
 },
 {
  ar: "مجمع",
  he: "קונגלומרט (חברת ענק המורכבת ממספר פירמות)",
  en: "conglomerate"
 },
 {
  ar: "مجموعة الدول العشر",
  he: "קבוצת העשר",
  en: "Group of ten"
 },
 {
  ar: "مجموعة شركات",
  he: "קבוצת חברות",
  en: "Group of companies"
 },
 {
  ar: "محاسب",
  he: "מנהל חשבונות",
  en: "bookkeeper"
 },
 {
  ar: "محاسب استشاري",
  he: "יועץ חשבונאי",
  en: "Consulting accountant"
 },
 {
  ar: "محاسبة",
  he: "חשבונאות",
  en: "Accountancy\/ accounting "
 },
 {
  ar: null,
  he: "הנהלת חשבונות",
  en: "bookkeeping"
 },
 {
  ar: "محاسبة ادارية",
  he: "חשבונאות ניהולית",
  en: "Management accounting\/ managerial accounting"
 },
 {
  ar: "محاسبة التكاليف",
  he: "תמחיר",
  en: "Costing\/ cost accounting"
 },
 {
  ar: "محاسبة التكاليف التحميلية",
  he: "תמחיר ספיגה",
  en: "Absorption costing\/ absorption cost accounting"
 },
 {
  ar: "محاسبة التكاليف القياسية",
  he: "תמחיר תקן",
  en: "Standard costing\/ standard cost accounting"
 },
 {
  ar: "محاسبة تكاليف مراحل التصنيع",
  he: "תמחיר תהליך",
  en: "Process costing\/ process cost accounting"
 },
 {
  ar: "محاسبة تكاليف مراحل المعالجة",
  he: "תמחיר תהליך",
  en: "Process costing\/ process cost accounting"
 },
 {
  ar: "محاسبة دورية",
  he: "חשבונאות מחזורים",
  en: "Cycle accounting"
 },
 {
  ar: "محاسبة طبيعة",
  he: "חשבונאות מקובלת",
  en: "Applied accounting"
 },
 {
  ar: "محاسبة مالية",
  he: "חשבונאות פינאנסית",
  en: "Financial accounting"
 },
 {
  ar: "محافظ بنك",
  he: "נגיד בנק",
  en: "Governor of a bank"
 },
 {
  ar: "محترف",
  he: "איש מקצוע",
  en: "professional"
 },
 {
  ar: "محدود",
  he: "בעירבון מוגבל",
  en: "limited"
 },
 {
  ar: "محصول",
  he: "תפוקה",
  en: "Output"
 },
 {
  ar: null,
  he: "יבול",
  en: "crop"
 },
 {
  ar: "محطة توليد الطاقة",
  he: "תחנת כוח",
  en: "Power station"
 },
 {
  ar: "محفظة مستندات الاستثمار",
  he: "תיק השקעות",
  en: "Investment portfolio"
 },
 {
  ar: "محل تجاري",
  he: "חנות",
  en: "Shop\/ store"
 },
 {
  ar: "محلات السلسلة",
  he: "רשת חנויות",
  en: "Chain stores\/ multiple stores"
 },
 {
  ar: "محوزات",
  he: "החזקות",
  en: "holdings"
 },
 {
  ar: "مخزن",
  he: "מחסן",
  en: "storeroom"
 },
 {
  ar: "مخزون البضاعة",
  he: "מלאי",
  en: "Stock\/ inventory"
 },
 {
  ar: "مخصص",
  he: "קצבה",
  en: "allowance"
 },
 {
  ar: "مخطط",
  he: "תכנית",
  en: "plan"
 },
 {
  ar: "مدى الضرر",
  he: "מידת הנזק",
  en: "Extent of damage"
 },
 {
  ar: "مدبرة المنزل",
  he: "עוזרת בית",
  en: "housekeeper"
 },
 {
  ar: "مدة الاساس",
  he: "תקופת בסיס",
  en: "Bas period"
 },
 {
  ar: "مدة المحاسبة",
  he: "תקופת חשבון",
  en: "Accounting period"
 },
 {
  ar: "مدة الوظيفة",
  he: "תקופת כהונה",
  en: "Tenure\/ term of office"
 },
 {
  ar: "مدخل الانتاج",
  he: "תשומה",
  en: "input"
 },
 {
  ar: "مدفوع",
  he: "תשלום",
  en: "payment"
 },
 {
  ar: "مدفوعات تحويلية",
  he: "תשלומי העברה",
  en: "Transfer payments"
 },
 {
  ar: "مدقق حسابات",
  he: "רואה חשבון",
  en: "accountant"
 },
 {
  ar: "مدير",
  he: "מנהל\/ איש מנהלה",
  en: "Manager\/ administrator\/ director"
 },
 {
  ar: "مدير المبيعات",
  he: "מנהל מכירות",
  en: "Sales manager"
 },
 {
  ar: "مدير تنفيذي",
  he: "מנהל כללי",
  en: "Executive director\/ managing director"
 },
 {
  ar: "مدير عام",
  he: "מנהל כללי",
  en: "Executive director\/ managing director"
 },
 {
  ar: "مدير مشترك",
  he: "חברת הנהלה בכמה חברות",
  en: "Interlocking director"
 },
 {
  ar: "مدير مكتب البريد",
  he: "מנהל הדואר",
  en: "postmaster"
 },
 {
  ar: "مدير منتدب",
  he: "מנהל כללי",
  en: "Executive director\/ managing director"
 },
 {
  ar: "مديرة المنزل",
  he: "מנהל משק בית",
  en: "housekeeper"
 },
 {
  ar: "مدين",
  he: "בעל חוב\/ חייב",
  en: "debtor"
 },
 {
  ar: "مدينة السوق",
  he: "עיר שוק",
  en: "Market town"
 },
 {
  ar: "مدينة سكنية",
  he: "עיר שינה",
  en: "Dormitory town"
 },
 {
  ar: "مديونية",
  he: "חבויות (כלל חובות של גוף מסוים)",
  en: "indebtedness"
 },
 {
  ar: "مذهب التجارية",
  he: "מרקנטליזם",
  en: "mercantilism"
 },
 {
  ar: "مراب",
  he: "מלווה בריבית",
  en: "usurer"
 },
 {
  ar: "مرابحة",
  he: "מכירת סחורה או שירות על ידי מתווך כשפער התיווך ידוע לקונה",
  en: "Selling of commodity or service by a middleman when the brokerage is known to the purchaser"
 },
 {
  ar: "مراجحة",
  he: "ארביטראג'\/ בוררות",
  en: "arbitrage"
 },
 {
  ar: "مراقب الحسابات",
  he: "מבקר",
  en: "comptroller"
 },
 {
  ar: "مراقب العملة",
  he: "מפקח על מטבע חוץ",
  en: "Controller of the currency\/ supervisor of foreign currency"
 },
 {
  ar: "مراقب الموازنة",
  he: "קצין תקציבים",
  en: "Budget controller"
 },
 {
  ar: "مراقب مالي",
  he: "מבקר",
  en: "comptroller"
 },
 {
  ar: "مراقبة الاسعار",
  he: "פיקוח על המחירים",
  en: "Price control\/ valorization"
 },
 {
  ar: "مراقبة الانتاج",
  he: "בקרת ייצור",
  en: "Production control"
 },
 {
  ar: "مراقبة التحويل",
  he: "פיקוח על מטבע",
  en: "Exchange control"
 },
 {
  ar: "مراقبة التسليف",
  he: "פיקוח על אשראי",
  en: "Credit control"
 },
 {
  ar: "مراقبة الجودة",
  he: "בקרת איכות",
  en: "Quality control"
 },
 {
  ar: "مراقبة الدولة",
  he: "פיקוח ממשלתי",
  en: "Government supervision"
 },
 {
  ar: "مراقبة الميزانية",
  he: "בקרה תקציבית",
  en: "Budgetary control"
 },
 {
  ar: "مراقبة النوعية",
  he: "בקרת איכות",
  en: "Quality control"
 },
 {
  ar: "مراهنة",
  he: "הימור",
  en: "gambling"
 },
 {
  ar: "مربحية",
  he: "רווחיות",
  en: "profitability"
 },
 {
  ar: "مرتهن",
  he: "נותן משכנתא",
  en: "mortgagee"
 },
 {
  ar: "مردود ",
  he: "תשואה",
  en: "Return\/ yield"
 },
 {
  ar: "مرسل",
  he: "שולח",
  en: "Dispatcher"
 },
 {
  ar: null,
  he: "משגר (שולח הסחורה)",
  en: "Consigner"
 },
 {
  ar: "مركز التكاليف",
  he: "מרכז עלות",
  en: "Cost center"
 },
 {
  ar: "مركز تسوق",
  he: "מרכז קניות",
  en: "Shopping center"
 },
 {
  ar: "مركز جمركي",
  he: "בית המכס",
  en: "Customs house"
 },
 {
  ar: "مركز رئيسي",
  he: "משרד ראשי",
  en: "Head office\/ main office\/ headquarters"
 },
 {
  ar: "مركز صناعي",
  he: "מרכז תעשייתי",
  en: "Industrial center"
 },
 {
  ar: "مرونة الاسعار",
  he: "גמישות מחירים",
  en: "Flexibility of prices"
 },
 {
  ar: "مزاد",
  he: "מכירה פומבית",
  en: "auction"
 },
 {
  ar: "مزاد تراجعي",
  he: "מכירה פומבית הולנדית",
  en: "Dutch auction"
 },
 {
  ar: "مزاد صيني ",
  he: "מכירה פומבית סינית",
  en: "Chinese auction"
 },
 {
  ar: "مزاد هولندي",
  he: "מכירה פומבית הולנדית",
  en: "Dutch auction"
 },
 {
  ar: "مزارع",
  he: "חקלאי",
  en: "farmer"
 },
 {
  ar: "مزارعة",
  he: "החכרת קרקע למעבד תמורת חלק מהיבול",
  en: "Leasing of land to a cultivator in exchange for part of the crops"
 },
 {
  ar: "مزايد",
  he: "מציע מחיר במכירה פומבית",
  en: "bidder"
 },
 {
  ar: "مزرعة",
  he: "חווה",
  en: "farm"
 },
 {
  ar: "مزرعة جماعية",
  he: "חווה שיתופית",
  en: "Collective farm"
 },
 {
  ar: "مساح",
  he: "מודד",
  en: "surveyor"
 },
 {
  ar: "مساحة",
  he: "מדידה",
  en: "surveying"
 },
 {
  ar: "مساعد اداري",
  he: "עוזר מנהלי",
  en: "Administrative assiatant"
 },
 {
  ar: "مساعدة",
  he: "סיוע\/ עזרה",
  en: "aid"
 },
 {
  ar: "مساقاة",
  he: "החכרת קרקע למעבד תמורת חלק מהיבול",
  en: "Leasing of land to a cultivator in exchange for part of the crops"
 },
 {
  ar: "مساندة",
  he: "סיוע\/ עזרה",
  en: "aid"
 },
 {
  ar: "مساهم",
  he: "בעל מניות",
  en: "Stockholder\/ shareholder"
 },
 {
  ar: "مساهمة",
  he: "השתתפות",
  en: "contribution"
 },
 {
  ar: "المساوة في تحمل عبء الضرائب",
  he: "שוויון בעול המסים",
  en: "Equality of taxation"
 },
 {
  ar: "مسؤول اداري",
  he: "מנהל\/ איש מנהלה",
  en: "Manager\/ administrator\/ director administrative officer"
 },
 {
  ar: null,
  he: "אחראי מנהלי",
  en: null
 },
 {
  ar: "مسؤولية",
  he: "אחריות",
  en: "Liability\/ responsibility"
 },
 {
  ar: "مسؤولية غير محدودة",
  he: "אחריות בלתי מוגבלת",
  en: "Unlimited liability"
 },
 {
  ar: "مسؤلية محدودة",
  he: "אחריות מוגבלת",
  en: "Limited liability"
 },
 {
  ar: "مساومة جماعية",
  he: "הסכם קיבוצי",
  en: "Collective bargaining"
 },
 {
  ar: "مستاجر",
  he: "שוכר\/ דייר",
  en: "Tenant"
 },
 {
  ar: null,
  he: "חוכר",
  en: "leaseholder"
 },
 {
  ar: "مستثمر",
  he: "משקיע",
  en: "investor"
 },
 {
  ar: "مستثمرون حماعيون",
  he: "משקיעים מוסדיים",
  en: "Institutional investors"
 },
 {
  ar: "مستحق الدفع",
  he: "לתשלום",
  en: "payable"
 },
 {
  ar: "مستخدم",
  he: "מועסק\/ שכיר\/ עובד",
  en: "Employee\/ worker"
 },
 {
  ar: "مستشار اداري",
  he: "יועץ מנהלי",
  en: "Administrative adviser"
 },
 {
  ar: "مستشار اقتصادي",
  he: "יועץ כלכלי",
  en: "Economic adviser"
 },
 {
  ar: "مستشار الاستثمار",
  he: "יועץ השקעות",
  en: "Investment adviser"
 },
 {
  ar: "مستشار مالي",
  he: "יועץ פינאנסי",
  en: "Financial adviser"
 },
 {
  ar: "مستفيد",
  he: "מוטב",
  en: "payee"
 },
 {
  ar: "مستند القيد",
  he: "שובר",
  en: "Coupon\/ voucher"
 },
 {
  ar: "مستهلك",
  he: "צרכן",
  en: "consumer"
 },
 {
  ar: "مستوى الاسعار",
  he: "רמת מחירים",
  en: "Price level"
 },
 {
  ar: "مستوى المعيشة",
  he: "רמת חיים",
  en: "Standard of living"
 },
 {
  ar: "مستودع",
  he: "מחסן",
  en: "storeroom"
 },
 {
  ar: "مستودع الجمرك",
  he: "מחסן ערובה",
  en: "Bond\/ warehouse"
 },
 {
  ar: "مستورد",
  he: "יבואן",
  en: "importer"
 },
 {
  ar: "مستوردات",
  he: "סחורות יבוא",
  en: "imports"
 },
 {
  ar: "مسجل",
  he: "רשם",
  en: "Registrar"
 },
 {
  ar: "مسجل الشركات",
  he: "רשם החברות",
  en: "Registrar of companies"
 },
 {
  ar: "مسك الدفاتر بطريقة القيد المزدوج",
  he: "ניהול ספרים בשיטת הפנקסנות הכפולה",
  en: "Double entry bookkeeping"
 },
 {
  ar: "مسك الدفاتر بطريقة القيد المفرد ",
  he: "ניהול ספרים בשיטת הרישום החד- צדדית",
  en: "Single entry bookkeeping"
 },
 {
  ar: "مسكوكة",
  he: "מטבע",
  en: "coin"
 },
 {
  ar: "مسكوكة ذهبية",
  he: "מטבע זהב",
  en: "Gold coin"
 },
 {
  ar: "مشتري",
  he: "קונה",
  en: "Purchaser\/ buyer"
 },
 {
  ar: "مشترك بالتضامن والتكافل",
  he: "ביחד וליחוד",
  en: "Joint and several"
 },
 {
  ar: "مشتريات",
  he: "רכש",
  en: "Purchase"
 },
 {
  ar: null,
  he: "קניות",
  en: "Purchases"
 },
 {
  ar: "مشروع",
  he: "מפעל",
  en: "Enterprise"
 },
 {
  ar: null,
  he: "תכנית",
  en: "Plan"
 },
 {
  ar: null,
  he: "פרוייקט",
  en: "Project\/ venture"
 },
 {
  ar: "مشروع انجازي",
  he: "פרוייקט המבוצע בשלמותו עד למסירה הסופית ללקוח",
  en: "Turn key project"
 },
 {
  ar: "مشروع خاص",
  he: "יוזמה פרטית",
  en: "Private enterprise"
 },
 {
  ar: "مشروع عام",
  he: "מפעל ציבורי",
  en: "Public enterprise"
 },
 {
  ar: "مشروع مارشال",
  he: "תכנית מארשל",
  en: "Marshall plan"
 },
 {
  ar: "مشروع ممكن تطبيقة عمليا",
  he: "תכנית ישימה",
  en: "Feasible program"
 },
 {
  ar: "مشغل",
  he: "בית מלאכה",
  en: "Workshop"
 },
 {
  ar: "مصادرة",
  he: "עיקול",
  en: "Confiscation"
 },
 {
  ar: "مصاريف مستحقة متراكمة",
  he: "חיובים נצברים",
  en: "Accrued charges"
 },
 {
  ar: "مصدر",
  he: "מקור",
  en: "Source"
 },
 {
  ar: "مصدر",
  he: "יצואן",
  en: "Exporter"
 },
 {
  ar: "مصدر ثروة",
  he: "משאב",
  en: "Resource"
 },
 {
  ar: "مصدر غير مباشر",
  he: "יצואן עקיף",
  en: "indirect exporter"
 },
 {
  ar: "مصدر مباشر",
  he: "יצואן ישיר",
  en: "Direct exporter"
 },
 {
  ar: "مصرف",
  he: "בנק",
  en: "bank"
 },
 {
  ar: "مصروف",
  he: "הוצאה",
  en: "Outlay\/ expenditure\/ expense"
 },
 {
  ar: "مصروف منفق",
  he: "הוצאה שוטפת",
  en: "Out of pocket expense"
 },
 {
  ar: "مصف",
  he: "מפרק",
  en: "Liquidator"
 },
 {
  ar: "مصفاة",
  he: "בית זיקוק",
  en: "Refinery"
 },
 {
  ar: "مصلحة الاوراق المالية",
  he: "רשות לנייר ערך",
  en: "Securities authority"
 },
 {
  ar: "مصنع",
  he: "בית חרושת",
  en: "Factory"
 },
 {
  ar: "مصنع مسبقا",
  he: "טרומי",
  en: "Prefabricated"
 },
 {
  ar: "مصنوع بناءا على طلب",
  he: "לפי הזמנה",
  en: "Made to order"
 },
 {
  ar: "مضارب",
  he: "ספקולנט",
  en: "Speculator"
 },
 {
  ar: null,
  he: "מבקש מניות בהנפקה חדשה כדי לממשן מהר",
  en: "Bear stage"
 },
 {
  ar: "مضارب بالنزول",
  he: "ספקולנט",
  en: "Speculator\/ bear"
 },
 {
  ar: "مضارب بانخفاض الاسعار",
  he: "ספקולנט",
  en: "Speculator\/ bear"
 },
 {
  ar: "مضاربة",
  he: "ספספרות\/ ספקולציה",
  en: "Speculation"
 },
 {
  ar: null,
  he: "שותפות שבה גורם אחד תורם את המימון והאחר את העבודה והידע",
  en: "Partnership in which one side contributes the financing and the other contributes the work and knowledge"
 },
 {
  ar: "مضاربة مفتعلة",
  he: "מניפולציה",
  en: "Manipulation"
 },
 {
  ar: "مطالب",
  he: "דרישות",
  en: "requirements"
 },
 {
  ar: "مع الربح",
  he: "עם דיווידנד",
  en: "Cum dividend"
 },
 {
  ar: "معاش التقاعد",
  he: "פנסיה",
  en: "Pension"
 },
 {
  ar: "معاملة",
  he: "עסקה",
  en: "Bargain\/ transaction"
 },
 {
  ar: "معدل",
  he: "שער\/ שיעור",
  en: "Rate\/ ratio"
 },
 {
  ar: null,
  he: "ממוצע",
  en: "average"
 },
 {
  ar: "معدل اساسي",
  he: "שער בסיסי",
  en: "Flat rate\/ basic rate"
 },
 {
  ar: "معدل التكلفة",
  he: "עלות ממוצעת",
  en: "Average cost"
 },
 {
  ar: "معدل السعر",
  he: "שער יציג",
  en: "Representative rate"
 },
 {
  ar: "معدل العائد",
  he: "שער התשואה",
  en: "Rate of return"
 },
 {
  ar: "معدل المردود",
  he: "שער התשואה",
  en: "Rate of return"
 },
 {
  ar: "معدل النمو",
  he: "שיעור הצמיחה",
  en: "Rate of growth"
 },
 {
  ar: "معدل داخلي مردود",
  he: "שיעור תשואה פנימי",
  en: "Internal rate of return"
 },
 {
  ar: "معدل دورات المخزون",
  he: "מחזור מלאי",
  en: "Inventory turnover"
 },
 {
  ar: "معدلات التبادل",
  he: "תנאי סחר",
  en: "Terms of trade"
 },
 {
  ar: "معفى من اجرة البريد",
  he: "דמי הדואר שולמו",
  en: "Post- free\/ post paid"
 },
 {
  ar: "معفى من الرسوم",
  he: "פטור ממכס",
  en: "Duty- free"
 },
 {
  ar: "معفى من الضريبة",
  he: "פטור ממס",
  en: "Tax- free"
 },
 {
  ar: "معمل تكرير",
  he: "בית זיקוק",
  en: "refinery"
 },
 {
  ar: "معونة",
  he: "סיוע",
  en: "Aid"
 },
 {
  ar: "معونة اجنبية",
  he: "סיוע חוץ",
  en: "Foreign aid"
 },
 {
  ar: "معونة مالية",
  he: "סיוע פינאנסי",
  en: "Financial aid"
 },
 {
  ar: "مفسد الاضراب",
  he: "מפר שביתה",
  en: "Strike breaker"
 },
 {
  ar: "مفوض مراقبة المصارف",
  he: "המפקח של הבנקים",
  en: "Bank commissioner\/ bank examiner"
 },
 {
  ar: "مقابل الدفع",
  he: "כנגד תשלום",
  en: "Against payment"
 },
 {
  ar: "مقابل ايصال",
  he: "כנגד קבלה",
  en: "Against receipt"
 },
 {
  ar: "مقاصة",
  he: "קיזוז",
  en: "Set off"
 },
 {
  ar: "مقاطعة",
  he: "חרם",
  en: "Boycott"
 },
 {
  ar: "مقامرة",
  he: "הימור",
  en: "gambling"
 },
 {
  ar: "مقاول",
  he: "קבלן",
  en: "Contractor"
 },
 {
  ar: "مقاول من الباطن",
  he: "קבלן משנה",
  en: "Sub- contractor"
 },
 {
  ar: "مقاولات من الباطن",
  he: "קבלנות משנה",
  en: "Sub- contracting"
 },
 {
  ar: "مقاومة الانكماش",
  he: "נקיטת צעדים אנטי- דפלציוניים",
  en: "reflation"
 },
 {
  ar: "مقايضة",
  he: "סחר חליפין",
  en: "Barter"
 },
 {
  ar: "مقدر",
  he: "שמאי",
  en: "Assessor"
 },
 {
  ar: "مقدم العطاء",
  he: "מציע מחיר במכירה פומבית\/ מציע מחיר במכרז",
  en: "bidder"
 },
 {
  ar: "مقر رئيسي",
  he: "משרד ראשי",
  en: "Head office\/ main office\/ headquarters"
 },
 {
  ar: "مقرض",
  he: "מלווה",
  en: "Measure\/ creditor"
 },
 {
  ar: "مقياس الاضرار",
  he: "מדידת נזק",
  en: "Measure of damages"
 },
 {
  ar: "مكافأة",
  he: "פרס",
  en: "Award"
 },
 {
  ar: null,
  he: "תגמול",
  en: "Reward"
 },
 {
  ar: null,
  he: "הטבה",
  en: "Bonus\/ premium"
 },
 {
  ar: "مكتب",
  he: "משרד",
  en: "Office"
 },
 {
  ar: "مكتب بريد",
  he: "בית דואר",
  en: "Post office"
 },
 {
  ar: "مكتب رئيسي",
  he: "משרד ראשי",
  en: "Head office\/ main office\/ headquarters"
 },
 {
  ar: "المكتب الرئيسي لمقاطعة اسرائيل",
  he: "המשרד הראשי לחרם על ישראל",
  en: "Central boycott office"
 },
 {
  ar: "مكنة",
  he: "מכונה",
  en: "Machine"
 },
 {
  ar: "مكينة",
  he: "מכונה",
  en: "machine"
 },
 {
  ar: "ملاءة",
  he: "יכולת לשלם חובות",
  en: "solvency"
 },
 {
  ar: "ملاءة ائتمانية",
  he: "כושר אשראי",
  en: "Credit wortiness"
 },
 {
  ar: "ملاحظ اشغال",
  he: "מנהל עבודה",
  en: "foreman"
 },
 {
  ar: "ملاحظ اعمال",
  he: "מנהל עבודה",
  en: "foreman"
 },
 {
  ar: "ملك",
  he: "נכסים",
  en: "Assets"
 },
 {
  ar: null,
  he: "רכוש",
  en: "property"
 },
 {
  ar: "ملك ثابت",
  he: "נכס דלא ניידי",
  en: "Real estate"
 },
 {
  ar: "ملك خاص",
  he: "רכוש פרטי",
  en: "Private property"
 },
 {
  ar: "ملك شائع",
  he: "רכוש משותף",
  en: "Joint property\/ coproperty"
 },
 {
  ar: "ملك عام",
  he: "נחלת הכלל",
  en: "Public domain"
 },
 {
  ar: null,
  he: "רכוש ציבורי",
  en: "Public property"
 },
 {
  ar: null,
  he: null,
  en: null
 },
 {
  ar: "ملك عقاري",
  he: "נכס דלא ניידי",
  en: "Real estate"
 },
 {
  ar: "ملك مشترك",
  he: "רכוש משותף",
  en: "Joint property\/ coproperty"
 },
 {
  ar: "ملكية",
  he: "קניין\/ רכוש",
  en: "Property"
 },
 {
  ar: null,
  he: "בעלות",
  en: "Ownership\/ proprietorship"
 },
 {
  ar: "ملكية الدولة",
  he: "בעלות ממשלתית",
  en: "State ownership"
 },
 {
  ar: "ملكية جماعية",
  he: "בעלות קיבוצית",
  en: "Collective ownership"
 },
 {
  ar: "ملكية عامة",
  he: "בעלות ציבורית",
  en: "Public ownership"
 },
 {
  ar: "ملكية مشتركة",
  he: "בעלות משותפת",
  en: "Co- ownership\/ joint ownership"
 },
 {
  ar: "ممارسة تجارية تقييدية",
  he: "הגבל עסק",
  en: "Restrictive trade practice"
 },
 {
  ar: "ممتهن",
  he: "איש מקצוע",
  en: "Professional"
 },
 {
  ar: "ممول",
  he: "מממן",
  en: "Financier"
 },
 {
  ar: "منافسة",
  he: "תחרות",
  en: "Competition"
 },
 {
  ar: "منافسة احتكارية",
  he: "תחרות מונופוליסטית",
  en: "Monopolistic competition"
 },
 {
  ar: "منافسة حادة",
  he: "תחרות עזה",
  en: "Brisk competition"
 },
 {
  ar: "منافسة حرة",
  he: "תחרות חופשית",
  en: "Free competition"
 },
 {
  ar: "منافسة غير كاملة",
  he: "תחרות בלתי משוכללת",
  en: "Imperfect competition"
 },
 {
  ar: "منافسة كاملة",
  he: "תחרות משוכללת",
  en: "Perfect competition"
 },
 {
  ar: "منافسة نشطة",
  he: "תחרות עזה",
  en: "Brisk competition"
 },
 {
  ar: "مناقصة",
  he: "מכרז",
  en: "Tender"
 },
 {
  ar: "منتج",
  he: "יצרן",
  en: "Producer"
 },
 {
  ar: "منتج",
  he: "מוצר\/ תוצר",
  en: "Good\/ product"
 },
 {
  ar: "منتج ثانوي ",
  he: "מוצר לוואי",
  en: "By- product"
 },
 {
  ar: "منتج جانبي",
  he: "מוצר לוואי",
  en: "By- product"
 },
 {
  ar: "منتج فرعي",
  he: "מוצר לוואי",
  en: "By- product"
 },
 {
  ar: "منتج مشترك",
  he: "מוצר משולב",
  en: "Joint product"
 },
 {
  ar: "منتج من الدرجة الثانية",
  he: "מוצר סוג ב",
  en: "second"
 },
 {
  ar: "منتج نهائي",
  he: "מוצר מוגמר",
  en: "End product\/ final product\/ finished good"
 },
 {
  ar: "منتج وسيط",
  he: "מוצר ביניים",
  en: "Intermediate product"
 },
 {
  ar: "منتوج",
  he: "מוצר",
  en: "Product\/ good"
 },
 {
  ar: "منحة",
  he: "מענק",
  en: "Grant"
 },
 {
  ar: "منشأة متعددة الانتاج",
  he: "חברה רב- מוצרית",
  en: "Multi- product firm"
 },
 {
  ar: "منصب",
  he: "משרה",
  en: "Post\/ job"
 },
 {
  ar: "منصب شاغر",
  he: "משרה פנויה",
  en: "vacancy"
 },
 {
  ar: "منطقة التجارة الحرة",
  he: "אזור סחר חופשי",
  en: "Free trade area\/ free trade zone"
 },
 {
  ar: "منطقة تنمية",
  he: "אזור פיתוח",
  en: "Development area"
 },
 {
  ar: "منظم العمل",
  he: "יזם",
  en: "entrepreneur"
 },
 {
  ar: "منظمة البلدان المصدرة للبترول",
  he: "ארגון המדינות המיצאות נפט",
  en: "Organization of petroleum exporting countries"
 },
 {
  ar: "منظمة التعاون الاقتصادي والتنمية ",
  he: "ארגון לשיתוף פעולה ולפיתוח כלכלי",
  en: "Organization for economic cooperation and development"
 },
 {
  ar: "منظمة امامية",
  he: "חברצ קש",
  en: "Front organization"
 },
 {
  ar: "منظمة عمالية",
  he: "ארגון עובדים",
  en: "Labour organization"
 },
 {
  ar: "منفعة",
  he: "תועלת",
  en: "Utility\/ benefit"
 },
 {
  ar: "منفعة حدية",
  he: "תועלת שולית",
  en: "Marginal utility"
 },
 {
  ar: "مهلة الدفع",
  he: "ארכה לתשלום",
  en: "Grace period"
 },
 {
  ar: "مهنة",
  he: "מקצוע",
  en: "profession"
 },
 {
  ar: "مهندس الانتاج",
  he: "מהנדס ייצור",
  en: "Production engineer"
 },
 {
  ar: "مهندس زراعي",
  he: "אגרונום",
  en: "Agronomist"
 },
 {
  ar: "موارد المال",
  he: "מקורות הכסף\/ היצע כסף",
  en: "Money supply"
 },
 {
  ar: "موارد طبيعية",
  he: "משאבים טבעיים",
  en: "Natural resources"
 },
 {
  ar: "موازنة",
  he: "תקציב",
  en: "Budget"
 },
 {
  ar: "موازنة المشتريات",
  he: "תקציב רכישה",
  en: "Purchasing budget"
 },
 {
  ar: "موازنة وظيفية",
  he: "תקציב ייעודי",
  en: "Functional budget"
 },
 {
  ar: "مواصفة",
  he: "מיפרט",
  en: "Specification"
 },
 {
  ar: "موثق الشركات",
  he: "רשם חברות",
  en: "Registrar of companies"
 },
 {
  ar: "موجودات الشركة",
  he: "נכסים",
  en: "Assets"
 },
 {
  ar: "مودع",
  he: "מפקיד",
  en: "Depositor"
 },
 {
  ar: "مودع في مستودع الجمرك",
  he: "מאוחסן במחסן ערובה",
  en: "In bond"
 },
 {
  ar: "موراتوريوم",
  he: "מורטוריום\/ דחיית פרעון חובות",
  en: "Moratorium"
 },
 {
  ar: "مورد",
  he: "משאב",
  en: "Resource"
 },
 {
  ar: null,
  he: "ספק",
  en: "supplier"
 },
 {
  ar: "موظف",
  he: "עובד\/ מועסק\/ שכיר",
  en: "Worker\/ employee"
 },
 {
  ar: null,
  he: " פקיד",
  en: "Clerk "
 },
 {
  ar: "موظف جمركي",
  he: "פקיד מכס",
  en: "Customs officer"
 },
 {
  ar: "موظف حكومي",
  he: "עובד מדינה",
  en: "Government employee\/ civil servant"
 },
 {
  ar: "موظف كامل الدوام",
  he: "עובד במשרה מלאה",
  en: "Full time employee"
 },
 {
  ar: "موظف مأجور",
  he: "מועסק בשכר",
  en: "Salaried employee"
 },
 {
  ar: "موظف مدني",
  he: "עובד מדינה",
  en: "Government employee\/ civil servant"
 },
 {
  ar: "موظفون",
  he: "פקידות\/ צוות עובדים",
  en: "staff"
 },
 {
  ar: "ميزان",
  he: "מאזן",
  en: "Balance\/ balance sheet"
 },
 {
  ar: "ميزان الاختبار",
  he: "מאזן בוחן",
  en: "Trial balance"
 },
 {
  ar: "ميزان تجاري",
  he: "מאזן מסחרי\/ מאזן סחר חוץ",
  en: "Trade balance"
 },
 {
  ar: "ميزان عاجز",
  he: "מאזן שלילי",
  en: "Adverse balance"
 },
 {
  ar: "ميزان مدفوعات",
  he: "מאזן תשלומים",
  en: "Balance of payments"
 },
 {
  ar: "ميزان مدفوعات رابح",
  he: "מאזן תשלומים חיובי",
  en: "Active balance of payments"
 },
 {
  ar: "ميزان مدفوعات نشط",
  he: "מאזן תשלומים חיובי",
  en: "Active balance of payments"
 },
 {
  ar: "ميزان مدين",
  he: "מאזן שלילי",
  en: "Adverse balance"
 },
 {
  ar: "ميزانية",
  he: "תקציב",
  en: "budget"
 },
 {
  ar: "ميزانية الدولة",
  he: "תקציב המדינה",
  en: "State budget\/ national budget"
 },
 {
  ar: "ميزانية المبيعات",
  he: "תקציב מכירות",
  en: "Sales budget"
 },
 {
  ar: "ميزانية النقد",
  he: "תקציב מזומנים",
  en: " Cash budget "
 },
 {
  ar: "ميزانية رأس المال",
  he: "תקציב פיתוח",
  en: "Capital budget"
 },
 {
  ar: "ميزانية سنوية",
  he: "תקציב שנתי",
  en: "Annual budget"
 },
 {
  ar: "ميزانية عامة",
  he: "מאזן",
  en: "Balance\/ balance sheet"
 },
 {
  ar: "ميزانية عمومية",
  he: "מאזן",
  en: "Balance\/ balance sheet"
 },
 {
  ar: "ميزانيةغير متوازنة",
  he: "תקציב בלתי מאוזן",
  en: "Unbalanced budget"
 },
 {
  ar: "ميزانية متوازنة",
  he: "תקציב מאוזן",
  en: "Balanced budget"
 },
 {
  ar: "ميزانية مرنة",
  he: "תקציב גמיש",
  en: "Flexible budget"
 },
 {
  ar: "ميزانية مؤقتة",
  he: "תקציב ביניים",
  en: "Interim budget"
 },
 {
  ar: "ميناء حر",
  he: "נמל חופשי",
  en: "Free port"
 }
]
export default m ;