import React, { useState, useRef } from "react";
import { DownloadTableExcel, downloadExcel } from "react-export-table-to-excel";
import { SiMicrosoftexcel } from 'react-icons/si'
import ReactTooltip from 'react-tooltip';

import taxProperty from "../real-state/court"
export function TxTblLandInv() {
    let header = ["تاريخ الشراء", "حتى مبلغ", "النسبة %"]
    let tableBody = []
    taxProperty.taxProperty.map((data) => {
        // console.log(data.band1)
        let trData = {
            date: data.date,
            band1M: data.band1.max,
            rate1: data.band1.rate,

        }
        tableBody.push({ ...trData })
    })
    function handleDownloadExcel() {
        downloadExcel({
            fileName: "MassPortal-جدول عقارات مخزون تجاري ",
            sheet: "MP-عقارات مخزون تجاري ",
            tablePayload: {
                header,
                body: tableBody,
            },
        });
    }
    return (<div className="card tax-card ">


        <div className="card-body">
            <div className="card-header">
                <h4 className="card-title mb-0 text-center privacy">جداول عقارات مخزون تجاري
                    <span style={{ float: "left" }}>
                        <button className="btn " onClick={handleDownloadExcel} data-tip data-for="excel-tip">
                            <SiMicrosoftexcel size={25} className='privacy ' />
                        </button>
                    </span>
                </h4>

            </div>
            <div className="table-responsive">
                <table className="table table-nowrap table-striped mb-0">
                    <thead>

                        <tr >
                            <th></th>
                            <th colSpan={2} className='table-tr-underline bold' >  الشريحة  الاولى</th>


                        </tr>


                    </thead>
                    <thead>

                        <tr>
                            <th className="privacy">تاريخ الشراء </th>

                            <th>حتى مبلغ</th>
                            <th className="privacy">النسبة %</th>



                            {/* <th>Country</th> */}
                        </tr>
                    </thead>
                    <tbody>


                        {

                            taxProperty.taxProperty.map((data) => {


                                return (
                                    <tr>
                                        <th className="privacy">        {data.date}</th>

                                        <th>  {data.band1.max}</th>
                                        <th className="privacy"> {data.band1.rate}</th>




                                    </tr>)
                            })



                        }
   <ReactTooltip id="excel-tip" place="top" effect="solid">
                            طباعة Excel
                        </ReactTooltip>

                        {/* <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr>
 <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr>
 <tr>
   <td>1</td>
   <td>Anna</td>
   <td>Pitt</td>
   <td>35</td>
   <td>New York</td>
 </tr> */}
                    </tbody>
                </table>
            </div>
        </div>
    </div>)
}