/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export default class LawRights extends Component {
	render() {
		return (
			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | تقادم حقوق في قوانين العمل       </title>
					<meta name="description" content="MassPortal - اعلام مسبق " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>تقادم حقوق في قوانين العمل    </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>
						<div className="row">
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							

							<div className="col-lg-10">
								<div className="card">
									<div className="card-header">
										<br></br>
										<h5 className="card-title mb-0 text-center privacy">تقادم حقوق في قوانين العمل </h5>
										<br></br>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead >
													<tr >
														<th> الحقوق  </th>
														<th > فترة التقادم     </th>
														<th>ملاحظات    </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>اجازة سنوية  </td>
														<td>ثلاثة سنوات   </td>
														<td> -  </td>
													</tr>
													<tr>
													<td> اجازة سنوية باتفاقية نقاهة  </td>
													<td> سبع سنوات   </td>
													<td>أيام الاجازة التي تعلو على أيام الاجازة القانونية <br></br>بعد انتهاء فترة العمل يمكنه مطالبة سنتين فقط </td>

													</tr>
													<tr>
														<td> سفريات </td>
														<td> سبع سنوات  </td>
														<td> - </td>
													</tr>
													<tr>
														<td> اجازة مرضية  </td>
														<td> سبع سنوات  </td>
														<td>يمكن مطالبة <span className='privacy'> 90 </span> يوم لخمس سنوات   </td>

														
													</tr>
													<tr>
														<td> اجازة عمل  </td>
														<td> سبع سنوات  </td>
														<td> - </td>
													</tr>
													<tr>
														<td> تعويضات نهاية الخدمة  </td>
														<td> سبع سنوات  </td>
														<td> - </td>
													</tr>
													
												</tbody>
											</table>
										</div>
									</div>
									
								</div>
							</div>
						</div>
						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
