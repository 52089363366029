let sad=[
 {
  ar: "صاحب الدخل",
  he: "בעל הכנסה (שאינה מעבודה) ",
  en: "rentier"
 },
 {
  ar: "صاحب عمل",
  he: "מעסיק",
  en: "employer"
 },
 {
  ar: "صاحب مصرف",
  he: "בנקאי",
  en: "banker"
 },
 {
  ar: "صادرات",
  he: "סחורות יצוא",
  en: "export"
 },
 {
  ar: "صافي",
  he: "נטו\/ נקי",
  en: "net"
 },
 {
  ar: "صافي الاستثمار",
  he: "השקעה נקייה",
  en: "Net investment"
 },
 {
  ar: "صافي القيمة الحالية",
  he: "ערך נוכח נקי",
  en: "Net present investment"
 },
 {
  ar: "صافي الناتج القومي",
  he: "תוצר לאומי נקי",
  en: "Net national product"
 },
 {
  ar: "صراف",
  he: "גזבר\/ קופאי",
  en: "Burser\/ treasurer\/ cashier"
 },
 {
  ar: null,
  he: "שלם",
  en: "paymaster\/ payclerk\/ disburser"
 },
 {
  ar: null,
  he: null,
  en: "changer\/ money changer"
 },
 {
  ar: null,
  he: "חלפן כספים",
  en: "teller"
 },
 {
  ar: null,
  he: "טלר",
  en: null
 },
 {
  ar: "صرف",
  he: "פיטורים",
  en: "Dismissal"
 },
 {
  ar: null,
  he: "תשלום",
  en: "payment"
 },
 {
  ar: "صفقات متكافئة",
  he: "סחר נגדי",
  en: "Counter trade"
 },
 {
  ar: "صفقة",
  he: "עסקה\/ מציאה",
  en: "Transaction\/ bargain"
 },
 {
  ar: "صك",
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "صك تحويل الملكية",
  he: "שטר העברת מניות",
  en: "Transfer deed"
 },
 {
  ar: "صناعة",
  he: "תעשייה",
  en: "industry"
 },
 {
  ar: "صناعة ثقيلة",
  he: "תעשייה כבדה",
  en: "Heavy industry"
 },
 {
  ar: "صناعي",
  he: "תעשיין",
  en: "Industrialist"
 },
 {
  ar: null,
  he: "תעשייתי",
  en: "industrial"
 },
 {
  ar: "صندوق",
  he: "קרן",
  en: "fund"
 },
 {
  ar: "صندوق ائتمان",
  he: "קרן נאמנות",
  en: "Mutual fund\/ unit trust"
 },
 {
  ar: "صندوق الاحتياطي",
  he: "קופת גמל",
  en: "Provident fund"
 },
 {
  ar: "صندوق الطوارئ",
  he: "קופת גמל",
  en: "Provident fund"
 },
 {
  ar: "صندوق النقد الدولي",
  he: "קרן המטבע הבינלאומית",
  en: "International monetary fund"
 },
 {
  ar: "صندوق النقد العربي",
  he: "קרן המטבע הערבית",
  en: "Arab monetary fund"
 },
 {
  ar: "صندوق توفير",
  he: "קרן חיסכון",
  en: "Saving fund"
 },
 {
  ar: "صندوق عام",
  he: "קרן ציבורית",
  en: "Public fund"
 },
 {
  ar: "صندوق معاشات التقاعد",
  he: "קרן פנסיה",
  en: "Pension fund"
 },
 {
  ar: "صهريجية",
  he: "מכלית נפט",
  en: "tanker"
 },
 {
  ar: "صيانة",
  he: "תחזוקה",
  en: "maintenance"
 },
 {
  ar: "صيرفة",
  he: "בנקאות",
  en: "Banking"
 },
 {
  ar: null,
  he: "חלפנות",
  en: "money changing"
 },
 {
  ar: "صيرفة متعددة",
  he: "בנקאות כפולה",
  en: "Multiple banking"
 }
]
export default sad ;