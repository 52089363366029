/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export default class LawEos extends Component {
	render() {
		return (
			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | تعويضات نهاية الخدمة     </title>
					<meta name="description" content="MassPortal - قانون رسوم النقاهة " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1>تعويضات نهاية الخدمة   </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>
						<div className="row">
							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<h3 >تعويضات نهاية الخدمة : </h3>
								<br></br>
								<ul className='privacy-ol'>
									<li>
										<p>
											يحدد قانون تعويضات نهاية الخدمة حقوق الموظف في حالة فصله عن العمل. حيث يحدد القانون الأوضاع التي يحق للموظف أو أنجاله أخذ تعويضات عند نهاية خدمته, وما هي نسبة التعويضات, كيف يتم احتسابها, ومتى يجب دفعها.
											<br></br>
										</p>
									</li>
									<li>
										<p>
											يعدد القانون عدة مواقف التي تعد كفصل من العمل لغاية استحقاق دفع تعويضات نهاية الخدمة حتى إذا لم يتم فصل بشكل مباشر. ومن ناحية ثانية, يفصل القانون مواقف التي يمكن فصل موظف بدون أن يدفع له تعويضات.
										</p>
									</li>
								</ul>
								<h3 >ظروف استقالة التي تحسب كفصل لغاية تعويضات نهاية الخدمة   </h3>
								<br></br>
							</div>

							<div className="col-lg-10">
								<div className="card">
									<div className="card-header">
										<br></br>
										<h5 className="card-title mb-0 text-center privacy">ظروف استقالة التي تحسب كفصل لغاية تعويضات نهاية الخدمة</h5>
										<br></br>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap table-striped mb-0">
												<thead >
													<tr className='privacy'>
														<th> # </th>
														<th > سبب الاستقالة   </th>
														<th>تفصيل الظروف  </th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>1</td>
														<td>الصحة </td>
														<td>استقالة بسبب الوضع الصحي للموظف أو ابن عائلته </td>
													</tr>
													<tr>
														<td><br></br>2</td>
														<td><br></br>
															استقالة ( الأب / الأم ) بعد الولادة<br></br> <span className='privacy '>  البند السابع للقانون </span> </td>
														<td >
															<ul className=' privacy-oll text-left'>
																<li >هدف الاستقالة تربية الأولاد </li>
																<li> موعد الاستقالة حتى 9 أشهر من يوم الولادة أو التبني </li>
																<li> يحق لأحد الوالدين فقط </li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>3</td>
														<td>
															نقل مكان السكن
															<br></br>
															<span className='privacy'>( البند الثامن للقانون و تعليماته )</span>
														</td>
														<td >
															<ul className=' privacy-oll text-left'>
																<li >
																	<span className='privacy'> حسب التعليمات </span>
																	<br></br>
																	يحق للموظف الاستقالة اذا تم نقل سكنه
																</li>
															</ul>
														</td>
													</tr>
													<tr>
														<td><br></br>4</td>
														<td><br></br>انتهاء فترة اتفاقية عمل محددة </td>
														<td >
															<ul className=' privacy-oll text-left'>
																<li >يمكن رؤية موظف استقال اذا انتهت فترة اتفاقية عمل ولم تجدد  </li>
																<li> عرض تجديد الاتفاقية يجب أن يعرض قبل انتهاء فترة العقد الأول </li>
																<li> اذا رفض الموظف تجديد عقده فنرى أنه استقال من رغبته  </li>
															</ul>
														</td>
													</tr>
													<tr>
														<td><br></br>5</td>
														<td>
															<br></br>جيل الشيخوخة
															<br></br>
															<span className='privacy'> ( البند 11 للقانون )</span> </td>
														<td >
															<ul className=' privacy-oll text-left'>
																<li >الاستقالة من جيل ال <span className='privacy'> 62 - 65  </span> للمرأة   </li>
																<li >الاستقالة بعد جيل ال <span className='privacy'> 67 </span> للرجل    </li>

																<li> اذا ناهز الموظف جيل الشيخوخة و انتقل <br></br>للعمل في مكان اخر فلا يحق له أخذ التعويضات   </li>
															</ul>
														</td>
													</tr>
													<tr>
														<td>6</td>
														<td>
															اساءة ملموسة في ظروف العمل
															<br></br>
															<span className='privacy'> [  البند 11  ( أ ) للقانون   ]</span>
														</td>
														<td >
															<ul className=' privacy-oll text-left'>
																<br></br>
																<li>   استقالة بسبب اساءة ملموسة في ظروف العمل  </li>
															</ul>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<hr></hr>
									<ul className='privacy-ol'>
										<h5 className='privacy '>
											قانون تعويضات نهاية الخدمة (1963) يفصل عدة حالات استقالة التي تعد كفصل ويحق للموظف بمطالبة أتعابه حتى لو لم يفصل.
										</h5>
									</ul>
								</div>
							</div>
						</div>
						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
