let wow =[
 {
  ar: "واجهة عرض",
  he: "חלון ראווה",
  en: "Display window"
 },
 {
  ar: "وارث",
  he: "יורש",
  en: "Heir"
 },
 {
  ar: "واردات",
  he: "סחורות יבוא",
  en: "Imports"
 },
 {
  ar: "واردات معفاة من الرسوم",
  he: "סחורות המותרות ביבוא חופשי",
  en: "Free imports"
 },
 {
  ar: "وثيقة",
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "وحدة العملة الاوروبية",
  he: "יחידת המטבע האירופית",
  en: "European currency unit"
 },
 {
  ar: "وديعة",
  he: "הפקדה",
  en: "Deposit"
 },
 {
  ar: "وديعة حاضرة",
  he: "הפקדה במזומן",
  en: "Cash Deposit "
 },
 {
  ar: "وديعة لأجل",
  he: "פקדון לזמו קצוב",
  en: "Time deposit"
 },
 {
  ar: "وديعة للاستيراد",
  he: "הפקדת יבוא",
  en: "Import deposit"
 },
 {
  ar: "وديعة مصرفية",
  he: "פיקדון בבנק",
  en: "Bank deposit"
 },
 {
  ar: "وراثة",
  he: "ירושה",
  en: "Inheritance"
 },
 {
  ar: "ورشة",
  he: "בית מלאכה",
  en: "workshop"
 },
 {
  ar: "ورقة تجارية مقبولة",
  he: "מסמך מסחרי מתאים",
  en: "Eligible commercial"
 },
 {
  ar: null,
  he: null,
  en: "paper"
 },
 {
  ar: "ورقة مالية",
  he: "בטוחה\/ נייר ערך",
  en: "security"
 },
 {
  ar: "ورقة مصرفية",
  he: "בנקנוט\/ שטר בנקנוט\/ ",
  en: "Bank paper\/ banknote"
 },
 {
  ar: null,
  he: "שטר כסף",
  en: "Bank bill\/ currency note\/"
 },
 {
  ar: null,
  he: null,
  en: "Paper money"
 },
 {
  ar: "ورقة نقدية",
  he: "שטר",
  en: "Note\/ deed\/ bill"
 },
 {
  ar: "وزارة الاسكان",
  he: "משרד השיכון",
  en: "Ministry of housing"
 },
 {
  ar: "وزارة الاشغال العامة",
  he: "משרד לעבודות ציבוריות",
  en: "Ministry of public works"
 },
 {
  ar: "وزارة الاقتصاد",
  he: "משרד הכלכלה",
  en: "Ministry of economy"
 },
 {
  ar: "وزارة الاقتصاد الوطني",
  he: "משרד הכלכלה הלאומית",
  en: "Ministry of national economy "
 },
 {
  ar: "وزارة التجارة",
  he: "משרד המסחר",
  en: "Ministry of commerce"
 },
 {
  ar: "وزارة التخطيط",
  he: "משרד התכנון",
  en: "Ministry of planning"
 },
 {
  ar: "وزارة الخزينة",
  he: "משרד האוצר",
  en: "Exchequer\/ treasury\/"
 },
 {
  ar: null,
  he: null,
  en: "Ministry of finance"
 },
 {
  ar: "وزارة الزراعة",
  he: "משרד החקלאות",
  en: "Ministry of agriculture"
 },
 {
  ar: "وزارة السياحة",
  he: "משרד התיירות",
  en: "Ministry of tourism"
 },
 {
  ar: "وزارة الشؤون الاجتماعية",
  he: "משרד הרווחה",
  en: "Ministry of social affairs"
 },
 {
  ar: "وزارة الصناعة",
  he: "משרד התעשייה",
  en: "Ministry of industry"
 },
 {
  ar: "وزارة العمل",
  he: "משרד העבודה",
  en: "Ministry of labour"
 },
 {
  ar: "وزارة المال",
  he: "משרד האוצר",
  en: "Exchequer\/ treasury\/"
 },
 {
  ar: null,
  he: null,
  en: "Ministry of finance"
 },
 {
  ar: "وزارة المالية",
  he: "משרד האוצר",
  en: "Exchequer\/ treasury\/"
 },
 {
  ar: null,
  he: null,
  en: "Ministry of finance"
 },
 {
  ar: "وزن اجمالي",
  he: "משקל ברוטו",
  en: "Gross weight"
 },
 {
  ar: "وزن صافي",
  he: "משקל נקי",
  en: "Net weight"
 },
 {
  ar: "وزير الخزانة",
  he: "שר האוצר",
  en: "Minister of finance\/"
 },
 {
  ar: null,
  he: null,
  en: "Chancellor of the exchequer"
 },
 {
  ar: "وزير المالية",
  he: "שר האוצר",
  en: "Minister of finance\/"
 },
 {
  ar: null,
  he: null,
  en: "Chancellor of the exchequer"
 },
 {
  ar: "وسائل الدفع",
  he: "אמצעי תשלום",
  en: "Means of payment"
 },
 {
  ar: "وسطاء ماليون",
  he: "מתווכים פינאנסיים",
  en: "Financial intermediaries"
 },
 {
  ar: "وسيط",
  he: "מתווך",
  en: "Broker"
 },
 {
  ar: "وسيط جمركي",
  he: "עובד במכס",
  en: "Customs broker"
 },
 {
  ar: "وسيط خارج البورصة",
  he: "ברוקר העוסק במסחר בנירות",
  en: "Curb broker"
 },
 {
  ar: null,
  he: "ערך שאינם רשומים",
  en: null
 },
 {
  ar: "وصية",
  he: "צוואה",
  en: "will"
 },
 {
  ar: "وصية شفوية ",
  he: "צוואה בעל- פה",
  en: "Nuncupation will"
 },
 {
  ar: "وضع اليد الفعلي",
  he: "החזקה בפועל",
  en: "Actual possession"
 },
 {
  ar: "وضيعة",
  he: "הפקדה",
  en: "Deposit"
 },
 {
  ar: "وظيفة",
  he: "משרה",
  en: "Job"
 },
 {
  ar: "وظيفة لبعض الوقت",
  he: "משרה חלקית",
  en: "Discharge"
 },
 {
  ar: "وفرة دولارية",
  he: "גלישת דולרים",
  en: "Dollar glut"
 },
 {
  ar: "وقت اضافي",
  he: "שעות נוספות",
  en: "overtime"
 },
 {
  ar: "وقود",
  he: "דלק",
  en: "Fuel"
 },
 {
  ar: "وكالة",
  he: "סוכנות",
  en: "Agency"
 },
 {
  ar: "وكيل",
  he: "סוכן",
  en: "Agent"
 },
 {
  ar: "وكيل المشتريات",
  he: "קניין",
  en: "Buyer"
 },
 {
  ar: "وكيل عام",
  he: "סוכן כללי",
  en: "General agent"
 },
 {
  ar: "وكيل تأمين",
  he: "סוכן ביטוח",
  en: "Insurance agent"
 },
 {
  ar: "وكيل وحيد",
  he: "סוכן בלעדי",
  en: "Exclusive agent"
 }
]
export default wow ;