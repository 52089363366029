/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import TxTbl from './TxTblAbt';

export default class TaxtTable extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | جدول نسب ضريبة الأملاك     </title>
					<meta name="description" content="MassPortal - جدول نسب ضريبة الأملاك  " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<h1>جدول نسب ضريبة الأملاك</h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
						</div>
						<div className="row">
									<div className="card-body">
									<TxTbl />
									</div>
							<div className='col-lg-1'>
							</div>
						</div>
						{/* /Content End */}
					</div>
					{/* /Page Content */}
				</div>
			</div>
		);
	}
}
