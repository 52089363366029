import React from 'react';
const localeOptions = {
	style:'currency',
	currency:'ILS',
	minimumFractionDigits: 2,
    maximumFractionDigits: 2
}

export const PaymentTable = props => {
	let paymentMonthsArr = [];
	for(let i = 0; i < props.yearlyPayments.length; i++) {
		paymentMonthsArr.push(
			<tr key={props.yearlyPayments[i].year}>
				<td>{props.yearlyPayments[i].year}</td>
				<td>{props.yearlyPayments[i].interestPaid.toLocaleString('en-GB', localeOptions)}</td>
				<td className="to-date-yearly-payment">{props.yearlyPayments[i].interestPaidToDate.toLocaleString('en-GB', localeOptions)}</td>
				<td>{props.yearlyPayments[i].principalRepaid.toLocaleString('en-GB', localeOptions)}</td>
				<td className="to-date-yearly-payment">{props.yearlyPayments[i].principalRepaidToDate.toLocaleString('en-GB', localeOptions)}</td>
				<td>{props.yearlyPayments[i].outstandingBalance.toLocaleString('en-GB', localeOptions)}</td>
			</tr>
		);
	}

	return(
		<div className='card'>
		<div className="card-body">
		<div className="table-responsive">
			<h2 class="mortage-card-title text-center">لائحة تسديد القرض</h2>
		<table className=" paymentsCalculatorTable table table-striped mb-0 " >
			<thead>
				<tr>
					<th>السنة</th>
					<th>الفائدة السنوية المدفوعة</th>
					<th className="to-date-yearly-payment">إجمالي الفوائد المدفوعة</th>
					<th>الدفع لكل سنة</th>
					<th className="to-date-yearly-payment">إجمالي المبلغ المدفوع</th>
					<th>رصيد القرض المتبقي</th>
				</tr>
			</thead>
			<tbody>
				{paymentMonthsArr}	
			</tbody>
		</table>
		</div>
		</div>
		</div>
	);
}