import { TextField } from "@material-ui/core";
import React, { useState, } from "react";
import { useEffect } from "react";

export function CalculatorRtX_21(props) {
  
const [inputFields , setInputFields] = useState ([])
const [showDev , setShowDev] = useState (false)
const handleAddFields= () => {
  // setInputFields([...inputFields,{totalTax:'', payedTax:'', leftTax:''}]);
  
  // console.log("handle ");
  
}

// const handleremoveFields = (index)=>{
//   const values = [...inputFields];
//   values.splice(index, 1);
//   setInputFields(values);
// }
// const handleChangeInput = (index , event) =>{
//   console.log(index, event.target.name);
// }
const [yearlyIncome, setYearlyIncome] = useState('0');
const [yearlyTax, setYearlyTax] = useState('0');
const [yearlyDontion, setYearlyDonation] = useState('0');
const [pension, setPension] = useState('0');
const [points, setPoints] = useState(0);
const [tax, setTax] = useState(0);
const [show,setShow] = useState(false);
const [data,setData] = useState ([]);
const [totalfinal, setFinal]= useState('0')
const[label1Value , setLabel1Value]= useState('0')
const[label2Value , setLabel2Value]= useState('0')
const[label3Value , setLabel3Value]= useState('0')
const[pensvalue, setbensValue]= useState('0');
const[donvalue, setdonValue]= useState('0');
const [span3 , setSpan3]= useState('0')
let pricePoint = 218;
let num = [
   6290,
   9030,
   14490,
   20140,
   41910,
   53970];

let percentage =[
   0.1,
   0.14,
   0.2,
   0.31,
   0.35,
   0.47,
   0.5];

let np =[
(num[0])*percentage[0],
(num[1]-num[0])*percentage[1],
(num[2]-num[1])*percentage[2],
(num[3]-num[2])*percentage[3],
(num[4]-num[3])*percentage[4],
(num[5]-num[4])*percentage[5]];

const reloadWindow = (evt) => {
  window.location.reload();
}

const handleSubmit = (evt) => {
  
     evt.preventDefault();
   
    // console.log("Handling here")

    // console.log("yearly income / 12 ? ", yearlyIncome);
    // console.log(" Tax Payed : ", tax);
    const yt = parseFloat(yearlyTax).toFixed(2)
    setYearlyTax(yt);
   
   
   
    let id = Math.random();
   
    if (yearlyIncome !=="")
    {
      
      setTax(calculateTax(parseFloat(yearlyIncome/12)));

      
      
    
      
    }
    // setShow(true)

    function calculateTax (income) {
      let tax = 0;
      // console.log("Monthly income is : ", income);
      if(income > 0 && income <= num[0]){
        tax = (income) * percentage[0] - (points*pricePoint);
      }
      else if(income > num[0] && income <=num[1]){
        tax = ((income - num[0]) * percentage[1]) + (np[0]) - (points*pricePoint);
      }
      else if(income > num[1] && income <= num[2]){
        tax = ((income - num[1]) * percentage[2]) + (np[0]+np[1]) - (points*pricePoint);
      }
      else if(income > num[2] && income <= num[3]){
        tax = ((income - num[2]) * percentage[3]) + (np[0]+np[1]+np[2]) - (points*pricePoint);
      }
      else if(income > num[3] && income <= num[4]){
        tax = ((income - num[3]) * percentage[4]) + (np[0]+np[1]+np[2]+np[3]) - (points*pricePoint);
      }
      else if(income >num[4] && income <= num[5]){
        tax = ((income - num[4]) * percentage[5]) + (np[0]+np[1]+np[2]+np[3]+np[4]) - (points*pricePoint);
      }
      else if(income > num[5]){
        tax = ((income -num[5]) * percentage[6]) +  (np[0]+np[1]+np[2]+np[3]+np[4]+np[5]) - (points*pricePoint);
      }

      setData(prev =>[...prev,
        { id: id,
        tax: tax.toFixed(1),
        points:points,
        total : (yearlyIncome - tax).toFixed(1),
        monthlyIncome:yearlyIncome
        }])

        if(tax<0){
          // console.log("cancel and return ")
        tax = 0;
        }

        let pensionTotal =  (parseFloat(pension)*.35);
       
        const yearlyDonTotal =  (parseFloat(yearlyDontion)*.35);
        const totalCalc =   ((tax*12).toFixed(2));
        setSpan3(totalCalc)
        if(pensionTotal != 0 && totalCalc == 0)
        {
          console.log("we have a pension Value  ", pensionTotal ," and  no totalCalc :", totalCalc);
          pensionTotal = 0;
        }else{
          console.log("  ");
        }
        const totalFinalCalc = parseFloat(totalCalc)-parseFloat(pensionTotal)-parseFloat(yearlyDonTotal)
        // console.log("TTTTTTTTOTAL CALC VALUE : ", parseFloat(totalCalc)-parseFloat(pensionTotal)-parseFloat(yearlyDonTotal))
        // console.log(" tax x 12  : ", totalCalc)

        const label1 =  parseFloat(totalFinalCalc).toFixed(2);
        setbensValue(pensionTotal);
        setdonValue(parseFloat(yearlyDonTotal))
        setLabel1Value(label1);
        const x = parseFloat(yearlyTax? yearlyTax : "0")
        setLabel2Value(x)
        // console.log("label1 value : ", label1);
        // console.log("x value : ", x);
        setFinal(totalCalc);
        setShowDev(true);
        const label3 = (parseFloat(label1) - parseFloat(x)).toFixed(2);
        setLabel3Value(label3)
        // console.log("label3 value : ", (parseFloat(label1) - parseFloat(x)).toFixed(2))
        return ((tax*12).toFixed(2));
        
    } 
   
   
}

const handleDelete=(id)=>{
  let newData = data.filter((data)=>
      data.id !== id )
      setData(newData)
      newData = [];
}
  return (
    <div className="row">
    <div className="col-xl-6 d-flex">
      <div className="card flex-fill">
        <div className="card-header">
          <h4 className="card-title mb-0 privacy ">فحص ارجاع ضريبة دخل  </h4>
        </div>
        <div className="card-body">
          
          <form onSubmit={handleSubmit}>
          
          {/* <div className="form-group row">
                          <label className="col-lg-4 col-form-label">اختر السنة </label>
                          <div className="col-lg-8">
                            <select className="select rtx-select">
                             
                              <option value={1}>2022</option>
                              <option value={2}>2021</option>
                              <option value={3}>2020</option>
                              <option value={4}>2019</option>
                              <option value={4}>2018</option>
                              <option value={4}>2017</option>
                              <option value={4}>2016</option>
                            </select>
                          </div>
                        </div> */}
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">رواتب سنوية ( 172 , 158 )</label>
              <div className="col-lg-8">
                <input type="text" className="form-control"
                  onChange={(e) => setYearlyIncome(e.target.value)}
                  
                />

            
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">ضريبة سنوية ( 42 )</label>
              <div className="col-lg-8">
                <input type="text" 
                  className="form-control"
                  onChange={(e) => setYearlyTax(e.target.value)}
                 />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">تبرعات  سنوية ( 37 )</label>
              <div className="col-lg-8">
                <input type="text" className="form-control" 
                  onChange={ (e) => setYearlyDonation(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="form-group row">
              <label className="col-lg-4 col-form-label">  قيمة نقاط تزكية أخرى </label>
              <div className="col-lg-8">
                <input type="number" className="form-control" />
              </div>
            </div> */}
            <div className="form-group row">
              <label className="col-lg-4 col-form-label">   نقاط تزكية  </label>
              <div className="col-lg-8">
                <input type="text" className="form-control"
                  onChange={(e)=> setPoints(e.target.value)}
                 />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-4 col-form-label"dir="ltr">   مخصصات تأمين تقاعدي  (45,86) </label>
              <div className="col-lg-8">
                <input type="text" className="form-control"
                  onChange={ (e)=> setPension(e.target.value)}
                />
              </div>
            </div>                   
              <div className="text-center">              
              <button type="submit"  className="btn btn-primary btn-return" > احتسب  </button>
            </div>           
          </form>         
        </div>        
      </div>      
    </div>

  
     
    <div className={showDev ? "col-xl-5 d-flex" : "hide-div"} >
      <div className="card flex-fill">
        <div className="card-header">
          <h4 className="card-title mb-0 privacy " >ناتج الاحتساب </h4>
        </div>
        <div className="card-body">
          <form onSubmit={reloadWindow}>
         <br></br> <br></br>
         <div className="form-group row">
              <label className="col-lg-12 col-form-label"> الضريبة المستحقة </label>
              <div className="col-lg-12">
                <label  className="form-control result-label" id="label1"> {label1Value}</label>
                
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-12 col-form-label">  تم الدفع  </label>
              <div className="col-lg-12">
              <label  className="form-control result-label" > {label2Value}</label>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-12 col-form-label">  ارجاع \ رصيد   </label>
              <div className="col-lg-12">
              <label  className={(label3Value > 0) ? "form-control result-label result-label-green" : "form-control result-label result-label-red"} > {label3Value}</label>
              </div>
            </div>
            
            <div className="form-group row">
              <div className="col-6">
              <h6><span className="privacy">{pensvalue}</span>  pension Total : </h6>

              </div>
              <div className="col-6">
              <h6><span className="privacy">{donvalue}</span>  Donation Total : </h6>

              </div>
              <div className="col-6">
              <h6> ضريبة الرواتب السنوية   : <span className="privacy">{span3}</span></h6>

              </div>
              <div className="col-6">
              <h6> نقاط التزكية   : <span className="privacy">{points}</span></h6>

              </div>
              {/* <div className="col-6">
              <h6> قيمة نقاط التزكية   : <span className="privacy">{points*218}</span></h6>

              </div> */}
            </div>
            <div className="text-center">              
              <button type="submit"  className="btn btn-primary btn-return"> احتساب جديد  </button>
            </div>   
          
          </form>
        </div>
      </div>
    </div>
    {/* { show && 
				  
          <div className="card-body">
    
        <h3 className="card-title">احتساب الضريبة الشهرية</h3>
      
    
        <div className="table-responsive">
        <table className="table table-striped mb-0 ">
          <thead>
          <tr>
            <th></th>
            <th>الضريبة المستحقة</th>
            <th>نقاط التزكية</th>
            <th>الراتب الشهري</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {
            data.map((d,index)=>(
              <tr key={index}>
             <td>{index+1}</td>
              <td>{d.tax}</td>
              <td>{d.points}</td>
              <td>{d.monthlyIncome}</td>
              
              <td onClick={()=>handleDelete(d.id)}>حذف</td>
              </tr>
            ))}
        
          </tbody>
        </table>
        </div>
      </div>
     
  } */}
  
  </div>
  )
}