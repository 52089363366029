import React, { useState } from "react";


export function CalculatorAnnual() {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfToday, setDateOfToday] = useState("")
  const [startingWork, setStartingWork] = useState(0);
  const [startingW, setStartingW] = useState("");
  const [endingWork, setEndingWork] = useState(0);
  const [endingW, setEndingW] = useState("");
  const [paysDate, setPaysDate] = useState("YYYY-MM-DD");

  const [anotherYears, setAnotherYears] = useState(0);
  const [allMonths, setAllMonths] = useState(0);
  const [monthlyIncome, setMonthlyIncome] = useState(0);

  const [IncomeWithTax, setIncomeWithTax] = useState(0);
  const [IncomeWithOutTax, setIncomeWithOutTax] = useState(0);

  const [perc, setPerc] = useState("100");
  const [years, setYears] = useState(0);
  const [total, setTotal] = useState(0);
  //
  const [show, setShow] = useState(false)

  let limit = 12640

  // Handle Submit Function
  const handleSubmit = (e) => {
    e.preventDefault();
    setShow(true)
    calc();
    getDateOfToday();
    const timer = setTimeout(() => window.scroll(0, 2000), 80);
  };


  // Thousands Commas Function 
  function commify(n) {
    var parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
  }

  // Refresh Page أحتساب جديد
  const newCalc = () => {
    window.location.reload()
  }

  // Current Date
  const getDateOfToday = () => {
    let currentDate = new Date();
    let cDay = currentDate.getDate();
    let cMonth = currentDate.getMonth() + 1;
    let cYear = currentDate.getFullYear();
    setDateOfToday(cDay + "/" + cMonth + "/" + cYear)

  }

  // Tax & Tzkeat
  function calc() {
    let year,
      total,
      wTax, // wTax => monthlyIncome WithTax
      woTax = 0; // woTax => monthlyIncome WithOutTax



    // عدد السنوات
    year = (((endingWork - startingWork) / 31536000000 + anotherYears).toFixed(1));
    setYears(year);
    // مجموع الاقدميات بالاشهر
    setAllMonths((year * 12).toFixed(2));
    // مبلغ التعويضاتq  a
    total = year * monthlyIncome;
    // نسبة التعويض
    let per = perc;

    if (monthlyIncome > limit && per / 100 > 1) {
      setTotal(total * (per / 100).toFixed(2));
      woTax = limit * year;
      setIncomeWithOutTax(woTax.toFixed(2));
      wTax = total - woTax;
      setIncomeWithTax(wTax.toFixed(2));
    }
    else if (monthlyIncome < limit && per / 100 > 1) {
      wTax = monthlyIncome * (per / 100 - 1) * year;
      woTax = monthlyIncome * year;
      total = woTax + wTax;
      setTotal(total.toFixed(2));
      setIncomeWithTax(wTax.toFixed(2));
      setIncomeWithOutTax(woTax.toFixed(2));
    }
    else if (monthlyIncome < limit) {
      setTotal(total.toFixed(2));
      setIncomeWithOutTax(total.toFixed(2))
      setIncomeWithTax(0);
    }
    else if (monthlyIncome > limit) {
      setTotal(total.toFixed(2));
      wTax = (monthlyIncome - limit) * year;
      setIncomeWithTax(wTax.toFixed(2));
      woTax = total - wTax;
      setIncomeWithOutTax(woTax.toFixed(2));
    }

  }

  return (
    <div className=" card">
      <div className="card eofS">
        <div className="card-body">
          <h3 className="card-title text-center privacy ">تعويضات نهاية الخدمة</h3>
        </div>
        <div className="card-body eOfService">
          <form className="form container naqaha-form " onSubmit={handleSubmit}>
            <div className="">


              <div className="form-group row insurance-row">
                <label className="col-lg-4 col-form-label">الاسم الشخصي</label>
                <input
                  type="text"
                  className="form-control"
                  name=""
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="form-group row insurance-row">
                <label className="col-lg-4 col-form-label">اسم العائلة</label>
                <input
                  type="text"
                  className="form-control"
                  name=""
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="form-group row insurance-row">
                <label className="col-lg-4 col-form-label">تاريخ بدء العمل</label>
                <input
                  type="date"
                  className="form-control m-b-0"
                  name=""
                  onChange={(e) => { setStartingWork(Date.parse(new Date(e.target.value))); setStartingW(e.target.value) }
                  }
                />
              </div>
              <div className="form-group row insurance-row">
                <label className="col-lg-4 col-form-label">تاريخ انتهاء العمل</label>
                <input
                  type="date"
                  className="form-control m-b-0"
                  name=""
                  onChange={(e) => { setEndingWork(Date.parse(new Date(e.target.value))); setEndingW(e.target.value) }
                  }
                />
              </div>
              <div className="form-group row insurance-row">
                <label className="col-lg-4 col-form-label">تاريخ الدفع</label>
                <input
                  type="date"
                  className="form-control m-b-0"
                  name=""
                  onChange={(e) => setPaysDate(e.target.value)}
                />
              </div>
              <div className="form-group row insurance-row">
                <label className="col-lg-4 col-form-label">أقدمية إضافية(بالاشهر)</label>
                <input
                  type="text"
                  className="form-control"
                  name=""
                  onChange={(e) => setAnotherYears(e.target.value / 12)}
                />
              </div>
              <div className="form-group row insurance-row">
                <label className="col-lg-4 col-form-label">المعاش الشهري</label>
                <input
                  type="text"
                  className="form-control"
                  name=""
                  onChange={(e) => setMonthlyIncome(e.target.value)}
                />
              </div>
              <div className="form-group row insurance-row">
                <label className="col-lg-4 col-form-label"> نسبة التعويض ( % ) </label>
                <input
                  type="text"
                  className="form-control"
                  name=""
                  placeholder="100"
                  onChange={(e) => setPerc(e.target.value)}
                />
              </div>
            </div>

            <br />

            <div className="form-group row">

              <div className="col-sm-12 col-md-3"></div><br></br><br></br>
              <div className="col-sm-12 col-md-6  ">
                <br></br>
                <button className="btn btn-primary naqaha-btn insurance-btn"  > احتساب  </button>
                <br></br> <br></br>
              </div>

              <hr></hr>
            </div>
          </form>

          <br />
          <hr />
          <br />
        </div>
        {show && <div className="">
          {/* =================Result===================*/}
          <div className=" container">
            <div className="content container-fluid">
              <div className="page-header ">

                <div className="">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title mb-0 text-center privacy">نتائج الاحتساب </h4>
                    </div>
                    <div className="card-body">
                      {/* ================================================ */}

                      <div className="row result-div" >
                        <br></br>


                        <div className="col-sm-12 col-md-4 ">
                          <label className="label-title">الاسم: </label>

                        </div>
                        <div className="col-sm-12 col-md-3">

                          <label className="privacy reuslt-insurance"><h6>{firstName} {lastName}</h6></label>
                        </div>
                        <div className="col-sm-12 col-md-2 ">
                          <label className="label-title">التاريخ: </label>

                        </div>
                        <div className="col-sm-12 col-md-3">

                          <label className="privacy reuslt-insurance "><h6>{dateOfToday}</h6></label>
                        </div>
                        <div>

                        </div>

                        <div className=" row col-sm-12 col-md-6 border-div">
                          <br></br>
                          <h4 className=" mb-0  privacy text-center">السنوات </h4>
                          <br></br>
                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label>عدد السنوات </label>
                          </div>
                          <div className="col-sm-12 col-md-6 ">
                            <br></br>
                            <label className="reuslt-insurance">{commify(years)}</label>
                          </div>

                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label>أقدمية إضافية (بالاشهر)</label>
                          </div>
                          <div className="col-sm-12 col-md-6 ">
                            <br></br>
                            <label className="reuslt-insurance">{commify(anotherYears * 12)}</label>
                          </div>

                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label>مجموع الأقدميات (بالاشهر)   </label>
                          </div>
                          <div className="col-sm-12 col-md-6 ">
                            <br></br>
                            <label className="reuslt-insurance privacy ">{commify(allMonths)}</label>
                          </div>
                        </div>
                        <div className="row col-sm-12 col-md-6 border-div">
                          <br></br>
                          <h4 className=" mb-0  privacy text-center">المستحقات   </h4>
                          <br></br>
                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label>تاريخ بدء العمل </label>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label className="reuslt-insurance">{startingW}</label>
                          </div>


                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label>تاريخ انتهاء العمل   </label>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label className="reuslt-insurance">{endingW}</label>
                          </div>


                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label>حد الأعفاء من الضريبة   </label>
                          </div>
                          <div className="col-sm-12 col-md-6">
                            <br></br>
                            <label className="reuslt-insurance privacy">{commify(limit)}</label>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-2"></div>
                        <div className="col-sm-12 col-md-4  ">
                          <br></br>
                          <label>الراتب الشهري    </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance privacy">{commify(monthlyIncome)}</label>
                        </div>
                        <div className="col-sm-12 col-md-2"></div>
                        <div className="col-sm-12 col-md-4  ">
                          <br></br>
                          <label>المبلغ المعفى من الضريبة     </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance privacy">{commify(IncomeWithOutTax)}</label>
                        </div>
                        <div className="col-sm-12 col-md-2"></div>
                        <div className="col-sm-12 col-md-4  ">
                          <br></br>
                          <label>المبلغ الملزم بالضريبة     </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance privacy">{commify(IncomeWithTax)}</label>
                        </div>
                        <div className="col-sm-12 col-md-2"></div>
                        <div className="col-sm-12 col-md-4  ">
                          <br></br>
                          <label>مبلغ التعويضات     </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance privacy">{commify(total)}</label>
                        </div>
                        <div className="col-sm-12 col-md-2"></div>
                        <div className="col-sm-12 col-md-4  ">
                          <br></br>
                          <label>نسبة التعويض     </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance privacy">{commify(perc<100? 100:perc )}%</label>
                        </div>
                        <div className="col-sm-12 col-md-2"></div>
                        <div className="col-sm-12 col-md-4  ">
                          <br></br>
                          <label>تاريخ الدفع     </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <br></br>
                          <label className="reuslt-insurance privacy">{paysDate}</label>
                        </div>


                        <div className="form-group row">

                          <div className="col-sm-12 col-md-3"></div><br></br><br></br>
                          <div className="col-sm-12 col-md-6  ">
                            <br></br>
                            <button className="btn btn-primary naqaha-btn insurance-btn" onClick={newCalc} > احتساب جديد  </button>
                            <br></br> <br></br>
                          </div>

                          <hr></hr>
                        </div>

                      </div> <br></br>

                      {/* =================================================== */}

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
}