/**
 * TermsCondition Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import {FiSettings} from 'react-icons/fi';
import {FaSignature, FaPercent} from 'react-icons/fa';
import{Mpayroll, Mpayroll2, MassLow , MassPayroll, MassAsset, MassManage} from '../../Entryfile/imagepath';
import {IoDocumentTextOutline} from 'react-icons/io5';
import {VscLaw} from 'react-icons/vsc';

import {  BsTable } from "react-icons/bs";
import {  AiOutlineCalculator , AiOutlineLock } from "react-icons/ai";

import { NaqahaCalcG } from './naqaha-calc-general';
import { NaqahaCalcP } from './naqaha-calc-private';
// import Attendance from '../../../pr-system/attendance';
// import Attendance from '../attendance';
export default class NaqahaCalcc extends Component {
  render() {
    return (
           
			<div className="page-wrapper">
				 <Helmet>
              <title>MassPortal - رسوم النقاهة   </title>
              <meta name="description" content=" MassPortal - قوانين العمل   " />
            </Helmet>
			{/* Page Content */}
			<div className="content container-fluid">
			  {/* Page Header */}
			  <div className="page-header">
				<div className="row">
				  <div className="col-sm-8 col-4">
					<h3 className="page-title"> احتساب رسوم النقاهة       </h3>
					<ul className="breadcrumb">
					  <li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
					</ul>
				  </div>
				</div>
			  </div>
			   {/* Account Logo */}
			   {/* <div className="account-logo">
			  <img src={MassLow} alt='MassPortal Law' />
            </div> */}
            {/* /Account Logo */}
			  {/* /Page Header */}
			
			  
			  <div className="row">
			  
				<div className="col-lg-10 mx-auto">
				  {/* Plan Tab */}
				  <div className="row justify-content-center mb-4">
				  <div className="row">
					  
					  <div className="col-md-12">
						<div className="card card-table mb-0">
						  <div className="card-header">
							<h4 className="card-title mb-0 privacy">معطيات   </h4>
						  </div>
						  <div className="card-body">
							<div className="table-responsive">
							  <table className="table table-center mb-0">
								<thead>
								  <tr>
									<th>قيمة يوم النقاهة / خاص</th>
									<th>قيمة يوم النقاهة / عام   </th>
									<th>راتب حد أدنى / شهري </th>
									<th>راتب حد أدنى / ساعة  </th>
									<th>معدل الرواتب </th>
									<th>يوم سفريات </th>
								  
								  </tr>
								</thead>
								<tbody>
								  <tr className='text-center privacy tr-givens'>
									<td>400</td>
									<td>449</td>
									<td>5300</td>
									<td>29.12</td>
									<td>10428</td>
									<td>22.60</td>
									{/* <td><a className="btn btn-primary btn-sm" href="">Change Plan</a></td> */}
								  </tr>
								</tbody>
							  </table>
							</div>
						  </div>
						</div>
					  </div>
					</div>
					<br></br>
					<div className="col-auto">
					<br></br>
				
					  <nav className="nav btn-group settings-nav">
						<a href="#general" className="btn btn-outline-secondary active show" data-toggle="tab"> قطاع عام   </a>
						{/* <a href="#annual" className="btn btn-outline-secondary" data-toggle="tab"> حاسبات  </a> */}
						<a href="#private" className="btn btn-outline-secondary" data-toggle="tab"> قطاع خاص    </a>

					  </nav>
					</div>
				  </div>
				  {/* /Plan Tab */}
				  {/* Plan Tab Content */}
				  <div className="tab-content">
					{/* Monthly Tab */}
					<div className="tab-pane fade active show" id="general">
					  <div className="row mb-30 equal-height-cards">
						<NaqahaCalcG />
						
						
						
					  </div>
					</div>
					{/* /Monthly Tab */}
					{/* Yearly Tab */}
					<div className="tab-pane fade" id="private">
					  <div className="row mb-30 equal-height-cards">
						
					  <NaqahaCalcP />
					  </div>
					</div>
					{/* Yearly Tab */}
				  </div>
				  {/* /Plan Tab Content */}
				  {/* Plan Details */}
				  {/* <div className='row'>
				  <div className="col-lg-12 mx-auto"> 
			  <br></br>
			  <div className="table-responsive">
              <table className="table table-striped custom-table table-nowrap mb-0">
                <thead>
                  <tr>                   
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>                  
                  </tr>
                </thead>
                <tbody>
                  <tr>                   
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><a href="" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success" /></a></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>
                    <td><i className="fa fa-close text-danger" /></td>                  
                  </tr>                 
                </tbody>
              </table>
            </div>
			  <br></br>
			  </div>			  
				  </div> */}
				
				  {/* /Plan Details */}
				</div>
			  </div>
			</div>
			{/* /Page Content */}
		  </div>
       
    );
  }
}
