/**
 * Blank Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Itt21 from '../../Tables/basic21';
import Itt22 from '../../Tables/basic22/itt22';
import { TaxCalculatorComponent21 } from '../../../calculators/components/TaxCalculatorComponent21';
import { TaxCalculatorComponent22 } from '../../../calculators/components/TaxCalculatorComponent22';
export default class AboutMp extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal | من نحن   </title>
					<meta name="description" content="MassPortal - من نحن  " />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					<div className="small-container">
						<div className="inner-header text-center">
							<br></br>
							<br></br>
							<h1> Mass Portal </h1>
							<p className="privacy-bred"><a href="/">الرئيسية</a></p>
							<br></br>
							<br></br>
						</div>


						<div className="row">

							<br></br>
							<br></br>
							<br></br>
							<br></br>
							<div className="col-sm-10 offset-1 ">
								<h2 className='text-center'>يسعدنا اطلاعكم باطلاق الموقع الالكتروني <span className='privacy '>  MassPortal </span> الجديد بعد العيد ان شاء الله كجزء من التعاون مع ماث أكادمي .  </h2>
								<br></br>
								<h3 className='text-center'>مشروع طلاب <span className='privacy'> Math Academy </span>  موقع الكتروني للمحاسبين في القدس.   </h3>
								<br></br>
								<ul className='privacy-ol'>
									<li>
										<p>
											سيسمح الموقع الجديد
											، الذي سيديره الاستاذ حسن مصاروة ، بتوسيع وتقوية النشاط الرقمي والتواصل بين المحاسبين
											، ليكون بمثابة منصة للتحديثات والمعلومات المهنية ذات الصلة وحتى المناقشات. كما ترى
											، نحن نقدم بالفعل مجموعة مختارة من المحاضرات  على الموقع الإلكتروني الجديد
											، وفي وقت لاحق في البرنامج سوف نقدم أيضًا عروض تقديمية من اجتماعات المتدربين والمزيد
											، المراجعات الموصى بها للرجوع إليها والمزيد. سيعرض الموقع أيضًا بشكل منتظم ارتباطات لآخر
											التحديثات الاقتصاديه ، والذي يمكنك من خلاله أيضًا تلقي تحديثات منتظمة عبر البريد الإلكتروني.
											من المهم ملاحظة أنه يمكنك دائمًا تحديث التفاصيل الخاصة بك بالإضافة إلى إعدادات تلقي
											التحديثات عبر البريد الإلكتروني (بدءًا من القبول الكامل لقاعدة أو إضافة مجالات وموضوعات).
											<br></br>
										</p>
									</li>
									<li>
										<p>
											يتطلب معظم المحتوى المعروض على الموقع تعريفًا بكلمة المرور الخاصة بك للموقع.
											سيتطلب المحتوى أو المعلومات المخصصة لأعضاء الموقع فقط تحديد الهوية وفقًا لذلك.
											يمكنك دائمًا النقر فوق <span className='privacy'> " نسيت كلمة المرور " </span> عند تحديد الهوية وسيتم إرسالها إليك.
										</p>
									</li>
									<li>
										<p className='privacy text-under'>
											كجزء من التعاون مع <span className='privacy'> MAth Academy </span> سيتمكن أعضاء الموقع من التمتع بعدة مزايا :

										</p>
										<ol className='privacy-ol'>
											<li>
												<p>
													صفحة رئيسية شخصية يمكنك تحديثها بشكل مستقل ويمكن استخدامها أيضًا كمكتب محاسبي افتراضية (ستتلقى رسالة محددة حول هذا قريبًا).
												</p>
											</li>
											<li>
												<p>
													من موقع الويب الخاص ب <span className='privacy'> MassPortal </span> سيكون هناك مرجع لقائمة الصفحات الرئيسية للأعضاء , بالطبع يمكن للصديق الذي لا يهتم بهذه الصفحة الرئيسية ان يعطي دائما تعليمات لازالتها.
												</p>
											</li>
											<li>
												<p>
													الوصول المجاني ل <span className='privacy'> MassPortal Club </span> وهي مجلة رقمية و أيضا فلوج يوتيوب شهري يتضمن مراجعات متعمقة و دراسات حالة من المجالات المحاسبية الرقمية الرائدة في العالم .

												</p>
											</li>
											<li>
												<p>
													امكانية عرض القضايا للمناقشة في موقع <span className='privacy '> MassPortal </span>.
												</p>
											</li>
											<li>
												<p>
													كل واحد منكم مدعو للمشاركة في أنشطة <span className='privacy'> MassPortal </span> عبر الانترنت , اما عن طريق الاتصال بنا عن طريق كتابة التعليقات / الاقتراحات / المنشورات على الموقع , أو بارسال الاقتراحات و التوصيات مباشرة الى الاستاذ حسن مصاروة .
												</p>
											</li>
											<li>
												<p>
													في حال وجود أي مشكلة تواجهك على الموقع , يمكنك دائما الاتصال بنا من خلال صفحة <span className='privacy '><a href='https://massportall.web.app/contact' target='_blank' className='privacy'> اتصل بنا </a>  </span>

												</p>
											</li>

										</ol>
									</li>

								</ul>
								<hr></hr><br></br>
								<h1 className='text-center privacy '> نتمنى للقدس كل التوفيق والنجاح و الاستخدام المفيد و الممتع للموقع الجديد !

								</h1>
								<br></br>
								<hr></hr>
							</div>



						</div>



						{/* /Content End */}
					</div>

					{/* /Page Content */}
				</div>
			</div>

		);
	}
}
