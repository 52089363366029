/**
 * Basic Table
 */
import React, { Component, Fragment } from 'react';
import { Helmet } from "react-helmet";

class Itt21 extends Component {

	// const computTax = 5;
	render() {
		const computeTax = () => {
			console.log("Computing tax Function is here");
		}
		computeTax();

		return (


			<div >


				<div className="row">
					<h>
						
						<br></br>
						<br></br>
						<br></br>
					</h>
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title mb-0 text-center privacy">شرائح ضريبة الدخل لعام 2021</h4>
								<span>  قيمة نقطة التزكية :   <b className='privacy'> 218</b></span>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-nowrap table-striped mb-0">
										<thead>
											<tr>
												<th>الراتب ( شيكل جديد )</th>
												<th>الراتب التراكمي</th>
												<th>معدل الضريبة</th>
												<th>الضريبة </th>
												<th>الضريبة التراكمية</th>
												{/* <th>Country</th> */}
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>من  0  الى   6.290</td>
												<td>6.290</td>
												<td>10.0%</td>
												<td>629</td>
												<td>629</td>
											</tr>
											<tr>
												<td><span className='privacy'>+</span> 2.740</td>
												<td>9.030</td>
												<td>14.0%</td>
												<td>384</td>
												<td>1.013</td>
											</tr>
											<tr>
												<td><span className='privacy'>+</span> 5.460</td>
												<td>14.490 </td>
												<td>20.0%</td>
												<td>1.092</td>
												<td>2.105</td>
											</tr>
											<tr>
												<td><span className='privacy'>+</span> 5.650</td>
												<td>20.140</td>
												<td>31.0%</td>
												<td>1.752</td>
												<td>3.856</td>
											</tr>
											<tr>
												<td><span className='privacy'>+</span> 21.770</td>
												<td>41.910</td>
												<td>35.0%</td>
												<td>7.620</td>
												<td>11.476</td>
											</tr>
											<tr>
												<td> <span className='privacy'>+</span> 12.060  </td>
												<td>53.970</td>
												<td>47.0%</td>
												<td>5.668</td>
												<td>17.144</td>
											</tr>
											{/* <tr>
							  <td>1</td>
							  <td>Anna</td>
							  <td>Pitt</td>
							  <td>35</td>
							  <td>New York</td>
							</tr>
							<tr>
							  <td>1</td>
							  <td>Anna</td>
							  <td>Pitt</td>
							  <td>35</td>
							  <td>New York</td>
							</tr>
							<tr>
							  <td>1</td>
							  <td>Anna</td>
							  <td>Pitt</td>
							  <td>35</td>
							  <td>New York</td>
							</tr> */}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

export default Itt21;
