import { useEffect, useState } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
// import '../../src/assets/js/app.js';
// import 'font-awesome/css/font-awesome.min.css';
// import '../../src/assets/css/font-awesome.min.css';
// import '../../src/assets/css/line-awesome.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// // ==================================================
// import '../../src/assets/js/bootstrap.min.js';
// import '../../src/assets/css/select2.min.css';
// import '../../src/assets/js/popper.min.js';
// import '../../src/assets/js/select2.min.js';
// import '../../src/assets/js/jquery-3.2.1.min.js';
// import '../../src/assets/js/jquery.slimscroll.min.js';
// import '../../src/assets/js/jquery.slimscroll.js';
// import "../../src/assets/js/bootstrap-datetimepicker.min.js"
// import "../../src/assets/js/jquery-ui.min.js"
// import "../../src/assets/js/task.js"
// import "../../src/assets/js/multiselect.min.js"
// import "../../src/assets/plugins/bootstrap-tagsinput/bootstrap-tagsinput.css"
// import "../../src/assets/plugins/bootstrap-rtl/css/bootstrap.min.css"
// import "../../src/assets/plugins/bootstrap-rtl/js/bootstrap.min.js"
// import '../../src/assets/css/style.css'

// ==================================================
export const useLogout = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()
  
  const logout = async () => {
    setError(null)
    setIsPending(true)

    try {
      // sign the user out
      await projectAuth.signOut()
      
      // dispatch logout action
      dispatch({ type: 'LOGOUT' })

      // update state
      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      } 
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { logout, error, isPending }
}