/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { FaInfo } from "react-icons/fa";

class AssetTaxRate extends Component {

  render() {
    return (
      <div className="page-wrapper">
        <Helmet>
          <title>MassPortal - نسب الاستهلاك السنوية  </title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                {/* <h3 className="page-title"> فئات الأصول  </h3> */}
                <ul className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">الرئيسية</a></li>
                  <br></br>
                  {/* <li className="breadcrumb-item active">Blank Page</li>
							 */}
                </ul>

              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className='small-container'>

            {/* Buttons With Links - Start */}
            <div className='row'>
              <div className="col-sm-12 col-md-3 ">

              </div>

              <div className="col-sm-12 col-md-6 ">
                <br></br>
                <br></br>
                <h2 className='links-title usool-title text-center   '> نسب الاستهلاك السنوية </h2>

                <br></br>
                <br></br>
              </div>


            </div>
            {/* Buttons With Links - End */}
            <div className="row">


              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h6 className="card-title m-b-20  ">وفقًا لتعليمات  ضريبة الدخل    ( الاستهلاك ) المادة 1941</h6>
                <h6 className="card-title m-b-20 ">  البند  <span className='privacy '> 17 (8)</span> و <span className='privacy'> 21 </span> لقانون ضريبة الدخل</h6>
                <div>
                  <ul className='privacy-ol'>
                    <li>
                      سيتم الاعتراف بتكلفة الأصول الثابتة كمصروف خاضع للضريبة وفقًا لمعدلات الاستهلاك السنوية كما هو موضح في الجدول.
                    </li>
                    <li>
                      عندما يكون هناك تآكل كبير بشكل خاص في استخدام الممتلكات ، يمكن المطالبة باستهلاك يصل إلى ضعف نسبة الاستهلاك المذكور في الجدول.
                    </li>

                  </ul>
                </div>
                <div className="m-b-30">

                </div>
                <div className="table-responsive-code">
                  <table className="table table-striped custom-table table-bordered usool-table">
                    <thead className=''>
                      <tr className='privacy-white bold table-code-title '>
                        <th className='privacy'>نوع الملكية </th>
                        <th className="privacy">نسبة الاستهلاك </th>
                      </tr>
                    </thead>
                    <tbody className='table-code-body'>
                      {/* Table Row  */}
                      <tr className='table-code-tr usool-table-subtitle' >
                        <td colSpan={2}> 1-
                          المباني   ( باستثناء  الأراضي المبنية عليها   )
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>مباني حجرية من الدرجة الأولى</p></li>
                            <li><p>مباني خرسانية مسلحة من الدرجة الأولى</p></li>
                            <li><p>مباني من الدرجة الثانية التي يكون بناؤها أقل صلابة</p></li>
                            <li><p>مباني من الدرجة الثالثة التي تكون بنيتها رديئة ولكنها لا تشمل المباني المؤقتة </p></li>
                            <li><p> المباني المؤقتة بشكل صارم مثل المباني الخشبية <span className='privacy'> (  سيتم الاعتراف بالتجديدات كمصروفات )  </span></p></li>
                            <li><p>شقة في مبنى للإيجار موافق عليها قانونا لتشجيع الاستثمارات الرأسمالية</p>
                              <ul className='privacy-ol'>
                                <li>في مبنى تم تأجيره لأول مرة من 1.9.91 إلى 31.12.97</li>
                                <li>في حالة اخرى</li>
                              </ul>
                            </li>
                          </ol>
                        </td>
                        <td>
                          <ol className='privacy-ol  ol-raw'>
                            <li><p> 1.5 <span className='privacy'> % </span></p></li>
                            <li><p>2.0 <span className='privacy'> % </span></p></li>
                            <li> <p> 4.0 <span className='privacy '> % </span> </p></li>
                            <li> <p> 6.5 <span className='privacy'> % </span></p></li>
                            <li> <p>0.0 <span className='privacy'> % </span></p></li>
                            <li className='li-test'> <p>_____</p></li>
                            <li><p> 20 <span className='privacy'> % </span></p></li>
                            <li><p> 10 <span className='privacy '> % </span></p></li>
                          </ol>
                        </td>
                      </tr>

                      {/* Table Row  */}
                      <tr className='table-code-tr usool-table-subtitle' >
                        <td colSpan={2}>
                          ملاحظات بشأن المباني من  <span className='privacy'>( 1 - 4 )  </span>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td colSpan={2}>
                          <ul className='privacy-ol usool-privacy'>
                            <li><p>بالنسبة لمباني المصانع باستثناء المكاتب والمستودعات ومساكن المسؤولين <br></br>  <span className='privacy'> يتم خصم الاستهلاك بمعدل مضاعف </span></p></li>
                            <li><p>
                              <span className='privacy '> مباني السينما مثل المباني العادية </span>. <br></br>ينطبق هذا أيضًا على تلك الأجزاء التي تشمل أجهزة تكييف الهواء
                              وآلات العرض ،<br></br> ولا ينبغي اعتبار هذا الجزء من المبنى مبنى صناعيًا
                            </p></li>
                            <li><p>مباني من الدرجة الثانية التي يكون بناؤها أقل صلابة</p></li>
                            <li><p>مباني من الدرجة الثالثة التي تكون بنيتها رديئة ولكنها لا تشمل المباني المؤقتة </p></li>
                          </ul>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr usool-table-subtitle' >
                        <td colSpan={2}>2- الأثاث والاكسسوارات </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>الأثاث والاكسسوارات العامة</p></li>
                            <li><p>الأثاث والاكسسوارات المستخدمة في الفنادق والمنازل الداخلية</p></li>
                            <li><p>الأثاث في المقاهي والمطاعم والملاعب العامة منذ عام 1981 و مؤسسات الرعاية الخاصة</p></li>
                          </ol>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p> 6.0  <span className='privacy'> % </span></p></li>
                            <li><p>9.0 <span className='privacy'> % </span></p></li>
                            <li> <p> 12 <span className='privacy '> % </span> </p></li>
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr usool-table-subtitle' >
                        <td colSpan={2}>3- الآلات والمعدات </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>الآلات والمعدات العامة</p></li>
                            <li><p>اسطوانات الغاز البترولي السائل</p></li>
                            <li><p>ماكينات اسبريسو لصنع القهوة</p></li>

                            <li><p className='privacy'> نسبة خاصة على الآلات والمعدات المستخدمة في المصانع كالتالي:</p>
                              {/* <hr></hr> */}
                              <h4 className='privacy'> الفئة الاولى :   </h4><br></br>
                              <ol className='privacy-ol border-right'>

                                <li> <p> طواحين الدقيق </p></li>
                                <li><p>طواحين الأرز</p></li>
                                <li><p>طواحين العظام</p></li>
                                <li><p>معدات استخراج البترول  <span className='privacy'> ( التقطير ) </span></p></li>
                                <li><p>مصانع الثلج</p></li>
                                <li><p>مصانع إنتاج غاز الصودا و المياه </p></li>
                                <li><p>مصانع الكبريت</p></li>
                                <li><p>مصانع الأحذية والمنتجات الجلدية</p></li>
                                <li><p>مصانع النشا</p></li>
                                <li><p>مصانع انتاج الحمضيات المعلبة</p></li>
                              </ol>


                              <hr></hr>
                              <h4 className='privacy'> الفئة الثانية  :   </h4><br></br>
                              <ol className='privacy-ol border-right'>

                                <li> <p> مصانع الورق </p></li>
                                <li><p>مصانع قصب القش</p></li>
                                <li><p>مصانع السفن والمعامل الهندسية</p></li>
                                <li><p>مصانع  الحديد والصلب</p></li>
                                <li><p>مصانع الألمنيوم</p></li>
                                <li><p>مصانع الهندسة الكهربائية </p></li>
                                <li><p>مصانع إصلاح السيارات والمحركات</p></li>
                                <li><p>مصانع الجلفنة  و الطلاء</p></li>
                                <li><p>مصانع استخراج الزيوت</p></li>
                                <li><p>المصانع الكيماوية</p></li>
                                <li><p>مصانع الصابون والشموع</p></li>
                                <li><p>مصانع الجير</p></li>
                                <li><p>مناشير</p></li>
                                <li><p>مصانع القصدير والعلب</p></li>
                                <li><p>مصانع الغزل والنسيج</p></li>
                                <li><p>مصانع الأسمنت باستخدام الأفران</p></li>
                                <li><p>مكابس هيدروليكية</p></li>
                                <li><p>مصانع الطوب</p></li>
                                <li><p>صناعة الرصف</p></li>
                                <li><p>صناعة الأدوات البصرية</p></li>
                                <li><p>صناعة الأنابيب الأسمنتية</p></li>
                                <li><p>صناعة الزجاج والأنابيب المفرغة</p></li>
                                <li><p>مصانع الهاتف</p></li>
                                <li><p>مصانع الأسلاك الحديدية والمسامير</p></li>
                                <li><p>صناعة الحديد و الفولاذ</p></li>
                                <li><p>مصانع الدباغة </p></li>
                                <li><p>صناعة البطاريات</p></li>
                                <li><p>الطباعة <span>  (سيتم الاعتراف بتجديد الخطوط كمصروفات) </span></p></li>

                              </ol>


                            </li>
                            {/* <li><p>الأثاث في المقاهي والمطاعم والملاعب العامة منذ عام 1981 و مؤسسات الرعاية الخاصة</p></li> */}



                          </ol>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p> 7.0  <span className='privacy'> % </span></p></li>
                            <li><p> 7.0  <span className='privacy'> % </span></p></li>
                            <li><p> 20  <span className='privacy'> % </span></p></li>
                            <li></li><li><li></li></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 9.0<span className='privacy '> % </span> </p></li>
                            <li className=' '> </li>
                          </ol>

                          {/* ============================================ */}

                          <ol className='privacy-ol usool-privacy ol-raw'>

                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li>
                            {/* <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li> */}
                            {/* <li className='border-left'> <p> 10<span className='privacy '> % </span> </p></li> */}




                          </ol>
                        </td>
                      </tr>
                      <tr className='table-code-tr usool-table-subtitle' >
                        <td colSpan={2}> سيتم تقديم سعر خاص للآلات والمصانع التالية </td>
                      </tr>
                      <tr>
                        <td>
                          <ul><li><h3>مصانع الملح</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>الآلات والنظام والقاطرات والعربات والجوال</p></li>
                            <li><p>سفن الشحن والآلات العائمة</p></li>
                            <li><p>جميع الآلات والأجهزة العامة المستخدمة في ورش إصلاح الماكينات <br></br>
                              <span className='privacy'>  (سيتم الاعتراف بالإصلاحات المبنية من الأوساخ كمصاريف   ) </span>
                            </p></li>
                            <li><p>المسابح وغرف التركيز وأحواض الملح والقنوات والنقل والمراسي <br></br>إذا كانت مبنية من الحجر أو الأسمنت المسلح أو الأسفلت أو مواد مماثلة.</p></li>
                            <li><p>المراسي و الأرصفة المبنية بشكل كامل أو أساسي من الفولاذ</p></li>
                            <li><p>المراسي والأرصفة والأرصفة المبنية بالكامل أو بشكل رئيسي من الخشب</p></li>
                            <li><p>مواسير لنقل المياه المالحة إذا كانت مصنوعة من الخرسانة المسلحة أو الإسفلت</p></li>

                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>

                            <li> <p> 15 <span className='privacy '> % </span> </p></li>
                            <li> <p> 10 <span className='privacy '> % </span> </p></li>
                            <li> <p> 10 <span className='privacy '> % </span> </p></li>
                            <li> <p> <br></br>6.0 <span className='privacy '> % </span> </p></li>
                            <li> <p> 7.0 <span className='privacy '> % </span> </p></li>
                            <li> <p> 12 <span className='privacy '> % </span> </p></li>
                            <li> <p> 12 <span className='privacy '> % </span> </p></li>


                          </ol>
                        </td>
                      </tr>
                      <tr>

                        <td>
                          <ul><li><h3>الآلات الكهربائية</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>بطاريات</p></li>
                            <li><p>كابلات واسلاك تحت الارض</p></li>
                            <li><p> الكابلات والأسلاك فوق سطح الأرض</p></li>
                            <li>
                              <p>أجهزة الأشعة السينية وآلات العلاج الكهربائي وملحقاتها</p>
                              <ul>
                                <li><p>التشخيص</p></li>
                                <li><p>أجهزة الصدمات <span className='privacy'> (سيتم خصم سعر استبدالهم) </span></p></li>
                                <li><p> التصوير المقطعي المحوسب C.T. <span className='privacy '> (من السنة الضريبية 1988) </span></p></li>
                                <li><p> كرسي طبيب أسنان لطبيب الأسنان
                                  <span className='privacy'> 
                                    (من السنة الضريبية 1988)
                                  </span>
                                </p>
                                </li>
                                <li><p>آلات أخرى</p></li>
                              </ul>
                            </li>
                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 10 <span className='privacy '> % </span> </p></li>
                            <li> <p> 3.0 <span className='privacy '> % </span> </p></li>
                            <li> <p> 5.0 <span className='privacy '> % </span> </p></li>
                            <li><p></p></li>
                            <li> <p> 10 <span className='privacy '> % </span> </p></li>
                            <li> <p> 15<span className='privacy '> % </span> </p></li>
                            <li> <p> 20 <span className='privacy '> % </span> </p></li>
                            <li> <p> 10 <span className='privacy '> % </span> </p></li>
                            <li> <p> 7.5 <span className='privacy '> % </span> </p></li>
                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      <tr>

                        <td>
                          <ul><li><h3>محطات إمداد الكهرباء</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>اجهزة لتوريد الكهرباء والالات والمراجل</p></li>
                            <li><p>محطات الطاقة الكهرومائية والمحطات الهيدروليكية وخطوط الأنابيب والسدود والكابلات</p></li>


                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 7.0 <span className='privacy '> % </span> </p></li>
                            <li> <p> 3.0 <span className='privacy '> % </span> </p></li>

                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <ul><li><h3>مصانع الزيوت المعدنية</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li>
                              <p>المصافي والنباتات الحقلية</p>
                              <ul>
                                <li><p>الأنابيب</p></li>
                                <li><p>ممتلكات متنقلة و عدة حفر , برك و أصول أخرى</p></li>
                              </ul>
                            </li>
                            <li>
                              <p>موزعات</p>
                              <ul>
                                <li><p>مضخات على جانب الطريق بما في ذلك حمامات السباحة تحت الأرض وملحقاتها</p></li>
                              </ul>
                            </li>
                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 5.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                            <ul className=' ol-raw'><li><h3><br></br></h3></li></ul>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>
                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}

                      <tr>
                        <td>
                          <ul><li><h3>السفن والقوارب والحاويات</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li>
                              <p>السفن</p>
                              <ul>
                                <li><p><span className='privacy'> من السنة الضريبية 1992 </span>: السفن التي يكون استخدامها<br></br> الرئيسي هو النقل من دولة إلى أخرى</p></li>
                                <li><p><span className='privacy'> من السنة الضريبية 1987 </span>: السفن الأخرى (حتى 1991 - بما في ذلك<br></br> السفن التي يتمثل هدفها الرئيسي في النقل من بلد إلى آخر )</p></li>
                                <li><p> <span className='privacy'> حتى السنة الضريبية 1986 </span>: البواخر</p></li>
                                <li><p>إبحار أو جر السفن أو السفن الأخرى</p></li>
                              </ul>
                            </li>
                            <li>
                              <p>الحاويات</p>
                              <ul>
                                <li><p>من الألمنيوم</p></li>
                                <li><p>من الحديد</p></li>

                              </ul>
                            </li>
                            <li>
                              <p>القوارب</p>
                              <ul>
                                <li><p>من سنة 1989 الضريبية</p></li>
                                <li>
                                  <p>حتى العام الضريبي  : 1988</p>
                                  <ol className='privacy-ol usool-privacy'>
                                    <li><p>القوارب والجولات السياحية</p></li>
                                    <li><p>قوارب سريعة</p></li>
                                  </ol>
                                </li>
                              </ul>
                            </li>



                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'><br></br>
                            <li> <p> 10<span className='privacy '> % </span> </p></li><br></br>
                            <li> <p> 7.5<span className='privacy '> % </span> </p></li>
                            <li> <p> 5.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 4.0<span className='privacy '> % </span> </p></li><br></br>
                            <li> <p> 7.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 15<span className='privacy '> % </span> </p></li><br></br><br></br>


                            {/* <ul className=' ol-raw'><li><h3><br></br></h3></li></ul> */}
                            <li> <p> 7.5<span className='privacy '> % </span> </p></li>
                            <br></br><br></br>
                            <li> <br></br><p> 12.5<span className='privacy '> % </span> </p></li>

                            <li> <p> 20<span className='privacy '> % </span> </p></li>

                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <ul ><li><h3>المناجم والمحاجر</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li>
                              <p>الآلات</p>
                              <ul>
                                <li><p>الآلات فوق الأرض والآلات الموجودة تحت الأرض <span className='privacy'> ( باستثناء الآلات الكهربائية )</span></p></li>
                                <li><p>المراجل </p></li>
                              </ul>
                            </li>
                            <li><p>الحفارات والمنحدرات</p></li>
                            <li><p>  ماكينات تحت الارض </p></li>
                            <li><p> مصابيح الأمان  <span className='privacy'> ( مصابيح الأمان التي سيتم خصم مصاريفها كنفقة )</span></p></li>
                            <li><p> الآلات و كهربائيات مصانع النقل </p></li>
                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 7.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 7.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 7.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                            <li><p></p></li>
                            <li> <p> 7.0<span className='privacy '> % </span> </p></li>


                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <ul><li><h3>الطائرات</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>

                            <li><p>طائرات</p></li>
                            <li><p>  آلات الطيران </p></li>
                            <li><p> آلات التصوير الجوي </p></li>
                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 30<span className='privacy '> % </span> </p></li>
                            <li> <p> 40<span className='privacy '> % </span> </p></li>
                            <li> <p> 25<span className='privacy '> % </span> </p></li>



                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <ul className='privacy-ol usool-privacy '><li><h3>إمدادات المياه ومحطات الري  <span className='privacy'> ( باستثناء المستخدمة في الزراعة )</span></h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>

                            <li><p>الحفر والآبار في طبقات الصخور الصلبة أو الاسمنتية </p></li>
                            <li><p>  الآبار ذات الطبقات الحجرية </p></li>
                            <li><p> الآبار ذات المرشحات في طبقات الرمل </p></li>
                            <li><p>مضخات حفر</p></li>
                            <li><p>مضخات أخرى</p></li>
                            <li><p>أبراج مياه من الخرسانة المسلحة</p></li>
                            <li><p>حمامات من الخرسانة المسلحة</p></li>
                            <li><p>أنابيب فولاذية</p></li>
                            <li><p>الأنابيب والقنوات الخرسانية</p></li>
                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 2.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 8.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 15<span className='privacy '> % </span> </p></li>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>
                            <li> <p> 7.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 4.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 4.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 5.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 4.0<span className='privacy '> % </span> </p></li>



                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <br></br>
                          <ul className='privacy-ol usool-privacy '><li><h3>محطات التبريد والثلاجات وما إلى ذلك.  </h3></li></ul>
                        </td>
                        <td>
                          <br></br>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 9.0<span className='privacy '> % </span> </p></li>
                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <ul><li><h3>الآلات والمعدات على النحو التالي</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>

                            <li><p> آلات ضغط الهواء</p></li>
                            <li><p>  آلات الطلاء الكهربائي واللحام الكهربائي </p></li>
                            <li><p> آلات تكييف الهواء </p></li>
                            <li><p>آلات ومعدات مقاولي البناء</p></li>
                            <li><p>آلات صناعة الأنابيب الخرسانية</p></li>
                            <li><p>معدات و أضواء عرض الصور المتحركة </p></li>

                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>
                            <li> <p> 15<span className='privacy '> % </span> </p></li>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <ul><li><h3>مركبات</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>

                            <li><p> السيارات والدراجات النارية والدراجات البخارية والمقطورات <span className='privacy'> ( من سنة 1985 الضريبية )</span> </p></li>
                            <li><p>  شاحنات بيك أب يصل وزنها إلى 3000 كجم <span className='privacy'> ( من السنة الضريبية 1991 )</span> </p></li>
                            <li><p> سيارات الأجرة والشاحنات والحافلات <span className='privacy'> ( من العام الضريبي 1977 ) </span> <br></br>باستثناء تلك المستخدمة في الزراعة </p></li>
                            <li><p>تأجير السيارات  <span className='privacy'> ( ابتداء من السنة الضريبية 1988 )</span></p></li>
                            <li><p>سيارة مستخدمة للقيادة في مدرسة لتعليم القيادة <span className='privacy'> ( من الإقرار الضريبي لعام 1981 )</span></p></li>
                            <li><p>معدات و أضواء عرض الصور المتحركة </p></li>

                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 15<span className='privacy '> % </span> </p></li>
                            <li> <p> 15<span className='privacy '> % </span> </p></li>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <ul><li><h3> الآلات والمعدات التالية</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>


                            <li><p>الأدوات الجراحية <span className='privacy'> ( سيتم خصم ثمن استبدالها )</span> </p></li>
                            <li><p>أجهزة الراديو ومعداتها وملحقاتها</p></li>
                            <li><p> المعمل الصغير لتنمية الصور Minilabs  <span className='privacy'> ( من السنة الضريبية 1991 )</span></p></li>

                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> <span className='privacy '>  </span> </p></li>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>

                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr>
                        <td>
                          <ul><li><h3>  السكك الحديدية</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>



                            <li><p>التنقيب ، المحاذاة ، السدود ، الأنفاق ، إلخ</p></li>
                            <li><p> المشارب وغيرها من الملحقات </p></li>

                          </ol>
                        </td>
                        <td>
                          <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li> <p> 2.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 6.5<span className='privacy '> % </span> </p></li>

                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}

                      <tr>
                        <td>
                          <ul ><li><h3>الكترونيكا</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>معدات الكترونية</p></li>
                            <li><p>   أجهزة الكمبيوتر التي تم شراءها  منذ عام 1997 </p></li>
                            <li>
                              <p>حواسيب</p>
                              <ul>
                                <li><p>حواسيب شخصية <span className='privacy'> ( تم شراؤها من السنة الضريبية 1997 ) </span></p></li>
                                <li><p>حواسيب شخصية <span className='privacy'> ( تم شراؤها من السنة الضريبية 1996 ) </span></p></li>
                                <li><p>حواسيب شخصية <span className='privacy'> ( تم شراؤها حتى عام 1995 الضريبي ) </span></p></li>

                              </ul>
                            </li>
                            <li>
                              <p>أجهزة كمبيوتر أخرى</p>
                              <ul>
                                <li><p>حواسيب أخرى <span className='privacy'> ( تم شراؤها من السنة الضريبية 1997 ) </span></p></li>
                                <li><p>حواسيب أخرى <span className='privacy'> ( تم شراؤها حتى عام 1996 الضريبي ) </span></p></li>

                              </ul>
                            </li>
                          </ol>
                        </td>
                        <td>
                          {/* <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul> */}
                          <ol className='privacy-ol usool-privacy ol-raw'><br></br>
                            <li> <p> 15<span className='privacy '> % </span> </p></li>
                            <li> <p> 15<span className='privacy '> % </span> </p></li>
                            {/* <li> <p> 5.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 4.0<span className='privacy '> % </span> </p></li><br></br>
                            <li> <p> 7.0<span className='privacy '> % </span> </p></li>
                            <li> <p> 15<span className='privacy '> % </span> </p></li><br></br><br></br> */}
                            <ul className=' ol-raw'><li><h3><br></br></h3></li></ul>
                            <li> <p> 33<span className='privacy '> % </span> </p></li>
                            <li> <p> 25<span className='privacy '> % </span> </p></li>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                            <ul className=' ol-raw'><li><h3><br></br></h3></li></ul>
                            <li> <p> 20<span className='privacy '> % </span> </p></li>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>

                            {/* <li> <br></br><p> 12.5<span className='privacy '> % </span> </p></li>

                            <li> <p> 20<span className='privacy '> % </span> </p></li> */}

                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      {/* Table Row  */}

                      <tr>
                        <td>
                          <ul ><li><h3>الغسالات البخارية</h3></li></ul>

                        </td>
                        <td>
                          {/* <ul className=' ol-raw'><li><h3><br></br><br></br></h3></li></ul> */}
                          <ol className='privacy-ol usool-privacy ol-raw'><br></br>
                            <li> <p> 10<span className='privacy '> % </span> </p></li>


                          </ol>
                        </td>

                      </tr>
                      {/* Table Row  */}
                      {/* Table Row  */}
                      <tr className='table-code-tr usool-table-subtitle' >
                        <td colSpan={2}>4- الزراعة </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ul><li><h3> المزارع</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>افوكادو</p></li>
                            <li><p>موز</p></li>
                            <li><p>الجوافة</p></li>
                            <li><p>الحمضيات</p></li>
                            <li><p>زيتون  <span className='privacy'> ( جبلي  )</span></p></li>
                            <li><p>زيتون  <span className='privacy'> ( مروي )</span></p></li>
                            <li><p>الخروب </p></li>
                            <li><p>أشجار الفاكهة المتساقطة الأوراق </p></li>
                            <li><p>العنب  <span className='privacy'> ( جبلي  )</span></p></li>
                            <li><p>العنب  <span className='privacy'> ( مروي )</span></p></li>
                            <li><p>رمان </p></li>
                            <li><p>إسكدنيا </p></li>
                            <li><p>تين </p></li>
                            <li><p>تمر </p></li>
                          </ol>

                        </td>
                        <td>
                          <ul className='ol-raw'><li><h3><br></br> </h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p> 6.0  <span className='privacy'> % </span></p></li>
                            <li><p>9.0 <span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>
                            <li><p>6.7<span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>
                            <li><p>6.7<span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>
                            <li><p>6.7<span className='privacy'> % </span></p></li>
                            <li><p>6.7<span className='privacy'> % </span></p></li>


                          </ol>
                        </td>
                      </tr>

                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ul><li><h3> شبكة ري تستخدم للزراعة</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>بئر مع مرشح في طبقات من الرمل</p></li>
                            <li><p>آبار أخرى</p></li>

                            <li><p>شبكة ري سفلية  <span className='privacy'> ( الرئيسية  )</span></p></li>
                            <li><p>شبكة رى علوية ثابتة من مواسير بلاستيك</p></li>
                            <li><p>شبكة ري علوية أخرى </p></li>

                          </ol>

                        </td>
                        <td>
                          <ul className='ol-raw'><li><h3><br></br> </h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p> 20  <span className='privacy'> % </span></p></li>
                            <li><p>10 <span className='privacy'> % </span></p></li>
                            <li><p>10<span className='privacy'> % </span></p></li>
                            <li><p>20<span className='privacy'> % </span></p></li>
                            <li><p>15<span className='privacy'> % </span></p></li>



                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ul> <li><h3>الدفيئة  <span className='privacy'> ( بما في ذلك الهيكل والمرافق والمعدات والأدوات  )</span></h3></li></ul>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><br></br><p> 25  <span className='privacy'> % </span></p></li>
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ul> <li><h3>المباني المستخدمة للزراعة  <span className='privacy'> ( باستثناء الدفيئة  )</span></h3></li></ul>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><br></br><p> 12.5  <span className='privacy'> % </span></p></li>
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ul> <li><h3>الجرارات والآلات ذاتية الدفع </h3></li></ul>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><br></br><p> 20  <span className='privacy'> % </span></p></li>
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ul> <li><h3>الأدوات والمعدات الأخرى المستخدمة في الزراعة </h3></li></ul>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><br></br><p> 20  <span className='privacy'> % </span></p></li>
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ul><li><h3> الحيوانات</h3></li></ul>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>الحمار</p></li>
                            <li><p>الحصان</p></li>
                            <li><p>البغل</p></li>

                          </ol>

                        </td>
                        <td>
                          <ul className='ol-raw'><li><h3><br></br> </h3></li></ul>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p>10 <span className='privacy'> % </span></p></li>
                            <li><p>10<span className='privacy'> % </span></p></li>
                            <li><p>8.5<span className='privacy'> % </span></p></li>
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='table-code-tr usool-table-subtitle' >
                        <td colSpan={2}> 5-
                          كتب وأشرطة
                        </td>
                      </tr>
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>
                              الأدب المهني <br></br><span className='privacy'>  ( باستثناء أولئك الذين حصلوا على خصم بسبب نفقات الأدب المهني  )</span>

                            </p></li>
                            <li><p>
                              أشرطة فيديو مسجلة مملوكة <br></br><span className='privacy'>  ( بدون حقوق شراء ، لأصحاب المكتبات ، لأشرطة الفيديو - من السنة الضريبية - 1989  )</span>

                            </p></li>

                          </ol>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <br></br> <li><p> 15 <span className='privacy'> % </span></p></li><br></br>
                            <li><p> 25 <span className='privacy'> % </span></p></li>

                          </ol>
                        </td>
                      </tr>

                      {/* Table Row  */}
                    </tbody>
                  </table>
                </div>
                <br></br>
                <br></br>
                <h6 className="card-title m-b-20  privacy text-center table-tr-underline">الاستهلاك الخاص بالسفن و الحاويات </h6>
                <h4 className="">
                  <br></br>
                  وفقا لتعليمات ضريبة الدخل
                  <span className='privacy'> ( نسبة استهلاك السفن  ) </span>
                  يشار اليها فيما يلي :

                </h4>
                <div>
                  <br></br>
                  <ul className='privacy-ol'>
                    <li>
                      <h3>خصم الاستهلاك بسبب استخدام السفينة لتوليد الدخل</h3>
                    </li>
                    
                  </ul>
                </div>

                <div className="table-responsive-code">
                  <table className="table table-striped custom-table table-bordered usool-table">
                  <thead className=''>
                      <tr className='privacy-white bold table-code-title '>
                        <th className=' text-center border-bottom'colSpan={3}>نسب الاستهلاك  للسفن <br></br></th>
                      
                      </tr>
                    </thead>
                    <thead className=''>
                      <tr className='privacy-white bold table-code-title border-bottoom '>
                        <th className='privacy'>نوع السفينة </th>
                        <th className="privacy">وفقًا للمادة 1941 </th>
                        <th className="privacy">وفقًا للقوائم الجديدة  </th>

                      </tr>
                    </thead>
                    <tbody className='table-code-body'>
                      {/* Table Row  */}
                      
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p> استخدامها الرئيسي هو النقل من دولة إلى أخرى</p></li>
                           
                          </ol>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p> 10 <span className='privacy'> % </span></p></li>
                           
                          </ol>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p> 20 <span className='privacy'> % </span></p></li>
                           
                          </ol>
                        </td>
                      </tr>

                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>السفن والقوارب الأخرى</p></li>
                           
                          </ol>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p>7.5 <span className='privacy'> % </span></p></li>
                           
                          </ol>
                        </td>
                        <td>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p>7.5 <span className='privacy'> % </span></p></li>
                           
                          </ol>
                        </td>
                      </tr>
                      {/* Table Row  */}
                 
                      <tr >
                        <td colSpan={3}>
                        <p className='text-center'>
                        بالإضافة إلى خصم الاستهلاك وفقًا لقوائم الاستهلاك  <span className='privacy'> للمادة 1941 </span> ، فإن مبلغًا أكبر لخصم الاستهلاك المستحق للسفينة يساوي  <span className='privacy'> 100٪ </span> من مبلغ خصم الاستهلاك                             </p>
                        </td>
                               
                      </tr>
                      </tbody>
                      </table>
                      </div>
                      
                <div className="table-responsive-code">
                            <br></br>
                            <ul className='privacy-ol'>
                              <li>
                              <h2> الاستهلاك بسبب الحاوية </h2>
                              </li>
                            </ul>
                  
             
                  <table className="table table-striped custom-table table-bordered usool-table">
                  <thead className=''>
                      <tr className='privacy-white bold table-code-title '>
                        <th className=' text-center border-bottom'colSpan={3}>نسب الاستهلاك بسبب الحاوية <br></br></th>
                      
                      </tr>
                    </thead>
                    <thead className=''>
                      <tr className='privacy-white bold table-code-title border-bottoom '>
                        <th className='privacy'>نوع الحاوية </th>
                        <th className="privacy">وفقًا للمادة 1941 </th>
                        <th className="privacy">وفقًا للقوائم الجديدة  </th>

                      </tr>
                    </thead>
                    <tbody className='table-code-body'>
                      {/* Table Row  */}
                      
                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p> حاويات الحديد</p></li>
                           
                          </ol>
                        </td>
                        <td >
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p className='text-center'> 7.5 <span className='privacy'> % </span></p></li>
                           
                          </ol>
                        </td>
                        <td className='text-center'>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p className='text-center'> 9.3 <span className='privacy'> % </span></p></li>
                           
                          </ol>
                        </td>
                      </tr>

                      {/* Table Row  */}
                      <tr className='tr-border-bottom'>
                        <td>
                          <ol className='privacy-ol usool-privacy'>
                            <li><p>حاويات الألمنيوم</p></li>
                           
                          </ol>
                        </td>
                        <td className='text-center'>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p className='text-center'>15 <span className='privacy'> % </span></p></li>
                           
                          </ol>
                        </td>
                        <td className='text-center'>
                          <ol className='privacy-ol usool-privacy ol-raw'>
                            <li><p className='text-center'>20 <span className='privacy'> % </span></p></li>
                           
                          </ol>
                        </td>
                      </tr>
                      <tr >
                        <td colSpan={3}>
                        <p className='text-center'>
                            بالإضافة إلى خصم الاستهلاك وفقًا للمادة <span className='privacy'> 1941 </span> ، يتم خصم المزيد من الاستهلاك للحاوية بمبلغ <span className='privacy'> يساوي 1/3٪ 33 </span> من مبلغ خصم الاستهلاك .
                            </p>
                        </td>
                               
                      </tr>
                           
                      {/* Table Row  */}
                      </tbody>
                      </table>


                      
                      </div>
                      <br></br>
                
                <div>
                  <br></br>
                  <ul className='privacy-ol'>
                    <li>
                      <h3>حدود سقف الخصم</h3>
                    </li>
                    <li>
                    <h4 className="">
             
                  ثبت في القوائم الجديدة أن سقف الخصم لسفينة أو حاوية , سيكون بحدود الخصم وفقا للمادة <span className='privacy'> 1941 </span> <br></br>بالاضافة الى الخصم وفقا للقوائم الجديدة و الخصم الاضافي بسبب الاستهلاك وفقا للمادة <span className='privacy'> 3 (  ب )</span>
                  من قانون ضريبة الدخل <br></br><span className='privacy '> ( تعديلات بسبب التضخم )  </span> , 1985-5555 <span> ( ملحق الاستهلاك الحقيقي ) </span>
                </h4>
                    </li>
                  </ul>
                  
                </div>



              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}


      </div>
    );
  }
}

export default AssetTaxRate;
