/**
 * TermsCondition Page
 */
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { FiSettings } from 'react-icons/fi';
import { FaSignature } from 'react-icons/fa';
import { MassBill, MassManage, MassContractLogo } from '../../Entryfile/imagepath';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { VscLaw } from 'react-icons/vsc';
import {RiGovernmentLine} from 'react-icons/ri';
import Attendance from '../../pr-system/attendance';
import ContrcHebrewComp from '../../components/pages/sidebar-pages/contracts/contrctHebreComp';
import ReportsHeComponent from '../../components/pages/sidebar-pages/contracts/ReportsHeComponent';
// import Attendance from '../attendance';
export default class MassContracts extends Component {
	render() {
		return (

			<div className="page-wrapper">
				<Helmet>
					<title>MassPortal - عقود   </title>
					<meta name="description" content="Reactify Blank Page" />
				</Helmet>
				{/* Page Content */}
				<div className="content container-fluid">
					{/* Page Header */}
					<div className="page-header">
						<div className="row">
							<div className="col-sm-8 col-4">
								<h3 className="page-title"> عقود     </h3>
								<ul className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">الرئيسية </a></li>
								</ul>
							</div>
						</div>
					</div>
					{/* Account Logo */}
					<div className="account-logo">
						{/* <a href="/"><img src={MassBill} alt="Mass Billing " /></a> */}
						<img src={MassContractLogo} alt="MassPortal Contracts " />
					</div>
					{/* /Account Logo */}
					{/* /Page Header */}


					<div className="row">

						<div className="col-lg-10 mx-auto">
							{/* Plan Tab */}
							<div className="row justify-content-center mb-4">
								<div className="col-auto">
									<nav className="nav btn-group settings-nav">
										<a href="#monthly" className="btn btn-outline-secondary active show" data-toggle="tab"> عقود  </a>
										<a href="#annual" className="btn btn-outline-secondary" data-toggle="tab">  عقود ديناميكية  </a>
									</nav>
								</div>
							</div>
							{/* /Plan Tab */}
							{/* Plan Tab Content */}
							<div className="tab-content">
								{/* Monthly Tab */}
								<div className="tab-pane fade active show" id="monthly">
								<div className="card-header">
										<h4 className="card-title mb-0 privacy text-center">عقود     </h4>
									</div>
									<br></br>
									<ContrcHebrewComp />
									<div className="card-header">
										<h4 className="card-title mb-0 privacy text-center">مراسلات و تقارير    </h4>
									</div>
									<br></br>
									<ReportsHeComponent />
								</div>
								{/* /Monthly Tab */}
								{/* Yearly Tab */}
								<div className="tab-pane fade" id="annual">
									<div className="row mb-30 equal-height-cards">
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className="settings-title">انشاء عقود ديناميكية     </h2>
														<span className="display-6">< FaSignature size={40} className="privacy" /> </span>
													</div>
													<ul className='text-left settings-mass'>
														<li><a href='/pages/sidebar-pages/contracts/rent-contract'><FaSignature size={25} className='privacy' /> انشاء عقد ايجار      </a></li>
														<li><a href='#'><FaSignature size={25} className='privacy' /> أخرى ....... </a></li>
														<li><a href='#'><FaSignature size={25} className='privacy' /> أخرى .......  </a></li>
													</ul>
													{/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className='settings-title'> وزارة العدل </h2>
														<span className="display-6"><RiGovernmentLine className='privacy' /></span>

														{/* <h3>Professional</h3>
								<span className="display-4">$199</span>
								<span>/mo</span> */}
													</div>
													<ul className='text-left settings-mass'>
														<li><a href='/rasham-comp'><RiGovernmentLine size={25} className='privacy' /> بحث عن شركة في مراقب الشركات      </a></li>
														<li><a href='/rasham-as'><RiGovernmentLine size={25} className='privacy' />  بحث عن جمعية في مراقب الجمعيات      </a></li>
														<li><a href='#'><RiGovernmentLine size={25} className='privacy' /> تقارير أخرى ...      </a></li>


													</ul>
													{/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card pricing-box">
												<div className="card-body d-flex flex-column">
													<div className="mb-4">
														<br></br>
														<h2 className='settings-title'> مراقب الشركات   </h2>
														<span className="display-6"><RiGovernmentLine className='privacy' /></span>
													</div>
													<ul className='settings-mass text-left '>
														<li><a href='#'><RiGovernmentLine size={25} className='privacy' /> تقديم كشف سنوي   </a></li>
														<li><a href='#'><RiGovernmentLine size={25} className='privacy' /> فتح شركة    </a></li>
														<li><a href='#'><RiGovernmentLine size={25} className='privacy' /> معاملات  - <span className='privacy'> Online</span>   </a></li>


													</ul>
													{/* <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a> */}
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* Yearly Tab */}
							</div>
							
						</div>
					</div>
				</div>
				{/* /Page Content */}
			</div>

		);
	}
}
